import { parseMoment } from '../util/algorithm';
import { CloudRunWithCache } from './config';
import dataExplorer from './data-explorer';

const loadSystemPage = async () => {
  try {
    return await dataExplorer.searchAll("SystemPage", null, "pageName");
  } catch (error) {
    console.error("get system page failed", error);
    throw error;
  }
};

const setPageAction = (p, policies) => {
  const selectedActions = p?.extraParams?.selectedActions ?  p?.extraParams?.selectedActions : null;
  const actions = []
  if (selectedActions) {
    for (const element of selectedActions) {
      for (const po of policies) {
        if (element.actionKey === po.actionKey) {
          element.data = po.data;
          element.versionStamp = po.versionStamp;
        }
      }
    }
  }
  return actions;
}

const getSystemPage = async (isPreview, pageKey) => {
  try {
    const pageData = await dataExplorer.getActiveByKey("SystemPage", pageKey, isPreview);
    const policies = await getSystemPageActionPolicies(isPreview, pageKey);
    setPageAction(pageData, policies);
    return pageData;
  } catch (error) {
    console.error("get system page failed", error);
    return error;
  }
};

const getSystemPageVersions = async (pageKey) => {
  try {
    return await dataExplorer.getAllByKey("SystemPage", pageKey);
  } catch (error) {
    console.error("get system page failed", error);
    throw error;
  }
};

const saveSystemPage = async (pageKey, page, isNew) => {
  try {
    return await dataExplorer.saveByKey("SystemPage", pageKey, page, isNew);
  } catch (error) {
    console.error("get system page failed", error);
    throw error;
  }
};

const deleteSystemPage = async (page) => {
  try {
    return await dataExplorer.destory("SystemPage", page);
  } catch (error) {
    console.error("get system page failed", error);
    throw error;
  }
};

const getSystemPageActionPolicies = async (isPreview, pageKey) => {
  try {
    const param = {
      pagekey: pageKey,
      isPreview: isPreview,
    };
    const nocache = isPreview ? true : false;
    return await CloudRunWithCache("getSystemPageActionPolicies", param, nocache);
  } catch (error) {
    console.error("get system page action policies failed", error);
    return [];
  }
}

const processSystemPageDataChange = async (isPreview, pageKey, page, oldPage) => {
  try {
    const parsedPage = JSON.parse(JSON.stringify(page));
    const parsedOldPage = oldPage ? JSON.parse(JSON.stringify(oldPage)) : undefined;
    const param = {
      pagekey: pageKey,
      page: parsedPage,
      oldPage: parsedOldPage,
      isPreview: isPreview,
    };
    const json = await CloudRunWithCache("processSystemPageDataChange", param, true);
    return parseMoment(json);
  } catch (error) {
    console.error("process system page change failed", error);
    throw error;
  }
}

const pageApi = {
  loadSystemPage,
  getSystemPage,
  getSystemPageVersions,
  saveSystemPage,
  deleteSystemPage,
  getSystemPageActionPolicies,
  processSystemPageDataChange,
}

export default pageApi;