import { useEditor, useNode } from "@craftjs/core";
import { Checkbox, Col, InputNumber, Switch } from "antd";
import { Form, Input, Select } from "components/Form";
import React, { useEffect, useRef, useState } from "react";
import { IoMdSwitch } from "react-icons/io";
import IntlMessages from "util/IntlMessages";
import { AccountStore } from "../../../../constants/Account";
import { useLbl } from "../../../../lngProvider";
import { isEmpty } from "../../../../util/algorithm";
import {
  colSls, convertRules, convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate, updateTableCell
} from "./common";
import { settingsSignal } from "../../../../util/signal";

const SfSwitchSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="checkedChildren" label="label 1">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="unCheckedChildren" label="label 2">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="checkbox" label="checkbox" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="initialValue" label="initial value">
        <Select className="item-property" allowClear>
          <Select.Option value={true}>true</Select.Option>
          <Select.Option value={false}>false</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="size" label="size">
        <Select className="item-property">
          <Select.Option value="default">default</Select.Option>
          <Select.Option value="small">small</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hidden" label="hidden" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="editInTable" label="edit col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="exclusive" label="exclusive" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const MySwitch = ({formKey, itemKey, checked, noupdate, disabled, checkbox, initialValue, onChange, readOnly, listType, isUseCode, ...props}) => {
  const ref = useRef();
  const [myChecked, setMyChecked] = useState(checked);
  const locale = settingsSignal.locale;
  const lbl = useLbl(locale);
  const initialRef = useRef();
  useEffect(() => {
    setMyChecked(checked);
    if (initialRef.current) {
      clearTimeout(initialRef.current);
      initialRef.current = null;
    }
    initialRef.current = setTimeout(() => {
      if (typeof checked === 'undefined' &&
          typeof initialValue !== 'undefined') {
        if (onChange) {
          onChange(!!initialValue)
        }
      }
    }, AccountStore.ON_CHANGE_DELAY)
  }, [checked, initialValue, onChange])
  const onMyCheckboxChange = (event) => {
    if (readOnly) return;
    const checked = event.target.checked;
    onMyChange(checked, event);
  }
  const onMyChange = (value, event) => {
    if (readOnly) return;
    setMyChecked(value);
    if (onChange) onChange(value, event);
  }
  if (ref.current === undefined) ref.current = noupdate && checked !== undefined;
  if (checkbox) {
    return <Checkbox checked={!!myChecked} disabled={disabled || ref.current}
      onChange={onMyCheckboxChange}/>

  } else {
    let checkedChildren = undefined;
    let unCheckedChildren = undefined;
    if (!isEmpty(props.checkedChildren)) checkedChildren = lbl(`form.${formKey}.${itemKey}.checked` ,props.checkedChildren)
    if (!isEmpty(props.unCheckedChildren)) unCheckedChildren = lbl(`form.${formKey}.${itemKey}.unchecked` ,props.unCheckedChildren)
    return <Switch checked={!!myChecked} disabled={disabled || ref.current}
      onChange={onMyChange} checkedChildren={checkedChildren} unCheckedChildren={unCheckedChildren}/>
  }
}

export const SfSwitch = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpSwitch doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const SfpSwitch = ({
  formKey, doRef, form, condistyles, className, style, dbtn, hidden, hideInTable, editInTable, exclusive, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, volitate, skipcopy,
  inputref, styleStr, children, ...otherProps }) => {
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const [fxr, setFxr] = useState({});
  const newRules = convertRules(rules);
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name} label={title}
              shouldUpdate={fx} hidden={fxr.hidden || (!doRef && hidden)} rules={fxr.hidden || hidden || otherProps.disabled ? null : newRules}
              labelAlign={labelAlign} labelCol={lcs} wrap valuePropName="checked">
              <MySwitch formKey={formKey} inputref={inputref} style={sls} {...otherProps} itemKey={itemKey}/>
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

const MySwitchRenderer = ({checked, checkbox, record, index, form, tableKey, onRowChange, editInTable, exclusive, itemKey, tableColWidth, disabled, checkedChildren, unCheckedChildren}) => {
  const leditInTable = editInTable && !disabled;
  const [myValue, setMyValue] = useState(checked);
  useEffect(() => {
    setMyValue(checked);
  }, [checked])
  const onClick = (value, event) => {
    if (!event) event = value;
    const newMyValue = !myValue;
    setMyValue(newMyValue);
    updateTableCell({form, tableKey, record, itemKey, myValue: newMyValue, onRowChange, event, index, exclusive});
  }
  const onChange = (newMyValue, event) => {
    setMyValue(newMyValue);
    updateTableCell({form, tableKey, record, itemKey, myValue: newMyValue, onRowChange, event, index, exclusive});
  }
  const props = {checked: !!myValue, checkbox, disabled, checkedChildren, unCheckedChildren, onChange};
  if (leditInTable) {
    props.onClick = onClick;
  } else {
    props.readOnly = true;
  }
  return <MySwitch {...props}/>
}

SfpSwitch.render = ({itemKey, checkedChildren, unCheckedChildren, editInTable, exclusive, tableColWidth, disabled, checkbox}, form, tableKey, onRowChange) => (checked, record, index) => {
  const props = {checked, checkbox, record, index, form, tableKey, onRowChange, editInTable, exclusive, itemKey, tableColWidth, disabled, checkedChildren, unCheckedChildren};
  return <MySwitchRenderer {...props} />
}

SfpSwitch.renderHeader = ({itemKey, checkedChildren, unCheckedChildren, editInTable, exclusive, tableColWidth, disabled, checkbox}, form, tableKey, onRowChange) => {
  if (checkbox && editInTable && !exclusive) {
    return <Checkbox onChange={(event) => {
      const list = form.getFieldValue(tableKey) || [];
      const newMyValue = !!event.target.checked;
      for(var index = 0; index < list.length; index++) {
        const record = list[index]
        updateTableCell({form, tableKey, record, itemKey, myValue: newMyValue, onRowChange, event, index, exclusive});
      }
      if (onRowChange) onRowChange()
    }}/>
  } else {
    return null;
  }
}

SfSwitch.craft = {
  displayName: "Switch",
  related: {
    settings: SfSwitchSetting
  }
}

SfSwitch.validate = (props, {parents, container, extraParams, formData}) => {
  props.formKey = formData.formKey;
  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "bool",
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      checkbox: props.checkbox,
      checkedChildren: props.checkedChildren,
      unCheckedChildren: props.unCheckedChildren,
      sortable: true,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
    });
  }
}

SfSwitch.isSearchable = true;
SfSwitch.isRulable = true;

registerComponent({
  key: "SfSwitch",
  component: SfSwitch,
  runtimeComponent: SfpSwitch,
  template: <SfSwitch itemKey={getId('switch')} className="sf-switch wrap"
    title={"Switch"} span={24} labelColStr="span:6" />,
  title: <IntlMessages id="system.form.library.switch" text="Switch" />,
  icon: <IoMdSwitch  className="react-icons icon-io"/>,
  type: "Component",
  sequence: 9,
});

export default SfSwitch;