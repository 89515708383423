import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Switch } from "antd";
import { Form, FormContext, Input, Select } from "components/Form";
import moment from "moment";
import React, {useState} from "react";
import { GrDocumentTime } from "react-icons/gr";
import IntlMessages from "util/IntlMessages";
import {
  colSls, convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate
} from "./common";
import { MyDatePicker } from "./SfDate";

const SfSystemDateSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="format" label="format">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="forCreate" label="for create" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="forUpdate" label="for update" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="size" label="size">
        <Select className="item-property">
          <Select.Option value="large">large</Select.Option>
          <Select.Option value="middle">middle</Select.Option>
          <Select.Option value="small">small</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="bordered" label="bordered" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hidden" label="hidden" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfSystemDate = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpSystemDate doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const SfpSystemDate = ({
  doRef, form, condistyles, className, style, dbtn, hidden, hideInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, volitate, skipcopy,
  forCreate, forUpdate, inputref, styleStr, ...otherProps }) => {
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const [fxr, setFxr] = useState({});

  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
        return (
          <FormContext.Consumer>
            {(formCtx) => {
              const objectId = formCtx?.formInstance?.getFieldValue(["objectId"]);
              const initialValue = ((forCreate && !objectId) || (forUpdate && objectId)) ? moment() : null;
              return (
                <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
                <Form.Item name={name} label={title}
                  shouldUpdate={fx} hidden={fxr.hidden || (!doRef && hidden)}
                  labelAlign={labelAlign} labelCol={lcs} initialValue={initialValue} wrap>
                  <MyDatePicker inputref={inputref} style={sls} disabled
                  popupStyle={{display:"none"}} inputReadOnly={true} allowClear={false}
                  {...otherProps}/>
                </Form.Item>
                {dbtn}
              </Col>
              )
            }}
          </FormContext.Consumer>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

const formatValue = (value, format) => {
  return value.format ? value.format(format) : moment(value).format(format)
}

SfpSystemDate.render = ({itemKey,format}) => (value, record, index) => {
  return <span key={itemKey+"_"+index}>{value ? formatValue(value, format) : value}</span>;
}

SfSystemDate.craft = {
  displayName: "System Date",
  related: {
    settings: SfSystemDateSetting
  }
}

SfSystemDate.validate = (props, {parents, container, extraParams}) => {
  const itemKey = props.itemKey;
  const forCreate = props.forCreate;
  const forUpdate = props.forUpdate;
  if (props.skipcopy === undefined) props.skipcopy = true;
  if (!forCreate && !forUpdate) {
    return <IntlMessages id="system.form.validate.system-date-create-or-update" text="item ({itemKey}) - for create / for update is not checked." values={{ itemKey: itemKey }} />
  }

  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "date",
      picker: props.picker,
      format: props.format,
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      sortable: true,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
    });
  }
}

SfSystemDate.isSearchable = true;

registerComponent({
  key:"SfSystemDate",
  component: SfSystemDate,
  runtimeComponent: SfpSystemDate,
  template: <SfSystemDate itemKey={getId('systemdate')} className="sf-systemdate wrap"
            title={"System Date"} format="YYYY-MM-DD HH:mm:ss" span={24} labelColStr="span:6" placeholder=""/>,
  title: <IntlMessages id="system.form.library.system-date" text="System Date" />,
  icon: <GrDocumentTime  className="react-icons icon-gr"/>,
  type: "Component",
  subtype: "sys",
  sequence: 3,
});

export default SfSystemDate;