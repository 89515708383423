import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Switch } from "antd";
import { Form, Input, Select } from "components/Form";
import React, {useEffect, useState, useRef} from "react";
import IntlMessages from "util/IntlMessages";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting, syncPageState, updatePageState } from "./common";
import { getArrayElementByAttribute, log } from "util/algorithm";
import { selectApi } from "parse-api";
import { SystemTransfer } from "../../FormSetup/components/SfTransfer";
import { BsArrowLeftRight } from "react-icons/bs";
import { AccountStore } from "../../../../constants/Account";

const SpTransferSetting = () => {
  const [systemSelectList, setSystemSelectList] = useState([]);
  const [selects, setSelects] = useState([]);
  const [selected, setSelected] = useState({});
  const [filter, setFilter] = useState([]);
  const selectedKey = useRef();
  const mounted = useRef();

  useEffect(() => {
    mounted.current = true;
    const fetchData = async () => {
      const list = await selectApi.loadSystemSelect();
      setSystemSelectList(list);
      // convert to option
      const options = list.map(l => {
        return {
          value: l.selectKey,
          label: l.name,
        }
      })
      if (mounted.current) setSelects(options);
    }
    fetchData();
    return () => {
      mounted.current = false;
    }
  }, [])

  const refreshOptionTypes = () => {
    if (selectedKey.current && systemSelectList?.length > 0) {
      if (selected && selected?.selectKey !== selectedKey.current) {
        log("refreshOptionTypes", selected?.selectKey, selectedKey.current);
        const sel = getArrayElementByAttribute(systemSelectList, "selectKey", selectedKey.current);
        setSelected(sel);
        if (sel && sel.optionTypes) {
          if (mounted.current) setFilter(sel.optionTypes.map(f => ({label:f, value:f})));
        } else {
          if (mounted.current) setFilter([]);
        }
      }
    }
  }

  const onSelectKeyChange = (value) => {
    refreshOptionTypes();
    selectedKey.current = value;
  }

  const SelectKey = ({value, onChange, ...props}) => {
    useEffect(()=> {
      onSelectKeyChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[value])
    return <Select {...props} value={value} onChange={onChange} />
  }

  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="defaultValue" label="default value">
        <Input className="item-property"/>
      </Form.Item>
      <Form.Item name="selectkey" label="select">
        <SelectKey className="item-property" options={selects} allowClear showSearch />
      </Form.Item>
      <Form.Item name="filter" label="filter">
        <Select className="item-property" options={filter} allowClear/>
      </Form.Item>
      <Form.Item name="parentkeys" label="parents">
        <Select className="item-property" mode="tags" />
      </Form.Item>
      <Form.Item name="isUseCode" label="use code" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="sourceTitle" label="source">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="targetTitle" label="target">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="showSearch" label="show search" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="pageSize" label="page size">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="listStyleStr" label="list style">
        <Input className="item-property"/>
      </Form.Item>
      <Form.Item name="responsive" label="responsive" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  )
}

export const SpTransfer = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppTransfer doRef={ref => connect(drag(ref))} dbtn={dbtn} {...props} selectedStyle={style} />
  )
}

export const SppTransfer = ({
  doRef, dbtn, selectedStyle,
  pageKey, itemKey, pageState, setPageState, value,  defaultValue,
  selectkey, filter, parentkeys, isUseCode, sourceTitle, targetTitle, showSearch, pageSize, listStyleStr, disabled, responsive,
  ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}
  const inputProps = {selectkey, filter, parentkeys, isUseCode, sourceTitle, targetTitle, showSearch, pageSize, listStyleStr, disabled, responsive};
  const [myValue, setMyValue] = useState(defaultValue);
  const [parentValues, setParentValues] = useState([]);
  const timer = useRef();

  let sourceTitleLabel = sourceTitle;
  let targetTitleLabel = targetTitle;
  if (pageKey) {
    sourceTitleLabel = <IntlMessages id={`page.${pageKey}.${itemKey}.source.title`} text={sourceTitle}/>;
    targetTitleLabel = <IntlMessages id={`form.${pageKey}.${itemKey}.target.title`} text={targetTitle}/>;
  }

  useEffect(() => {
    syncPageState({itemKey, myValue, setMyValue, pageState, setPageState})
    if (pageState && parentkeys) {
      const parentValues = parentkeys.map(key => pageState[key]);
      setParentValues(parentValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[itemKey, myValue, pageState, setPageState])

  const onMyChange = (myValue) => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      updatePageState({itemKey, myValue, setMyValue, pageState, setPageState})
    }, AccountStore.ON_CHANGE_DELAY);
  }

  return (
    <Col ref={doRef} {...props}>
      <SystemTransfer targetKeys={myValue} onChange={onMyChange}
        parentValues={parentValues}
        titles={[sourceTitleLabel, targetTitleLabel]}
        {...inputProps} />
      {dbtn}
    </Col>
  );
}
SppTransfer.enablePageState = true;

SpTransfer.craft = {
  displayName: "Transfer",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpTransferSetting
  }
}

registerComponent({
  key: "SpTransfer",
  component: SpTransfer,
  runtimeComponent: SppTransfer,
  template: <SpTransfer itemKey={getId('transfer')} className="sp-transfer" title={"Transfer"} span={24} sourceTitle="Available" targetTitle="Assigned"/>,
  title: <IntlMessages id="system.page.library.transfer" text="Transfer" />,
  icon: <BsArrowLeftRight  className="react-icons icon-bs"/>,
  type: "Component",
  subtype: "input",
  sequence: 7,
});

export default SpTransfer;