import React from "react";
import {Link} from "react-router-dom";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import MenuIcon from "./MenuIcon";
import { settingsSignal, systemSignal } from "../../util/signal";

const SidebarLogo = ({sidebarCollapsed, setSidebarCollapsed}) => {
  const {width, themeType} = settingsSignal;
  let navStyle = settingsSignal.navStyle;
  const { baseUrl, logo } = systemSignal;
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }

  let _logo = null;
  if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR && width >= TAB_SIZE) {
    _logo = <img alt="lo" src={(logo)}/>
  } else if (themeType === THEME_TYPE_LITE) {
    _logo = <img alt="logo1" src={(baseUrl+"/assets/images/logo-white.png")}/>
  } else {
    _logo = <img alt="logo2" src={(logo)}/>
  }

  return (
    <div className="gx-layout-sider-header">
      {(navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR)
       ? <MenuIcon
          sidebarCollapsed={sidebarCollapsed}
          setSidebarCollapsed={setSidebarCollapsed}/>
       : null}

      <Link to="/home" className="gx-site-logo">
        {_logo}
      </Link>
    </div>
  );
};

export default SidebarLogo;
