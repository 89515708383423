import { equals, getLocalStorageItemObject, setLocalStorageItemObject, uid } from "./algorithm";

class Signal {
  constructor(state, name, debug) {
    this.debug = debug;
    this.name = name;
    this.state = state;
    this.stateDelta = {};
    this.stateDeltaOld = {};
    this.stateDeltaUpdated = false;
    this.markUpdated = this.markUpdated.bind(this);
    this.sendUpdates = this.sendUpdates.bind(this);
    this.waitFor = this.waitFor.bind(this);
    this.register = this.register.bind(this);
    this.unregister = this.unregister.bind(this);
    this.stateProxyHandler = {
      get: this.get.bind(this),
      set: this.set.bind(this)
    };
    this.stateProxy = new Proxy(this.state, this.stateProxyHandler);
    this.listeners = {};
    this.waitingQueue = [];
  }

  markUpdated(prop) {
    this.stateDelta[prop] = this.stateProxy[prop];
    this.stateDeltaUpdated = true;
  }

  get(obj, prop) {
    if (prop === 'waitFor') {
      return this.waitFor;
    } else if (prop === 'sendUpdates') {
      return this.sendUpdates;
    } else if (prop === 'register') {
      return this.register;
    } else if (prop === 'unregister') {
      return this.unregister;
    }
    return obj[prop];
  }

  set(obj, prop, value) {
    const oldValue = obj[prop];
    obj[prop] = value;
    if (!equals(oldValue, value)) {
      if (!this.stateDeltaOld[prop]) {
        this.stateDeltaOld[prop] = oldValue;
      }
      this.stateDelta[prop] = value;
      this.stateDeltaUpdated = true;
      // if (this.debug) console.log(`signal ${this.name} updated ${prop} => ${value}`)
    }
    if (!obj.pauseUpdates) {
      requestAnimationFrame(() => {
        this.sendUpdates();
      })
    }
    return true;
  }

  sendUpdates() {
    if (this.stateDeltaUpdated) {
      const newValue = this.stateDelta;
      const oldValues = this.stateDeltaOld;
      this.stateDelta = {};
      this.stateDeltaOld = {};
      this.stateDeltaUpdated = false;

      this.fire(oldValues, newValue);
    }
  }

  resetUpdates() {
    this.stateDelta = {};
    this.stateDeltaOld = {};
    this.stateDeltaUpdated = false;
  }

  fire(oldValue, newValue) {
    Object.values(this.listeners).forEach(fn => {
      if (typeof fn === 'function') {
        fn(oldValue, newValue)
      }
    })
    this.waitingQueue.forEach(({resolve, key, value}) => {
      if (value !== undefined) {
        if (newValue?.[key] === value) {
          console.timeLog(this.name, `Successfully wait for ${this.name}.${key} to be ${value}!`);
          resolve(true);
        }
      } else {
        if (newValue?.[key] !== undefined && newValue?.[key] !== null) {
          console.timeLog(this.name, `Successfully wait for ${this.name}.${key} to be defined!`);
          resolve(true);
        }
      }
    })
  }

  register(key, fn) {
    this.listeners[key] = fn;
  }

  unregister(key) {
    this.listeners[key] = undefined;
    delete this.listeners[key];
  }

  addWaitQueue(resolve, key, value) {
    this.waitingQueue.push({resolve, key, value})
  }

  removeWaitQueue(resolve) {
    const index = this.waitingQueue.findIndex(q => q.resolve === resolve);
    if (index !== -1) {
      this.waitingQueue.splice(index, 1);
    }
  }

  async waitFor(key, value, timeout = 30000) {
    if (value !== undefined) {
      if (this.state[key] === value) {
        return true;
      }
    } else {
      if (this.state[key] !== undefined && this.state[key] !== null) {
        return true;
      }
    }
    console.time(this.name)
    return new Promise((resolve, reject) => {
      this.addWaitQueue(resolve, key, value);
      if (timeout > 0) {
        setTimeout(() => {
          this.removeWaitQueue(resolve);
          reject(new Error(`Waiting for ${this.name}.${key} timed-out!`));
        }, timeout);
      }
    })
  }
}

const SignalStore = {}
const SignalStorePrefix = "SignalStore_";

export const signal = (name, target, persist) => {
  let snl = name ? SignalStore[name] : null;
  if (!snl) {
    const targetType = typeof target;
    if (targetType !== 'object') throw Error(`Target type not supported - ${targetType}!`);

    snl = new Signal(target, name, true);
    SignalStore[name] = snl;

    if (persist) {
      const storedValue = getLocalStorageItemObject(SignalStorePrefix + name);
      if (storedValue) {
        for (const key in storedValue) {
          snl.stateProxy[key] = storedValue[key];
        }
        snl.resetUpdates();
      }

      snl.register('saveToLocalStorage', () => {
        setLocalStorageItemObject(SignalStorePrefix + name, this.state)
      });
    }
  }

  return snl.stateProxy;
}

export const destorySignal = (name) => {
  let snl = name ? SignalStore[name] : null;
  if (snl) {
    SignalStore[name] = null;
  }
}

export const windowIdentitySignal = signal('windowIdentitySignal', {
  value: uid(),
  installationId: null,
});
export const parseServerInitializedSignal = signal('parseServerInitialized', {value: false});
export const clientConfigInitializedSignal = signal('clientConfigInitialized', {value: false});
export const keycloakInitializedSignal = signal('keycloakInitialized', {
  value: false,
  processing: false,
});

export const commonSignal = signal('common', {});
export const systemSignal = signal('system', {});
export const settingsSignal = signal('settings', {});
export const authSignal = signal('auth', {});
export const translateSignal = signal('translate', {});
export const scriptsSignal = signal('scripts', {});

export const isParseSDKInitialized = () => parseServerInitializedSignal.value;

export const tableDatachangedIndicators = signal('tableDatachangedIndicators', {});
