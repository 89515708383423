import languageData from "lngProvider/languageData";
import moment from "moment";
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-hk';
import { SWITCH_LANGUAGE } from "../../constants/ActionTypes";
import {
  LAYOUT_TYPE,
  LAYOUT_TYPE_FULL,
  NAV_STYLE,
  NAV_STYLE_FIXED,
  THEME_COLOR,
  THEME_TYPE,
  THEME_TYPE_SEMI_DARK, UPDATE_RTL_STATUS
} from "../../constants/ThemeSetting";
import { getUserLocale } from "../../lngProvider";
import { MyCustomEvent, getLocalStorageItemObject, urlparam } from "../../util/algorithm";
import { settingsSignal } from "../../util/signal";

const getLanguage = (languageId) => {
  const langs = getLocalStorageItemObject('language_data', languageData);
  if (langs) {
    let found = null;
    langs.forEach(l => {
      if (l.languageId === languageId) {
        found = l;
      }
    });
    return found;
  } else {
    return null;
  }
}

const initialSettings = {
  navStyle: NAV_STYLE_FIXED,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_SEMI_DARK,
  themeColor: '',
  isDirectionRTL: false,
  locale: getLanguage(urlparam('lang')) ? getLanguage(urlparam('lang')) : getUserLocale(),
};
moment.locale(initialSettings.locale.moment);
export const GlobalSettingsEevent = new MyCustomEvent();
const globalSettingHandler = {
  set(obj, prop, value) {
    console.log('global settings set', obj, prop, value)
    obj[prop] = value
    if (prop === 'url') {
      obj.isReady = true
    }
    GlobalSettingsEevent.fire();
    return true
  }
}

export const GlobalSettings = new Proxy(initialSettings, globalSettingHandler)

const SettingsReducer = (state = initialSettings, action) => {
  let newState = state;
  switch (action.type) {
    case THEME_TYPE:
      newState = {
        ...state,
        themeType: action.themeType
      };
      break;
    case THEME_COLOR:
      newState = {
        ...state,
        themeColor: action.themeColor
      };
      break;
    case UPDATE_RTL_STATUS:
      newState = {
        ...state,
        isDirectionRTL: action.rtlStatus
      };
      break;
    case NAV_STYLE:
      newState = {
        ...state,
        navStyle: action.navStyle
      };
      break;
    case LAYOUT_TYPE:
      newState = {
        ...state,
        layoutType: action.layoutType
      };
      break;
    case SWITCH_LANGUAGE:
      const newLocale = action.payload ? action.payload : (state.locale || {});
      GlobalSettings.locale = newLocale;
      newState = {
        ...state,
        locale: newLocale,
      };
      break;
    default:
      newState = state;
  }
  Object.assign(settingsSignal, newState);
  return newState;
};

export default SettingsReducer;
