import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Switch } from "antd";
import { Form, Input } from "components/Form";
import React, { useEffect, useRef, useState } from "react";
import { BsTextareaT } from "react-icons/bs";
import IntlMessages from "util/IntlMessages";
import { AccountStore } from "../../../../constants/Account";
import {
  colSls, convertRules, convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector,
  registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate, updateTableCell
} from "./common";

const SfTextAreaSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="autoSize" label="auto size" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="bordered" label="bordered" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="maxLength" label="max len">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="rows" label="rows">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="allowClear" label="allow clear" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="showCount" label="show count" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="translate" label="translate" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="readOnly" label="read-only" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="editInTable" label="edit col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const MyTextArea = ({value, noupdate, disabled, onChange, triggerOnChange, ...props}) => {
  const ref = useRef();
  const [myValue, setMyValue] = useState("");
  useEffect(() => {
    setMyValue(value);
  },[value])
  const onMyChangeRef = useRef();
  const onMyChange = (event) => {
    setMyValue(event.target.value);
    if (triggerOnChange && onChange) {
      onChange(event);
    } else if (onChange) {
      if (onMyChangeRef.current) {
        clearTimeout(onMyChangeRef.current);
      }
      onMyChangeRef.current = setTimeout(() => {
        onChange(event);
      }, AccountStore.ON_CHANGE_DELAY);
    }
  }
  if (ref.current === undefined) ref.current = noupdate && value !== undefined;
  return <Input.TextArea value={myValue} disabled={disabled || ref.current}
      onChange={onMyChange} {...props}/>
}

export const SfTextArea = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpTextArea doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const SfpTextArea = ({
  doRef, form, condistyles, className, style, dbtn, hideInTable, editInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, translate, volitate, skipcopy,
  inputref, styleStr, children, ...otherProps }) => {
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const [fxr, setFxr] = useState({style});
  const newRules = convertRules(rules);
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name} label={title}
              shouldUpdate={fx} hidden={fxr.hidden} rules={fxr.hidden || otherProps.disabled ? null : newRules}
              labelAlign={labelAlign} labelCol={lcs} wrap>
              <MyTextArea inputref={inputref} style={sls} {...otherProps} />
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

const TextAreaRenderer = ({value, record, index, form, tableKey, onRowChange, editInTable, itemKey, tableColWidth, disabled, maxLength, showCount, autoSize, rows}) => {
  const [editing, setEditing] = useState(false);
  const [myValue, setMyValue] = useState(value);
  const leditInTable = editInTable && !disabled;
  const ref = useRef();
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, [])
  useEffect(() => {
    setMyValue(value);
  }, [value]);
  const onClick = (event) => {
    setEditing(true);
    event.preventDefault();
    event.stopPropagation();
  }
  if (editing) {
    const onChange = (event) => {
      try {
        setMyValue(event.target.value);
      } catch (e) {
        console.error(e);
      }
    }
    const onBlur = (event) => {
      updateTableCell({form, tableKey, record, itemKey, myValue, onRowChange, setEditing, event, index});
    }
    const onKeyDown = (e) => {
      if (e.keyCode === 13 && (e?.ctrlKey || e?.metaKey)) {
        e.preventDefault();
        e.stopPropagation();
        ref.current.blur();
      }
    }
    const props = {value: myValue, onBlur, onChange, ref, onKeyDown, maxLength, showCount, autoSize, rows};
    if (tableColWidth) props.style = {"minWidth": `${tableColWidth}px`,"maxWidth": `${tableColWidth}px`}
    return <Input.TextArea autoFocus {...props} />
  } else {
    const editingProps = leditInTable ? {className: "row-editable table-cell-textarea", onClick} : {className: "table-cell-textarea"};
    editingProps.style = {};
    if (tableColWidth) editingProps.style = {...editingProps.style, "minWidth": `${tableColWidth}px`, "maxWidth": `${tableColWidth}px`}
    if (rows) editingProps.style = {...editingProps.style, "maxHeight": (rows + 1) + "rem", overflow: "scroll"}
    return <span {...editingProps} >{myValue}</span>;
  }
}

SfpTextArea.render = ({ systemFormatter, editInTable, itemKey, tableColWidth, disabled, maxLength, showCount, autoSize, rows }, form, tableKey, onRowChange) => (value, record, index) => {
  const props = {value, record, index, form, tableKey, onRowChange, editInTable, itemKey, tableColWidth, disabled, maxLength, showCount, autoSize, rows};
  return <TextAreaRenderer {...props}/>
}

SfTextArea.craft = {
  displayName: "Text Area",
  related: {
    settings: SfTextAreaSetting
  }
}

SfTextArea.validate = (props, {parents, container, extraParams}) => {
  const itemKey = props.itemKey;
  const title = props.title;
  if (container.type.resolvedName !== "SfMainPanel") {
    if (props.translate) {
      return <IntlMessages id="system.form.validate.non-main-panel-translate" text="item ({itemKey}) - input translate is only allowed in main panel." values={{itemKey:itemKey}}/>
    }
  }
  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "string",
      props: {
        rows: props.rows
      },
      typeRender: "textarea",
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      sortable: true,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
      translate: props.translate,
    });
    if (props.translate) {
      extraParams.translate.enabled = true;
      extraParams.translate.items.push({
        key:itemKey,
        type: 'textarea',
        label: title,
        index: Object.keys(parents).indexOf(props.itemKey)
      })
    }
  }
}

SfTextArea.isSearchable = true;
SfTextArea.isRulable = true;

registerComponent({
  key:"SfTextArea",
  component: SfTextArea,
  runtimeComponent: SfpTextArea,
  template: <SfTextArea itemKey={getId('textarea')} className="sf-textarea wrap"
            title={"Text Area"} span={24} labelColStr="span:6" hideInTable={true}/>,
  title: <IntlMessages id="system.form.library.textarea" text="Text Area" />,
  icon: <BsTextareaT  className="react-icons icon-bs"/>,
  type: "Component",
  sequence: 13,
});

export default SfTextArea;