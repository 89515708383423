import { Collapse, Space } from "antd";
import React, {
  Children,
  cloneElement,
  useEffect,
  useRef,
  useState,
} from "react";
import Draggable from "react-draggable";
import { AiOutlineColumnWidth } from "react-icons/ai";
import { BiDockRight, BiWindows } from "react-icons/bi";
import { getLocalStorageItemObject, setLocalStorageItemObject } from "../../util/algorithm";

const DEFAULT_SETTINGS = {
  sequences: [],
  undocked: {},
  minimized: {},
  narrowed: {},
  position: {},
};

const saveDockSettings = (name, setSetting, key, value) => {
  setSetting((oldSettings) => {
    const results = { ...oldSettings, [key]: value };
    setLocalStorageItemObject("dockable_settings." + name, results);
    return results;
  });
};

const getDockSettings = (name) => {
  const json = getLocalStorageItemObject("dockable_settings." + name);
  let settings = { ...DEFAULT_SETTINGS };
  if (json) {
    settings = { ...DEFAULT_SETTINGS, ...json };
  }
  return settings;
};

const updateDockedCount = (children, settings, setDockedCount) => {
  let dockedCount = 0;
  const arrayChildren = Children.toArray(children);
  Children.forEach(arrayChildren, (child, index) => {
    const key = child.props.className;
    const isUndocked = settings.undocked?.[key];
    if (!isUndocked) {
      dockedCount++;
    }
  });
  if (setDockedCount) setDockedCount(dockedCount);
};

const UndockedWindow = ({
  children,
  className,
  header,
  onDockedChange,
  onMinimizedChange,
  onNarrowedChange,
  onClick,
  settings,
  windowName,
  style,
  extraButtons,
  ...props
}) => {
  const narrowed = (settings.narrowed||{})[windowName]
  const newStyle = style ? {...style} : {};
  if (narrowed === 0) {
    newStyle.width = "380px"
  } else if (narrowed === 1) {
    newStyle.width = "290px"
  } else if (narrowed === 2) {
    newStyle.width = "200px"
  } else if (narrowed === 3) {
    newStyle.width = "120px"
  }
  const handleClick = (event) => {
    onClick(windowName, event);
  }
  return (
    <div
      className={
        "ant-collapse ant-collapse-icon-position-left dockable-window " +
        className
      }
      style={newStyle}
      {...props}
    >
      <div className="ant-collapse-item">
        <div
          className="ant-collapse-header dockable-handler"
          role="button"
          tabIndex={0}
          aria-expanded="true"
          onClick={(event) => handleClick(event)}
          onTouchEnd={(event)=> handleClick(event)}
        >
          {header}
          <div className="ant-collapse-extra">
            <Space>
              {extraButtons}
              <AiOutlineColumnWidth
                onClick={(event) => {
                  // If you don't want click extra trigger collapse, you can prevent this:
                  event.stopPropagation();
                  onNarrowedChange(windowName);
                }}
              />
              <BiDockRight
                onClick={(event) => {
                  // If you don't want click extra trigger collapse, you can prevent this:
                  event.stopPropagation();
                  onDockedChange(windowName);
                }}
              />
            </Space>
          </div>
        </div>
        <div
          className={
            "ant-collapse-content " +
            ((settings.minimized||{})[windowName]
              ? "ant-collapse-content-inactive ant-collapse-content-hidden"
              : "ant-collapse-content-active")
          }
        >
          <div className="ant-collapse-content-box">{children}</div>
        </div>
      </div>
    </div>
  );
};

export const DockableCollapse = ({
  name,
  children,
  activeKey,
  onChange,
  setDockedCount,
  maxNarrowLevel
}) => {
  const arrayChildren = Children.toArray(children);
  const [settings, setSettings] = useState(getDockSettings(name));
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  useEffect(() => {
    updateDockedCount(children, settings, setDockedCount);
  }, [children, setDockedCount, settings]);
  const doRearrange = (key) => {
    const newSequences = (settings.sequences || []).filter((k) => k !== key);
    newSequences.push(key);
    if (mounted.current)
      saveDockSettings(name, setSettings, "sequences", newSequences);
  };
  const onDockedChange = (key) => {
    const newUndocked = {
      ...(settings.undocked || {}),
      [key]: !settings.undocked?.[key],
    };
    if (mounted.current)
      saveDockSettings(name, setSettings, "undocked", newUndocked);
    doRearrange(key);
    updateDockedCount();
  };
  const onMinimizedChange = (key) => {
    const newMinimized = {
      ...(settings.minimized || {}),
      [key]: !settings.minimized?.[key],
    };
    if (mounted.current)
      saveDockSettings(name, setSettings, "minimized", newMinimized);
    doRearrange(key);
  };
  const onNarrowedChange = (key) => {
    let narrowType = 0;
    if (typeof settings.narrowed?.[key] === 'number') {
      narrowType = settings.narrowed[key];
      narrowType++;
      narrowType = narrowType % (maxNarrowLevel?.[key] || 2);
    } else {
      narrowType = 1;
    }
    const newNarrowed = {
      ...(settings.narrowed || {}),
      [key]: narrowType,
    };
    if (mounted.current)
      saveDockSettings(name, setSettings, "narrowed", newNarrowed);
    doRearrange(key);
  }
  const onMove = (key, data) => {
    const newPosition = {
      ...(settings.position || {}),
      [key]: { x: data.x, y: data.y },
    };
    if (mounted.current)
      saveDockSettings(name, setSettings, "position", newPosition);
    doRearrange(key);
  };
  const dockedChildren = [];
  const undockedChildren = [];
  Children.forEach(arrayChildren, (child, index) => {
    const key = child.props.className;
    const isUndocked = settings.undocked?.[key];
    if (isUndocked) {
      undockedChildren.push(child);
    } else {
      dockedChildren.push(child);
    }
  });
  return (
    <>
      {dockedChildren.length > 0 && (
        <Collapse activeKey={activeKey} onChange={onChange}>
          {dockedChildren.map((child, index) => {
            return cloneElement(child, {
              key: child.props.className,
              extra: (
                <Space>
                  {child.props.extraButtons}
                  <BiWindows
                    onClick={(event) => {
                      // If you don't want click extra trigger collapse, you can prevent this:
                      event.stopPropagation();
                      onDockedChange(child.props.className);
                    }}
                  />
                </Space>
              ),
            });
          })}
        </Collapse>
      )}
      {undockedChildren.length > 0 && (
        <div className="dockable-undocked-area">
          {undockedChildren.map((child, index) => {
            const style = {
              zIndex:
                10001 + (settings.sequences || []).indexOf(child.props.className),
            };
            const draggableOpts = {
              handle: ".dockable-handler",
              bounds: "parent",
              grid: [10, 10],
              onStop: (_, data) => onMove(child.props.className, data),
              position: settings.position?.[child.props.className],
            };
            return (
              <Draggable
                key={child.props.className}
                {...draggableOpts}
              >
                <UndockedWindow
                  {...child.props}
                  onDockedChange={onDockedChange}
                  onMinimizedChange={onMinimizedChange}
                  onNarrowedChange={onNarrowedChange}
                  settings={settings}
                  onClick={onMinimizedChange}
                  windowName={child.props.className}
                  style={style}
                  extraButtons={child.props.extraButtons}
                ></UndockedWindow>
              </Draggable>
            );
          })}
        </div>
      )}
    </>
  );
};
