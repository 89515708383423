import { Editor } from "@craftjs/core";
import { Layers } from "@craftjs/layers";
import { Collapse, message } from "antd";
import React, { useState } from "react";
import IntlMessages from "../../../util/IntlMessages";
import { Container, Library, Menu, MenuItem, MenuItemGroup, MenuDivider, SettingsPanel, setupMenu, SubMenu, TheFrame, Topbar } from "./MenuComponent";

const Panel = Collapse.Panel;
const MenuSetup = () => {
  const [editable, setEditable] = useState(false);
  const [activeKey, setActiveKey] = useState(['forminfo']);
  const [api, contextHolder] = message.useMessage();

  const showProperty=()=> {
    if (activeKey.indexOf('property') === -1) {
      setActiveKey([...activeKey, 'property']);
    }
  }

  const onPanelChange=(key)=> {
    setActiveKey(key);
  }

  return (
    <Editor resolver={{ Container, Menu, SubMenu, MenuItem, MenuItemGroup, MenuDivider }} enabled={true}>
      <div className="craft-main-panel-outer">
        <div className="craft-main-panel-top">
          <Topbar editable={editable} setEditable={setEditable} />
        </div>
        <div className="craft-main-panel">
          {contextHolder}

          <div className="craft-main-panel-left menu">
            <div className="craft-menu-frame-panel">
              <div className="inner">
                <div className="gx-app-sidebar ant-layout-sider">
                  <TheFrame editable={editable} messageApi={api} showProperty={showProperty} />
                </div>
              </div>
            </div>
          </div>
          <div className="craft-main-panel-right menu">
            <Collapse activeKey={activeKey} onChange={onPanelChange}  >
              <Panel className="craft-menu-library-panel" header={<IntlMessages id="system.menu.library" text="LIBRARY" />} key="library">
                <Library editable={editable} />
              </Panel>
              <Panel className="craft-menu-layers-panel" header={<IntlMessages id="system.menu.layers" text="LAYERS" />} key="layers">
                {editable && <Layers />}
              </Panel>
              <Panel className="craft-menu-property-panel" header={<IntlMessages id="system.menu.property" text="PROPERTY" />} key="property">
                {editable && <SettingsPanel />}
              </Panel>
            </Collapse>
          </div>
        </div>
      </div>
    </Editor>
  );
};

export {
  setupMenu
};

export default MenuSetup;