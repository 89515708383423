import { replace } from 'connected-react-router';
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { log } from '../../../util/algorithm';
import { FormRuntimeSearchImpl, FormHistoryModal, SelectFormSearch } from './runtimesearchimpl';

export { FormHistoryModal, SelectFormSearch }
const FormRuntimeSearch = ({ match, location }) => {
  const { flow, type, formKey, formView, flowParent } = useParams();
  const dispatch = useDispatch();
  const mounted = useRef();

  useEffect(() => {
    mounted.current = true;
    log('FormRuntimeSearch match location', {match, location})
    return () => {
      mounted.current = false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const dispatchReplace = (path, state) => {
    dispatch(replace(path, state));
  }

  return (
    <FormRuntimeSearchImpl
      match={match}
      location={location}
      flow={flow}
      type={type}
      formKey={formKey}
      formView={formView}
      flowParent={flowParent}
      dispatchReplace={dispatchReplace}
    />
  );
};

export default FormRuntimeSearch;