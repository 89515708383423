import { RiStackshareLine } from "react-icons/ri";
import { useEditor, useNode } from "@craftjs/core";
import classNames from 'classnames';
import { Form, Input, Select } from "components/Form";
import { useEffect, useRef, useState } from "react";
import IntlMessages from 'util/IntlMessages';
import { convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SelectCollector } from "./common";
import { formApi, flowApi, dataExplorer, system } from 'parse-api';
import { log, getArraysIntersection } from "util/algorithm";
import { InputNumber, Switch } from "antd";

const RELACTION_TYPE = ["child-form","child-flow"];
const ACTION_TYPES = ["AddRelationAction", "RemoveRelationAction"];

const SfRelationSetting = () => {
  const { localSelects, mainSelects } = useEditor(SelectCollector);
  const [allForms, setAllForms] = useState([]);
  const [allFlows, setAllFlows] = useState([]);
  const [allActionPolicies, setAllActionPolicies] = useState([]);
  const [selectedForm, setSelectedForm] = useState();
  const [options, setOptions] = useState([]);
  const mounted = useRef();

  useEffect(() => {
    mounted.current = true;
    const fetchData = async () => {
      const roles = await system.getAllRoles();
      const options = roles.map(r => ({label:r.name, value:r.name}));
      if (mounted.current) setOptions(options);
      if (mounted.current) setAllForms(await formApi.loadSystemForm());
      if (mounted.current) setAllFlows(await flowApi.loadSystemFlow());
      const policies = await dataExplorer.searchAll("SystemActionPolicy", null, "actionName");
      setAllActionPolicies(policies.filter(p => getArraysIntersection(p.actionTypes, ACTION_TYPES).length > 0));
    }
    fetchData();
    return () => {
      mounted.current = false;
    }
  },[])

  const RelationType = ({value, onChange, ...props}) => {
    useEffect(()=> {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[value])
    return (
      <Select {...props} value={value} onChange={onChange}>
        {RELACTION_TYPE.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
      </Select>
    );
  }

  const FormKey = ({value, onChange, ...props}) => {
    useEffect(()=> {
      onFormKeyChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[value])
    return (
      <Select {...props} value={value} onChange={onChange}>
        {allForms.map(f => <Select.Option key={f.formKey} value={f.formKey}>{f.formKey}</Select.Option>)}
      </Select>
    );
  }

  const onFormKeyChange = (value) => {
    if (value) {
      allForms.forEach(f => {
        if (f.formKey === value) {
          setSelectedForm(f);
        }
      })
    }
  }

  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="type" label="type">
        <RelationType className="item-property" placeholder="type" allowClear showSearch>
        </RelationType>
      </Form.Item>
      <Form.Item name="form" label="form">
        <FormKey className="item-property" placeholder="form" allowClear showSearch onChange={onFormKeyChange}>
        </FormKey>
      </Form.Item>
      <Form.Item name="view" label="view">
        <Select className="item-property" placeholder="view" allowClear showSearch>
          {selectedForm?.extraParams?.allViews.map(v => <Select.Option key={v} value={v}>{v}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name="flow" label="flow">
        <Select className="item-property" placeholder="flow" allowClear showSearch>
        {allFlows.map(f => <Select.Option key={f.flowKey} value={f.flowKey}>{f.flowKey}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name="parentkeys" label="parents">
        <Select className="item-property" placeholder="fields" options={[...mainSelects, ...localSelects]} allowClear showSearch mode="multiple" />
      </Form.Item>
      <Form.Item name="childkeys" label="childs">
        <Select className="item-property" placeholder="fields" allowClear showSearch mode="multiple" >
          {selectedForm?.extraParams?.dataClassConfig.columns.map(c => <Select.Option key={c.dataIndex} value={c.dataIndex}>{c.dataIndex}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name="delayedsave" label="delayed save" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="actions" label="actions">
        <Select className="item-property" placeholder="actions" allowClear showSearch mode={"multiple"}>
        {allActionPolicies.map(a => <Select.Option key={a.actionKey} value={a.actionKey}>{a.actionName}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name="roles" label="roles">
        <Select mode="multiple" options={options} allowClear></Select>
      </Form.Item>
      <Form.Item name="sort" label="enable sort" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="filter" label="enable filter" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="maxDisplay" label="max display">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="isSelectKey" label="select key" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
    </>
  )
}

export const SfRelation = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpRelation doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const SfpRelation = ({ ...props }) => {
  if (props.doRef) {
    return (
      <SfpRelationInner {...props} />
    )
  } else {
    return null
  }
}

export const SfpRelationInner = ({
  doRef, style, dbtn, onClick, dashed, plain, orientation, type, index,
  itemKey, className, styleStr, title,
  disabled, ...otherProps }) => {
  const prefixCls = "ant-divider";
  const ltype = "horizontal";
  const lorientation = "center"
  const orientationPrefix = lorientation && lorientation.length > 0 ? `-${lorientation}` : lorientation;
  const hasChildren = !!title;
  const classString = classNames(
    prefixCls,
    `${prefixCls}-${ltype}`,
    {
      [`${prefixCls}-with-text`]: hasChildren,
      [`${prefixCls}-with-text${orientationPrefix}`]: hasChildren,
      [`${prefixCls}-dashed`]: !!dashed,
      [`${prefixCls}-plain`]: !!plain,
    },
    className,
  );
  let sls = convertStyleStr(styleStr);
  if (style) sls = sls ? { ...sls, ...style } : style;
  if (doRef) {
    const height = "18px";
    const width = type === "vertical" ? "18px" : null;
    sls = { ...sls, height: height, width: width }
    return (
      <div ref={doRef} style={sls} className={classString}
      ><span className="title">Relation: {itemKey} </span>{dbtn}</div>
    );
  } else {
    return null;
  }
}

SfRelation.validate = (props, {parents, container, extraParams, allActionPolicies}) => {
  const itemKey = props.itemKey;
  if (!props.type) {
    return <IntlMessages id="system.form.validate.missing-relation-type" text="item ({itemKey}) - please select a relation type." values={{itemKey:itemKey}}/>
  }
  if (!props.form) {
    return <IntlMessages id="system.form.validate.missing-form" text="item ({itemKey}) - please select a form." values={{itemKey:itemKey}}/>
  }
  if (!props.view) {
    return <IntlMessages id="system.form.validate.missing-view" text="item ({itemKey}) - please select a view." values={{itemKey:itemKey}}/>
  }
  if (props.type === 'child-flow') {
    if (!props.flow) {
      return <IntlMessages id="system.form.validate.missing-flow" text="item ({itemKey}) - please select a flow." values={{itemKey:itemKey}}/>
    }
  }

  const actions = props.actions;
  let error = null;
  const selectedActions = (actions || []).map(action => {
    let selected = null;
    allActionPolicies.forEach(p => {
      if (p.actionKey === action) {
        selected = {
          actionKey: p.actionKey,
          data: p.data,
          actionTypes: p.actionTypes,
        };
      }
    });
    if (!selected) {
      error = <IntlMessages id="system.form.validate.action-not-found" text="Unknown action policy ({action})." values={{action}}/>
    }
    return selected;
  });

  if (error) return error;

  extraParams.relations.push({
    max: props.max,
    min: props.min,
    form: props.form,
    type: props.type,
    view: props.view,
    flow: props.flow,
    title: props.title,
    itemKey: props.itemKey,
    permission: props.permission,
    filter: props.filter,
    sort: props.sort,
    maxDisplay: props.maxDisplay,
    actions: actions,
    selectedActions: selectedActions,
    roles: props.roles,
    parentkeys: props.parentkeys,
    childkeys: props.childkeys,
    delayedsave: props.delayedsave,
  });

  if (props.isSelectKey) {
    extraParams.selectConfig.optionTypes.push('relation.'+props.itemKey);
  }
}

SfRelation.craft = {
  displayName: "Relation",
  related: {
    settings: SfRelationSetting
  },
  rules: {
    canDrop: (targetNode, currentNode, helper) => {
      let parent = null;
      if (targetNode?.data?.parent) {
        try {
          parent = helper(targetNode.data.parent).get()
        } catch (error) {
          log("error", error);
        }
      }
      return parent?.data?.name === "SfMainPanel";
    }
  }
}

registerComponent({
  key:"SfRelation",
  component: SfRelation,
  runtimeComponent: SfpRelation,
  template: <SfRelation itemKey={getId('relation')} className="sf-divider" title="Relation" plain dashed sort={true} maxDisplay={10}/>,
  title: <IntlMessages id="system.form.library.relation" text="Relation"/>,
  icon: <RiStackshareLine  className="react-icons icon-ri"/>,
  type: "Logic",
  sequence: 4,
});

export default SfRelation;