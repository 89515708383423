import { Divider, Select } from "antd";
import { getMessage } from "lngProvider";
import React, { useRef, useState } from "react";
import { injectIntl } from "react-intl";
import Input from "./IntlInput";
import { isEmpty } from "util/algorithm";
import { settingsSignal } from "../../util/signal";

const IntlSelect = ({ intl: { formatMessage }, intlPrefix, className, placeholder, filterOption, children, onAddItem, onChange, ...otherProps }) => {
  const { locale } = settingsSignal;
  const [newItem, setNewItem] = useState("");
  const ref = useRef();

  const cls = className;
  const getOptionText = (option) => {
    const text = getMessage(locale.languageId, intlPrefix+option.value, option.label);
    return text ? text : option.value;
  }
  const dfo = (input, option) => {
    const text = getOptionText(option);
    return text ? (text.toLowerCase().indexOf(input.toLowerCase()) >= 0) : false;
  }
  const fo = filterOption ? filterOption : dfo;

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      if (onAddItem) {
        if (!isEmpty(newItem)) onAddItem(newItem);
        if (onChange) onChange(newItem);
        setNewItem("");
      }
    }
  }
  const onInputChange = (e) => {
    setNewItem(e.target.value)
  }
  const dropdownRender = onAddItem ? (
    (menu) => (
      <div>
        {menu}
        <Divider style={{ margin: '4px 0' }} />
        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
          <Input value={newItem} className="select-add-item" placeholder="system.select.add-item"
          onChange={onInputChange} onKeyDown={onKeyDown}
          />
        </div>
      </div>
    )
  ) : null;

  if (placeholder) {
    const message = getMessage(locale.languageId, placeholder);
    if (message) {
      return (
      <Select ref={ref} onChange={onChange} className={cls} dropdownRender={dropdownRender} placeholder={formatMessage({ id: placeholder })} {...otherProps} filterOption={fo}>
        {children}
        </Select>);
    } else {
      return (
      <Select ref={ref} onChange={onChange} className={cls} dropdownRender={dropdownRender} placeholder={placeholder} {...otherProps} filterOption={fo}>
        {children}
      </Select>);
    }
  } else {
    return (<Select ref={ref} onChange={onChange} className={cls} dropdownRender={dropdownRender} {...otherProps} filterOption={fo}>
      {children}
    </Select>);
  }
}

const injectedIntlSelect = injectIntl(IntlSelect);
injectedIntlSelect.Option = Select.Option;
export default injectedIntlSelect;