import { useEffect, useRef, useState } from "react";
import { isEmpty, uid } from "./algorithm";
import { signal } from "./signal";

const DEFAULT_OPTIONS = {
  config: {
    attributes: true,
    childList: true,
    subtree: true,
    attributeOldValue: true,
  },
};
export function useMutationObservable(targetEl, cb, enabled, options = DEFAULT_OPTIONS) {
  const [observer, setObserver] = useState(null);

  useEffect(() => {
    const obs = new MutationObserver(cb);
    setObserver(obs);
  }, [cb, options, setObserver]);

  useEffect(() => {
    if (observer && targetEl && enabled) {
      const { config } = options;
      observer.observe(targetEl, config);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [observer, targetEl, enabled, options]);

}

export const convertMutationRecords = (mutationList) => {
  const list = [];
  for (const m of mutationList) {
    try {
      const { type, attributeName, oldValue, target } = m;
      if (type === "attributes") {
        if (attributeName?.startsWith("data-")) {
          const newValue = target.getAttribute(attributeName);
          list.push({ type, attributeName, oldValue, newValue });
        }
      }
    } catch (error) {
      console.log("failed to convert multation", error);
    }
  }
  return list;
};

export const convertMutationValues = (mutationList, obsPattern) => {
  const values = {};
  let updated = false;
  for (const m of mutationList) {
    try {
      const { type, attributeName, target } = m;
      if (type === "attributes") {
        if (attributeName?.startsWith("data-")) {
          if (isEmpty(obsPattern) || attributeName.match(new RegExp(obsPattern))) {
            const newValue = target.getAttribute(attributeName);
            values[attributeName] = newValue;
            updated = true;
          }
        }
      }
    } catch (error) {
      console.log("failed to convert multation", error);
    }
  }
  return updated ? values : null;
};

export function useConsolidateTrigger(fn, timeout) {
  const timeoutRef = useRef();
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  const trigger = () => {
    console.log("waiting for trigger...");
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      console.log("trigger...");
      fn();
    }, timeout);
  };
  return trigger;
}

export function useRendersCount(componentName, params) {
}

export function useSignal(state, name) {
  const signalRef = useRef();
  if (!signalRef.current) {
    signalRef.current = signal(name, state);
  }
  return signalRef.current;
}

export const useSignalValue = (targetSignal, attr) => {
  const [value, setValue] = useState(targetSignal[attr]);
  useEffect(() => {
    const key = uid();
    targetSignal.register(key, (oldVal, newVal) => {
      if (oldVal[attr] !== newVal[attr]) {
        setValue(newVal[attr]);
      }
    });
    return () => {
      targetSignal.unregister(key);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return value;
}