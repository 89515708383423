import React from "react";
import { MdGroups } from "react-icons/md";
import IntlMessages from "util/IntlMessages";
import { registerComponent } from "./common";
import { SfpTransfer, SfTransfer } from "./SfTransfer";

registerComponent({
  key: "SfUserRole",
  component: SfTransfer,
  runtimeComponent: SfpTransfer,
  template: <SfTransfer itemKey={'userRoles'} className="sf-tranfer wrap" sourceTitle="Available" targetTitle="Assigned" styleStr="width:100%,height:200px"
    title={"User Roles"} selectkey={"Role"} isUseCode={true} showSearch={true} datasec={true} datasectype={"userRoles"} span={24} labelColStr="span:6" />,
  title: <IntlMessages id="system.form.library.userRoles" text="User Roles" />,
  icon: <MdGroups  className="react-icons icon-md"/>,
  type: "Component",
  subtype: "sys",
  sequence: 7,
});
