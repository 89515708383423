import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import { useLbl } from "../../lngProvider";
import { keycloakApi } from "../../parse-api/keycloak-sso";
import translateApi from "../../parse-api/translate";
import { setupMenu } from "../../routes/system/MenuSetup";
import { getClientRoot, toAuthUserObj } from "../../util/algorithm";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import { authSignal, commonSignal, settingsSignal, systemSignal } from "../../util/signal";
import { useSignalValue } from "../../util/reactHook";

const SidebarContent = ({sidebarCollapsed, setSidebarCollapsed}) => {
  const {navStyle, themeType} = settingsSignal;
  const menu = useSignalValue(systemSignal, 'menu');
  const { width, pathname } = commonSignal;
  const authUser = useSignalValue(authSignal, 'authUser');
  const authUserObj = toAuthUserObj(authUser);
  const [selectedKey, setSelectedKey] = useState(pathname);
  const [loading, setLoading] = useState(true);
  const locale = settingsSignal.locale;
  const lbl = useLbl(locale);
  const dispatch = useDispatch();

  useEffect(() => {
    translateApi.waitTranslateData().then(result => {
      setLoading(false);
    })
  }, [])

  const client_root = getClientRoot();
  const keycloakParmas = keycloakApi.getKeycloakUrlParams();
  const urlParams = {username: authUserObj.username, client_root, ...keycloakParmas};

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const onClick = (selected) => {
    const link = selected?.props?.link;
    setSelectedKey(link);
  }

  const theme = themeType === THEME_TYPE_LITE ? 'lite' : 'dark';
  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed}/>
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile sidebarCollapsed={sidebarCollapsed}/>
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          {!loading && setupMenu({
            menu: menu,
            preview: false,
            //defaultOpenKeys: [defaultOpenKeys],
            selectedKeys: [selectedKey],
            theme: theme,
            roles: authUserObj.roles,
            urlParams: urlParams,
            onClick: onClick,
            dispatch: dispatch,
            lbl, locale, location: 'sidebar', width})}
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);

