import { useEditor, useNode } from "@craftjs/core";
import { Col, Switch } from "antd";
import { Form, Input } from "components/Form";
import React, { useEffect, useRef, useState } from "react";
import { BiCodeCurly } from "react-icons/bi";
import IntlMessages from "util/IntlMessages";
import JsonTextArea from "../../../../components/Form/JsonTextArea";
import { AccountStore } from "../../../../constants/Account";
import { JsonObjectEditor } from "../../FormSetup/components/SfObject";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting, syncPageState, updatePageState } from "./common";

const SpObjectSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="defaultValue" label="default value" className="defaultValue"
        labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} >
        <JsonTextArea/>
      </Form.Item>
      <Form.Item name="readOnly" label="read-only" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hidden" label="hidden" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="isConfig" label="is config" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  )
}

export const SpObject = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppObject doRef={ref => connect(drag(ref))} dbtn={dbtn} {...props} selectedStyle={style} />
  )
}

export const SppObject = ({
  doRef, dbtn, selectedStyle,
  pageKey, itemKey, pageState, setPageState, value,  defaultValue,
  readOnly, disabled, hidden, isConfig, ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  if (!doRef && (hidden || isConfig)) style.display = 'none';
  const props = {...otherProps, style}
  const inputProps = {readOnly, disabled};
  const [myValue, setMyValue] = useState(defaultValue);
  const timer = useRef()

  useEffect(() => {
    syncPageState({itemKey, myValue, setMyValue, pageState, setPageState})
  },[itemKey, myValue, pageState, setPageState])

  const onMyChange = (myValue) => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      updatePageState({itemKey, myValue, setMyValue, pageState, setPageState})
    }, AccountStore.ON_CHANGE_DELAY);
  }

  return (
    <Col ref={doRef} {...props}>
      <JsonObjectEditor value={myValue} onChange={onMyChange} {...inputProps} />
      {dbtn}
    </Col>
  );
}
SppObject.enablePageState = true;

SpObject.craft = {
  displayName: "Object",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpObjectSetting
  }
}

SpObject.validate = (props, {extraParams, pageData}) => {
  if (props.isConfig) {
    extraParams.configData = props.defaultValue;
  }
}

registerComponent({
  key: "SpObject",
  component: SpObject,
  runtimeComponent: SppObject,
  template: <SpObject itemKey={getId('object')} className="sp-object" title={"Object"} span={24} />,
  title: <IntlMessages id="system.page.library.object" text="Object" />,
  icon: <BiCodeCurly className="react-icons icon-bi"/>,
  type: "Component",
  subtype: "input",
  sequence: 22,
});

export default SpObject;