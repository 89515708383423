import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Switch } from "antd";
import { Form, Input, Select } from "components/Form";
import { formatterApi } from "parse-api";
import React, { useEffect, useRef, useState } from "react";
import { MdOutlinePlusOne } from "react-icons/md";
import IntlMessages from "util/IntlMessages";
import {
  colSls, convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext
} from "./common";
import { log } from "util/algorithm";

const SfAutoIncrementSetting = () => {
  const [formatters, setFormatters] = useState([]);
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    const fetchData = async () => {
      const list = await formatterApi.loadSystemFormatter();
      if (mounted.current) setFormatters(list);
    }
    fetchData();
    return () => {
      mounted.current = false;
    }
  }, [])
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="seqKey" label="seq.">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="min" label="min">
        <InputNumber className="item-property" />
      </Form.Item>
      <Form.Item name="max" label="max">
        <InputNumber className="item-property" />
      </Form.Item>
      <Form.Item name="step" label="step">
        <InputNumber className="item-property" />
      </Form.Item>
      <Form.Item name="systemFormatter" label="formatter">
        <Select className="item-property" allowClear>
          {formatters.map(f => {
            return (
              <Select.Option key={f.formatterKey} value={f.formatterKey}>{f.name}</Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item name="stringMode" label="text mode" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hidden" label="hidden" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="isSelectKey" label="select key" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

const createFormatter = (formatter) => {
  if (formatter && formatter.formatters) {
    return (value) => {
      try {
        let currval = `${value}`;
        for (const element of formatter.formatters) {
          const f = element;
          currval = currval.replace(RegExp(f.input, 'g'), f.output);
        }
        return currval;
      } catch (e) {
        log(e);
        return value;
      }
    }
  } else {
    return undefined;
  }
}

const createParser = (formatter) => {
  if (formatter && formatter.parsers) {
    return (value) => {
      try {
        let currval = `${value}`;
        for (const element of formatter.parsers) {
          const f = element;
          currval = currval.replace(RegExp(f.input, 'g'), f.output);
        }
        return currval;
      } catch (e) {
        log(e);
        return value;
      }
    }
  } else {
    return undefined;
  }
}

export const SfAutoIncrement = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpAutoIncrement doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const MyInputNumber = ({ inputRef, systemFormatter, systemFormatterData, value, onChange, noupdate, skipcopy, ...otherProps }) => {
  if (systemFormatter && !systemFormatterData) return null;
  else return <InputNumber ref={inputRef} value={value} onChange={onChange} {...otherProps} />
}

export const SfpAutoIncrement = ({
  doRef, form, className, style, dbtn, hidden, hideInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, volitate, skipcopy, seqKey,
  inputref, styleStr, systemFormatter, children, isSelectKey, ...otherProps }) => {
  const [systemFormatterData, setSystemFormatterData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      if (systemFormatter) {
        const sf = await formatterApi.getSystemFormatter(systemFormatter);
        setSystemFormatterData(sf);
      }
    }
    fetchData();
  }, [systemFormatter])
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const f = createFormatter(systemFormatterData);
  const p = createParser(systemFormatterData);

  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        return (
          <Col ref={doRef} className={className} style={style} {...colSls(otherProps)}>
            <Form.Item name={name} label={title} labelAlign={labelAlign} labelCol={lcs} wrap hidden={!doRef && hidden}>
              <MyInputNumber inputref={inputref} style={sls}
                systemFormatter={systemFormatter} systemFormatterData={systemFormatterData}
                formatter={f} parser={p} disabled={true} {...otherProps} />
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

SfAutoIncrement.craft = {
  displayName: "Auto Increment",
  related: {
    settings: SfAutoIncrementSetting
  }
}

SfAutoIncrement.validate = (props, { parents, container, extraParams }) => {
  const itemKey = props.itemKey;
  const min = props.min;
  const step = props.step;
  if (props.skipcopy === undefined) props.skipcopy = true;
  if (!min || !step) {
    return <IntlMessages id="system.form.validate.auto-increment-key-missing" text="item ({itemKey}) - min / step is missing." values={{ itemKey: itemKey }} />
  }
  if (!props.seqKey) {
    const formKey = extraParams.dataClassConfig.formKey;
    props.seqKey = `${formKey}.${itemKey}`;
  }

  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "number",
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      sortable: true,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
    });
    if (props.isSelectKey) {
      extraParams.selectConfig.optionTypes.push(props.itemKey);
    }
  }
}

SfAutoIncrement.isSearchable = true;
SfAutoIncrement.isRulable = true;

registerComponent({
  key: "SfAutoIncrement",
  component: SfAutoIncrement,
  runtimeComponent: SfpAutoIncrement,
  template: <SfAutoIncrement itemKey={getId('autoincrement')} className="sf-autoincrement wrap"
    title={"Auto Increment"} span={24} labelColStr="span:6" min={1} step={1} />,
  title: <IntlMessages id="system.form.library.autoincrement" text="Auto Increment" />,
  icon: <MdOutlinePlusOne className="react-icons icon-md" />,
  type: "Component",
  subtype: "sys",
  sequence: 1,
});

export default SfAutoIncrement;