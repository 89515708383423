import { Element, useEditor, useNode } from "@craftjs/core";
import { Checkbox, Col, Empty, Radio, Switch } from "antd";
import { useEffect, useRef, useState } from "react";
import { MdDynamicForm } from "react-icons/md";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "../../../../components/CircularProgress";
import { Form, ManagedSelect, Select } from "../../../../components/Form";
import { flowApi, formApi } from "../../../../parse-api";
import { compare, equals, getArraysSubtraction, getArraysUnion, getFormIdentity, getMatchPath, isEmpty, isParamUpdated } from "../../../../util/algorithm";
import { FormRuntimeSearchImpl } from "../../FormSetup/runtimesearchimpl";
import { FormRuntimeImpl } from "../../FormSetup/runtimeimpl";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting } from "./common";
import { AccountStore } from "../../../../constants/Account";
import { useLbl } from "../../../../lngProvider";
import { settingsSignal } from "../../../../util/signal";

const doModifiedCheck = (isPageModifiedRef, lbl) => {
  console.log('doModifiedCheck()', isPageModifiedRef)
  if (isPageModifiedRef.current) {
    if (!window.confirm(lbl('system.modified.message', 'You have unsaved changes, are you sure you want to leave?'))) {
      return false;
    }
  }
  isPageModifiedRef.current = false;;
  return true;
}

export const SpForm = ({...props}) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppForm doRef={ref => connect(drag(ref))} selectedStyle={style} dbtn={dbtn} {...props}/>
  )
}

const FILTER_ATTRIBUTES = ['close', 'objectId', 'params', 'sort', 'descending', 'reload', 'resetSearch', 'resetClose'];
const filterPageState = (prev, pageState, itemKey) => {
  const myPageState = {};
  let changed = false;
  FILTER_ATTRIBUTES.forEach(attr => {
    const key = itemKey + '_' + attr;
    if (!equals(prev?.[key], pageState?.[key])) {
      changed = true;
    }
    myPageState[key] = pageState?.[key];
  })
  if (changed) {
    return myPageState;
  } else {
    return prev;
  }
}

export const SppForm = ({
  doRef, dbtn, selectedStyle, pageKey, itemKey, pageState, setPageState,
  flowKey, formKey, formView, isCreate, displaymode, paginationPosition,
  showTotal, showTitle, nopaging, selectionType, isMaxContent, sticky,
  hideVersion, keepSearchState, hideDraft, hideFlowInfo, autoScrollTop,
  onBeforeSearch, disabled,
  ...otherProps }) => {
  const [pathInfo, setPathInfo] = useState({
    path: null,
    state: {},
    history: []
  });
  const [myPageState, setMyPageState] = useState(filterPageState(null, pageState, itemKey));
  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState({});
  const [myPath, setMyPath] = useState();
  const mounted = useRef();
  const apiRef = useRef();
  const doDownloadCalledRef = useRef();
  const isPageModifiedRef = useRef();
  const locale = settingsSignal.locale;
  const lbl = useLbl(locale);
  const ref = useRef();

  useEffect(()=>{
    mounted.current = true;
    return ()=>{
      mounted.current = false;
    }
  }, [])

  useEffect(() => {
    setMyPageState(prev => filterPageState(prev, pageState, itemKey))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      if (flowKey && formKey && formView) {
        const objectId = myPageState?.[itemKey + '_objectId'];
        if (objectId || isCreate) {
          if (objectId === 'new' || isCreate) {
            const p = `/system/wrt/fm/${flowKey}/${formKey}/${formView}`;
            setPathInfo({...pathInfo, path: p});
          } else {
            const oid = objectId ? '/' + objectId : ''
            const p = `/system/wrt/fm/${flowKey}/${formKey}/${formView}${oid}`;
            setPathInfo({...pathInfo, path: p});
          }
        } else {
          const p = `/system/wrts/fm/${flowKey}/${formKey}/${formView}`;
          setPathInfo({...pathInfo, path: p});
        }
      } else if (formKey && formView) {
        const objectId = myPageState?.[itemKey + '_objectId'];
        if (objectId || isCreate) {
          if (objectId === 'new' || isCreate) {
            const p = `/system/rt/fm/${formKey}/${formView}`;
            setPathInfo({...pathInfo, path: p});
          } else {
            const oid = objectId ? '/' + objectId : ''
            const p = `/system/rt/fm/${formKey}/${formView}${oid}`;
            setPathInfo({...pathInfo, path: p});
          }
        } else {
          const p = `/system/rts/fm/${formKey}/${formView}`;
          setPathInfo({...pathInfo, path: p});
        }
      } else {
        setPathInfo({...pathInfo, path: null});
      }
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowKey, formKey, formView, isCreate, itemKey, autoScrollTop])

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, AccountStore.ON_CHANGE_DELAY)
  }, [displaymode, selectionType, isMaxContent, sticky, hideVersion, hideDraft, hideFlowInfo])

  useEffect(() => {
    if (setPageState) setPageState((pageState) => {
      return {...pageState, [itemKey + '_selectedIds']: selectedIds}
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds])

  useEffect(() => {
    if (pageState) {
      const path = pageState[itemKey + "_path" ];
      if (path && path !== myPath) {
        setMyPath(path);
        dispatchReplace(path)
      } else if (myPath) {
        setMyPath(null);
      }
      const doDownloadCalled = pageState[itemKey + "_doDownload_called" ];
      if (doDownloadCalled && apiRef.current?.doDownload && isParamUpdated(doDownloadCalled, doDownloadCalledRef)) {
        apiRef.current.doDownload(doDownloadCalled)
        if (setPageState) setPageState((pageState) => {
          return {...pageState, [itemKey + '_doDownload_called']: null}
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pageState])

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      if (setPageState) setPageState((pageState) => {
        return {...pageState, [itemKey + '_pathInfo']: pathInfo}
      })
      if (autoScrollTop) {
        try {
          if (ref.current?.querySelector('.gx-layout-content, .layout-headless')) ref.current.querySelector('.gx-layout-content, .layout-headless').scrollTo(0, 0)
          else ref.current?.scrollIntoView(false)
        } catch (error) {
          console.log("failed to scroll to top", error)
        }
      }
    }, AccountStore.ON_CHANGE_DELAY)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathInfo])

  const uncheckAll = (selected) => {
    if (selected) Object.values(selected).forEach(r => {
      if (r) {
        r.checked = false;
      }
    })
  }

  const dispatchReplace = (path, state) => {
    if (!doModifiedCheck(isPageModifiedRef, lbl)) return;
    setPathInfo({...pathInfo, path, state: state || {}});
  }

  const dispatchGoBack = () => {
    if (!doModifiedCheck(isPageModifiedRef, lbl)) return;
    if (pathInfo.history.length > 0) {
      const path = pathInfo.history[pathInfo.history.length - 1]
      const history = pathInfo.history.slice(0, pathInfo.history.length - 1)
      setPathInfo({history, path, state: {}});
    }
  }

  const dispatchPush = (path, state) => {
    if (!doModifiedCheck(isPageModifiedRef, lbl)) return;
    const history = [...pathInfo.history, pathInfo.path];
    setPathInfo({path, history, state: state || {}});
  }

  const setSelection = (checked, multiple, record, selectedRecords) => {
    record.checked = checked;
    if (multiple) {
      const newSelectedRecords = {...selectedRecords};
      if (checked) {
        if (mounted.current) setSelectedIds((selectedIds) => getArraysUnion(selectedIds, [record.objectId]));
      } else {
        if (mounted.current) setSelectedIds((selectedIds) => getArraysSubtraction(selectedIds, [record.objectId]));
      }
      newSelectedRecords[record.objectId] = checked ? record : null;
      if (mounted.current) setSelectedRecords((selectedRecords) => ({...selectedRecords, [record.objectId]: checked ? record : null}));
    } else {
      if (checked) {
        if (mounted.current) setSelectedIds([record.objectId]);
        if (mounted.current) setSelectedRecords((selectedRecords) => {
          uncheckAll(selectedRecords)
          setSelectedRecords({[record.objectId]: record})
        });
      } else {
        if (mounted.current) setSelectedIds([]);
        if (mounted.current) setSelectedRecords({});
      }
    }
  }

  const doMyOnBeforeSearch = (params, sort, descending) => {
    if (onBeforeSearch) {
      params = onBeforeSearch(params, sort, descending);
    }
    if (params) {
      if (setPageState) setPageState((pageState) => {
        return {
          ...pageState,
          [itemKey + '_advanceparams']: params,
          [itemKey + '_advancesort']: sort,
          [itemKey + '_advancedescending']: descending,
        }
      })
    }
    return params
  }

  const SelectedCheckbox = ({record}) => {
    const isChecked = record.checked;
    const multiple = selectionType === 'checkbox';
    if (selectionType === 'radio') {
      return <div className="table-action-centered clickable"><Radio onChange={(e)=>setSelection(e.target.checked, multiple, record, selectedRecords)} checked={isChecked}/></div>;
    } else {
      return <div className="table-action-centered clickable"><Checkbox onChange={(e)=>setSelection(e.target.checked, multiple, record, selectedRecords)} checked={isChecked}/></div>;
    }
  }
  const getSelectAction = (record) => <SelectedCheckbox record={record}/>

  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}
  let showTotalFn = null;
  if (showTotal) {
    showTotalFn = (total) => lbl('system.form.show-total', 'Total {total} items', {total})
  }
  if (loading) {
    return (
      <Col ref={doRef || ref} {...props}>
        <CircularProgress/>
        {dbtn}
      </Col>
    );
  } else if (!isEmpty(pathInfo.path) && formKey && formView) {
    const identity = getFormIdentity(pathInfo.path)
    const match = { path: getMatchPath(pathInfo.path) }
    const location = { state: pathInfo.state }
    const isSearchScreen = pathInfo.path.indexOf('rts') !== -1
    return (
      <Col ref={doRef || ref} {...props}>
        {isSearchScreen && (
          <FormRuntimeSearchImpl
            match={match}
            location={location}
            apiRef={apiRef}
            itemKey={itemKey}
            keepSearchState={keepSearchState}
            flow={identity?.flowKey}
            type={identity?.type}
            formKey={identity?.formKey}
            formView={identity?.formView}
            dispatchReplace={dispatchReplace}
            displaymode={displaymode || 'search'}
            showTitle={showTitle}
            extAction={selectionType ? getSelectAction : null}
            sticky={sticky}
            disabled={disabled || !!doRef}
            isMaxContent={isMaxContent}
            extParams={myPageState?.[itemKey + '_params']}
            extSort={myPageState?.[itemKey + '_sort']}
            extDescending={!!myPageState?.[itemKey + '_descending']}
            extReload={myPageState?.[itemKey + '_reload']}
            extResetSearch={myPageState?.[itemKey + '_resetSearch']}
            onBeforeSearch={doMyOnBeforeSearch}
            isComponent={true}
            nopaging={nopaging}
            paginationPosition={paginationPosition}
            showTotal={showTotalFn}
            isEnableAdminAllowed={false}
          />
        )}
        {!isSearchScreen && (
          <FormRuntimeImpl
            match={match}
            location={location}
            flow={identity?.flowKey}
            type={identity?.type}
            formKey={identity?.formKey}
            formView={identity?.formView}
            id={identity?.id}
            hideVersion={hideVersion}
            hideDraft={hideDraft}
            hideFlowInfo={hideFlowInfo}
            dispatchReplace={dispatchReplace}
            dispatchGoBack={dispatchGoBack}
            dispatchPush={dispatchPush}
            extClose={myPageState?.[itemKey + '_close']}
            extResetClose={myPageState?.[itemKey + '_resetClose']}
            isComponent={true}
            isPageModifiedRef={isPageModifiedRef}
          />
        )}
        {dbtn}
      </Col>
    );
  } else {
    return (
      <Col ref={doRef || ref} {...props}>
        <Empty />
        {dbtn}
      </Col>
    );
  }
}

const SpFormSetting = () => {
  const [allForms, setAllForms] = useState([]);
  const [allFlows, setAllFlows] = useState([]);
  const [formOptions, setFormOptions] = useState([]);
  const [flowOptions, setFlowOptions] = useState([]);
  const [viewOptions, setViewOptions] = useState([]);
  const [selectedFlow, setSelectedFlow] = useState();
  const [selectedForm, setSelectedForm] = useState();

  const [flowKey, setFlowKey] = useState();
  const [formKey, setFormKey] = useState();
  const [formView, setFormView] = useState();

  const mounted = useRef();
  const optionsSorter = (a, b) => compare(a.label, b.label)
  useEffect(()=>{
    mounted.current = true;
    const fetchData = async () => {
      if (mounted.current) setAllForms(await formApi.loadSystemForm());
      if (mounted.current) setAllFlows(await flowApi.loadSystemFlow());
    }
    fetchData();
    return ()=>{
      mounted.current = false;
    }
  },[])

  useEffect(() => {
      if (mounted.current) setFormOptions(allForms.map(f => ({value: f.formKey, label: f.formKey})).sort(optionsSorter));
  }, [allForms])

  useEffect(() => {
      if (mounted.current) setFlowOptions(allFlows.map(f => ({value: f.flowKey, label: f.flowKey})).sort(optionsSorter));
  }, [allFlows])

  useEffect(() => {
    if (mounted.current) {
      setViewOptions((selectedForm?.extraParams?.allViews || []).map(v => ({value: v, label: v})).sort(optionsSorter))
      if (selectedFlow) {
        setFormView(selectedFlow.extraParams?.taskMap?.start?.view)
      } else {
        setFormView(null)
      }
    }
  }, [selectedForm, selectedFlow])

  useEffect(() => {
    if (flowKey && allFlows) {
      allFlows.forEach(f => {
        if (f.flowKey === flowKey) {
          setSelectedFlow(f);
          setFormKey(f.formKey)
        }
      })
    } else {
      setSelectedFlow(null);
    }
  }, [flowKey, allFlows])

  useEffect(() => {
    if (formKey && allForms) {
      allForms.forEach(f => {
        if (f.formKey === formKey) {
          setSelectedForm(f);
          if (selectedFlow) {
            setFormView(selectedFlow.extraParams?.taskMap?.start?.view)
          } else {
            setFormView(null)
          }
        }
      })
    } else {
      setSelectedForm(null);
    }
  }, [formKey, allForms, selectedFlow])

  const onFlowKeyChange = (value) => {
    setFlowKey(value)
  }

  const onFormKeyChange = (value) => {
    setFormKey(value)
  }

  return (
    <>
      <Form.Item name="flowKey" label="flow" >
        <ManagedSelect className="item-property" placeholder="flow" allowClear showSearch
          options={flowOptions} onSetValue={onFlowKeyChange} targetValue={flowKey} />
      </Form.Item>
      <Form.Item name="formKey" label="form" >
        <ManagedSelect className="item-property" placeholder="form" allowClear showSearch
          options={formOptions} onSetValue={onFormKeyChange} targetValue={formKey}
          disabled={!!flowKey}/>
      </Form.Item>
      <Form.Item name="formView" label="view" >
        <ManagedSelect className="item-property" placeholder="view" allowClear showSearch
          options={viewOptions} onSetValue={setFormView} targetValue={formView}
          disabled={!!flowKey}/>
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="isCreate" label="is create" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="displaymode" label="mode">
        <Select className="item-property" allowClear>
          <Select.Option value="maintenance">maintenance</Select.Option>
          <Select.Option value="maintenance-wo-simple-search">maintenance (w/o Simple Search)</Select.Option>
          <Select.Option value="search">search</Select.Option>
          <Select.Option value="restrictedsearch">restricted search</Select.Option>
          <Select.Option value="table">table</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="paginationPosition" label="paging position">
        <Select className="item-property" allowClear mode="multiple">
          <Select.Option value="topLeft">top left</Select.Option>
          <Select.Option value="topCenter">top center</Select.Option>
          <Select.Option value="topRight">top right</Select.Option>
          <Select.Option value="bottomLeft">bottom left</Select.Option>
          <Select.Option value="bottomCenter">bottom center</Select.Option>
          <Select.Option value="bottomRight">bottom right</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="showTotal" label="show total" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="selectionType" label="selection">
        <Select className="item-property" allowClear>
          <Select.Option value="checkbox">checkbox</Select.Option>
          <Select.Option value="radio">radio</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="showTitle" label="show title" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="isMaxContent" label="max-content" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="sticky" label="sticky" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="nopaging" label="no paging" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="keepSearchState" label="search state" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="hideVersion" label="hide ver." valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="hideDraft" label="hide draft" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="hideFlowInfo" label="hide flow" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="autoScrollTop" label="auto scroll" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  );
}

SpForm.craft = {
  displayName: "Form",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpFormSetting
  }
}
SppForm.enablePageState = true;

registerComponent({
  key:"SpForm",
  component: SpForm,
  runtimeComponent: SppForm,
  template: <Element canvas is={SpForm} itemKey={getId('form')}
            className="sp-form" displaymode={'search'} span="24"/>,
  title: <IntlMessages id="system.page.library.form" text="Form" />,
  icon: <MdDynamicForm  className="react-icons icon-md"/>,
  type: "Component",
  sequence: 8,
});

export default SpForm;