import { SlidersOutlined } from "@ant-design/icons";
import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Slider, Switch } from "antd";
import { Form } from "components/Form";
import React, {useEffect, useState} from "react";
import IntlMessages from "util/IntlMessages";
import { Input } from "../../../../components/Form";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting, syncPageState, updatePageState } from "./common";

const SpSliderSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="defaultValue" label="default value">
        <InputNumber min={0} step={0.5} className="item-property"/>
      </Form.Item>
      <Form.Item name="min" label="min">
        <InputNumber className="item-property"/>
      </Form.Item>
      <Form.Item name="max" label="max">
        <InputNumber className="item-property"/>
      </Form.Item>
      <Form.Item name="step" label="step">
        <InputNumber className="item-property"/>
      </Form.Item>
      <Form.Item name="range" label="range" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="reverse" label="reverse" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="vertical" label="vertical" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  )
}

export const SpSlider = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppSlider doRef={ref => connect(drag(ref))} dbtn={dbtn} {...props} selectedStyle={style} />
  )
}

export const SppSlider = ({
  doRef, dbtn, selectedStyle,
  pageKey, itemKey, pageState, setPageState,
  value, defaultValue, min, max, step, range, reverse, vertical, disabled, ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}
  const inputProps = {min, max, step, range, reverse, vertical, disabled};
  const [myValue, setMyValue] = useState(defaultValue);

  useEffect(() => {
    syncPageState({itemKey, myValue, setMyValue, pageState, setPageState})
  },[itemKey, myValue, pageState, setPageState])

  const onMyChange = (myValue) => {
    updatePageState({itemKey, myValue, setMyValue, pageState, setPageState})
  }

  let displayValue = myValue;
  if (range && !Array.isArray(myValue)) displayValue = [0,0];
  if (!range && Array.isArray(myValue)) displayValue = 0;
  return (
    <Col ref={doRef} {...props}>
      <Slider value={displayValue} onChange={onMyChange} {...inputProps}/>
      {dbtn}
    </Col>
  );
}
SppSlider.enablePageState = true;

SpSlider.craft = {
  displayName: "Slider",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpSliderSetting
  }
}

registerComponent({
  key: "SpSlider",
  component: SpSlider,
  runtimeComponent: SppSlider,
  template: <SpSlider itemKey={getId('slider')} className="sp-slider" title={"Slider"} span={24} />,
  title: <IntlMessages id="system.page.library.slider" text="Slider" />,
  icon: <SlidersOutlined  className="react-icons icon-antd"/>,
  type: "Component",
  subtype: "input",
  sequence: 10,
});

export default SpSlider;