import { CalendarOutlined } from "@ant-design/icons";
import { useEditor, useNode } from "@craftjs/core";
import { Col, Switch, Tooltip } from "antd";
import { Form, Input, Select } from "components/Form";
import React, {useEffect, useState} from "react";
import IntlMessages from "util/IntlMessages";
import { convertStyleStr } from "../../FormSetup/components/common";
import { MyDatePicker } from "../../FormSetup/components/SfDate";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting, syncPageState, updatePageState } from "./common";

const SpDateSetting = () => {
  const form = Form.useFormInstance();
  const stringMode = Form.useWatch('stringMode', form);
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="defaultValue" label="default value">
        <Input className="item-property"/>
      </Form.Item>
      <Form.Item name="placeholder" label="placeholder">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="format" label="format">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="popupStyleStr" label="popup style">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="picker" label="picker">
        <Select className="item-property">
          <Select.Option value="date">date</Select.Option>
          <Select.Option value="week">week</Select.Option>
          <Select.Option value="month">month</Select.Option>
          <Select.Option value="quarter">quarter</Select.Option>
          <Select.Option value="year">year</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="showTime" label="show time" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="showNow" label="show now" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Tooltip placement="leftTop" title="This option is only for week selection mode.">
        <Form.Item name="showDateRange" label="show date range" valuePropName="checked">
          <Switch className="item-property" />
        </Form.Item>
      </Tooltip>
      <Form.Item name="stringMode" label="text mode" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="withTimezone" label="w/ timezone" valuePropName="checked">
        <Switch className="item-property" disabled={stringMode} />
      </Form.Item>
      <Form.Item name="size" label="size">
        <Select className="item-property">
          <Select.Option value="large">large</Select.Option>
          <Select.Option value="middle">middle</Select.Option>
          <Select.Option value="small">small</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="allowClear" label="allow clear" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="bordered" label="bordered" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="inputReadOnly" label="choose only" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="fieldWidth" label="field-width">
        <Input className="item-property" />
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  )
}

export const SpDate = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppDate doRef={ref => connect(drag(ref))} dbtn={dbtn} {...props} selectedStyle={style} />
  )
}

export const SppDate = ({
  doRef, dbtn, selectedStyle,
  pageKey, itemKey, pageState, setPageState, value,  defaultValue, popupStyleStr,
  fieldWidth, placeholder, format, picker, showTime, showNow, showDateRange, stringMode, withTimezone, size, allowClear, bordered, inputReadOnly, disabled,
  disabledDate, disabledTime,
  ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}
  const inputProps = {placeholder, format, picker, showTime, showNow, showDateRange, stringMode, withTimezone, size, allowClear, bordered, inputReadOnly, disabled, disabledDate, disabledTime};
  const [myValue, setMyValue] = useState(defaultValue);
  const pus = convertStyleStr(popupStyleStr);
  useEffect(() => {
    syncPageState({itemKey, myValue, setMyValue, pageState, setPageState})
  },[itemKey, myValue, pageState, setPageState])

  const onMyChange = (myValue) => {
    updatePageState({itemKey, myValue, setMyValue, pageState, setPageState})
  }
  const fieldStyle = {};
  if (fieldWidth) fieldStyle.width = fieldWidth;
  return (
    <Col ref={doRef} {...props}>
      <MyDatePicker style={fieldStyle} value={myValue} onChange={onMyChange} popupStyle={pus} {...inputProps} />
      {dbtn}
    </Col>
  );
}
SppDate.enablePageState = true;

SpDate.craft = {
  displayName: "Date",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpDateSetting
  }
}

SpDate.validate = (props) => {
  if (props.stringMode && props.withTimezone) {
    props.withTimezone = false;
  }
}

registerComponent({
  key: "SpDate",
  component: SpDate,
  runtimeComponent: SppDate,
  template: <SpDate itemKey={getId('date')} className="sp-date" title={"Date"} span={24} showTime={false}/>,
  title: <IntlMessages id="system.page.library.date" text="Date" />,
  icon: <CalendarOutlined  className="react-icons icon-bs"/>,
  type: "Component",
  subtype: "input",
  sequence: 3,
});

export default SpDate;