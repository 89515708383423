import antdID from "antd/lib/locale-provider/id_ID";
import enMessages from "../locales/id_ID.json";

const IDLang = {
  messages: {
    ...enMessages
  },
  antd: antdID,
  locale: 'id-ID',
};
export default IDLang;
