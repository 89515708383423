import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useEditor, useNode } from "@craftjs/core";
import { Button, Switch } from "antd";
import { Form, Input, Select } from "components/Form";
import IntlMessages from 'util/IntlMessages';
import { convertStyleStr, deleteButton, EditorCollector, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, SfPanelListContext } from "./common";

const BUTTON_TYPES = [
  "default", "primary", "ghost", "dashed", "link", "text",
]

const BUTTON_SHAPE = [
  "default", "circle", "round",
]

const BUTTON_ACTION = {
  add: PlusOutlined,
  delete: MinusCircleOutlined
}

const SfButtonSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property"/>
      </Form.Item>
      <Form.Item name="buttonType" label="type">
        <Select className="item-property" placeholder="type" allowClear showSearch>
          {BUTTON_TYPES.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name="buttonShape" label="shape">
        <Select className="item-property" placeholder="shape" allowClear showSearch>
          {BUTTON_SHAPE.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name="block" label="block" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfButton = ({...props}) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpButton doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props}/>
  )
}

export const SfpButton = ({...props}) => {
  if (!props.doRef && props.buttonAction === "add") {
    return (
      <SfPanelListContext.Consumer>
         {({max, count, addRow}) => (
           <SfpButtonInner onClick={addRow} max={max} count={count} {...props}/>
         )}
      </SfPanelListContext.Consumer>
    )
  } else if (!props.doRef && props.buttonAction === "delete") {
    return (
      <SfPanelContext.Consumer>
         {({min, count, removeRow}) => (
           <SfpButtonInner onClick={removeRow} min={min} count={count} {...props}  />
         )}
      </SfPanelContext.Consumer>
    )
  } else {
    return (
      <SfpButtonInner {...props} />
    )
  }
}

export const SfpButtonInner = ({
  formKey, doRef, style, dbtn, itemKey, styleStr, title, span,
  buttonType, buttonAction, buttonShape,
  max, min, count,
  ...otherProps }) => {
  const sls = convertStyleStr(styleStr);
  const Icon = buttonAction ? BUTTON_ACTION[buttonAction] : null;
  const buttonText = title ? <IntlMessages id={`user.form.${formKey}.button.${itemKey}`} text={title} /> : null

  let disabled = false;
  if (typeof max === 'number' && typeof count === 'number') {
    if (count >= max) {
      disabled = true;
    }
  }
  if (typeof min === 'number' && typeof count === 'number') {
    if (count <= min) {
      disabled = true;
    }
  }
  if (!disabled) {
    return (
      <Button key={itemKey} ref={doRef} style={sls}
      type={buttonType} shape={buttonShape} {...otherProps}
      icon={<Icon/>}>{buttonText}</Button>
    );
  } else {
    return null;
  }
}

SfButton.craft = {
  displayName: "Button",
  related: {
    settings: SfButtonSetting
  }
}

SfButton.validate = (props, {extraParams, formData}) => {
  props.formKey = formData.formKey;
}

registerComponent({
  key:"SfButton",
  component: SfButton,
  runtimeComponent: SfpButton,
  type: "Component",
});

export default SfButton;