import { SlidersOutlined } from "@ant-design/icons";
import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Slider, Switch } from "antd";
import { Form, Input } from "components/Form";
import React, {useState} from "react";
import IntlMessages from "util/IntlMessages";
import {
  colSls, convertRules, convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate
} from "./common";

const SfSliderSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="min" label="min">
        <InputNumber className="item-property"/>
      </Form.Item>
      <Form.Item name="max" label="max">
        <InputNumber className="item-property"/>
      </Form.Item>
      <Form.Item name="step" label="step">
        <InputNumber className="item-property"/>
      </Form.Item>
      <Form.Item name="range" label="range" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="reverse" label="reverse" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="vertical" label="vertical" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfSlider = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpSlider doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const SfpSlider = ({
  doRef, form, condistyles, className, style, dbtn, hideInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, volitate, skipcopy,
  inputref, styleStr, children, ...otherProps }) => {
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const [fxr, setFxr] = useState({});
  const newRules = convertRules(rules);
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name} label={title}
              shouldUpdate={fx} hidden={fxr.hidden} rules={fxr.hidden || otherProps.disabled ? null : newRules}
              labelAlign={labelAlign} labelCol={lcs} wrap >
              <Slider inputref={inputref} style={sls} {...otherProps}/>
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

SfpSlider.render = ({itemKey,range}) => (value, record, index) => {
  return <Slider key={itemKey+"_"+index} value={value} range={range} />
}

SfSlider.craft = {
  displayName: "Slider",
  related: {
    settings: SfSliderSetting
  }
}

SfSlider.validate = (props, {parents, container, extraParams, formData}) => {
  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: props.range ? "json[]" : "number",
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      sortable: true,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
    });
  }
}

SfSlider.isSearchable = true;
SfSlider.isRulable = true;

registerComponent({
  key: "SfSlider",
  component: SfSlider,
  runtimeComponent: SfpSlider,
  template: <SfSlider itemKey={getId('slider')} className="sf-slider wrap"
    title={"Slider"} span={24} labelColStr="span:6" />,
  title: <IntlMessages id="system.form.library.slider" text="Slider" />,
  icon: <SlidersOutlined  className="react-icons icon-antd"/>,
  type: "Component",
  sequence: 10,
});

export default SfSlider;