import { ThunderboltOutlined } from "@ant-design/icons";
import { useEditor, useNode } from "@craftjs/core";
import { InputNumber, Switch } from "antd";
import classNames from 'classnames';
import { Form, Input, Select } from "components/Form";
import { useEffect, useRef, useState } from "react";
import IntlMessages from 'util/IntlMessages';
import { dataExplorer } from "../../../../parse-api";
import { getArraysIntersection, getArraysUnion } from "../../../../util/algorithm";
import { convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent } from "./common";

const RULE_TYPE = [
  "string","number","boolean","method","regexp","integer","float","array","object","enum","date","url","hex","email","any"
]

const ACTION_TYPES = ["ValidationRule"];

const SfRuleSetting = () => {
  const { rulable } = useEditor(EditorCollector);
  const [allActionPolicies, setAllActionPolicies] = useState([]);
  const mounted = useRef();

  useEffect(() => {
    mounted.current = true;
    const fetchData = async () => {
      const policies = await dataExplorer.searchAll("SystemActionPolicy", null, "actionName");
      setAllActionPolicies(policies.filter(p => getArraysIntersection(p.actionTypes, ACTION_TYPES).length > 0));
    }
    fetchData();
    return () => {
      mounted.current = false;
    }
  },[])

  return (
    <>
      <Form.Item name="parentkeys" label="parents">
        <Select className="item-property" options={rulable} mode="multiple" />
      </Form.Item>
      <Form.Item name="type" label="type">
        <Select className="item-property" placeholder="type" allowClear showSearch>
          {RULE_TYPE.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name="required" label="required" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="unique" label="unique" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="noupdate" label="no update" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="len" label="len">
        <InputNumber min="0" className="item-property" />
      </Form.Item>
      <Form.Item name="max" label="max">
        <InputNumber min="0" className="item-property" />
      </Form.Item>
      <Form.Item name="min" label="min">
        <InputNumber min="0" className="item-property" />
      </Form.Item>
      <Form.Item name="pattern" label="pattern">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="actions" label="actions">
        <Select className="item-property" placeholder="actions" allowClear showSearch mode={"multiple"}>
        {allActionPolicies.map(a => <Select.Option key={a.actionKey} value={a.actionKey}>{a.actionName}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name="message" label="message">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="warningOnly" label="warning only" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="whitespace" label="whitespace" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
    </>
  )
}

export const SfRule = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpRule doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const SfpRule = ({ ...props }) => {
  if (props.doRef) {
    return (
      <SfpRuleInner {...props} />
    )
  } else {
    return null
  }
}

export const SfpRuleInner = ({
  doRef, style, dbtn, onClick, dashed, plain, orientation, type, index,
  itemKey, className, styleStr, title,
  disabled, ...otherProps }) => {
  const prefixCls = "ant-divider";
  const ltype = "horizontal";
  const lorientation = "center"
  const orientationPrefix = lorientation && lorientation.length > 0 ? `-${lorientation}` : lorientation;
  const hasChildren = !!title;
  const classString = classNames(
    prefixCls,
    `${prefixCls}-${ltype}`,
    {
      [`${prefixCls}-with-text`]: hasChildren,
      [`${prefixCls}-with-text${orientationPrefix}`]: hasChildren,
      [`${prefixCls}-dashed`]: !!dashed,
      [`${prefixCls}-plain`]: !!plain,
    },
    className,
  );
  let sls = convertStyleStr(styleStr);
  if (style) sls = sls ? { ...sls, ...style } : style;
  if (doRef) {
    const height = "18px";
    const width = type === "vertical" ? "18px" : null;
    sls = { ...sls, height: height, width: width }
    return (
      <div ref={doRef} style={sls} className={classString}
      ><span className="title">Rule: {itemKey} </span>{dbtn}</div>
    );
  } else {
    return null;
  }
}

const prepareRule = (props, key) => {
  const rule = {
    formKey: props.formKey,
    itemKey: key,
    type: props.type,
    required: props.required,
    enum: props.enum,
    len: props.len,
    min: props.min,
    max: props.max,
    message: props.message,
    warningOnly: props.warningOnly,
    whitespace: props.whitespace,
    pattern: props.pattern,
    actions: props.actions,
  };
  return rule;
}

SfRule.validate = (props, {parents, container, extraParams, formData}) => {
  props.formKey = formData.formKey;
  const itemKey = props.itemKey;
  const parentkeys = props.parentkeys;
  const parentTypes = [];
  if (!parentkeys || parentkeys.length === 0) {
    return <IntlMessages id="system.form.validate.parent-key-missing" text="item ({itemKey}) - parent is missing." values={{itemKey:itemKey}}/>
  }
  for (const element of parentkeys) {
    const key = element;
    const className = parents[key]?.type.resolvedName;
    if (className === "SfInputNumber" || className === "SfAutoIncrement") {
      parentTypes.push("number");
    } else {
      parentTypes.push("string");
    }
    if (!parents[key]) {
      return <IntlMessages id="system.form.validate.parent-key-not-found" text="item ({itemKey}) - parent key not found ({parent})." values={{parent:key,itemKey:itemKey}}/>
    }
  }
  for (const element of parentkeys) {
    const key = element;
    const parentNode = parents[key].props;
    if (!parentNode.rules) {
      parentNode.rules = [];
    }
    parentNode.rules.push(prepareRule(props, key));
    if (props.noupdate) {
      parentNode.noupdate = true;
    }
  }
  if (props.unique) {
    const uniqueKeys = extraParams.uniqueKeys || {};
    if (!extraParams.uniqueKeys) extraParams.uniqueKeys = uniqueKeys;
    if (container?.props?.table) {
      uniqueKeys[itemKey]={
        keys: parentkeys,
        types: parentTypes,
        table: container.props.table,
      }
    } else if (container.props.itemKey) {
      uniqueKeys[itemKey]={
        keys: parentkeys,
        types: parentTypes,
        table: container.props.itemKey,
      }
    } else {
      const parent = container?.props?.itemKey;
      return <IntlMessages id="system.form.validate.table-not-defined" text="item ({itemKey}) - table not found for unique rule ({parent})." values={{parent,itemKey:itemKey}}/>
    }
  }
  if (props.actions) {
    extraParams.validationRules = extraParams.validationRules || [];
    extraParams.validationRules = getArraysUnion(props.actions, extraParams.validationRules);
  }
}

SfRule.craft = {
  displayName: "Rule",
  related: {
    settings: SfRuleSetting
  }
}

registerComponent({
  key:"SfRule",
  component: SfRule,
  runtimeComponent: SfpRule,
  template: <SfRule itemKey={getId('rule')} className="sf-divider" title="Rule" plain dashed/>,
  title: <IntlMessages id="system.form.library.rule" text="Rule"/>,
  icon: <ThunderboltOutlined  className="react-icons icon-antd"/>,
  type: "Logic",
  sequence: 1,
});

export default SfRule;