import { CalendarOutlined } from "@ant-design/icons";
import { useEditor, useNode } from "@craftjs/core";
import { Col, DatePicker, InputNumber, Switch } from "antd";
import { Form, Input, Select } from "components/Form";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { momentDate, isDate } from "util/algorithm";
import IntlMessages from "util/IntlMessages";
import { dateText, isEmpty, log, momentTimezoneDate } from "../../../../util/algorithm";
import {
  generateTooltip,colSls, convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate, updateTableCell, convertRules
} from "./common";

const SfDateSetting = () => {
  const form = Form.useFormInstance();
  const stringMode = Form.useWatch('stringMode', form);
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="placeholder" label="placeholder">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="format" label="format">
        <Input className="item-property"/>
      </Form.Item>
      <Form.Item name="popupStyleStr" label="popup style">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="picker" label="picker">
        <Select className="item-property">
          <Select.Option value="date">date</Select.Option>
          <Select.Option value="week">week</Select.Option>
          <Select.Option value="month">month</Select.Option>
          <Select.Option value="quarter">quarter</Select.Option>
          <Select.Option value="year">year</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="showTime" label="show time" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="showNow" label="show now" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="stringMode" label="text mode" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="withTimezone" label="w/ timezone" valuePropName="checked">
        <Switch className="item-property" disabled={stringMode} />
      </Form.Item>
      <Form.Item name="tiptext" label="tooltip text" >
        <Input.TextArea
          className="item-property"
          autoSize allowClear
        />
      </Form.Item>
      <Form.Item name="tiplink" label="tooltip link" >
        <Input className="item-property" type="url"/>
      </Form.Item>
      <Form.Item name="useAlternativePopupContaier" label="alter cntr" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="size" label="size">
        <Select className="item-property">
          <Select.Option value="large">large</Select.Option>
          <Select.Option value="middle">middle</Select.Option>
          <Select.Option value="small">small</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="allowClear" label="allow clear" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="bordered" label="bordered" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="inputReadOnly" label="choose only" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hidden" label="hidden" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="editInTable" label="edit col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="isSelectKey" label="select key" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfDate = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpDate doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

const VALID_CLASS = /(ant-picker)/i
const EXCLUDED_CLASS = /(savedFlowKey|savedFormKey|savedPageKey)/i
const VALID_DATE_CLASS = /(ant-picker-dropdown)/
const getPopupContainer = (trigger) => {
  if ((trigger?.className || '').match(VALID_CLASS) || (trigger?.className || '').match(VALID_DATE_CLASS)) {
    if (trigger?.parentElement) {
      if ((trigger.parentElement.className || '').match(EXCLUDED_CLASS)) {
        return document.body;
      }
    }
    return trigger?.parentElement || document.body;
  } else {
    return document.body;
  }
}

export const MyDatePicker = ({value, noupdate, disabled, onChange, picker, showTime, showDateRange, format, stringMode, withTimezone, allowClear, open, ...props}) => {
  const ref = useRef();
  const [myValue, setMyValue] = useState();
  const [myOpen, setMyOpen] = useState(open);
  const doMyChange = (value) => {
    if (stringMode) {
      if (value) value = value.format(format);
    } else if (withTimezone) {
      if (value) value = momentTimezoneDate(value, picker, false, showTime, format);
    } else {
      if (value) value = momentDate(value, picker, true, showTime, format);
    }
    if (onChange) onChange(value);
  }
  const doMyOpenChange = (newOpen) => {
    setMyOpen(newOpen);
    if (open && props.onBlur) {
      props.onBlur();
    }
  }
  const customWeekStartEndFormat = () => {
    if (!format) format='YYYY-MM-DD'
    return `${moment(value).startOf('week').format(format)} ~ ${moment(value).endOf('week').format(format)}`;
  }

  useEffect(() => {
    if (typeof value === 'string') {
      try {
        if (stringMode) {
          setMyValue(moment(value, format));
        } else if (withTimezone) {
          setMyValue(moment(value));
        } else if (isDate(value)) {
          setMyValue(moment(value));
        } else {
          setMyValue(null);
        }
      } catch (err) {
        log("failed to set date value", err);
        setMyValue(null);
      }
    } else {
      setMyValue(value);
    }
  },[format, stringMode, value, withTimezone])
  const timeFormat = showTime && format && !isEmpty(format.replace(/[^Hms:]/g,'')) ? {format: format.replace(/[^Hms:]/g,'')} : false;
  if (ref.current === undefined) ref.current = noupdate && value !== undefined;
  return <DatePicker value={myValue} disabled={disabled || ref.current} {...props} open={myOpen} onOpenChange={doMyOpenChange} allowClear={allowClear} showTime={timeFormat} format={picker === 'week' && showDateRange ? customWeekStartEndFormat : format} picker={picker} onChange={doMyChange}/>
}

export const SfpDate = ({
  doRef, form, condistyles, className, style, dbtn, hidden, hideInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, volitate, skipcopy,useAlternativePopupContaier,
  inputref, styleStr, popupStyleStr, tiptext, tiplink, ...otherProps }) => {
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const pus = convertStyleStr(popupStyleStr);
  const [fxr, setFxr] = useState({});
  const newRules = convertRules(rules, 'date');
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name} label={title} tooltip={generateTooltip(tiptext,tiplink)}
              shouldUpdate={fx} hidden={fxr.hidden || (!doRef && hidden)} rules={fxr.hidden || hidden || otherProps.disabled ? null : newRules}
              labelAlign={labelAlign} labelCol={lcs} wrap>
              <MyDatePicker inputref={inputref} style={sls} popupStyle={pus} getPopupContainer={useAlternativePopupContaier ? getPopupContainer : undefined} {...otherProps} />
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

export const EditableDateRenderer = ({
  value, record, index, form, tableKey, onRowChange, editInTable, itemKey, tableColWidth,
  disabled, allowClear, format, stringMode, showTime, showNow, showDateRange, withTimezone}) => {
  const [editing, setEditing] = useState(false);
  const [myValue, setMyValue] = useState(value);
  const leditInTable = editInTable && !disabled;
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, [])
  useEffect(() => {
    setMyValue(value);
  }, [value]);
  const onClick = (event) => {
    setEditing(true);
    event.preventDefault();
    event.stopPropagation();
  }
  if (editing) {
    const onChange = (value) => {
      try {
        setMyValue(value);
        const event = null;
        updateTableCell({form, tableKey, record, itemKey, myValue: value, onRowChange, setEditing, event, index});
        setEditing(false);
      } catch (e) {
        console.error(e);
      }
    }
    const onBlur = () => {
      setEditing(false);
    }
    const className = "row-editing table-cell-date"
    const open = true;
    const props = {value: myValue, record, index, allowClear, format, stringMode, showTime, showNow, showDateRange, withTimezone, className, onChange, onBlur, open};
    if (tableColWidth) props.style = {"minWidth": `${tableColWidth}px`,"maxWidth": `${tableColWidth}px`}
    return <MyDatePicker {...props} />
  } else {
    const editingProps = leditInTable ? {value: myValue, record, index, format, stringMode, showTime, showNow, showDateRange, withTimezone, className: "row-editable table-cell-date", onClick} : {};
    if (tableColWidth) editingProps.style = {"minWidth": `${tableColWidth}px`, "maxWidth": `${tableColWidth}px`}
    return <DateRenderer {...editingProps}/>;
  }
}

export const DateRenderer = ({className, style, onClick, itemKey, format, value, index}) => {
  return <div className={className} style={style} onClick={onClick} key={itemKey+"_"+index}>{dateText(value, format)}</div>;
}

SfpDate.render = ({ editInTable, itemKey, mask, tableColWidth, disabled, allowClear, format, stringMode, showTime, showNow, showDateRange, withTimezone }, form, tableKey, onRowChange,getPopupContainer) => (value, record, index) => {
  const props = {editInTable, itemKey, mask, tableColWidth, disabled, allowClear, format, stringMode, showTime, showNow, showDateRange, withTimezone, value, record, index, form, tableKey, onRowChange,getPopupContainer}
  if (editInTable) {
    return <EditableDateRenderer {...props}/>
  } else {
    return <DateRenderer {...props}/>
  }
}

SfDate.craft = {
  displayName: "Date",
  related: {
    settings: SfDateSetting
  }
}

SfDate.validate = (props, {parents, container, extraParams, formData}) => {
  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "date",
      picker: props.picker,
      format: props.format,
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      showTime: props.showTime,
      stringMode: props.stringMode,
      showNow: props.showNow,
      showDateRange: props.showDateRange,
      withTimezone: props.withTimezone,
      sortable: true,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
    });
  }
  if (props.stringMode && props.withTimezone) {
    props.withTimezone = false;
  }
}

SfDate.isSearchable = true;
SfDate.isRulable = true;

registerComponent({
  key:"SfDate",
  component: SfDate,
  runtimeComponent: SfpDate,
  template: <SfDate itemKey={getId('date')} className="sf-date wrap" showTime={false}
            title={"Date"} format="YYYY-MM-DD" span={24} labelColStr="span:6" />,
  title: <IntlMessages id="system.form.library.date" text="Date" />,
  icon: <CalendarOutlined className="react-icons icon-antd"/>,
  type: "Component",
  sequence: 3,
});

export default SfDate;