import { useEditor, useNode } from "@craftjs/core";
import { Col } from "antd";
import { Form, Input } from "components/Form";
import React, { useState } from "react";
import { MdVerified } from "react-icons/md";
import IntlMessages from "util/IntlMessages";
import Captcha from "../../../../components/Form/Captcha";
import {
  colSls, convertRules, convertStyleStr, deleteButton, EditorCollector, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate
} from "./common";

const SfCaptchaSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfCaptcha = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpCaptcha doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const SfpCaptcha = ({
  doRef, form, condistyles, className, style, dbtn, hidden, hideInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, volitate, skipcopy,
  inputref, styleStr, children, isSelectKey, ...otherProps }) => {
  const lcs = convertStyleStr(labelColStr);
  const [fxr, setFxr] = useState({});
  const newRules = convertRules(rules);
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name} label={title} placeholder={otherProps.placeholder}
               shouldUpdate={fx} hidden={fxr.hidden || (!doRef && hidden)} rules={fxr.hidden || hidden || otherProps.disabled ? null : newRules}
              labelAlign={labelAlign} labelCol={lcs} wrap>
              <Captcha/>
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

SfCaptcha.craft = {
  displayName: "Captcha",
  related: {
    settings: SfCaptchaSetting
  }
}

SfCaptcha.isRulable = true;

registerComponent({
  key:"SfCaptcha",
  component: SfCaptcha,
  runtimeComponent: SfpCaptcha,
  template: <SfCaptcha itemKey={'captcha'} className="sf-captcha wrap"
            title={"Captcha"} span={24} labelColStr="span:6" volitate={true} />,
  title: <IntlMessages id="system.form.library.captcha" text="Captcha" />,
  icon: <MdVerified className="react-icons icon-md"/>,
  type: "Component",
  sequence: 27,
});

export default SfCaptcha;