const initData = {
  "data": null,
  "currId": 2,
  "pageKey": "page-1",
  "pageName": "Unnamed Page",
  "saved": false,
}
export default initData;

export const defaultCss = `

.sp-canvas {
  margin-left: unset ;
  margin-right: unset ;
  border-color: rgba(224,224,224,1);
  border-style: solid;
  border-width: 1px;
  background-color: rgba(255,255,255,1);
}

.sf-page .sp-canvas {
  border: none;
}

.sp-page .sp-canvas {
  border: none;
}

.sp-canvas .ant-col:not(.ant-form-item-control):not(.ant-form-item-label) {
  padding-left: unset;
  padding-right: unset;
}

.sp-canvas .ant-row {
  margin-left: unset ;
  margin-right: unset ;
}

.sp-canvas .ant-col {
  margin-left: unset ;
  margin-right: unset ;
}

.sp-panel {
  min-height: 50px;
}

.sp-panel .ant-row {
  margin-left: unset ;
  margin-right: unset ;
}

.sp-text {
  min-height: 25px;
}

.sp-image {
  min-height: 25px;
}

.sp-button .ant-btn {
  margin-bottom: unset;
  margin-right: unset;
}

.sp-button .btn-icon {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 0px;
  line-height: 14px;
  vertical-align: middle;
  margin-bottom: unset;
}

.sp-button .btn-text {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 8px;
  padding-left: 8px;
  line-height: 14px;
  vertical-align: middle;
  margin-bottom: unset;
}

.ant-btn-round.sp-button {
  padding-top: unset;
}

.ant-divider-horizontal.sp-divider::after,.ant-divider-horizontal.sp-divider::before {
  transform: none;
}

.sp-markdown .editor-preview {
  background: unset;
}

.select-option .icon-text{
  padding-bottom: 12px;
  padding-left: 30px;
  display:inline;
  vertical-align: middle;
}

.select-option .select-icon {
  vertical-align: middle;
  position: absolute;
}

.icon-text{
  padding-bottom: 12px;
  display:inline;
  vertical-align: middle;
}

.select-icon {
  vertical-align: middle;
}

.sp-canvas .ant-col.sp-datagrid-page-ctrl {
  padding: 10px;
  text-align: center;
}

@media (min-width: 576px) {
  .sp-form .ant-col {
    padding-left: 18px;
    padding-right: 18px;
  }
}

.sf-page .page-main-panel div.page-main-panel > .ant-col,
.sf-page .page-main-panel div.form-main-panel > .ant-col,
.sp-page .page-main-panel div.page-main-panel > .ant-col,
.sp-page .page-main-panel div.form-main-panel > .ant-col,
.sf-page .page-main-panel:not(:has(.page-main-panel,.form-main-panel)) .ant-col,
.sp-page .page-main-panel:not(:has(.page-main-panel,.form-main-panel)) .ant-col
 {
  padding-left: 0px;
  padding-right: 0px;
}

.sp-form .ant-row.form-main-panel {
  margin-left: -16px;
  margin-right: -16px;
}

.shadow-body .ant-row.form-main-panel {
  margin-left: -16px;
  margin-right: -16px;
}

.sp-form .widget-dataentry-advance-search .ant-col {
    padding-left: 0px;
    padding-right: 0px;
}

.sp-table .ant-btn {
  margin-bottom: 0px;
}

.sp-table .row-editable {
  cursor: pointer;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
}

.sp-table .ant-table-cell:has(.row-editable) {
  background-color: #ffffe0;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.sp-table .ant-table-cell.ant-table-cell-row-hover:has(.row-editable) {
  background-color: #ffffca;
}

.sp-table .row-editing {
  cursor: pointer;
  display: flex;
}

.sp-table .table-cell-textarea {
  white-space: pre-wrap;
  overflow: auto;
  min-height: 1rem;
}

.sp-menu.menu .ant-menu-horizontal:not(.ant-menu-dark) > *::after {
  border-bottom: none !important;
}

.sp-canvas .sp-select .ant-select {
  width: 100%
}

.sp-canvas .sp-transfer .ant-select {
  width: 100%
}

.sp-canvas .sp-inputnumber .ant-input-number {
  width: 100%
}

.sp-canvas .ant-transfer-list {
  width: 50%
}

.sp-canvas .ant-tabs-tabpane.sp-tabspane:not(.ant-tabs-tabpane-active) {
  display: none;
}

.sp-form:has(>.form-main-panel) {
  container-type: inline-size;
  container-name: form-main-panel-cntr;
}

@container form-main-panel-cntr (inline-size > 1000px) {
  .form-main-panel {
      padding-left: calc((100% - 1000px) / 2);
      padding-right: calc((100% - 1000px) / 2);
  }
}

.sp-splitter {
  display: flex;
}

.sp-splitter .splitter-left {
  position: relative;
  float: left;
}

.sp-splitter .splitter-right {
  flex: 1;
  padding: 1px;
}

.sp-splitter .resize-main {
  top: 0; right: 5px; bottom: 0; left: 0;
  padding: 1px;
}

.sp-splitter .resize-bar {
  width: 200px;
  min-height: 300px;
  resize: horizontal;
  cursor: col-resize;
  opacity: 0;
  overflow: scroll;
}

.sp-splitter .resize-line {
  position: absolute;
  top: 0; right: 0; bottom: 0;
  border-right: 2px solid #eee;
  border-left: 1px solid #bbb;
  pointer-events: none;
}

.sp-splitter .resize-bar:hover ~ .resize-line,
.sp-splitter .resize-bar:active ~ .resize-line {
  border-left: 1px dashed skyblue;
}

.sp-splitter .resize-bar::-webkit-scrollbar {
  width: 200px;
  min-height: 300px;
  height: var(--hgt);
}

.ant-col.hidden {
  display: none;
}

.craft-page-frame-panel .ant-col.hidden {
  display: block !important;
  background-color: lightgrey;
}

`