import { END_TRANSLATE, START_FORM_TRANSLATE, START_TRANSLATE } from "../../constants/ActionTypes";
import { setLocalStorageItem } from "../../util/algorithm";

export function startTranslate() {
  setLocalStorageItem('translate', "Y");
  return (dispatch) => {
    dispatch({
      type: START_TRANSLATE
    });
  }
}

export function endTranslate() {
  setLocalStorageItem('translate', "N");
  return (dispatch) => {
    dispatch({
      type: END_TRANSLATE
    });
  }
}

export function startFormTranslate(payload) {
  return (dispatch) => {
    dispatch({
      type: START_FORM_TRANSLATE,
      payload: payload
    })
  }
}






