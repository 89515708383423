import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import { MdOutlineCancel } from "react-icons/md";

export const rgbaStr = (color) => {
  return `rgba(${color.r},${color.g},${color.b},${color.a})`;
}
const PATTERN = /^rgba\((\d+),(\d+),(\d+),(\d+)\)$/
export const rgbaObj = (text) => {
  let value = {r:'255',g:'255',b:'255',a:'1'};
  if (text && text.match) {
    if (text.match(PATTERN)) {
      value = {
        r: text.replace(PATTERN, "$1"),
        g: text.replace(PATTERN, "$2"),
        b: text.replace(PATTERN, "$3"),
        a: text.replace(PATTERN, "$4"),
      }
    }
  }
  return value
}

export const ColorPicker = ({value, onChange, defaultValue, allowClear, defaultOpen, disabled, ...props}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(!!defaultOpen);
  const [color, setColor] = useState(defaultValue ? rgbaObj(defaultValue) : {r:'255',g:'255',b:'255',a:'1'})
  useEffect(()=> {
    setColor(rgbaObj(value));
  }, [value])
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDisplayColorPicker(!displayColorPicker);
  }
  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDisplayColorPicker(false);
    if (onChange) onChange(value);
  }
  const handleChange = (color) => {
    setColor(color.rgb);
    if (onChange) onChange(rgbaStr(color.rgb));
  };
  const handleRemove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (onChange) onChange(null);
  }
  const styles = {
    color: {
      width: '36px',
      height: '14px',
      borderRadius: '2px',
      background: rgbaStr(color),
    },
    swatch: {
      padding: '5px',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    popover: {
      position: 'absolute',
      zIndex: '2',
      top:'-305px',
      left:'0px',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  };
  return (
    <div className='color-picker'>
      <div style={ styles.swatch } onClick={ handleClick }>
        <div style={ styles.color } />
      </div>
      {(allowClear && !disabled) && <Button className={'remove-btn'} size="small" type="text" icon={<MdOutlineCancel/>} onClick={handleRemove}/>}
      { (displayColorPicker && !disabled) ? <div style={ styles.popover }>
        <div style={ styles.cover } onClick={ handleClose }/>
        <SketchPicker color={ color } onChange={ handleChange } onClick={(e)=>{
          e.preventDefault();
          e.stopPropagation();
        }}/>
      </div> : null }
    </div>
  )
}