import { useEditor, useNode } from "@craftjs/core";
import { Col, Steps } from "antd";
import { Form, Input, Select } from "components/Form";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { IoFootstepsSharp } from "react-icons/io5";
import IntlMessages from "util/IntlMessages";
import { log } from "../../../../util/algorithm";
import {
  colSls, convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate
} from "./common";


export const INITIAL_CONTENT = `
{
  "current":1,
  "steps": [
    {
      "title":"Order Created",
      "description":"This is a description."
    },
    {
      "title":"In Progress",
      "subTitle": "Left 00:00:08",
      "description":"This is a description."
    },
    {
      "title":"Finished",
      "description":"This is a description."
    }
  ]
}
`
const SfStepsSetting = () => {
  return (
    <>
      <Form.Item name="size" label="size">
        <Select className="item-property">
          <Select.Option value="default">default</Select.Option>
          <Select.Option value="small">small</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="initialContent" label="initial content"
        labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
        <Input.TextArea className="item-property" allowClear autoSize/>
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfSteps = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions);
  return (
    <SfpSteps doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const MySteps = ({value, initialContent, onChange, ...props}) => {
  const [myValue, setMyValue] = useState(value);
  useEffect(() => {
    setMyValue(value);
  },[value])
  let content = null;
  if (isEmpty(myValue) && !isEmpty(initialContent)) {
    try {
      content = JSON.parse(initialContent);
    } catch (e) {
      log("error in parsing initial content", e);
      log("initial content", initialContent);
    }
  } else if (!isEmpty(myValue)) {
    try {
      content = JSON.parse(myValue);
    } catch (e) {
      log("error in parsing myValue", e);
      log("myValue", myValue);
    }
  }
  return <Steps current={content?.current} {...props}>
    {content?.steps?.map(s => {
      return <Steps.Step key={s.title} title={s.title} description={s.description} subTitle={s.subTitle} />
    })}
  </Steps>
}

export const SfpSteps = ({
  doRef, form, condistyles, className, style, dbtn, hideInTable, tableColWidth,  tableColTitle,
  itemKey, value, initialContent, volitate,
  inputref, styleStr, format, ...otherProps }) => {
  const sls = convertStyleStr(styleStr);
  const [fxr, setFxr] = useState({});
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name}
               shouldUpdate={fx} hidden={fxr.hidden}
              wrap>
              <MySteps initialContent={initialContent} style={sls} {...otherProps} />
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

SfSteps.craft = {
  displayName: "Steps",
  related: {
    settings: SfStepsSetting
  }
}

registerComponent({
  key:"SfSteps",
  component: SfSteps,
  runtimeComponent: SfpSteps,
  template: <SfSteps itemKey={getId('steps')} className="sf-steps wrap"
            title={"Steps"} span={24} size={"small"} volitate={true} initialContent={INITIAL_CONTENT} />,
  title: <IntlMessages id="system.form.library.steps" text="Steps" />,
  icon: <IoFootstepsSharp className="react-icons icon-io5"/>,
  type: "Component",
  sequence: 22,
});

export default SfSteps;