import { useEditor, useNode } from "@craftjs/core";
import { Checkbox, Col, InputNumber, Row, Select, Spin, Switch } from "antd";
import { Form, Input } from "components/Form";
import { selectApi } from "parse-api";
import React, { useEffect, useRef, useState } from "react";
import { IoCheckboxOutline } from "react-icons/io5";
import { compare, getArrayElementByAttribute, log } from "util/algorithm";
import IntlMessages from "util/IntlMessages";
import { settingsSignal } from "../../../../util/signal";
import {
  colSls, convertRules, convertStyleStr, deleteButton, EditorCollector, getId, getParentName, NodeCollector, refreshOptions, registerComponent, SelectCollector, SfLayoutSetting, SfPanelContext, shouldUpdate
} from "./common";
import { SelectRenderer } from "./SfSelect";

const SfCheckboxSetting = () => {
  const { localSelects, mainSelects } = useEditor(SelectCollector);
  const { locale } = settingsSignal;
  const [systemSelectList, setSystemSelectList] = useState([]);
  const [selects, setSelects] = useState([]);
  const [selected, setSelected] = useState({});
  const [values, setValues] = useState({});
  const [options, setOptions] = useState([]);
  const [filter, setFilter] = useState([]);
  const selectedKey = useRef();
  const mounted = useRef();

  useEffect(() => {
    mounted.current = true;
    const fetchData = async () => {
      const list = await selectApi.loadSystemSelect();
      setSystemSelectList(list);
      // convert to option
      const options = list.map(l => {
        return {
          value: l.selectKey,
          label: l.name,
        }
      })
      if (mounted.current) setSelects(options);
    }
    fetchData();
    return () => {
      mounted.current = false;
    }
  }, [])

  const refreshOptionTypes = () => {
    if (selectedKey.current && systemSelectList?.length > 0) {
      if (selected && selected?.selectKey !== selectedKey.current) {
        log("refreshOptionTypes", selected?.selectKey, selectedKey.current);
        const sel = getArrayElementByAttribute(systemSelectList, "selectKey", selectedKey.current);
        log("refreshOptionTypes", systemSelectList, sel);
        setSelected(sel);
        if (sel && sel.optionTypes) {
          if (mounted.current) setFilter(sel.optionTypes.map(f => ({label:f, value:f})));
        } else {
          if (mounted.current) setFilter([]);
        }
        updateFilterOption({ ...values, "selectkey": selectedKey.current });
      }
    }
  }

  const onIsUseCodeChanged = (value) => {
    if (mounted.current) updateFilterOption({ ...values, "isUseCode": value });
  }

  const onFilterChanged = (value) => {
    if (mounted.current) updateFilterOption({ ...values, "filter": value });
  }

  const updateFilterOption = async (values) => {
    if (mounted.current) setValues(values);
    if (values.selectkey) {
      let list = await refreshOptions(locale, values.selectkey, values.isUseCode);
      if (filter) list = list.filter(l => values.filter ? l.optionType === values.filter : true);
      if (mounted.current) setOptions(list.map(({value,label})=>({value,label})));
    }
  }

  const onSelectKeyChange = (value) => {
    refreshOptionTypes();
    selectedKey.current = value;
  }

  const SelectKey = ({value, ...props}) => {
    useEffect(()=> {
      onSelectKeyChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[value])
    return <Select {...props} value={value} />
  }

  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="selectkey" label="select">
        <SelectKey className="item-property" options={selects} allowClear showSearch/>
      </Form.Item>
      <Form.Item name="filter" label="filter">
        <Select className="item-property" options={filter} allowClear onChange={onFilterChanged}/>
      </Form.Item>
      <Form.Item name="parentkeys" label="parents">
        <Select className="item-property" options={[...mainSelects,...localSelects]} mode="multiple" />
      </Form.Item>
      <Form.Item name="maxOptionCount" label="max count">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="filterOption" label="filter option">
        <Select className="item-property" mode="multiple" options={options} />
      </Form.Item>
      <Form.Item name="isUseCode" label="use code" valuePropName="checked">
        <Switch className="item-property" onChange={onIsUseCodeChanged} />
      </Form.Item>
      <Form.Item name="optionGrid" label="option grid">
        <InputNumber min="1" max="4" className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="isSelectKey" label="select key" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SystemCheckbox = ({ selectkey, filter, parentkeys, parentValues, maxOptionCount, filterOption, isUseCode, optionGrid, ...otherProps }) => {
  const { locale } = settingsSignal;
  const [fetching, setFetching] = useState(false);
  const [list, setList] = useState([]);
  const [options, setOptions] = useState([]);
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, []);

  useEffect(() => {
    const options = list.sort((a, b) => compare(a.displayOrder || a.label, b.displayOrder || b.label))
      .filter((option, index) =>
        (!maxOptionCount || index < maxOptionCount) &&
        (!filterOption || filterOption.length === 0 || filterOption.indexOf(option.value) !== -1)
      )
      .map(l => {
        if (optionGrid) {
          return <Col key={l.value} span={24 / parseInt(optionGrid)}><Checkbox value={l.value} >{l.label}</Checkbox></Col>
        } else {
          return <Checkbox key={l.value} value={l.value} >{l.label}</Checkbox>
        }
      })

    if (mounted.current) setOptions(options);
  }, [list, filterOption, maxOptionCount, optionGrid]);

  useEffect(() => {
    refreshOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectkey, filter, parentkeys, parentValues]);

  const refreshOptions = async () => {
    if (selectkey) {
      if (mounted.current) setFetching(true);
      let list = [];
      if (parentkeys && parentkeys.length > 0) {
        const parentOptionKey = await selectApi.getParentOptionKey(locale, selectkey, isUseCode, parentValues);
        if (parentOptionKey) {
          list = await selectApi.fetchSystemSelectOption(locale, selectkey, isUseCode, parentOptionKey);
        }
      } else {
        list = await selectApi.fetchSystemSelectOption(locale, selectkey, isUseCode);
      }
      if (filter) list = list.filter(l => l.optionType === filter);
      if (mounted.current) setList(list);
      if (mounted.current) setFetching(false);
    }
  }
  if (fetching) {
    return <Spin size="small" />
  } else {
    return <Checkbox.Group {...otherProps}>
      {optionGrid &&
        <Row>
          {options}
        </Row>
      }
      {!optionGrid && options}
    </Checkbox.Group>
  }
}

export const SfCheckbox = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpCheckbox doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const SfpCheckbox = ({
  doRef, form, condistyles, className, style, dbtn, hideInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, volitate, skipcopy,
  selectkey, filter, parentkeys, isUseCode, maxOptionCount, filterOption, optionGrid,
  inputref, styleStr, children, isSelectKey, ...otherProps }) => {
  const [fxr, setFxr] = useState({});
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const mounted = useRef();
  const newRules = convertRules(rules);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, []);
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const parentname = getParentName(ctx);
        let targetForm = form;
        const dependencies = parentkeys ? parentkeys.map(p => {
          if (p.startsWith("main.")) {
            targetForm = ctx?.parentForm ||  targetForm;
            return [p.substr(5)];
          } else {
            return [...parentname, p];
          }
        }) : [];
        const pValues = dependencies.map(d => targetForm?.getFieldValue(d));
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name} label={title}
               shouldUpdate={fx} hidden={fxr.hidden} rules={fxr.hidden || otherProps.disabled ? null : newRules}
              labelAlign={labelAlign} labelCol={lcs} wrap >
              <SystemCheckbox
                selectkey={selectkey}
                filter={filter}
                isUseCode={isUseCode}
                maxOptionCount={maxOptionCount}
                filterOption={filterOption}
                optionGrid={optionGrid}
                parentkeys={parentkeys}
                parentValues={pValues}
                style={sls}
                {...otherProps} />
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

SfpCheckbox.render = ({ selectkey, isUseCode }) => (value, record, index) => {
  const props = {selectkey, isUseCode, value, record, index};
  return <SelectRenderer {...props}/>
}

SfCheckbox.craft = {
  displayName: "Checkbox",
  related: {
    settings: SfCheckboxSetting
  }
}

SfCheckbox.validate = (props, { parents, mainParents, container, extraParams }) => {
  const itemKey = props.itemKey;
  const selectkey = props.selectkey;
  const parentkeys = props.parentkeys;
  if (!selectkey) {
    return <IntlMessages id="system.form.validate.select-key-missing" text="item ({itemKey}) - select is missing." values={{ itemKey: itemKey }} />
  }

  let isArrayParent = false;
  if (parentkeys) parentkeys.forEach(key => {
    if (key.startsWith('main.')) {
      key = key.substr(5);
      if (mainParents[key]?.type.resolvedName === "SfArray") {
        isArrayParent = true;
      }
    } else {
      if (parents[key]?.type.resolvedName === "SfArray") {
        isArrayParent = true;
      }
    }
  })
  if (isArrayParent && parentkeys.length > 1) {
    return <IntlMessages id="system.form.validate.select-one-and-only-one-array-parent" text="item ({itemKey}) - you could only select one and only one array parent." values={{itemKey:itemKey}}/>
  }
  if (isArrayParent) {
    props.selectkey = "SelfReference"
  } else {
    if (props.selectkey === "SelfReference") {
      return <IntlMessages id="system.form.validate.select-key-invalid" text="item ({itemKey}) - Self Reference is only for array parent." values={{itemKey:itemKey}}/>
    }
  }

  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "select",
      mode: "multiple",
      valueIndex: props.isUseCode ? "code" : "name",
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      sortable: true,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
      selectkey: props.selectkey,
      parentkeys: props.parentkeys,
      isUseCode: props.isUseCode,
    });
    if (props.isSelectKey) {
      extraParams.selectConfig.optionTypes.push(props.itemKey);
    }
  }
}

SfCheckbox.isSearchable = true;
SfCheckbox.isRulable = true;

registerComponent({
  key: "SfCheckbox",
  component: SfCheckbox,
  runtimeComponent: SfpCheckbox,
  template: <SfCheckbox itemKey={getId('checkbox')} className="sf-checkbox wrap"
    title={"Checkbox"} span={24} labelColStr="span:6" maxOptionCount={10}/>,
  title: <IntlMessages id="system.form.library.checkbox" text="Checkbox" />,
  icon: <IoCheckboxOutline  className="react-icons icon-io"/>,
  type: "Component",
  sequence: 6,
});

export default SfCheckbox;