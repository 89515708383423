import { message } from 'antd';
import { AccountStore } from '../constants/Account';
import { displayError } from '../lngProvider';
import { GUEST_USER_OBJ, delay, log, removeLocalStorageItem } from '../util/algorithm';
import Parse, { CloudRunWithCache } from './config';
import { parseServerInitializedSignal } from '../util/signal';
import { keycloakApi, samlApi, ssoHelper } from './keycloak-sso';

const createUserWithEmailAndPassword = async function (username, nickname, email, password, captcha) {
  try {
    // Since the signUp method returns a Promise, we need to call it using await
    ssoHelper.resetAllLoginSessionFlags();
    const user = new Parse.User();
    user.set("username", username.toLowerCase());
    user.set("password", password);
    user.set("contactEmail", email?.toLowerCase());
    // other fields can be set just like with Parse.Object
    user.set("nickname", nickname);
    user.set("avatar", null);
    user.set("captcha", captcha);
    const createdUser = await user.signUp();
    log(
      `Success! User ${createdUser.getUsername()} was successfully created!`
    );
    const roles = await getUserRoles(createdUser);
    const authUser = convertUser(createdUser, roles);
    await signOut(true);
    return authUser;
  } catch (error) {
    if (error.message === 'Invalid session token') {
      log(`session token is not valid!`);
      await signOut(true);
    } else {
      throw error;
    }
  }
};

const signInWithEmailAndPassword = async function (username, password) {
  try {
    // Since the signUp method returns a Promise, we need to call it using await
    ssoHelper.resetAllLoginSessionFlags();
    const result = await Parse.Cloud.run('login', { username: username.toLowerCase(), password })
    const loggedInUser = await Parse.User.become(result.user.sessionToken);
    log(
      `Success! User ${loggedInUser.get(
        'username'
      )} has successfully signed in!`
    );
    const currentUser = await getCurrentUser()
    const roles = await getUserRoles(currentUser);
    return convertUser(currentUser, roles);
  } catch (error) {
    // signUp can fail if any parameter is blank or failed an uniqueness check on the server
    log(`Error! ${error}`);
    return error;
  }
};

const getKeycloakLoginUser = async () => {
  try {
    const keycloak = await keycloakApi.getKeycloak(true);
    await keycloak.updateToken(30);
    const token = keycloak.token;
    if (token) {
      const param = {token};
      const result = await CloudRunWithCache("getKeycloakUserInfo", param, true);
      if (result && result.preferred_username) {
        return result.preferred_username;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (error) {
    console.error("list data failed", error);
    return null;
  }
};

const signInWithSession = async function (session) {
  try {
    // Since the signUp method returns a Promise, we need to call it using await
    console.log('signInWithSession()', session)
    if (session === 'guest') {
      ssoHelper.resetAllLoginSessionFlags();
      const currentUser = await getCurrentUser();
      if (currentUser && currentUser.get("username") !== "guest") {
        await signOut(true);
      }
      if (!currentUser || currentUser.get("username") !== "guest") {
        return {
          user: GUEST_USER_OBJ
        };
      }
    } else if (session === 'keycloak') {
      const currentUser = await getCurrentUser();
      const keycloakUserPromise = keycloakApi.getKeycloak();
      let returnUser = null;
      console.log('signInWithSession()', {currentUser})
      await Promise.all([keycloakUserPromise]).then(async (results) => {
        const keycloak = results[0];
        if (!keycloak) {
          throw new Error('Keycloak login is not enabled.')
        }
        console.log('signInWithSession()', {keycloak})
        const preferred_username = keycloak.tokenParsed?.preferred_username?.toLowerCase();
        const email = keycloak.tokenParsed?.email?.toLowerCase();
        const name =  keycloak.tokenParsed?.name;
        const token = keycloak.token;
        if (!keycloak.tokenParsed) {
          console.log("failed to login with Keycloak", keycloak, keycloak.tokenParsed);
          ssoHelper.resetAllLoginSessionFlags();
          throw new Error('Oops! Service is currently unavailable, please try again later.');
        } else if (!name) {
          console.log("failed to login with Keycloak", keycloak, keycloak.tokenParsed);
          ssoHelper.resetAllLoginSessionFlags();
          throw new Error('Please refine the First Name field in Keycloak.');
        }
        ssoHelper.setKeycloakSession();
        if (currentUser && currentUser.username === preferred_username) {
          log("user already login, no action");
          const roles = await getUserRoles(currentUser);
          returnUser = convertUser(currentUser, roles);
        } else {
          const preLinkedUser = await preLinkUser(preferred_username, email, name, token);
          log("pre-link-user: user", preLinkedUser);
          if (preLinkedUser?.username === preferred_username) {
            try {
              if (currentUser) {
                log("change user, log out");
                await signOut(true, true);
              }
              ssoHelper.setKeycloakSession();
              let user = new Parse.User();
              const authData = {
                "access_token": keycloak.token,
                "id": keycloak.tokenParsed?.preferred_username,
                "roles": [],
                "groups": []
              }
              user = await Parse.User.logInWith('keycloak', { authData: authData });
              log("link-user: user", user);
              const roles = await getUserRoles(user);
              returnUser = convertUser(user, roles);
            } catch (error) {
              message.error(displayError('Your account is disabled, please contact system administrator!'));
              log("link-user: failed", error);
              setTimeout(async () => {
                await signOut(true);
              }, 2000)
              returnUser = error;
            }
          } else {
            throw new Error('Keycloak login is not enabled.')
          }
        }
      }).catch((error) => {
        console.log("failed to login with Keycloak", error);
      });
      return returnUser;
    } else if (session === 'saml') {
      if (samlApi.isSamlCallback()) {
        const state = samlApi.getAuthInfo();
        if (state?.username && state?.oneTimePassword) {
          const result = await Parse.Cloud.run('login', { username: state.username.toLowerCase(), password: state.oneTimePassword })
          const loggedInUser = await Parse.User.become(result.user.sessionToken);
          log(
            `Success! User ${loggedInUser.get(
              'username'
            )} has successfully signed in!`
          );
          const currentUser = await getCurrentUser()
          const roles = await getUserRoles(currentUser);
          return convertUser(currentUser, roles);
        } else {
          throw new Error('SAML login is not enabled.')
        }
      } else {
        await samlApi.login();
      }
    } else {
      if (AccountStore.LOGIN_WITH_SESSION_TOKEN === 'N') {
        throw new Error('Login with session token is not enabled.')
      }
      log("Login with session token...");
      await signOut(true);
      const loggedInUser = await Parse.User.become(session);
      ssoHelper.resetAllLoginSessionFlags();
      log(
        `Success! User ${loggedInUser.get(
          'username'
        )} has successfully signed in!`
      );
      try {
        const currentUser = await getCurrentUser();
        const roles = await getUserRoles(currentUser);
        return convertUser(currentUser, roles);
      } catch (e) {
        log(`Login with session token failed! ${e}`);
        throw new Error('Login with session token failed!');
      }
    }
  } catch (error) {
    // signUp can fail if any parameter is blank or failed an uniqueness check on the server
    log(`Error! ${error}`);
    return error;
  }
};

const getKeycloakLoginUserByToken = async (token) => {
  try {
    if (token) {
      const param = { token }
      const result = await CloudRunWithCache('getKeycloakUserInfo', param, true)
      if (result && result.preferred_username) {
        return result
      } else {
        return null
      }
    } else {
      return null
    }
  } catch (error) {
    console.error('list data failed', error)
    throw error
  }
}

const signInWithKeycloak = async (keycloak) => {
  let assessToken = keycloak.token
  const keycloakUser = await auth.getKeycloakLoginUserByToken(assessToken)
  const preferredUsername = keycloakUser?.preferred_username?.toLowerCase()
  const email = keycloakUser?.email?.toLowerCase()
  const name = keycloakUser?.name
  if (!preferredUsername) {
    throw new Error(
      'Invalid access token or keycloak login is not enabled.'
    )
  }
  const preLinkedUser = await preLinkUser(
    preferredUsername,
    email,
    name,
    assessToken
  )
  if (preLinkedUser?.username === preferredUsername) {
    await auth.signOut()
  }
  log('pre-link-user: user', preLinkedUser)
  const authData = {
    access_token: assessToken,
    id: keycloak.tokenParsed?.preferred_username,
    roles: [],
    groups: [],
  };
  const user = await Parse.User.logInWith("keycloak", { authData: authData });
  log("link-user: user", user);
  const roles = await getUserRoles(user);
  return convertUser(user, roles);
};

const signOutParse = async function() {
  try {
    log('signOutParse triggered', new Error('signOutParse trace...'))
    await Parse.User.logOut();
  } catch (error) {}
}

const signOut = async function (notReportError, noRedirect, noClearCache) {
  try {
    // Since the signUp method returns a Promise, we need to call it using await
    log('singOut triggered', notReportError, noRedirect, new Error('signOut trace...'))
    await Parse.User.logOut();
    removeLocalStorageItem('user_obj');
    // if (!noClearCache) {
    //   removeLocalStorageItem('username');
    // }
    const isKeycloakSession = ssoHelper.isKeycloakSession();
    const isSamlSession = ssoHelper.isSamlSession();
    if (isKeycloakSession && !noRedirect) {
      ssoHelper.resetAllLoginSessionFlags();
      await keycloakApi.logout();
    }
    if (isSamlSession && !noRedirect) {
      ssoHelper.resetAllLoginSessionFlags();
      await samlApi.logout();
    }
    log(
      `Success! User has successfully signed out!`
    );
    return undefined;
  } catch (error) {
    // signUp can fail if any parameter is blank or failed an uniqueness check on the server
    log(`Error! ${error}`);
    if (notReportError) {
      return undefined;
    } else {
      return error;
    }
  }
}

const resetPassword = async function (email) {
  try {
    // Since the signUp method returns a Promise, we need to call it using await
    await Parse.User.requestPasswordReset(email)
    log(
      `Success! User has successfully reset the password!`
    );
    return undefined;
  } catch (error) {
    // signUp can fail if any parameter is blank or failed an uniqueness check on the server
    log(`Error! ${error}`);
    return error;
  }
}

const convertUser = function (parseUser, roles) {
  const attr = parseUser.attributes || parseUser;
  const user = {
    user: {
      uid: parseUser.id || parseUser.objectId,
      nickname: attr.nickname,
      username: attr.username,
      email: attr.email,
      avatar: attr.avatar,
      language: attr.language,
      timezone: attr.timezone,
      isClientLogging: attr.isClientLogging,
      roles: roles,
    }
  };
  log('converted user', user);
  return user;
}


const getUserRoles = async function (user, retry) {
  try {
    const param = {}
    const info = await CloudRunWithCache("getUserInfo", param, true);
    if (info) {
      return info.roles;
    } else {
      return null;
    }
  } catch (error) {
    if (!retry) retry = 0;
    console.log("getUserRoles() error", {error, retry})
    if (retry < 5) {
      await delay(200);
      return getUserRoles(user, retry + 1)
    }
  }
}

const getCurrentUser = async () => {
  let currentUser = null;
  try {
    await parseServerInitializedSignal.waitFor('value', true);
    currentUser = await Parse.User.current();
  } catch (e) {
    log('failed to get current user', e);
  }
  return currentUser ? currentUser.toJSON() : null;
}

const preLinkCount = {count: 0};
const preLinkUser = async (preferred_username, email, name, token) => {
  log("preLinkUser() start...");
  try {
    while (preLinkCount.count > 0) {
      await delay(1000);
    }
    preLinkCount.count++;
    const param = {preferred_username, email, name, token};
    const result = await CloudRunWithCache("preLinkUser", param, true);
    preLinkCount.count--;
    log("preLinkUser() ended");
    return result;
  } catch (error) {
    console.error("pre-link user error", error);
    log("preLinkUser() ended");
    throw error;
  }
}

const auth = {
  getKeycloakLoginUser,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithSession,
  signInWithKeycloak,
  signOut,
  signOutParse,
  getCurrentUser,
  getUserRoles,
  convertUser,
  resetPassword,
  getKeycloakLoginUserByToken
}

export default auth;