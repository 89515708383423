import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Switch } from "antd";
import { Form, Input } from "components/Form";
import React, { useEffect, useRef, useState } from "react";
import { ImQuill } from "react-icons/im";
import IntlMessages from "util/IntlMessages";
import {
  colSls, convertRules, convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate
} from "./common";
import { equals, isEmpty } from "util/algorithm";

import ReactQuill, {Quill}  from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
Quill.register('modules/imageResize', ImageResize);

const SfQuillEditorSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="staticContent" label="content">
        <Input.TextArea className="item-property" />
      </Form.Item>
      <Form.Item name="translate" label="translate" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="readOnly" label="read-only" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="advanced" label="advanced" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="imageBase64" label="image" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="highlight" label="highlight" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="fontstyle" label="font style" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfQuillEditor = ({ ...props }) => {
  const { actions: {setProp}, connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  const onChange = (value) => {
    setProp(props => {
      props.staticContent = value;
    });
  }
  return (
    <SfpQuillEditor doRef={ref => connect(drag(ref))} onChange={onChange} style={style} dbtn={dbtn} {...props} />
  )
}

const setupToolbar = ({imageBase64, highlight, fontstyle, advanced}) => {
  const toolbarOptions = [
    fontstyle ? [{ 'font': [] }] : null,
    [{ size: [ 'small', false, 'large', 'huge' ]}],
    advanced ? [{ 'header': [1, 2, 3, 4, 5, 6, false] }] : null,
    advanced ? ['bold', 'italic', 'underline', 'strike'] : null,
    !advanced ? ['bold', 'italic'] : null,
    advanced ? ['blockquote', 'code-block'] : null,
    !advanced ? ['blockquote'] : null,
    advanced ? [{ 'script': 'sub'}, { 'script': 'super' }] : null,      // superscript/subscript
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    highlight ? [{ 'color': [] }, { 'background': [] }] : null,          // dropdown with defaults from theme
    advanced ? [{ 'align': [] }] : null,
    imageBase64 ? ['image', 'video'] : null,
    ['clean']                                         // remove formatting button
  ];
  return toolbarOptions.filter(item => item !== null);
}

export const MyQuillEditor = ({
  id, noupdate, editorRef, value, onChange, readOnly, disabled, staticContent,
  imageBase64, highlight, fontstyle, codestyle, advanced, designer
}) => {
  const [editorState,setEditorState] = useState("<p></p>");
  const [toolbar,setToolbar] = useState(setupToolbar({imageBase64, highlight, fontstyle, codestyle, advanced}));
  const mounted = useRef();
  const ref = useRef();
  const tgr = useRef();
  if (ref.current === undefined) ref.current = noupdate && value !== undefined;

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  },[]);

  useEffect(() => {
    setToolbar(setupToolbar({imageBase64, highlight, fontstyle, codestyle, advanced}))
  },[imageBase64, highlight, fontstyle, codestyle, advanced])

  useEffect(() => {
    if (value !== undefined && (designer || !isEmpty(value))) {
      if (mounted.current) {
        if (!equals(editorState, value)) {
          setEditorState(!isEmpty(value) ? value : "<p></p>");
        }
      }
    } else if (!isEmpty(staticContent)){
      if (mounted.current) {
        if (!equals(editorState, staticContent)) {
          setEditorState(staticContent);
          if (onChange) onChange(staticContent);
        }
      }
    } else {
      if (mounted.current) {
        setEditorState("<p></p>");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, staticContent]);

  const onMyChange = (value) => {
    setEditorState(value);
    if (tgr.current) {
      clearTimeout(tgr.current);
    }
    tgr.current = setTimeout(() => {
      if (onChange) {
        onChange(value);
      }
    }, 500);
  }

  if (readOnly) {
    return (<div className="ck-content" dangerouslySetInnerHTML={{__html: editorState}} />)
  } else {
    return (
      <div id={id}>
        <ReactQuill className={disabled || ref.current ? "disabled-editor" : undefined}
        readOnly={disabled || ref.current}
        modules={{toolbar:toolbar,
        imageResize: {
          modules: ['Resize', 'DisplaySize']
        }}} value={editorState} onChange={onMyChange} />
      </div>
    )
  }
}

export const SfpQuillEditor = ({
  doRef, form, condistyles, className, style, dbtn, hideInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, translate, volitate, skipcopy,
  inputref, styleStr, children, ...otherProps }) => {
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const [fxr, setFxr] = useState({});
  const newRules = convertRules(rules);
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name} label={title}
              shouldUpdate={fx} hidden={fxr.hidden} rules={fxr.hidden || otherProps.disabled ? null : newRules}
              labelAlign={labelAlign} labelCol={lcs}
              getValueFromEvent={(value)=>value} wrap>
              <MyQuillEditor editorStyle={sls} {...otherProps} designer={!!doRef}/>
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

const HtmlRenderer = ({value, record, index, form, tableKey, onRowChange, editInTable, itemKey, tableColWidth, disabled}) => {
  return (<div className="ck-content" dangerouslySetInnerHTML={{__html: value}} />)
}

SfpQuillEditor.render = ({ systemFormatter, editInTable, itemKey, tableColWidth, disabled }, form, tableKey, onRowChange) => (value, record, index) => {
  const props = {value, record, index, form, tableKey, onRowChange, editInTable, itemKey, tableColWidth, disabled};
  return <HtmlRenderer {...props}/>
}

SfQuillEditor.craft = {
  displayName: "Quill Editor",
  related: {
    settings: SfQuillEditorSetting
  }
}

SfQuillEditor.validate = (props, {parents, container, extraParams}) => {
  const itemKey = props.itemKey;
  const title = props.title;
  if (container.type.resolvedName !== "SfMainPanel") {
    if (props.translate) {
      return <IntlMessages id="system.form.validate.non-main-panel-translate" text="item ({itemKey}) - input translate is only allowed in main panel." values={{itemKey:itemKey}}/>
    }
  }
  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "string",
      typeRender: "htmltext",
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      sortable: true,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
      translate: props.translate,
    });
    if (props.translate) {
      extraParams.translate.enabled = true;
      extraParams.translate.items.push({
        key:itemKey,
        type: 'quilleditor',
        label: title,
        index: Object.keys(parents).indexOf(props.itemKey)
      })
    }
  }
}

SfQuillEditor.isSearchable = true;
SfQuillEditor.isRulable = true;

registerComponent({
  key: "SfQuillEditor",
  component: SfQuillEditor,
  runtimeComponent: SfpQuillEditor,
  template: <SfQuillEditor itemKey={getId('quilleditor')} className="sf-quilleditor wrap"
    title={"Quill Editor"} span={24} labelColStr="span:6" hideInTable={true} />,
  title: <IntlMessages id="system.form.library.quilleditor" text="Quill Editor" />,
  icon: <ImQuill  className="react-icons icon-im"/>,
  type: "Component",
  sequence: 14,
});

export default SfQuillEditor;