export class DataRetrievalLock {
    constructor() {
      this.isLocked = false;
      this.lockQueue = [];
      this.lockKey = null;
    }

    static lockMap = new Map();

    static getLock(key) {
      if (!DataRetrievalLock.lockMap.has(key)) {
        DataRetrievalLock.lockMap.set(key, new DataRetrievalLock());
      }
      return DataRetrievalLock.lockMap.get(key);
    }

    static getReleaseKey(data) {
      if (data?.name === 'ReleaseKey' && data?.releaseKey) {
        return data.releaseKey;
      } else {
        return null;
      }
    }

    acquire(timeout = 0, forced = false) {
      if (forced && this.unlockable) {
        this.isLocked = false;
      }
      if (this.isLocked) {
        if (this.error) return Promise.reject(this.error);
        if (this.data) return Promise.resolve(this.data);
        if (timeout <= 0) {
          return new Promise((resolve, reject) => {
            this.lockQueue.push({resolve, reject});
          });
        } else {
          return new Promise((_resolve, _reject) => {
            const timeoutId = setTimeout(() => {
              clearTimeout(timeoutId);
              _reject(new Error('Lock acquisition timeout'));
            }, timeout);
            const resolve = (data) => {
              clearTimeout(timeoutId);
              _resolve(data);
            }
            const reject = (error) => {
              clearTimeout(timeoutId);
              _reject(error);
            }
            this.lockQueue.push({resolve, reject});
          });
        }
      } else {
        this.isLocked = true;
        this.lockKey = Math.random().toString(36).substring(7);
        this.data = null;
        this.error = null;
        this.unlockable = false;
        return Promise.resolve({name:'ReleaseKey',releaseKey:this.lockKey});
      }
    }

    setData(key, data, error) {
      if (this.isLocked && key === this.lockKey) {
        while (this.lockQueue.length > 0) {
          const {resolve, reject} = this.lockQueue.shift();
          if (error) {
            reject(error);
          } else {
            resolve(data);
          }
        }
        this.data = data;
        this.error = error;
        this.unlockable = true;
      }
    }

    release(key, data, error) {
      if (this.isLocked && key === this.lockKey) {
        while (this.lockQueue.length > 0) {
          const {resolve, reject} = this.lockQueue.shift();
          if (error) {
            reject(error);
          } else {
            resolve(data);
          }
        }
        this.isLocked = false;
        this.lockKey = null;
        this.data = null;
        this.error = null;
        this.unlockable = false;
      }
    }
  }


export class Queue {
    constructor(name) {
      this.name = name;
      this.items = [];
      this.isRunning = false;
      this.handler = null;
      this.processJob = null;
      this.lastId = 1
      this.timeoutRef = null;
    }

    // Add a job to the queue
    add(data, opts) {
      const id = this.lastId++;
      this.items.push({ id, data, opts });
      console.log(this.name, "add", id, data.uniqueIdentifier, this.items.length);
      if (data.uniqueIdentifier) {
        for (let i = this.items.length - 2; i >= 0; i--) {
          if (this.items[i].data.uniqueIdentifier === data.uniqueIdentifier) {
            this.items.splice(i, 1);
          }
        }
      }
      if (!this.isRunning && this.processJob) {
        if (this.timeoutRef) clearTimeout(this.timeoutRef);
        this.timeoutRef = setTimeout(this.processJob, 100);
      }
      return Promise.resolve();
    }

    // Process jobs in the queue
    process(handler) {
      if (handler) {
        this.handler = handler;
        this.processJob = async () => {
          if (!this.isRunning) {
            this.isRunning = true;
            console.log(`Processing job queue ${this.name}: Started`);
          }
          if (this.items.length > 0) {
            const job = this.items.shift();
            try {
              console.log(`Processing job queue ${this.name}:`, job.id);
              await this.handler(job);
            } catch (error) {
              console.error(`Error processing job queue ${this.name}:`, error, job);
              console.error(`Error job details ${this.name}:`, job);
            }
            setImmediate(this.processJob);
          } else {
            this.isRunning = false;
            console.log(`Processing job queue ${this.name}: Ended`);
            return;
          }
        };
      }
    }

    // Get the number of jobs in the queue
    getJobCounts() {
      return Promise.resolve({
        waiting: this.items.length,
        active: 0,
        completed: 0,
        failed: 0,
        delayed: 0,
        paused: 0,
      });
    }

    // Empty the queue
    empty() {
      this.items = [];
      return Promise.resolve();
    }
  }