import { useEditor, useNode } from "@craftjs/core";
import classNames from 'classnames';
import { Form, Input, Select } from "components/Form";
import IntlMessages from 'util/IntlMessages';
import { convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, permissionOptions, registerComponent } from "./common";
import { log } from "util/algorithm";
import { MdSmartButton } from "react-icons/md";
import { Switch } from "antd";
import { useEffect, useRef, useState } from "react";
import { system } from "parse-api";

const SfSubmitSetting = () => {
  const [options, setOptions] = useState([]);
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    const fetchData = async() => {
      const roles = await system.getAllRoles();
      const options = roles.map(r => ({label:r.name, value:r.name}));
      setOptions(options);
    }
    fetchData();
    return () => {
      mounted.current = false;
    }
  }, [])
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="buttonClass" label="button-class">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="submit" label="submit" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="forCreate" label="for create" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="forUpdate" label="for update" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="forView" label="for view">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="subsitute" label="substitute" >
        <Select className="item-property" options={permissionOptions} mode="multiple"/>
      </Form.Item>
      <Form.Item name="roles" label="roles">
        <Select mode="multiple" options={options} allowClear></Select>
      </Form.Item>
      <Form.Item name="confirm" label="confirm">
        <Input className="item-property" />
      </Form.Item>
    </>
  )
}

export const SfSubmit = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpSubmit doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const SfpSubmit = ({ ...props }) => {
  if (props.doRef) {
    return (
      <SfpSubmitInner {...props} />
    )
  } else {
    return null
  }
}

export const SfpSubmitInner = ({
  doRef, style, dbtn, onClick, dashed, plain, index,
  itemKey, className, styleStr, title, buttonClass, subsitute,
  disabled, ...otherProps }) => {
  const prefixCls = "ant-divider";
  const type = "horizontal";
  const orientation = "center"
  const orientationPrefix = orientation && orientation.length > 0 ? `-${orientation}` : orientation;
  const hasChildren = !!title;
  const classString = classNames(
    prefixCls,
    `${prefixCls}-${type}`,
    {
      [`${prefixCls}-with-text`]: hasChildren,
      [`${prefixCls}-with-text${orientationPrefix}`]: hasChildren,
      [`${prefixCls}-dashed`]: !!dashed,
      [`${prefixCls}-plain`]: !!plain,
    },
    className,
  );
  let sls = convertStyleStr(styleStr);
  if (style) sls = sls ? { ...sls, ...style } : style;
  if (doRef) {
    const height = "18px";
    const width = type === "vertical" ? "18px" : null;
    sls = { ...sls, height: height, width: width }
    return (
      <div ref={doRef} style={sls} className={classString}
      ><span className="title">Submit: {itemKey} </span>{dbtn}</div>
    );
  } else {
    return null;
  }
}

SfSubmit.validate = (props, {parents, container, extraParams, allActionPolicies}) => {
  extraParams.submits.push({
    title: props.title,
    itemKey: props.itemKey,
    index: Object.keys(parents).indexOf(props.itemKey),
    permission: props.permission,
    roles: props.roles,
    submit: props.submit,
    forCreate: props.forCreate,
    forUpdate: props.forUpdate,
    forView: props.forView,
    subsitute: props.subsitute,
    buttonClass: props.buttonClass,
    confirm: props.confirm,
  });
}

SfSubmit.craft = {
  displayName: "Submit",
  related: {
    settings: SfSubmitSetting
  },
  rules: {
    canDrop: (targetNode, currentNode, helper) => {
      let parent = null;
      if (targetNode?.data?.parent) {
        try {
          parent = helper(targetNode.data.parent).get()
        } catch (error) {
          log("error", error);
        }
      }
      return parent?.data?.name === "SfMainPanel";
    }
  }
}

registerComponent({
  key:"SfSubmit",
  component: SfSubmit,
  runtimeComponent: SfpSubmit,
  template: <SfSubmit itemKey={getId('submit')} className="sf-divider" title="Submit" plain dashed buttonClass={"action-btn"}/>,
  title: <IntlMessages id="system.form.library.submit" text="Submit"/>,
  icon: <MdSmartButton  className="react-icons icon-md"/>,
  type: "Logic",
  sequence: 6,
});

export default SfSubmit;