import { useEditor, useNode } from "@craftjs/core";
import classNames from 'classnames';
import { Form, Select } from "components/Form";
import { MdSavedSearch } from "react-icons/md";
import IntlMessages from 'util/IntlMessages';
import { convertStyleStr, deleteButton, EditorCollector, NodeCollector, registerComponent } from "./common";
import { useLbl } from "lngProvider";
import { settingsSignal } from "util/signal";


const SfAdvanceSearchSetting = () => {
  const { searchable } = useEditor(EditorCollector);
  const locale = settingsSignal.locale;
  const lbl = useLbl(locale);
  const fixedItems = [
    {value: 'createdAt', label: lbl("system.form.createdAt", "Created At")},
    {value: 'updatedAt', label: lbl("system.form.updatedAt", "Updated At")},
    {value: 'versionStamp', label: lbl("system.form.versionStamp", "Version")}
  ]
  return (
    <>
      <Form.Item name="parentkeys" label="parents">
        <Select className="item-property" options={[...searchable, ...fixedItems]} mode="multiple" />
      </Form.Item>
    </>
  )
}

export const SfAdvanceSearch = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpAdvanceSearch doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const SfpAdvanceSearch = ({ ...props }) => {
  if (props.doRef) {
    return (
      <SfpAdvanceSearchInner {...props} />
    )
  } else {
    return null
  }
}

export const SfpAdvanceSearchInner = ({
  doRef, style, dbtn, onClick, dashed, plain, index,
  itemKey, className, styleStr, title,
  disabled, ...otherProps }) => {
  const prefixCls = "ant-divider";
  const type = "horizontal";
  const orientation = "center"
  const orientationPrefix = orientation && orientation.length > 0 ? `-${orientation}` : orientation;
  const hasChildren = !!title;
  const classString = classNames(
    prefixCls,
    `${prefixCls}-${type}`,
    {
      [`${prefixCls}-with-text`]: hasChildren,
      [`${prefixCls}-with-text${orientationPrefix}`]: hasChildren,
      [`${prefixCls}-dashed`]: !!dashed,
      [`${prefixCls}-plain`]: !!plain,
    },
    className,
  );
  let sls = convertStyleStr(styleStr);
  if (style) sls = sls ? { ...sls, ...style } : style;
  if (doRef) {
    const height = "18px";
    const width = type === "vertical" ? "18px" : null;
    sls = { ...sls, height: height, width: width }
    return (
      <div ref={doRef} style={sls} className={classString}
      ><span className="title">{itemKey} </span>{dbtn}</div>
    );
  } else {
    return null;
  }
}

SfAdvanceSearch.validate = (props, {parents, container, extraParams}) => {
  const itemKey = props.itemKey;
  const parentkeys = props.parentkeys;
  if (!parentkeys || parentkeys.length === 0) {
    return <IntlMessages id="system.form.validate.parent-key-missing" text="item ({itemKey}) - parent is missing." values={{itemKey:itemKey}}/>
  }
  for (const element of parentkeys) {
    const key = element;
    if (container.type.resolvedName === "SfMainPanel") {
      extraParams.dataClassConfig.advanceSearchIndex.push(key);
    }
  }
}

SfAdvanceSearch.craft = {
  displayName: "Search (Advance)",
  related: {
    settings: SfAdvanceSearchSetting
  }
}

registerComponent({
  key:"SfAdvanceSearch",
  component: SfAdvanceSearch,
  runtimeComponent: SfpAdvanceSearch,
  template: <SfAdvanceSearch itemKey={'search-advance'} className="sf-divider" title="Advance Search" plain dashed/>,
  title: <IntlMessages id="system.form.library.search-advance" text="Advance Search"/>,
  icon: <MdSavedSearch  className="react-icons icon-antd"/>,
  type: "Logic",
  sequence: 2,
});

export default SfAdvanceSearch;