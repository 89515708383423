import { GoogleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Drawer, Form, Input, message, Select, Switch as AntSwitch } from 'antd';
import { endTranslate } from "appRedux/actions";
import { dataExplorer, translateApi, formApi } from "parse-api";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { convertPath, isEmpty, compare, getUserLanguages, log, getFormIdentity, toAuthUserObj } from '../../util/algorithm';
import { AccountStore } from '../../constants/Account';
import IntlMessages from '../../util/IntlMessages';
import { syncMessages, useLbl } from "../../lngProvider";
import { MyQuillEditor } from "../../routes/system/FormSetup/components/SfQuillEditor";
import CircularProgress from "../../components/CircularProgress";
import { MyCKEditor } from "../../routes/system/FormSetup/components";
import { authSignal, settingsSignal, systemSignal, translateSignal } from "../../util/signal";
import { useSignalValue } from "../../util/reactHook";

const typeIdx = (type) => {
  if (type !== 'label') {
    return 1;
  } else {
    return 2;
  }
}

const deduceDefaultLanguage = (locale, defaultLanguage) => {
  let language = defaultLanguage;
  if (locale?.languageId && locale.languageId !== 'en') {
    return locale.languageId;
  }
  return language;
}

const TranslateDrawer = (props) => {
  const dispatch = useDispatch();
  const { baseUrl, languageData } = systemSignal;
  const {filterKeyPrefix, translatePath, showCurrentPathOnly} = translateSignal;
  const translate = useSignalValue(translateSignal, 'translate');
  const { authUser } = authSignal;
  const authUserObj = toAuthUserObj(authUser);
  const [loading, setLoading] = useState(false);
  const mounted = useRef();

  const [languageOptions, setLanguageOptions] = useState([]);
  const [allTranslate, setAllTranslate] = useState([]);
  const [filterredTranslate, setFilterredTranslate] = useState([]);
  const [filterTranslate, setFilterTranslate] = useState(true);
  const [filterKey, setFilterKey] = useState("");
  const [googleTranslating, setGoogleTranslating] = useState(false);
  const [selectedTranslateLanguage, setSelectedTranslateLanguage] = useState('zh_HK');
  const [form] = Form.useForm();
  const locale = settingsSignal.locale;
  const lbl = useLbl(locale);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  },[])
  useEffect(() => {
    setFilterKey(filterKeyPrefix);
    setFilterTranslate(showCurrentPathOnly)
    form.setFields([
      {name: ["filterKey"], value: filterKeyPrefix},
      {name: ["filterred"], value: showCurrentPathOnly},
    ]);
  },[filterKeyPrefix, form, showCurrentPathOnly])
  useEffect(() => {
    const fetchData = async () => {
      if (translate) {
        if (mounted.current) setLoading(true);
        log("languageData", languageData);
        let path = translatePath || window.location.pathname.substring(baseUrl.length);
        path = convertPath(path, null, true);
        log("path", path, "translatePath", translatePath);
        const list = await syncMessages(filterKeyPrefix, path, translatePath);
        const sortedList = list.sort((a,b) => compare({order: a.order, type: typeIdx(a.type), messageKey: a.messageKey}, {order: b.order, type: typeIdx(b.type), messageKey: b.messageKey}, ['order', 'type','messageKey']));
        const filterred = sortedList.filter(l => l.path && l.path.indexOf(path) !== -1);
        const values = {};
        for (const element of sortedList) {
          const data = element
          values[data.messageKey] = data;
        }
        const lang = deduceDefaultLanguage(locale, 'zh_HK');
        values.languageId = lang;
        values.filterred = showCurrentPathOnly;
        form.setFieldsValue(values);
        if (mounted.current) setAllTranslate(sortedList);
        if (mounted.current) setFilterredTranslate(filterred);
        if (mounted.current) setFilterTranslate(true);
        if (mounted.current) setSelectedTranslateLanguage(lang);
        if (mounted.current) setLoading(false);
      }
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageData, translate, showCurrentPathOnly])

  useEffect(() => {
    if (languageData) {
      setLanguageOptions(getUserLanguages(languageData, authUserObj).map(l => {
        return {
          value: l.languageId,
          label: l.name
        }
      }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageData])

  const onClose = () => {
    dispatch(endTranslate());
  };

  const isFilterred = (key, data) => {
    let matched = true;
    if (data && key) {
      if (data.messageKey.indexOf(key) !== -1 || data.value.indexOf(key) !== -1) {
        // ignored
      } else {
        matched = false;
      }
    } else {
      // ignored
    }
    return matched;
  }

  const doGoogleTranslate = async () => {
    if (mounted.current) setGoogleTranslating(true);
    try {
      const list = [];
      const keys = [];
      const dataList = filterTranslate ? filterredTranslate : allTranslate;
      const values = form.getFieldsValue();
      let needContinue = false;
      for (const element of dataList) {
        const data = element;
        const messageKey = data.messageKey;
        if (isFilterred(filterKey, data)) {
          if (isEmpty(values[messageKey]?.[selectedTranslateLanguage])) {
            list.push(data.value);
            keys.push(data.messageKey);
            if (list.length >= 50) {
              needContinue = true;
              break;
            }
          }
        }
      }
      const source = 'en';
      const results = await translateApi.googleTranslate(list, source, selectedTranslateLanguage);
      if (results) {
        for (let i = 0; i < results.length; i++) {
          form.setFields([{name: [keys[i], selectedTranslateLanguage], value: results[i]}]);
        }
      }
      if (needContinue) {
        await doGoogleTranslate();
      }
    } catch (e) {
      log('translate error ', e);
    }
    if (mounted.current) setGoogleTranslating(false);
  }

  const doSaveTranslate = () => {
    try {
      const values = form.getFieldsValue();
      const list = filterTranslate ? filterredTranslate : allTranslate;
      const updates = [];
      for (const element of list) {
        const data = element;
        const oldValue = data[selectedTranslateLanguage];
        let newValue = values[data.messageKey]?.[selectedTranslateLanguage];
        if (newValue === "") newValue = null;
        if (oldValue !== newValue) {
          data[selectedTranslateLanguage] = newValue;
          updates.push(data);
        }
      }
      log("doSaveTranslate", filterKeyPrefix, updates);
      if (updates.length > 0) {
        if (filterKeyPrefix) {
          const {formKey, formView, isPreview, id} = getFormIdentity(translatePath);
          formApi.saveFormTranslate(filterKeyPrefix, formKey, formView, isPreview, id, updates);
        } else {
          dataExplorer.saveAll("SystemTranslate", updates);
        }
      }
      dispatch(endTranslate());
    } catch (error) {
      log("error", error);
      message.error(`${error}`)
    }
  }

  const getInput = (type, disabled) => {
    if (type === 'textarea') {
      return <Input.TextArea className="item-property" disabled={disabled} autoSize></Input.TextArea>
    } else if (type === 'quilleditor') {
      return <MyQuillEditor disabled={disabled}></MyQuillEditor>
    } else if (type === 'texteditor') {
      return <MyCKEditor disabled={disabled} sourceEditing={true}></MyCKEditor>
    } else {
      return <Input className="item-property" disabled={disabled}></Input>
    }
  }

  return (
    <Drawer
      title="Translate"
      placement="right"
      className="translate-drawer"
      headerStyle={{ display: "none" }}
      onClose={onClose}
      visible={translate}
    >
      <div className="translate-header">
        <span className="translate-title"><IntlMessages id="translate.translate" text="Translate" /></span>
        <Button className="translate-btn cancel" onClick={onClose}>Cancel</Button>
        <Button className="translate-btn save" type="primary" onClick={doSaveTranslate}>
          OK
        </Button>
        {AccountStore.GOOGLE_TRANSLATE === 'Y' && <Button className="translate-btn translate" onClick={doGoogleTranslate} icon={googleTranslating ? <LoadingOutlined /> : <GoogleOutlined />}></Button>}
      </div>
      <Form form={form} layout="vertical">
        <Form.Item name={['languageId']}
          labelCol={{ span: "23" }} wrapperCol={{ span: "23" }}>
          <Select className="item-property" options={languageOptions}
            onChange={(value) => setSelectedTranslateLanguage(value)}
          ></Select>
        </Form.Item>
        <Form.Item name={['filterKey']} hidden={!!filterKeyPrefix}
          labelCol={{ span: "23" }} wrapperCol={{ span: "23" }}>
          <Input onChange={(e)=>setFilterKey(e.target.value)} placeholder={"Filter Key"}/>
        </Form.Item>
        <Form.Item name={['filterred']} label={'Current Page'} hidden={!!filterKeyPrefix}
          labelCol={{ span: "12" }} wrapperCol={{ span: "11" }} valuePropName="checked">
          <AntSwitch className="item-property" options={languageOptions}
            onChange={(value) => setFilterTranslate(value)}></AntSwitch>
        </Form.Item>
        {!loading && !filterTranslate && allTranslate.filter(t => isFilterred(filterKey, t)).filter((t, index) => index < 500).map(t => {
          return (
            <div key={t.messageKey}>
              <Form.Item className="translate-value" name={[t.messageKey, 'value']} label={t.messageKey}
                labelCol={{ span: "23" }} wrapperCol={{ span: "23" }}>
                {getInput(t.type,true)}
              </Form.Item>
              <Form.Item className="translate-label" name={[t.messageKey, selectedTranslateLanguage]}
                labelCol={{ span: "23" }} wrapperCol={{ span: "23" }}>
                {getInput(t.type,false)}
              </Form.Item>
            </div>
          )
        })}
        {!loading && filterTranslate && filterredTranslate.filter(t => isFilterred(filterKey, t)).filter((t, index) => index < 500).map(t => {
          const label = (t.messageLabel && t.parentKey) ? lbl(t.parentKey, t.messageLabel) : t.messageKey;
          return (
            <div key={t.messageKey}>
              <Form.Item className="translate-value" name={[t.messageKey, 'value']} label={label}
                labelCol={{ span: "23" }} wrapperCol={{ span: "23" }}>
                {getInput(t.type,true)}
              </Form.Item>
              <Form.Item className="translate-label" name={[t.messageKey, selectedTranslateLanguage]}
                labelCol={{ span: "23" }} wrapperCol={{ span: "23" }}>
                {getInput(t.type,false)}
              </Form.Item>
            </div>
          )
        })}
        {loading && <CircularProgress/>}
      </Form>
    </Drawer>
  )
};

export default TranslateDrawer;
