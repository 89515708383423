import { useLbl } from "lngProvider";
import { useEffect, useState, useRef } from "react";
import { Prompt } from "react-router-dom/cjs/react-router-dom.min";
import { settingsSignal } from "./signal";

export const ModifiedChecker = ({isModified}) => {
  const locale = settingsSignal.locale;
  const [message, setMessage] = useState('You have unsaved changes, are you sure you want to leave?');
  const lbl = useLbl(locale);
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    setMessage(lbl('system.modified.message', 'You have unsaved changes, are you sure you want to leave?'));
    return () => {
      mounted.current = false;
      window.onbeforeunload = () => {
        return null;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  useEffect(() => {
    if (isModified) {
      window.onbeforeunload = () => {
        return message;
      }
    } else {
      window.onbeforeunload = () => {
        return null;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isModified]);
  return <Prompt when={isModified} message={message}/>
}

export default ModifiedChecker;