import { TableOutlined } from "@ant-design/icons";
import { Element, useEditor, useNode } from "@craftjs/core";
import { Button, Col, Empty, message, Switch, Table } from "antd";
import { Form, Select } from "components/Form";
import { dataExplorer, dataPolicyApi } from "parse-api";
import { useEffect, useRef, useState } from "react";
import { log } from "util/algorithm";
import IntlMessages from "util/IntlMessages";
import { AccountStore } from "../../../../constants/Account";
import { Compare, dateText, displayError, equals } from "../../../../util/algorithm";
import { getRender } from "../../FormSetup/components/common";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting } from "./common";
import { commonSignal, settingsSignal, systemSignal } from "../../../../util/signal";

export const SpTable = ({...props}) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppTable doRef={ref => connect(drag(ref))} selectedStyle={style} dbtn={dbtn} {...props}/>
  )
}

const isCellClickable = (target, level) => {
  if (level > 20) return false;
  if (!target) return false;
  try {
    if (target.className?.split(" ").indexOf("clickable") !== -1) return true;
    if (target.className?.split(" ").indexOf("ant-popover") !== -1) return true;
  } catch (error) {}
  return isCellClickable(target.parentElement, level ? level + 1 : 1);
}

const callChildOnClick = (event, target, level) => {
  if (level > 10) return;
  if (!target) target = event?.target;
  if (!target) return;
  if (target?.onclick) {
    try {
      target.click()
      return true;
    } catch (e) {
      log('onclick error', e)
      return false;
    }
  } else {
    for (const c of target.children) {
      const success = callChildOnClick(event, c, level ? level + 1 : 1);
      if (success) {
        return success;
      }
    }
    return false;
  }
}

const normalizeKey = (key) => {
  if (key) {
      if (Array.isArray(key)) {
        key = JSON.stringify(key);
      }
      return key.replace(/[[\]",]/g, '')
  }
  return key;
}

const findSorter = (sorterArr, col) => {
  let sorter = null;
  for (const s of sorterArr) {
    const k1 = normalizeKey(s.columnKey);
    const k2 = normalizeKey(col.key);
    if (k1 === k2) {
      sorter = s;
      break;
    }
  }
  return sorter;
}

const rowKey = (record) => {
  if (record.index) return record.index;
  if (record.objectId) return record.objectId;
  if (record.rowKey) return record.rowKey;
  log("row has no id", record);
  return null;
}

export const SppTable = ({
  doRef, dbtn, selectedStyle, pageKey, itemKey, pageState, setPageState,
  policy, initFetch, editable, showError, preview, bordered, size, nopaging,
  sticky, sort, selectionType, scroll, isMaxContent, firePageEvent,
  ...otherProps }) => {
  const { locale } = settingsSignal;
  const { headless } = systemSignal;
  const { width: screenWidth } = commonSignal;
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const fetched = useRef();
  const [dataPolicyParams, setDataPolicyParams] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [sorter, setSorter] = useState(null);
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [expandable, setExpandable] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [form] = Form.useForm();

  if (!preview) preview = false;
  const mounted = useRef();
  const ctrlRef = useRef();

  const onKeyDown = (e) => {
    ctrlRef.current = true;
  }
  const onKeyUp = (e) => {
    ctrlRef.current = false;
  }
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    window.addEventListener('keyup', onKeyUp)
    mounted.current = true;
    return () => {
      window.removeEventListener('keydown', onKeyDown)
      window.removeEventListener('keyup', onKeyUp)
      mounted.current = false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const generateepandedValue = (column,data) => {
    if(column.renderConfig && column.renderConfig === 'HTML'){
      return <td className='expanded-value'><div dangerouslySetInnerHTML = {{ __html: data[column.key]}}></div></td>
    }else{
      if(column.className !== 'clickable'){
        return <td className='expanded-value'><span className="non-clickable">{data[column.dataIndex]}</span></td>
      }else{
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        return <td className='expanded-value'><a  className="clickable_cell" >{data[column.dataIndex]}</a></td>
      }
    }
  }

  const expandedRowRender = (record, index, indent, expanded) => {
    if(expandable){
      const list = hiddenColumns.map(c => {
        return (
          <tr key={`${c.dataIndex}_${index}`}>
            <td className='expanded-label' span={8}>{c.title}</td>
            {generateepandedValue(c,record)}
          </tr>
        )
      })
      return <table className='widget-dataentry-nested-table'><tbody>{list}</tbody></table>
    }
    if (record?.expandedHTML) {
      return <div dangerouslySetInnerHTML = {{ __html: record?.expandedHTML}}></div>
    } else {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
    }
  }

  const onRowClick = (rowKey) => {
    if (!editable) {
      const key = itemKey + "_clickedRowKey";
      if (firePageEvent) {
        firePageEvent(pageState, key, rowKey);
      } else if (setPageState && pageState) {
        setPageState((prev) => ({...prev, [key]: rowKey}));
        setTimeout(() => {
          setPageState((prev) => ({...prev, [key]: null}));
        }, AccountStore.ON_CHANGE_END_DELAY)
      }
    }
  }
  const onColClick = (rowKey, col, dataIndex) => {
    const key = itemKey + "_clickedCol_" + dataIndex;
    if (firePageEvent) {
      firePageEvent(pageState, key, rowKey);
    } else if (setPageState && pageState) {
      setPageState((prev) => ({...prev, [key]: rowKey}));
      setTimeout(() => {
        setPageState((prev) => ({...prev, [key]: null}));
      }, AccountStore.ON_CHANGE_END_DELAY)
    }
  }

  const getSortOrder = (col, sorterArr) => {
    if (!Array.isArray(sorterArr)) {
      sorterArr = [sorterArr]
    }
    const sorter = findSorter(sorterArr, col);
    if (sorter?.column && sorter.field && (col.dataIndex === sorter.field || equals(col.dataIndex, sorter.field))) {
      if (sorter.order === 'descend') {
        return 'descend';
      } else {
        return 'ascend';
      }
    } else {
      return false;
    }
  }

  const updateSorter = (columns, sorter) => {
    if (columns && sorter) {
      const newColumns = columns.map((col, i) => {
        const newCol = { ...col };
        newCol.sortOrder = getSortOrder(col, sorter);
        return newCol;
      });
      return newColumns;
    } else {
      return columns;
    }
  }

  const onRowChange = () => {
    const value = form.getFieldValue(itemKey);
    if (setPageState && pageState) {
      setPageState((prev) => ({...prev, [itemKey + "_dataSource"]: value}));
    }
  }

  const fetchData = async () => {
    if (policy && (dataPolicyParams || initFetch)) {
      try {
        setTableLoading(true);
        let params = null;
        if (dataPolicyParams) {
          params = dataPolicyParams;
        } else {
          params = {initFetch}
        }
        if (editable) params = {...params, editable}
        const data = await dataPolicyApi.getDataPolicyData(preview, policy, "Table", locale, params);
        log("data", data);
        log("fetched", fetched.current);
        if (data.columns) {
          let hiddenColumns =  [];
          let mergedColumns = [];
          if (data.expandable && screenWidth < AccountStore.MOBILE_BREAKPOINT) {
            hiddenColumns = data.columns.filter(col => col.hiddenInMobile);
            mergedColumns = data.columns.filter(col => !col.hiddenInMobile);
            data.columns = mergedColumns
            setHiddenColumns(hiddenColumns)
          }
          data.columns = data.columns.map((col, i) => {
            const newCol = { ...col };
            const index = newCol.dataIndex;
            newCol.sorter = (sort || newCol.sort) ? {
              compare: Compare(index),
              multiple: data.columns.length - i,
            } : undefined;
            if (!newCol.renderConfig) {
              newCol.render = (value) => (value);
            } else if (newCol.renderConfig && typeof newCol.renderConfig === 'string') {
              newCol.render = (text, record) => {
                if(newCol.renderConfig && newCol.renderConfig === 'HTML'){
                  return <div dangerouslySetInnerHTML = {{ __html: record[newCol.key]}}></div>
                } else if(newCol.renderConfig && newCol.renderConfig === 'BUTTON'){
                  return <Button onClick={() => onColClick(record.key, i, newCol.dataIndex)} {...record[newCol.key]}/>
                } else if(newCol.renderConfig && newCol.renderConfig === 'DATE'){
                  if (record[newCol.key]) {
                    const {value, format, ...props} = record[newCol.key]
                    return <div {...props}>{dateText(value, format)}</div>;
                  } else {
                    return null;
                  }
                } else{
                  log("unknown renderConfig", newCol.renderConfig);
                  return text
                }
              }
            } else if (typeof newCol.renderConfig === 'object') {
              newCol.render = getRender(newCol.renderConfig, form, itemKey, onRowChange);
            } else {
              newCol.render = (value) => (value);
            }
            if (typeof newCol.key !== 'string') {
              newCol.key = JSON.stringify(newCol.key)
            }
            return newCol;
          });
          log("columns", data.columns);
        }

        if (mounted.current) setColumns(data.columns);
        if (!editable) {
          if (mounted.current) setDataSource(data.dataSource);
          if (mounted.current) setExpandable(data.expandable);
          if (mounted.current) setExpandedRowKeys([]);
        }
        if (mounted.current) setTableLoading(false);
      } catch (e) {
        if (showError) {
          if (!headless) message.error(displayError(e));
        }
        log("get data failed", e);
        if (!editable) {
          if (mounted.current) setDataSource([]);
        }
        if (mounted.current) setTableLoading(false);
      }
    } else {
      if (!editable) {
        if (mounted.current) setDataSource([]);
      }
      if (mounted.current) setTableLoading(false);
    }
  }

  useEffect(()=>{
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dataPolicyParams, locale, policy, preview, sort])

  useEffect(() => {
    if (doRef) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        fetchData();
      }, AccountStore.ON_CHANGE_WAIT_DELAY);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sort, sticky, isMaxContent])

  useEffect(() => {
    if (sorter && columns) {
      const newColumns = updateSorter(columns, sorter);
      if (!equals(newColumns, columns)) {
        setColumns(newColumns);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sorter, columns])

  useEffect(() => {
    if (pageState) {
      const params = pageState[itemKey + "_params" ];
      if (params && !equals(dataPolicyParams, params)) {
        setDataPolicyParams(params);
      }
      const pagination = pageState[itemKey + "_pagination" ];
      if (pagination) {
        setPagination(pagination);
      }
      const sorter = pageState[itemKey + "_sorter" ];
      if (sorter) {
        setSorter(sorter);
      }
      if (editable) {
        const dataSource = pageState[itemKey + "_dataSource" ];
        if (dataSource && Array.isArray(dataSource)) {
          setDataSource(dataSource);
          form.setFieldsValue({[itemKey]: dataSource});
        }
      }
    }
  },[dataPolicyParams, itemKey, initFetch, pageState, editable, form])

  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}
  const onChange = (pagination, filter, sorter, extra) => {
    setPagination(pagination);
    if (!ctrlRef.current && sorter && sorter.length > 0) {
      sorter = [sorter.at(-1)];
    }
    setSorter(sorter)
    const searchparams = {};
    searchparams.params = dataPolicyParams;
    searchparams.pagination = pagination;
    if (setPageState && pageState) {
      setPageState((prev) => ({...prev,
        [itemKey + "_pagination"]: pagination,
        [itemKey + "_sorter"]: sorter
      }));
    }
  }
  let rowSelection = null;
  if (selectionType) {
    rowSelection = {
      type: selectionType,
      onChange: (selectedRowKeys, selectedRows) => {
        if (setPageState && pageState) {
          setPageState((prev) => ({...prev, [itemKey + "_selectedRowKeys"]: selectedRowKeys}));
        }
      },
    }
  }
  const scrollConfig = {};
  if (scroll) scrollConfig.x = true;
  if (isMaxContent) scrollConfig.x = 'max-content';

  const onExpandedRowsChange = (expandedRows) => {
    setExpandedRowKeys(expandedRows);
  }
  return (
    <Col ref={doRef} {...props}>
      {!loading && <Table
        columns={columns}
        dataSource={dataSource}
        expandable={expandable && screenWidth < AccountStore.MOBILE_BREAKPOINT && hiddenColumns.length > 0 ? {expandedRowRender, onExpandedRowsChange, expandedRowKeys} : undefined}
        rowClassName={"clickable-row"}
        onRow={(record) => {
          return {
            onClick: (event) => {
              if (callChildOnClick(event)) {
                // ignore
              } else if (!isCellClickable(event.target)) {
                onRowClick(record.key || record.rowKey);
              }
            }
          };
        }}
        rowKey={rowKey}
        onChange={onChange}
        pagination={!nopaging && pagination}
        scroll={scrollConfig}
        sticky={sticky}
        loading={tableLoading}
        rowSelection={rowSelection}
        bordered={bordered}
        size={size}
        ></Table>}
      {dbtn}
    </Col>
  );
}

const SpTableSetting = () => {
  const [policyOptions, setPolicyOptions] = useState([]);
  const mounted = useRef();
  useEffect(()=>{
    mounted.current = true;
    const fetchData = async () => {
      const params = [{key:"dataTypes", operator:"=", value:"Table"}];
      const list = await dataExplorer.searchAll("SystemDataPolicy", params, "dataName");
      if (mounted.current) setPolicyOptions(list.map(p=>({value:p.dataKey,label:p.dataName})));
    }
    fetchData();
    return ()=>{
      mounted.current = false;
    }
  },[])
  return (
    <>
      <Form.Item name="policy" label="policy" >
        <Select className="item-property" options={policyOptions} allowClear/>
      </Form.Item>
      <Form.Item name="initFetch" label="init-fetch" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="editable" label="editable" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="showError" label="show error" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="preview" label="preview" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="size" label="size">
        <Select className="item-property">
          <Select.Option value="large">large</Select.Option>
          <Select.Option value="middle">middle</Select.Option>
          <Select.Option value="small">small</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="nopaging" label="no paging" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="bordered" label="bordered" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="sticky" label="sticky" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="sort" label="sort" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="scroll" label="scroll" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="isMaxContent" label="max-content" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="selectionType" label="selection type">
        <Select className="item-property" allowClear>
          <Select.Option value="checkbox">checkbox</Select.Option>
          <Select.Option value="radio">radio</Select.Option>
        </Select>
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  );
}

SpTable.craft = {
  displayName: "Table",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpTableSetting
  }
}
SppTable.enablePageState = true;
SppTable.enablePageEvent = true;

registerComponent({
  key:"SpTable",
  component: SpTable,
  runtimeComponent: SppTable,
  template: <Element canvas is={SpTable} itemKey={getId('table')} preview={true}
            className="sp-table" span="24" size={"middle"}/>,
  title: <IntlMessages id="system.page.library.table" text="Table" />,
  icon: <TableOutlined  className="react-icons icon-antd"/>,
  type: "Component",
  sequence: 7,
});

export default SpTable;