import { useNode } from "@craftjs/core";
import { Row } from "antd";
import React from "react";
import { convertStyleStr, getElements, NodeCollector, registerComponent } from "./common";

export const EditPad = ({...props}) => {
  const { connectors: { connect, drag }, style } = useNode(NodeCollector);
  return (
    <SfpEditPad doRef={ref => connect(drag(ref))} style={style} {...props}/>
  )
}

export const SfpEditPad = ({
  doRef, style, dbtn, itemKey, noStyle, children,
  styleStr, className, onNodeChange, ...otherProps }) => {
  if (noStyle) {
    return (
      <div ref={doRef}>
        {children}
      </div>
    )
  } else {
    let sls = convertStyleStr(styleStr);
    let cls = "craft-form-container";
    if (className) cls = cls + " " + className;
    if (style) sls = sls ? {...sls, ...style} : style;
    return (
      <Row ref={doRef} {...otherProps} className={cls} style={sls}>
        {children}
      </Row>
    )
  }
}

const LOCKED_LIST = ["SfButton"];
EditPad.craft = {
  displayName: "Edit Pad",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      const REJECTED_LIST = getElements("Container");
      let rtnVal = false;
      rtnVal = (REJECTED_LIST.indexOf(incomingNode.data.name) === -1) &&
               (LOCKED_LIST.indexOf(incomingNode.data.name) === -1);
      return rtnVal;
    },
    canMoveOut: function(outgoingNode, currentNode) {
      let rtnVal = false;
      rtnVal = (LOCKED_LIST.indexOf(outgoingNode.data.name) === -1);
      return rtnVal;
    },
  }
}

registerComponent({
  key:"EditPad",
  component: EditPad,
  runtimeComponent: SfpEditPad,
  type: "Container",
});

export default EditPad;