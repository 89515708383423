import { useNode } from "@craftjs/core";
import { Row } from "antd";
import React from "react";
import { convertStyleStr, NodeCollector, registerComponent } from "./common";

export const Container = ({...props}) => {
  const { connectors: { connect, drag }, style } = useNode(NodeCollector);
  return (
    <SppContainer doRef={ref => connect(drag(ref))} {...props} style={style}/>
  )
}

export const SppContainer = ({
  doRef, style, dbtn, pageKey, itemKey, noStyle, children, styleStr, className, ...otherProps }) => {
   if (noStyle) {
    return (
      <div ref={doRef}>
        {children}
      </div>
    )
  } else {
    let sls = convertStyleStr(styleStr);
    if (style) sls = sls ? {...sls, ...style} : style;
    return (
      <Row ref={doRef} {...otherProps} className={className} style={sls}>
        {children}
      </Row>
    )
  }
}

const ACCEPTED_LIST = ["SpTabsPane"];

Container.craft = {
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      if (currentNode.data.props.type === "SpTabs") {
        let rtnVal = false;
        rtnVal = (ACCEPTED_LIST.indexOf(incomingNode.data.name) !== -1);
        return rtnVal;
      } else {
        return true;
      }
    },
    canMoveOut: function(outgoingNode, currentNode) {
      return true;
    },
  }
}

registerComponent({
  key:"Container",
  component: Container,
  runtimeComponent: SppContainer,
  type: "Container",
});

export default Container;