import { Element, useEditor, useNode } from "@craftjs/core";
import { Col, Switch } from "antd";
import { Form, Input, Select } from "components/Form";
import { dataExplorer, dataPolicyApi } from "parse-api";
import { useEffect, useRef, useState } from "react";
import { MdScatterPlot } from "react-icons/md";
import { CartesianGrid, Legend, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis, ZAxis } from 'recharts';
import { isEmpty, log, equals } from "util/algorithm";
import IntlMessages from "util/IntlMessages";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting } from "./common";
import { settingsSignal } from "../../../../util/signal";

export const SpChartScatter = ({...props}) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppChartScatter doRef={ref => connect(drag(ref))} selectedStyle={style} dbtn={dbtn} {...props}/>
  )
}

const axisAttr = (arr, index) => {
  if (arr && arr.length > index) {
    return arr[index];
  } else {
    return undefined;
  }
}

export const SppChartScatter = ({
  doRef, dbtn, selectedStyle, pageKey, itemKey, pageState, setPageState,
  policy, preview, gradient, grid, legend, tootip,
  ...otherProps }) => {
  const { locale } = settingsSignal;
  const [data, setData] = useState();
  const [dataPolicyParams, setDataPolicyParams] = useState(null);
  if (!preview) preview = false;
  const mounted = useRef();
  useEffect(()=>{
    mounted.current = true;
    return ()=>{
      mounted.current = false;
    }
  },[])
  useEffect(()=>{
    const fetchData = async () => {
      if (policy) {
        try {
          let params = null;
          if (dataPolicyParams) {
            params = dataPolicyParams;
          }
          const data = await dataPolicyApi.getDataPolicyData(preview, policy, "ScatterChart", locale, params);
          log("data", data);
          if (mounted.current) setData(data);
        } catch (e) {
          log("get data failed", e);
          if (mounted.current) setData(null);
        }
      } else {
        if (mounted.current) setData(null);
      }
    }
    fetchData();
  },[dataPolicyParams, locale, policy, preview])
  useEffect(() => {
    if (pageState) {
      const params = pageState[itemKey + "_params" ];
      if (params && !equals(dataPolicyParams, params)) {
        setDataPolicyParams(params);
      }
    }
  },[dataPolicyParams, itemKey, pageState])
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}
  const xkey = axisAttr(data?.dataKeys, 0);
  const ykey = axisAttr(data?.dataKeys, 1);
  const zkey = axisAttr(data?.dataKeys, 2);
  const xunit = axisAttr(data?.dataUnits, 0);
  const yunit = axisAttr(data?.dataUnits, 1);
  const zunit = axisAttr(data?.dataUnits, 2);
  const xtype = axisAttr(data?.dataTypes, 0);
  const ytype = axisAttr(data?.dataTypes, 1);
  const ztype = axisAttr(data?.dataTypes, 2);
  const xrange = axisAttr(data?.dataRanges, 0);
  const yrange = axisAttr(data?.dataRanges, 1);
  const zrange = axisAttr(data?.dataRanges, 2);

  return (
    <Col ref={doRef} {...props}>
      <ResponsiveContainer width="100%" height="100%">
        <ScatterChart
          margin={{ top: 20, right: 20, bottom: 10, left: 10 }}>
          {!isEmpty(grid) && <CartesianGrid strokeDasharray={grid} />}
          <XAxis range={xrange} type={xtype} dataKey={xkey} name={xkey} unit={xunit} />
          <YAxis range={yrange} type={ytype} dataKey={ykey} name={ykey} unit={yunit} />
          <ZAxis range={zrange} type={ztype} dataKey={zkey} name={zkey} unit={zunit} />
          {tootip && <Tooltip />}
          {legend && <Legend verticalAlign="bottom" height={36}/>}
          {data?.data.map(({name, scatter}, index)=>{
            return <Scatter key={"area"+index} name={name} data={scatter} fill={data?.fills[index]} />
          })}
        </ScatterChart>
      </ResponsiveContainer>
      {dbtn}
    </Col>
  );
}

const SpChartScatterSetting = () => {
  const [policyOptions, setPolicyOptions] = useState([]);
  const mounted = useRef();
  useEffect(()=>{
    mounted.current = true;
    const fetchData = async () => {
      const params = [{key:"dataTypes", operator:"=", value:"ScatterChart"}];
      const list = await dataExplorer.searchAll("SystemDataPolicy", params, "dataName");
      if (mounted.current) setPolicyOptions(list.map(p=>({value:p.dataKey,label:p.dataName})));
    }
    fetchData();
    return ()=>{
      mounted.current = false;
    }
  },[])
  return (
    <>
      <Form.Item name="policy" label="policy" >
        <Select className="item-property" options={policyOptions} allowClear/>
      </Form.Item>
      <Form.Item name="grid" label="grid" >
        <Input className="item-property" allowClear/>
      </Form.Item>
      <Form.Item name="preview" label="preview" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="legend" label="legend" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="tootip" label="tootip" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  );
}

SpChartScatter.craft = {
  displayName: "Scatter Chart",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpChartScatterSetting
  }
}
SppChartScatter.enablePageState = true;

registerComponent({
  key:"SpChartScatter",
  component: SpChartScatter,
  runtimeComponent: SppChartScatter,
  template: <Element canvas is={SpChartScatter} itemKey={getId('chartscatter')} preview={true}
            className="sp-chart" span="24" style={{height:"300px"}}/>,
  title: <IntlMessages id="system.page.library.chartscatter" text="Scatter Chart" />,
  icon: <MdScatterPlot  className="react-icons icon-fa"/>,
  type: "Component",
  subtype: "chart",
  sequence: 6,
});

export default SpChartScatter;