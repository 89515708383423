import { Element, useEditor, useNode } from "@craftjs/core";
import { Col, Switch } from "antd";
import { Form, Input } from "components/Form";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { BiText } from "react-icons/bi";
import { shallowEqual } from "react-redux";
import IntlMessages from "util/IntlMessages";
import { equals, isEmpty, prepareTextV2 } from "../../../../util/algorithm";
import { convertMutationValues, useMutationObservable } from "../../../../util/reactHook";
import { EditorCollector, NodeCollector, SpLayoutSetting, SpStyleSetting, deleteButton, getId, registerComponent, updatePageState } from "./common";
import { getMessage } from "../../../../lngProvider";
import { settingsSignal } from "../../../../util/signal";

const SpTextSetting = () => {
  return (
    <>
      <Form.Item name="content" label="content" className="content"
        labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} >
        <Input.TextArea
          className="item-property"
          autoSize allowClear
        />
      </Form.Item>
      <Form.Item name="watch" label="watch">
        <Input className="item-property" allowClear/>
      </Form.Item>
      <Form.Item name="substitute" label="substitute" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="html" label="html" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="translate" label="translate" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="observer" label="observer" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="obsPattern" label="obs. pattern">
        <Input className="item-property"/>
      </Form.Item>
      <Form.Item name="norepaint" label="no repaint" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="clickevent" label="click event" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  );
}

export const SpText = ({...props}) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppText doRef={ref => connect(drag(ref))} selectedStyle={style} dbtn={dbtn} {...props}/>
  )
}

const convertContentToText = ({
  substitute, myContent, pageState, translate,
  pageKey, itemKey, locale, html}) => {
  let text = myContent;
  if (substitute) {
    text = prepareTextV2(text, pageState);
  }
  if (!isEmpty(myContent) && translate) {
    const messageKey = `page.${pageKey}.text.${itemKey}`;
    text = getMessage(locale.languageId, messageKey, text, html ? 'texteditor' : 'label');
  }
  return text;
}

const SppTextImpl = ({
  doRef, dbtn, selectedStyle, content, watch, itemKey,
  substitute, html, translate, observer, obsPattern, norepaint, clickevent,
  pageKey, pageState, setPageState, firePageEvent, ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}
  const [myContent, setMyContent] = useState(content);
  const { locale } = settingsSignal;
  const [myText, setMyText] = useState(convertContentToText({
    substitute, myContent, pageState, translate,
    pageKey, itemKey, locale, html
  }));
  const watchRef = useRef();
  const observerRef = useRef();
  const onMutation = useCallback(
    (mutationList) => {
      const values = convertMutationValues(mutationList, obsPattern);
      if (observer && setPageState && values) {
        setPageState((oldPageState) => {
          const key = itemKey + '_observed';
          const oldValues = oldPageState[key]
          let newValues = {};
          if (oldValues) newValues = {...newValues, ...oldValues}
          if (values) newValues = {...newValues, ...values}
          if (equals(oldValues, newValues)) {
            return oldPageState;
          } else {
            const newPageState = {...oldPageState};
            newPageState[key] = newValues
            return newPageState;
          }
        })
      }
    },
    [obsPattern, observer, setPageState, itemKey]
  );

  useEffect(() => {
    if ((!myContent || doRef) && content && myContent !== content) {
      setMyContent(content);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[content])

  useEffect(() => {
    const key = itemKey + '_content';
    const myWatch = watch || itemKey;
    if (pageState?.[key] && pageState[key] !== myContent) {
      setMyContent(pageState[key]);
    } else if (!isEmpty(myWatch) &&
      pageState?.[myWatch] && pageState[myWatch] !== watchRef.current) {
      const text = convertContentToText({
        substitute, myContent, pageState, translate,
        pageKey, itemKey, locale, html
      })
      setMyText(text);
      watchRef.current = pageState?.[myWatch];
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pageState])

  useEffect(() => {
    const text = convertContentToText({
      substitute, myContent, pageState, translate,
      pageKey, itemKey, locale, html
    })
    setMyText(text);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[myContent])

  const onClick = () => {
    const key = itemKey + '_clicked';
    if (firePageEvent) {
      firePageEvent(pageState, key, true);
    } else {
      updatePageState({itemKey: key, myValue: true, pageState, setPageState})
    }
  }
  useMutationObservable(observerRef.current, onMutation, observer);
  if (html) {
    return (
      <Col ref={doRef} {...props}>
        <div ref={observerRef} dangerouslySetInnerHTML={{__html: myText}} onClick={clickevent ? onClick : undefined}></div>
        {dbtn}
      </Col>
    );
  } else {
    return (
      <Col ref={doRef} {...props}>
        <div onClick={clickevent ? onClick : undefined}>{myText}</div>
        {dbtn}
      </Col>
    );
  }
}

function arePropsEqual(prevProps, nextProps) {
  if (nextProps.norepaint) {
    return true;
  } else {
    return shallowEqual(prevProps, nextProps)
  }
}

const SppText = memo(SppTextImpl, arePropsEqual);
SppText.enablePageState = true;
SppText.enablePageEvent = true;

SpText.craft = {
  displayName: "Text",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpTextSetting
  }
}

registerComponent({
  key:"SpText",
  component: SpText,
  runtimeComponent: SppText,
  template: <Element canvas is={SpText} itemKey={getId('text')}
            className="sp-text" span="24"/>,
  title: <IntlMessages id="system.page.library.text" text="Text" />,
  icon: <BiText  className="react-icons icon-bi"/>,
  type: "Component",
  sequence: 1,
});

export default SpText;