import { Form as AntdForm } from "antd";
import { getMessage } from "lngProvider";
import React, { useEffect } from "react";
import { isEmpty } from "../../util/algorithm";
import { settingsSignal } from "../../util/signal";

const DEFAULT_FORM = "form-default";
const DEFAULT_ITEM = "item-default";
const DEFAULT_LABEL = "label-default";

export const IntlFormContext = React.createContext({ form: DEFAULT_FORM });
export const IntlFormItemContext = React.createContext({ item: DEFAULT_ITEM, label: DEFAULT_LABEL });

const IntlForm = ({name, form, children, setIsSubmitting, ...otherProps}) => {
  const ctx = { form: name ? name : DEFAULT_FORM, formInstance: form, setIsSubmitting };
  return (
    <IntlFormContext.Provider value={ctx}>
      <AntdForm name={name} form={form} {...otherProps}>
        {children}
      </AntdForm>
    </IntlFormContext.Provider>
  );
}

const convertLabel = ({locale, ctx, formCtx, label, placeholder, moreSuffix, hidden}) => {
  label = isEmpty(label) ? null : label;
  label = label || placeholder;
  if (!hidden && formCtx.form && typeof label === 'string' && label.length > 0) {
    let suffix = Array.isArray(ctx.item) ? ctx.item[ctx.item.length - 1] : ctx.item;
    if (moreSuffix) suffix = suffix + '.' + moreSuffix;
    const messageKey = "form." + formCtx.form + "." + suffix;
    let lbl = getMessage(locale.languageId, messageKey, label);
    return lbl || label;
  } else {
    return label;
  }
}

const IntlFormItem = ({name, label, placeholder, hidden, children, ...otherProps}) => {
  const { locale } = settingsSignal;
  const ctx = { item: name ? name : DEFAULT_ITEM, label: label ? label : DEFAULT_LABEL };
  useEffect(() => {
    if (otherProps.shouldUpdate && typeof otherProps.shouldUpdate === 'function') {
      const shouldUpdate = otherProps.shouldUpdate;
      shouldUpdate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <IntlFormItemContext.Provider value={ctx}>
      <IntlFormContext.Consumer>
        {(formCtx) => {
          label = isEmpty(label) ? null : label;
          const lbl = convertLabel({locale, ctx, formCtx, label, placeholder, hidden})
          const phr = convertLabel({locale, ctx, formCtx, placeholder, moreSuffix: 'placeholder', hidden})
          const messageVariables = {
            label: lbl,
          };
          return (
            <AntdForm.Item name={name} label={lbl} placeholder={phr} hidden={hidden} messageVariables={messageVariables} {...otherProps}>
              {children}
            </AntdForm.Item>
          )
        }}
      </IntlFormContext.Consumer>
    </IntlFormItemContext.Provider>
  );
}

const Form = IntlForm;
Form.Item = IntlFormItem;
Form.useForm = () => {
  return AntdForm.useForm();
}
Form.useFormInstance = () => {
  return AntdForm.useFormInstance();
}
Form.List = AntdForm.List;
Form.useWatch = (dependencies, form) => {
  return AntdForm.useWatch(dependencies, form);
}
export default Form;