import { Element, useEditor, useNode } from "@craftjs/core";
import { Col, Empty, InputNumber, Switch } from "antd";
import { useEffect, useRef, useState } from "react";
import { TiFlowMerge } from "react-icons/ti";
import IntlMessages from "util/IntlMessages";
import { Form, ManagedSelect } from "../../../../components/Form";
import { AccountStore } from "../../../../constants/Account";
import { flowApi } from "../../../../parse-api";
import { compare } from "../../../../util/algorithm";
import { FlowDiagramInternal } from "../../FlowSetup/FlowComponent";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting } from "./common";

export const SpFlow = ({...props}) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppFlow doRef={ref => connect(drag(ref))} selectedStyle={style} dbtn={dbtn} {...props}/>
  )
}

export const FlowDiagram = ({
  flowKey,
  width,
  height,
  noCtrl,
  noBackground,
  readOnly,
  noPanZoom,
  currentTask
}) => {
  const [flowData, setFlowData] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      const f = await flowApi.getSystemFlow(false, flowKey)
      setFlowData(f)
    }
    fetchData()
  }, [flowKey])

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, AccountStore.ON_CHANGE_DELAY)
  }, [flowKey, width, height])

  if (loading) {
    return null
  } else {
    const style = {}
    if (height) style.height = height ? height + "px" : "500px";
    if (width) style.width = width ? width + "px" : "500px";
    return (
      <div className='flow-main-panel' style={style}>
        <FlowDiagramInternal
          flowData={flowData}
          width={width}
          height={height}
          noCtrl={noCtrl}
          noBackground={noBackground}
          readOnly={readOnly}
          noPanZoom={noPanZoom}
          currentTask={currentTask}
        />
      </div>
    )
  }
}

export const SppFlow = ({
  doRef, dbtn, selectedStyle, pageKey, itemKey, pageState, setPageState,
  flowKey, width, height, noCtrl, noBackground, readOnly, noPanZoom, currentTask,
  ...otherProps }) => {
  const mounted = useRef();
  const [myTask, setMyTask] = useState(currentTask);
  useEffect(()=>{
    mounted.current = true;
    return ()=>{
      mounted.current = false;
    }
  }, [])

  useEffect(() => {
    if (pageState) {
      const task = pageState[itemKey + "_currentTask" ];
      if (task && task !== myTask) {
        setMyTask(task);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pageState])

  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}

  if (flowKey) {
    return (
      <Col ref={doRef} {...props}>
        <FlowDiagram
          flowKey={flowKey}
          width={width}
          height={height}
          noCtrl={noCtrl}
          noBackground={noBackground}
          readOnly={readOnly}
          noPanZoom={noPanZoom}
          currentTask={myTask}
          />
        {dbtn}
      </Col>
    );
  } else {
    return (
      <Col ref={doRef} {...props}>
        <Empty />
        {dbtn}
      </Col>
    );
  }
}

const SpFlowSetting = () => {
  const [allFlows, setAllFlows] = useState([]);
  const [flowOptions, setFlowOptions] = useState([]);
  const [taskOptions, setTaskOptions] = useState([]);

  const [flowKey, setFlowKey] = useState();
  const [taskKey, setTaskKey] = useState();

  const mounted = useRef();
  const optionsSorter = (a, b) => compare(a.label, b.label)
  useEffect(()=>{
    mounted.current = true;
    const fetchData = async () => {
      if (mounted.current) setAllFlows(await flowApi.loadSystemFlow());
    }
    fetchData();
    return ()=>{
      mounted.current = false;
    }
  },[])

  useEffect(() => {
      if (mounted.current) setFlowOptions(allFlows.map(f => ({value: f.flowKey, label: f.flowKey})).sort(optionsSorter));
  }, [allFlows])

  useEffect(() => {
    if (allFlows && flowKey) {
      allFlows.forEach(f => {
        if (f.flowKey === flowKey) {
          const tasks = Object.keys(f.extraParams.taskMap);
          const options = tasks.map(t => ({value: t, label: t})).sort(optionsSorter);
          setTaskOptions(options)
          if (taskKey && tasks.indexOf(taskKey) === -1) {
            setTaskKey(null);
          }
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFlows, flowKey])

  const onFlowKeyChange = (value) => {
    setFlowKey(value)
    if (value) {
      allFlows.forEach(f => {
        if (f.flowKey === value) {
          setTaskOptions(Object.keys(f.extraParams.taskMap).map(t => ({value: t, label: t})).sort(optionsSorter))
          setTaskKey(null)
        }
      })
    }
  }

  const onTaskKeyChange = (value) => {
    setTaskKey(value)
  }

  return (
    <>
      <Form.Item name="flowKey" label="flow" >
        <ManagedSelect className="item-property" placeholder="flow" allowClear showSearch
          options={flowOptions} onSetValue={onFlowKeyChange} targetValue={flowKey} />
      </Form.Item>
      <Form.Item name="currentTask" label="task" >
        <ManagedSelect className="item-property" placeholder="task" allowClear showSearch
          options={taskOptions} onSetValue={onTaskKeyChange} targetValue={taskKey} />
      </Form.Item>
      <Form.Item name="width" label="width">
        <InputNumber className="item-property" min={100}/>
      </Form.Item>
      <Form.Item name="height" label="height">
        <InputNumber className="item-property" min={100}/>
      </Form.Item>
      <Form.Item name="noCtrl" label="no ctrl" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="noBackground" label="no background" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="noPanZoom" label="no zoom" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="readOnly" label="read-only" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  );
}

SpFlow.craft = {
  displayName: "Flow",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpFlowSetting
  }
}
SppFlow.enablePageState = true;

registerComponent({
  key:"SpFlow",
  component: SpFlow,
  runtimeComponent: SppFlow,
  template: <Element canvas is={SpFlow} itemKey={getId('flow')}
            className="sp-flow" width={500} height={500} span="24"/>,
  title: <IntlMessages id="system.page.library.flow" text="Flow" />,
  icon: <TiFlowMerge  className="react-icons icon-ti"/>,
  type: "Component",
  sequence: 9,
});

export default SpFlow;