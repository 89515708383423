import { useNode } from "@craftjs/core";
import { Row } from "antd";
import React from "react";
import { convertStyleStr, NodeCollector, registerComponent } from "./common";

export const ButtonArea = ({...props}) => {
  const { connectors: { connect, drag }, style } = useNode(NodeCollector);
  return (
    <SfpButtonArea doRef={ref => connect(drag(ref))} style={style} {...props}/>
  )
}

export const SfpButtonArea = ({ 
  doRef, style, dbtn, itemKey, noStyle, children, 
  styleStr, className, onNodeChange, ...otherProps }) => {
  if (noStyle) {
    return (
      <div ref={doRef}>
        {children}
      </div>
    )
  } else {
    let sls = convertStyleStr(styleStr);
    let cls = "craft-form-container";
    if (className) cls = cls + " " + className;
    if (style) sls = sls ? {...sls, ...style} : style;
    return (
      <Row ref={doRef} {...otherProps} className={cls} style={sls}>
        {children}
      </Row>
    )
  }
}

const LOCKED_LIST = ["SfButton"];
ButtonArea.craft = {
  displayName: "Button Area",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    },
    canMoveOut: function(outgoingNode, currentNode) {
      let rtnVal = false;
      rtnVal = (LOCKED_LIST.indexOf(outgoingNode.data.name) === -1);
      return rtnVal;
    },
  }
}

registerComponent({
  key:"ButtonArea", 
  component: ButtonArea, 
  runtimeComponent: SfpButtonArea,
  type: "Container",
});

export default ButtonArea;