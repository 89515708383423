export const SERVER_OFFLINE = "SERVER_OFFLINE";
export const BASE_URL = "BASE_URL";
export const APP_HEADLESS = "APP_HEADLESS";
export const APP_SESSION = "APP_SESSION";
export const APP_LOGO = "APP_LOGO";

// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';
export const START_TRANSLATE = 'START_TRANSLATE';
export const END_TRANSLATE = 'END_TRANSLATE';
export const START_FORM_TRANSLATE = 'START_FORM_TRANSLATE';
export const LOAD_LANGUAGE = 'LOAD_LANGUAGE';
export const LOAD_LANGUAGE_SUCCESS = 'LOAD_LANGUAGE_SUCCESS';
export const LOAD_VERSION_NO = 'LOAD_VERSION_NO';
export const LOAD_VERSION_NO_SUCCESS = 'LOAD_VERSION_NO_SUCCESS';
export const LOAD_VERSION_NO_FAIL = 'LOAD_VERSION_NO_FAIL';
export const INITIALIZING = 'INITIALIZING';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER_START = 'SIGNUP_USER_START';
export const SIGNUP_USER_STOP = 'SIGNUP_USER_STOP';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

//Config const
export const LOAD_MENU = "LOAD_MENU";
export const LOAD_MENU_SUCCESS = "LOAD_MENU_SUCCESS";
export const SAVE_MENU = "SAVE_MENU";
export const SAVE_MENU_SUCCESS = "SAVE_MENU_SUCCESS";


