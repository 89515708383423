import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, message, Popover, Switch, Tabs, Tag } from "antd";
import { Form, Input } from "components/Form";
import React, { useEffect, useRef, useState } from "react";
import { BiCodeCurly } from "react-icons/bi";
import ReactJson from "react-json-view";
import IntlMessages from "util/IntlMessages";
import { equals, tryParseJson } from "../../../../util/algorithm";
import {
  colSls, convertRules, convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate
} from "./common";

const SfObjectSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="readOnly" label="read-only" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hidden" label="hidden" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="isSelectKey" label="select key" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfObject = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpObject doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const JsonObjectEditor = ({
  readOnly,
  disabled,
  value,
  onChange,
  hidden,
}) => {
  const [myValue, setMyValue] = useState("");
  const [jsonValue, setJsonValue] = useState({});
  const ref = useRef();
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  useEffect(() => {
    if (value) {
      let v = value;
      if (typeof v === 'string') {
        v = tryParseJson(v);
      }
      if (!Array.isArray(v)) {
        if (!equals(jsonValue, v)) {
          if (mounted.current) setMyValue(JSON.stringify(v, null, 3));
          if (mounted.current) setJsonValue(v);
        }
      } else {
        if (mounted.current) setJsonValue({});
      }
    } else {
      if (mounted.current) setMyValue("");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const onBlur = () => {
    if (onChange) {
      try {
        if (myValue && myValue.length > 0) {
          const value = JSON.parse(myValue);
          if (!Array.isArray(value)) {
            onChange(value);
          } else {
            throw new Error("Array is not supported in this component!");
          }
        } else {
          onChange(null);
        }
      } catch (e) {
        message.error(`${e}`);
        ref.current.focus();
      }
    }
  };
  const updateJsonValue = (e) => {
    let newValue = e.updated_src;
    if (mounted) setJsonValue(newValue);
    if (newValue.length === 0) newValue = null;
    if (onChange) onChange(newValue);
  };
  if (!hidden) {

    return (
      <Tabs>
        <Tabs.TabPane
          tab={<IntlMessages id="system.form.json.normal" text="Normal" />}
          key="Normal"
        >
          <ReactJson
            src={jsonValue}
            onEdit={!readOnly && !disabled && updateJsonValue}
            onAdd={!readOnly && !disabled && updateJsonValue}
            onDelete={!readOnly && !disabled && updateJsonValue}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={<IntlMessages id="system.form.json.advance" text="Advance" />}
          key="Advance"
        >
          <Input.TextArea
            ref={ref}
            readOnly={readOnly}
            disabled={disabled}
            value={myValue}
            autoSize
            onChange={(e) => setMyValue(e.target.value)}
            onBlur={onBlur}
          />
        </Tabs.TabPane>
      </Tabs>
    );
  } else {
    return null;
  }
};

export const SfpObject = ({
  doRef, form, condistyles, className, style, dbtn, hidden, hideInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, volitate, skipcopy,
  inputref, styleStr, children, isSelectKey, ...otherProps }) => {
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const [fxr, setFxr] = useState({});
  const newRules = convertRules(rules);
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name} label={title} placeholder={otherProps.placeholder}
               shouldUpdate={fx} hidden={fxr.hidden || (!doRef && hidden)} rules={fxr.hidden || hidden || otherProps.disabled ? null : newRules}
              labelAlign={labelAlign} labelCol={lcs} wrap>
              <JsonObjectEditor style={sls} disabled={fxr.disabled || otherProps.disabled} hidden={!doRef && hidden} {...otherProps} />
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

const JsonRenderer = ({
  value,
  record,
  index,
  form,
  tableKey,
  onRowChange,
  editInTable,
  itemKey,
  mask,
  tableColWidth,
  disabled,
}) => {
  return (
    <span className="cell-content clickable">
      {value && (
        <Popover content={<ReactJson src={value} />}>
          <Tag>
            <BiCodeCurly className="react-icons icon-bi" />
          </Tag>
        </Popover>
      )}
    </span>
  );
};

SfpObject.render = ({ editInTable, itemKey, mask, tableColWidth, disabled }, form, tableKey, onRowChange) => (value, record, index) => {
  const props = {value, record, index, form, tableKey, onRowChange, editInTable, itemKey, mask, tableColWidth, disabled};
  return <JsonRenderer {...props}/>
}

SfObject.craft = {
  displayName: "Object",
  related: {
    settings: SfObjectSetting
  }
}

SfObject.validate = (props, {parents, container, extraParams}) => {
  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "json",
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      sortable: true,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
    });
    if (props.isSelectKey) {
      extraParams.selectConfig.optionTypes.push(props.itemKey);
    }
  }
}

SfObject.isSearchable = true;
SfObject.isRulable = true;

registerComponent({
  key:"SfObject",
  component: SfObject,
  runtimeComponent: SfpObject,
  template: <SfObject itemKey={getId('object')} className="sf-object wrap"
            title={"Object"} span={24} labelColStr="span:6" />,
  title: <IntlMessages id="system.form.library.object" text="Object" />,
  icon: <BiCodeCurly className="react-icons icon-bi"/>,
  type: "Component",
  sequence: 26,
});

export default SfObject;