import React, { Suspense, lazy } from "react";
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import {Route, Switch} from "react-router-dom";
import "assets/vendors/style";
import "styles/ergo.less";

import configureStore, { history } from './appRedux/store';
import CircularProgress from "components/CircularProgress";
const App = lazy(() => import("./containers/App/index"));

const store = configureStore();

const NextApp = () =>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Suspense fallback={<CircularProgress/>}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </Suspense>
    </ConnectedRouter>
  </Provider>;


export default NextApp;
