import { ConfigProvider, Empty, message } from "antd";
import "assets/vendors/style";
import React, { useEffect, useRef, useState } from "react";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { MemoryRouter } from "react-router-dom";
import "styles/ergo.less";

import configureStore from "./appRedux/store";
import CircularProgress from "./components/CircularProgress";
import MainApp from "./containers/App/MainApp";
import { getAppLocale, getUserLocale } from "./lngProvider";
import { FormRuntimeImpl } from "./routes/system/FormSetup/runtimeimpl";
import { FormRuntimeSearchImpl } from "./routes/system/FormSetup/runtimesearchimpl";
import PageRuntimeImpl from "./routes/system/PageSetup/runtimeimpl";
import {
  getFormIdentity,
  getMatchPath,
  getPageIdentity,
  isEmpty,
  registerPageComponentApis,
  unregisterPageComponentApis,
  toAuthUserObj,
  removeLocalStorageItem
} from "./util/algorithm";
import { authSignal } from "./util/signal";
import { setLanguage } from './util/exportToWindow'

const store = configureStore();

const VALID_CLASS = /(ant-select-selector)/i;
const EXCLUDED_CLASS = /(savedFlowKey|savedFormKey|savedPageKey)/i;
const createGetPopupContainer = (ref) => {
  return (trigger) => {
    let cntr = null;
    if ((trigger?.className || "").match(VALID_CLASS)) {
      if (trigger?.parentElement) {
        if ((trigger.parentElement.className || "").match(EXCLUDED_CLASS)) {
          cntr = trigger?.parentElement.closest('.appframe-container');
        }
      }
    }
    if (!cntr) {
      cntr = ref.current?.closest('.appframe-container')
    }
    if (!cntr) {
      cntr = document.querySelector('.appframe-container');
    }
    if (cntr) {
      return cntr;
    } else {
      return document.body;
    }
  };
}

export const MyPage = ({
  name,
  src,
  pageKey,
  flowKey,
  formKey,
  formView,
  isCreate,
  objectId,
  uniqueKeyValues,
  isPreview,
  isRequiredLogin
}) => {
  const [loading, setLoading] = useState(true);
  const [keys, setKeys] = useState(uniqueKeyValues);
  const [pathInfo, setPathInfo] = useState({
    path: null,
    state: {},
    history: [],
  });
  const { authUser } = authSignal;
  const authUserObj = toAuthUserObj(authUser);
  const dispatchReplace = (path, state) => {
    setLoading(true);
    setKeys(null);
    setPathInfo((prev) => ({ ...prev, path, state: state || {} }));
    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  const dispatchGoBack = () => {
    if (pathInfo.history.length > 0) {
      setLoading(true);
      setPathInfo((prev) => {
        const path = prev.history[prev.history.length - 1];
        const history = prev.history.slice(0, prev.history.length - 1);
        return { ...prev, history, path, state: {} };
      });
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  };

  const dispatchPush = (path, state) => {
    setLoading(true);
    setPathInfo((prev) => {
      const history = [...prev.history, prev.path];
      return { path, history, state: state || {} };
    });
    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  useEffect(() => {
    const key = 'AppFrame.' + (name);
    if (name) {
      registerPageComponentApis(key, 'dispatchReplace', dispatchReplace);
      registerPageComponentApis(key, 'dispatchGoBack', dispatchGoBack);
      registerPageComponentApis(key, 'dispatchPush', dispatchPush);
    }
    const fetchData = async () => {
      setLoading(true);
      let path = null;
      if (pageKey) {
        const pg = isPreview ? "preview" : "pg";
        path = `/system/prt/${pg}/${pageKey}`;
      } else if (formKey) {
        if (!objectId && !isCreate) {
          if (flowKey) {
            const fm = isPreview ? "preview" : "fm";
            path = `/system/wrts/${fm}/${flowKey}/${formKey}`;
          } else if (formView) {
            const fm = isPreview ? "preview" : "fm";
            path = `/system/rts/${fm}/${formKey}/${formView}`;
          }
        } else {
          const id = objectId ? `/${objectId}` : "";
          if (flowKey && formView) {
            const fm = isPreview ? "preview" : "fm";
            path = `/system/wrt/${fm}/${flowKey}/${formKey}/${formView}${id}`;
          } else if (formView) {
            const fm = isPreview ? "preview" : "fm";
            path = `/system/rt/${fm}/${formKey}/${formView}${id}`;
          }
        }
      } else if (src) {
        path = src;
      }

      if (isRequiredLogin) {
        if (authUserObj.username === 'guest') window.location.href = '/signin'
      }
      setPathInfo({ ...pathInfo, path });
      setLoading(false);
    };
    fetchData();
    return () => {
      if (name) {
        unregisterPageComponentApis(key, 'dispatchReplace', dispatchReplace);
        unregisterPageComponentApis(key, 'dispatchGoBack', dispatchGoBack);
        unregisterPageComponentApis(key, 'dispatchPush', dispatchPush);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, pageKey, flowKey, formKey, formView, isPreview]);

  if (loading) {
    console.log("AppFrame() loading...", {loading, pathInfo, src, pageKey, flowKey, formKey, formView, isPreview})
    return <CircularProgress />;
  } else if (!isEmpty(pathInfo.path)) {
    let pageIdentity = getPageIdentity(pathInfo.path);
    let formIdentity = getFormIdentity(pathInfo.path);
    if (pageIdentity?.pageKey) {
      return (
        <PageRuntimeImpl
          pageKey={pageIdentity?.pageKey}
          type={pageIdentity?.type}
          hideVersion={true}
          dispatchReplace={dispatchReplace}
          dispatchGoBack={dispatchGoBack}
          dispatchPush={dispatchPush}
          pathInfo={pathInfo}
        />
      );
    } else if (formIdentity?.formKey) {
      const match = { path: getMatchPath(pathInfo.path) };
      const location = { state: pathInfo.state };
      const isSearchScreen = pathInfo.path.includes("rts");
      if (isSearchScreen) {
        return (
          <FormRuntimeSearchImpl
            match={match}
            location={location}
            flow={formIdentity?.flowKey}
            type={formIdentity?.type}
            formKey={formIdentity?.formKey}
            formView={formIdentity?.formView}
            dispatchReplace={dispatchReplace}
          />
        );
      } else {
        return (
          <FormRuntimeImpl
            match={match}
            location={location}
            flow={formIdentity?.flowKey}
            type={formIdentity?.type}
            formKey={formIdentity?.formKey}
            formView={formIdentity?.formView}
            id={formIdentity?.id}
            direct={keys?.length > 0 ? keys[0] : null}
            uniqueKeyValues={keys}
            hideVersion={true}
            dispatchReplace={dispatchReplace}
            dispatchGoBack={dispatchGoBack}
            dispatchPush={dispatchPush}
          />
        );
      }
    } else {
      console.log('AppFrame only support Form or Page URL')
      return <Empty />;
    }
  } else {
    return <Empty />;
  }
};

const AppFrame = (props) => {
  const [ loading, setLoading] = useState(true)
  const languageId = props?.languageId || 'en'
  const [locale, setLocale] = useState(getUserLocale());
  const [currentAppLocale, setCurrentAppLocale] = useState(getAppLocale(languageId || locale.languageId));
  const isDirectionRTL = false;
  const appframeRef = useRef();
  const getPopupContainer = createGetPopupContainer(appframeRef);
  message.config({
    getContainer: getPopupContainer
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async() => {
    setLoading(true)
    if(languageId){
      removeLocalStorageItem('language_data')
      setLanguage(languageId)
      setLocale(languageId)
      setCurrentAppLocale(getAppLocale(languageId))
    }
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, [languageId,locale]);

  if(loading){
    return <CircularProgress />;
  }else{
    return (
      <Provider store={store}>
        <MemoryRouter initialEntries={["/"]}>
          <ConfigProvider
            getPopupContainer={getPopupContainer}
            prefixCls="ant"
            locale={currentAppLocale.antd}
            direction={isDirectionRTL ? "rtl" : "ltr"}
          >
            <IntlProvider
              locale={currentAppLocale.locale}
              messages={currentAppLocale.messages}
            >
              <MainApp>
                <MyPage {...props} />
                <span
                  style={{"display": "none"}}
                  ref={appframeRef} ></span>
              </MainApp>
            </IntlProvider>
          </ConfigProvider>
        </MemoryRouter>
      </Provider>
    );
  }

};

export default AppFrame;
