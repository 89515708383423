import { Col, InputNumber, Row, Switch } from "antd";
import { ColorPicker, Form, Input, Select, PixelInput } from "components/Form";
import { selectApi } from "parse-api";
import React, { useEffect, useState } from "react";
import { compare, getText, uid, log } from "util/algorithm";
import { deduceUndefined, equals, getCraftSelectedNodeId } from "../../../../util/algorithm";

let currentSelectedNodeId = null;

const scrollIntoView = (selectedNodeId) => {
  if (currentSelectedNodeId !== selectedNodeId) {
    currentSelectedNodeId = selectedNodeId;
    setTimeout(() => {
      const elms = document.querySelectorAll("*[style]");
      let target = null;
      elms.forEach(el => {
        if (el.style.outline && el.style.outline.indexOf("orange") !== -1) target = el;
      });
      if (target) {
        scrollIntoViewIfOutOfView(target)
      }
    }, 500);
  }
}

function scrollIntoViewIfOutOfView(el) {
  if (el.scrollIntoViewIfNeeded) {
    el.scrollIntoViewIfNeeded();
  } else {
    el.scrollIntoView(false);
  }
}

export const EditorCollector = (state, query) => {
  const currentNodeId = getCraftSelectedNodeId({state, query});
  let selectedNode;

  if (currentNodeId) {
    selectedNode = {
      id: currentNodeId,
      name: state.nodes[currentNodeId].data.name,
      displayName: state.nodes[currentNodeId].data.displayName,
      props: state.nodes[currentNodeId].data.props,
      settings: state.nodes[currentNodeId].related && state.nodes[currentNodeId].related.settings,
      isDeletable: query.node(currentNodeId).isDeletable()
    };
    scrollIntoView(currentNodeId);
  }

  const ancestors = selectedNode ? query.node(selectedNode.id).ancestors() : null;
  let sibling = [];
  if (ancestors && ancestors.length > 0) {
    sibling = query.node(ancestors[0]).childNodes();
    sibling = sibling.filter(sid=>sid !== selectedNode.id).map(sid => {
      const n = query.node(sid).get();
      return n?.data?.props?.itemKey;
    });
  }
  const parents = sibling.map(s => {
    return { value: s, label: s };
  })

  return {
    selectedNode,
    ancestors,
    sibling,
    parents,
  }
}

export const NodeCollector = (node) => {
  const selected = node.events.selected;
  const style = selected ? { outline: "orange 3px solid", zIndex: 1 } : { outline: "lightblue 1px dashed" } ;
  return {
    selected: selected,
    style: style,
  };
}

const LAYOUTATTR = ["span", "xs", "sm", "md", "lg", "xl", "xxl"];
export const SpLayoutSetting = () => {
  return (
    <>
      <Row justify="start" className="sp-layout-setting" gutter={0} style={{ marginLeft: "-8px", marginRight: "18px" }}>
        {LAYOUTATTR.map((attr, index) => {
          return (
            <Col key={attr} span={8} className={(index % 3) === 0 ? "first" : null}>
              <Form.Item name={attr} label={attr} labelAlign={"right"} labelCol={{span:10}} wrapperCol={{span:10}}>
                <InputNumber min="0" max="24" style={{ width: "35px" }} controls={false} />
              </Form.Item>
            </Col>
          )
        })}
      </Row>
    </>
  );
}

const BORDER_STYLE = ["none", "dotted", "dashed", "solid", "double", "groove", "ridge", "inset", "outset"]
const BOX_SHADOW = "0 0 10px 10px rgb(0 0 0 / 3%)"
const FONT = [
  "Lato",
  "Noto Sans TC",
  "Open Sans",
  "Roboto",
  "Times New Roman, Times, serif",
  "Georgia, serif",
  "Garamond, serif",
  "Arial, Helvetica, sans-serif",
  "Tahoma, Verdana, sans-serif",
  "Trebuchet MS, Helvetica, sans-serif",
  "Georgia, Verdana, sans-serif",
  "Courier New, Courier, monospace",
  "Brush Script MT, cursive",
  "Copperplate, Papyrus, fantasy",
];
const TEXT_ALIGN = ["left", "center", "right", "justify"];
const FLOAT = ["left", "right"];
const DISPLAY = ["none", "block", "inline", "inline-block", "flex"];
const ALIGN_ITEMS = ["flex-start", "flex-end", "center", "stretch", "baseline"];
const JUSTIFY_ITEMS = ["flex-start", "flex-end", "center", "stretch", "baseline"];
const ALIGN_CONTENT = ["flex-start", "flex-end", "center", "space-between", "space-around", "stretch", "space-evenly"];
const JUSTIFY_CONTENT = ["flex-start", "flex-end", "center", "space-between", "space-around", "space-evenly"];
const FLEX_DIRECTION = ["column", "row"]

const BoxShadow = ({value, onChange}) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(()=> {
    if (value) {
      if (value !== BOX_SHADOW) {
        if (onChange) onChange(BOX_SHADOW);
      }
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[value])
  const onSwitchChange = (value) => {
    setEnabled(value);
    if (value)  {
      if (onChange) onChange(BOX_SHADOW)
    } else {
      if (onChange) onChange(null)
    }
  }
  return (
    <Switch className="item-property" checked={enabled} onChange={onSwitchChange}/>
  )
}

export const SpStyleSetting = () => {
  return (
    <>
      <Form.Item name={["style", "color"]} label={"color"}>
        <ColorPicker />
      </Form.Item>
      <Form.Item name={["style", "backgroundColor"]} label={"background"}>
        <ColorPicker />
      </Form.Item>
      <Form.Item name={["style", "borderColor"]} label={"bder. color"}>
        <ColorPicker />
      </Form.Item>
      <Form.Item name={["style", "borderWidth"]} label={"bder. width"}>
        <PixelInput min={0} max={24}/>
      </Form.Item>
      <Form.Item name={["style", "borderRadius"]} label={"bder. radius"}>
        <PixelInput min={0} max={24}/>
      </Form.Item>
      <Form.Item name={["style", "borderStyle"]} label={"bder. style"}>
        <Select className="item-property" allowClear>
          {BORDER_STYLE.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["style", "boxShadow"]} label={"shadow"}>
        <BoxShadow/>
      </Form.Item>
      <Form.Item name={["style", "float"]} label={"float"}>
        <Select className="item-property" allowClear>
            {FLOAT.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={"margin"} label={"margin"}>
        <Form.Item name={["style", "marginTop"]} noStyle>
          <PixelInput style={{ width: "45px", marginRight: "1px" }} />
        </Form.Item>
        <Form.Item name={["style", "marginRight"]} noStyle>
          <PixelInput style={{ width: "45px", marginRight: "1px" }} />
        </Form.Item>
        <Form.Item name={["style", "marginBottom"]} noStyle>
          <PixelInput style={{ width: "45px", marginRight: "1px" }} />
        </Form.Item>
        <Form.Item name={["style", "marginLeft"]} noStyle>
          <PixelInput style={{ width: "45px", marginRight: "1px" }} />
        </Form.Item>
      </Form.Item>
      <Form.Item name={"padding"} label={"padding"} >
        <Form.Item name={["style", "paddingTop"]} noStyle>
          <PixelInput style={{ width: "45px", marginRight: "1px" }} />
        </Form.Item>
        <Form.Item name={["style", "paddingRight"]} noStyle>
          <PixelInput style={{ width: "45px", marginRight: "1px" }} />
        </Form.Item>
        <Form.Item name={["style", "paddingBottom"]} noStyle>
          <PixelInput style={{ width: "45px", marginRight: "1px" }} />
        </Form.Item>
        <Form.Item name={["style", "paddingLeft"]} noStyle>
          <PixelInput style={{ width: "45px", marginRight: "1px" }} />
        </Form.Item>
      </Form.Item>
      <Form.Item name={["style", "height"]} label={"height"}>
        <PixelInput min={0} />
      </Form.Item>
      <Form.Item name={["style", "minHeight"]} label={"min height"}>
        <PixelInput min={0} />
      </Form.Item>
      <Form.Item name={["style", "maxHeight"]} label={"max height"}>
        <PixelInput min={0} />
      </Form.Item>
      <Form.Item name={["style", "width"]} label={"width"}>
        <PixelInput min={0} />
      </Form.Item>
      <Form.Item name={["style", "minWidth"]} label={"min width"}>
        <PixelInput min={0} />
      </Form.Item>
      <Form.Item name={["style", "maxWidth"]} label={"max width"}>
        <PixelInput min={0} />
      </Form.Item>
      <Form.Item name={["style", "fontFamily"]} label={"font"}>
        <Select className="item-property" allowClear>
          {FONT.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["style", "fontSize"]} label={"font size"}>
        <PixelInput min={5} />
      </Form.Item>
      <Form.Item name={["style", "fontWeight"]} label={"font weight"}>
        <InputNumber min={100} max={900} step={100} />
      </Form.Item>
      <Form.Item name={["style", "textAlign"]} label={"text-align"}>
        <Select className="item-property" allowClear>
          {TEXT_ALIGN.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["style", "display"]} label={"display"}>
        <Select className="item-property" allowClear>
          {DISPLAY.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["style", "alignItems"]} label={"align-items"}>
        <Select className="item-property" allowClear>
          {ALIGN_ITEMS.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["style", "alignContent"]} label={"align-content"}>
        <Select className="item-property" allowClear>
          {ALIGN_CONTENT.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["style", "justifyItems"]} label={"justify-items"}>
        <Select className="item-property" allowClear>
          {JUSTIFY_ITEMS.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["style", "justifyContent"]} label={"justify-content"}>
        <Select className="item-property" allowClear>
          {JUSTIFY_CONTENT.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["style", "flexDirection"]} label={"flex-direction"}>
        <Select className="item-property" allowClear>
          {FLEX_DIRECTION.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["style", "lineHeight"]} label={"line-height"}>
        <PixelInput min={0} max={100}/>
      </Form.Item>
    </>
  );
}

export const deleteButton = (selected, selectedNode, action) => {
  return selected && selectedNode.isDeletable ? (
    <span onClick={() => action.delete(selectedNode.id)}>
      <i className={"icon icon-close craft-page-delete-button"} />
    </span>
  ) : null;
}

export const convertStyleStr = (styleStr) => {
  if (styleStr) {
    try {
      const map = {}
      const token = styleStr.replace(/"/g, '').split(/[,;]/g);
      token.forEach(t => {
        let name = t;
        let val = true;
        if (t.indexOf(":") !== -1) {
          const index = t.indexOf(":");
          name = t.substr(0, index).trim();
          val = t.substr(index + 1).trim();
        }
        name = name.replace(/-\w/g, (c) => c.slice(1).toUpperCase());
        map[name] = val;
      });
      return map;
    } catch (e) {
      log(e);
      return null;
    }
  } else {
    return null;
  }
}

export const SpPanelContext = React.createContext();
SpPanelContext.displayName = "Panel Context";

export const SpTabsContext = React.createContext();
SpTabsContext.displayName = "Tabs Context";

export const SpDataGridContext = React.createContext();
SpDataGridContext.displayName = "Data Grid Context";

export const SpPageContext = React.createContext();
SpPageContext.displayName = "Page Context";

export const persistentInfo = (table, name) => {
  if (table) {
    return (
      <>
        <Form.Item name={[...name, "className"]} label="className" hidden>
          <Input className="item-property" />
        </Form.Item>
        <Form.Item name={[...name, "objectId"]} label="objectId" hidden>
          <Input className="item-property" />
        </Form.Item>
        <Form.Item name={[...name, "rowKey"]} label="rowKey" hidden>
          <Input className="item-property" />
        </Form.Item>
        <Form.Item name={[...name, "createdAt"]} label="createdAt" hidden>
          <Input className="item-property" />
        </Form.Item>
        <Form.Item name={[...name, "updatedAt"]} label="updatedAt" hidden>
          <Input className="item-property" />
        </Form.Item>
      </>
    )
  } else {
    return (
      <>
        <Form.Item name={[...name, "rowKey"]} label="rowKey" hidden>
          <Input className="item-property" />
        </Form.Item>
      </>
    );
  }
}

export const Elements = {};
export const Buttons = {};
export const UserRuntimeComponents = {};
export const UserComponents = {};
export const InputComponents = [];

export const registerComponent = ({
  key, component, runtimeComponent,
  template, title, icon, type, subtype, sequence }) => {
  UserComponents[key] = component;
  UserRuntimeComponents[key] = runtimeComponent;
  if (!type) type = "Component";
  const btntype = type + (subtype ? "-"+subtype : "");
  const buttons = Buttons[btntype] || {};
  const elements = Elements[type] || {};
  Buttons[btntype] = buttons;
  Elements[type] = elements;
  if (subtype === 'input') InputComponents.push(key);

  elements[key] = key;
  if (template) {
    buttons[key] = {
      key: key,
      template: template,
      title: title,
      icon: icon,
      sequence: sequence,
    };
  }
}

export const getAllButtons = () => {
  let result = [];
  Object.values(Buttons).forEach(map => {
    result = result.concat(Object.values(map));
  });
  result = result.sort((a, b) => compare(a.title, b.title));
  return result;
}

export const getAllButtonGroups = () => {
  let result = [];
  Object.values(Buttons).forEach(map => {
    result.push(Object.values(map));
  });
  result = result.sort((a, b) => compare(a.title, b.title));
  return result;
}

export const getButtons = (type) => {
  const map = Buttons[type] || {};
  let result = Object.values(map)
  result = result.sort((a, b) => compare({sequence:a.sequence,title:a.title},{sequence:b.sequence,title:b.title}, ['sequence', 'title']));
  return result;
}

export const getElements = (type) => {
  const map = Elements[type] || {};
  return Object.values(map);
}

export const getRender = (node) => {
  const type = node.type.resolvedName;
  const UC = UserRuntimeComponents[type];
  if (UC.render) {
    return UC.render(node.props);
  } else {
    return null;
  }
}

const DEFAULT_ID = "96dj03";
export const getRealId = (type) => `${type}${uid()}`;

export const patchRealId = (json) => {
  json = json.replace(RegExp(DEFAULT_ID, "g"), (match) => {
    return uid();
  });
  return json;
}

const ITEM_KEY_NOT_REQUIRED = [
  "SpCanvas", "Container",
]

const INVALID_KEY = /[. /]/;
const PREFERRED_KEY = /^[A-Z][A-Za-z0-9]+$/;
const RESERVED_WORD1 = /^(flowParent|flow|parent|relation|createdAt|updatedAt|ACL|id|objectId|push|replace|reload|open|openPage|cancelPage|closePage)$/;
const RESERVED_WORD2 = /^(forceUpdate|rowKey|key|checked|errorMessage|infoMessage|warningMessage|pageId|pageKey|pageAdmins|pageAdminRoles|pageWidth)$/;

export const updateDisplayName = (props, id) => {
  if (!props.custom) props.custom = {};
  if (props.props?.itemKey) {
    props.custom.displayName = props.displayName + " (" + props.props?.itemKey + ")";
  } else {
    props.custom.displayName = props.displayName;
  }
}

export const validatePageInfo = (json, pageData) => {
  const nodeMap = JSON.parse(json);
  const extraParams = {};
  const itemKeyMap = {};
  const keyItemMap = {};
  const cntrMap = {};
  const parentMap = {};

  if (pageData.pageKey && pageData.pageKey.match(INVALID_KEY)) {
    return returnError("page key is invalid - ("+pageData.pageKey+")");
  }

  if (!pageData.versionStamp && pageData.pageKey && !pageData.pageKey.match(PREFERRED_KEY)) {
    return returnError("page key should be camel case and start with capital letter - ("+pageData.pageKey+")");
  }

  for (const key in nodeMap) {
    if (nodeMap.hasOwnProperty(key)) {
      const node = nodeMap[key];
      const itemKey = node.props?.itemKey;
      if (itemKey) {
        if (ITEM_KEY_NOT_REQUIRED.indexOf(node.type.resolvedName) === -1) {
          if (!itemKey.match(/^[a-zA-Z][a-zA-Z_$0-9]*$/)) {
            return returnError("Item Key is invalid - ("+node.displayName+" - "+itemKey+")");
          }
          if (itemKey.match(RESERVED_WORD1) || itemKey.match(RESERVED_WORD2)) {
            return returnError("Item Key is reserved word - ("+node.displayName+" - "+itemKey+")");
          }
        }
        keyItemMap[key] = itemKey;
        if (!itemKeyMap[itemKey]) {
          itemKeyMap[itemKey] = key;
        }
      }
      // clear rules
      if (node.props?.rules) delete node.props.rules;
      if (node.props?.uniquetable) delete node.props.uniquetable;
    }
  }

  for (const key in nodeMap) {
    if (nodeMap.hasOwnProperty(key)) {
      const node = nodeMap[key];
      updateDisplayName(node, key);
      const nodes = node.nodes;
      if (nodes) {
        const parents = {};
        nodes.forEach(id => {
          const key = keyItemMap[id];
          if (parents[key]) {
            return returnError("Item Key is duplicated - "+key);
          }
          parents[key] = nodeMap[id];
        });

        for(const element of nodes) {
          const id = element;
          if (cntrMap[id]) {
            return returnError("Node found in two containers "+key+" and "+cntrMap[id]);
          } else {
            parentMap[id] = parents;
            cntrMap[id] = key;
          }
        }
      }
      let linkedNodes = node.linkedNodes;
      if (linkedNodes) {
        linkedNodes = Object.values(linkedNodes);
        for(const element of linkedNodes) {
          const id = element;
          if (cntrMap[id]) {
            return returnError("Node found in two containers "+key+" and "+cntrMap[id]);
          } else {
            cntrMap[id] = key;
          }
        }
      }
    }
  }

  const getCntr = (key) => {
    if (cntrMap[key]) {
      const k1 = cntrMap[key];
      if (k1) {
        const k2 = cntrMap[k1];
        const n = nodeMap[k2];
        if (n && n.props?.itemKey) {
          return n;
        } else {
          const k3 = cntrMap[k2];
          const m = nodeMap[k3];
          if (m) {
            return m;
          }
        }
      }
    }
    return null;
  }

  for (const key in nodeMap) {
    if (nodeMap.hasOwnProperty(key)) {
      const node = nodeMap[key];
      const type = node.type.resolvedName;
      const parents = parentMap[key];
      const cntr = getCntr(key);
      const UC = UserComponents[type];

      if (UC.validate) {
        const result = UC.validate(node.props, {parents: parents, container: cntr, extraParams, pageData});
        if (result) {
          return returnError(result);
        }
      }
    }
  }
  return {
    error: null,
    json: JSON.stringify(nodeMap, (key, value) => deduceUndefined(value)),
    extraParams: extraParams,
  };
}

const returnError = (error) => {
  return {
    error: error
  };
}

export const getId = (type) => `${type}${DEFAULT_ID}`;

export const extractTag = (text, tag) => {
  const start = text.indexOf("<"+tag+"");
  const end1 = text.indexOf(">", start);
  const end = text.indexOf("</"+tag+">", end1+1);
  log("start", start, "end", end, "tag", tag);
  if (start !== -1 && end !== -1 && start < end) {
    const content = text.substr(start, end-start+tag.length+3);
    const prefix = text.substr(0, start);
    const suffix = text.substr(end+tag.length+3);
    log("content", content);
    return [`${prefix}${suffix}`, content];
  } else {
    return null;
  }
}

export const getHTML = async (url) => {
  return new Promise((resolve, reject) => {
    const page = window.open(url+"?headless=true",'_blank','height=600,width=800');
    page.setTimeout(function () {
      page.requestAnimationFrame(() => {
        log("on load...");
        const outerHTML = page.document.documentElement.outerHTML;
        const nobase64 = outerHTML.replace(/base64[^=]*=/g,"");
        const heads = extractTag(nobase64, "head");
        const html = heads[0];
        let style = heads[1];
        let noscripts = extractTag(style, "script");
        style = style.replace(/\\r\\n/g," ");
        style = style.replace(/\s+/g," ");
        let count = 0
        while (noscripts != null && count < 100) {
          style = noscripts[0];
          noscripts = extractTag(style, "script");
          count++
        }
        const allStyles = [];
        const usedClasses = [];
        html.match(/class="[^"]+"/g).forEach(s=> {
          s.substr(7,s.length-8).split(" ").forEach(s2=>{
            usedClasses.push(s2);
          })
        });
        let level = 0;
        let idx = -1;
        for (let i = 0; i < style.length; i++) {
          if (style[i] === "." && level === 0 && idx === -1) {
            idx = i;
          } else if (style[i] === '{') {
            level++;
          } else if (style[i] === '}' && level > 0) {
            level--;
            if (level === 0) {
              const line = style.substr(idx, i-idx+1).trim();
              let found = false;
              for (const element of usedClasses) {
                if (element.length > 3) {
                  if (line.indexOf(element) !== -1) {
                    found = true;
                    break;
                  }
                }
              }
              if (found) {
                allStyles.push(line);
              }
              idx = i+1;
            }
          }
        }
        resolve(html.replace("<body", "<style>"+allStyles.join("\n")+"</style><body"));
        page.close();
      });
    }, 1000 * 5)
  });
}

export const defaultOptionFilter = (input, option) => {
  return getText(option).toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

export const refreshOptions = async (locale, selectkey, isUseCode) => {
  const list = await selectApi.fetchSystemSelectOption(locale, selectkey, isUseCode);
  return list;
}

export const syncPageState = ({itemKey, myValue, setMyValue, pageState, setPageState}) => {
  if (pageState) {
    if (itemKey in pageState) {
      if (setMyValue) {
        const newMyValue = pageState[itemKey];
        if (!equals(myValue, newMyValue)) {
          setMyValue(pageState[itemKey]);
        }
      }
    } else {
      if (setPageState) {
        setPageState({...pageState, [itemKey]: myValue});
      }
    }
  }
}

export const updatePageState = ({itemKey, myValue, setMyValue, pageState, setPageState, action}) => {
  const value = (myValue?.target) ? myValue.target.value : myValue;
  if (pageState && setPageState) {
    setPageState((prev) => {
      const newPageState = {...prev, [itemKey]:value};
      if (action) newPageState[action + '_clicked'] = true;
      return newPageState;
    });
  } else if (setMyValue) {
    setMyValue(value);
  }
}

const allowedParentTypes = ['SpSelect', 'SpDate', 'SpArray']

export const SelectCollector = (state, query) => {
  const currentNodeId = getCraftSelectedNodeId({state, query});
  let selectedNode;

  if (currentNodeId) {
    selectedNode = {
      id: currentNodeId,
      name: state.nodes[currentNodeId].data.name,
      displayName: state.nodes[currentNodeId].data.displayName,
      props: state.nodes[currentNodeId].data.props,
      settings: state.nodes[currentNodeId].related && state.nodes[currentNodeId].related.settings,
      isDeletable: query.node(currentNodeId).isDeletable()
    };
  }

  const ancestors = selectedNode ? query.node(selectedNode.id).ancestors() : null;
  let sibling = [];
  if (ancestors && ancestors.length > 0) {
    sibling = query.node(ancestors[0]).childNodes();
    sibling = sibling.filter(sid=> {
      if (sid !== selectedNode.id) {
        const n = query.node(sid).get();
        if (allowedParentTypes.indexOf(n?.data?.name) !== -1) {
          return true;
        }
      }
      return false;
    }).map(sid => {
      const n = query.node(sid).get();
      return n?.data?.props?.itemKey;
    });
  }
  const localSelects = sibling.map(s => {
    return { value: s, label: s };
  })
  const mainSelects = getMainSelects(query, selectedNode);
  return {
    localSelects,
    mainSelects,
  }
}

export const getMainSelects = (query, selectedNode) => {
  try {
    if (selectedNode) {
      const ancestor = query.node(selectedNode.id).ancestors()[0];
      if (query.node(ancestor).get()?.data?.name === 'EditPad') {
        // table cannot get value from parent form, disable this feature
        return [];
      }

      let mainSibling = [];
      const root = query.node("ROOT");
      const rootSibling = root.childNodes();
      const mainNode = rootSibling.filter(sid => {
        const n = query.node(sid).get();
        return n.data.name === 'SfMainPanel';
      })[0];
      const mainContainer = query.node(mainNode).linkedNodes()[0];
      if (ancestor !== mainContainer) {
        mainSibling = query.node(mainContainer).childNodes();
        mainSibling = mainSibling.filter(sid => {
          const n = query.node(sid).get();
          return allowedParentTypes.indexOf(n?.data?.name) !== -1;
        }).map(sid => {
          const n = query.node(sid).get();
          return n?.data?.props?.itemKey;
        });
        const mainParents = mainSibling.map(s => {
          return { value: 'main.'+s, label: 'main.'+s };
        })
        return mainParents;
      }
    }
  } catch (error) {}
  return [];
}
