import { ClockCircleOutlined } from "@ant-design/icons";
import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Switch, TimePicker } from "antd";
import { Form, Input, Select } from "components/Form";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { momentTime } from "util/algorithm";
import IntlMessages from "util/IntlMessages";
import { dateText, isDate } from "../../../../util/algorithm";
import {
  generateTooltip,colSls, convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate, updateTableCell, convertRules
} from "./common";

const SfTimeSetting = () => {
  const form = Form.useFormInstance();
  const stringMode = Form.useWatch('stringMode', form);
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="placeholder" label="placeholder">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="format" label="format">
        <Select className="item-property">
          <Select.Option value="HH:mm:ss">HH:mm:ss</Select.Option>
          <Select.Option value="HH:mm">HH:mm</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="minuteStep" label="min. step">
        <InputNumber className="item-property" min={1} max={60}/>
      </Form.Item>
      <Form.Item name="secondStep" label="sec. step">
        <InputNumber className="item-property" min={1} max={60}/>
      </Form.Item>
      <Form.Item name="stringMode" label="text mode" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="withTimezone" label="w/ timezone" valuePropName="checked">
        <Switch className="item-property" disabled={stringMode} />
      </Form.Item>
      <Form.Item name="tiptext" label="tooltip text" >
        <Input.TextArea
          className="item-property"
          autoSize allowClear
        />
      </Form.Item>
      <Form.Item name="tiplink" label="tooltip link" >
        <Input className="item-property" type="url"/>
      </Form.Item>
      <Form.Item name="popupStyleStr" label="popup style">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="size" label="size">
        <Select className="item-property">
          <Select.Option value="large">large</Select.Option>
          <Select.Option value="middle">middle</Select.Option>
          <Select.Option value="small">small</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="allowClear" label="allow clear" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="bordered" label="bordered" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="inputReadOnly" label="choose only" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="editInTable" label="edit col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="isSelectKey" label="select key" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfTime = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpTime doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const MyTimePicker = ({value, noupdate, disabled, onChange, format, stringMode, withTimezone, allowClear, open, ...props}) => {
  const ref = useRef();
  const [myValue, setMyValue] = useState();
  const [myOpen, setMyOpen] = useState(open);
  const doMyChange = (value) => {
    if (stringMode) {
      if (value) value = value.format(format);
    } else if (withTimezone) {
      if (value) value = value.format();
    } else {
      if (value) value = momentTime(value, format, true);
    }
    if (onChange) onChange(value);
  }
  const doMyOpenChange = (newOpen) => {
    setMyOpen(newOpen);
    if (open && props.onBlur) {
      props.onBlur();
    }
  }
  useEffect(() => {
    if (typeof value === 'string') {
      if (stringMode) {
        setMyValue(moment(value, format));
      } else if (withTimezone) {
        setMyValue(moment(value));
      } else if (isDate(value)) {
        setMyValue(moment(value));
      } else {
        setMyValue(null);
      }
    } else {
      setMyValue(value);
    }
  },[format, stringMode, value, withTimezone])
  if (ref.current === undefined) ref.current = noupdate && value !== undefined;
  return <TimePicker value={myValue} disabled={disabled || ref.current} open={myOpen} onOpenChange={doMyOpenChange} format={format} allowClear={allowClear} {...props} onChange={doMyChange}/>
}

export const SfpTime = ({
  doRef, form, condistyles, className, style, dbtn, hideInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, volitate, skipcopy,tiptext,tiplink,
  inputref, styleStr, popupStyleStr, children, ...otherProps }) => {
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const pus = convertStyleStr(popupStyleStr);
  const [fxr, setFxr] = useState({});
  const newRules = convertRules(rules);
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name} label={title} tooltip={generateTooltip(tiptext,tiplink)}
              shouldUpdate={fx} hidden={fxr.hidden} rules={fxr.hidden || otherProps.disabled ? null : newRules}
              labelAlign={labelAlign} labelCol={lcs} wrap>
              <MyTimePicker inputref={inputref} style={sls} popupStyle={pus} {...otherProps}/>
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

export const EditableTimeRenderer = ({value, record, index, form, tableKey, onRowChange, editInTable, itemKey, tableColWidth, disabled, format, minuteStep, secondStep, stringMode, showTime, showNow, withTimezone}) => {
  const [editing, setEditing] = useState(false);
  const [myValue, setMyValue] = useState(value);
  const leditInTable = editInTable && !disabled;
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, [])
  useEffect(() => {
    setMyValue(value);
  }, [value]);
  const onClick = (event) => {
    setEditing(true);
    event.preventDefault();
    event.stopPropagation();
  }
  if (editing) {
    const onChange = (value) => {
      try {
        setMyValue(value);
        const event = null;
        updateTableCell({form, tableKey, record, itemKey, myValue: value, onRowChange, setEditing, event, index});
        setEditing(false);
      } catch (e) {
        console.error(e);
      }
    }
    const onBlur = () => {
      setEditing(false);
    }
    const className = "row-editing table-cell-time"
    const open = true;
    const props = {value: myValue, record, index, format, minuteStep, secondStep, stringMode, showTime, showNow, withTimezone, className, onChange, onBlur, open};
    if (tableColWidth) props.style = {"minWidth": `${tableColWidth}px`,"maxWidth": `${tableColWidth}px`}
    return <MyTimePicker {...props} />
  } else {
    const editingProps = leditInTable ? {value: myValue, record, index, format, minuteStep, secondStep, stringMode, showTime, showNow, withTimezone, className: "row-editable table-cell-time", onClick} : {};
    if (tableColWidth) editingProps.style = {"minWidth": `${tableColWidth}px`, "maxWidth": `${tableColWidth}px`}
    return <TimeRenderer {...editingProps}/>;
  }
}

export const TimeRenderer = ({className, style, onClick, itemKey, format, value, index}) => {
  return <span className={className} style={style} onClick={onClick} key={itemKey+"_"+index}>{dateText(value, format)}</span>;
}

SfpTime.render = ({ editInTable, itemKey, mask, tableColWidth, disabled, format, minuteStep, secondStep, stringMode, showTime, showNow, withTimezone }, form, tableKey, onRowChange) => (value, record, index) => {
  const props = {editInTable, itemKey, mask, tableColWidth, disabled, format, minuteStep, secondStep, stringMode, showTime, showNow, withTimezone, value, record, index, form, tableKey, onRowChange}
  if (editInTable) {
    return <EditableTimeRenderer {...props}/>
  } else {
    return <TimeRenderer {...props}/>
  }
}

SfTime.craft = {
  displayName: "Time",
  related: {
    settings: SfTimeSetting
  }
}

SfTime.validate = (props, {parents, container, extraParams, formData}) => {
  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "time",
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      format: props.format,
      stringMode: props.stringMode,
      withTimezone: props.withTimezone,
      minuteStep: props.minuteStep,
      secondStep: props.secondStep,
      sortable: true,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
    });
    if (props.isSelectKey) {
      extraParams.selectConfig.optionTypes.push(props.itemKey);
    }
  }
  if (props.stringMode && props.withTimezone) {
    props.withTimezone = false;
  }
}

SfTime.isSearchable = true;
SfTime.isRulable = true;

registerComponent({
  key:"SfTime",
  component: SfTime,
  runtimeComponent: SfpTime,
  template: <SfTime itemKey={getId('time')} className="sf-time wrap"
            title={"Time"} format="HH:mm" span={24} labelColStr="span:6" />,
  title: <IntlMessages id="system.form.library.time" text="Time" />,
  icon: <ClockCircleOutlined  className="react-icons icon-antd"/>,
  type: "Component",
  sequence: 4,
});

export default SfTime;