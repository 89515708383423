import { useEditor, useNode } from "@craftjs/core";
import { Button, Col, Empty, InputNumber, Modal, Switch } from "antd";
import { Form, Input } from "components/Form";
import React, { useEffect, useRef, useState } from "react";
import { FaSignature } from "react-icons/fa";
import SignatureCanvas from 'react-signature-canvas';
import IntlMessages from "util/IntlMessages";
import {
  colSls, convertRules, convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate
} from "./common";
import { resizeImage, log } from "util/algorithm";


const SfInputSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="width" label="width">
        <InputNumber min={1} className="item-property"/>
      </Form.Item>
      <Form.Item name="height" label="height">
        <InputNumber min={1} className="item-property"/>
      </Form.Item>
      <Form.Item name="popupWidth" label="popup width">
        <InputNumber min={1} className="item-property"/>
      </Form.Item>
      <Form.Item name="popupHeight" label="popup height">
        <InputNumber min={1} className="item-property"/>
      </Form.Item>
      <Form.Item name="centered" label="centered" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="top" label="top">
        <InputNumber className="item-property" min={0} />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfSignature = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpSignature doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const MySignature = ({id, value, onChange, noupdate, disabled, width, height, popupWidth, popupHeight, top, style, className, ...props}) => {
  const [visible, setVisible] = useState();
  const sigCanvas = useRef();
  const ref = useRef();
  const [canvasArea, setCanvasArea] = useState({width:popupWidth, height:popupHeight});
  const [windowSize, setWindowSize] = useState({width:window.innerWidth - 40, height:window.innerHeight - 100});

  if (ref.current === undefined) ref.current = noupdate && value !== undefined;
  disabled=(disabled || ref.current);
  const mounted = useRef();
  useEffect(()=> {
    mounted.current = true;
    window.addEventListener("resize", handleResize, false);
    return ()=> {
      mounted.current = false;
      window.removeEventListener("resize", handleResize);
    }
  },[])

  useEffect(()=> {
    const w = width ? width : 350;
    const h = height ? height : 150;
    const ratio = h / w;
    let pWidth = popupWidth;
    if (!pWidth) pWidth = 700;
    if (pWidth > windowSize.width - 40) pWidth = windowSize.width - 40;
    if (pWidth < w) pWidth = w;
    const pHeight = pWidth * ratio;
    setCanvasArea({width:pWidth, height:pHeight});
  }, [width, height, popupWidth, popupHeight, windowSize])

  const handleResize = () => {
    setWindowSize({width:window.innerWidth - 40, height:window.innerHeight - 100});
  }

  const handleOk = () => {
    log("handleOk", sigCanvas.current.isEmpty());
    if (sigCanvas.current.isEmpty()) {
      if (onChange) onChange(null);
    } else {
      const data = sigCanvas.current.toDataURL("image/svg");
      log("data", data)
      if (onChange) onChange(data);
    }
    if (mounted.current) setVisible(false);
  }
  const handleCancel = () => {
    if (mounted.current) setVisible(false);
  }
  const handleClear = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
    }
  }
  const setSigCanvas = async (ref) => {
    if (!sigCanvas.current && ref) {
      sigCanvas.current = ref;
      sigCanvas.current.clear();
      sigCanvas.current.fromDataURL(await resizeImage(value, canvasArea.width), canvasArea);
    }
  }
  const onClick = async () => {
    if (value && sigCanvas.current) {
      sigCanvas.current.clear();
      sigCanvas.current.fromDataURL(await resizeImage(value, canvasArea.width), canvasArea);
    }
    if (mounted.current) setVisible(true);
  }
  if (!style) style = {};
  if (width) style.width = width;
  if (height) style.height = "auto";
  if (!value) style.padding = "15px 15px 10px 10px";
  let modalStyle = null;
  if (top) {
    modalStyle = {
      top: top
    }
  }
  return (
    <div id={id} className={"signature"}>
      {value && <div style={style} className={"image"}><img src={value} alt="signature"/></div>}
      {!value && <div style={style} className={"image"}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/></div>}
      {!disabled && <Button icon={<FaSignature className="react-icons icon-fa" />} onClick={onClick} />}
      <Modal style={modalStyle} className={className} width={canvasArea.width + 20} onCancel={handleCancel}
        title={<IntlMessages id="system.form.signature" text="Signature" />} visible={visible}
        footer={[
          <Button key="ok" onClick={handleOk}>
            <IntlMessages id="system.form.ok" text="OK" />
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            <IntlMessages id="system.form.cancel" text="Cancel" />
          </Button>,
          <Button key="clear" onClick={handleClear} >
            <IntlMessages id="system.form.signature.clear" text="Clear" />
          </Button>,
        ]}
      >
        <SignatureCanvas
          ref={setSigCanvas}
          canvasProps={canvasArea} />
      </Modal>
    </div>
  );
}

export const SfpSignature = ({
  doRef, form, condistyles, className, style, dbtn, hideInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, volitate, skipcopy,
  inputref, styleStr, children, ...otherProps }) => {
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const [fxr, setFxr] = useState({});
  const newRules = convertRules(rules);
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name} label={title}
              shouldUpdate={fx} hidden={fxr.hidden} rules={fxr.hidden || otherProps.disabled ? null : newRules}
              labelAlign={labelAlign} labelCol={lcs} wrap>
              <MySignature className={className} inputref={inputref} style={sls} {...otherProps} />
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

SfpSignature.render = ({itemKey,width,height}) => (value, index) => {
  return <span key={itemKey+"_"+index}><MySignature width={width} height={height} value={value} disabled={true}/></span>;
}

SfSignature.craft = {
  displayName: "Signature",
  related: {
    settings: SfInputSetting
  }
}

SfSignature.validate = (props, {parents, container, extraParams}) => {
  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "image",
      width: props.width,
      height: props.height,
      title: props.tableColTitle || props.title,
      sortable: false,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
    });
  }
}

registerComponent({
  key:"SfSignature",
  component: SfSignature,
  runtimeComponent: SfpSignature,
  template: <SfSignature itemKey={getId('signature')} className="sf-signature wrap"
            title={"Signature"} span={24} labelColStr="span:6" hideInTable={true} width={350} height={150} popupWidth={700} popupHeight={300}/>,
  title: <IntlMessages id="system.form.library.signature" text="Signature" />,
  icon: <FaSignature  className="react-icons icon-fa"/>,
  type: "Component",
  sequence: 16,
});

export default SfSignature;