import { BorderHorizontalOutlined } from "@ant-design/icons";
import { Element, useEditor, useNode } from "@craftjs/core";
import { Col, Row } from "antd";
import IntlMessages from "util/IntlMessages";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting } from "./common";
import SpSplitterPanel from "./SpSplitterPanel";
import { useEffect, useRef } from "react";

export const SpSplitter = ({...props}) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  const resizeBarRef = useRef();
  const resizeMainRef = useRef();
  const observeRef = useRef();
  const resizeHgt = useRef();
  useEffect(() => {
    if (resizeMainRef.current) {
      observeRef.current = new ResizeObserver(function () {
        try {
          const hgt = resizeMainRef.current.offsetHeight;
          resizeMainRef.current.style.position = 'absolute';
          resizeBarRef.current.style.height = `${hgt}px`;
          resizeHgt.current = `${hgt}px`;
        } catch (error) {}
      }).observe(resizeMainRef.current);
    }
    return () => {
      if (observeRef.current) {
        observeRef.current.disconnect();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <SppSplitter
      doRef={(ref) => connect(drag(ref))}
      selectedStyle={style}
      dbtn={dbtn}
      {...props}
    >
      <Col className="splitter-left">
        <div className="resize-bar" ref={resizeBarRef} style={{'--hgt': resizeHgt.current}}></div>
        <div className="resize-line"></div>
        <div className="resize-main" ref={resizeMainRef}>
            <Row>
                <Element
                    id="left"
                    canvas
                    is={SpSplitterPanel}
                    itemKey={getId("splitterPanel")}
                    className="sp-splitter-panel"
                    span="24"
                    style={{minHeight: "300px"}}
                />
          </Row>
        </div>
      </Col>
      <Col className="splitter-right">
        <Row>
            <Element
            id="right"
            canvas
            is={SpSplitterPanel}
            itemKey={getId("splitterPanel")}
            className="sp-splitter-panel"
            span="24"
            style={{minHeight: "300px", backgroundColor: "#EEE"}}
            />
        </Row>
      </Col>
    </SppSplitter>
  );
}

export const SppSplitter = ({
  doRef, dbtn, selectedStyle, children, childNodes, processChildNode,
  pageKey, itemKey, pageState, setPageState, ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}
  const resizeBarRef = useRef();
  const resizeMainRef = useRef();
  const observeRef = useRef();
  const resizeHgt = useRef();
  useEffect(() => {
    if (resizeMainRef.current) {
      observeRef.current = new ResizeObserver(function () {
        try {
          const hgt = resizeMainRef.current.offsetHeight;
          resizeMainRef.current.style.position = 'absolute';
          resizeBarRef.current.style.height = `${hgt}px`;
          resizeHgt.current = `${hgt}px`;
        } catch (error) {}
      }).observe(resizeMainRef.current);
    }
    return () => {
      if (observeRef.current) {
        observeRef.current.disconnect();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (doRef) {
    return (
      <Col ref={doRef} {...props}>
        {children}
        {dbtn}
      </Col>
    );
  } else {
    return (
      <Col ref={doRef} {...props}>
        <Col className="splitter-left">
          <div className="resize-bar" ref={resizeBarRef} style={{'--hgt': resizeHgt.current}}></div>
          <div className="resize-line"></div>
          <div className="resize-main" ref={resizeMainRef}>
            {childNodes?.length > 0 && processChildNode(childNodes?.[0])}
          </div>
        </Col>
        <Col className="splitter-right">
          {childNodes?.length > 0 && processChildNode(childNodes?.[1])}
        </Col>
      </Col>
    );
  }
}
SppSplitter.collector = () => ["SpSplitterPanel"];

const SpSplitterSetting = () => {
  return (
    <>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  );
}
SppSplitter.enablePageState = true;

SpSplitter.craft = {
  displayName: "Splitter",
  related: {
    settings: SpSplitterSetting
  }
}

registerComponent({
  key:"SpSplitter",
  component: SpSplitter,
  runtimeComponent: SppSplitter,
  template: <Element canvas is={SpSplitter} itemKey={getId('splitter')}
            className="sp-splitter" span="24" />,
  title: <IntlMessages id="system.page.library.splitter" text="Splitter" />,
  icon: <BorderHorizontalOutlined  className="react-icons icon-antd"/>,
  type: "Container",
});

export default SpSplitter;