import { UserOutlined } from "@ant-design/icons";
import { Avatar, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import ReactAvatar from 'react-avatar';
import {prepareText, hashCode} from '../../util/algorithm';
import { AccountStore } from "../../constants/Account";
const SPRITE = ["initials", "identicon", "bottts", "avataaars", "jdenticon"];

export const UserAvatar = ({username, userObj, className, light, noTooltip, onClick, size}) => {
  const [name,setName] = useState();
  useEffect(() => {
    let n = userObj?.nickname || userObj?.username;
    if (!n && username && username.length > 0) {
      n = username;
    }
    if (n && n !== name) {
      setName(n);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userObj, username])
  const cls = className ? `gx-pointer ${className}` : 'gx-pointer';
  let comp = null;
  if (userObj?.username === 'guest') {
    comp = <Avatar className={cls} icon={<UserOutlined />} size={size}/>;
  } else if (!name) {
    comp = <Avatar className={cls} icon={<UserOutlined />} size={size}/>;
  } else if (userObj?.avatar) {
    comp = <Avatar className={cls} src={userObj.avatar} alt={name} size={size}/>
  } else if (AccountStore.ENABLE_AVATAR_URL) {
    const sprite = SPRITE[Math.abs(hashCode(name)) % SPRITE.length]
    const uri = prepareText(AccountStore.AVATAR_URL, {seed:name,sprite});
    comp = <Avatar className={cls} src={uri} alt={name} size={size}/>;
  } else {
    comp = <ReactAvatar className={cls} textSizeRatio={3}  size={size || 40} name={name} round/>
  }
  if (noTooltip) {
    return comp;
  } else {
    return <Tooltip mouseEnterDelay={1} title={name} onClick={onClick}>{comp}</Tooltip>;
  }
};

export default UserAvatar;
