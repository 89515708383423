import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, message, Popover, Switch, Tabs, Tag } from "antd";
import { Form, Input } from "components/Form";
import React, { useEffect, useRef, useState } from "react";
import { VscSymbolArray } from "react-icons/vsc";
import ReactJson from "react-json-view";
import IntlMessages from "util/IntlMessages";
import { AccountStore } from "../../../../constants/Account";
import {
  colSls, convertRules, convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate
} from "./common";

const SfArraySetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="readOnly" label="read-only" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hidden" label="hidden" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="isSelectKey" label="select key" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfArray = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpArray doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const JsonArrayEditor = ({
  readOnly,
  disabled,
  value,
  onChange,
  hidden,
  initialValue,
}) => {
  const [myValue, setMyValue] = useState("");
  const [jsonValue, setJsonValue] = useState([]);
  const ref = useRef();
  const initialRef = useRef();
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  useEffect(() => {
    if (value) {
      updateValue(value)
    } else {
      if (mounted.current) setMyValue("");
    }
    if (initialRef.current) {
      clearTimeout(initialRef.current);
      initialRef.current = null;
    }
    initialRef.current = setTimeout(() => {
      if (typeof value === 'undefined' &&
          typeof initialValue !== 'undefined') {
        if (onChange) {
          onChange(initialValue)
        }
      }
    }, AccountStore.ON_CHANGE_DELAY)
  }, [value, initialValue, onChange]);
  const updateValue = (value) => {
    if (Array.isArray(value)) {
      if (mounted.current) setMyValue(JSON.stringify(value, null, 3));
      if (mounted.current) setJsonValue(value);
    } else {
      if (mounted.current) setJsonValue([]);
    }
  }
  const onBlur = () => {
    if (onChange) {
      try {
        if (myValue && myValue.length > 0) {
          const value = JSON.parse(myValue);
          if (Array.isArray(value)) {
            onChange(value);
          } else {
            throw new Error("Object is not an array!");
          }
        } else {
          onChange(null);
        }
      } catch (e) {
        message.error(`${e}`);
        ref.current.focus();
      }
    }
  };
  const updateJsonValue = (e) => {
    let newValue = e.updated_src;
    if (mounted) setJsonValue(newValue);
    if (newValue.length === 0) newValue = null;
    if (onChange) onChange(newValue);
  };
  if (!hidden) {
    return (
      <Tabs>
        <Tabs.TabPane
          tab={<IntlMessages id="system.form.json.normal" text="Normal" />}
          key="Normal"
        >
          <ReactJson
            src={jsonValue}
            onEdit={!readOnly && !disabled && updateJsonValue}
            onAdd={!readOnly && !disabled && updateJsonValue}
            onDelete={!readOnly && !disabled && updateJsonValue}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={<IntlMessages id="system.form.json.advance" text="Advance" />}
          key="Advance"
        >
          <Input.TextArea
            ref={ref}
            readOnly={readOnly}
            disabled={disabled}
            value={myValue}
            autoSize
            onChange={(e) => setMyValue(e.target.value)}
            onBlur={onBlur}
          />
        </Tabs.TabPane>
      </Tabs>
    );
  } else {
    return null;
  }
};

export const SfpArray = ({
  doRef, form, condistyles, className, style, dbtn, hidden, hideInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, volitate, skipcopy,
  inputref, styleStr, children, isSelectKey, ...otherProps }) => {
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const [fxr, setFxr] = useState({});
  const newRules = convertRules(rules);
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name} label={title} placeholder={otherProps.placeholder}
               shouldUpdate={fx} hidden={fxr.hidden || (!doRef && hidden)} rules={fxr.hidden || hidden || otherProps.disabled ? null : newRules}
              labelAlign={labelAlign} labelCol={lcs} wrap>
              <JsonArrayEditor style={sls} disabled={fxr.disabled || otherProps.disabled} hidden={!doRef && hidden} {...otherProps} />
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

const JsonRenderer = ({
  value,
  record,
  index,
  form,
  tableKey,
  onRowChange,
  editInTable,
  itemKey,
  mask,
  tableColWidth,
  disabled,
}) => {
  return (
    <span className="cell-content clickable">
      {value && (
        <Popover content={<ReactJson src={value} />}>
          <Tag>
            <VscSymbolArray className="react-icons icon-vsc" />
          </Tag>
        </Popover>
      )}
    </span>
  );
};

SfpArray.render = ({ editInTable, itemKey, mask, tableColWidth, disabled }, form, tableKey, onRowChange) => (value, record, index) => {
  const props = {value, record, index, form, tableKey, onRowChange, editInTable, itemKey, mask, tableColWidth, disabled};
  return <JsonRenderer {...props}/>
}

SfArray.craft = {
  displayName: "Array",
  related: {
    settings: SfArraySetting
  }
}

SfArray.validate = (props, {parents, container, extraParams}) => {
  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "json[]",
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      sortable: true,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
    });
    if (props.isSelectKey) {
      extraParams.selectConfig.optionTypes.push(props.itemKey);
    }
  }
}

SfArray.isSearchable = true;
SfArray.isRulable = true;

registerComponent({
  key:"SfArray",
  component: SfArray,
  runtimeComponent: SfpArray,
  template: <SfArray itemKey={getId('array')} className="sf-array wrap"
            title={"Array"} span={24} labelColStr="span:6" />,
  title: <IntlMessages id="system.form.library.array" text="Array" />,
  icon: <VscSymbolArray  className="react-icons icon-vsc"/>,
  type: "Component",
  sequence: 25,
});

export default SfArray;