import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Switch } from "antd";
import { Form, Input } from "components/Form";
import React, { useEffect, useRef, useState } from "react";
import { MdOutlineAccountTree } from "react-icons/md";
import IntlMessages from "util/IntlMessages";
import { IconSelect } from "../../../../components/Form/IconSelect";
import { AccountStore } from "../../../../constants/Account";
import { equals, tryParseJson } from "../../../../util/algorithm";
import { MyTree } from "../../FormSetup/components/SfTree";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting, syncPageState, updatePageState } from "./common";

const SpTreeSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="defaultValue" label="default value" className="defaultValue"
        labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} >
        <Input.TextArea
          className="item-property"
          autoSize allowClear
        />
      </Form.Item>
      <Form.Item name="height" label="height">
        <InputNumber className="item-property" />
      </Form.Item>
      <Form.Item name="showLine" label="show line" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="switcherIconKey" label="switcher">
        <IconSelect iconTypes={['ANTD']}/>
      </Form.Item>
      <Form.Item name="leafIconKey" label="leaf icon">
        <IconSelect iconTypes={['ANTD']}/>
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="selectable" label="selectable" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="checkable" label="checkable" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="blockNode" label="block node" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="draggable" label="draggable" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="debug" label="debug" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="noadd" label="no add" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="noedit" label="no edit" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="nocopy" label="no copy" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="nodelete" label="no delete" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  )
}

export const SpTree = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppTree doRef={ref => connect(drag(ref))} dbtn={dbtn} {...props} selectedStyle={style} />
  )
}

export const SppTree = ({
  doRef, dbtn, selectedStyle,
  pageKey, itemKey, pageState, setPageState, value, defaultValue,
  height, showLine, switcherIconKey, leafIconKey, selectable, checkable, blockNode, draggable,
  disabled, debug, noadd, noedit, nocopy, nodelete, autocolor, row, hidden, firePageEvent, ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  if (!doRef && hidden) style.display = 'none';
  const props = {...otherProps, style}
  const inputProps = {height, showLine, switcherIconKey, leafIconKey, selectable, checkable, blockNode, draggable, disabled, debug, noadd, noedit, nocopy, nodelete, autocolor, row};
  const [myValue, setMyValue] = useState();
  const timer = useRef()
  const selectedKeysRef = useRef();

  useEffect(() => {
    if (typeof value === 'undefined') {
      updatePageState({itemKey, myValue: tryParseJson(defaultValue), setMyValue, pageState, setPageState})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    syncPageState({itemKey, myValue, setMyValue, pageState, setPageState})
  },[itemKey, myValue, pageState, setPageState])

  const onMyChange = (myValue) => {
    const sks = myValue.selectedKeys;
    if (firePageEvent && !equals(sks, selectedKeysRef.current)) {
      firePageEvent(pageState, itemKey, myValue)
    } else {
      if (timer.current) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        updatePageState({itemKey, myValue, setMyValue, pageState, setPageState})
      }, AccountStore.ON_CHANGE_DELAY);
    }
    selectedKeysRef.current = sks;
  }

  return (
    <Col ref={doRef} {...props}>
      <MyTree id={itemKey} value={myValue} onChange={onMyChange} {...inputProps} />
      {dbtn}
    </Col>
  );
}
SppTree.enablePageState = true;
SppTree.enablePageEvent = true;

SpTree.craft = {
  displayName: "Tree",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpTreeSetting
  }
}

registerComponent({
  key: "SpTree",
  component: SpTree,
  runtimeComponent: SppTree,
  template: <SpTree itemKey={getId('tree')} className="sp-tree tree" title={"Tree"} span={24} row={6} />,
  title: <IntlMessages id="system.page.library.tree" text="Tree" />,
  icon: <MdOutlineAccountTree className="react-icons icon-md"/>,
  type: "Component",
  subtype: "input",
  sequence: 20,
});

export default SpTree;