import { LOAD_LANGUAGE, LOAD_MENU, LOAD_VERSION_NO, SAVE_MENU } from "constants/ActionTypes";
import {
  system, translateApi
} from "parse-api";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  loadMenuSuccess, loadVersionFail,
  loadVersionSuccess, saveMenuSuccess,
} from "../../appRedux/actions/System";
import { getUserLocale } from "../../lngProvider";
import { getLocalStorageItem, isInIframe, removeLocalStorageItem, setLocalStorageItem } from "../../util/algorithm";
import { switchLanguage } from "../actions/Setting";

const loadVersionFromServerRequest = async () =>
  system.getSystemVersion()
    .then(version => version)
    .catch(error => error);

function* loadVersionFromServer() {
  try {
    const version = yield call(loadVersionFromServerRequest);
    if (version && version.version) {
      yield put(loadVersionSuccess(version));
    } else {
      yield put(loadVersionFail());
    }
  } catch (error) {
    console.error("load menu error", error);
  }
}

const loadMenuFromServerRequest = async () => {
  const locale = getUserLocale();
  return system.loadMenu(locale)
    .then(menu => menu)
    .catch(error => error);
}

function* loadMenuFromServer() {
  try {
    const menu = yield call(loadMenuFromServerRequest);
    if (menu === undefined) {
      // skip undefined
    } else {
      setLocalStorageItem('menu_obj_enc', menu);
      yield put(loadMenuSuccess(menu));
    }
  } catch (error) {
    console.error("load menu error", error);
  }
}

const saveMenuToStorageRequest = async (value) =>
  system.saveSystemParameter("MENU", value)
    .then(parameter => parameter ? parameter.value : undefined)
    .catch(error => error);

function* saveMenuToStorage({payload}) {
  const menu = yield call(saveMenuToStorageRequest, payload);
  try {
    if (menu === undefined) {
      removeLocalStorageItem('menu_obj_enc');
    } else {
      setLocalStorageItem('menu_obj_enc', menu);
    }
    yield put(saveMenuSuccess(menu));
  } catch (error) {
    console.error("save menu error", error);
  }
}

const loadLanguageFromServerRequest = async () =>
  translateApi.loadSystemLanguage()
    .then(languages => languages)
    .catch(error => error);

function* loadLanguageFromServer() {
  try {
    const languages = yield call(loadLanguageFromServerRequest);
    const oldLanguages = getLocalStorageItem('language_data')
    const newLanguages = JSON.stringify(languages);
    if (oldLanguages !== newLanguages) {
      if (languages) setLocalStorageItem('language_data', newLanguages);
      if  (!isInIframe()) {
        setLocalStorageItem('IS_LOCALE_LOADING', "Y");
        yield put(switchLanguage());
      }
    } else {
      if  (!isInIframe()) {
        yield put(switchLanguage());
      }
    }
  } catch (error) {
    console.error("load menu error", error);
  }
}

export function* loadVersion() {
  yield takeEvery(LOAD_VERSION_NO, loadVersionFromServer);
}

export function* loadMenu() {
  yield takeEvery(LOAD_MENU, loadMenuFromServer);
}

export function* saveMenu() {
  yield takeEvery(SAVE_MENU, saveMenuToStorage);
}

export function* loadLanguage() {
  yield takeEvery(LOAD_LANGUAGE, loadLanguageFromServer);
}

export default function* rootSaga() {
  yield all([fork(loadVersion),fork(loadMenu),fork(saveMenu),fork(loadLanguage)]);
}
