import { ClockCircleOutlined } from "@ant-design/icons";
import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Switch } from "antd";
import { Form, Input, Select } from "components/Form";
import React, {useEffect, useState} from "react";
import IntlMessages from "util/IntlMessages";
import { convertStyleStr } from "../../FormSetup/components/common";
import { MyTimePicker } from "../../FormSetup/components/SfTime";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting, syncPageState, updatePageState } from "./common";

const SpTimeSetting = () => {
  const form = Form.useFormInstance();
  const stringMode = Form.useWatch('stringMode', form);
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="defaultValue" label="default value">
        <Input className="item-property"/>
      </Form.Item>
      <Form.Item name="placeholder" label="placeholder">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="format" label="format">
        <Select className="item-property">
          <Select.Option value="HH:mm:ss">HH:mm:ss</Select.Option>
          <Select.Option value="HH:mm">HH:mm</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="minuteStep" label="min. step">
        <InputNumber className="item-property" min={1} max={60}/>
      </Form.Item>
      <Form.Item name="secondStep" label="sec. step">
        <InputNumber className="item-property" min={1} max={60}/>
      </Form.Item>
      <Form.Item name="stringMode" label="text mode" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="withTimezone" label="w/ timezone" valuePropName="checked">
        <Switch className="item-property" disabled={stringMode} />
      </Form.Item>
      <Form.Item name="popupStyleStr" label="popup style">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="size" label="size">
        <Select className="item-property">
          <Select.Option value="large">large</Select.Option>
          <Select.Option value="middle">middle</Select.Option>
          <Select.Option value="small">small</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="allowClear" label="allow clear" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="bordered" label="bordered" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="inputReadOnly" label="choose only" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="fieldWidth" label="field-width">
        <Input className="item-property" />
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  )
}

export const SpTime = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppTime doRef={ref => connect(drag(ref))} dbtn={dbtn} {...props} selectedStyle={style} />
  )
}

export const SppTime = ({
  doRef, dbtn, selectedStyle,
  pageKey, itemKey, pageState, setPageState, value,  defaultValue, popupStyleStr,
  fieldWidth, placeholder, format, minuteStep, secondStep, stringMode, withTimezone, size, allowClear, bordered, inputReadOnly, disabled,
  disabledTime,
  ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}
  const inputProps = {placeholder, format, minuteStep, secondStep, stringMode, withTimezone, size, allowClear, bordered, inputReadOnly, disabled, disabledTime};
  const [myValue, setMyValue] = useState(defaultValue);
  const pus = convertStyleStr(popupStyleStr);
  useEffect(() => {
    syncPageState({itemKey, myValue, setMyValue, pageState, setPageState})
  },[itemKey, myValue, pageState, setPageState])

  const onMyChange = (myValue) => {
    updatePageState({itemKey, myValue, setMyValue, pageState, setPageState})
  }
  const fieldStyle = {};
  if (fieldWidth) fieldStyle.width = fieldWidth;
  return (
    <Col ref={doRef} {...props}>
      <MyTimePicker style={fieldStyle} value={myValue} onChange={onMyChange} popupStyle={pus} {...inputProps} />
      {dbtn}
    </Col>
  );
}
SppTime.enablePageState = true;

SpTime.craft = {
  displayName: "Time",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpTimeSetting
  }
}

SpTime.validate = (props) => {
  if (props.stringMode && props.withTimezone) {
    props.withTimezone = false;
  }
}

registerComponent({
  key: "SpTime",
  component: SpTime,
  runtimeComponent: SppTime,
  template: <SpTime itemKey={getId('time')} className="sp-time" title={"Time"} span={24} />,
  title: <IntlMessages id="system.page.library.time" text="Time" />,
  icon: <ClockCircleOutlined  className="react-icons icon-bs"/>,
  type: "Component",
  subtype: "input",
  sequence: 4,
});

export default SpTime;