import { updateMessages } from "../lngProvider";
import languageData from "../lngProvider/languageData";
import { CloudRunWithoutCache } from './config';
import dataExplorer from "./data-explorer";
import { delay, getLocalStorageItem, log, setLocalStorageItem } from "../util/algorithm";

const loadSystemLanguage = async () => {
  try {
    setLocalStorageItem("TRANSLATE_DATA_LOADING", "Y");
    const allLanguages = await getSystemLanguage()
    const allTranslate = await getSystemTranslate();
    if (allTranslate) {
      applySystemTranslate(allTranslate, allLanguages);
    }
    setLocalStorageItem("TRANSLATE_DATA_LOADING", "N");
    return allLanguages;
  } catch (error) {
    log("get language data failed", error);
    setLocalStorageItem("TRANSLATE_DATA_LOADING", "N");
    return languageData;
  }
};

const waitTranslateData = async () => {
  const maxCount = 10;
  let i = 0;
  let result = false;
  while(i < maxCount) {
    result = getLocalStorageItem("TRANSLATE_DATA_LOADING") === "N";
    if (result) {
      break;
    }
    i++;
    await delay(500)
  }
  return result;
}

const applySystemTranslate = (allTranslate, allLanguages) => {
  allTranslate.forEach(translate => {
    const messageKey = translate.messageKey;
    allLanguages.forEach(l => {
      const languageId = l.languageId;
      let message = translate[languageId];
      updateMessages(languageId, messageKey, message);
    })
  });
}

const saveSystemTranslate = async (id, messageMap) => {
  try {
    return await dataExplorer.saveByKey("SystemTranslate", id, messageMap, false, true);
  } catch (error) {
    console.error("save translate failed", error);
  }
};

const getSystemLanguage = async () => {
  try {
    const param = {};
    return await CloudRunWithoutCache("getSystemLanguage", param);
  } catch (error) {
    console.error("system import failed", error);
    throw error;
  }
};

const getSystemTranslate = async () => {
  try {
    const param = {};
    return await CloudRunWithoutCache("getSystemTranslate", param);
  } catch (error) {
    console.error("system import failed", error);
    throw error;
  }
};

const initSystemTranslate = async (messageKey, text, message) => {
  try {
    const translate = await dataExplorer.getByKey("SystemTranslate", messageKey);
    if (!translate) {
      await saveSystemTranslate(messageKey, {en: text ? text : message});
    } else {
      const messageMap = translate;
      if (!messageMap.en) {
        messageMap.en = text ? text : message;
        await saveSystemTranslate(messageKey, messageMap);
      }
    }
  } catch (error) {
    log("init system translate failed", error);
  }
};

const googleTranslate = async (q, source, target) => {
  try {
    const param = {q, source, target};
    log("google translate", param);
    const translatedText = await CloudRunWithoutCache("translate", param);
    log("translatedText", translatedText);
    return translatedText;
  } catch (error) {
    log("error to google translte text", error);
    return null;
  }
}

const translateApi = {
  loadSystemLanguage,
  initSystemTranslate,
  saveSystemTranslate,
  googleTranslate,
  waitTranslateData,
}

export default translateApi;