import { useEditor, useNode } from "@craftjs/core";
import { InputNumber, Switch } from "antd";
import classNames from 'classnames';
import { Form, Input, Select } from "components/Form";
import IntlMessages from 'util/IntlMessages';
import { convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfStyleSetting } from "./common";
import { ImCss3 } from "react-icons/im";
import { isEmpty } from "../../../../util/algorithm";

const SfConditionalStyleSetting = () => {
  const { parents } = useEditor(EditorCollector);
  return (
    <>
      <Form.Item name="parentkey" label="parent">
        <Select className="item-property" options={parents} allowClear/>
      </Form.Item>
      <Form.Item name="parentkeyfullpath" label="full path">
        <Select className="item-property" mode="tags" />
      </Form.Item>
      <Form.Item name="empty" label="empty" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="min" label="min">
        <InputNumber min="0" className="item-property" />
      </Form.Item>
      <Form.Item name="max" label="max">
        <InputNumber min="0" className="item-property" />
      </Form.Item>
      <Form.Item name="pattern" label="pattern">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="inversePattern" label="inverse pattern">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="applyTo" label="apply to">
        <Select className="item-property" options={[{"value":"Whole-Panel", "label":"Whole-Panel"}, ...parents]} mode="multiple" />
      </Form.Item>
      <Form.Item name="hidden" label="hidden" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SfStyleSetting/>
    </>
  )
}

export const SfConditionalStyle = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpConditionalStyle doRef={ref => connect(drag(ref))} dbtn={dbtn} {...props} style={style} />
  )
}

export const SfpConditionalStyle = ({ ...props }) => {
  if (props.doRef) {
    return (
      <SfpConditionalStyleInner {...props} />
    )
  } else {
    return null
  }
}

export const SfpConditionalStyleInner = ({
  doRef, style, dbtn, onClick, dashed, plain, index,
  itemKey, className, styleStr, title,
  disabled, ...otherProps }) => {
  const prefixCls = "ant-divider";
  const type = "horizontal";
  const orientation = "center"
  const orientationPrefix = orientation && orientation.length > 0 ? `-${orientation}` : orientation;
  const hasChildren = !!title;
  const classString = classNames(
    prefixCls,
    `${prefixCls}-${type}`,
    {
      [`${prefixCls}-with-text`]: hasChildren,
      [`${prefixCls}-with-text${orientationPrefix}`]: hasChildren,
      [`${prefixCls}-dashed`]: !!dashed,
      [`${prefixCls}-plain`]: !!plain,
    },
    className,
  );

  let sls = convertStyleStr(styleStr);
  if (style) sls = sls ? { ...style } : style;
  if (doRef) {
    const height = "18px";
    const width = type === "vertical" ? "18px" : null;
    sls = { ...sls, height: height, width: width }
    return (
      <div ref={doRef} style={sls} className={classString}
      ><span className="title">Condi-Style: {itemKey} </span>{dbtn}</div>
    );
  } else {
    return null;
  }
}

const prepareCondiStyle = (parentkey, props) => {
  const rule = {
    parentkey: parentkey,
    parentkeyfullpath: props.parentkeyfullpath,
    empty: props.empty,
    min: props.min,
    max: props.max,
    pattern: props.pattern,
    inversePattern: props.inversePattern,
    hidden: props.hidden,
    disabled: props.disabled,
    style: props.style
  };
  return rule;
}

const validateKeys = (parentkeys, parents, id, name, itemKey) => {
  if (!parentkeys || parentkeys.length === 0) {
    return <IntlMessages id={`system.form.validate.${id}-key-missing`} text={`item ({itemKey}) - ${name} is missing.`} values={{itemKey:itemKey}}/>
  }
  for (const element of parentkeys) {
    const key = element;
    if (key === 'Whole-Panel') continue;
    if (!parents[key]) {
      return <IntlMessages id={`system.form.validate.${id}-key-not-found`} text={`item ({itemKey}) - ${name} key not found ({parent}).`} values={{parent:key,itemKey:itemKey}}/>
    }
  }
  return null;
}

SfConditionalStyle.validate = (props, {parents, container, extraParams}) => {
  const itemKey = props.itemKey;
  const parentkey = props.parentkey
  const parentkeyfullpath = props.parentkeyfullpath;
  const parentkeys = props.parentkey ? [props.parentkey] : null;
  const applyTo = props.applyTo;
  let message = null;
  message = validateKeys(parentkeys, parents, 'parent', 'parent', itemKey);
  if (message && isEmpty(parentkeyfullpath)) return message;
  message = validateKeys(applyTo, parents, 'apply-to', 'apply to', itemKey);
  if (message) return message;
  for (const element of applyTo) {
    const key = element;
    if (key === 'Whole-Panel') {
      const applyToNode = container.props;
      if (!applyToNode.condistyles) {
        applyToNode.condistyles = [];
      }
      applyToNode.condistyles.push(prepareCondiStyle(parentkey, props));
    } else {
      const applyToNode = parents[key].props;
      if (!applyToNode.condistyles) {
        applyToNode.condistyles = [];
      }
      applyToNode.condistyles.push(prepareCondiStyle(parentkey, props));
    }
  }
}

SfConditionalStyle.craft = {
  displayName: "Conditional Style",
  related: {
    settings: SfConditionalStyleSetting
  }
}

registerComponent({
  key:"SfConditionalStyle",
  component: SfConditionalStyle,
  runtimeComponent: SfpConditionalStyle,
  template: <SfConditionalStyle itemKey={getId('condistyle')} className="sf-divider" title="Conditional Style" plain dashed/>,
  title: <IntlMessages id="system.form.library.condistyle" text="Conditional Style"/>,
  icon: <ImCss3 className="react-icons icon-im"/>,
  type: "Logic",
  sequence: 1,
});

export default SfConditionalStyle;