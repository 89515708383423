import React from "react";
import {Spin} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);
export const LoadingProps = {size: "large", indicator: antIcon};
export const Loading = <Spin {...LoadingProps} />;
export default Loading;

