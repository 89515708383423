import { FolderOutlined } from "@ant-design/icons";
import { Element, useEditor, useNode } from "@craftjs/core";
import { Switch, Tabs } from "antd";
import { Form, Input } from "components/Form";
import IntlMessages from "util/IntlMessages";
import { Select } from "../../../../components/Form";
import { appendAttributes } from "../../../../util/algorithm";
import { convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, persistentInfo, registerComponent, SfPanelContext } from "./common";
import Container from "./Container";

export const SfTabs = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  const children =
    <Element is={Container} type="SfTabs" id={"children"} canvas>
    </Element>
  return (
    <SfpTabs doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props}>{children}</SfpTabs>
  )
}

export const SfpTabs = ({
  formKey, doRef, style, dbtn, children, itemKey, className, styleStr, table,
  defaultActiveKey, activeKey, childNodes, processChildNode, forceRender,
  ...otherProps }) => {
  const cls = " " + (className ? className : "");
  let sls = convertStyleStr(styleStr);
  if (style) sls = sls ? { ...sls, ...style } : style;
  if (doRef) {
    return (
      <div ref={doRef} className={cls} style={sls}>
        <div className="ant-card-head">
          <div className="ant-card-head-wrapper">
            <div className="ant-card-head-title">Tab Panel</div>
          </div>
        </div>
        <div className="ant-card-body">
          {children}
        </div>
        {dbtn}
      </div>
    );
  } else {
    return (
      <div className={cls} style={sls}>
        <Tabs activeKey={activeKey} defaultActiveKey={defaultActiveKey}>
          {childNodes && childNodes.map((n) => {
            const tsls = convertStyleStr(n.props.styleStr);
            appendAttributes(n.props, 'style', tsls);
            const panelContext = { name: [n.props.itemKey] };
            if (n.props.style?.display === 'none') return null;
            return (
              <Tabs.TabPane
                tab={<IntlMessages id={`form.${formKey}.${n.props.itemKey}.tab`}
                text={n.props.tab}/>} key={n.props.itemKey} className={n.props.className}
                style={n.props.style} disabled={n.props.disabled} forceRender={forceRender} >
                <SfPanelContext.Provider value={panelContext}>
                  {n.props.showTitle && <div className="tab-title">{n.props.title}</div>}
                  <div className="tab-body">
                    {persistentInfo(n.props.table, panelContext.name)}
                    {processChildNode(n)}
                  </div>
                </SfPanelContext.Provider>
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </div>
    );
  }
}
SfpTabs.collector = () => ["SfTabsPane"];
SfpTabs.forwardAttributes = ["activeKey"];

const SfTabsSetting = () => {
  return (
    <>
      <Form.Item name="defaultActiveKey" label="active pane">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="type" label="type">
        <Select className="item-property" allowClear>
          <Select.Option value="line">line</Select.Option>
          <Select.Option value="card">card</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="size" label="size">
        <Select className="item-property" allowClear>
          <Select.Option value="small">small</Select.Option>
          <Select.Option value="middle">middle</Select.Option>
          <Select.Option value="large">large</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="forceRender" label="force render" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
    </>
  );
}

const ACCEPTED_LIST = ["SfTabsPane"];
SfTabs.craft = {
  displayName: "Tabs",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      let rtnVal = false;
      rtnVal = (ACCEPTED_LIST.indexOf(incomingNode.data.name) !== -1);
      return rtnVal;
    }
  },
  related: {
    settings: SfTabsSetting
  }
}

SfTabs.validate = (props, {formData}) => {
  props.formKey = formData.formKey;
}

registerComponent({
  key:"SfTabs",
  component: SfTabs,
  runtimeComponent: SfpTabs,
  template: <SfTabs itemKey={getId('tabs')} className="sf-tabs" title={"Tabs"} />,
  title: <IntlMessages id="system.form.library.tabs" text="Tabs" />,
  icon: <FolderOutlined  className="react-icons icon-antd"/>,
  type: "Container",
});

export default SfTabs;