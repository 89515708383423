import { useEditor, useNode } from "@craftjs/core";
import classNames from 'classnames';
import { Form, Select } from "components/Form";
import { MdSearch } from "react-icons/md";
import IntlMessages from 'util/IntlMessages';
import { convertStyleStr, deleteButton, EditorCollector, NodeCollector, registerComponent } from "./common";

const SfSimpleSearchSetting = () => {
  const { searchable } = useEditor(EditorCollector);
  return (
    <>
      <Form.Item name="parentkeys" label="parents">
        <Select className="item-property" options={searchable} mode="multiple" />
      </Form.Item>
    </>
  )
}

export const SfSimpleSearch = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpSimpleSearch doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const SfpSimpleSearch = ({ ...props }) => {
  if (props.doRef) {
    return (
      <SfpSimpleSearchInner {...props} />
    )
  } else {
    return null
  }
}

export const SfpSimpleSearchInner = ({
  doRef, style, dbtn, onClick, dashed, plain, index,
  itemKey, className, styleStr, title,
  disabled, ...otherProps }) => {
  const prefixCls = "ant-divider";
  const type = "horizontal";
  const orientation = "center"
  const orientationPrefix = orientation && orientation.length > 0 ? `-${orientation}` : orientation;
  const hasChildren = !!title;
  const classString = classNames(
    prefixCls,
    `${prefixCls}-${type}`,
    {
      [`${prefixCls}-with-text`]: hasChildren,
      [`${prefixCls}-with-text${orientationPrefix}`]: hasChildren,
      [`${prefixCls}-dashed`]: !!dashed,
      [`${prefixCls}-plain`]: !!plain,
    },
    className,
  );
  let sls = convertStyleStr(styleStr);
  if (style) sls = sls ? { ...sls, ...style } : style;
  if (doRef) {
    const height = "18px";
    const width = type === "vertical" ? "18px" : null;
    sls = { ...sls, height: height, width: width }
    return (
      <div ref={doRef} style={sls} className={classString}
      ><span className="title">{itemKey} </span>{dbtn}</div>
    );
  } else {
    return null;
  }
}

SfSimpleSearch.validate = (props, {parents, container, extraParams}) => {
  const itemKey = props.itemKey;
  const parentkeys = props.parentkeys;
  if (!parentkeys || parentkeys.length === 0) {
    return <IntlMessages id="system.form.validate.parent-key-missing" text="item ({itemKey}) - parent is missing." values={{itemKey:itemKey}}/>
  }
  for (const element of parentkeys) {
    const key = element;
    if (container.type.resolvedName === "SfMainPanel") {
      extraParams.dataClassConfig.simpleSearchIndex.push(key);
    }
  }
}

SfSimpleSearch.craft = {
  displayName: "Simple Search",
  related: {
    settings: SfSimpleSearchSetting
  }
}

registerComponent({
  key:"SfSimpleSearch",
  component: SfSimpleSearch,
  runtimeComponent: SfpSimpleSearch,
  template: <SfSimpleSearch itemKey={'search-simple'} className="sf-divider" title="Simple Search" plain dashed/>,
  title: <IntlMessages id="system.form.library.search-simple" text="Simple Search"/>,
  icon: <MdSearch  className="react-icons icon-antd"/>,
  type: "Logic",
  sequence: 3,
});

export default SfSimpleSearch;