import React from "react";
import ReactDOM from "react-dom";

import NextApp from './NextApp';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { exportToWindow } from "./util/exportToWindow";

exportToWindow();

if (!window?.AppCtrl?.passiveMode) {
    ReactDOM.render(<NextApp />, document.getElementById('root'));
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorkerRegistration.unregister();

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
}
