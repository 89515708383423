import { useCallback, useEffect, useRef, useState } from "react";
import { loadScript } from "../../util/algorithm";

const initEditor = ({ editorRef, data, onChange, onBlur, onFocus }) => {
  editorRef.current.editing.view.document.on(
    "change:isFocused",
    (evt, data, isFocused) => {
      if (!isFocused && onBlur) onBlur(evt, editorRef.current);
      if (isFocused && onFocus) onFocus(evt, editorRef.current);
    }
  );
  if (data) {
    editorRef.current.setData(data);
  }
  editorRef.current.model.document.on("change:data", (evt) => {
    if (onChange) onChange(evt, editorRef.current);
  });
};

const parseConfig = (config) => {
  const myConfig = config || {};
  myConfig.removePlugins = [
      "CKBox",
      "CKFinder",
      "EasyImage",
      "RealTimeCollaborativeComments",
      "RealTimeCollaborativeTrackChanges",
      "RealTimeCollaborativeRevisionHistory",
      "PresenceList",
      "Comments",
      "TrackChanges",
      "TrackChangesData",
      "RevisionHistory",
      "Pagination",
      "WProofreader",
      "MathType",
      "SlashCommand",
      "Template",
      "DocumentOutline",
      "FormatPainter",
      "TableOfContents",
    ]
  return myConfig;
}

export const CdnCKEditor = ({ id, data, onReady, onChange, onBlur, config }) => {
  const ref = useRef();
  const editorRef = useRef();
  const [failed, setFailed] = useState(false);
  const myDataRef = useRef([]);
  const isUpdating = useRef();
  const tgr = useRef();

  const onMyBlur = (evt, editor) => {
    isUpdating.current = false;
    if (onBlur) onBlur(evt, editor);
  }

  const addMyData = (data) => {
    myDataRef.current.push(data);
    if (myDataRef.current.length > 10) {
      myDataRef.current = myDataRef.current.slice(-10);
    }
  }

  const onMyChange = (event, editor) => {
    isUpdating.current = true;
    addMyData(editor.getData());
    if (onChange) onChange(event, editor);
  }
  const setRef = useCallback(async (element) => {
    if (!ref.current) {
      ref.current = element;
      if (!window.CKEDITOR) {
        let count = 0;
        do {
          await loadScript(
            "https://cdn.ckeditor.com/ckeditor5/39.0.0/super-build/ckeditor.js"
          );
          count++;
        } while (count < 10 && !window.CKEDITOR)
      }
      if (!window.CKEDITOR) {
        setFailed(true);
      } else {
        editorRef.current = await window.CKEDITOR.ClassicEditor.create(
          ref.current,
          parseConfig(config)
        );
        initEditor({editorRef, data, onChange: onMyChange, onBlur: onMyBlur});
        if (onReady) onReady(editorRef.current)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const doUpdateData = async () => {
      if (tgr.current) {
        clearTimeout(tgr.current);
      }
      tgr.current = setTimeout(() => {
        const currentData = editorRef.current.getData();
        if (data === "<p></p>" || (data.startsWith("<reply>") && data.endsWith("</reply>"))) myDataRef.current = []
        if (myDataRef.current.includes(data)) return
        console.log("CKEditor() current", myDataRef.current, "data", data)
        if (currentData !== data) {
          editorRef.current.setData(data);
          addMyData(data);
        } else {
          addMyData(data);
        }
      }, 100);
    }
    doUpdateData();
  }, [data])

  return <div ref={setRef}>{failed ? 'Failed to load CKEditor' : null}</div>;
};

export default CdnCKEditor;
