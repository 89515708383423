import {all} from "redux-saga/effects";
import authSagas from "./Auth";
import systemSagas from "./System"

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    systemSagas()
  ]);
}
