import { FolderAddOutlined } from "@ant-design/icons";
import { Element, useEditor, useNode } from "@craftjs/core";
import { Row, Switch, Tabs } from "antd";
import { Form, Input } from "components/Form";
import IntlMessages from "util/IntlMessages";
import { convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpTabsContext } from "./common";

export const SpTabsPane = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions);
  return (
    <SppTabsPane doRef={ref => connect(drag(ref))} dbtn={dbtn} {...props} style={style} />
  )
}

export const SppTabsPane = ({
  doRef, style, dbtn, children,
  pageKey, itemKey, pageState, setPageState, disabled,
  className, styleStr, title, table, showTitle, tab, ...otherProps }) => {
  const sls = convertStyleStr(styleStr);
  if (doRef) {
    return (
      <div ref={doRef} className="craft-page-tab" style={style}>
        <div className="craft-page-tab-title"><span>Tab : {tab}</span></div>
        <div className={className} style={sls}>
          {showTitle && <div className="tab-title">{title}</div>}
          <div className="craft-tabs-body">
            <Row>
              {children}
            </Row>
          </div>
        </div>
        {dbtn}
      </div>
    );
  } else {
    return <SpTabsContext.Consumer>
      {ctx => {
        ctx && ctx.push(itemKey,
          <Tabs.TabPane tab={tab} key={itemKey} className={className} disabled={disabled} style={sls}>
            {showTitle && <div className="tab-title">{title}</div>}
            <div className="tab-body">
              <Row>
                {children}
              </Row>
            </div>
          </Tabs.TabPane>
        );
        return false;
      }}
    </SpTabsContext.Consumer>
  }
}

const SpTabsPaneSetting = () => {
  return (
    <>
      <Form.Item name="tab" label="tab">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="showTitle" label="show title" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
    </>
  );
}
SppTabsPane.enablePageState = true;

SpTabsPane.craft = {
  displayName: "Tab Pane",
  related: {
    settings: SpTabsPaneSetting
  }
}

registerComponent({
  key:"SpTabsPane",
  component: SpTabsPane,
  runtimeComponent: SppTabsPane,
  template: <Element canvas is={SpTabsPane} itemKey={getId('tabspane')} className="sp-tabspane"
            title={"Tabs Pane"} tab={"Tab"} />,
  title: <IntlMessages id="system.page.library.tabspane" text="Tabs Pane" />,
  icon: <FolderAddOutlined  className="react-icons icon-antd"/>,
  type: "Container",
});

export default SpTabsPane;