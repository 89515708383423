import enLang from "./entries/en-US";
import idLang from "./entries/id-ID";
import phLang from "./entries/tl-PH";
import zhCNLang from "./entries/zh-Hans-CN";
import zhHKLang from "./entries/zh-Hans-HK";

import antdEN from "antd/lib/locale-provider/en_US";
import antdJP from "antd/lib/locale-provider/ja_JP";
import antdKR from "antd/lib/locale-provider/ko_KR";
import antdCN from "antd/lib/locale-provider/zh_CN";
import antdHK from "antd/lib/locale-provider/zh_HK";
import antdID from "antd/lib/locale-provider/id_ID";
import React from "react";
import { AccountStore } from "../constants/Account";
import { dataExplorer, formApi } from "../parse-api";
import { convertPath, getFormIdentity, getLocalStorageItem, getLocalStorageItemObject, log, toAuthUserObj } from "../util/algorithm";

const AntDLocale = new Map([
  ["en", antdEN],
  ["zh_HK", antdHK],
  ["zh_CN", antdCN],
  ["ja", antdJP],
  ["ko", antdKR],
  ["id_ID", antdID],
  ["tl_PH", antdEN]
])

const AppLocale = {
  en: enLang,
  zh_CN: zhCNLang,
  zh_HK: zhHKLang,
  id_ID: idLang,
  tl_PH: phLang
};

export const getAppLocale = (key) => {
  if (AppLocale[key]) {
    return AppLocale[key];
  } else {
    let antd = AntDLocale.get(key);
    if (!antd) antd = antdEN;

    const appLocale = {
      messages: {
      },
      antd: antd,
      locale: key,
    };
    AppLocale[key] = appLocale;
    return appLocale;
  }
}

export const updateMessages = (languageId, messageKey, message) => {
  const appLocale = getAppLocale(languageId);
  appLocale.messages[messageKey] = message;
}

const QUEUE_HISTORY = {};
const QUEUE = [];

export const registerMessage = (messageKey, text, inputType, messageLabel, parentKey, translatePath, order) => {
  if (messageKey && text && AccountStore.TRANSLATE === 'Y' && messageKey.indexOf('undefined') === -1) {
    const baseUrl = getLocalStorageItem('baseUrl', process.env.PUBLIC_URL || "");
    const path = translatePath || window.location.pathname.substring(baseUrl.length);
    const data = {
      messageKey, path: convertPath(path, messageKey),
      type: inputType ? inputType : 'label',
      value: text,
      messageLabel, parentKey, order
    };
    const key = JSON.stringify(data);
    if (!QUEUE_HISTORY[key]) {
      QUEUE.push(data);
      QUEUE_HISTORY[key] = true;
    }
  } else if (messageKey.indexOf('undefined') !== -1) {
    // messageKey contains undefined keywords
  }
}

export const getMessage = (languageId, messageKey, text, inputType, translatePath) => {
  const appLocale = getAppLocale(languageId);
  const message = appLocale.messages[messageKey];
  registerMessage(messageKey, text, inputType, null, null, translatePath);
  return message ? message : text;
}

export const syncMessages = async (filterKeyPrefix, currentPath, translatePath) => {
  return new Promise(async (resolve, reject) => {
    try {
      const authUser = getLocalStorageItem('user_obj');
      const authUserObj = toAuthUserObj(authUser);
      let allTranslate = null;
      const formIdentity = getFormIdentity(translatePath);
      const {formKey, formView, isPreview, id} = formIdentity ? formIdentity : {};
      if (filterKeyPrefix) {
        allTranslate = await formApi.getFormTranslate(filterKeyPrefix, formKey, formView, isPreview, id);
      } else {
        allTranslate = await dataExplorer.searchAll("SystemTranslate", null, "messageKey");
      }
      const translateMap = {};
      allTranslate.forEach(t => {
        translateMap[t.messageKey] = t;
      })
      if (authUserObj && authUserObj.roles && authUserObj.roles.indexOf("Translator")) {
        const queue = [];
        const skipped = [];
        while (QUEUE.length > 0) {
          let data = QUEUE.shift();
          const text = data.value;
          const messageKey = data.messageKey;
          const parentKey = data.parentKey;
          const messageLabel = data.messageLabel;
          const order = data.order;
          if (translateMap[messageKey]) {
            data = {...translateMap[messageKey], ...data};
          }
          const path = data.path;
          if ((filterKeyPrefix && parentKey) || (!filterKeyPrefix && !parentKey)) {
            if (filterKeyPrefix && messageKey.indexOf(filterKeyPrefix) !== 0) {
              skipped.push(data);
            } else if (!path && currentPath) {
              skipped.push(data);
            } else if (currentPath && path && path.indexOf(currentPath) === -1) {
              skipped.push(data);
            } else {
              const translate = translateMap[messageKey];
              if (translate) {
                const oldPath = translate.path;
                const oldValue = translate.value;
                const oldLabel = translate.messageLabel;
                const oldOrder = translate.order;
                let updated = false;
                if (oldPath !== path) {
                  translate.path = path;
                  updated = true;
                }
                if (text && text !== oldValue) {
                  translate.value = text;
                  updated = true;
                }
                if (messageLabel && messageLabel !== oldLabel) {
                  translate.messageLabel = messageLabel;
                  translate.parentKey = parentKey;
                  updated = true;
                }
                if (order && oldOrder !== order) {
                  translate.order = order;
                  updated = true;
                }
                if (updated) {
                  queue.push(translate);
                }
              } else {
                translateMap[messageKey] = data;
                queue.push(data);
              }
            }
          } else {
            skipped.push(data);
          }
        }

        let results = null;
        if (queue.length > 0) {
          if (filterKeyPrefix) {
            results = await formApi.saveFormTranslate(filterKeyPrefix, formKey, formView, isPreview, id, queue)
          } else {
            results = await dataExplorer.saveAll("SystemTranslate", queue, true);
          }
        }
        if (results) {
          results.forEach(r => {
            translateMap[r.messageKey] = r;
          })
        }
        if (filterKeyPrefix) {
          skipped.forEach(t => {
            QUEUE.push(t);
          })
        } else {
          skipped.forEach(r => {
            if (r.parentKey) {
              QUEUE.push(r);
            } else {
              translateMap[r.messageKey] = r;
            }
          })
        }
        resolve(Object.values(translateMap).filter(t => filterKeyPrefix ? t.messageKey.indexOf(filterKeyPrefix) === 0 : true));
      } else {
        resolve(Object.values(translateMap));
      }
    } catch (error) {
      reject(error);
    }
  })
}

export const getUserLocale = () => {
  return getLocalStorageItemObject('user_locale', {
    languageId: 'en',
    locale: 'en',
    name: 'English',
    icon: 'us',
    moment: 'en',
    translate: 'en',
  })
}

export const getLbl = (messageKey, text, values) => {
  const locale = getUserLocale();
  const lbl = getMessage(locale.languageId, messageKey, text);
  const processed = processMessage(messageKey, lbl ? lbl : text, values);
  return processed ? processed : text;
}

export const useLbl = (locale) => (messageKey, text, values) => {
  const lbl = getMessage(locale.languageId, messageKey, text);
  const processed = processMessage(messageKey, lbl ? lbl : text, values);
  return processed ? processed : text;
}

const processMessage = (id, text, values) => {
  if (text) {
    if (values) {
      Object.keys(values).forEach(k => {
        const v = values[k];
        if (v) {
          text = text.replace(new RegExp('\\{'+k+'\\}','g'), `${v}`);
        }
      })
    }
    return text;
  } else {
    return id;
  }
}

export const getMessageMap = (messageKey, text, returnNull) => {
  const messageMap = {};
  let isFound = false;
  Object.keys(AppLocale).forEach(languageId => {
    messageMap[languageId] = AppLocale[languageId].messages[messageKey];
    if (messageMap[languageId]) isFound = true;
  });
  if (!isFound) {
    if (returnNull) {
      return null;
    } else {
      messageMap['en'] = text;
    }
  }
  return messageMap;
}

export const updateMessageMap = (messageKey, messageMap) => {
  Object.keys(AppLocale).forEach(languageId => {
    if (messageMap[languageId]) {
      AppLocale[languageId].messages[messageKey] = messageMap[languageId];
    }
  });
}

export const displayError = (e) => {
  log("displayError", e);
  let rtnVal = null;
  if (React.isValidElement(e)) {
    log("valid react element", e);
    rtnVal = e;
  } else if(typeof e === 'string' && e.startsWith("ApplicationError: {\"module\"")) {
    const apperr = JSON.parse(e.substring(18));
    rtnVal = getLbl('ERR-'+apperr.code, apperr.text, apperr.values);
  } else if(typeof e === 'string' && e.startsWith("{\"module\"")) {
    const apperr = JSON.parse(e);
    rtnVal = getLbl('ERR-'+apperr.code, apperr.text, apperr.values);
  } else if(e?.message?.startsWith("{\"module\"")) {
    const apperr = JSON.parse(e.message);
    rtnVal = getLbl('ERR-'+apperr.code, apperr.text, apperr.values);
  } else if (`${e}`.indexOf('Invalid session token') !== -1 ||
    `${e}`.indexOf('Validation failed. Please login to continue.') !== -1) {
    log(`session token is not valid!`);
    rtnVal = "Your login session is ended! Please reload the page.";
  } else {
    let msg = e;
    if (typeof msg != "string" ) {
      msg = "" + e;
    }
    log("msg", msg);
    msg = msg.replace(/^ParseError: \d+/,'Error: ');
    rtnVal = msg;
  }
  return rtnVal;
}

export default AppLocale;
