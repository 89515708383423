import { Element, useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Switch } from "antd";
import { Form, Input } from "components/Form";
import { BiVideo } from "react-icons/bi";
import ReactPlayer from 'react-player';
import IntlMessages from "util/IntlMessages";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting } from "./common";
import { prepareText } from "util/algorithm";

const SpVideoSetting = () => {
  return (
    <>
      <Form.Item name={["player","url"]} label="url" className="url">
        <Input className="item-property" allowClear/>
      </Form.Item>
      <Form.Item name="substitute" label="substitute" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name={["player","width"]} label="width" className="width">
        <InputNumber className="item-property" />
      </Form.Item>
      <Form.Item name={["player","height"]} label="height" className="height">
        <InputNumber className="item-property" />
      </Form.Item>
      <Form.Item name={["player","playing"]} label="playing" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name={["player","loop"]} label="loop" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name={["player","controls"]} label="controls" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name={["player","light"]} label="light" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name={["player","muted"]} label="muted" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name={["player","pip"]} label="pip" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name={["player","stopOnUnmount"]} label="stopOnUnmount" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  );
}

export const SpVideo = ({...props}) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppVideo doRef={ref => connect(drag(ref))} selectedStyle={style} dbtn={dbtn} {...props}/>
  )
}

export const SppVideo = ({
  doRef, dbtn, selectedStyle, content, pageKey, itemKey,
  player, substitute,
  pageState, setPageState,
  ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}

  const finalPlayer = player ? {...player} : null;
  if (finalPlayer && substitute) {
    finalPlayer.url = prepareText(finalPlayer.url, pageState);
  }

  return (
    <Col ref={doRef} {...props}>
      <ReactPlayer {...finalPlayer}/>
      {dbtn}
    </Col>
  );
}
SppVideo.enablePageState = true;

SpVideo.craft = {
  displayName: "Video",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpVideoSetting
  }
}

registerComponent({
  key:"SpVideo",
  component: SpVideo,
  runtimeComponent: SppVideo,
  template: <Element canvas is={SpVideo} itemKey={getId('video')}
            className="sp-video" span="24"/>,
  title: <IntlMessages id="system.page.library.video" text="Video" />,
  icon: <BiVideo  className="react-icons icon-bi"/>,
  type: "Component",
  sequence: 4,
});

export default SpVideo;