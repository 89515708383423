import { useEditor, useNode } from "@craftjs/core";
import { Col, Input, Switch } from "antd";
import { Form } from "components/Form";
import React, { useEffect, useRef, useState } from "react";
import { BiText } from "react-icons/bi";
import IntlMessages from "util/IntlMessages";
import { isEmpty, log } from "util/algorithm";
import { getMessage } from "../../../../lngProvider";
import { prepareTextV2 } from "../../../../util/algorithm";
import {
  EditorCollector,
  NodeCollector,
  SfLayoutSetting, SfPanelContext,
  colSls, convertRules, convertStyleStr, deleteButton,
  getId,
  registerComponent,
  shouldUpdate
} from "./common";
import { settingsSignal } from "../../../../util/signal";

const SfStaticTextSetting = () => {
  return (
    <>
      <Form.Item name="staticContent" label="content" className="content"
        labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} >
        <Input.TextArea
          className="item-property"
          autoSize allowClear
        />
      </Form.Item>
      <Form.Item name="watch" label="watch">
        <Input className="item-property" allowClear/>
      </Form.Item>
      <Form.Item name="substitute" label="substitute" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="html" label="html" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="translate" label="translate" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}


const StaticText = ({
  style, content, watch, designer, substitute, html, translate, form, formKey, itemKey}) => {
  const [myContent, setMyContent] = useState(content);
  const { locale } = settingsSignal;
  const watchValue = Form.useWatch(watch ? watch.split('.') : "novalue", form);

  useEffect(() => {
    if (!isEmpty(content)) {
      let text = content;
      if (translate && !designer) {
        const messageKey = `form.${formKey}.text.${itemKey}`;
        text = getMessage(locale.languageId, messageKey, text, html ? 'texteditor' : 'label');
      }
      if (substitute && form && !designer) {
        const values = form.getFieldsValue(true);
        text = prepareTextV2(text, values);
      }
      setMyContent(text);
    } else {
      setMyContent("");
    }
  },[content, designer, form, formKey, html, itemKey, locale.languageId, substitute, translate, watchValue])

  if (html) {
    return (
      <div style={style} dangerouslySetInnerHTML={{__html: myContent}}></div>
    );
  } else {
    return (
      <div style={style}>{myContent}</div>
    );
  }
}


export const SfStaticText = ({ ...props }) => {
  const deletedRef = useRef();
  const { actions: {setProp}, connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions, deletedRef)
  const onChange = (value) => {
    try {
      if (!deletedRef.current) {
        setProp(props => {
          props.staticContent = value;
        });
      }
    } catch (error) {
      log("error", error);
    }
  }
  return (
    <SfpStaticText doRef={ref => connect(drag(ref))} onChange={onChange} style={style} dbtn={dbtn} {...props} />
  )
}

export const SfpStaticText = ({
  doRef, form, condistyles, className, style, dbtn, hideInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, formKey,
  inputref, styleStr, staticContent, watch, children, translate, html, substitute, ...otherProps }) => {
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const [fxr, setFxr] = useState({});
  const newRules = convertRules(rules);
  const inputAttributes = {watch, substitute, html, translate, form, formKey, itemKey}

  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name}
              shouldUpdate={fx} hidden={fxr.hidden} rules={fxr.hidden || otherProps.disabled ? null : newRules}
              labelAlign={labelAlign} labelCol={lcs}
              getValueFromEvent={(value)=>value} wrap>
              <StaticText style={sls} content={staticContent} designer={!!doRef} {...inputAttributes}/>
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

SfStaticText.craft = {
  displayName: "Static Text",
  related: {
    settings: SfStaticTextSetting
  }
}

SfStaticText.validate = (props, {parents, container, extraParams, formData}) => {
  props.formKey = formData.formKey;
  props.volitate = true;
}

registerComponent({
  key: "SfStaticText",
  component: SfStaticText,
  runtimeComponent: SfpStaticText,
  template: <SfStaticText itemKey={getId('staticText')} className="sf-staticText wrap"
    title={"Static Text"} span={24} labelColStr="span:6"/>,
  title: <IntlMessages id="system.form.library.staticText" text="Static Text" />,
  icon: <BiText  className="react-icons icon-bi"/>,
  type: "Component",
  sequence: 22,
});

export default SfStaticText;