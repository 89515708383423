import { DownOutlined } from "@ant-design/icons";
import { useEditor, useNode } from "@craftjs/core";
import { Affix, Button, Col, Input, InputNumber, message, Modal, Row, Switch, Tree } from "antd";
import { Form } from "components/Form";
import $ from 'jquery';
import React, { useEffect, useMemo, useRef, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { MdOutlineAccountTree } from "react-icons/md";
import IntlMessages from "util/IntlMessages";
import { ColorPicker } from '../../../../components/Form';
import { IconOnly, IconSelect, IconTitle } from "../../../../components/Form/IconSelect";
import { useLbl } from "../../../../lngProvider";
import { equals, log, uid } from "../../../../util/algorithm";
import {
  colSls, convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate
} from "./common";
import { JsonObjectEditor } from "./SfObject";
import { settingsSignal } from "../../../../util/signal";

const defaultMyValue = {
  nodes: {
    "_root": {
      title: "Root",
      key: "_root",
      rowKey: "_root"
    }
  },
  selectedKeys: [],
  expandedKeys: [],
  checkedKeys: [],
}

const SfTreeSetting = () => {
  return (
    <>
      <Form.Item name="height" label="height">
        <InputNumber className="item-property" />
      </Form.Item>
      <Form.Item name="showLine" label="show line" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="switcherIconKey" label="switcher">
        <IconSelect iconTypes={['ANTD']}/>
      </Form.Item>
      <Form.Item name="leafIconKey" label="leaf icon">
        <IconSelect iconTypes={['ANTD']}/>
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="selectable" label="selectable" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="checkable" label="checkable" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="blockNode" label="block node" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="draggable" label="draggable" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="debug" label="debug" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="noadd" label="no add" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="noedit" label="no edit" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="nocopy" label="no copy" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="nodelete" label="no delete" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfTree = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions);
  return (
    <SfpTree doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const MyTree = ({
    id, value, disabled, showLine, switcherIconKey, leafIconKey,
    debug, noadd, noedit, nocopy, nodelete,
    onChange, children, draggable, fieldNames,...props}) => {
  const [oldValue, setOldValue] = useState(defaultMyValue);
  const [myValue, setMyValue] = useState(defaultMyValue);
  const [myNodes, setMyNodes] = useState(defaultMyValue.nodes);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingNode, setEditingNode] = useState();
  const locale = settingsSignal.locale;
  const lbl = useLbl(locale);
  const mounted = useRef();
  const copyRef = useRef();
  const rootRef = useRef();
  const onKeyDown = (e) => {
    if (e.ctrlKey) {
      if (!copyRef.current) copyRef.current = true;
      $(`.tree-root-${id}`).addClass('tree-node-copy')
    }
  }
  const onKeyUp = (e) => {
    if (copyRef.current) copyRef.current = false;
    $(`.tree-root-${id}`).removeClass('tree-node-copy')
  }
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    window.addEventListener('keyup', onKeyUp)
    mounted.current = true;
    return () => {
      window.removeEventListener('keydown', onKeyDown)
      window.removeEventListener('keyup', onKeyUp)
      mounted.current = false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (value && !equals(value, oldValue)) {
      if (mounted.current) {
        setMyValue(value)
        setMyNodes(value?.nodes || []);
        setCheckedKeys(value?.checkedKeys || []);
        setExpandedKeys(value?.expandedKeys || []);
        setSelectedKeys(value?.selectedKeys || []);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[value])
  const sortByIndex = (o1,o2) => o1.index - o2.index;
  const myTreeData = useMemo(() => {
    const root = [];
    const copyMyNodes = JSON.parse(JSON.stringify(myNodes));
    const nodes = Object.values(copyMyNodes).sort(sortByIndex);
    for (const node of nodes) {
      if (node && !node.isDelete) {
        let noParent = !node.parentKey;
        if (!noParent) {
          const parentNode = copyMyNodes[node.parentKey];
          if (parentNode) {
            if (!parentNode.children) parentNode.children = [];
            parentNode.children.push(node);
          } else {
            noParent = true;
          }
        }

        if (noParent) {
          root.push(node);
        }
      }
    }
    return root;
  }, [myNodes])

  useEffect(() => {
    setMyValue((v) => {
      return {...v, nodes: myNodes, selectedKeys, checkedKeys, expandedKeys}
    })
  },[myNodes, selectedKeys, checkedKeys, expandedKeys])

  const timerRef = useRef();
  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      if (onChange) {
        const json = JSON.parse(JSON.stringify(myValue || {}));
        if (!equals(json, value)) {
          setOldValue(json);
          onChange(json);
        }
      }
    }, 500);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myValue])

  const insertChildNode  = (myNodes, dataKey, targetKey, index) => {
    console.log('insertChildNode()', {myNodes, dataKey, targetKey, index})
    let children = []
    for (const key in myNodes) {
      if (myNodes.hasOwnProperty(key)) {
        const node = myNodes[key];
        if (targetKey !== node.rowKey) {
          if (
            (node.parentKey === dataKey && !node.isDelete) ||
            (!dataKey && !node.parentKey)
          ) {
            children.push(node);
          }
        }
      }
    }
    children.sort(sortByIndex);
    children = [...children.slice(0, index), myNodes[targetKey], ...children.slice(index)];
    children.forEach((c, index) => c.index = index);
  }

  const onDrop = (info) => {
    try {
      console.log('onDrop()', info)
      const copy = !!copyRef.current && !nocopy;
      const dropKey = info.node.key;
      const dragKey = info.dragNode.key;
      let index = info.dropPosition
      const dropToGap = info.dropToGap;
      const node = myNodes[dragKey];
      const dropNode = myNodes[dropKey];
      let parentNode = dropNode;
      if (dropToGap) {
        if (parentNode?.parentKey) {
          parentNode = myNodes[parentNode.parentKey];
          if (node.parentKey === dropNode.parentKey) {
            if (node.index < index) index--;
          }
        } else {
          parentNode = null;
        }
      }
      setMyNodes((prev) => {
        if (node && parentNode) {
          const newNode = {...node};
          newNode.parentKey = parentNode.rowKey;
          const key = copy ? uid() : dragKey;
          newNode.key = key;
          newNode.rowKey = key;
          const newNodes = {...prev, [key]: newNode};
          insertChildNode(newNodes, newNode.parentKey, key, index);
          return newNodes;
        } else {
          return prev;
        }
      })
      if (expandedKeys.indexOf(dropKey) === -1) {
        setExpandedKeys([...expandedKeys, dropKey])
      }
    } catch (err) {
      log(err)
    }
  }

  const onSelect = (selectedKeys) => {
    setSelectedKeys(selectedKeys);
  }

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
  }

  const onCheck = (checkedKeys) => {
    setCheckedKeys(checkedKeys);
  }

  const onValueChange = (text) => {
    if (onChange) {
      onChange(text);
    }
  }

  const titleRender = (data) => {
    const parentKey = data.rowKey;
    const style = {};
    if (data.color) style.color = data.color;
    if (data.backgroundColor) style.backgroundColor = data.backgroundColor;

    return <>
      <span className="title" style={style} onClick={(e) => e.detail >= 2 && onEditNode(parentKey)} data-row-key-value={parentKey}><IconTitle title={data.title} icon={data.iconKey || (!data.children?.length && leafIconKey)}/></span>
      {!disabled && !noadd && <Button className={'add-node-button'}
          shape={"circle"} size={"small"} icon={<BsPlus/>}
          onClick={() => onAddNode(parentKey)}></Button>}
    </>
  }

  const onAddNode = (parentKey) => {
    const parentTitle = myNodes[parentKey]?.title;
    const index = getChildrenCount(parentKey);
    const data = {parentKey, parentTitle, index};
    setMyValue((prev) => ({...prev, editing: {...data}}));
    if (!noadd) {
      setEditingNode(data);
      setModalVisible(true);
    }
  }

  const getChildrenCount = (dataKey) => {
    let count = 0;
    for (const key in myNodes) {
      if (myNodes.hasOwnProperty(key)) {
        const node = myNodes[key];
        if (
          (node.parentKey === dataKey && !node.isDelete) ||
          (!dataKey && !node.parentKey)
        ) {
          count++;
        }
      }
    }
    return count;
  }

  const onEditNode = (dataKey) => {
    const node = myNodes[dataKey]
    const parentKey = node.parentKey;
    const parentTitle = parentKey ?  myNodes[parentKey]?.title : null;
    const childrenCount = getChildrenCount(dataKey);
    const data = {parentKey, parentTitle, childrenCount, ...node};
    setMyValue((prev) => ({...prev, editing: {...data}}));
    if (!noedit) {
      setEditingNode(data);
      setModalVisible(true);
    }
  }

  const onOk = (updatedNode) => {
    if (updatedNode) {
      setMyNodes((prev) => {
        if (!updatedNode.rowKey) {
          updatedNode.rowKey = uid();
          updatedNode.key = updatedNode.rowKey;
        }
        if (updatedNode.parentKey) {
          if (expandedKeys.indexOf(updatedNode.parentKey) === -1) {
            setExpandedKeys([...expandedKeys, updatedNode.parentKey])
          }
        }
        return {...prev, [updatedNode.rowKey]: updatedNode};
      })
      setModalVisible(false);
      setEditingNode(null);
    } else {
      message.error(lbl("system.form.tree.no-node-defined", "No node defined!"));
    }
    setMyValue((prev) => ({...prev, editing: null}));
  }

  const onCancel = () => {
    setModalVisible(false);
    setEditingNode(null);
    setMyValue((prev) => ({...prev, editing: null}));
  }

  const showLeafIcon = false;
  const myShowLine = showLine ? {showLeafIcon} : false;
  if (typeof value?.draggable === 'boolean') draggable = value?.draggable;
  // Changed the default key node, [key] to [rowKey]
  fieldNames = {key: "rowKey"}
  if (typeof value?.fieldNames === 'object') fieldNames = value?.fieldNames;
  return (
    <div ref={rootRef} className={`tree-root tree-root-${id} ${nocopy ? 'nocopy' : ''}`}>
      <Affix offsetTop={78}>
        <div ref={rootRef} className={`tree-copy-flag`}>{lbl('system.form.tree.node.copy', 'Copy')}</div>
      </Affix>
      <Tree
        titleRender={titleRender} showLine={myShowLine}
        treeData={myTreeData}
        switcherIcon={switcherIconKey ? <IconOnly icon={switcherIconKey}/> : <DownOutlined/>}
        onSelect={onSelect} selectedKeys={selectedKeys}
        onExpand={onExpand} expandedKeys={expandedKeys}
        onCheck={onCheck} checkedKeys={checkedKeys}
        onDrop={onDrop} draggable={draggable}
        fieldNames={fieldNames}
        {...props}>
      </Tree>
      {debug && <JsonObjectEditor value={value} onChange={onValueChange} />}
      <TreeNodeModal
        visible={modalVisible}
        onOk={onOk} onCancel={onCancel}
        node={editingNode} nodelete={nodelete} nodrag={!(draggable)}/>
    </div>
  )
}

const TreeNodeModal = ({
  visible,
  onOk,
  onCancel,
  node,
  nodelete,
  nodrag,
}) => {
  const [form] = Form.useForm();
  const mounted = useRef();
  const titleRef = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(node);
    setTimeout(() => {
      titleRef.current?.focus();
    }, 500)
  }, [form, node]);

  const onFinish = async () => {
    const values = form.getFieldsValue(true);
    if (onOk) onOk(values);
  };
  return (
    <Modal
      width={600}
      title={
        node?.rowKey ? (
          <IntlMessages id="system.form.edit" text="Edit" />
        ) : (
          <IntlMessages id="system.form.add" text="Add" />
        )
      }
      className="tree-node-modal"
      visible={visible}
      onOk={onFinish}
      onCancel={onCancel}
    >
      <Form form={form} name={"AddEditRecord"} colon={false}>
        <Row>
          <Form.Item name="rowKey" hidden>
            <Input />
          </Form.Item>
          {/* <Form.Item name="key" hidden>
            <Input />
          </Form.Item> */}
          <Form.Item name="index" hidden>
            <InputNumber />
          </Form.Item>
          <Col span={24}>
            <Form.Item
              name={'parentTitle'}
              label={<IntlMessages id="system.form.tree.node.parent" text="Parent" />}
              labelCol={{ span: 8 }}
            >
              <Input readOnly/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={'title'}
              label={<IntlMessages id="system.form.title" text="Title" />}
              labelCol={{ span: 8 }}
            >
              <Input ref={titleRef} onPressEnter={onFinish}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={'backgroundColor'}
              label={<IntlMessages id="system.form.tree.node.background-color" text="background color" />}
              labelCol={{ span: 8 }}
            >
              <ColorPicker allowClear />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={'color'}
              label={<IntlMessages id="system.form.tree.node.color" text="font color" />}
              labelCol={{ span: 8 }}
            >
              <ColorPicker allowClear />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={'iconKey'}
              label={<IntlMessages id="system.form.tree.node.icon" text="icon" />}
              labelCol={{ span: 8 }}
            >
              <IconSelect />
            </Form.Item>
          </Col>
          <Col md={12} span={24}>
            <Form.Item
              name="checkable"
              label={<IntlMessages id="system.form.tree.node.checkable" text="Checkable" />}
              labelCol={{ span: 16 }}
              valuePropName="checked"
            >
              <Switch className="item-property" />
            </Form.Item>
          </Col>
          <Col md={12} span={24}>
            <Form.Item
              name="checkable"
              label={<IntlMessages id="system.form.tree.node.disableCheckbox" text="Disable Checkbox" />}
              labelCol={{ span: 16 }}
              valuePropName="checked"
            >
              <Switch className="item-property" />
            </Form.Item>
          </Col>
          <Col md={12} span={24}>
            <Form.Item
              name="checkable"
              label={<IntlMessages id="system.form.tree.node.disabled" text="Disable" />}
              labelCol={{ span: 16 }}
              valuePropName="checked"
            >
              <Switch className="item-property" />
            </Form.Item>
          </Col>
          <Col md={12} span={24}>
            <Form.Item
              name="isLeaf"
              label={<IntlMessages id="system.form.tree.node.isLeaf" text="Leaf?" />}
              labelCol={{ span: 16 }}
              valuePropName="checked"
            >
              <Switch className="item-property" />
            </Form.Item>
          </Col>
          <Col md={12} span={24}>
            <Form.Item
              name="selectable"
              label={<IntlMessages id="system.form.tree.node.selectable" text="Selectable?" />}
              labelCol={{ span: 16 }}
              valuePropName="checked"
            >
              <Switch className="item-property" />
            </Form.Item>
          </Col>
          {!nodrag && <Col md={12} span={24}>
            <Form.Item
              name="nodrag"
              label={<IntlMessages id="system.form.tree.node.nodrag" text="No Drag" />}
              labelCol={{ span: 16 }}
              valuePropName="checked"
            >
              <Switch className="item-property" />
            </Form.Item>
          </Col>}
          {node?.rowKey && node?.childrenCount === 0 && node?.parentKey && !nodelete && !node?.nodelete && <Col md={12} span={24}>
            <Form.Item
              name="isDelete"
              label={<IntlMessages id="system.form.delete" text="Delete" />}
              labelCol={{ span: 16 }}
              valuePropName="checked"
            >
              <Switch className="item-property delete" />
            </Form.Item>
          </Col>}
        </Row>
      </Form>
    </Modal>
  );
};

export const SfpTree = ({
  doRef, form, condistyles, className, style, dbtn, hideInTable, tableColWidth, tableColTitle,
  itemKey, volitate,
  styleStr, ...otherProps }) => {
  const sls = convertStyleStr(styleStr);
  const [fxr, setFxr] = useState({});
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name}
               shouldUpdate={fx} hidden={fxr.hidden}
              wrap>
              <MyTree tyle={sls} id={itemKey} {...otherProps} />
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

SfTree.craft = {
  displayName: "Tree",
  related: {
    settings: SfTreeSetting
  }
}

registerComponent({
  key:"SfTree",
  component: SfTree,
  runtimeComponent: SfpTree,
  template: <SfTree itemKey={getId('tree')} className="sf-tree tree wrap"
            title={"Tree"} span={24} />,
  title: <IntlMessages id="system.form.library.tree" text="Tree" />,
  icon: <MdOutlineAccountTree className="react-icons icon-md"/>,
  type: "Component",
  sequence: 24,
});

export default SfTree;