import { APP_HEADLESS, APP_LOGO, BASE_URL, INITIALIZING, LOAD_LANGUAGE, LOAD_LANGUAGE_SUCCESS, LOAD_MENU, LOAD_MENU_SUCCESS, LOAD_VERSION_NO, LOAD_VERSION_NO_FAIL, LOAD_VERSION_NO_SUCCESS, SAVE_MENU, SAVE_MENU_SUCCESS, SERVER_OFFLINE } from "../../constants/ActionTypes";
import { setLocalStorageItem } from "../../util/algorithm";

export const setBaseUrl = (path) => {
  setLocalStorageItem('baseUrl', path);
  return {
    type: BASE_URL,
    payload: path,
  }
}

export const setLogo = (logo) => {
  setLocalStorageItem('logo', logo);
  return {
    type: APP_LOGO,
    payload: logo,
  }
}

export const setServerOffline = (status) => {
  return {
    type: SERVER_OFFLINE,
    payload: status,
  }
};

export const setHeadless = (headless) => {
  return {
    type: APP_HEADLESS,
    payload: headless,
  }
};

export const loadVersion = () => {
  return {
    type: LOAD_VERSION_NO
  }
};

export const loadVersionSuccess = (version) => {
  return {
    type: LOAD_VERSION_NO_SUCCESS,
    payload: version
  }
};

export const loadVersionFail = () => {
  return {
    type: LOAD_VERSION_NO_FAIL,
    payload: INITIALIZING
  }
};

export const loadMenu = () => {
  return {
    type: LOAD_MENU
  }
};

export const loadMenuSuccess = (menu) => {
  return {
    type: LOAD_MENU_SUCCESS,
    payload: menu
  }
};

export const saveMenu = (menu) => {
  return {
    type: SAVE_MENU,
    payload: menu
  }
};

export const saveMenuSuccess = (menu) => {
  return {
    type: SAVE_MENU_SUCCESS,
    payload: menu
  }
};

export function loadLanguage() {
  return (dispatch) => {
    dispatch({
      type: LOAD_LANGUAGE,
    });
  }
}

export function loadLanguageSuccess(languageData) {
  return (dispatch) => {
    dispatch({
      type: LOAD_LANGUAGE_SUCCESS,
      payload: languageData
    });
  }
}






