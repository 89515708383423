import { Select } from "antd";
import * as AntdIcons from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import { iconData, FA_ICONS, MD_ICONS, ANTD_ICONS } from "../DynIcon/iconData";
import { loadDynIcon } from "../DynIcon";

export const prepareTitleElement = (title, icon, moreCss) => {
  let titleElement = <span>{title}</span>;
  moreCss = moreCss ? ' ' + moreCss : '';
  if (icon) {
    const ButtonIcon = getIcon(icon);
    if (ButtonIcon) {
      titleElement = (
        <span className={"menu-react-icons " + icon + moreCss}>
          <ButtonIcon size="18px"/>
          <span className="title">{title}</span>
        </span>
      );
    } else {
      titleElement = (
        <span className={"menu-react-icons"}>
          <i className={"icon icon-" + icon + moreCss} />
          <span className="title">{title}</span>
        </span>
      );
    }
  }
  return titleElement;
};

const hasIconType = (iconTypes, type) => {
  return !iconTypes || iconTypes.indexOf(type) !== -1;
};

const DISABLE_SELLEC_SHOW_ICON = true;

export const IconSelect = ({ iconTypes, ...props }) => {
  return (
    <Select placeholder="Icon" allowClear showSearch {...props}>
      {hasIconType(iconTypes, "GA") && iconData.map((icon) => (
        <Select.Option key={icon} value={icon}><i className={"craft-selectIcon icon icon-" + icon} /><span className="craft-selectIconText">{icon}</span></Select.Option>
      ))}
      {hasIconType(iconTypes, "FA") &&
        FA_ICONS.map((icon) => {
          return (
            <Select.Option key={icon} value={icon}>
              {prepareTitleElement(icon, DISABLE_SELLEC_SHOW_ICON ? null : icon)}
            </Select.Option>
          );
        })}
      {hasIconType(iconTypes, "MD") &&
        MD_ICONS.map((icon) => {
          return (
            <Select.Option key={icon} value={icon}>
              {prepareTitleElement(icon, DISABLE_SELLEC_SHOW_ICON ? null : icon)}
            </Select.Option>
          );
        })}
      {hasIconType(iconTypes, "ANTD") &&
        ANTD_ICONS.map((icon) => {
          return (
            <Select.Option key={icon} value={icon}>
              {prepareTitleElement(icon, DISABLE_SELLEC_SHOW_ICON ? null : icon)}
            </Select.Option>
          );
        })}
    </Select>
  );
};

export const IconTitle = ({ title, icon }) => {
  return prepareTitleElement(title, icon);
};

export const IconOnly = ({ icon, ...props }) => {
  const [myIcon, setMyIcon] = useState(icon);
  const Icon = useMemo(() => {
    return getIcon(myIcon);
  }, [myIcon]);
  useEffect(() => {
    setMyIcon((oldIcon) => {
      if (icon !== oldIcon) {
        return icon;
      } else {
        return oldIcon;
      }
    });
  }, [icon]);
  if (Icon) {
    return <Icon {...props} />;
  }
};

export const getIcon = (icon) => {
  let target = null;
  if (FA_ICONS.includes(icon) || MD_ICONS.includes(icon)) {
    target = loadDynIcon(icon);
  } else if (AntdIcons[icon]) {
    const Icon = AntdIcons[icon];
    target = (props) => {
      return <Icon {...props} />;
    };
  } else {
    return undefined;
  }
  return target;
};
