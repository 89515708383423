import { GroupOutlined } from "@ant-design/icons";
import { Element, useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Popover, Tag } from "antd";
import { Form, Input } from "components/Form";
import { useEffect, useRef, useState } from "react";
import { uid } from "util/algorithm";
import IntlMessages from "util/IntlMessages";
import ButtonArea from "./ButtonArea";
import { colSls, convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, SfPanelListContext, shouldUpdate } from "./common";
import Container from "./Container";
import RepeatedArea from "./RepeatedArea";
import SfButton from "./SfButton";
import SfDivider from "./SfDivider";
import ReactJson from "react-json-view";
import { VscSymbolArray } from "react-icons/vsc";

export const SfGroupList = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  const children =
    <Element is={Container} type="SfGroupList" id={"children"} noStyle canvas>
      <Element is={RepeatedArea} type="SfGroupListRepeat" id={"repeat_area"} styleStr="position:relative,marginBottom:10px" canvas>
        <SfDivider itemKey="divider" className="sf-divider" orientation="left" type="horizontal" plain={true} title={"Record"}/>
        <SfButton itemKey="del" className="sf-button delete" buttonType="text" buttonAction="delete" styleStr="position:absolute,right:-18px,top:59px" />
      </Element>
      <Element is={ButtonArea} type="SfGroupListControl" id={"buttons"} canvas styleStr="minHeight:0px">
        <SfButton itemKey="add" className="sf-button add" buttonType="dashed" block buttonAction="add" title="Add" styleStr="marginBottom:0px" />
      </Element>
    </Element>
  return (
    <SfpGroupList doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props}>{children}</SfpGroupList>
  )
}

export const SfpGroupList = ({
  doRef, form, condistyles, style, dbtn, children, itemKey, className, styleStr,
  min, max,
  ...otherProps }) => {
  const cls = "" + (className ? className : "");
  const [fxr, setFxr] = useState({});
  let sls = convertStyleStr(styleStr);
  if (style) sls = sls ? { ...sls, ...style } : style;
  if (fxr.hidden) {
    if (!sls) sls = {};
    sls.display = "none";
  }
  const fx = shouldUpdate({condistyles, form, ctx: {}, style, setFxr});
  if (doRef) {
    return (
      <SfPanelListContext.Provider value={{}}>
        <Col ref={doRef} className={cls} style={style} {...colSls(otherProps)}>
          {children}
          {dbtn}
        </Col>
      </SfPanelListContext.Provider>
    );
  } else {
    return (
      <>
        <Form.Item name={itemKey+'_OnChange'} hidden shouldUpdate={fx}>
          <Input/>
        </Form.Item>
        <SfPanelContext.Consumer>
        {ctx => {
          const name = ctx ? [...ctx.name, itemKey] : [itemKey];
          const innerProps = {form, name, itemKey, doRef, cls, style, otherProps, children, dbtn, fxr, min, max}
          return <SfpGroupListInner {...innerProps}/>
        }}
        </SfPanelContext.Consumer>
      </>
    );
  }
}

const SfpGroupListInner = ({form, name, itemKey, doRef, cls, style, otherProps, children, dbtn, fxr, min, max}) => {
  const value = Form.useWatch(name, form);
  const [count, setCount] = useState();
  const valueRef = useRef();
  useEffect(() => {
    if (valueRef.current) {
      clearTimeout(valueRef.current);
    }
    valueRef.current = setTimeout(() => {
      const c = value?.length;
      if (typeof c === 'number') {
        setCount(c);
      }
      if (min > 0) {
        let updated = false;
        let newValue = value;
        if (!newValue) {
          newValue = [];
          updated = true;
        }
        while (newValue.length < min) {
          newValue.push({});
          updated = true;
        }

        if (updated && form) {
          form.setFields([{name: name, value: newValue}]);
        }
      }
    }, 200)
  }, [form, min, name, value]);
  return <Form.List name={name}>
    {(fields, { add, remove }) => (
      <SfPanelListContext.Provider value={{ itemKey, fields, min, max, count, addRow: ()=>add({rowKey: uid()}), remove }}>
        {!fxr.hidden && <Col ref={doRef} className={cls} style={style} {...colSls(otherProps)}>
          {children}
          {dbtn}
        </Col>}
      </SfPanelListContext.Provider>
    )}
  </Form.List>
}

const SfGroupListSetting = () => {
  return (
    <>
      <Form.Item name="min" label="min">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="max" label="max">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  );
}

const JsonRenderer = ({
  value,
  record,
  index,
  form,
  tableKey,
  onRowChange,
  editInTable,
  itemKey,
  mask,
  tableColWidth,
  disabled,
}) => {
  return (
    <span className="cell-content clickable">
      {value && (
        <Popover content={<ReactJson src={value} />}>
          <Tag>
            <VscSymbolArray className="react-icons icon-vsc" />
          </Tag>
        </Popover>
      )}
    </span>
  );
};

SfpGroupList.render = ({ editInTable, itemKey, mask, tableColWidth, disabled }, form, tableKey, onRowChange) => (value, record, index) => {
  const props = {value, record, index, form, tableKey, onRowChange, editInTable, itemKey, mask, tableColWidth, disabled};
  return <JsonRenderer {...props}/>
}


SfGroupList.craft = {
  displayName: "Group List",
  related: {
    settings: SfGroupListSetting
  }
}

SfGroupList.validate = (props, {parents, container, extraParams}) => {
  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "json[]",
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      sortable: true,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
    });
    if (props.isSelectKey) {
      extraParams.selectConfig.optionTypes.push(props.itemKey);
    }
  }
}

SfGroupList.isSearchable = true;
SfGroupList.isRulable = true;

registerComponent({
  key:"SfGroupList",
  component: SfGroupList,
  runtimeComponent: SfpGroupList,
  template: <SfGroupList itemKey={getId('groupList')} className="sf-group-list" span={24}/>,
  title: <IntlMessages id="system.form.library.group-list" text="Group List" />,
  icon: <GroupOutlined  className="react-icons icon-antd"/>,
  type: "Component",
  sequence: 24,
});

export default SfGroupList;