export const SampleColumns = [
  {
    title: 'Col 1',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Col 2',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Col 3',
    dataIndex: 'address1',
    key: 'address',
  },
  {
    title: 'Col 4',
    dataIndex: 'address2',
    key: 'address',
  }
];

const numbers = [...Array(100).keys()].slice(1);
const names = ['John Brown', 'Jim Green', 'Joe Black'];

const randomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
const randomName = () => {
  return names[randomNumber(0,2)];
}

export const SampleData = numbers.map(i => {
  return {
    key: `${i}`,
    name: randomName(),
    age: randomNumber(30, 60),
    address1: `New York No. ${i} Lake Park`,
    address2: `New York No. ${i} Lake Park`,
  }
})