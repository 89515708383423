import { Element, useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Switch } from "antd";
import { ColorPicker, Form, Input, Select } from "components/Form";
import { AiOutlineQrcode } from "react-icons/ai";
import IntlMessages from "util/IntlMessages";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting } from "./common";
import { prepareText } from "util/algorithm";
import QRCode from "react-qr-code";

const QR_LEVEL = ['L','M','Q','H'];

const SpQRCodeSetting = () => {
  return (
    <>
      <Form.Item name="value" label="value" className="value">
        <Input className="item-property" allowClear/>
      </Form.Item>
      <Form.Item name="substitute" label="substitute" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name={"bgColor"} label={"bg-color"}>
        <ColorPicker />
      </Form.Item>
      <Form.Item name={"fgColor"} label={"fg-color"}>
        <ColorPicker />
      </Form.Item>
      <Form.Item name="level" label="level">
        <Select className="item-property" placeholder="type" allowClear showSearch>
          {QR_LEVEL.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name="size" label="size" className="size">
        <InputNumber className="item-property" />
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  );
}

export const SpQRCode = ({...props}) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppQRCode doRef={ref => connect(drag(ref))} selectedStyle={style} dbtn={dbtn} {...props}/>
  )
}

export const SppQRCode = ({
  doRef, dbtn, selectedStyle, content, pageKey, itemKey,
  value, substitute, fgColor, bgColor, level, size,
  pageState, setPageState,
  ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}
  const qrProps = {fgColor, bgColor, level, size}

  let finalValue = value;
  if (substitute) {
    finalValue = prepareText(value, pageState);
  }
  if (!finalValue) {
    finalValue = "This is a sample value";
  }
  return (
    <Col ref={doRef} {...props}>
      <QRCode value={finalValue} {...qrProps}/>
      {dbtn}
    </Col>
  );
}
SppQRCode.enablePageState = true;

SpQRCode.craft = {
  displayName: "QR Code",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpQRCodeSetting
  }
}

registerComponent({
  key:"SpQRCode",
  component: SpQRCode,
  runtimeComponent: SppQRCode,
  template: <Element canvas is={SpQRCode} itemKey={getId('qrcode')} preview={true}
            className="sp-qrcode" span="24" size={128} level={'L'}/>,
  title: <IntlMessages id="system.page.library.qrcode" text="QR Code" />,
  icon: <AiOutlineQrcode  className="react-icons icon-bi"/>,
  type: "Component",
  sequence: 3,
});

export default SpQRCode;