import { parseMoment } from '../util/algorithm';
import { CloudRunWithoutCache } from './config';

const getSystemComment = async (parentType, parentKey, parentObjectId, accessKey) => {
  try {
    const param = { parentType, parentKey, parentObjectId, accessKey };
    const json = await CloudRunWithoutCache("getSystemComment", param);
    return parseMoment(json);
  } catch (error) {
    console.error("get system comment failed", error);
    throw error;
  }
}

const addSystemComment = async (parentType, parentKey, parentObjectId, accessKey, comment, emailAlert, smsAlert, link) => {
  try {
    comment = JSON.parse(JSON.stringify(comment));
    const param = { parentType, parentKey, parentObjectId, accessKey, comment, emailAlert, smsAlert, link };
    const json = await CloudRunWithoutCache("addSystemComment", param);
    return parseMoment(json);

  } catch (error) {
    console.error("get system comment failed", error);
    throw error;
  }
}

const updateSystemComment = async (parentType, parentKey, parentObjectId, accessKey, id, updates, emailAlert, smsAlert, link) => {
  try {
    const param = { parentType, parentKey, parentObjectId, accessKey, id, updates, emailAlert, smsAlert, link };
    const json = await CloudRunWithoutCache("updateSystemComment", param);
    return parseMoment(json);
  } catch (error) {
    console.error("get system comment failed", error);
    throw error;
  }
}

const getSystemCommentFlow = async () => {
  try {
    const param = { };
    const json = await CloudRunWithoutCache("getSystemCommentFlow", param);
    return json;
  } catch (error) {
    console.error("get system comment failed", error);
    throw error;
  }
}

const subscribeSystemComment = async (parentType, parentKey, parentObjectId, accessKey, subscribe) => {
  try {
    const param = { parentType, parentKey, parentObjectId, accessKey, subscribe };
    const json = await CloudRunWithoutCache("subscribeSystemComment", param);
    return parseMoment(json);
  } catch (error) {
    console.error("subscribe system comment failed", error);
    throw error;
  }
}

const comment = {
  getSystemComment,
  addSystemComment,
  updateSystemComment,
  subscribeSystemComment,
  getSystemCommentFlow,
}

export default comment;