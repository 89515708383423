import { Layout, Popover, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Auxiliary from "util/Auxiliary";
import CustomScrollbars from "util/CustomScrollbars";
import IntlMessages from "util/IntlMessages";
import { endTranslate, startTranslate, switchLanguage, toggleCollapsedSideNav } from "../../appRedux/actions";
import UserInfo from "../../components/UserInfo";

import { useDispatch } from "react-redux";
import { NAV_STYLE_DEFAULT_HORIZONTAL, NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE, THEME_TYPE_LITE } from "../../constants/ThemeSetting";
import { useLbl } from "../../lngProvider";
import { translateApi } from "../../parse-api";
import { keycloakApi } from "../../parse-api/keycloak-sso";
import { setupMenu } from "../../routes/system/MenuSetup";
import { getNavStyle } from "../../routes/system/MenuSetup/MenuComponent";
import { getClientRoot, getUserLanguages, toAuthUserObj } from "../../util/algorithm";
import TranslateDrawer from "../App/TranslateDrawer";
import { authSignal, commonSignal, settingsSignal, systemSignal, translateSignal } from "../../util/signal";
import { useSignalValue } from "../../util/reactHook";
import { replace } from 'connected-react-router';

const {Header} = Layout;

export const LanguageMenu = ({close,pathname}) => {
  const dispatch = useDispatch();
  const {languageData} = systemSignal;
  const authUser = useSignalValue(authSignal, 'authUser');
  const translate = useSignalValue(translateSignal, 'translate');
  const authUserObj = toAuthUserObj(authUser);
  const isTranslator = authUserObj.roles ? authUserObj.roles.includes("Translator") : false;
  const cls = ["gx-popover-lang-scroll"];
  if (isTranslator) cls.push("translate");

  const onChange = (checked) => {
    if (checked) {
      dispatch(startTranslate());
      if (close) close();
    } else {
      dispatch(endTranslate());
      if (close) close();
    }
  }
  const userLanguages = getUserLanguages(languageData, authUserObj);
  const count = userLanguages.length;
  const height = ((count > 8 ? 8 : count) * 35 + (isTranslator ? 43 : 0)) +"px";
  return (
    <CustomScrollbars className={cls.join(" ")} style={{ minHeight: height }}>
      <ul className="gx-sub-popover" style={{ minHeight: height }}>
        {isTranslator &&
          <li className="gx-media intl-translate-button">
            <Switch checked={translate} onChange={onChange} />
            <span className="text"> <IntlMessages id="translate.translate" text="Translate"></IntlMessages></span>
          </li>
        }
        {userLanguages.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={() => {
            dispatch(switchLanguage(language))
            dispatch(replace(pathname))
            if (close) close();
          }}>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        )}
      </ul>
    </CustomScrollbars>)
};

const Topbar = () => {
  const {locale, themeType} = settingsSignal;
  const { pathname } = commonSignal;
  const width = useSignalValue(commonSignal, 'width');
  const navCollapsed = useSignalValue(commonSignal, 'navCollapsed');
  const authUser = useSignalValue(authSignal, 'authUser');
  const authUserObj = toAuthUserObj(authUser);
  const { baseUrl, menu } = systemSignal;
  const [popover, setPopover] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedKey, setSelectedKey] = useState(pathname);
  const lbl = useLbl(locale);
  const dispatch = useDispatch();
  const client_root = getClientRoot();
  const keycloakParmas = keycloakApi.getKeycloakUrlParams();
  const urlParams = {username: authUserObj.username, client_root, ...keycloakParmas};
  const navStyle = getNavStyle(menu, width)
  const theme = themeType === THEME_TYPE_LITE ? 'lite' : 'dark';

  const onClick = (selected) => {
    const link = selected?.props?.link;
    setSelectedKey(link);
  }

  useEffect(() => {
    translateApi.waitTranslateData().then(result => {
      setLoading(false);
    })
  }, [])

  const logoClass = navStyle !== NAV_STYLE_DEFAULT_HORIZONTAL ?
    "gx-d-block gx-d-lg-none gx-pointer gx-site-logo-w" : "gx-d-block gx-pointer gx-site-logo-w horizontal"

  console.log("Topbar()", {logoClass, navStyle, navCollapsed, width})
  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
        <div className="gx-linebar gx-mr-3">
          <i className="gx-icon-btn icon icon-menu"
             onClick={() => {
               dispatch(toggleCollapsedSideNav(!navCollapsed));
             }}
          />
        </div> : null}
      <Link to="/" className={logoClass}>
        <img alt="" src={(baseUrl+"/assets/images/w-logo.png")}/></Link>
      {!loading && setupMenu({
            menu: menu,
            preview: false,
            //defaultOpenKeys: [defaultOpenKeys],
            selectedKeys: [selectedKey],
            theme: theme,
            roles: authUserObj.roles,
            urlParams: urlParams,
            onClick: onClick,
            dispatch: dispatch,
            lbl, locale, location: 'topbar', width})}
      <ul className="gx-header-notifications">
        <li className="gx-language">
          <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={<LanguageMenu close={()=>setPopover(false)} pathname={pathname}/>}
                   trigger="click" visible={popover} onVisibleChange={(visible)=>setPopover(visible)}>
                <span className="gx-pointer gx-flex-row gx-align-items-center">
                  <i className={`flag flag-24 flag-${locale.icon}`}/>
                  <span className="gx-pl-2 gx-language-name">{locale.name}</span>
                  <i className="icon icon-chevron-down gx-pl-2"/>
                </span>
          </Popover>
        </li>
        {(width >= TAB_SIZE && navStyle !== NAV_STYLE_DEFAULT_HORIZONTAL) ? null :
          <Auxiliary>
            <li className="gx-user-nav"><UserInfo/></li>
          </Auxiliary>
        }
      </ul>
      <TranslateDrawer/>
    </Header>
  );
};

export default Topbar;
