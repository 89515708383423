import { useEditor, useNode } from "@craftjs/core";
import { Col, Switch } from "antd";
import { Form, Input } from "components/Form";
import React, { useEffect, useState } from "react";
import { BsFileEarmarkRichtext } from "react-icons/bs";
import IntlMessages from "util/IntlMessages";
import { MyCKEditor } from "../../FormSetup/components/SfTextEditor";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting, syncPageState, updatePageState } from "./common";

const SpTextEditorSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="staticContent" label="content">
        <Input.TextArea className="item-property" />
      </Form.Item>
      <Form.Item name="readOnly" label="read-only" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="advanced" label="advanced" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="imageBase64" label="image" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="highlight" label="highlight" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="fontstyle" label="font style" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="codestyle" label="code style" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="wraptoolbar" label="wrap tollbar" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  )
}

export const SpTextEditor = ({ ...props }) => {
  const { actions: {setProp}, connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  const onChange = (value) => {
    setProp(props => {
      props.staticContent = value;
    });
  }
  return (
    <SppTextEditor doRef={ref => connect(drag(ref))} onChange={onChange} dbtn={dbtn} {...props} selectedStyle={style} />
  )
}

export const SppTextEditor = ({
  doRef, selectedStyle, dbtn, onChange, staticContent, readOnly,
  pageKey, itemKey, pageState, setPageState, ...otherProps }) => {
  let style = otherProps.style || {};
  style = { ...style, ...selectedStyle };
  const props = { ...otherProps, style }
  const [myValue, setMyValue] = useState(staticContent)

  useEffect(() => {
    syncPageState({itemKey, myValue, setMyValue, pageState, setPageState})
  },[itemKey, myValue, pageState, setPageState])

  const onMyChange = (myValue) => {
    updatePageState({itemKey, myValue, setMyValue, pageState, setPageState})
  }
  return (
    <Col ref={doRef}  {...props}>
      <MyCKEditor readOnly={readOnly} value={myValue} onChange={onMyChange} staticContent={staticContent} designer={!!doRef}/>
      {dbtn}
    </Col>
  );
}
SppTextEditor.enablePageState = true;

SpTextEditor.craft = {
  displayName: "Text Editor",
  related: {
    settings: SpTextEditorSetting
  }
}

registerComponent({
  key: "SpTextEditor",
  component: SpTextEditor,
  runtimeComponent: SppTextEditor,
  template: <SpTextEditor itemKey={getId('texteditor')} className="sp-texteditor"
    title={"Text Editor"} span={24} />,
  title: <IntlMessages id="system.form.library.texteditor" text="Text Editor" />,
  icon: <BsFileEarmarkRichtext  className="react-icons icon-bs"/>,
  type: "Component",
  subtype: "input",
  sequence: 14,
});

export default SpTextEditor;