import { Select } from "antd";
import { useEffect, useState } from "react";

export const ManagedSelect = ({
  value,
  onChange,
  onSetValue,
  targetValue,
  ...props
}) => {
  const [sync, setSync] = useState(false);
  useEffect(() => {
    if (onSetValue) {
      if (targetValue !== value) {
        onSetValue(value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSetValue]);
  useEffect(() => {
    if (sync && targetValue !== value) {
      onMyChange(targetValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetValue]);
  useEffect(() => {
    if (value === targetValue) {
      setSync(true);
    }
  }, [targetValue, value])
  const onMyChange = (value) => {
    if (onSetValue) onSetValue(value);
    if (onChange) onChange(value);
  };
  return <Select {...props} value={value} onChange={onMyChange}></Select>;
};

export default ManagedSelect;