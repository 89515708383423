import { LoadingOutlined } from '@ant-design/icons';
import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Space, Switch, Select } from "antd";
import { Form, Input } from "components/Form";
import { AccountStore } from "constants/Account";
import L from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import { BiCurrentLocation } from "react-icons/bi";
import IntlMessages from "util/IntlMessages";
import {
  colSls, convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate, convertRules
} from "./common";
import { selectApi } from "parse-api";
import { equals, log } from "util/algorithm";
import { settingsSignal } from '../../../../util/signal';

const DEFAULT_LATLNG = "22.285978,114.191490";
const DEfAULT_ZOOMLEVEL = 17;
const PATTERN_TEXT = '^-?\\d+.\\d+,-?\\d+.\\d+(,\\d+)?$';
const PATTERN = new RegExp(PATTERN_TEXT);
const ATTRIBUTION = 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>';
const OSMUrl = 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}'
const MAP_VERSION_ID = 'mapbox/streets-v11';

const SfCoordinateInputSetting = () => {
  const { parents } = useEditor(EditorCollector);
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="parentkeys" label="parents">
        <Select className="item-property" options={parents} mode="multiple" />
      </Form.Item>
      <Form.Item name="isUseCode" label="use code" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="showMap" label="show map" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="mapWidth" label="map width">
        <InputNumber min="250" className="item-property" />
      </Form.Item>
      <Form.Item name="mapHeight" label="map height">
        <InputNumber min="100" className="item-property" />
      </Form.Item>
      <Form.Item name="latlng" label="lat lng">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="zoomlevel" label="zoom">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="allowClear" label="allow clear" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="readOnly" label="read-only" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="isSelectKey" label="select key" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfCoordinateInput = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpCoordinateInput doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const MyCoordinateInput = ({value, latlng, zoomlevel, noupdate, disabled, onChange, password, inputClass, showMap, mapWidth, mapHeight, readOnly, ...props}) => {
  const ref = useRef();
  const [myValue, setMyValue] = useState(latlng);
  const [loading, setLoading] = useState(false);
  const mapRef = useRef();
  const markerRef = useRef();
  const spin = <LoadingOutlined style={{ fontSize: 14 }} spin />;

  const onMapClick = async (e) => {
    log("map clicked", e, mapRef, markerRef);
    if (!disabled && !readOnly) {
      if (mapRef.current && markerRef.current) {
        mapRef.current.setView(e.latlng);
        markerRef.current.setLatLng(e.latlng);
        const zoom = e.target?._zoom ? `,${e.target._zoom}` : '';
        const value = `${e.latlng.lat},${e.latlng.lng}${zoom}`;
        setMyValue(value);
        if (onChange) onChange(value);
      }
    }
  }
  useEffect(() => {
    try {
      if (showMap) {
        let llstr = latlng;
        let zl = zoomlevel;
        if (!llstr) llstr = DEFAULT_LATLNG;
        if (!zl) zl = DEfAULT_ZOOMLEVEL;
        const ll = L.latLng(llstr.split(","));
        let map = null;
        if (mapRef.current) {
          map = mapRef.current;
        } else {
          map = L.map("mapid").setView(ll, zl);
          mapRef.current = map;
        }
        L.tileLayer(OSMUrl, {
          attribution: ATTRIBUTION,
          maxZoom: 18,
          id: MAP_VERSION_ID,
          tileSize: 512,
          zoomOffset: -1,
          accessToken: AccountStore.MAP_API_KEY,
        }).addTo(map);
        if (!markerRef.current) {
          markerRef.current = L.marker(ll).addTo(map);
        }
        map.on("click", onMapClick);
      }
    } catch (error) {
      log("load map error", error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  useEffect(() => {
    setMyValue(latlng);
  }, [latlng]);
  useEffect(() => {
    if (showMap && mapRef.current && markerRef.current && myValue && myValue.match(PATTERN)) {
      const entries = myValue.split(",");
      if (entries.length === 3) {
        const ll = L.latLng(entries.slice(0,2));
        mapRef.current.setView(ll, parseInt(entries[2], 10));
        markerRef.current.setLatLng(ll);
      } else {
        const ll = L.latLng(entries);
        mapRef.current.setView(ll);
        markerRef.current.setLatLng(ll);
      }
    }
  },[myValue, showMap, zoomlevel])
  useEffect(() => {
    setMyValue(value);
  },[value])
  const onClick = () => {
    if (!disabled && !readOnly) {
      new Promise((resolve, reject) => {
        setLoading(true);
        window.navigator.geolocation.getCurrentPosition((position) => {
          log("position", position);
          if (position?.coords?.latitude && position?.coords?.longitude) {
            const value = `${position.coords.latitude},${position.coords.longitude},${zoomlevel}`;
            setMyValue(value)
            if (onChange) onChange(value)
          } else {
            reject(new Error('get geolocation failed'));
          }
          resolve();
        });
      }).then(() => {
        setLoading(false);
      }).catch(e => {
        log("loading position error", e);
        setLoading(false);
      });
    }
  }
  if (ref.current === undefined) ref.current = noupdate && value !== undefined;
  let locateBtn = <span className="sf-coor-btn" onClick={onClick}>{loading ? spin : <BiCurrentLocation />}</span>;
  if (readOnly || disabled) locateBtn = null;
  if (showMap) {
    const w = mapWidth ? mapWidth : 300;
    const h = mapHeight ? mapHeight : 300;
    return (
      <Space direction="vertical">
        <Input value={myValue} readOnly={readOnly} addonAfter={locateBtn} className={inputClass} disabled={disabled || ref.current}
          onBlur={onChange} onChange={(e) => setMyValue(e.target.value)} {...props} />
        <div id="mapid" style={{width:`${w}px`, height:`${h}px`, backgroundColor: 'lightgrey'}}></div>
      </Space>
    );
  } else {
    return <Input value={myValue} readOnly={readOnly} addonAfter={locateBtn} className={inputClass} disabled={disabled || ref.current}
      onBlur={onChange} onChange={(e) => setMyValue(e.target.value)} {...props} />
  }
}

export const SfpCoordinateInput = ({
  doRef, form, condistyles, className, style, dbtn, hideInTable, tableColWidth, tableColTitle,
  itemKey, parentkeys, isUseCode, zoomlevel, title,
  labelAlign, labelColStr, rules, volitate, skipcopy,
  inputref, styleStr, children, isSelectKey, ...otherProps }) => {
  const { locale } = settingsSignal;
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const [fxr, setFxr] = useState({});
  const [parentValues, setParentValues] = useState();
  const latlng = {
    type: 'regexp', pattern: PATTERN_TEXT, message: 'Invalid coordinate!'
  }
  const myRules = rules ? rules : [];
  const newRules = convertRules([...myRules,latlng]);
  const refreshCoordinate = async (form, dependencies, name) => {
    if (form && dependencies && dependencies.length > 0) {
      const objectId = form.getFieldValue(['objectId']);
      const pValues = dependencies.map(d => form.getFieldValue(d));
      if (!parentValues && objectId) {
        setParentValues(pValues);
        return;
      } else if (equals(parentValues, pValues)) {
        return;
      } else {
        setParentValues(pValues);
      }
      let list = [];
      let val = null;
      let idx = pValues.length;
      for (let i = 0; i < pValues.length; i++) {
        if (!pValues[i]) {
          idx = i;
          break;
        }
      }
      if (idx === 0) {
        return;
      } else if (idx === 1) {
        list = [];
        val = pValues[0];
      } else if (idx > 1) {
        list = pValues.slice(0, idx - 1);
        val = pValues[idx - 1];
      }
      const selectkey = "CountryLocation";
      let data = null;
      if (list.length > 0) {
        const parentOptionKey = await selectApi.getParentOptionKey(locale, selectkey, isUseCode, list);
        if (parentOptionKey) {
          data = await selectApi.getSystemSelectOptionData(locale, selectkey, isUseCode, parentOptionKey, val);
        }
      } else {
        data = await selectApi.getSystemSelectOptionData(locale, selectkey, isUseCode, null, val);
      }
      if (!data && pValues.length > 0) {
        list = [];
        val = pValues[0];
        data = await selectApi.getSystemSelectOptionData(locale, selectkey, isUseCode, null, val);
      }
      log("coordinate data", data);
      if (data?.data?.latitude && data?.data?.longitude) {
        let zl = '';
        if (!list || list.length === 0) {
          zl = 5 > zoomlevel ? zoomlevel : ',5';
        } else if (list.length === 1) {
          zl = 10 > zoomlevel ? zoomlevel : ',10';
        }
        const value = `${data.data.latitude},${data.data.longitude}${zl}`;
        form.setFields([{ name: name, value: value }]);
      }
    }
  }
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const dependencies = parentkeys ? parentkeys.map(p => ctx ? [...ctx.name, p] : [p]) : [];
        const doRefresh = () => refreshCoordinate(form, dependencies, name);
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr, doRefresh});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name} label={title}
               shouldUpdate={fx} hidden={fxr.hidden} rules={fxr.hidden ? null : newRules}
              labelAlign={labelAlign} labelCol={lcs} wrap>
              <MyCoordinateInput inputref={inputref} style={sls} zoomlevel={zoomlevel} {...otherProps} />
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

SfCoordinateInput.craft = {
  displayName: "Coordinate Input",
  related: {
    settings: SfCoordinateInputSetting
  }
}

SfCoordinateInput.validate = (props, {parents, container, extraParams}) => {
  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "string",
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      sortable: true,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
    });
    if (props.isSelectKey) {
      extraParams.selectConfig.optionTypes.push(props.itemKey);
    }
  }
}

SfCoordinateInput.isSearchable = true;
SfCoordinateInput.isRulable = true;

registerComponent({
  key:"SfCoordinateInput",
  component: SfCoordinateInput,
  runtimeComponent: SfpCoordinateInput,
  template: <SfCoordinateInput itemKey={getId('coordinate')} className="sf-coordinate wrap"
            title={"Coordinate"} span={24} labelColStr="span:6" latlng={DEFAULT_LATLNG} zoomlevel={DEfAULT_ZOOMLEVEL} mapWidth={250} mapHeight={150}/>,
  title: <IntlMessages id="system.form.library.coordinate" text="Coordinate" />,
  icon: <BiCurrentLocation  className="react-icons icon-bi"/>,
  type: "Component",
  sequence: 18,
});

export default SfCoordinateInput;
