import { message } from "antd";
import CryptoJS from 'crypto-js';
import $ from 'jquery';
import lz from 'lzutf8';
import moment from 'moment';
import { useEffect, useRef } from "react";
import { AccountStore, ActionType } from '../constants/Account';
import { formApi, pageApi, system } from '../parse-api';
import { json } from './net';
export { displayError } from '../lngProvider';
export { getBrowserTimezone } from '../parse-api/config';

let isClientLogging = false;

export function getLocalStoragePrefix() {
  let prefix = window.AppCtrl?.localStoragePrefix;
  if (prefix) {
    prefix = prefix + "_";
  } else {
    prefix = "";
  }
  return prefix;
}


export const getLocalStorageItemObject = (key, defaultValue) => {
  try {
    const value = localStorage.getItem(getLocalStoragePrefix() + key)
    if (value) {
      return JSON.parse(value);
    } else {
      return defaultValue;
    }
  } catch (err) {
    log('failed to parse json data in localStorage', key, err);
    removeLocalStorageItem(key)
    return defaultValue;
  }
}

export const setLocalStorageItemObject = (key, value) => {
  try {
    if (value === null || typeof value === 'undefined') {
      localStorage.removeItem(getLocalStoragePrefix() + key);
    } else {
      localStorage.setItem(getLocalStoragePrefix() + key, JSON.stringify(value))
    }
  } catch (err) {
    log('failed to stringify json data into localStorage', key, err);
  }
}

export const getLocalStorageItem = (key, defaultValue) => {
  try {
    const value = localStorage.getItem(getLocalStoragePrefix() + key)
    if (value) {
      return value;
    } else {
      return defaultValue;
    }
  } catch (err) {
    log('failed to parse json data in localStorage', key, err);
    return defaultValue;
  }
}

export const setLocalStorageItem = (key, value) => {
  try {
    if (value) {
      localStorage.setItem(getLocalStoragePrefix() + key, value)
    } else {
      localStorage.removeItem(getLocalStoragePrefix() + key);
    }
  } catch (err) {
    log('failed to set data into localStorage', key, err);
  }
}

export const removeLocalStorageItem = (key) => {
  try {
    localStorage.removeItem(getLocalStoragePrefix() + key);
  } catch (err) {
    log('failed to remove data from localStorage', key, err);
  }
}

const LSK_BASE_URL = "baseUrl";
export const getBaseUrl = () => {
  return getLocalStorageItem(LSK_BASE_URL, process.env.PUBLIC_URL || "");
}

export const getClientRoot = () => {
  const location = window.location;
  const protocol = location.protocol;
  const host = location.host;
  const baseUrl = getBaseUrl();
  return `${protocol}//${host}${baseUrl}`;
}

const LSK_SELECTED_MENU = "SELECTED_MENU";
export const setSelectedMenu = (selected) => {
  setLocalStorageItemObject(LSK_SELECTED_MENU, selected)
}

export const getSelectedMenu = () => {
  return getLocalStorageItemObject(LSK_SELECTED_MENU)
}

export const refreshClientLogging = () => {
  system.isClientLogging().then(flag => {
    console.log("refreshClientLogging", flag);
    isClientLogging = flag;
  }).catch(error => {
    console.log("refresh client logging error", error);
  })
}

setTimeout(refreshClientLogging, 10000);

export const log = (...args) => {
  const category = window.location.pathname
  if (AccountStore.DEBUG === 'Y') {
    return new Promise((resolve, reject) => {
      try {
        console.log(`${category} - `, ...args);
        if (isClientLogging) {
          system.clientLogging(JSON.stringify({category, args}, skipRepeated()))
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    })
  }
}

/**
* Secure Hash Algorithm (SHA1)
* http://www.webtoolkit.info/
**/
export const SHA1 = (msg) => {
  function rotate_left(n, s) {
    const t4 = (n << s) | (n >>> (32 - s));
    return t4;
  }
  function cvt_hex(val) {
    let str = '';
    let i;
    let v;
    for (i = 7; i >= 0; i--) {
      v = (val >>> (i * 4)) & 0x0f;
      str += v.toString(16);
    }
    return str;
  }
  function Utf8Encode(string) {
    string = string.replace(/\r\n/g, '\n');
    let utftext = '';
    for (let n = 0; n < string.length; n++) {
      const c = string.charCodeAt(n);
      if (c < 128) {
        utftext += String.fromCharCode(c);
      }
      else if ((c > 127) && (c < 2048)) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      }
      else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    }
    return utftext;
  }
  let blockstart;
  let i, j;
  const W = new Array(80);
  let H0 = 0x67452301;
  let H1 = 0xEFCDAB89;
  let H2 = 0x98BADCFE;
  let H3 = 0x10325476;
  let H4 = 0xC3D2E1F0;
  let A, B, C, D, E;
  let temp;
  msg = Utf8Encode(msg);
  const msg_len = msg.length;
  const word_array = [];
  for (i = 0; i < msg_len - 3; i += 4) {
    // eslint-disable-next-line no-mixed-operators
    j = msg.charCodeAt(i) << 24 | msg.charCodeAt(i + 1) << 16 | msg.charCodeAt(i + 2) << 8 | msg.charCodeAt(i + 3);
    word_array.push(j);
  }
  // eslint-disable-next-line default-case
  switch (msg_len % 4) {
    case 0:
      i = 0x080000000;
      break;
    case 1:
      // eslint-disable-next-line no-mixed-operators
      i = msg.charCodeAt(msg_len - 1) << 24 | 0x0800000;
      break;
    case 2:
      // eslint-disable-next-line no-mixed-operators
      i = msg.charCodeAt(msg_len - 2) << 24 | msg.charCodeAt(msg_len - 1) << 16 | 0x08000;
      break;
    case 3:
      // eslint-disable-next-line no-mixed-operators
      i = msg.charCodeAt(msg_len - 3) << 24 | msg.charCodeAt(msg_len - 2) << 16 | msg.charCodeAt(msg_len - 1) << 8 | 0x80;
      break;
  }
  word_array.push(i);
  while ((word_array.length % 16) !== 14) word_array.push(0);
  word_array.push(msg_len >>> 29);
  word_array.push((msg_len << 3) & 0x0ffffffff);
  for (blockstart = 0; blockstart < word_array.length; blockstart += 16) {
    for (i = 0; i < 16; i++) W[i] = word_array[blockstart + i];
    for (i = 16; i <= 79; i++) W[i] = rotate_left(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1);
    A = H0;
    B = H1;
    C = H2;
    D = H3;
    E = H4;
    for (i = 0; i <= 19; i++) {
      temp = (rotate_left(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5A827999) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotate_left(B, 30);
      B = A;
      A = temp;
    }
    for (i = 20; i <= 39; i++) {
      temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ED9EBA1) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotate_left(B, 30);
      B = A;
      A = temp;
    }
    for (i = 40; i <= 59; i++) {
      temp = (rotate_left(A, 5) + ((B & C) | (B & D) | (C & D)) + E + W[i] + 0x8F1BBCDC) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotate_left(B, 30);
      B = A;
      A = temp;
    }
    for (i = 60; i <= 79; i++) {
      temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0xCA62C1D6) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotate_left(B, 30);
      B = A;
      A = temp;
    }
    H0 = (H0 + A) & 0x0ffffffff;
    H1 = (H1 + B) & 0x0ffffffff;
    H2 = (H2 + C) & 0x0ffffffff;
    H3 = (H3 + D) & 0x0ffffffff;
    H4 = (H4 + E) & 0x0ffffffff;
  }
  temp = cvt_hex(H0) + cvt_hex(H1) + cvt_hex(H2) + cvt_hex(H3) + cvt_hex(H4);
  return temp.toLowerCase();
}

export const shortHash = (text) => {
  return SHA1(text).substring(0, 10);
}

export const uid = () => {
  // I generate the UID from two parts here
  // to ensure the random number provide enough bits.
  let firstPart = (Math.random() * 46656) | 0;
  let secondPart = (Math.random() * 46656) | 0;
  firstPart = ("000" + firstPart.toString(36)).slice(-3);
  secondPart = ("000" + secondPart.toString(36)).slice(-3);
  const newUid = firstPart + secondPart;
  return newUid;
}

export const findAndReplaceValueforKeyInJson = (object, value, replacevalue, targetKey) => {
  //key must not be null
  if (targetKey !== null && targetKey !== '') {
    //split the given key using '.' which will help us to traverse through JSON object
    const keys = targetKey.split('.');
    //loop through JSON Object
    for (const x in object) {
      //check for key matches
      if (keys.indexOf(x) !== -1) {
        //check whether matched key-value is an object or not, if object again call the same function to repeat the process
        if (typeof object[x] === typeof {}) {
          findAndReplaceValueforKeyInJson(object[x], value, replacevalue, targetKey);
        }
        // if not object replace with new value & come out of for loop
        if (object[x] === value) {
          object[x] = replacevalue;
          break;
        }
      }
    }
  }
}

export const getText = (object, attr) => {
  const parents = [];
  const arr = getTextArray(object, attr, 0, parents);
  return arr.join(" ");
}

export const getTextArray = (object, attr, level, parents) => {
  let values = [];
  if (object) {
    if (level > 10) return "";
    if (parents.includes(object)) return "";
    parents.push(object)
    if (Array.isArray(object)) {
      object.forEach(o => {
        values = [...values, ...getTextArray(o, attr, level + 1, parents)];
      })
      return values;
    } else {
      if (typeof object === 'string') {
        return [object];
      }
      if (object.children) {
        values = [...values, ...getTextArray(object.children, attr, level + 1, parents)];
      }
      if (object.props) {
        values = [...values, ...getTextArray(object.props, attr, level + 1, parents)];
      }
      if (object.value) {
        values = [...values, ...getTextArray(object.value, attr, level + 1, parents)];
      }
      if (object.label) {
        values = [...values, ...getTextArray(object.label, attr, level + 1, parents)];
      }
      if (attr && object[attr]) {
        values = [...values, ...getTextArray(object[attr], attr, level + 1, parents)];
      }
      return values;
    }
  } else {
    return "";
  }
}

export const isEmpty = (value) => {
if (value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return isEmptyString(value);
    }
  } else if (value === false) {
    return false;
  } else {
    return true;
  }
}

export const isEmptyString = (str) => {
  if (typeof str == 'string') {
    if (!str?.replace(/\s/g, '').length) {
      return true;
    }
  }
  return false;
}

export const prepareText = (text, values) => {
  if (values && text) {
    if (text.indexOf('{') !== -1) {
      Object.keys(values).forEach(k => {
        const v = values[k];
        if (text.indexOf(k) !== -1) {
          if (v && typeof v == 'string') {
            text = text.replace(new RegExp('\\{'+k+'\\}','g'), `${v}`);
          } else if (v && typeof v == 'number') {
            text = text.replace(new RegExp('\\{'+k+'\\}','g'), `${v}`);
          } else {
            text = text.replace(new RegExp('\\{'+k+'\\}','g'), ``);
          }
        }
      })
    } else if (values[text]) {
      text = values[text];
    }
  }
  return text;
}

export const getValueByPath = (obj, key) => {
  const path = key.split(".");
  let src = obj;
  for (let i = 0; i < path.length - 1; i++) {
    const p = path[i];
    src = getNextLevelObject(p, src);
  }
  const last = path[path.length - 1]
  return src[last];
}

export const prepareTextV2 = (text, values) => {
  const PATTERN = /\{[^}]+\}/g
  if (values && text) {
    if (text.indexOf('{') !== -1) {
      text = text.replace(PATTERN, (v) => {
        let t = getValueByPath(values, v.substring(1, v.length - 1))
        const isBool = v.match(/(enable|disable)/i);
        if (isBool && t === undefined) {
          t = false;
        }
        if (t !== undefined && t !== null) {
          return `${t}`;
        } else {
          return "";
        }
      });
    } else if (values[text]) {
      text = values[text];
    }
  }
  return text;
}

export const equals = (obj1, obj2, debug) => {
  const json1 = JSON.stringify(obj1, (key, value) => {
    if (!value) {
      return undefined;
    } else {
      return value;
    }
  });
  const json2 = JSON.stringify(obj2, (key, value) => {
    if (!value) {
      return undefined;
    } else {
      return value;
    }
  });
  if (debug) {
    console.log('json1', json1)
    console.log('json2', json2)
  }
  return json1 === json2;
}

export const compare = (obj1, obj2, attributes, nullFirst) => {
  if (typeof obj1 == 'number' && typeof obj2 == 'number') {
    let rtnVal = obj1 - obj2;
    return rtnVal;
  } else if (obj1 && obj2 && attributes) {
    if (!Array.isArray(attributes)) attributes = [attributes];
    let rtnVal = 0;
    for (const element of attributes) {
      rtnVal = compare(obj1[element], obj2[element], null);
      if (rtnVal !== 0) {
        return rtnVal;
      }
    }
    return rtnVal;
  } else if (obj1 !== undefined && obj2!== undefined && obj1 !== null && obj2!== null) {
    const json1 = JSON.stringify(obj1, Object.keys(obj1).sort());
    const json2 = JSON.stringify(obj2, Object.keys(obj2).sort());
    let rtnVal = 0;
    if (json1 > json2) {
      rtnVal = 1;
    } else if (json1 < json2) {
      rtnVal = -1;
    }
    return rtnVal;
  } else if (obj1 !== undefined && obj1 !== null) {
    return nullFirst ? 1 : -1;
  } else if (obj2 !== undefined && obj2 !== null) {
    return nullFirst ? -1 : 1;
  } else {
    return 0;
  }
}

export const deleteEmptyProperty = (entity, property) => {
  for (const item of property) {
    if (Object.prototype.toString.call(entity[item]) === '[object Object]') {
      if (Object.keys(entity[item]).length === 0) delete entity[item]
    } else if (Object.prototype.toString.call(entity[item]) === '[object Array]') {
      if (entity[item].length === 0) delete entity[item]
    } else {
      if (!entity[item]) delete entity[item]
    }
  }
}

const getNextLevelObject = (p, src) => {
  if (p.indexOf("[") !== -1) {
    // is array
    const idx = p.indexOf("[");
    const a = p.substr(0, idx);
    const k = p.substr(idx+1,p.length-idx-2);
    if (!src[a]) {
      src[a] = [];
    }
    if (Array.isArray(src[a])) {
      const arr = src[a];
      let found = false;
      for (const element of arr) {
        if (element.rowKey === k) {
          src = element;
          found = true;
        }
      }
      if (!found) {
        // assume array of array is not supported
        src = {};
        arr.push(src);
      }
    } else {
      log("key is the target object is not an array", p);
    }
  } else {
    if (!src[p]) {
      src[p] = {};
    }
    src = src[p];
  }
  return src;
}

export const isDate = (value) => {
  const rtnVal = (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(value));
  return rtnVal;
}

export const isTzDate = (value) => {
   const rtnVal = (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/.test(value));
  return rtnVal;
}

export const isMoment = (value) => {
  return moment.isMoment(value);
}

export const isNumeric = (str) => {
  if (typeof str != "string") return false // we only process strings!
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export const parseDate = (value) => {
  try {
    return moment(value).toJSON();
  } catch (e) {
    log("failed to parse value to date string", value);
    return value;
  }
}

export const momentTime = (value, format, returnMoment) => {
  if (format === "HH:mm") {
    value = value.format("HH:mm");
    value = moment(`1970-01-01 ${value}:00`, "YYYY-MM-DD HH:mm:ss");
    value = value.toJSON();
  } else {
    value = value.format("HH:mm:ss");
    value = moment(`1970-01-01 ${value}`, "YYYY-MM-DD HH:mm:ss");
    value = value.toJSON();
  }
  if (returnMoment) value = moment(value);
  return value;
}

export const momentDate = (value, picker, returnMoment, showTime, format) => {
  if (picker === 'year') {
    value = value.format("YYYY");
    value = moment(`${value}-01-01 00:00:00`, "YYYY-MM-DD HH:mm:ss");
  } else if (picker === 'date'){
    value = processReturnMomentData(value,returnMoment,showTime,format)
  } else if (!showTime) {
    value = value.format("YYYY-MM-DD");
    value = moment(`${value} 00:00:00`, "YYYY-MM-DD HH:mm:ss");
  }
  value = value.toJSON();
  if (returnMoment) value = moment(value);
  return value;
}

export const momentTimezoneDate = (value, picker, returnMoment, showTime, format) => {
  if (value) {
    value = value.format();
    if (picker === 'year') {
      value = value.replace(/(\d{4})-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+(\d{2}:\d{2})/, '$1-01-01T00:00:00+$2');
    } else if (picker === 'date'){
      value = value.replace(/(\d{4}-\d{2}-\d{2}T)\d{2}:\d{2}:\d{2}\+(\d{2}:\d{2})/, '$100:00:00+$2');
    } else if (!showTime) {
      value = value.replace(/(\d{4}-\d{2}-\d{2}T)\d{2}:\d{2}:\d{2}\+(\d{2}:\d{2})/, '$100:00:00+$2');
    }
  }
  if (returnMoment) value = moment(value);
  return value;
}

export const processReturnMomentData =(value,returnMoment,showTime,format) => {
  if(showTime && returnMoment ){
    value = value.format("YYYY-MM-DD HH:mm:ss");
    value = moment(value, "YYYY-MM-DD HH:mm:ss");
  }else if(!showTime && returnMoment ){
    value = processFormatMomentData(value,format)
  }else{
    value = value.format("YYYY-MM-DD");
    value = moment(`${value} 00:00:00`, "YYYY-MM-DD HH:mm:ss");
  }
  return value
}

export const processFormatMomentData =(value,format) => {
  if(format === "YYYY-MM-DD HH:mm:ss"){
    value = value.format("YYYY-MM-DD HH:mm:ss");
    value = moment(value, "YYYY-MM-DD HH:mm:ss");
  }else{
    value = value.format("YYYY-MM-DD");
    value = moment(`${value} 00:00:00`, "YYYY-MM-DD HH:mm:ss");
  }
  return value
}

export const dateText = (value, format) => {
  let finalValue = value;
  if (value?.format) {
    finalValue = value.format(format);
  } else if ((isDate(value) || isTzDate(value)) && format) {
    finalValue = moment(value).format(format);
  }
  return finalValue || '\u00a0';
}

export const dateMoment = (value, format, stringMode) => {
  let date = null;
  if (value) {
    if (stringMode) {
      date = moment(value, format)
    } else {
      date = moment(value)
    }
  }
  return date;
}

export const getArraysIntersection = (a1,a2) => {
  if (!a1 || !a2) return [];
  return  a1.filter(function(n) { return a2.indexOf(n) !== -1;});
}

export const getArraysSubtraction = (a1,a2) => {
  if (!a2) return a1;
  return a1.filter(function(n) { return a2.indexOf(n) === -1;});
}

export const getArraysUnion = (a1,a2) => {
  const missing = getArraysSubtraction(a2, a1);
  return [...a1, ...missing];
}

export const getArrayUnique = (arr,key) => {
  if (!arr?.forEach) {
    if (Array.isArray(arr)) {
      return arr;
    } else {
      return [];
    }
  }
  const map = {};
  const uniqueArr = [];
  arr.forEach(a => {
    const k =  a[key];
    if (k) {
      if (!map[k]) {
        map[k] = true;
        uniqueArr.push(a);
      }
    }
  })
  return uniqueArr;
}

export const arrayPushUnique = (a1, i1) => {
  if (a1.indexOf(i1) === -1) {
    a1.push(i1);
  }
}

export const purifyJson = (json, extraAttrs) => {
  const pureObj = Object.entries(json).reduce((result, entry) => {
    const key = entry[0];
    const value = entry[1];
    const type = typeof value;
    if (key.startsWith("_")) {
      // ignore
    } else if (type === 'function') {
      // ignore
    } else if (type === 'undefined') {
      // ignore
    } else if (!value) {
      // ignore
    } else if (extraAttrs && extraAttrs.indexOf(key) !== -1) {
      // skipped
    } else {
      result[key] = value;
    }
    return result;
  }, {});
  return parseMoment(pureObj);
}

export const parseMoment = (json) => {
  try {
    if (json) {
      const parsed = JSON.parse(JSON.stringify(json), (key, value) => {
        if (isDate(value)) {
          return moment(value);
        } else {
          return value;
        }
      })
      return parsed;
    } else {
      return json;
    }
  } catch (error) {
    console.log('parseMoment error', error)
    return json;
  }
}

export const cloneJson = (json) => {
  if (json) {
    return JSON.parse(JSON.stringify(json));
  } else {
    return json;
  }
}

export const copyEach = (src) => {
  const list = [];
  for (const element of src) {
    list.push({...element})
  }
  return list;
}

export const sortObjectByValue = (list, attribute) => {
  list.sort((a, b) => {
    if (a && b) {
      return compare(a[attribute], b[attribute]);
    } else if (a) {
      return 1;
    } else if (b) {
      return -1;
    } else {
      return 0;
    }
  });
}

export const getArrayElementByAttribute = (arr, key, value) => {
  if (Array.isArray(arr)) {
    for (const element of arr) {
      const ele = element;
      if (ele[key] === value) {
        return ele;
      }
    }
    return null;
  } else {
    log("input arr is not an array");
    return null;
  }
}

export function removeItemByIndex(array, index) {
  if (index >= 0 && index < array.length) {
    array.splice(index, 1);
    return array;
  }
}

export const replacePath = (path, params) => {
  Object.keys(params).forEach(k => {
    const v = params[k] ? "/"+params[k] : "";
    const pattern = new RegExp("/:"+k+"\\??","");
    path = path.replace(pattern, v);
  });
  return path;
}

export const decompressJsonData = (data) => {
  if (data) {
    if (typeof data == 'string') {
      if (!data.startsWith("{")) {
        const json = lz.decompress(lz.decodeBase64(data));
        if (typeof json == 'string') {
          if (json.startsWith("{")) {
            return json;
          }
        }
      }
    }
  }
  return data;
}

export const compressJsonData = (json) => {
  if (json) {
    if (typeof json == 'string') {
      if (json.startsWith("{") || json.startsWith("[")) {
        const data = lz.encodeBase64(lz.compress(json));
        if (typeof data == 'string') {
          if (!data.startsWith("{") && !data.startsWith("[")) {
            return data;
          }
        }
      }
    }
    console.log("compressJsonData()", json)
    throw new Error("Only support stringified json object");
  } else {
    return json;
  }
}

export const encodeBase64 = (data) => {
  const buff = Buffer.from(data);
  const base64data = buff.toString('base64');
  return base64data;
}

export const decodeBase64 = (data) => {
  const buff = Buffer.from(data, 'base64');
  const text = buff.toString('ascii');
  return text;
}

export const cleanFilename = (filename) => {
  let name = filename;
  name = name.replace(/[#()]/g,'');
  name = name.replace("/", " - ");
  name = name.replace("|", "_");
  return name;
}

const SKIP_CONTAINERS = ["SfMainPanel", "SfTabs", "EditPad"]
export const getPropsMapping = (data, res = {}, extraKey = '', nodeMap = null, currentNode = null) => {
  if (!nodeMap) {
    let json = data;
    if (data) {
      try {
        json = lz.decompress(lz.decodeBase64(data));
        nodeMap = JSON.parse(json);
      } catch (e) {
        nodeMap = JSON.parse(data);
      }
    }
    currentNode = nodeMap["ROOT"];
  }
  if (currentNode.type.resolvedName === "SfMainPanel") {
     extraKey = '';
  }
  if (currentNode.nodes) {
    for(const element of currentNode.nodes) {
      const id = element;
      const node = nodeMap[id];
      if (node.props.itemKey || SKIP_CONTAINERS.indexOf(currentNode.type.resolvedName) !== -1) {
        const key = node.props.itemKey
        if (node.type.resolvedName !== "SfButton" && node.type.resolvedName !== "EditPad") {
          res[extraKey + key] = {...node.props};
          res[extraKey + key].resolvedName = node.type.resolvedName;
        }
        if (SKIP_CONTAINERS.indexOf(node.type.resolvedName) !== -1) {
          getPropsMapping(data, res, extraKey, nodeMap, node);
        } else {
          getPropsMapping(data, res, `${extraKey}${key}.`, nodeMap, node);
        }
      } else {
        getPropsMapping(data, res, extraKey, nodeMap, node);
      }
    }
  }
  if (currentNode.linkedNodes) {
    const linkedNodes = Object.values(currentNode.linkedNodes);
    for(const element of linkedNodes) {
      const id = element;
      const node = nodeMap[id];
      getPropsMapping(data, res, extraKey, nodeMap, node);
    }
  }
  return res;
}

export const flattenJSON = (obj = {}, res = {}, extraKey = '') => {
  for(const key in obj){
    if (!key.startsWith('_')) {
      if(moment.isMoment(obj[key])) {
        res[extraKey + key] = obj[key].toJSON();
      }else if(typeof obj[key] == 'function') {
        // ignore function
      }else if(typeof obj[key] !== 'object'){
         res[extraKey + key] = obj[key];
      }else{
        const child = obj[key];
        if (Array.isArray(obj)) {
          if (!child.rowKey) {
            child.rowKey = uid();
          }
          if (extraKey.length > 0 && extraKey[extraKey.length - 1] === '.') extraKey = extraKey.substring(0,extraKey.length - 1);
          flattenJSON(obj[key], res, `${extraKey}[${child.rowKey}].`);
        } else {
          flattenJSON(obj[key], res, `${extraKey}${key}.`);
        }
      }
    }
  }
  return res;
};

export const updateFlattenJSON = (obj = {}, updates = {}, extraKey = '', isDefaultValue = null) => {
  const nextupdates = {};
  let hasNextUpdate = false;
  for (const key in updates) {
    if (updates.hasOwnProperty(key)) {
      const val = updates[key];
      const path = key.split(".");
      let src = obj;
      for (let i = 0; i < path.length - 1; i++) {
        const p = path[i];

        src = getNextLevelObject(p, src);
        if (Array.isArray(src)) {
          for (const element of src) {
            const child = element;
            if (!child.rowKey) child.rowKey = uid();
            const newpath = [...path];
            newpath[i] = p + "[" + child.rowKey + "]";
            nextupdates[newpath.join('.')]=val;
            hasNextUpdate = true;
          }
        }
      }
      if (!Array.isArray(src)) {
        const last = path[path.length - 1]
        if (!isDefaultValue || !src[last]) {
          src[last] = val;
        }
      }
    }
  }
  if (hasNextUpdate) {
    updateFlattenJSON(obj, nextupdates, '', isDefaultValue);
  }
};

export const delay = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const delayRun = (fn, timeout, ref) => {
  if (ref.current) {
    clearTimeout(ref.current);
    ref.current = null;
  }
  ref.current = setTimeout(() => {
    fn()
  }, timeout)
}

let ACQUIRED_LOCKS = {};
export const acquireLock = async (key, timeout) => {
  const lock = ACQUIRED_LOCKS[key];
  if (lock) {
    do {
      await delay(timeout);
    } while (ACQUIRED_LOCKS[key]);
    return null;
  } else {
    ACQUIRED_LOCKS[key] = 'releasekey_' + uid();
    return ACQUIRED_LOCKS[key];
  }
}

export const releaseLock = (key, releaseKey) => {
  if (ACQUIRED_LOCKS[key] === releaseKey) {
    ACQUIRED_LOCKS[key] = null;
    return true;
  } else {
    console.error("release lock failed "+key+"->"+ACQUIRED_LOCKS[key]+" by ", releaseKey);
    return false;
  }
}

export const deepClone = (obj) => {
  try {
    const stringified = JSON.stringify(obj);
    const parsed = JSON.parse(stringified);
    return parsed;
  } catch (error) {
    console.log("error", error);
    console.log("obj", obj);
    return obj;
  }
}

let CACHED_DATA = {};
export const getCachedData = async (key, nowait, nocache) => {
  const data = CACHED_DATA[key];
  if ((data || nowait) && !nocache) {
    return deepClone(data);
  } else {
    const releaseKey = await acquireLock(key, 100);
    if (releaseKey) {
      return releaseKey;
    } else {
      return null;
    }
  }
}

export const setCachedData = async (key, releaseKey, data, waitTime) => {
  if (releaseLock(key, releaseKey)) {
    CACHED_DATA[key] = data;
    if (!waitTime) waitTime = 10000;
    delay(waitTime).then(() => {
      // clear cache
      CACHED_DATA[key] = null;
    });
  }
}

export const clearCachedData = () => {
  CACHED_DATA = {};
  ACQUIRED_LOCKS = {};
}

const WHEN_PATTERN = /^([+-])(\d+)([mhdwM])$/
export const generateRelativeTimeOptions = (values, lbl, setInvalidValues) => {
  const unknownPattern = []
  const keys = {};
  const options = values.filter(val => {
      if (keys[val]) {
        return false;
      } else {
        keys[val] = true;
        return true;
      }
    }).map(val => {
    if (val.match(WHEN_PATTERN)) {
      const direction = val.replace(WHEN_PATTERN,"$1");
      const duration = val.replace(WHEN_PATTERN,"$2");
      const unit = val.replace(WHEN_PATTERN,"$3");
      let messageKey = null;
      let messagePattern = null;
      let unitLabel = null;
      if (direction === "+") {
        messageKey = "system.emailpolicy.when.later";
        messagePattern = "{duration} {unit}(s) later";
      } else if (direction === "-") {
        messageKey = "system.emailpolicy.when.before";
        messagePattern = "{duration} {unit}(s) before";
      } else {
        unknownPattern.push(val);
        return {label:val, value:val};
      }
      if (unit === 'M') {
        unitLabel = lbl('system.emailpolicy.when.unit.month', 'month');
      } else if (unit === 'w') {
        unitLabel = lbl('system.emailpolicy.when.unit.week', 'week');
      } else if (unit === 'd') {
        unitLabel = lbl('system.emailpolicy.when.unit.day', 'day');
      } else if (unit === 'h') {
        unitLabel = lbl('system.emailpolicy.when.unit.hour', 'hour');
      } else if (unit === 'm') {
        unitLabel = lbl('system.emailpolicy.when.unit.minute', 'minute');
      }
      const label = lbl(messageKey, messagePattern, {duration:duration,unit:unitLabel});
      return {label: label+" ("+val+")", value: val};
    } else {
      unknownPattern.push(val);
      return {label:val, value:val};
    }
  });
  if (unknownPattern.length > 0) {
    if (setInvalidValues) setInvalidValues(unknownPattern);
  }
  return options;
}

export const resizeImage = (base64Str, maxWidth = 400) => {
  return new Promise((resolve) => {
    const img = new Image()
    img.src = base64Str
    img.onload = () => {
      const canvas = document.createElement('canvas')
      const width = img.width
      const height = img.height
      log("img size", img.width, img.height);
      const maxHeight = height/width*maxWidth
      log("img resize", maxWidth, maxHeight);
      canvas.width = maxWidth;
      canvas.height = maxHeight;
      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0, maxWidth, maxHeight)
      resolve(canvas.toDataURL("image/svg"))
    }
  })
}

const IGNORE_PATH = [
  '/system/menu',
  '/system/flow',
  '/system/form',
  '/system/page',
]
const MESSAGEKEY_PATH = [
  'menusetup',
  'flowsetup',
  'formsetup',
  'pagesetup',
]
export const convertPath = (path, messageKey, isEditing) => {
  let newPath = null;
  for (let i = 0; i < IGNORE_PATH.length; i++) {
    if (path.startsWith(IGNORE_PATH[i])) {
      if (isEditing) {
        newPath = MESSAGEKEY_PATH[i];
      } else {
        newPath = null;
      }
    }
  }
  if (!newPath && (path.indexOf("/wrt/") !== -1 || path.indexOf("/wrts/") !== -1)) {
    const token = path.split("/")
    if (token.length > 5) {
      if (!messageKey || messageKey.indexOf(token[5]) !== -1) {
        newPath = 'flow/' + token[5];
      }
    }
  }
  if (!newPath && (path.indexOf("/rt/") !== -1 || path.indexOf("/rts/") !== -1)) {
    const token = path.split("/")
    if (token.length > 4) {
      if (!messageKey || messageKey.indexOf(token[4]) !== -1) {
        newPath = 'form/' + token[4];
      }
    }
  }
  if (!newPath && (path.indexOf("/prt/") !== -1)) {
    const token = path.split("/")
    if (token.length > 3) {
      if (!messageKey || messageKey.indexOf(token[3]) !== -1) {
        newPath = 'page/' + token[3];
      }
    }
  }
  if (!newPath && messageKey) {
    MESSAGEKEY_PATH.forEach(msgkey => {
      if (messageKey.startsWith(`form.${msgkey}.`)) {
        newPath = msgkey;
      }
    })
  }
  if (messageKey && messageKey.indexOf('widgets') !== -1) {
    newPath = messageKey.replace(/^system\.widgets\.([^)]+)\..*$/g, 'system/widgets/$1');
  }

  if (!messageKey && path.indexOf("widgets") !== -1) {
    newPath = path.replace(/^\//, "").replace(/\/$/, "");
  }
  return newPath;
}

export const getCommonPath = (path1, path2) => {
  if (path1 && path2) {
    const convertedPath1 = convertPath(path1);
    const convertedPath2 = convertPath(path2);
    if (convertedPath1 && convertedPath2) {
      const token1 = convertPath(path1).split("/");
      const token2 = convertPath(path2).split("/");
      const common = [];
      for (let i = 0; i < token1.length; i++) {
        if (token1[i] === token2[i]) {
          common.push(token1[i]);
        } else {
          break;
        }
      }
      return common.join("/");
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}

const getRootNode = (ref) => {
  if (ref?.current?.getRootNode) {
    const root = ref.current.getRootNode();
    if (root instanceof ShadowRoot) {
      return root.childNodes?.[0] || document.head;
    } else {
      return document.head;
    }
  } else {
    return document.head;
  }
}

export const applyCss = (css, name, ref) => {
  const root = getRootNode(ref)
  console.log('getRootNode()', name, root);
  const eleid = name ? `css-dynamic-${name}` : `css-dynamic`;
  let ele = root.querySelector('#' + eleid);
  if (ele) {
    try {
      $(ele).remove();
    } catch (e) {}
  }
  try {
    $('#' + eleid).remove();
  } catch (e) {}
  $(`<style id="${eleid}">`)
  .attr('type', 'text/css')
  .text(css)
  .appendTo(root);
}

export const applyGlobalCss = (css) => {
  try {
    $('#css-dynamic-global').remove();
  } catch (e) {}
  $('<style id="css-dynamic-global">')
  .attr('type', 'text/css')
  .text(css)
  .appendTo('head');
}

export const applyLocalCss = (element, css, name) => {
  const eleid = name ? `css-dynamic-${name}` : `css-dynamic`;
  try {
    $('#' + eleid).remove();
  } catch (e) {}
  $(`<style id="${eleid}">`)
  .attr('type', 'text/css')
  .text(css)
  .appendTo(element);
}

export const setCookie = (key, value, minutes) => {
  log("set cookie", key, minutes);
  if (!minutes) minutes = 5;
  const date = new Date();
  date.setTime(date.getTime()+(minutes*60*1000));
  const expires = date.toGMTString();
  document.cookie = `${key}=${value}; expires=${expires}; path=/; domain=${AccountStore.PARSE_DOMAIN}`;
}

export const removeCookie = (key) => {
  log("remove cookie", key);
  document.cookie = `${key}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${AccountStore.PARSE_DOMAIN}`;
}

const DELAY_NOTIFY_TIMER = {};

export const notify = async (action, data, status, errorMessage) => {
  try {
    const message = {
      action, data, status, errorMessage
    }
    const json = JSON.stringify(message);
    window.parent.postMessage(json, AccountStore.TRUSTED_MESSAGE_ORIGIN);
  } catch (error) {
    log("error in postMessage", error);
  }
}

export const notifyLater = (action, data, status, errorMessage) => {
  if (DELAY_NOTIFY_TIMER[action]) {
    clearTimeout(DELAY_NOTIFY_TIMER[action]);
  }
  DELAY_NOTIFY_TIMER[action] = setTimeout(()=> {
    notify(action, data, status, errorMessage);
  }, 1000);
}

const REGISTERED_ACTION = {};

const getActionList = () => {
  const actions = Object.keys(REGISTERED_ACTION).filter(k => REGISTERED_ACTION[k] !== null);
  return actions.map(a => ({action:a, label: REGISTERED_ACTION[a]?.label}));
}

export const registerPostMessageAction = (action, label, handler, override) => {
  if (REGISTERED_ACTION[action] && !override) {
    // TODO document why this block is empty

  } else {
    REGISTERED_ACTION[action] = {handler, label};
  }
  notifyLater('update-action-list', { actions: getActionList() });
}

export const unregisterPostMessageAction = (action) => {
  REGISTERED_ACTION[action] = null;
  notifyLater('update-action-list', { actions: getActionList() });
}

export const unregisterAllPostMessageAction = (prefix) => {
  if (isEmpty(prefix)) {
    log("unregisterAllPostMessageAction(): missing parameter");
  } else {
    const keys = Object.keys(REGISTERED_ACTION);
    for (const element of keys) {
      if (element.startsWith(prefix)) {
        REGISTERED_ACTION[element] = null;
      }
    }
  }
  notifyLater('update-action-list', { actions: getActionList() });
}

export const runPostMessageAction = async (action, data) => {
  if (REGISTERED_ACTION[action]) {
    const handler = REGISTERED_ACTION[action]?.handler;
    try {
      if (handler) {
        await handler(data);
      } else {
        log("missing handler to perform action", action, data);
      }
    } catch (error) {
      log("failed to perform action", action, data, error);
    }
  }
}

export const encrypt = (content, secretKey) => {
  if (!secretKey) secretKey = AccountStore.PARSE_USER_SECRET_KEY;
  const data = {
    content,
    createdAt: moment().toJSON()
  }
  const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  return ciphertext;
}

export const decrypt = (ciphertext, secretKey) => {
  if (!secretKey) secretKey = AccountStore.PARSE_USER_SECRET_KEY;
  const bytes  = CryptoJS.AES.decrypt(ciphertext, secretKey);
  try {
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    if (decryptedData?.createdAt && decryptedData.content) {
      return decryptedData.content;
    }
  } catch (err) {
    // ignored
  }
  return null;
}

export const isInIframe = () => {
  if(window.self !== window.top) {
    try {
      // !== the operator checks if the operands are
      // have not the same value or not equal type
      return window.top?.location?.origin !== window.self?.location?.origin;
    } catch (error) {
      console.log("failed to detect iframe", error);
      return false;
    }
  } else {
    return false;
  }
}

export const getDefaultAvatar = (user, light) => {
  let avatar = "Unknown";
  if (typeof user === 'string') {
    avatar = user;
  } else {
    avatar = user?.nickname || user?.username || avatar;
  }
  let bgColor = "2E67A0";
  const color = "EEEEEE"
  if (light) {
    bgColor = "6998AB";
  }
  avatar = encodeURIComponent(avatar);
  return `https://ui-avatars.com/api/?size=150&format=svg&background=${bgColor}&color=${color}&name=${avatar}`;
}

export const urlparam = (name, nodecode) => {
  const params = new URLSearchParams(window.location.search);
  if (params.has(name)) {
    const value = params.get(name);
    if (nodecode) {
      return value?.replace(/ /g, '+');
    } else {
      return value;
    }
  } else {
    return undefined;
  }
}

export const hashCode = function(text) {
  if (!text) return 0;
  let hash = 0, i, chr;
  if (text.length === 0) return hash;
  for (i = 0; i < text.length; i++) {
    chr   = text.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const getUserLanguages = (allLanguages, user) => {
  return allLanguages.filter(l => !l.hidden || l.languageId === user?.language);
}

export const skipRepeated = () => {
  const visited = new WeakSet();
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (visited.has(value)) {
        return;
      }
      visited.add(value);
    }
    return value;
  };
}

const PATHPARAM = '/?([^/]+)/?([^/]*)/?([^/]*)/?([^/]*)/?([^/]*)/?([^/]*)$';
const FLOW_SEARCH_URL_PATTERN = '^.*?/system/wrts/(preview|fm|vo)' + PATHPARAM;
const FLOW_URL_PATTERN = '^.*?/system/wrt/(preview|fm|vo)' + PATHPARAM;
const FORM_SEARCH_URL_PATTERN = '^.*?/system/rts/(preview|fm|vo)' + PATHPARAM;
const FORM_URL_PATTERN = '^.*?/system/rt/(preview|fm|vo)' + PATHPARAM;

const getPathValue = (value, pathname, pattern, pos) => {
  if (!value) {
    value = pathname?.replace(RegExp(pattern), pos);
    if (value !== pathname) {
      return value;
    }
    return null;
  } else {
    return value;
  }
}

export const getFormType = (pathname) => {
  pathname = pathname || window.location.pathname;
  let value = null;
  value = getPathValue(value, pathname, FLOW_URL_PATTERN, '$1');
  value = getPathValue(value, pathname, FORM_URL_PATTERN, '$1');
  value = getPathValue(value, pathname, FLOW_SEARCH_URL_PATTERN, '$1');
  value = getPathValue(value, pathname, FORM_SEARCH_URL_PATTERN, '$1');
  return value;
}

export const getFlowKey = (pathname) => {
  pathname = pathname || window.location.pathname;
  let value = null;
  value = getPathValue(value, pathname, FLOW_URL_PATTERN, '$2');
  value = getPathValue(value, pathname, FLOW_SEARCH_URL_PATTERN, '$2');
  return value;
}

export const getFormKey = (pathname) => {
  pathname = pathname || window.location.pathname;
  let value = null;
  value = getPathValue(value, pathname, FLOW_URL_PATTERN, '$3');
  value = getPathValue(value, pathname, FORM_URL_PATTERN, '$2');
  value = getPathValue(value, pathname, FLOW_SEARCH_URL_PATTERN, '$3');
  value = getPathValue(value, pathname, FORM_SEARCH_URL_PATTERN, '$2');
  return value;
}

export const getFormView = (pathname) => {
  pathname = pathname || window.location.pathname;
  let value = null;
  value = getPathValue(value, pathname, FLOW_URL_PATTERN, '$4');
  value = getPathValue(value, pathname, FORM_URL_PATTERN, '$3');
  value = getPathValue(value, pathname, FLOW_SEARCH_URL_PATTERN, '$4');
  value = getPathValue(value, pathname, FORM_SEARCH_URL_PATTERN, '$3');
  return value;
}

export const getFormID = (pathname) => {
  pathname = pathname || window.location.pathname;
  let value = null;
  value = getPathValue(value, pathname, FLOW_URL_PATTERN, '$5');
  value = getPathValue(value, pathname, FORM_URL_PATTERN, '$4');
  return value;
}

export const getFormIdentity = (pathname) => {
  const identity = {
    type: getFormType(pathname),
    flowKey: getFlowKey(pathname),
    formKey: getFormKey(pathname),
    formView: getFormView(pathname),
    id: getFormID(pathname),
    isPreview: getFormType(pathname) === 'preview',
  }
  if (identity.formKey && (identity.formView || identity.flowKey)) {
    return identity;
  } else {
    return null;
  }
}

const PAGE_URL_PATTERN = '^.*?/(system|page)/prt/(preview|fm|pg)' + PATHPARAM;

export const getPageType = (pathname) => {
  pathname = pathname || window.location.pathname;
  let value = null;
  value = getPathValue(value, pathname, PAGE_URL_PATTERN, '$2');
  return value;
}

export const getPageKey = (pathname) => {
  pathname = pathname || window.location.pathname;
  let value = null;
  value = getPathValue(value, pathname, PAGE_URL_PATTERN, '$3');
  return value;
}

export const getPageIdentity = (pathname) => {
  const identity = {
    type: getPageType(pathname),
    pageKey: getPageKey(pathname),
    isPreview: getPageType(pathname) === 'preview',
  }
  if (identity.pageKey) {
    return identity;
  } else {
    return null;
  }
}

const PUBLIC_URL_PATTERN = `^${getBaseUrl()}/page/.*$`;

export const isPublicPage = (pathname) => {
  pathname = pathname || window.location.pathname;
  return !!(pathname?.match(PUBLIC_URL_PATTERN));
}

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const isValidUrl = (urlString) => {
  try {
    return new URL(urlString);
  } catch (e) {
    return false;
  }
};

export const GUEST_USER_OBJ = {
  "uid": "guest",
  "nickname": "Guest",
  "username": "guest",
  "email": null,
  "language": "en",
  "roles": [
      "Guest"
  ]
}

export const toAuthUserObj = (authUser) => {
  const defaultObj = isPublicPage() ? GUEST_USER_OBJ : {};
  const authUserObj = authUser ? tryParseJson(authUser, defaultObj) : defaultObj;
  return authUserObj;
}

const NO_GUEST_URL = [
  '^/system/widgets/.*$',
]

export const isGuestAllowed = async (location) => {
  try {

    const pathname = location.pathname;
    if (pathname) {
      for (const element of NO_GUEST_URL) {
        if (pathname.match(new RegExp(element))) {
          return false;
        }
      }
      const formIdentity = getFormIdentity(pathname);
      if (formIdentity) {
        const f = await formApi.getSystemForm(formIdentity.isPreview, formIdentity.formKey);
        const roles = f.extraParams.allowedRoles[formIdentity.formView];
        if (getArraysIntersection(['Guest'], roles).length === 0) {
          return false;
        }
      }
      const pageIdentity = getPageIdentity(pathname);
      if (pageIdentity) {
        const p = await pageApi.getSystemPage(pageIdentity.isPreview, pageIdentity.pageKey);
        const roles = p.extraParams?.allowedRoles;
        if (getArraysIntersection(['Guest'], roles).length === 0) {
          return false;
        }
      }
    }
    return true;
  } catch (e) {
    return true;
  }
}

export const Compare = (index) => {
  return (a, b) => {
    const attrs = Array.isArray(index) ? index : [index];
    let v1 = a;
    for (const element of attrs) {
      if (v1?.[element]) {
        v1 = v1[element]
      } else {
        v1 = null;
      }
    }
    let v2 = b;
    for (const element of attrs) {
      if (v2?.[element]) {
        v2 = v2[element]
      } else {
        v2 = null;
      }
    }
    let rtnVal = 0;
    if (v1 && !v2) {
      return -1;
    } else if (v2 && !v1) {
      return 1
    } else if (!v1 && !v2) {
      return 0;
    } else if (v1 > v2) {
      rtnVal = 1;
    } else if (v1 < v2) {
      rtnVal = -1;
    }
    return rtnVal;
  }
}

export class MyCustomEvent {
  constructor() {
    this.listenerId = 1;
    this.listeners = [];
  }
  register(callback) {
    if (callback) {
      const id = this.listenerId++
      this.listeners.push({ id, callback })
      return id
    } else {
      console.log('missing callback when register client config listener')
    }
  }
  unregister(id) {
    let target = null
    this.listeners.forEach((l, index) => {
      if (l.id === id) {
        target = index
      }
    })
    if (target) {
      this.listeners.splice(target, 1)
    }
  }
  fire() {
    setTimeout(() => {
      this.listeners.forEach(async (l) => {
        if (l.id) {
          try {
            await l.callback()
          } catch (e) {}
        }
      })
    }, 1000)
  }
}

export const getSortPrefix = (descending) => {
  return descending ? "V" : "";
}

export const stringToColour = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).slice(-2);
  }
  return colour;
}

export const deduceUndefined = (value) => {
  if (isEmptyString(value)) {
    return undefined;
  } else if (value !== null && value !== undefined) {
    return value;
  } else {
    return undefined;
  }
}

export const showLoading = () => {
  if (!$('#fullscreen-loading').length) {
    $('<div id="fullscreen-loading" class="fullscreen-loading-div"><div class="fullscreen-loading"/></div>')
    .appendTo('body');
  }
}

export const hideLoading = () => {
  if ($('#fullscreen-loading').length) {
    try {
      $('#fullscreen-loading').remove();
    } catch (e) {
      console.log('hide loading error', e)
    }
  }
}

export const tryParseJson = (json, defaultValue) => {
  try {
    if (typeof json === 'string') {
      return JSON.parse(json);
    } else {
      return json;
    }
  } catch (e) {
    console.log('failed to parse json string', e);
    console.log('json string', json);
    return defaultValue;
  }
}

export const tryAssignJson = (defaultJson, json) => {
  const defaultObj = tryParseJson(defaultJson);
  const obj = tryParseJson(json);
  if (defaultObj && obj) {
    const defaultKeys = Object.keys(defaultObj);
    for (const k of defaultKeys) {
      defaultObj[k] = obj[k] !== undefined ? obj[k] : defaultObj[k];
    }
    return defaultObj;
  } else if (obj) {
    return obj;
  } else  if (defaultObj) {
    return defaultObj;
  }
}

export const getUserIP = () => { //  onNewIp - your listener function for new IPs
  const promise1 = new Promise((resolve, reject) => {
    json(`https://api.ipregistry.co/?key=tryout`).then(data => {
      console.log('getUserIP', data)
      resolve(`${data.ip}`);
    }).catch(e => {
      resolve(null);
    });
  })
  const promise2 = new Promise((resolve, reject) => {
    setTimeout(resolve, 1000, null);
  });
  return Promise.race([promise1, promise2])
}

const isRunningStandalone = () => {
    return (window.matchMedia('(display-mode: standalone)').matches);
}

const getIndexByRole = (authUserObj, urlObj) => {
  let userIndex = null;
  if (authUserObj && urlObj) {
    if (authUserObj.roles && authUserObj.roles.length > 0) {
      for (const key in urlObj) {
        if (authUserObj.roles.indexOf(key) !== -1) {
          userIndex = urlObj[key];
          break;
        }
      }
    }
  }
  return userIndex;
}

const IGNORED_INDEXES = [
  '/', '/home'
]
const getIndexValidate = (index) => {
  if (index) {
    index = index.trim();
    if (isEmptyString(index)) {
      index = null;
    }
    if (index && !index.startsWith('/')) {
      index = null;
    }
    if (IGNORED_INDEXES.includes(index)) {
      index = null;
    }
  }
  return index;
}

const getIndexByJson = (homePageUrl, authUserObj, isHomePage) => {
  let index = null;
  let urlObj = null;
  try {
    urlObj = JSON.parse(homePageUrl);
    if (isRunningStandalone() && typeof urlObj?.standalone === 'object') {
      urlObj = urlObj.standalone
    }
    index = getIndexValidate(urlObj.index);
  } catch (e) {
    // ignored
  }
  const userIndex = getIndexValidate(getIndexByRole(authUserObj, urlObj));
  if (isHomePage) {
    return userIndex;
  } else {
    return userIndex || index;
  }
}

const getIndexByText = (homePageUrl) => {
  let index = homePageUrl;
  index = getIndexValidate(index);
  return index;
}

export const getIndex = (homePageUrl, authUserObj, isHomePage) => {
  if (homePageUrl) {
    let index = null;
    console.log('getIndex()', homePageUrl)
    if (homePageUrl.startsWith('{')) {
      index = getIndexByJson(homePageUrl, authUserObj, isHomePage);
    } else if (homePageUrl.startsWith('/')) {
      index = getIndexByText(homePageUrl);
    }
    return index;
  } else {
    return null;
  }
}

export const isLoggedIn = ({authUser, authUserObj}) => {
  if (!authUser) {
    authUser = getLocalStorageItem('user_obj');
  }
  if (!authUser) return false;
  if (!authUserObj) {
    authUserObj = toAuthUserObj(authUser)
  }
  return !(!authUserObj?.username || authUserObj?.username === 'guest');
}

export const getCraftSelectedNodeId = ({state, query}) => {
  return state.events.selected
}

export const getVersionDesc = (v) => {
  let desc = `${v.versionStamp}`;
  if (v.versionRemarks) {
    desc += ` - ${v.versionRemarks}`
  }
  if (v.isPublished) {
    desc += ` [Published]`
  }
  return desc;
}

export const isParamUpdated = (params, paramsRef) => {
  if (!equals(params, paramsRef.current)) {
    paramsRef.current = cloneJson(params);
    return true;
  } else {
    return false;
  }
}

export const isStateUpdated = (state, attr) => {
  const params = state[attr];
  const paramsRef = state[attr + 'Ref'];
  if (!equals(params, paramsRef)) {
    state[attr + 'Ref'] = cloneJson(params);
    return true;
  } else {
    return false;
  }
}

export const isStateParamUpdated = (state, attr) => {
  const params = state.cProps?.[attr];
  const paramsRef = state[attr + 'Ref'];
  if (!equals(params, paramsRef)) {
    state[attr + 'Ref'] = cloneJson(params);
    return true;
  } else {
    return false;
  }
}

export const createShadow = (e, targetDOM) => {
  const selectorDOM = e.currentTarget;
  const { width, height } = targetDOM.getBoundingClientRect();

  if (!selectorDOM) {
    return;
  }

  const shadow = targetDOM.cloneNode(true);
  shadow.style.position = `fixed`;
  shadow.style.left = `-100%`;
  shadow.style.top = `-100%`;
  shadow.style.width = `${width}px`;
  shadow.style.height = `${height}px`;
  shadow.style.pointerEvents = 'none';
  document.body.appendChild(shadow);

  e.dataTransfer.setDragImage(shadow, 0, 0);

  return shadow;
};

const isLoopBackCouanter = {}
const isLoopBackCouanterRef = {}
export const isLoopBack = (fname) => {
  if (!isLoopBackCouanter[fname]) {
    isLoopBackCouanter[fname] = 1;
  } else {
    isLoopBackCouanter[fname] = isLoopBackCouanter[fname] + 1;
  }
  if (isLoopBackCouanterRef[fname]) {
    clearTimeout(isLoopBackCouanterRef[fname])
    isLoopBackCouanterRef[fname] = null;
  }
  isLoopBackCouanterRef[fname] = setTimeout(() => {
    isLoopBackCouanter[fname] = null;
    isLoopBackCouanterRef[fname] = null;
  }, 1000)
  if (isLoopBackCouanter[fname] > 50) {
    console.log('isLoopBack => true')
    return true;
  } else {
    return false;
  }
}

const messageCouanter = {}
const messageCouanterRef = {}

const isRepeatedMessage = (message) => {
  const key = JSON.stringify(message);
  let isRepeated;
  if (messageCouanter[key]) {
    isRepeated = true;
  } else {
    messageCouanter[key] = true;
    isRepeated = false;
  }
  if (messageCouanterRef[key]) {
    clearTimeout(messageCouanterRef[key])
    messageCouanterRef[key] = null;
  }
  messageCouanterRef[key] = setTimeout(() => {
    messageCouanter[key] = null;
    messageCouanterRef[key] = null;
  }, 2000)
  return isRepeated;
}

export const msgHelper = {
  error: (config) => {
    if (isRepeatedMessage(config)) return;
    message.error(config)
  },
  info: (config) => {
    if (isRepeatedMessage(config)) return;
    message.info(config)
  }
}

const LoggerLevelValue = {
  OFF: 0,
  ERROR: 1,
  WARN: 2,
  INFO: 3,
  DEBUG: 4,
  TRACE: 5,
}

const convertArg = (arg) => {
  if (arg instanceof Error) {
    if (arg?.stack) {
      console.log('error stack', arg.stack.split('\n'))
    }
    return arg?.message;
  } else {
    return arg;
  }
}

export const Logger = class {
  constructor(category, username, reqUid) {
    this.category = category;
    this.more = '';
    this.more = username ? `${this.more} [${username}]` : this.more;
    this.more = reqUid ? `${this.more} [${reqUid}]` : this.more;
  }
  isPrint(inLvl) {
    return true;
  }
  _log(level, ...args) {
    args = args.map(arg => convertArg(arg))
    switch(level) {
      case 1:
        console.error('error', `[${this.category}]${this.more}`, ...args); break;
      case 2:
        console.warn('warn', `[${this.category}]${this.more}`, ...args); break;
      case 3:
        console.info('info', `[${this.category}]${this.more}`, ...args); break;
      case 4:
        console.debug('debug', `[${this.category}]${this.more}`, ...args); break;
      case 5:
        console.trace('trace', `[${this.category}]${this.more}`, ...args); break;
      default:
        console.log('default', `[${this.category}]${this.more}`, ...args); break;
    }
  }
  log(...args) {
    return this.info(...args);
  }
  error(...args) {
    if (this.isPrint(LoggerLevelValue.ERROR)) {
      this._log(LoggerLevelValue.ERROR, ...args)
      return true;
    }
    return false;
  }
  warn(...args) {
    if (this.isPrint(LoggerLevelValue.WARN)) {
      this._log(LoggerLevelValue.WARN, ...args)
      return true;
    }
    return false;
  }
  debug(...args) {
    if (this.isPrint(LoggerLevelValue.DEBUG)) {
      this._log(LoggerLevelValue.DEBUG, ...args)
      return true;
    }
    return false;
  }
  info(...args) {
    if (this.isPrint(LoggerLevelValue.INFO)) {
      this._log(LoggerLevelValue.INFO, ...args)
      return true;
    }
    return false;
  }
  trace(...args) {
    if (this.isPrint(LoggerLevelValue.TRACE)) {
      this._log(LoggerLevelValue.TRACE, ...args)
      return true;
    }
    return false;
  }
}

export const getFileExtension = (path) => {
  const basename = path.split(/[\\/]/).pop(),
    pos = basename.lastIndexOf(".");
  if (basename === "" || pos < 1) return "";
  return basename.slice(pos + 1);
};

export const appendAttributes = (props, attr, values) => {
  if (values && typeof values === 'object') {
    const oldValues = props[attr];
    if (oldValues && typeof oldValues === 'object') {
      props[attr] = {...oldValues, ...values}
    } else {
      props[attr] = values;
    }
  } else if (values) {
    console.log('values is not object..., fallback to full override')
    props[attr] = values;
  }
}

export const isTypeArray = (object, type) => {
  if (Array.isArray(object)) {
    let rtnVal = true;
    for (const o of object) {
      if (typeof o !== type) {
        rtnVal = false;
        break;
      }
    }
    return rtnVal;
  } else {
    return false;
  }

}

export const appendClassName = (props, attr, values) => {
  if (values && isTypeArray(values, 'string')) {
    let oldValues = (props[attr] || "").split(' ');
    const removes = values.filter(v => v.startsWith('-')).map(v => v.substring(1));
    const adds = values.filter(v => !v.startsWith('-'));
    let newValues = getArraysUnion(adds, oldValues);
    newValues = getArraysSubtraction(newValues, removes).join(' ');
    props[attr] = newValues;
  } else {
    console.log('values is not string[] ..., fallback to full override')
    props[attr] = values;
  }
}

const forwardAttributes = ['readOnly', 'disabled', 'style', 'className'];
export const processForwardAttribute = (props, state, moreAttributes) => {
  const itemKey = props.itemKey;
  if (itemKey && state) {
    let attributes = [...forwardAttributes];
    if (moreAttributes) attributes = [...attributes, ...moreAttributes];
    attributes.forEach(attr => {
      if (typeof state[itemKey + '_' + attr] !== 'undefined') {
        if (attr === 'style') {
          appendAttributes(props, attr, state[itemKey + '_' + attr]);
        } else if (attr === 'className') {
          appendClassName(props, attr, state[itemKey + '_' + attr]);
        } else {
          props[attr] = state[itemKey + '_' + attr];
        }
      }
    })
  }
}

export const setScriptCache = (scriptKey, script, versionStamp) => {
  setLocalStorageItemObject(`ActionScript_${scriptKey}`, {script, versionStamp})
}

export const getScriptCache = (scriptKey, script, versionStamp) => {
  const scriptObj = getLocalStorageItemObject(`ActionScript_${scriptKey}`, {})
  if (scriptObj.versionStamp && versionStamp && scriptObj.versionStamp > versionStamp) {
    log('runScript override with cache', scriptKey, scriptObj.versionStamp, '>', versionStamp)
    return scriptObj.script;
  } else {
    return script;
  }
}

const SCRIPTS_MAP = new Map();

export const runScript = ({script, versionStamp, scriptKey, actionType, isPreview, formData, oldFormData, form, oldForm, pageData, oldPageData, page, oldPage, resultMap, forceUpdate, doSetValues, requestContext, mainPanel, frontendApi}) => {
  log('runScript', scriptKey, actionType, versionStamp)
  script = getScriptCache(scriptKey, script, versionStamp)
  const formKey = formData?.formKey
  const formView = form?.formState?.formView;
  const pageKey = pageData?.pageKey;
  const logger = new Logger(scriptKey);
  const AppError = (text, value) => {
    const json = JSON.stringify({ module: scriptKey, text, value })
    throw new Error(json);
  }
  const require = () => {
    console.log('require() should not call in frontend...');
  }
  const authUser = getLocalStorageItem('user_obj');
  const user = toAuthUserObj(authUser);
  const username = user?.username;
  const roles = user?.roles;
  const inputparams = {script, scriptKey, actionType, isPreview, moment, formData, oldFormData, formKey, formView, form, oldForm, pageData, oldPageData, pageKey, page, oldPage, resultMap, AppError, forceUpdate, logger, uid, requestContext, mainPanel, username, user, roles, doSetValues, frontendApi, require, ActionType: ActionType()}
  const newScript = `
    "use strict";
    const {script, scriptKey, actionType, isPreview, moment, formData, oldFormData, formKey, formView, form, oldForm, pageData, oldPageData, pageKey, page, oldPage, resultMap, AppError, forceUpdate, logger, uid, requestContext, mainPanel, username, user, roles, doSetValues, frontendApi, require, ActionType} = inputparams;
    const args = {scriptKey, actionType, formData, oldFormData, formKey, formView, form, oldForm, pageData, oldPageData, pageKey, username, user, roles, ActionType};
    try {
      ${script}
    } catch (error) {
      if (error?.message?.includes(scriptKey)) throw error;
      console.error(error);
    }
  `
  let newFunction = SCRIPTS_MAP.get(newScript);
  if (!newFunction) {
    // eslint-disable-next-line no-new-func
    newFunction = new Function("inputparams", newScript);
  }
  newFunction(inputparams);
};

export const checkPageStateChanged = (oldPageState, attribute, newValue) => {
  const oldValue = oldPageState?.[attribute];
  if (equals(oldValue, newValue)) {
    return oldPageState;
  } else {
    const newPageState = {...oldPageState};
    newPageState[attribute] = newValue;
    return newPageState;
  }
}

export const checkAnyPageStateChanged = (oldPageState, pageState) => {
  let newPageState = {};
  if (oldPageState) newPageState = {...newPageState, ...oldPageState};
  if (pageState) newPageState = {...newPageState, ...pageState};
  if (equals(oldPageState, newPageState)) {
    return oldPageState;
  } else {
    return newPageState;
  }
}

const PATH_FORM_SEARCH = '/system/rts/:type/:formKey/:formView'
const PATH_FORM_EDIT = '/system/rt/:type/:formKey/:formView/:id?/:direct?'
const PATH_FLOW_SEARCH =
  '/system/wrts/:type/:flow/:formKey/:formView/:flowParent?'
const PATH_FLOW_EDIT =
  '/system/wrt/:type/:flow/:formKey/:formView/:id?/:direct?'

export const getMatchPath = (pathname) => {
  if (isEmpty(pathname)) {
    return ''
  }
  if (pathname.indexOf('/rts/') !== -1) {
    return PATH_FORM_SEARCH
  } else if (pathname.indexOf('/rt/') !== -1) {
    return PATH_FORM_EDIT
  } else if (pathname.indexOf('/wrts/') !== -1) {
    return PATH_FLOW_SEARCH
  } else if (pathname.indexOf('/wrt/') !== -1) {
    return PATH_FLOW_EDIT
  }
}

const PAGE_COMPONENT_APIS = {

}

export const registerPageComponentApis = (pageKey, api, fn) => {
  let apisHolder = PAGE_COMPONENT_APIS[pageKey];
  if (!apisHolder) {
    apisHolder = {};
    PAGE_COMPONENT_APIS[pageKey] = apisHolder;
  }
  apisHolder[api] = fn;
}

export const unregisterPageComponentApis = (pageKey, api) => {
  let apisHolder = PAGE_COMPONENT_APIS[pageKey];
  if (!apisHolder) {
    apisHolder = {};
    PAGE_COMPONENT_APIS[pageKey] = apisHolder;
  }
  apisHolder[api] = null;
}

export const invokePageComponentApis = async (pageKey, api, params) => {
  const apisHolder = PAGE_COMPONENT_APIS[pageKey];
  const fn = apisHolder?.[api];
  if (fn) {
    const rtnVal = await fn(...params);
    log('component api invoked', {pageKey, api, params, rtnVal})
    return rtnVal;
  } else {
    log('component api not found', {pageKey, api})
    return null;
  }
}


const CACHE_SOURCE = `
https://cdn.ckeditor.com/ckeditor5/39.0.0/super-build/ckeditor.js
https://cdn.jsdelivr.net/npm/apexcharts
https://cdn.jsdelivr.net/npm/echarts@5.4.3/dist/echarts.min.js
https://cdn.jsdelivr.net/npm/monaco-editor@0.33.0/min/vs/base/browser/ui/codicons/codicon/codicon.ttf
https://cdn.jsdelivr.net/npm/monaco-editor@0.33.0/min/vs/base/common/worker/simpleWorker.nls.js
https://cdn.jsdelivr.net/npm/monaco-editor@0.33.0/min/vs/base/worker/workerMain.js
https://cdn.jsdelivr.net/npm/monaco-editor@0.33.0/min/vs/basic-languages/javascript/javascript.js
https://cdn.jsdelivr.net/npm/monaco-editor@0.33.0/min/vs/editor/editor.main.css
https://cdn.jsdelivr.net/npm/monaco-editor@0.33.0/min/vs/editor/editor.main.js
https://cdn.jsdelivr.net/npm/monaco-editor@0.33.0/min/vs/editor/editor.main.nls.js
https://cdn.jsdelivr.net/npm/monaco-editor@0.33.0/min/vs/language/typescript/tsMode.js
https://cdn.jsdelivr.net/npm/monaco-editor@0.33.0/min/vs/language/typescript/tsWorker.js
https://cdn.jsdelivr.net/npm/monaco-editor@0.33.0/min/vs/loader.js
https://cdn.jsdelivr.net/npm/monaco-editor@0.36.1/min/vs/base/browser/ui/codicons/codicon/codicon.ttf
https://cdn.jsdelivr.net/npm/monaco-editor@0.36.1/min/vs/base/common/worker/simpleWorker.nls.js
https://cdn.jsdelivr.net/npm/monaco-editor@0.36.1/min/vs/base/worker/workerMain.js
https://cdn.jsdelivr.net/npm/monaco-editor@0.36.1/min/vs/basic-languages/javascript/javascript.js
https://cdn.jsdelivr.net/npm/monaco-editor@0.36.1/min/vs/editor/editor.main.css
https://cdn.jsdelivr.net/npm/monaco-editor@0.36.1/min/vs/editor/editor.main.js
https://cdn.jsdelivr.net/npm/monaco-editor@0.36.1/min/vs/editor/editor.main.nls.js
https://cdn.jsdelivr.net/npm/monaco-editor@0.36.1/min/vs/language/typescript/tsMode.js
https://cdn.jsdelivr.net/npm/monaco-editor@0.36.1/min/vs/language/typescript/tsWorker.js
https://cdn.jsdelivr.net/npm/monaco-editor@0.36.1/min/vs/loader.js
https://cdn.jsdelivr.net/npm/quasar@2.12.0/dist/quasar.prod.css
https://cdn.jsdelivr.net/npm/quasar@2.12.0/dist/quasar.umd.prod.js
https://cdn.jsdelivr.net/npm/sortablejs@1.15.0/Sortable.min.js
https://cdn.jsdelivr.net/npm/summernote@0.8.18/dist/font/summernote.ttf
https://cdn.jsdelivr.net/npm/summernote@0.8.18/dist/font/summernote.woff
https://cdn.jsdelivr.net/npm/summernote@0.8.18/dist/font/summernote.woff2
https://cdn.jsdelivr.net/npm/summernote@0.8.18/dist/summernote-lite.min.css
https://cdn.jsdelivr.net/npm/summernote@0.8.18/dist/summernote-lite.min.js
https://cdn.jsdelivr.net/npm/vue@3/dist/vue.global.prod.js
https://cdn.tiny.cloud/1/zo2m6rwxii2lrw61k1d7z1k5o7diz7law6r8dhfgemo6bhiu/tinymce/6/tinymce.min.js
https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css
https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/fonts/fontawesome-webfont.ttf?v=4.7.0 .ttf
https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/fonts/fontawesome-webfont.woff2?v=4.7.0 .woff2
https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/fonts/fontawesome-webfont.woff?v=4.7.0 .woff
https://fastly.jsdelivr.net/npm/d3-array
https://fastly.jsdelivr.net/npm/d3-geo
https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Noto+Sans+TC:wght@100&family=Open+Sans:wght@300&family=Roboto:wght@300&display=swap .css
https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900|Material+Icons .css
https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh7USew8.ttf
https://fonts.gstatic.com/s/materialicons/v140/flUhRq6tzZclQEJ-Vdg-IuiaDsNZ.ttf
https://fonts.gstatic.com/s/notosanstc/v35/-nFuOG829Oofr2wohFbTp9ifNAn722rq0MXz76Cz_Co.ttf
https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0C4n.ttf
https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgWxP.ttf
https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9vAw.ttf
https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2
https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5vAw.ttf
https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAw.ttf
https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtvAw.ttf
https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5Q.ttf
https://rawgit.com/vitmalina/w2ui/master/dist/w2ui.css
https://rawgit.com/vitmalina/w2ui/master/dist/w2ui.js
https://unpkg.com/leaflet@1.7.1/dist/images/layers-2x.png
https://unpkg.com/leaflet@1.7.1/dist/images/layers.png
https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png
https://unpkg.com/leaflet@1.7.1/dist/leaflet.css
https://unpkg.com/onsenui/css/onsen-css-components.min.css
https://unpkg.com/onsenui/css/onsenui.css
https://unpkg.com/onsenui/js/onsenui.min.js
`
const CACHE_SOURCE_LIST = CACHE_SOURCE.split('\n').map(l => l.split(' '));
const CACHE_URL_LIST = CACHE_SOURCE_LIST.map(l => l[0]);

const NO_CACHE = false;

export function getCdnUrl() {
  let url = window.AppCtrl?.cdnUrl;
  // Microsoft -> https://cdn.ds1.nmt.com.hk/
  // Amazonas -> https://s3.ap-east-1.amazonaws.com/cdn.nmt.com.hk/
  // Cloudflare -> https://cdn.ideocanvas.com/
  if (!url) url = 'https://cdn.ds1.nmt.com.hk/';
  return url;
}

function patchCachedUrl(url) {
  if (NO_CACHE) return url;
  let alternative = null;
  const index = CACHE_URL_LIST.indexOf(url);
  if (index !== -1) {
    alternative = url.replace('https://', getCdnUrl());
    alternative = alternative.replace(/https:\/\//, '');
    alternative = alternative.replace(/[?=,:|+]/g, '_');
    alternative = 'https://' + alternative;
    if (CACHE_SOURCE_LIST[index].length > 1) {
      alternative = alternative + CACHE_SOURCE_LIST[index][1];
    }
  }
  return alternative;
}

export function loadScript(url, isModule) {
  const alternative = patchCachedUrl(url);
  return new Promise((resolve, reject) => {
    // Check if the script has already been loaded
    const script = document.querySelector(`script[src="${alternative || url}"]`);
    if (script) {
      // If the script is already loaded, resolve the promise immediately
      resolve();
      return;
    }

    // Create a new script element
    const newScript = document.createElement('script');
    newScript.src = alternative || url;
    if (isModule) newScript.type = 'module';
    newScript.referrerPolicy = "origin";

    // Set up event listeners to track the script's loading state
    newScript.addEventListener('load', () => {
      console.log(`load script successfully ${alternative || url}`);
      resolve();
    });
    newScript.addEventListener('error', async () => {
      reject(new Error(`Failed to load script ${alternative || url}`));
    });

    // Append the script element to the document's head
    document.head.appendChild(newScript);
  });
}

export function copyToClipboard({selector, text}) {
  try {
    /* Get the input field */
    if (selector) {
      const input = document.querySelector(selector);
      /* Select the text within the input field */
      input.select();
      input.setSelectionRange(0, 99999); /* For mobile devices */
      text = input.value;
    }
    if (text) {
      navigator.clipboard.writeText(text);
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log('failed to copy to clipboard', err)
    return false;
  }
}

export async function getClipboardContent() {
  try {
    const clipboardContent = await navigator.clipboard.readText();
    return clipboardContent;
  } catch (error) {
    throw new Error('Failed to read clipboard content: ' + error);
  }
}

export function waitForCondition(condition, params) {
  const interval = params?.interval || 500;
  const timeout = params?.timeout || 10000;
  const label = params?.label;
  let isFirst = true;
  return new Promise((resolve, reject) => {
    const startTime = Date.now();

    function checkCondition() {
      const rtnVal = condition();
      if (isFirst) {
        isFirst = false;
      } else {
        log('waitForCondition: checkCondition()', label, rtnVal)
      }
      if (rtnVal) {
        resolve(true);
      } else if (Date.now() - startTime >= timeout) {
        resolve(false);
      } else {
        setTimeout(checkCondition, interval);
      }
    }

    checkCondition();
  });
}

export function sortKeysByLastUpdateDate(keyMap, keys, nullFirst = true) {
  const keysWithDate = keys.filter(key => keyMap[key]);
  const keysWithoutDate = keys.filter(key => !keyMap[key]);

  const sortedKeysWithDate = keysWithDate.sort((a, b) => {
    const dateA = keyMap[a];
    const dateB = keyMap[b];
    return dateA - dateB;
  });

  if (nullFirst) {
    return keysWithoutDate.concat(sortedKeysWithDate);
  } else {
    return sortedKeysWithDate.concat(keysWithoutDate);
  }
}

export function isDateLongerThanMinutes(dateValue, minutes) {
  const date = new Date(dateValue);
  const now = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = now.getTime() - date.getTime();

  // Convert the time difference to minutes
  const minutesDifference = Math.floor(timeDifference / 1000 / 60);

  return minutesDifference > minutes;
}

export function copyVariablesByPrefix(storeObject, configObject, prefix) {
  if (configObject) {
    for (const key in configObject) {
      if (key.startsWith(prefix)) {
        const newKey = key.substring(prefix.length);
        const type = typeof storeObject[key];
        if (type === 'boolean') {
          if (configObject[key] === 'Y') storeObject[newKey] = true;
          if (configObject[key] === 'N') storeObject[newKey] = false;
        } else {
          storeObject[newKey] = configObject[key];
        }
      }
    }
  }
  return storeObject;
}


export function unsetResizable(splitterDiv) {
  Array.from(splitterDiv.querySelectorAll('.splitter-resize-indicator')).forEach(ele => ele.remove())
  Array.from(splitterDiv.querySelectorAll('.splitter-resize-handle')).forEach(ele => ele.remove())
}

export async function setupSplitter(splitterKey, splitterDiv, doResize, minimumWidth = 50, offsetTop = 0) {
  console.log('setupSplitter()', splitterDiv)
  const storedWidthKey = 'SPLITTER_WIDTH_' + splitterKey;
  const storedWidth = getLocalStorageItemObject(storedWidthKey, {});
  const parentNode = splitterDiv;
  const leftDiv = splitterDiv.querySelector('.splitter-left-panel');
  const rightDiv = splitterDiv.querySelector('.splitter-right-panel');
  let ri = splitterDiv.querySelector('.splitter-resize-indicator');
  let rh = splitterDiv.querySelector('.splitter-resize-handle');
  if (ri && rh) {
    return;
  }
  const lrect = leftDiv.getBoundingClientRect();
  const rrect = rightDiv.getBoundingClientRect();
  const prect = parentNode.getBoundingClientRect();
  let leftWidth = leftDiv.offsetWidth;
  let rightWidth = rightDiv.offsetWidth;
  const top = offsetTop + 'px';
  console.log('setupResizable() rect', {lrect, rrect, prect});
  unsetResizable(splitterDiv);
  await delay(500);

  ri = document.createElement("div");
  ri.classList.add("splitter-resize-indicator");
  ri.style.setProperty("top", top);
  ri.style.setProperty("left", (lrect.width - 10) + "px");
  parentNode.appendChild(ri);

  rh = document.createElement("div");
  rh.classList.add("splitter-resize-handle");
  rh.style.setProperty("top", top);
  rh.style.setProperty("left", (lrect.width - 10) + "px");
  parentNode.appendChild(rh);
  let startPos = null;
  let movePos = null;

  if (storedWidth.left && storedWidth.right && doResize) {
    leftWidth = storedWidth.left;
    rightWidth = storedWidth.right;
    ri.style.setProperty("left", (leftWidth - 10) + "px");
    rh.style.setProperty("left", (leftWidth - 10) + "px");
    doResize(storedWidth);
  }
  const currMousePos = (e) => {
    try {
      const contentRect = parentNode.getBoundingClientRect();
      const scrollLeft = parentNode.scrollLeft;
      console.log('currMousePos()', {scrollLeft, contentRect, e});
      return scrollLeft + e.clientX - contentRect.left;
    } catch (error) {
      console.log('currMousePos()', error, e);
      throw error;
    }
  }
  const mouseMove = (e) => {
	  try {
      e.preventDefault();
      e.stopPropagation();
      movePos = currMousePos(e);
      const delta = movePos - startPos;
      const currentWidth = leftDiv.offsetWidth;
      if (delta + currentWidth > minimumWidth) {
        ri.style.setProperty("left", movePos + "px");
      } else {
        ri.style.setProperty(
          "left",
          startPos - currentWidth + minimumWidth + "px"
        );
      }
    } catch (error) {
      console.log('onMouseMove()', error);
      console.log('onMouseMove() event', e);
    }
  }

  const mouseUp = () => {
    const lrect = leftDiv.getBoundingClientRect();
    ri.style.setProperty("display", "none");
    parentNode.removeEventListener('mousemove', mouseMove);
    parentNode.removeEventListener('mouseup', mouseUp);
    let delta = movePos - startPos;
    if (leftWidth + delta < minimumWidth) {
      delta = minimumWidth - leftWidth;
    }
    leftWidth = leftWidth + delta;
    rightWidth = rightWidth - delta;
    console.log('mouseUp()', {delta, lrect, leftWidth, rrect, rightWidth});
    rh.style.setProperty("left", (leftWidth - 10) + "px");
    storedWidth.left = leftWidth;
    storedWidth.right = rightWidth;
    if (doResize) {
      doResize(storedWidth);
      setLocalStorageItemObject(storedWidthKey, storedWidth)
    }
  }
  const mouseDown = (e) => {
    startPos = currMousePos(e);
    console.log('mouseDown()', startPos);
    ri.style.setProperty("display", "block");
    parentNode.addEventListener('mousemove', mouseMove);
    parentNode.addEventListener('mouseup', mouseUp);
  }
  rh.addEventListener("mousedown", mouseDown);
}

export function convertBytesToFileSize(bytes) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];

  if (bytes === 0) {
      return '0 B';
  }

  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const fileSize = parseFloat((bytes / Math.pow(1024, i)).toFixed(2));

  return fileSize + ' ' + units[i];
}