import { Button } from "antd";
import * as FileSaver from 'file-saver';
import { useState } from 'react';
import { log, isEmpty } from "util/algorithm";
import jszip from 'jszip'
import $ from 'jquery';
import { equals } from "./algorithm";

let XLSX = null;
import('xlsx').then(m => {
  XLSX = m;
});

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

function create_gap_rows(ws, nrows) {
  const ref = XLSX.utils.decode_range(ws["!ref"]);       // get original range
  ref.e.r += nrows;                                    // add to ending row
  ws["!ref"] = XLSX.utils.encode_range(ref);           // reassign row
}

const toCsv = function (table) {
  // Query all rows
  const rows = table.querySelectorAll('tr');

  const csv = [].slice
    .call(rows)
    .map(function (row) {
      // Query all cells
      const cells = row.querySelectorAll('th,td');
      return [].slice
        .call(cells)
        .map(function (cell) {
          return cell.textContent ? cell.textContent.trim() : '';
        });
    });

  const result = [];
  for (const element of csv) {
    const row = element;
    let keep = false;
    for (const element of row) {
      if (!isEmpty(element.trim())) {
        keep = true;
      }
    }
    if (keep) {
      log("push", row);
      result.push(row);
    }
  }

  return result;
};

export const exportTableToXLSX = (id, fileName, header) => {
  return new Promise((resolve, reject) => {
    try {
      const form = document.getElementById(id);
      const tables = form.getElementsByTagName('table');
      const ws = XLSX.utils.book_new();
      let row = 0;
      if (header) {
        XLSX.utils.sheet_add_aoa(ws, header, {origin:{r:row, c:0}});
        row += header.length + 2;
      }
      for (const element of tables) {
        if (header) {
          create_gap_rows(ws, 2);
        }
        const csv = toCsv(element);
        log("csv", csv);
        XLSX.utils.sheet_add_aoa(ws, csv, {origin:{r:row, c:0}});
        create_gap_rows(ws, 2);
        row += csv.length + 2;
      }
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {type: fileType});
      FileSaver.saveAs(data, fileName + fileExtension);
      resolve();
    } catch (e) {
      reject(e);
    }
  });
}

export const exportToXLSX = (csvData, fileName, columns) => {
  return new Promise((resolve, reject) => {
    try {
      let header = null;
      let skipHeader = false;
      let titles = null;
      let origin = null;
      const ws = XLSX.utils.book_new();
      if (columns) {
        csvData = csvData.map(row => {
          return columns.reduce(
            (converted, col)=>{
              let val = row[col.dataIndex];
              if (val && (col.type === 'json' || col.type === 'json[]' || (col.type === 'select' && (col.mode === 'multiple' || col.mode === 'tags')))) {
                val = JSON.stringify(val);
              }
              converted[col.dataIndex]=val;
              return converted;
            }, {})
        });
        header = columns.map(col => col.dataIndex);
        titles = columns.map(col => col.title);
        skipHeader = true;
        origin = 'A2';
        XLSX.utils.sheet_add_aoa(ws, [titles]);
      }
      log("export data", csvData);
      XLSX.utils.sheet_add_json(ws, csvData, {origin: origin, header:header, skipHeader:skipHeader});
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {type: fileType});
      FileSaver.saveAs(data, fileName + fileExtension);
      resolve();
    } catch (e) {
      reject(e);
    }
  });
}

export const exportToExcelTabs = (fileName, tabs) => {
  return new Promise((resolve, reject) => {
    try {
      const wb = XLSX.utils.book_new();
      for (const sheet of tabs) {
        const sheetName = sheet.sheetName;
        const columns = sheet.columns;
        const csvData = sheet.csvData;
        log("exportToExcelTabs=", {sheetName, columns, csvData});

        const ws = XLSX.utils.json_to_sheet(csvData);
        if (columns) {
          let header = [];
          Object.keys(columns).forEach(col => {
            header.push(columns[col]);
          });
          XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        }

        XLSX.utils.book_append_sheet(wb, ws, sheetName);
      }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {type: fileType});
      FileSaver.saveAs(data, fileName + fileExtension);
      resolve();
    } catch (e) {
      reject(e);
    }
  });
}

export const importXLSX = (columns) => {
  return new Promise((resolve, reject) => {
    if ($('#global-file-upload').length) {
      try {
        $('#global-file-upload').remove();
      } catch (e) {
        log('remove global file upload failed', e)
      }
    }
    if (!$('#global-file-upload').length) {
      $('<input id="global-file-upload" class="global-file-upload" style="display: none;" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>')
      .appendTo('body');
    }
    $('#global-file-upload').on('change', async (event) => {
      try {
        const selectedFile = event.target.files[0];
        if (selectedFile.name.toLowerCase().indexOf('xlsx') === -1) {
          return reject("Please upload an XLSX file!");
        }
        const data = await importFromXLSX(selectedFile, columns)
        resolve(data)
      } catch (error) {
        reject(error);
      }
    });
    $('#global-file-upload').click();
  })
}

export const importFromXLSX = (file, columns) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        let data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        const header = data[0];
        for (let i = 0; i < header.length; i++) {
          const h = header[i];
          if (h !== columns[i]?.title) {
            console.log('Invalid excel format.', i, h, columns[i]?.title)
            return reject("Invalid excel format.");
          }
        }
        data = data.slice(1); // skip header
        log("Data>>>", data);
        if (columns) {
          let csvData = data.map((row, rowIndex) => {
            return columns.reduce(
              (converted, col, index) => {
                let val = row[index];
                if (val && (col.type === 'json' || col.type === 'json[]' || (col.type === 'select' && col.mode))) {
                  try {
                    val = JSON.parse(val);
                  } catch (e) {
                    log("Invalid JSON data at row - "+(rowIndex+1), val, e);
                    reject("Invalid JSON data at row - "+(rowIndex+1));
                  }
                } else if (val && col.type === 'number' && typeof val !== 'number' && col.dataIndex !== 'versionStamp') {
                  log("Invalid number data at row - "+(rowIndex+1), val);
                  reject("Invalid number data at row - "+(rowIndex+1));
                } else if (val && col.type === 'string' && typeof val !== 'string') {
                  val = `${val}`;
                }
                if (val !== undefined) converted[col.dataIndex] = val;
                return converted;
              }, {})
          });
          csvData = csvData.filter(row => !equals(row, {}));
          log("csvData", csvData);
          return resolve(csvData);
        } else {
          return resolve(data);
        }
      }
      reader.readAsBinaryString(file);
    } catch (e) {
      log("failed to import file", e);
      reject(e);
    }
  });
}

export const ExportXLSXButton = ({csvData, fileName, ...props}) => {
  const [loading, setLoading] = useState(false);
  const onClick = async () => {
    setLoading(true);
    await exportToXLSX(csvData,fileName);
    setLoading(false);
  }
  return (
      <Button loading={loading} onClick={onClick} {...props}/>
  )
}

export const exportToTXT = (text, fileName, fileExtension, type) => {
  return new Promise((resolve, reject) => {
    try {
      if (!fileExtension) fileExtension = ".dat";
      if (!type) type = "text/plain;charset=utf-8";
      const blob = new Blob([text], {type});
      FileSaver.saveAs(blob, fileName + fileExtension);
      resolve();
    } catch (e) {
      reject(e);
    }
  });
}

export const exportToZip = (list, map, filename) => {
  return new Promise((resolve, reject) => {
    const zip = jszip()
    for (const item of list) {
      const text = map[item];
      if (item.startsWith('src/')) {
        console.log('export file', item)
        const entry = item.split('/');
        const last = entry.length - 1;
        let folder = zip.folder(entry[0]);
        for (let i = 1; i < last; i++) {
          folder = folder.folder(entry[i]);
        }
        folder.file(entry[last], text);
      } else {
        if (item.startsWith('ServerFile')) {
          zip.file(item.replace(/\//g, '_'), text);
        } else {
          zip.file(item, text);
        }
      }
    }
    zip.generateAsync({type: "blob"}).then(content => {
      FileSaver.saveAs(content, filename)
      resolve();
    }).catch((e) => {
      reject(e);
    })
  });
}

export const importFromTXT = (f) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.readAsText(f);
      reader.onload = function() {
        log(reader.result);
        resolve(reader.result);
      };
    } catch (e) {
      reject(e);
    }
  });
}

export const importFromZip = (f) => {
  return new Promise((resolve, reject) => {
    jszip.loadAsync(f).then(async (zip) => {
      const map = {};
      for (const name of Object.keys(zip.files)) {
        const file = zip.files[name];
        console.log('file', file)
        if (!file.dir) {
          if (name.startsWith('ServerFile')) {
            const fileContent = await file.async('string');
            const fileName = `ServerFile (${JSON.parse(fileContent).ServerFile[0].fileKey})`
            map[fileName] = fileContent;
          } else {
            map[name] = await file.async('string');
          }
        }
      }
      resolve(map);
    }).catch(e => {
      reject(e);
    })
  });
}

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export const saveBase64 = async (base64Data, fileName) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${base64Data}`).then(response => {
        response.blob().then(blob => {
          FileSaver.saveAs(blob, fileName);
        })
      });
      resolve();
    } catch (e) {
      reject(e);
    }
  });
}