import { useEditor, useNode } from "@craftjs/core";
import { Col, Row } from "antd";
import { deleteButton, EditorCollector, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting } from "./common";

export const SpSplitterPanel = ({...props}) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppSplitterPanel doRef={ref => connect(drag(ref))} selectedStyle={style} dbtn={dbtn} {...props}/>
  )
}

export const SppSplitterPanel = ({
  doRef, dbtn, selectedStyle, children,
  pageKey, itemKey, pageState, setPageState, ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}
  return (
    <Col ref={doRef} {...props}>
      <Row>
        {children}
      </Row>
      {dbtn}
    </Col>
  );
}

const SpSplitterPanelSetting = () => {
  return (
    <>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  );
}
SppSplitterPanel.enablePageState = true;

SpSplitterPanel.craft = {
  displayName: "SplitterPanel",
  related: {
    settings: SpSplitterPanelSetting
  }
}

registerComponent({
  key:"SpSplitterPanel",
  component: SpSplitterPanel,
  runtimeComponent: SppSplitterPanel,
  type: "Container",
});

export default SpSplitterPanel;