import { useEditor, useNode } from "@craftjs/core";
import { Col, Switch } from "antd";
import { Form, Input, Select } from "components/Form";
import React, {useEffect, useState} from "react";
import { IoMdSwitch } from "react-icons/io";
import IntlMessages from "util/IntlMessages";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting, syncPageState, updatePageState } from "./common";

const SpSwitchSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="defaultValue" label="default value">
        <Input className="item-property"/>
      </Form.Item>
      <Form.Item name="checkedChildren" label="label 1">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="unCheckedChildren" label="label 2">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="size" label="size">
        <Select className="item-property">
          <Select.Option value="default">default</Select.Option>
          <Select.Option value="small">small</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  )
}

export const SpSwitch = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppSwitch doRef={ref => connect(drag(ref))} dbtn={dbtn} {...props} selectedStyle={style} />
  )
}

export const SppSwitch = ({
  doRef, dbtn, selectedStyle,
  pageKey, itemKey, pageState, setPageState, value,  defaultValue,
  checkedChildren, unCheckedChildren, size, disabled,
  ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}
  const inputProps = {checkedChildren, unCheckedChildren, size, disabled};
  const [myValue, setMyValue] = useState(defaultValue);

  useEffect(() => {
    syncPageState({itemKey, myValue, setMyValue, pageState, setPageState})
  },[itemKey, myValue, pageState, setPageState])

  const onMyChange = (myValue) => {
    updatePageState({itemKey, myValue, setMyValue, pageState, setPageState})
  }

  return (
    <Col ref={doRef} {...props}>
      <Switch checked={myValue} onChange={onMyChange} {...inputProps}/>
      {dbtn}
    </Col>
  );
}
SppSwitch.enablePageState = true;

SpSwitch.craft = {
  displayName: "Switch",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpSwitchSetting
  }
}

registerComponent({
  key: "SpSwitch",
  component: SpSwitch,
  runtimeComponent: SppSwitch,
  template: <SpSwitch itemKey={getId('switch')} className="sp-switch" title={"Switch"} span={24} />,
  title: <IntlMessages id="system.page.library.switch" text="Switch" />,
  icon: <IoMdSwitch  className="react-icons icon-io"/>,
  type: "Component",
  subtype: "input",
  sequence: 9,
});

export default SpSwitch;