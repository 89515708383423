import { useEditor, useNode } from "@craftjs/core";
import { Col, Select } from "antd";
import { Form, Input } from "components/Form";
import React, { useEffect, useRef, useState } from "react";
import { IoFootstepsSharp } from "react-icons/io5";
import IntlMessages from "util/IntlMessages";
import { AccountStore } from "../../../../constants/Account";
import { INITIAL_CONTENT, MySteps } from "../../FormSetup/components/SfSteps";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting, syncPageState, updatePageState } from "./common";

const SpStepsSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="defaultValue" label="default value" className="defaultValue"
        labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} >
        <Input.TextArea
          className="item-property"
          autoSize allowClear
        />
      </Form.Item>
      <Form.Item name="size" label="size">
        <Select className="item-property">
          <Select.Option value="default">default</Select.Option>
          <Select.Option value="small">small</Select.Option>
        </Select>
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  )
}

export const SpSteps = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppSteps doRef={ref => connect(drag(ref))} dbtn={dbtn} {...props} selectedStyle={style} />
  )
}

export const SppSteps = ({
  doRef, dbtn, selectedStyle,
  pageKey, itemKey, pageState, setPageState, value,  defaultValue,
  size, hidden, ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  if (!doRef && hidden) style.display = 'none';
  const props = {...otherProps, style}
  const inputProps = {size};
  const [myValue, setMyValue] = useState(defaultValue);
  const timer = useRef()

  useEffect(() => {
    syncPageState({itemKey, myValue, setMyValue, pageState, setPageState})
  },[itemKey, myValue, pageState, setPageState])

  const onMyChange = (myValue) => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      updatePageState({itemKey, myValue, setMyValue, pageState, setPageState})
    }, AccountStore.ON_CHANGE_DELAY);
  }

  return (
    <Col ref={doRef} {...props}>
      <MySteps value={myValue} onChange={onMyChange} {...inputProps} />
      {dbtn}
    </Col>
  );
}
SppSteps.enablePageState = true;

SpSteps.craft = {
  displayName: "Steps",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpStepsSetting
  }
}

registerComponent({
  key: "SpSteps",
  component: SpSteps,
  runtimeComponent: SppSteps,
  template: <SpSteps itemKey={getId('steps')} className="sp-steps" title={"Steps"} span={24} defaultValue={INITIAL_CONTENT} />,
  title: <IntlMessages id="system.page.library.steps" text="Steps" />,
  icon: <IoFootstepsSharp className="react-icons icon-io5"/>,
  type: "Component",
  subtype: "input",
  sequence: 18,
});

export default SpSteps;