import { Layout } from "antd";
import $ from 'jquery';
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import IntlMessages from "util/IntlMessages";
import App from "../../routes/index";
import Topbar from "../Topbar/index";

import { useRouteMatch } from "react-router-dom";
import { updateWindowWidth } from "../../appRedux/actions";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";
import AppSidebar from "./AppSidebar";
import { isPublicPage } from "../../util/algorithm";
import TranslateDrawer from "./TranslateDrawer";
import { useRendersCount } from "../../util/reactHook";
import { settingsSignal, systemSignal } from "../../util/signal";

const {Content, Footer} = Layout;

const getContainerClass = (navStyle) => {
  switch (navStyle) {
    case NAV_STYLE_DARK_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_DEFAULT_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_BELOW_HEADER:
      return "gx-container-wrap";
    case NAV_STYLE_ABOVE_HEADER:
      return "gx-container-wrap";
    default:
      return '';
  }
};

const getNavStyles = () => {
  return <Topbar/>;
};

const getAbsoluteDelta = (number1, number2) => {
  if (!number1) number1 = 0;
  if (!number2) number2 = 0;
  return Math.abs(number1 - number2);
}

const MainApp = ({children}) => {
  const {navStyle} = settingsSignal;
  const {headless, copyright, version} = systemSignal;
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const rootDiv = useRef();
  const oldClientWidth = useRef();
  useRendersCount(`MainApp`);
  useEffect(() => {
    if (!children) {
      new ResizeObserver(function () {
        console.log('update client width', rootDiv.current?.clientWidth);
        console.log('update client width oldClientWidth', oldClientWidth.current);
        const delta = getAbsoluteDelta(oldClientWidth.current, rootDiv.current?.clientWidth);
        console.log('update client width delta', delta);
        if (rootDiv.current?.clientWidth > 0 && delta > 100) {
          dispatch(updateWindowWidth(rootDiv.current?.clientWidth));
          oldClientWidth.current = rootDiv.current?.clientWidth;
        }
      }).observe(rootDiv.current);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onScroll = (e) => {
    if (e.target.scrollTop > 30) {
      $("header.ant-layout-header").addClass("hidden");
      $("main.ant-layout-content.gx-layout-content").addClass("hidden");
    } else {
      $("header.ant-layout-header").removeClass("hidden");
      $("main.ant-layout-content.gx-layout-content").removeClass("hidden");
      window.scrollTo(0, 0);
    }
  }

  if (children) {
    return (
      <div className="layout-headless" ref={rootDiv} onScroll={onScroll}>
        {children}
        <TranslateDrawer/>
      </div>
    )
  } else if (headless || isPublicPage()) {
    return (
      <div className="layout-headless" ref={rootDiv} onScroll={onScroll}>
        <App match={match}/>
        <TranslateDrawer/>
      </div>
    )
  } else {
    return (
      <Layout className="gx-app-layout" ref={rootDiv}>
        <AppSidebar navStyle={navStyle}/>
        <Layout>
          {getNavStyles()}
          <Content className={`gx-layout-content ${getContainerClass(navStyle)} layout-normal`}  onScroll={onScroll}>
            <App match={match}/>
            <Footer>
              <div className="gx-layout-footer-content">
                <span className="copyright">{copyright}</span> <span className="version"><IntlMessages id={"system.version"} text={"version"}></IntlMessages> {version}</span>
              </div>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    )
  }
};
export default MainApp;

