import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  TOGGLE_COLLAPSED_NAV,
  WINDOW_WIDTH,
} from "../../constants/ActionTypes";
import { commonSignal } from "../../util/signal";

const INIT_STATE = {
  error: "",
  loading: false,
  message: "",
  navCollapsed: true,
  width: window.innerWidth,
  pathname: "/",
};

const CommonReducer = (state = INIT_STATE, action) => {
  let newState = state;
  switch (action.type) {
    case "@@router/LOCATION_CHANGE": {
      newState = {
        ...state,
        pathname: action.payload.location.pathname,
        navCollapsed: false,
      };
      break;
    }
    case WINDOW_WIDTH:
      newState = {
        ...state,
        width: action.width,
      };
      break;
    case TOGGLE_COLLAPSED_NAV: {
      newState = {
        ...state,
        navCollapsed: action.navCollapsed,
      };
      break;
    }
    case FETCH_START: {
      newState = { ...state, error: "", message: "", loading: true };
      break;
    }
    case FETCH_SUCCESS: {
      newState = { ...state, error: "", message: "", loading: false };
      break;
    }
    case SHOW_MESSAGE: {
      newState = {
        ...state,
        error: "",
        message: action.payload,
        loading: false,
      };
      break;
    }
    case FETCH_ERROR: {
      newState = {
        ...state,
        loading: false,
        error: action.payload,
        message: "",
      };
      break;
    }
    case HIDE_MESSAGE: {
      newState = { ...state, loading: false, error: "", message: "" };
      break;
    }
    default:
      newState = state;
      break;
  }
  Object.assign(commonSignal, newState);
  return newState;
};

export default CommonReducer;
