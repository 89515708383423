import { GatewayOutlined } from "@ant-design/icons";
import { Element, useEditor, useNode } from "@craftjs/core";
import { Col, Row } from "antd";
import { Form, Select } from "components/Form";
import IntlMessages from "util/IntlMessages";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting } from "./common";

export const SpPanel = ({...props}) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppPanel doRef={ref => connect(drag(ref))} selectedStyle={style} dbtn={dbtn} {...props}/>
  )
}

export const SppPanel = ({
  doRef, dbtn, selectedStyle, children,
  pageKey, itemKey, pageState, setPageState,
  justify, align, ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}
  return (
    <Col ref={doRef} {...props}>
      <Row justify={justify} align={align} >
        {children}
      </Row>
      {dbtn}
    </Col>
  );
}

const ROW_JUSTIFY = ["start", "end", "center", "space-around", "space-between"];
const ROW_ALIGN = ["top", "middle", "bottom"];

const SpPanelSetting = () => {
  return (
    <>
      <Form.Item name={["justify"]} label={"row justify"}>
        <Select className="item-property" allowClear>
          {ROW_JUSTIFY.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["align"]} label={"vertical align"}>
        <Select className="item-property" allowClear>
          {ROW_ALIGN.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  );
}
SppPanel.enablePageState = true;

SpPanel.craft = {
  displayName: "Panel",
  related: {
    settings: SpPanelSetting
  }
}

registerComponent({
  key:"SpPanel",
  component: SpPanel,
  runtimeComponent: SppPanel,
  template: <Element canvas is={SpPanel} itemKey={getId('panel')}
            className="sp-panel" span="24"/>,
  title: <IntlMessages id="system.page.library.panel" text="Panel" />,
  icon: <GatewayOutlined  className="react-icons icon-antd"/>,
  type: "Container",
});

export default SpPanel;