import { Element, useEditor, useNode } from "@craftjs/core";
import { Col, Switch } from "antd";
import { Form, Select } from "components/Form";
import { useLbl } from "lngProvider";
import { dataExplorer, dataPolicyApi } from "parse-api";
import { useEffect, useRef, useState } from "react";
import { FaChartPie } from "react-icons/fa";
import { CartesianGrid, Legend, Pie, PieChart, ResponsiveContainer, Tooltip,Cell } from 'recharts';
import { isEmpty, log, equals } from "util/algorithm";
import IntlMessages from "util/IntlMessages";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting } from "./common";
import { settingsSignal } from "../../../../util/signal";

export const SpChartPie = ({...props}) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppChartPie doRef={ref => connect(drag(ref))} selectedStyle={style} dbtn={dbtn} {...props}/>
  )
}

export const SppChartPie = ({
  doRef, dbtn, selectedStyle, pageKey, itemKey, pageState, setPageState,
  policy, preview, gradient, grid, legend, tootip,
  ...otherProps }) => {
  const { locale } = settingsSignal;
  const [data, setData] = useState();
  const lbl = useLbl(locale);
  const [dataPolicyParams, setDataPolicyParams] = useState(null);
  if (!preview) preview = false;
  const mounted = useRef();
  useEffect(()=>{
    mounted.current = true;
    return ()=>{
      mounted.current = false;
    }
  },[])
  useEffect(()=>{
    const fetchData = async () => {
      if (policy) {
        try {
          let params = null;
          if (dataPolicyParams) {
            params = dataPolicyParams;
          }
          const data = await dataPolicyApi.getDataPolicyData(preview, policy, "PieChart", locale, params);
          log("data", data);
          if (mounted.current) setData(data);
        } catch (e) {
          log("get data failed", e);
          if (mounted.current) setData(null);
        }
      } else {
        if (mounted.current) setData(null);
      }
    }
    fetchData();
  },[dataPolicyParams, locale, policy, preview])
  useEffect(() => {
    if (pageState) {
      const params = pageState[itemKey + "_params" ];
      if (params && !equals(dataPolicyParams, params)) {
        setDataPolicyParams(params);
      }
    }
  },[dataPolicyParams, itemKey, pageState])
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}

  const generateColor = (fills,index,i) => {
    if(fills[index % data?.fills.length] && Array.isArray(fills[index % data?.fills.length])){
      return fills[index % data?.fills.length][i % data?.fills[index].length]
    }else{
      return fills[index % data?.fills.length]
    }
  }

  return (
    <Col ref={doRef} {...props}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          {gradient &&
            <defs>
              {data?.dataKeys.map((key, index)=>{
                return (
                  <linearGradient key={"def"+key} id={"color"+key} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={data?.fills[index]} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={data?.fills[index]} stopOpacity={0} />
                  </linearGradient>
                )
              })}
            </defs>
          }
          {!isEmpty(grid) && <CartesianGrid strokeDasharray={grid} />}
          {tootip && <Tooltip />}
          {legend && <Legend verticalAlign="bottom" height={36}/>}
          {data?.dataKeys.map((key, index)=>{
            const isLast = data.dataKeys.length === (index + 1);
            return <Pie key={"area"+key} data={data?.data} label={isLast ? lbl(`user.data-policy.${policy}.${key}`, key) : null} type="monotone" dataKey={key} nameKey={data?.nameKey} cx="50%" cy="50%" innerRadius={data?.innerRadius[index]} outerRadius={data?.outerRadius[index]} fill={gradient ? `url(#color${key})` : data?.fills[index]} >
                  {data?.data.map((k,i)=>{
                    return <Cell key={`cell-${i}`} fill={generateColor(data?.fills,index,i)} />
                  })}
              </Pie>
          })}
        </PieChart>
      </ResponsiveContainer>
      {dbtn}
    </Col>
  );
}

const SpChartPieSetting = () => {
  const [policyOptions, setPolicyOptions] = useState([]);
  const mounted = useRef();
  useEffect(()=>{
    mounted.current = true;
    return ()=>{
      mounted.current = false;
    }
  },[])
  useEffect(()=>{
    const fetchData = async () => {
      const params = [{key:"dataTypes", operator:"=", value:"PieChart"}];
      const list = await dataExplorer.searchAll("SystemDataPolicy", params, "dataName");
      if (mounted.current) setPolicyOptions(list.map(p=>({value:p.dataKey,label:p.dataName})));
    }
    fetchData();
  },[])
  return (
    <>
      <Form.Item name="policy" label="policy" >
        <Select className="item-property" options={policyOptions} allowClear/>
      </Form.Item>
      <Form.Item name="preview" label="preview" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="legend" label="legend" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="tootip" label="tootip" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  );
}

SpChartPie.craft = {
  displayName: "Pie Chart",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpChartPieSetting
  }
}
SppChartPie.enablePageState = true;

registerComponent({
  key:"SpChartPie",
  component: SpChartPie,
  runtimeComponent: SppChartPie,
  template: <Element canvas is={SpChartPie} itemKey={getId('chartpie')} preview={true}
            className="sp-chart" span="24" style={{height:"300px"}}/>,
  title: <IntlMessages id="system.page.library.chartpie" text="Pie Chart" />,
  icon: <FaChartPie  className="react-icons icon-fa"/>,
  type: "Component",
  subtype: "chart",
  sequence: 4,
});

export default SpChartPie;