import React, { useEffect, useRef } from "react";
import { Route, Switch } from "react-router-dom";
import { log, notify } from "../util/algorithm";
import asyncComponent from "../util/asyncComponent";
import { systemSignal } from "../util/signal";

const App = ({ match }) => {
  const { headless } = systemSignal;
  const headlessRef = useRef();
  useEffect(() => {
    if (headless) {
      new ResizeObserver(function () {
        log("dimension changed:", headlessRef.current);
        notify('change-page-size', {width: headlessRef.current?.clientWidth, height: headlessRef.current?.clientHeight})
      }).observe(headlessRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="gx-main-content-wrapper" ref={headlessRef}>
      <Switch>
        <Route path={`${match.url}home`} component={asyncComponent(() => import('./HomePage'))} />
        <Route path={`${match.url}system`} component={asyncComponent(() => import('./system'))} />
        <Route path={`${match.url}page`} component={asyncComponent(() => import('./page'))} />
        <Route path={`${match.url}parse/files`} component={asyncComponent(() => import('./system/Widgets/DownloadFile'))} />
      </Switch>
    </div>
  )
};

export default App;
