import React from "react";
import { useDispatch } from "react-redux";

export const MenuIcon = ({sidebarCollapsed, setSidebarCollapsed, toggleCollapsedSideNav, navCollapsed}) => {
  const dispatch = useDispatch();
  return (
    <div className="menu-hamberger-icon">
      <div
        className="box"
        onClick={() => {
          if (setSidebarCollapsed) {
            setSidebarCollapsed(!sidebarCollapsed);
          } else if (toggleCollapsedSideNav) {
            dispatch(toggleCollapsedSideNav(!navCollapsed));
          }
        }}
      >
        <div className={sidebarCollapsed ? "btn not-active" : "btn active"}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
}

export default MenuIcon;