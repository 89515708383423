import antdEN from "antd/lib/locale-provider/en_US";
import enMessages from "../locales/tl_PH.json";

const IDLang = {
  messages: {
    ...enMessages
  },
  antd: antdEN,
  locale: 'tl-PH',
};
export default IDLang;
