import { Element, useEditor, useNode } from "@craftjs/core";
import { Col, Image, InputNumber, Switch } from "antd";
import { Form, Input } from "components/Form";
import { BiImage } from "react-icons/bi";
import IntlMessages from "util/IntlMessages";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting } from "./common";
import { prepareText } from "util/algorithm";

export const SpImage = ({...props}) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppImage doRef={ref => connect(drag(ref))} selectedStyle={style} dbtn={dbtn} {...props}/>
  )
}

export const SppImage = ({
  doRef, dbtn, selectedStyle, content, pageKey, itemKey,
  src, substitute, width, height, alt, preview,
  pageState, setPageState,
  ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}
  if (!preview) preview = false;

  let finalSrc = src;
  if (substitute) {
    finalSrc = prepareText(src, pageState);
  }
  return (
    <Col ref={doRef} {...props}>
      <Image src={finalSrc} width={width} height={height} alt={alt} preview={src && preview}/>
      {dbtn}
    </Col>
  );
}

const SpImageSetting = () => {
  return (
    <>
      <Form.Item name="src" label="src" className="src">
        <Input className="item-property" allowClear/>
      </Form.Item>
      <Form.Item name="substitute" label="substitute" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="alt" label="alt" className="alt">
        <Input className="item-property" allowClear/>
      </Form.Item>
      <Form.Item name="width" label="width" className="width">
        <InputNumber className="item-property" />
      </Form.Item>
      <Form.Item name="height" label="height" className="height">
        <InputNumber className="item-property" />
      </Form.Item>
      <Form.Item name="preview" label="preview" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  );
}
SppImage.enablePageState = true;

SpImage.craft = {
  displayName: "Image",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpImageSetting
  }
}

registerComponent({
  key:"SpImage",
  component: SpImage,
  runtimeComponent: SppImage,
  template: <Element canvas is={SpImage} itemKey={getId('image')} preview={true}
            className="sp-image" span="24"/>,
  title: <IntlMessages id="system.page.library.image" text="Image" />,
  icon: <BiImage  className="react-icons icon-bi"/>,
  type: "Component",
  sequence: 2,
});

export default SpImage;