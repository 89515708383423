import zhCN from 'antd/lib/locale-provider/zh_CN';
import zhCNMessages from "../locales/zh-Hans-CN.json";

const ZhLan = {
  messages: {
    ...zhCNMessages
  },
  antd: zhCN,
  locale: 'zh-Hans-CN',
};
export default ZhLan;
