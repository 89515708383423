import { Modal, Row } from "antd";
import { Form } from "components/Form";
import { useEffect, useRef } from "react";
import { convertStyleStr, getDefaultValidateMessage, persistentInfo, SfPanelContext } from "./common";
import { settingsSignal } from "../../../../util/signal";

const getDefaultPopupContainer = (trigger) => {
  const cntr = trigger?.parentElement.closest('.appframe-container');
  if (cntr) {
      return cntr;
  } else {
    return document.body;
  }
}

export const useResetFormOnCloseModal = ({ form, visible }) => {
  const prevVisibleRef = useRef();

  useEffect(() => {
    prevVisibleRef.current = visible;
  }, [visible]);
  const prevVisible = prevVisibleRef.current;
  useEffect(() => {
    if (!visible && prevVisible) {
      form.resetFields();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
};

export const ModalForm = ({
  formKey, itemKey, title, layout, labelAlign, labelColStr, colon, width,
  table, visible, disabled, onFinish, onCancel, childNodes, processNode, record,
  parentForm, centered, top }) => {
  const { locale } = settingsSignal;
  const [form] = Form.useForm();
  const inputref = useRef(null);
  useResetFormOnCloseModal({
    form,
    visible,
  });

  const onOk = () => {
    form.submit();
  };

  useEffect(() => {
    form.setFieldsValue({ ...record });
    form.validateFields();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record])

  const panelContext = { name: [], parentForm };
  const lcs = convertStyleStr(labelColStr);
  const validateMessages = getDefaultValidateMessage(locale);
  let modalStyle = null;
  if (top) {
    modalStyle = {
      top: top
    }
  }
  return (
    <Modal centered={centered} style={modalStyle}
      title={title} visible={visible} onOk={onOk} onCancel={onCancel}
      destroyOnClose={true} width={width} footer={disabled ? [] : undefined}
      getContainer={getDefaultPopupContainer}
      >
      <Form form={form}
        layout={layout} colon={!!colon} labelAlign={labelAlign} labelCol={lcs}
        validateMessages={validateMessages}
        name={`${formKey}.${itemKey}`} onFinish={onFinish}>
        <Row>
          {persistentInfo(table, panelContext.name)}
          {childNodes.map((n, index) => {
            if (index === 0) n.props.inputref = inputref;
            return (
              <SfPanelContext.Provider key={index} value={panelContext}>
                {processNode(n, form)}
              </SfPanelContext.Provider>
            )
          })}
        </Row>
      </Form>
    </Modal>
  );
};


