import { CopyOutlined, DeleteOutlined, EyeOutlined, FileAddOutlined, FolderOpenOutlined, ReloadOutlined, SaveOutlined } from "@ant-design/icons";
import { Element, Frame, useEditor } from "@craftjs/core";
import { Button, Col, Popconfirm, Popover, Switch, Tooltip, message } from "antd";
import { Form, Input, Select } from "components/Form";
import { system } from "parse-api";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { BiRedo, BiUndo } from "react-icons/bi";
import { MdDisabledVisible, MdMoreHoriz, MdOutlinePublic, MdOutlinePublicOff } from "react-icons/md";
import IntlMessages from "util/IntlMessages";
import { applyCss, compressJsonData, decompressJsonData, getArraysIntersection, getArraysSubtraction, getArraysUnion, isEmptyString, log } from "util/algorithm";
import { AccountStore } from "../../../constants/Account";
import { useLbl } from "../../../lngProvider";
import { copyToClipboard, delay, flattenJSON, getClipboardContent, getText, getVersionDesc, isEmpty, processForwardAttribute, registerPostMessageAction, uid, unregisterPostMessageAction, updateFlattenJSON } from "../../../util/algorithm";
import { EditorCollector, SfCanvas, SfMainPanel, SystemSelect, UserComponents, UserRuntimeComponents, getButtons, getId, patchRealId, permissionOptions, updateDisplayName, validateFormInfo } from "./components";
import { defaultCss } from "./defaultForm";
import { settingsSignal } from "../../../util/signal";

export const V_MASTER = 'master';
export const V_READONLY = 'read-only';

export const P_HIDDEN = 'hidden';
export const P_READONLY = 'read-only';
export const P_EDITABLE = 'editable';

const SKIP_PROPERTIES = ["convert to"];

const setValueByField = (props, field) => {
  const name = field.name;
  let obj = props;
  for (let i = 0; i < name.length; i++) {
    if (i === (name.length - 1)) {
      if (SKIP_PROPERTIES.indexOf(name[i]) === -1) {
        obj[name[i]] = field.value;
      }
    } else {
      if (obj[name[i]]) {
        obj = obj[name[i]];
      } else {
        obj[name[i]] = {};
        obj = obj[name[i]];
      }
    }
  }
}

const fieldsToActionProps = ({ props, changedFields, allFields, id }) => {
  updateDisplayName(props, id);
  if (changedFields.length > 0) {
    changedFields.forEach(field => {
      setValueByField(props, field);
    })
  } else {
    allFields.forEach(field => {
      setValueByField(props, field);
    })
  }
}

const actionPropsToFields = (form, selectedNode) => {
  const fields = [];
  const allFields = form.getFieldsValue(true);
  if (selectedNode) {
    fields.push({ name: ["selectedId"], value: `${selectedNode.displayName}` });
    Object.keys(allFields).forEach(field => {
      if (field !== "selectedId") {
        const val = selectedNode.props[field];
        fields.push({ name: [field], value: val });
      }
    });
    Object.keys(selectedNode.props).forEach(field => {
      const val = selectedNode.props[field];
      fields.push({ name: [field], value: val });
    });
  }
  return fields;
}

const LOGIC_ONLY = ['SfRule', 'SfSimpleSearch', 'SfAdvanceSearch', 'SfConditionalStyle', 'SfSubmit'];
const DISPLAY_ONLY = ['SfActionButton', 'SfStaticText', 'SfSteps'];

const CONVERT_MATRIX = {
  "SfInput": {
    options: [
      {value:"SfTextArea", label: "Text Area", toValues: ["textarea", "TextArea"]},
      {value:"SfSelect", label: "Select", toValues: ["select", "Select"]},
      {value:"SfDate", label: "Date", toValues: ["date", "Date"]},
    ],
    fromValues: ["input", "Input"]
  },
  "SfInputNumber": {
    options: [
      {value:"SfAutoIncrement", label: "Auto Increment", toValues: ["autoincrement", "AutoIncrement"]},
    ],
    fromValues: ["inputnumber", "InputNumber"]
  },
  "SfDate": {
    options: [
      {value:"SfSystemDate", label: "System Date", toValues: ["systemdate", "SystemDate"]},
    ],
    fromValues: ["date", "Date"]
  }
}
const KEYS = [
  "custom.displayName",
  "displayName",
  "props.className",
  "type.resolvedName",
];

const getPatched = (fromComponent, toComponent) => {
  const options = CONVERT_MATRIX[fromComponent]?.options;
  const fromValues = CONVERT_MATRIX[fromComponent]?.fromValues;
  const patches = {};
  if (options) {
    options.forEach(o => {
      if (o.value === toComponent) {
        for (let i = 0; i < fromValues.length; i++) {
          if (o.toValues.length > i && !isEmpty(o.toValues[i])) {
            patches[fromValues[i]] = o.toValues[i];
          }
        }
      }
    })
  }
  return patches;
}

const SettingsPanel = ({onChange, currentView}) => {
  const [form] = Form.useForm();
  const {query, actions, selectedNode } = useEditor(EditorCollector);
  const cssTextRef = useRef();
  const permissionOptions = [P_HIDDEN, P_READONLY, P_EDITABLE].map(v => ({value:v, label:v}));
  const selectedNodeIdRef = useRef()
  const [fields, setFields] = useState([])
  const copyDefaultCss = () => {
    form.setFields([
      { name: ["css"], value: defaultCss },
      { name: ["cssOverride"], value: true }
    ]);
  }

  useEffect(() => {
    if (selectedNode && selectedNodeIdRef.current !== selectedNode.id) {
      setFields(actionPropsToFields(form, selectedNode))
      selectedNodeIdRef.current = selectedNode.id;
    }
  }, [form, selectedNode])

  const onCssChange = (e) => {
    const css = form.getFieldValue(["css"]);
    const cssOverride = form.getFieldValue(["cssOverride"]);
    if (isEmptyString(css)) {
      form.setFields([
        { name: ["cssOverride"], value: false }
      ]);
      applyCss(defaultCss);
    } else {
      if (cssOverride) {
        applyCss(css);
      } else {
        applyCss(defaultCss+" "+css);
      }
    }
  }

  useEffect(() => {
    form.validateFields();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode])

  const onConvertTo = (toComponent) => {
    if (toComponent) {
      const json = query.serialize();
      const converted = JSON.parse(json, (key, value) => {
        if (key === selectedNode.id) {
          patchComponent(value, KEYS, getPatched(selectedNode.name, toComponent));
        }
        return value;
      })
      const convertedJson = JSON.stringify(converted);
      const selectedId = selectedNode.id;
      actions.deserialize(convertedJson);
      setTimeout(() => {
        actions.selectNode(selectedId);
      }, 500);
    }
  }

  const patchComponent = (value, keys, patches) => {
    const flatten = flattenJSON(value);
    const updates = {};
    for (const key in flatten) {
      if (keys.indexOf(key) !== -1) {
        const oriValue = flatten[key];
        for (const from in patches) {
          if (patches.hasOwnProperty(from)) {
            const to = patches[from];
            const newValue = oriValue.replace(from, to);
            updates[key] = newValue;
          }
        }
      }
    }
    updateFlattenJSON(value, updates);
  }

  return selectedNode ? (
    <>
      <Form form={form} size={"small"} name="formsetup.props" colon={false} fields={fields} onFieldsChange={(changedFields, allFields) => {
        try {
          const node = query.node(selectedNode.id).get();
          if (node) {
            actions.setProp(selectedNode.id, props => {
              fieldsToActionProps({ props, changedFields, allFields, id: selectedNode.id });
            });
          }
        } catch (e) {
          log(e);
        }
        if (onChange) onChange();
      }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item name="selectedId" label="name">
          <Input className="item-property" readOnly></Input>
        </Form.Item>
        <Form.Item name="convert to" label="convert" hidden={!CONVERT_MATRIX[selectedNode.name]}>
          <Select className="item-property" onChange={onConvertTo} allowClear
            options={CONVERT_MATRIX[selectedNode.name]?.options}></Select>
        </Form.Item>
        {selectedNode.id !== 'ROOT' &&
          <>
            <Form.Item name="itemKey" label="key" trigger={selectedNode.name === "SfTextEditor" ? "onBlur" : "onChange"}>
              <Input className="item-property"></Input>
            </Form.Item>
            {LOGIC_ONLY.indexOf(selectedNode.name) === -1 &&
              <>
                <Form.Item name="className" label="class">
                  <Input className="item-property"></Input>
                </Form.Item>
                <Form.Item name="styleStr" label="style">
                  <Input className="item-property"></Input>
                </Form.Item>
                <Form.Item name="permission" label="permission">
                  <Permission currentView={currentView} options={permissionOptions} className="item-property" placeholder="permission" allowClear intlPrefix="system.role."/>
                </Form.Item>
              </>
            }
            {LOGIC_ONLY.indexOf(selectedNode.name) === -1 &&
             DISPLAY_ONLY.indexOf(selectedNode.name) === -1 &&
              <>
                <Form.Item name="skipcopy" label="skip copy" valuePropName="checked">
                  <Switch className="item-property"/>
                </Form.Item>
                <Form.Item name="volitate" label="volatile" valuePropName="checked">
                  <Switch className="item-property"/>
                </Form.Item>
              </>
            }
          </>
        }
        {
          selectedNode.settings && React.createElement(selectedNode.settings, {currentView, actions, selectedNode})
        }
        {selectedNode.id === 'ROOT' &&
          <>
            <Form.Item name="css" label="css" className="css-text"
              labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} >
              <Input.TextArea ref={cssTextRef}
                className="item-property"
                autoSize allowClear bordered={false}
                onChange={onCssChange}
                />
            </Form.Item>
            <Form.Item name="cssOverride" label="css override" valuePropName="checked">
              <Switch className="item-property" onChange={onCssChange}/>
            </Form.Item>
            <Form.Item >
            <Button size="small" className="css-button" onClick={copyDefaultCss} ><Tooltip mouseEnterDelay={1} title={"copy default style template"}><CopyOutlined /></Tooltip></Button>
            </Form.Item>
          </>
        }
      </Form>
    </>
  ) : false;
};

const FormInfo = ({
  formData, onChange, isModified, allForms, allVersions,
  doSave, saveCount, doReload, doCopy, doAdd, doDelete, doPreview, doPublish, doUnpublish,
  currentView, setCurrentView,
  editable, setEditable, allActionPolicies, reportTemplates, exportTemplates }) => {
  const [form] = Form.useForm();
  const [isCopy, setIsCopy] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [allViews, setAllViews] = useState([V_MASTER, V_READONLY]);
  const [allRoles, setAllRoles] = useState([]);
  const locale = settingsSignal.locale;
  const lbl = useLbl(locale);
  const { actions, query, enabled, canUndo, canRedo } = useEditor((state, query) => {
    return {
      enabled: state.options.enabled,
      canUndo: query.history.canUndo(),
      canRedo: query.history.canRedo()
    }
  });
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    const fetchData = async () => {
      const roles = await system.getAllRoles();
      if (mounted.current) setAllRoles(roles);
    }
    fetchData();
    return () => {
      mounted.current = false;
    }
  }, [])

  useEffect(() => {
    if (formData) {
      if (form) {
        form.setFieldsValue({
          formKey: formData.formKey,
          savedFormKey: formData.formKey,
          formName: formData.formName,
          admins: formData.admins,
          adminRoles: formData.adminRoles,
          versionStamp: formData.versionStamp,
          versionRemarks: formData.versionRemarks,
          reportTemplate: formData.extraParams?.reportTemplate,
          exportTemplate: formData.extraParams?.exportTemplate,
          allowedRoles: formData.extraParams?.allowedRoles,
          viewPermissions: formData.extraParams?.viewPermissions,
          isPublished: formData.isPublished,
          actions: formData.extraParams?.actions,
        });
      }
      const data = formData.data;
      const json = decompressJsonData(data);
      if (json) {
        actions.history.ignore().deserialize(json);
      }
      let allViews = formData.extraParams?.allViews;
      if (!allViews) allViews = [V_MASTER, V_READONLY];
      setAllViews(allViews);
      if (mounted) setIsOpen(false);
    } else {
      if (mounted) setIsOpen(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData])

  useEffect(() => {
    if (saveCount > 0) {
      saveAction();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveCount])

  const getCurrentFormData = (initialData) => {
    if (initialData) {
      const {formKey, formName, data,
        objectId,
        extraParams, error} = initialData;
      const newFormData = {
        formKey, formName, data,
        objectId,
        extraParams, error
      }
      return newFormData;
    } else {
      const originalJson = query.serialize();
      const patchedJson = patchRealId(originalJson);
      const formKey = form.getFieldValue(["formKey"]);
      const formName = form.getFieldValue(["formName"]);
      const admins = form.getFieldValue(["admins"]);
      const adminRoles = form.getFieldValue(["adminRoles"]);
      const reportTemplate = form.getFieldValue(["reportTemplate"]);
      const exportTemplate = form.getFieldValue(["exportTemplate"]);
      const isPublished = form.getFieldValue(["isPublished"]);
      const allowedRoles = form.getFieldValue(["allowedRoles"]);
      const viewPermissions = form.getFieldValue(["viewPermissions"]);
      const actions = form.getFieldValue(["actions"]);
      const versionRemarks = form.getFieldValue(["versionRemarks"]);
      const versionStamp = form.getFieldValue(["versionStamp"]);
      const oldExtraParams = form.getFieldValue(["extraParams"]);

      let {error, extraParams, json} = validateFormInfo(patchedJson, {formKey, formName, versionStamp}, allViews, allActionPolicies);
      const selectedActions = (actions || []).map(action => {
        let selected = null;
        allActionPolicies.forEach(p => {
          if (p.actionKey === action) {
            selected = {
              actionKey: p.actionKey,
              // data: p.data,
              actionTypes: p.actionTypes,
            };
          }
        });
        if (!selected) {
          error = <IntlMessages id="system.form.validate.action-not-found" text="Unknown action policy ({action})." values={{action}}/>
        }
        return selected;
      });
      if (!error && extraParams.dataClassConfig.systemExport) {
        let hasSingleColUniqueKey = false;
        let keyIndex = null;
        if (extraParams.uniqueKeys) {
          for (const key in extraParams.uniqueKeys) {
            if (extraParams.uniqueKeys.hasOwnProperty(key)) {
              const unique = extraParams.uniqueKeys[key];
              if (unique?.keys && unique.keys.length === 1) {
                hasSingleColUniqueKey = true;
                keyIndex = unique.keys[0];
                break;
              }
            }
          }
        }
        if (!hasSingleColUniqueKey) {
          error = <IntlMessages id="system.form.validate.system-export-unique-key" text="System exportable form must contains a single column unique key!"/>
        } else {
          extraParams.dataClassConfig.keyIndex = keyIndex;
        }
      }

      const data = AccountStore.REACT_APP_COMPRESSDATA ? compressJsonData(json) : json;
      if (!extraParams) extraParams = {dataClassConfig:{}};
      extraParams.reportTemplate = reportTemplate;
      extraParams.exportTemplate = exportTemplate;
      extraParams.allViews = allViews;
      extraParams.allowedRoles = allowedRoles;
      extraParams.viewPermissions = viewPermissions;
      extraParams.actions = actions;
      extraParams.selectedActions = selectedActions;
      extraParams.dataClassConfig.actions = actions;
      extraParams.dataClassConfig.selectedActions = selectedActions;
      const newFormData = {
        formKey, formName, admins, adminRoles, data, isPublished,
        objectId:formData?.objectId,
        extraParams: {...oldExtraParams, ...extraParams}, error,
        versionRemarks, versionStamp
      }
      return newFormData;
    }
  }

  const saveAction = () => {
    const currentFormData = getCurrentFormData();
    const error = currentFormData.error;
    const isNew = !formData.objectId || isCopy;
    if (error) {
      message.error(error);
    } else {
      doSave(currentFormData, isNew);
    }
  };

  const copyAction = () => {
    if (mounted.current) setIsCopy(true);
    if (mounted.current) setIsOpen(false);
    form.setFieldsValue({ formKey: "", savedFormKey: "", formName: "" });
  }

  const openAction = () => {
    if (mounted.current) setIsOpen(true);
    if (mounted.current) setIsCopy(false);
    if (mounted.current) setEditable(false);
    form.setFieldsValue({ formKey: "", savedFormKey: "", formName: "" });
  }

  const addAction = () => {
    doAdd();
  }

  const reloadAction = () => {
    doReload();
  }

  const deleteAction = () => {
    const savedFormKey = form.getFieldValue(["savedFormKey"]);
    if (savedFormKey) {
      doDelete(formData);
    }
  }

  const previewAction = (e) => {
    const formKey = form.getFieldValue(["formKey"]);
    const currentView = form.getFieldValue(["currentView"]);
    if (doPreview) {
      doPreview(formKey, currentView, e);
    }
  }

  const onAddView = (value) => {
    const newAllViews = getArraysUnion(allViews, [value]);
    if (mounted.current) setAllViews(newAllViews);
  }

  const deleteViewAction = () => {
    if (currentView === V_MASTER || currentView === V_READONLY) {
      message.error(lbl('system.form.error.cannot-delete-view', "This view is not allowed to delete."));
    } else {
      const newAllViews = getArraysSubtraction(allViews, [currentView]);
      if (mounted.current) setAllViews(newAllViews);
      form.setFieldsValue({  currentView: "" });
    }
  }

  const onFieldsChange = (values) => {
    if (Array.isArray(values) && values.length > 0 &&
        Array.isArray(values[0].name) && values[0].name.length > 0 &&
        values[0].name[0] === 'currentView') {
      return;
    } else {
      if (onChange) {
        onChange(values);
      }
    }
  }

  const publishAction = () => {
    const data = formData.data;
    const json = decompressJsonData(data);
    const nodeMap = JSON.parse(json);
    let error = null;
    for (const key in nodeMap) {
      if (nodeMap.hasOwnProperty(key)) {
        const node = nodeMap[key];
        if (node.props.selectkey) {
          if (node.props.selectkey.match(/^UfxPreview/)) {
            error = "Select key is preview value. Cannot be published! - item key ("+node.props.itemKey+")";
            break;
          }
        }
      }
    }
    if (error) {
      message.error(error);
    } else {
      doPublish();
    }
  }

  const unpublishAction = () => {
    doUnpublish();
  }

  const unpublishAllAction = () => {
    doUnpublish({all:true});
  }

  const ActionButton = ({id, title, confirm, onClick, ...props}) => {
    if (confirm) {
      return <Popconfirm
        title={<IntlMessages id={`system.form.confirm.${id}`} text={confirm} />}
        onConfirm={onClick}
        okText={<IntlMessages id="system.confirm.yes" text="Yes" />}
        cancelText={<IntlMessages id="system.confirm.no" text="No" />}
      >
        <Tooltip mouseEnterDelay={1} title={<IntlMessages id={`system.form.action-button-${id}`} text={title} />}>
          <Button size="medium" className={`forminfo-btn ${id}`} {...props} />
        </Tooltip>
      </Popconfirm>
    } else {
    return <Tooltip mouseEnterDelay={1} title={<IntlMessages id={`system.form.action-button-${id}`} text={title} />}>
      <Button size="medium" className={`forminfo-btn ${id}`} onClick={onClick} {...props}/>
    </Tooltip>
    }
  }

  const ACTION_TYPES = ["OnChangeAction", "OnChangeActionFrontEnd", "PreSearchAction", "PostSearchAction", "PreGetAction", "PostGetAction",
    "PreSaveAction", "PostSaveAction", "PreDeleteAction", "PostDeleteAction", "FormSetupAction", "ImportDataAction",
    "PreImportDataActionFrontEnd", "PostExportDataActionFrontEnd"];
  const policyOptions = allActionPolicies?.filter(p => getArraysIntersection(p.actionTypes, ACTION_TYPES).length > 0).map((r) => (
    {value: r.actionKey, label: lbl("form.action." + r.actionKey, r.actionName)}
  ))
  return (
    <>
      <Form
        name="formsetup.info"
        form={form}
        labelAlign="left"
        size="small"
        colon={false}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        requiredMark={false}
        onFinish={saveAction}
        onFieldsChange={onFieldsChange}
      >
        {(isOpen || (formData?.objectId && !isCopy)) &&
          <Form.Item name="savedFormKey" label="form"
            rules={[{ required: true, message: <IntlMessages id="system.form.rules.mandatory.formKey" text="Please provide a form ID!" /> }]}
          >
            <Select className="savedFormKey" onChange={(value) => doReload(value)} showSearch>
              {allForms?.map(c => <Select.Option key={c.formKey} value={c.formKey}>{c.formKey}</Select.Option>)}
            </Select>
          </Form.Item>
        }
        {(!isOpen && (!formData?.objectId || isCopy)) &&
          <Form.Item name="formKey" label="form"
            rules={[{ required: true, message: <IntlMessages id="system.form.rules.mandatory.formKey" text="Please provide a form ID!" /> }]}
          >
            <Input className="formKey" readOnly={!editable} />
          </Form.Item>
        }
        <Form.Item name="formName" label="name" hidden={!editable}
          rules={[{ required: true, message: <IntlMessages id="system.form.rules.mandatory.formName" text="Please provide a form name!" /> }]}
        >
          <Input className="formName" readOnly={!editable}></Input>
        </Form.Item>
        <Form.Item name="admins" label="admins" hidden={!editable || isCopy}>
          <SystemSelect className="admins" selectkey="User" isUseCode={true} mode="multiple" showSearch allowClear/>
        </Form.Item>
        <Form.Item name="adminRoles" label="admin roles" hidden={!editable || isCopy}>
          <SystemSelect className="adminRoles" selectkey="Role" isUseCode={true} mode="multiple" showSearch allowClear/>
        </Form.Item>
        <Form.Item name="versionRemarks" label="remarks" hidden={!editable}>
          <Input className="versionRemarks" readOnly={!editable}></Input>
        </Form.Item>
        <Form.Item name="versionStamp" label="version" hidden={!editable || isCopy}>
          <Select className="versionStamp" onChange={(value) => doReload(formData.formKey, value)}>
            {allVersions.map(v => <Select.Option key={v.versionStamp} value={v.versionStamp}>{getVersionDesc(v)}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item name="currentView" label="view" hidden={!editable || isCopy}>
          <Select className="currentView" onAddItem={onAddView} onChange={setCurrentView} options={allViews.map(v=>({value:v,label:v}))} allowClear>
          </Select>
        </Form.Item>
        <Form.Item name="allowedRoles" label="roles" hidden={!editable || isCopy}>
          <AllowedRole className="allowedRoles" currentView={currentView} allowClear>
          {allRoles?.map((r) => (
            <Select.Option key={r.name} value={r.name}><IntlMessages id={"system.role." + r.name} text={r.name} /></Select.Option>
          ))}
          </AllowedRole>
        </Form.Item>
        <Form.Item name="viewPermissions" label="view perm." hidden={!editable || isCopy}>
          <ViewPermission className="viewPermissions" currentView={currentView} allowClear>
          </ViewPermission>
        </Form.Item>
        <Form.Item name="reportTemplate" label="report" hidden={!editable || isCopy}>
          <ReportTemplate className="reportTemplate" currentView={currentView} readOnly={!editable} templates={reportTemplates}></ReportTemplate>
        </Form.Item>
        <Form.Item name="exportTemplate" label="export" hidden={!editable || isCopy}>
          <ReportTemplate className="exportTemplate" currentView={currentView} readOnly={!editable} templates={exportTemplates}></ReportTemplate>
        </Form.Item>
        <Form.Item name="actions" label="actions" hidden={!editable || isCopy}>
          <Select className="actions" allowClear mode="multiple" dropdownMatchSelectWidth={false} options={policyOptions}/>
        </Form.Item>
        <Form.Item name="isPublished" label="published" valuePropName="checked" hidden={!editable || isCopy}>
          <Switch className="item-property" disabled/>
        </Form.Item>

        <div className="forminfo-btn-bar">
          <ActionButton disabled={!editable || !formData?.objectId || isCopy} onClick={copyAction} id="copy" title="Copy"><CopyOutlined /></ActionButton>
          {!isOpen && <ActionButton disabled={!editable} onClick={openAction} id="open" title="Open"><FolderOpenOutlined /></ActionButton>}
          {isOpen && <ActionButton onClick={addAction} id="add" title="New" ><FileAddOutlined /></ActionButton>}
          <ActionButton disabled={!editable || isOpen} id="save" title="Save" htmlType="submit"><SaveOutlined /></ActionButton>
          {!currentView && <ActionButton danger disabled={isCopy || !formData?.objectId} id="delete" title="Delete" onClick={deleteAction} confirm="Are you sure to delete this form?" ><DeleteOutlined /></ActionButton>}
          {currentView && <ActionButton danger disabled={currentView === V_MASTER || currentView === V_READONLY} id="delete" title="Delete" onClick={deleteViewAction} confirm="Are you sure to delete this view?"><DeleteOutlined /></ActionButton>}
          <ActionButton disabled={!editable} onClick={reloadAction} id="reload" title="Reload"><ReloadOutlined /></ActionButton>
          <ActionButton onClick={() => actions.history.undo()} disabled={!enabled || !canUndo} id="undo" title="Undo"><BiUndo /></ActionButton>
          <ActionButton onClick={() => actions.history.redo()} disabled={!enabled || !canRedo} id="redo" title="Redo"><BiRedo /></ActionButton>
          <ActionButton disabled={!formData?.objectId} id="preview" title="Preivew" onClick={previewAction}><EyeOutlined /></ActionButton>
          {!formData?.isPublished && <ActionButton id="publish" title="Publish" onClick={publishAction} confirm="Are you sure to publish this form?"><MdOutlinePublic /></ActionButton>}
          {formData?.isPublished && <ActionButton id="republish" title="Republish" onClick={publishAction} confirm="Are you sure to re-publish this form?"><MdOutlinePublic /></ActionButton>}
          <ActionButton disabled={!formData?.isPublished} id="unpublish" title="Unpublish" onClick={unpublishAction} confirm="Are you sure to unpublish this form?"><MdOutlinePublicOff /></ActionButton>
          <ActionButton id="unpublishall" title="Unpublish All" onClick={unpublishAllAction} confirm="Are you sure to unpublish all versions?"><MdDisabledVisible /></ActionButton>
        </div>
      </Form>
    </>
  );
}

const serializeNodeTree = (node, nodeTree) => {
  const patchedId = {};
  const nodes = {};
  const rootId = nodeTree.rootNodeId;
  for (const id in nodeTree.nodes) {
    const newId = id + '_' + uid();
    patchedId[id] = newId;
    const current = node(id);
    nodes[newId] = current.toSerializedNode();
  }
  for (const id in nodeTree.nodes) {
    const newId = patchedId[id];
    const current = nodes[newId];
    current.nodes = current.nodes?.map(i => patchedId[id] || id)
    current.parent = patchedId[current.parent];
  }
  return {rootNodeId: patchedId[rootId], nodes};
}

const deserializeNodeTree = async ({dataNodeTree, parent, index, add, selectNode, parseReactElement, node}) => {
  try {
    const idMap = {};
    let rootItemKey = null;
    let rootNodeId = null;
    for (const nodeId in dataNodeTree.nodes) {
      const n = dataNodeTree.nodes[nodeId];
      const name = n.type.resolvedName;
      if (name !== 'Container') {
        const props = n.props;
        if (!rootItemKey && props.itemKey) {
          rootItemKey = props.itemKey;
        }
        const pid = idMap[n.parent] || parent;
        const data = {name, props};
        const newNodeId = createNode({data, node, add, parseReactElement, parent: pid, index})
        if (!rootNodeId) {
          rootNodeId = newNodeId;
        }
        await delay(100);
        const newNode = node(newNodeId);
        const linkedNodes = newNode.linkedNodes();
        if (linkedNodes?.length > 0) {
          idMap[nodeId] = linkedNodes[0];
        } else {
          idMap[nodeId] = newNode.get().id;
        }
      } else {
        idMap[nodeId] = idMap[n.parent];
      }
    }
    if (selectNode && rootNodeId) selectNode(rootNodeId);
    return rootItemKey;
  } catch (error) {
    console.log('deserializeNodeTree() failed', error)
  }
}

const sendNodeToClipboard = ({selected, node}) => {
  const self = node(selected.id);
  const { data: {name, props}} = self.get();
  const nodeTree = serializeNodeTree(node, self.toNodeTree());
  const craftSelectedData = {name, props}
  copyToClipboard({text: JSON.stringify({craftSelectedData, nodeTree})})
  return props?.itemKey;
}

const CONTAINERS = ['Container']
const findContainer = ({self, node}) => {
  try {
    const ancestors = self.ancestors();
    const childs = self.linkedNodes();
    const selfId = self.get().id;
    const candidates = [...ancestors, ...childs]
    let container = null;
    let index = -1;
    for (const candidateId of candidates) {
      const candidate = node(candidateId)?.get();
      if (CONTAINERS.includes(candidate.data.name)) {
        container = candidateId;
        if (candidate.data?.nodes?.indexOf) {
          const originalIndex = candidate.data.nodes.indexOf(selfId);
          if (originalIndex !== -1) {
            index = originalIndex + 1;
          }
        }
        break;
      }
    }
    if (!container) {
      console.log('findContainer() not found', {self, node})
    }
    return {container, index};
  } catch (error) {
    console.log('findContainer() not found', {self, node, error})
    return null;
  }
}

const createNode = ({data, add, parseReactElement, parent, index}) => {
  const comp = UserComponents[data.name];
  if (comp) {
    const reactNode = React.createElement(comp, data.props);
    const nodeTree = parseReactElement(reactNode).toNodeTree();
    const rootNodeId = nodeTree.rootNodeId;
    const rootNode = nodeTree.nodes[rootNodeId];
    if (index > 0) {
      add(rootNode, parent, index)
    } else {
      add(rootNode, parent)
    }
    return rootNodeId;
  }
}

const createNodeFromClipboard = async ({add, selectNode, parseReactElement, node, selected}) => {
  try {
    const self = node(selected.id);
    let {container: parent, index} = findContainer({self, node})

    const content = await getClipboardContent();
    console.log('paste content', content);
    const dataNodeTree = JSON.parse(content)?.nodeTree;
    if (dataNodeTree && parent) {
      return await deserializeNodeTree({dataNodeTree, parent, index, add, selectNode, parseReactElement, node})
    }
  } catch (error) {
    console.log('ignore paste action', error)
  }
}

function isActiveElementBody() {
  if (document.activeElement === document.body) return true;
  if (document.activeElement?.closest('.craft-main-panel-left')) return true;

  return false;
}

function doCopy({selected, node, lbl}) {
  if (selected) {
    const itemKey = sendNodeToClipboard({selected, node});
    if (itemKey) {
      message.info(lbl("system.form.copied_to_clipboard", "{itemKey} copied to clipboard.", {itemKey}));
    }
  }
}

async function doPaste({add, selectNode, parseReactElement, node, selected, lbl}) {
  const itemKey = await createNodeFromClipboard({add, selectNode, parseReactElement, node, selected});
  if (itemKey) {
    message.info(lbl("system.form.pasted_from_clipboard", "{itemKey} pasted from clipboard.", {itemKey}));
  }
}

const TheFrame = ({ form, showProperty }) => {
  const { selectedNode, actions: {add, selectNode}, query: { node, parseReactElement } } = useEditor(EditorCollector);
  const prevSelectedNode = useRef();
  const { locale } = settingsSignal;
  const lbl = useLbl(locale);

  const onKeyDown = useCallback((e) => {
    if (isActiveElementBody()) {
      const selected = prevSelectedNode.current;
      if (e.ctrlKey === true && e.key === 'c') {
        doCopy({selected, node, lbl})
      } else if (e.ctrlKey === true && e.key === 'v') {
        doPaste({add, selectNode, parseReactElement, node, selected, lbl})
      }
    }
  }, [node, lbl, add, selectNode, parseReactElement])

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    }
  }, [onKeyDown])

  useEffect(() => {
    if (prevSelectedNode.current !== selectedNode) {
      if (selectedNode) showProperty();
    }
    prevSelectedNode.current = selectedNode;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedNode])

  const css = `
  `
  return (
    <Frame>
      <Element is={SfCanvas} className="sf-canvas" layout="horizontal" labelAlign="left" size="small" form={form} requiredMark={true} colon={true} css={css} scrollToFirstError={true} canvas>
        <SfMainPanel itemKey={"main"} table={getId('TempTable')} formKey={getId('form')} className="sf-panel" title={"Form"} />
      </Element>
    </Frame>
  );
}

export const setupForm = ({ data, preview, formView, buttonBar, form, onFinish, onChange, setIsSubmitting, onSubmitButtonClicked, disabled, formState, fireStateEvent }) => {
  let json = data;
  let nodeMap;
  if (data && typeof data === 'string') {
    try {
      json = decompressJsonData(data);
      nodeMap = JSON.parse(json);
    } catch (e) {
      nodeMap = JSON.parse(data);
    }
  } else {
    nodeMap = data;
  }
  if (disabled) {
    nodeMap = JSON.parse(JSON.stringify(nodeMap), (key, value) => {
      if (key === 'permission') {
        if (value && value[formView] && value[formView] === P_EDITABLE) {
          return P_READONLY;
        }
      }
      return value;
    })
  }
  if (json && nodeMap) {
    const node = nodeMap["ROOT"];
    return processNode({ nodeMap, node, preview, formView, buttonBar, form, onFinish, onChange, setIsSubmitting, onSubmitButtonClicked, disabled, formState, fireStateEvent });
  } else {
    return null;
  }
}

const processNode = ({ nodeMap, node, preview, formView, buttonBar, form, onFinish, onChange, setIsSubmitting, onSubmitButtonClicked, disabled, formState, fireStateEvent }) => {
  const type = node.type.resolvedName;
  const UC = UserRuntimeComponents[type];
  const props = node.props ? node.props : {};
  props.form = form;
  props.formview = formView;
  props.selectedId = undefined;
  delete props.selectedId;
  if (type === "SfCanvas") {
    props.onFinish = onFinish;
    props.onFieldsChange = onChange;
    props.setIsSubmitting = setIsSubmitting;
  } else if (type === 'SfTable') {
    props.onRowChange = onChange;
  }
  if (UC) {
    if (UC.enableStateEvent) {
      props.fireStateEvent = fireStateEvent;
    }
    const collectTags = UC.collector ? UC.collector() : null
    const nodeIds = getChildNodes({ node, nodeMap, collectTags: collectTags });
    const nodes = nodeIds.map(id => nodeMap[id]);
    const callProcessChildNode = (childNode, subform) => {
      return processChildNode({ nodeMap, node: childNode, preview, formView, form: subform ? subform : form, onSubmitButtonClicked, disabled, formState, onChange, fireStateEvent });
    }
    const callProcessNode = (node, subform) => {
      return processNode({ nodeMap, node: node, preview, formView, form: subform ? subform : form, onSubmitButtonClicked, disabled, formState, onChange, fireStateEvent })
    }
    let visible = true;
    let editable = true;
    if (formView === V_MASTER) {
      // all permission
    } else if (formView === V_READONLY) {
      editable = false;
    } else if (props.permission) {
      const perm = props.permission[formView];
      if (perm === P_HIDDEN) {
        visible = false;
        editable = false;
      } else if (perm === P_READONLY) {
        editable = false;
      } else if (perm === P_EDITABLE) {
        disabled = false;
      }
    }
    if (!editable || disabled) {
      props.disabled = true;
    }
    if (type === 'SfActionButton') {
      props.onSubmitButtonClicked = onSubmitButtonClicked;
    }
    if (visible) {
      if (!editable) disabled = true;
      processForwardAttribute(props, formState, UC.forwardAttributes);
      if (collectTags) {
        nodes.forEach(n => processForwardAttribute(n.props, formState))
        return <UC key={node.id} childNodes={nodes} processNode={callProcessNode} processChildNode={callProcessChildNode} {...props}>{processChildNode({ nodeMap, node, preview, formView, form, collectTags: collectTags, onSubmitButtonClicked, disabled, formState, onChange, fireStateEvent })}{buttonBar}</UC>
      } else {
        return <UC key={node.id} {...props}>{processChildNode({ nodeMap, node, preview, formView, form, collectTags: collectTags, onSubmitButtonClicked, disabled, formState, onChange, fireStateEvent })}{buttonBar}</UC>
      }
    } else {
      return null;
    }
  }
}

const getChildNodes = ({ node, nodeMap, collectTags }) => {
  const nodes = node.nodes;
  const linkedNodes = Object.values(node.linkedNodes);
  if (collectTags) {
    const includesNodes = collectTags;
    let children = getChildNodes({ node });
    const matchedChildren = [];

    let level = 0;
    while (children && children.length > 0) {
      if (level > 10) {
        break;
      }
      const newChildren = [];
      children.forEach(c => {
        const n = nodeMap[c];
        if (includesNodes.indexOf(n.type.resolvedName) !== -1) {
          matchedChildren.push(c);
        }
        newChildren.push.apply(newChildren, getChildNodes({ node: n }));
      });
      level++;
      children = newChildren;
    }
    return matchedChildren;
  } else if (nodes && nodes.length > 0) {
    return nodes;
  } else if (linkedNodes && linkedNodes.length > 0) {
    return linkedNodes;
  } else {
    return [];
  }
}

const processChildNode = ({ nodeMap, node, preview, formView, form, collectTags, onSubmitButtonClicked, disabled, formState, onChange, fireStateEvent }) => {
  const nodes = getChildNodes({ node, nodeMap, collectTags })
  if (nodes && nodes.length > 0) {
    return (<>
      {nodes.map(id => {
        const n = nodeMap[id];
        return <React.Fragment key={id}>{processNode({ nodeMap, node: n, preview, formView, form, onSubmitButtonClicked, disabled, formState, onChange, fireStateEvent })}</React.Fragment>;
      })}
    </>);
  } else {
    return false;
  }
}

export const Library = ({currentView}) => {
  const [filter, setFilter] = useState("");
  const locale = settingsSignal.locale;
  const lbl = useLbl(locale);
  const { connectors } = useEditor();
  const doFilter = (button) => {
    if (isEmpty(filter)) {
      return true;
    } else {
      return getText(button?.title, "text")?.toUpperCase().indexOf(filter.toUpperCase()) !== -1;
    }
  }
  return (
    <div className="craft-library">
      <Input size="small" placeholder={lbl("system.form.button-group.filter","filter")} onChange={(e) => {
        setFilter(e.target.value);
      }}/>
      <span className="system-form-lib-title">
        <IntlMessages id="system.form.button-group.container" text="Container"/>
      </span>
      {getButtons("Container").filter(doFilter).map(b => {
        return (
          <Tooltip mouseEnterDelay={1} key={b.key} title={b.title}><Button size="small" className="library-btn" ref={(ref) => connectors.create(ref, b.template)}>{b.icon}</Button></Tooltip>
        );
      })}
      <span className="system-form-lib-title">
        <IntlMessages id="system.form.button-group.component" text="Widget"/>
      </span>
      {getButtons("Component").filter(doFilter).map(b => {
        return (
          <Tooltip mouseEnterDelay={1} key={b.key} title={b.title}><Button size="small" className="library-btn" ref={(ref) => connectors.create(ref, b.template)}>{b.icon}</Button></Tooltip>
        );
      })}
      <span className="system-form-lib-title">
        <IntlMessages id="system.form.button-group.system-component" text="System"/>
      </span>
      {getButtons("Component-sys").filter(doFilter).map(b => {
        return (
          <Tooltip mouseEnterDelay={1} key={b.key} title={b.title}><Button size="small" className="library-btn" ref={(ref) => connectors.create(ref, b.template)}>{b.icon}</Button></Tooltip>
        );
      })}
      <span className="system-form-lib-title">
        <IntlMessages id="system.form.button-group.logic" text="Logic"/>
      </span>
      {getButtons("Logic").filter(doFilter).map(b => {
        return (
          <Tooltip mouseEnterDelay={1} key={b.key} title={b.title}><Button size="small" className="library-btn" ref={(ref) => connectors.create(ref, b.template)}>{b.icon}</Button></Tooltip>
        );
      })}
    </div>
  );
};

const doPermissionChange = (value, onChange, currentView, permission) => {
  if (value) {
    const newValue = { ...value };
    newValue[currentView] = permission;
    if (onChange) onChange(newValue);
  } else {
    const newValue = {};
    newValue[currentView] = permission;
    if (onChange) onChange(newValue);
  }
}

const Permission = ({value, onChange, currentView, options, ...props}) => {
  const disabled = (!currentView || currentView === V_MASTER || currentView === V_READONLY);
  let permission = null;

  if (currentView === V_MASTER) {
    permission = P_EDITABLE;
  } else if (currentView === V_READONLY) {
    permission = P_READONLY;
  } else if (value && currentView) {
    permission = value[currentView];
  }

  const onPermissionChange = (permission) => {
    doPermissionChange(value, onChange, currentView, permission);
  }

  return (
    <Select disabled={disabled} value={permission} onChange={onPermissionChange} options={options} {...props}>
    </Select>
  );
}

const AllowedRole = ({value, onChange, currentView, options, ...props}) => {
  const disabled = (!currentView);
  let role = undefined;

  if (!currentView) {
    role = undefined;
  } else if (value) {
    role = value[currentView];
  }

  const onRoleChange = (permission) => {
    doPermissionChange(value, onChange, currentView, permission);
  }

  return (
    <Select disabled={disabled} value={role} mode="multiple" onChange={onRoleChange} options={options} {...props}>
    </Select>
  );
}

const ViewPermission = ({value, onChange, currentView, ...props}) => {

  const disabled = (!currentView);
  let viewPermission = undefined;

  if (!currentView) {
    viewPermission = undefined;
  } else if (value) {
    viewPermission = value[currentView];
  }

  const onPermissionChange = (permission) => {
    doPermissionChange(value, onChange, currentView, permission);
  }

  return (
    <Select disabled={disabled} value={viewPermission} mode="multiple" onChange={onPermissionChange} options={[...permissionOptions, {"label":"denyall", "value":"denyall"}]} {...props}>
    </Select>
  );
}

const ViewText = ({value, onChange, currentView, ...props}) => {

  const disabled = (!currentView);
  let viewText = undefined;

  if (!currentView) {
    viewText = undefined;
  } else if (value) {
    viewText = value[currentView];
  }

  const onInputChange = (event) => {
    doPermissionChange(value, onChange, currentView, event.target.value);
  }
  return (
    <Input disabled={disabled} value={viewText} onChange={onInputChange} {...props}>
    </Input>
  );
}

const ReportTemplate = ({value, onChange, currentView, templates, ...props}) => {
  const disabled = (!currentView);
  let reportTemplate = undefined;

  if (!currentView) {
    reportTemplate = undefined;
  } else if (value) {
    reportTemplate = value[currentView];
  }

  const onReportChange = (e) => {
    if (value) {
      const newValue = { ...value };
      newValue[currentView] = e?.target?.value || e;
      if (onChange) onChange(newValue);
    } else {
      const newValue = {};
      newValue[currentView] = e?.target?.value || e;
      if (onChange) onChange(newValue);
    }
  }

  if (templates) {
    const options = templates.map(t => ({value: t.reportKey, label: t.reportKey}));
    return (
      <Select value={reportTemplate} onChange={onReportChange} options={options} disabled={disabled || props.disabled} {...props} allowClear showSearch></Select>
    );
  } else {
    return (
      <Input value={reportTemplate} onChange={onReportChange} disabled={disabled || props.disabled} {...props}></Input>
    );
  }
}

export const ActionButton = ({
  id,
  label,
  labelText,
  title,
  confirm,
  onClick,
  lbl,
  ...props
}) => {
  const buttonRef = useRef();
  if (props.disabled) {
    unregisterPostMessageAction(`form-${id}`);
  } else {
    registerPostMessageAction(
      `form-${id}`,
      lbl(label, labelText),
      () => {
        if (buttonRef.current?.click) {
          log("click button ", buttonRef.current?.click);
          buttonRef.current.click();
        } else {
          log("button action not found", `form-${id}`);
        }
      },
      true
    );
  }
  if (!isEmpty(confirm)) {
    return (
      <Popconfirm
        title={<IntlMessages id={`system.form.confirm.${id}`} text={confirm} />}
        onConfirm={onClick}
        okText={<IntlMessages id="system.confirm.yes" text="Yes" />}
        cancelText={<IntlMessages id="system.confirm.no" text="No" />}
        disabled={props.disabled}
      >
        <Button
          data-id-value={id}
          ref={buttonRef}
          size="small"
          className={`forminfo-btn ${id}`}
          {...props}
        >
          <IntlMessages id={label} text={labelText} />
        </Button>
      </Popconfirm>
    );
  } else {
    return (
      <Button
        data-id-value={id}
        ref={buttonRef}
        size="small"
        className={`forminfo-btn ${id}`}
        onClick={onClick}
        {...props}
      >
        <IntlMessages id={label} text={labelText} />
      </Button>
    );
  }
};

export const createButtonBarForVo = ({
  ref,
  record,
  authUserObj,
  formData,
  flow,
  currentTask,
  checkButtonBar,
  lbl,
  isSubmitting,
  doToggleEdit,
  checkIsSubstituteAction,
  formKey,
  doPrint,
  doExport,
  doClose,
  reportTemplate,
  exportTemplate,
  doShowHistory,
  doReload,
  isClosable,
}) => {
  let guiUid = ref.current;
  if (!guiUid) {
    guiUid = uid();
    ref.current = guiUid;
  }
  const buttonBarStyle = null;
  const isActionee =
    (!record?.flow?.assignee ||
      record?.flow?.assignee === authUserObj?.username) &&
    formData.allActions.length > 0;
  let buttonBarClasses = "button-bar";
  if (formData?.extraParams?.affixButtonBar) {
    buttonBarClasses += " my-affix fixed";
  }
  const buttonBarInner = (
    <>
      {(!flow || (currentTask?.allowSave && isActionee)) &&
        record.objectId &&
        checkButtonBar("update") && (
          <ActionButton
            lbl={lbl}
            id="edit"
            label="system.form.edit"
            labelText="Edit"
            className="save-btn"
            disabled={isSubmitting["any"]}
            loading={isSubmitting["save"]}
            onClick={doToggleEdit}
          />
        )}
      {formData.allSubmits
        .filter(
          (s) =>
            !s.hidden &&
            checkIsSubstituteAction(s.subsitute, ["print", "export", "close"])
        )
        .map((s) => {
          return (
            <ActionButton
              lbl={lbl}
              id={s.itemKey}
              label={`form.${formKey}.action.${s.itemKey}`}
              labelText={s.title}
              className={`${s.buttonClass} ${s.itemKey}-btn`}
              key={s.itemKey}
              disabled={isSubmitting["any"]}
              loading={isSubmitting[s.itemKey]}
              confirm={s.confirm}
              onClick={() => {
                log("do submit", s);
                if (checkIsSubstituteAction(s.subsitute, "print")) {
                  doPrint();
                } else if (checkIsSubstituteAction(s.subsitute, "export")) {
                  doExport();
                } else if (checkIsSubstituteAction(s.subsitute, "close")) {
                  doClose();
                }
              }}
            />
          );
        })}
      {record.objectId && checkButtonBar("print") && reportTemplate && (
        <ActionButton
          lbl={lbl}
          id="print"
          label="system.form.print"
          labelText="Print"
          className="print-btn"
          onClick={doPrint}
          disabled={isSubmitting["any"]}
          loading={isSubmitting["printing"]}
        />
      )}
      {record.objectId && checkButtonBar("export") && exportTemplate && (
        <ActionButton
          lbl={lbl}
          id="export"
          label="system.form.export"
          labelText="Export"
          className="export-btn"
          onClick={doExport}
          disabled={isSubmitting["any"]}
          loading={isSubmitting["exporting"]}
        />
      )}
      {record.objectId && checkButtonBar("history") && (
        <ActionButton
          lbl={lbl}
          id="history"
          label="system.form.history"
          labelText="History"
          className="history-btn"
          disabled={isSubmitting["any"]}
          onClick={doShowHistory}
        />
      )}
      {record.objectId && checkButtonBar("reload") && (
        <ActionButton
          lbl={lbl}
          id="reload"
          label="system.form.reload"
          labelText="Reload"
          className="reload-btn"
          disabled={isSubmitting["any"]}
          onClick={doReload}
        />
      )}
      {isClosable() && checkButtonBar("close") && (
        <ActionButton
          lbl={lbl}
          id="close"
          label="system.form.close"
          labelText="Close"
          className="close-btn"
          disabled={isSubmitting["any"]}
          onClick={doClose}
        />
      )}
    </>
  );
  const popoverContent = <div data-id-value={guiUid} className="button-bar-popover">{buttonBarInner}</div>
  const buttonBarForVo = (
    <Col span="24" data-id-value={guiUid} className={buttonBarClasses} style={buttonBarStyle}>
      {buttonBarInner}
      <Popover placement="topRight" content={popoverContent} trigger="click">
        <Button className={"button-bar-more"}><MdMoreHoriz/></Button>
      </Popover>
    </Col>
  );
  return buttonBarForVo;
};

export const createButtonBar = ({
  ref,
  record,
  authUserObj,
  formData,
  flow,
  currentTask,
  checkButtonBar,
  checkButtonPermission,
  lbl,
  isSubmitting,
  doSave,
  doDelete,
  id,
  formKey,
  checkIsSubstituteAction,
  doCopySelect,
  doPrint,
  doExport,
  doShowHistory,
  doReload,
  doClose,
  onSubmitButtonClicked,
  doTranslate,
  doSaveDraft,
  draft,
  doDiscardDraft,
  reportTemplate,
  exportTemplate,
  isClosable,
}) => {
  let guiUid = ref.current;
  if (!guiUid) {
    guiUid = uid();
    ref.current = guiUid;
  }
  const buttonBarStyle = null;
  const isActionee =
    (!record?.flow?.assignee ||
      record?.flow?.assignee === authUserObj?.username) &&
    formData.allActions.length > 0;
  const isPending = isActionee && record?.flow?.task === "start";
  let buttonBarClasses = "button-bar";
  if (formData?.extraParams?.affixButtonBar) {
    buttonBarClasses += " my-affix fixed";
  }
  const buttonBarInner = (
    <>
      {!record.objectId && checkButtonBar("add") && (
        <ActionButton
          lbl={lbl}
          id="create"
          label="system.form.create"
          labelText="Create"
          className="create-btn"
          disabled={isSubmitting["any"]}
          loading={isSubmitting["save"]}
          onClick={() => doSave()}
        />
      )}
      {(!flow || (currentTask?.allowSave && isActionee)) &&
        record.objectId &&
        checkButtonBar("update") && (
          <ActionButton
            lbl={lbl}
            id="save"
            label="system.form.save"
            labelText="Save"
            className="save-btn"
            disabled={isSubmitting["any"]}
            loading={isSubmitting["save"]}
            onClick={() => doSave()}
          />
        )}
      {(!flow || isPending) && checkButtonBar("delete") && (
        <ActionButton
          lbl={lbl}
          id="delete"
          label="system.form.delete"
          labelText="Delete"
          className="delete-btn"
          disabled={isSubmitting["any"] || !id}
          loading={isSubmitting["destory"]}
          onClick={doDelete}
          danger
          confirm="Are you sure to delete this record?"
        />
      )}
      {formData.allSubmits
        .filter((s) => !s.hidden)
        .map((s) => {
          return (
            <div>
              { checkButtonPermission(s.itemKey) &&
                <ActionButton
                  lbl={lbl}
                  id={s.itemKey}
                  label={`form.${formKey}.action.${s.itemKey}`}
                  labelText={s.title}
                  className={`${s.buttonClass} ${s.itemKey}-btn`}
                  key={s.itemKey}
                  disabled={isSubmitting["any"]}
                  loading={isSubmitting[s.itemKey]}
                  confirm={s.confirm}
                  onClick={() => {
                    log("do submit", s);
                    if (checkIsSubstituteAction(s.subsitute, "add")) {
                      doSave(s.itemKey);
                    } else if (checkIsSubstituteAction(s.subsitute, "copy")) {
                      doCopySelect();
                    } else if (checkIsSubstituteAction(s.subsitute, "update")) {
                      doSave(s.itemKey);
                    } else if (checkIsSubstituteAction(s.subsitute, "delete")) {
                      doDelete();
                    } else if (checkIsSubstituteAction(s.subsitute, "print")) {
                      doPrint();
                    } else if (checkIsSubstituteAction(s.subsitute, "export")) {
                      doExport();
                    } else if (checkIsSubstituteAction(s.subsitute, "history")) {
                      doShowHistory();
                    } else if (checkIsSubstituteAction(s.subsitute, "reload")) {
                      doReload();
                    } else if (checkIsSubstituteAction(s.subsitute, "close")) {
                      doClose();
                    } else {
                      if (s.submit) {
                        doSave(s.itemKey);
                      } else {
                        onSubmitButtonClicked(s.itemKey);
                      }
                    }
                  }}
                  htmlType={s.submit ? "submit" : undefined}
                />
              }
            </div>
          );
        })}
      {isActionee &&
        formData.allActions.map((a) => {
          return (
            <ActionButton
              lbl={lbl}
              id={a.action}
              label={`flow.${formKey}.action.${a.action}`}
              labelText={a.label}
              className={`action-btn ${a.action}-btn`}
              key={a.action}
              disabled={isSubmitting["any"] || !id}
              loading={isSubmitting[a.action]}
              confirm={a.confirm}
              onClick={() => {
                log("do action", a);
                doSave(a.action);
              }}
              htmlType="submit"
            />
          );
        })}
      {formData.extraParams?.translate?.enabled && (
        <ActionButton
          lbl={lbl}
          id="translate"
          label="system.form.translate"
          labelText="Translate"
          className="translate-btn"
          onClick={doTranslate}
        />
      )}
      {isActionee && currentTask?.allowSaveDraft && (
        <ActionButton
          lbl={lbl}
          id="save-draft"
          label="system.form.save-draft"
          labelText="Save Draft"
          className="save-draft-btn"
          disabled={isSubmitting["any"] || !id}
          loading={isSubmitting["saveDraft"]}
          onClick={doSaveDraft}
        />
      )}
      {isActionee && currentTask?.allowSaveDraft && (
        <ActionButton
          lbl={lbl}
          id="discard-draft"
          label="system.form.discard-draft"
          labelText="Discard Draft"
          className="discard-draft-btn"
          disabled={isSubmitting["any"] || !id || !draft}
          loading={isSubmitting["discardDraft"]}
          onClick={doDiscardDraft}
        />
      )}
      {checkButtonBar("copy") && (checkButtonBar("add") || checkButtonBar("update")) && (!flow || isActionee || !record.objectId) && (
        <ActionButton
          lbl={lbl}
          id="copy"
          label="system.form.copy"
          labelText="Copy"
          className="copy-btn"
          disabled={isSubmitting["any"]}
          onClick={doCopySelect}
        />
      )}
      {record.objectId && checkButtonBar("print") && reportTemplate && (
        <ActionButton
          lbl={lbl}
          id="print"
          label="system.form.print"
          labelText="Print"
          className="print-btn"
          onClick={doPrint}
          disabled={isSubmitting["any"]}
          loading={isSubmitting["printing"]}
        />
      )}
      {record.objectId && checkButtonBar("export") && exportTemplate && (
        <ActionButton
          lbl={lbl}
          id="export"
          label="system.form.export"
          labelText="Export"
          className="export-btn"
          onClick={doExport}
          disabled={isSubmitting["any"]}
          loading={isSubmitting["exporting"]}
        />
      )}
      {record.objectId && checkButtonBar("history") && (
        <ActionButton
          lbl={lbl}
          id="history"
          label="system.form.history"
          labelText="History"
          className="history-btn"
          disabled={isSubmitting["any"]}
          onClick={doShowHistory}
        />
      )}
      {record.objectId && checkButtonBar("reload") && (
        <ActionButton
          lbl={lbl}
          id="reload"
          label="system.form.reload"
          labelText="Reload"
          className="reload-btn"
          disabled={isSubmitting["any"]}
          onClick={doReload}
        />
      )}
      {isClosable() && checkButtonBar("close") && (
        <ActionButton
          lbl={lbl}
          id="close"
          label="system.form.close"
          labelText="Close"
          className="close-btn"
          disabled={isSubmitting["any"]}
          onClick={doClose}
        />
      )}
    </>
  )
  const popoverContent = <div data-id-value={guiUid} className="button-bar-popover">{buttonBarInner}</div>
  const buttonBar = (
    <Col span="24" data-id-value={guiUid} className={buttonBarClasses} style={buttonBarStyle}>
      {buttonBarInner}
      <Popover placement="topRight" content={popoverContent} trigger="click">
        <Button className={"button-bar-more"}><MdMoreHoriz/></Button>
      </Popover>
    </Col>
  );
  return buttonBar;
};

export { FormInfo, SettingsPanel, TheFrame, UserComponents, ViewText };

