import { useEditor, useNode } from "@craftjs/core";
import { Button, Col, Popconfirm, Switch } from "antd";
import { Form, Input, Select, PixelInput } from "components/Form";
import React, {useEffect, useState} from "react";
import { MdOutlineCallToAction } from "react-icons/md";
import IntlMessages from 'util/IntlMessages';
import { loadDynIcon,FA_ICONS, MD_ICONS } from "../../../../components/DynIcon";
import { isEmpty } from "../../../../util/algorithm";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting, syncPageState, updatePageState } from "./common";

const BUTTON_TYPES = [
  "default", "primary", "ghost", "dashed", "link", "text",
]

const BUTTON_SHAPE = [
  "default", "circle", "round",
]

const BUTTON_ICON = [...FA_ICONS, ...MD_ICONS];

const SpButtonSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property"/>
      </Form.Item>
      <Form.Item name="buttonType" label="type">
        <Select className="item-property" placeholder="type" allowClear showSearch>
          {BUTTON_TYPES.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name="buttonShape" label="shape">
        <Select className="item-property" placeholder="shape" allowClear showSearch>
          {BUTTON_SHAPE.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name="buttonIcon" label="icon">
        <Select className="item-property" placeholder="icon" allowClear showSearch>
          {BUTTON_ICON.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name="iconSize" label="icon size">
        <PixelInput min={1} unit="em" />
      </Form.Item>
      <Form.Item name="block" label="block" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="confirm" label="confirm">
        <Input className="item-property"/>
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  )
}

export const SpButton = ({...props}) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppButton doRef={ref => connect(drag(ref))} selectedStyle={style} dbtn={dbtn} {...props}/>
  )
}

export const SppButton = ({
  doRef, selectedStyle, dbtn, pageKey, itemKey, title, disabled, loading, confirm,
  pageState, setPageState, buttonType, buttonAction, buttonShape, buttonIcon, iconSize, style,
  block, firePageEvent, ...otherProps }) => {
  const buttonText = title ? <IntlMessages id={`page.${pageKey}.button.${itemKey}`} text={title} /> : null
  const props = {...otherProps, style: selectedStyle}
  const [myValue, setMyValue] = useState(false);

  useEffect(() => {
    syncPageState({itemKey, myValue, setMyValue, pageState, setPageState})
  },[itemKey, myValue, pageState, setPageState])

  const onClick = () => {
    const key = itemKey + '_clicked';
    if (firePageEvent) {
      firePageEvent(pageState, key, true);
    } else {
      updatePageState({itemKey: key, myValue: true, setMyValue, pageState, setPageState})
    }
  }

  let icon = null;
  if (buttonIcon) {
    const ButtonIcon = loadDynIcon(buttonIcon);
    icon = <span className="btn-icon"><ButtonIcon size={iconSize}/></span>
  }
  if (!isEmpty(confirm) && pageState?.pageKey) {
    return (
      <Col ref={doRef} {...props}>
        <Popconfirm
          title={
            <IntlMessages id={`${pageState?.pageKey}.confirm.${itemKey}`} text={confirm} />
          }
          onConfirm={onClick}
          okText={<IntlMessages id="system.confirm.yes" text="Yes" />}
          cancelText={<IntlMessages id="system.confirm.no" text="No" />}
          disabled={disabled}
        >
          <Button key={itemKey} ref={doRef} style={style}
          type={buttonType} shape={buttonShape} icon={icon}
          disabled={disabled} loading={loading} block={block}
          ><span className="btn-text">{buttonText}</span></Button>
        </Popconfirm>
        {dbtn}
      </Col>
    );
  } else {
    return (
      <Col ref={doRef} {...props}>
        <Button key={itemKey} ref={doRef} style={style}
        type={buttonType} shape={buttonShape} icon={icon} onClick={onClick}
        disabled={disabled} loading={loading} block={block}
        ><span className="btn-text">{buttonText}</span></Button>
        {dbtn}
      </Col>
    );
  }

}
SppButton.enablePageState = true;
SppButton.enablePageEvent = true;
SppButton.forwardAttributes = ["loading"];

SpButton.craft = {
  displayName: "Button",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpButtonSetting
  }
}

registerComponent({
  key:"SpButton",
  component: SpButton,
  runtimeComponent: SppButton,
  template: <SpButton itemKey={getId('button')} className="sp-button" title={"Button"} />,
  title: <IntlMessages id="system.page.library.button" text="Button" />,
  icon: <MdOutlineCallToAction className="react-icons icon-md" />,
  type: "Component",
  sequence: 5,
});

export default SpButton;