import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Switch } from "antd";
import { Form, Input } from "components/Form";
import React, { useEffect, useRef, useState } from "react";
import { MdOutlineColorLens } from "react-icons/md";
import IntlMessages from "util/IntlMessages";
import { ColorPicker } from "../../../../components/Form";
import { colSls, convertRules, convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate, updateTableCell } from "./common";

const SfColorPickerSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="allowClear" label="allow clear" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hidden" label="hidden" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="editInTable" label="edit col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfColorPicker = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpColorPicker doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const SfpColorPicker = ({
  doRef, form, condistyles, className, style, dbtn, hidden, hideInTable, editInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, translate, volitate, skipcopy,
  inputref, styleStr, children, ...otherProps }) => {
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const [fxr, setFxr] = useState({});
  const newRules = convertRules(rules);
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name} label={title}
               shouldUpdate={fx} hidden={fxr.hidden || (!doRef && hidden)} rules={fxr.hidden || hidden || otherProps.disabled ? null : newRules}
              labelAlign={labelAlign} labelCol={lcs} wrap>
              <ColorPicker style={sls} disabled={fxr.disabled || otherProps.disabled} {...otherProps} />
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}
const ColorPickerRenderer = ({value, record, index, form, tableKey, onRowChange, editInTable, itemKey, tableColWidth, disabled}) => {
  const [editing, setEditing] = useState(false);
  const [myValue, setMyValue] = useState(value);
  const leditInTable = editInTable && !disabled;
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, [])
  useEffect(() => {
    setMyValue(value);
  }, [value]);

  if (editing) {
    const onClick = (event) => {
      setEditing(false);
      event.preventDefault();
      event.stopPropagation();
    }
    const onChange = (myValue) => {
      try {
        setMyValue(myValue);
        updateTableCell({form, tableKey, record, itemKey, myValue, onRowChange, setEditing, index});
      } catch (e) {
        console.error(e);
      }
    }
    const className = "row-editing table-cell-input"
    const props = {value: myValue, className, onChange, onClick};
    if (tableColWidth) props.style = {"minWidth": `${tableColWidth}px`,"maxWidth": `${tableColWidth}px`}
    return <ColorPicker {...props} disabled={false} defaultOpen={true}/>
  } else {
    const onClick = (event) => {
      setEditing(true);
      event.preventDefault();
      event.stopPropagation();
    }
    const editingProps = leditInTable ? {className: "cell-content color row-editable table-cell-color", onClick} : {className: 'cell-content color'};
    if (tableColWidth) editingProps.style = {"minWidth": `${tableColWidth}px`, "maxWidth": `${tableColWidth}px`}
    return (
      <span {...editingProps}>
        <div title={value} style={{ backgroundColor: value }} />
      </span>
    );
  }
}

SfpColorPicker.render = ({ editInTable, itemKey, tableColWidth, disabled }, form, tableKey, onRowChange) => (value, record, index) => {
  const props = {value, record, index, form, tableKey, onRowChange, editInTable, itemKey, tableColWidth, disabled};
  return <ColorPickerRenderer {...props}/>
}

SfColorPicker.craft = {
  displayName: "Color",
  related: {
    settings: SfColorPickerSetting
  }
}

SfColorPicker.validate = (props, {parents, container, extraParams}) => {
  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "color",
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      sortable: true,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
    });
  }
}

SfColorPicker.isSearchable = true;
SfColorPicker.isRulable = true;

registerComponent({
  key:"SfColorPicker",
  component: SfColorPicker,
  runtimeComponent: SfpColorPicker,
  template: <SfColorPicker itemKey={getId('color')} className="sf-color wrap"
            title={"Color"} span={24} labelColStr="span:6" />,
  title: <IntlMessages id="system.form.library.color" text="Color" />,
  icon: <MdOutlineColorLens  className="react-icons icon-bs"/>,
  type: "Component",
  sequence: 11,
});

export default SfColorPicker;