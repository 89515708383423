import { Element, useEditor, useNode } from "@craftjs/core";
import { Col, Switch } from "antd";
import { Form, Input, Select } from "components/Form";
import { useLbl } from "lngProvider";
import { dataExplorer, dataPolicyApi } from "parse-api";
import { useEffect, useState, useRef } from "react";
import { FaChartLine } from "react-icons/fa";
import { Line, LineChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { isEmpty, log, equals } from "util/algorithm";
import IntlMessages from "util/IntlMessages";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting } from "./common";
import { settingsSignal } from "../../../../util/signal";

export const SpChartLine = ({...props}) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppChartLine doRef={ref => connect(drag(ref))} selectedStyle={style} dbtn={dbtn} {...props}/>
  )
}

export const SppChartLine = ({
  doRef, dbtn, selectedStyle, pageKey, itemKey, pageState, setPageState,
  policy, preview, gradient, grid, legend, tootip,
  ...otherProps }) => {
  const { locale } = settingsSignal;
  const [data, setData] = useState();
  const lbl = useLbl(locale);
  const [dataPolicyParams, setDataPolicyParams] = useState(null);
  if (!preview) preview = false;
  const mounted = useRef();
  useEffect(()=>{
    mounted.current = true;
    return ()=>{
      mounted.current = false;
    }
  },[])
  useEffect(()=>{
    const fetchData = async () => {
      if (policy) {
        try {
          let params = null;
          if (dataPolicyParams) {
            params = dataPolicyParams;
          }
          const data = await dataPolicyApi.getDataPolicyData(preview, policy, "LineChart", locale, params);
          log("data", data);
          if (mounted.current) setData(data);
        } catch (e) {
          log("get data failed", e);
          if (mounted.current) setData(null);
        }
      } else {
        if (mounted.current) setData(null);
      }
    }
    fetchData();
  },[dataPolicyParams, locale, policy,preview])
  useEffect(() => {
    if (pageState) {
      const params = pageState[itemKey + "_params" ];
      if (params && !equals(dataPolicyParams, params)) {
        setDataPolicyParams(params);
      }
    }
  },[dataPolicyParams, itemKey, pageState])
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}

  return (
    <Col ref={doRef} {...props}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data?.data}
        >
          {gradient &&
            <defs>
              {data?.dataKeys.map((key, index)=>{
                return (
                  <linearGradient key={"def"+key} id={"color"+key} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={data?.fills[index]} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={data?.fills[index]} stopOpacity={0} />
                  </linearGradient>
                )
              })}
            </defs>
          }
          {!isEmpty(grid) && <CartesianGrid strokeDasharray={grid} />}
          <XAxis dataKey={data?.nameKey}/>
          <YAxis />
          {tootip && <Tooltip />}
          {legend && <Legend verticalAlign="bottom" height={36}/>}
          {data?.dataKeys.map((key, index)=>{
            return <Line key={"area"+key} label={lbl(`user.data-policy.${policy}.${key}`, key)} type="monotone" dataKey={key} stroke={data?.stokes[index]} fill={gradient ? `url(#color${key})` : data?.fills[index]} />
          })}
        </LineChart>
      </ResponsiveContainer>
      {dbtn}
    </Col>
  );
}

const SpChartLineSetting = () => {
  const [policyOptions, setPolicyOptions] = useState([]);
  const mounted = useRef();
  useEffect(()=>{
    mounted.current = true;
    return ()=>{
      mounted.current = false;
    }
  },[])
  useEffect(()=>{
    const fetchData = async () => {
      const params = [{key:"dataTypes", operator:"=", value:"LineChart"}];
      const list = await dataExplorer.searchAll("SystemDataPolicy", params, "dataName");
      if (mounted.current) setPolicyOptions(list.map(p=>({value:p.dataKey,label:p.dataName})));
    }
    fetchData();
  },[])
  return (
    <>
      <Form.Item name="policy" label="policy" >
        <Select className="item-property" options={policyOptions} allowClear/>
      </Form.Item>
      <Form.Item name="grid" label="grid" >
        <Input className="item-property" allowClear/>
      </Form.Item>
      <Form.Item name="preview" label="preview" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="legend" label="legend" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="tootip" label="tootip" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  );
}

SpChartLine.craft = {
  displayName: "Line Chart",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpChartLineSetting
  }
}
SppChartLine.enablePageState = true;

registerComponent({
  key:"SpChartLine",
  component: SpChartLine,
  runtimeComponent: SppChartLine,
  template: <Element canvas is={SpChartLine} itemKey={getId('chartline')} preview={true}
            className="sp-chart" span="24" style={{height:"300px"}}/>,
  title: <IntlMessages id="system.page.library.chartline" text="Line Chart" />,
  icon: <FaChartLine  className="react-icons icon-fa"/>,
  type: "Component",
  subtype: "chart",
  sequence: 3,
});

export default SpChartLine;