import { END_TRANSLATE, START_FORM_TRANSLATE, START_TRANSLATE } from "../../constants/ActionTypes";
import { getLocalStorageItem } from "../../util/algorithm";
import { translateSignal } from "../../util/signal";

const initialConfig = {
  translate: getLocalStorageItem('translate') === "Y",
  filterKeyPrefix: "",
  translatePath: "",
  showCurrentPathOnly: true,
};

const TranslateReducer = (state = initialConfig, action) => {
  let newState = state;
  switch (action.type) {
    case START_TRANSLATE:
      newState = {
        ...state,
        translate: true,
        filterKeyPrefix: "",
        translatePath: "",
        showCurrentPathOnly: true,
      };
      break;
    case END_TRANSLATE:
      newState = {
        ...state,
        translate: false,
        filterKeyPrefix: "",
        translatePath: "",
        showCurrentPathOnly: true,
      };
      break;
    case START_FORM_TRANSLATE:
      newState = {
        ...state,
        translate: true,
        filterKeyPrefix: action.payload.filterKeyPrefix,
        translatePath: action.payload.translatePath,
        showCurrentPathOnly: false,
      };
      break;
    default:
      newState = state;
  }
  Object.assign(translateSignal, newState);
  return newState;
};

export default TranslateReducer;
