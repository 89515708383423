import { useEditor, useNode } from "@craftjs/core";
import { Tooltip } from "antd";
import { Form, Input } from "components/Form";
import { dataExplorer } from "parse-api";
import { useEffect, useRef, useState } from "react";
import { UserAvatar } from "../../../../components/Form";
import { deleteButton, EditorCollector, NodeCollector, registerComponent, SpStyleSetting, SpLayoutSetting } from "./common";

const SpUserAvatarSetting = () => {
  return (
    <>
      <Form.Item name="username" label="username">
        <Input className="item-property"/>
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  )
}

export const SpUserAvatar = ({...props}) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppUserAvatar doRef={ref => connect(drag(ref))} dbtn={dbtn} {...props} style={style}/>
  )
}

export const SppUserAvatar = ({
  doRef, style, dbtn, pageKey, itemKey, styleStr, username,
  ...otherProps }) => {
  const [user, setUser] = useState();
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  },[])
  useEffect(() => {
    const fetchData = async () => {
      if (username) {
        const usr = await dataExplorer.getByKey("User", username);
        if (mounted.current) setUser(usr);
      }
    }
    fetchData();
  },[username])

  return (
    <div ref={doRef}><Tooltip mouseEnterDelay={1} title={user?.nickname}><UserAvatar userObj={user} {...otherProps}/></Tooltip></div>
  );
}

SpUserAvatar.craft = {
  displayName: "User Avator",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpUserAvatarSetting
  }
}

registerComponent({
  key:"SpUserAvatar",
  component: SpUserAvatar,
  runtimeComponent: SppUserAvatar,
  type: "Component",
});

export default SpUserAvatar;