import ReactDOM from "react-dom";
import AppFrame from "../AppFrame";
import { setBaseUrl, switchLanguage, userSignInSuccess, userSignOutSuccess } from "../appRedux/actions";
import configureStore from "../appRedux/store";
import { loadClientConfig } from "../constants/Account";
import { displayError, getLbl, getMessage } from "../lngProvider";
import languageData from "../lngProvider/languageData";
import { auth, dataPolicyApi, system, translateApi } from "../parse-api";
import { getRequestContext, initializeParseSDK, setRequestContext } from "../parse-api/config";
import { getArrayElementByAttribute, getClientRoot, getLocalStorageItemObject, getLocalStoragePrefix, invokePageComponentApis, removeLocalStorageItem, setCookie, setLocalStorageItemObject, tryParseJson } from "./algorithm";
import { exportToXLSX } from "./export";
import { isParseSDKInitialized, parseServerInitializedSignal, windowIdentitySignal } from "./signal";
import { keycloakApi } from "../parse-api/keycloak-sso";
import { handleUploadFile } from "../parse-api/file";
import { message } from "antd";

const renderPage = (element, props) => {
  try {
    ReactDOM.unmountComponentAtNode(element);
  } catch (error) {
    console.log("renderPage() unmount error", error)
  }
  ReactDOM.render(<AppFrame {...props}/>, element);
}

const addCallback = (fn, callback) => {
  return async (...args) => {
    const rtnVal = await fn(...args);
    await callback(rtnVal);
    return rtnVal;
  }
}

let userReady = false;

const setUser = (userStore) => {
  const user = userStore?.user;
  const store = configureStore();
  console.log("setUser()", {store, user})
  store.dispatch(userSignInSuccess(user));
  setLocalStorageItemObject('user_obj', user);
  userReady = true;
}

const unsetUser = (userStore) => {
  const user = userStore?.user;
  const store = configureStore();
  console.log("unsetUser()", {store, user})
  store.dispatch(userSignOutSuccess(user));
  removeLocalStorageItem('user_obj');
  userReady = false;
}

const isUserReady = () => userReady;

export const setLanguage = async (languageId) => {
  let langs = getLocalStorageItemObject('language_data');
  if(!langs){
    langs = await translateApi.loadSystemLanguage()
  }
  if(!langs){
    langs = getLocalStorageItemObject('language_data', languageData);
  }
  setLocalStorageItemObject('language_data', langs)
  let lang = getArrayElementByAttribute(langs, 'languageId', languageId);
  if (lang) {
    const store = configureStore();
    store.dispatch(switchLanguage(lang));
  } else {
    console.log('languageId not found', languageId);
  }
}

export const reloadCurrentUser = async () => {
  await parseServerInitializedSignal.waitFor('value', true);
  let currentUser = await auth.getCurrentUser();
  if (currentUser) {
    let roles = await auth.getUserRoles(currentUser);
    let userStore = auth.convertUser(currentUser, roles);
    console.log("current userStore is ", userStore);
    setUser(userStore)
    return userStore;
  } else {
    return null;
  }
}

window.addEventListener('storage', function(event){
  console.log('storage event', event.key, event)
  if (event.key === getLocalStoragePrefix() + 'user_obj') {
    const store = configureStore();
    if (event.newValue) {
      const newValue = tryParseJson(event.newValue) || {};
      store.dispatch(userSignInSuccess(newValue));
    } else {
      const oldValue = tryParseJson(event.oldValue) || {};
      store.dispatch(userSignOutSuccess(oldValue));
    }
  }
});

const refreshAccessKey = async () => {
  const userStore = await reloadCurrentUser()
  if (userStore?.user) {
    if (userStore?.user?.username !== 'guest') {
      const accessKey = await system.generateAccessKey();
      setCookie("accesskey", accessKey);
    }
  }
}

const GeneralAlertStore = {};
export const setupGeneralAlert = async (userStore) => {
  const user = userStore?.user;
  let subscribed = false;
  if (user?.username !== "guest") {
    const subject =`GeneralAlert_${user?.username}`;
    if (GeneralAlertStore.subject !== subject) {
      if (GeneralAlertStore.subject) {
        await system.unsubscribe(subject, GeneralAlertStore.subject);
        GeneralAlertStore.subject = null;
      }
      GeneralAlertStore.subject = subject;
      if (user) {
        GeneralAlertStore.alertKey = await system.subscribe(
          subject,
          (notifyId, params) => {
            console.log("general alert data...", notifyId, params);
            const key = params?.key || "system.form.general_alert";
            const type = params?.type || "info";
            const content = params?.message || "A new alert has sent to you.";
            const duration = params?.duration || undefined;
            const destroy = params?.destroy || false;
            const scope = params?.scope || "user"; // scope allowed: user / installation / window
            const windowIdentity = params?.windowIdentity;
            const installationId = params?.installationId;
            let notify = true;
            if (windowIdentity !== "local") {
              if (
                scope === "installation" &&
                installationId !== windowIdentitySignal.installationId
              ) {
                notify = false;
              }
              if (
                scope === "window" &&
                windowIdentity !== windowIdentitySignal.value
              ) {
                notify = false;
              }
            }
            if (notify) {
              if (destroy) {
                message.destroy(key);
              } else {
                let onClick = undefined;
                message.open({
                  type,
                  content: getLbl(`${key}`, content),
                  key: key,
                  duration: duration,
                  onClick: onClick,
                });
              }
            }
          }
        );
        subscribed = true;
      }
    }
  }
  return subscribed;
};

export const exportToWindow = () => {
  if (typeof window !== "undefined") {
    const oldAppCtrl = window.AppCtrl || {};
    const AppCtrl = {
      ...oldAppCtrl,
      getMessage,
      getDataPolicyData: dataPolicyApi.getDataPolicyData,
      displayError: displayError,
      setRequestContext,
      getRequestContext,
      renderPage,
      subscribe: system.subscribe,
      unsubscribe: system.unsubscribe,
      createUser: auth.createUserWithEmailAndPassword,
      signIn: addCallback(auth.signInWithEmailAndPassword, setUser),
      signInWithEmailAndPassword: addCallback(auth.signInWithEmailAndPassword, setUser),
      signInWithSession: addCallback(auth.signInWithSession, setUser),
      signInWithKeycloak: addCallback(auth.signInWithKeycloak, setUser),
      reloadCurrentUser: reloadCurrentUser,
      signOut: addCallback(auth.signOut, unsetUser),
      isUserReady: isUserReady,
      setLanguage: setLanguage,
      invokePageComponentApis: invokePageComponentApis,
      getClientRoot: getClientRoot,
      loadClientConfig: loadClientConfig,
      initializeParseSDK: initializeParseSDK,
      isParseSDKInitialized: isParseSDKInitialized,
      initializeKeycloak: keycloakApi.initializeKeycloak,
      setBaseUrl: setBaseUrl,
      exportToXLSX: exportToXLSX,
      handleUploadFile: handleUploadFile,
      refreshAccessKey: refreshAccessKey,
      startNotification: system.startNotification,
      setupGeneralAlert: setupGeneralAlert,
    };
    window.AppCtrl = AppCtrl;
  }
};
