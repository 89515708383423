import React from "react";
import { FaUserEdit } from "react-icons/fa";
import IntlMessages from "util/IntlMessages";
import { registerComponent } from "./common";
import { SfpSelect, SfSelect } from "./SfSelect";

registerComponent({
  key: "SfOwner",
  component: SfSelect,
  runtimeComponent: SfpSelect,
  template: <SfSelect itemKey={'owners'} className="sf-select wrap"
    title={"Owners"} selectkey={"User"} isUseCode={true} showSearch={true} datasec={true} mode={"multiple"} datasectype={"owners"} span={24} labelColStr="span:6" />,
  title: <IntlMessages id="system.form.library.owners" text="Owners" />,
  icon: <FaUserEdit  className="react-icons icon-fa"/>,
  type: "Component",
  subtype: "sys",
  sequence: 4,
});
