import { useEditor, useNode } from "@craftjs/core";
import { Col, Empty, InputNumber, Select, Spin, Switch } from "antd";
import { Form, Input } from "components/Form";
import { selectApi } from "parse-api";
import React, { useEffect, useRef, useState } from "react";
import { GrDocumentUser } from "react-icons/gr";
import { compare, equals, getText } from "util/algorithm";
import IntlMessages from "util/IntlMessages";
import { toAuthUserObj } from "../../../../util/algorithm";
import {
  colSls, convertStyleStr, deleteButton, EditorCollector, getId,
  NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate
} from "./common";
import { SelectRenderer } from "./SfSelect";
import { authSignal, settingsSignal } from "../../../../util/signal";

const SfSystemUserSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="forCreate" label="for create" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="forUpdate" label="for update" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="size" label="size">
        <Select className="item-property">
          <Select.Option value="large">large</Select.Option>
          <Select.Option value="middle">middle</Select.Option>
          <Select.Option value="small">small</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="bordered" label="bordered" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="isSelectKey" label="select key" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="datasec" label="data sec." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="datasectype" label="datasec type">
        <Select className="item-property" allowClear>
          <Select.Option value="owners">owners</Select.Option>
          <Select.Option value="users">users</Select.Option>
          <Select.Option value="userRoles">userRoles</Select.Option>
        </Select>
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfSystemUser = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpSystemUser doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

const dfo = (input, option) => {
  return getText(option).toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

export const SfpSystemUser = ({
  doRef, form, condistyles, className, style, dbtn, hideInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, volitate, skipcopy,
  forCreate, forUpdate, datasec, datasectype,
  inputref, styleStr, children, ...otherProps }) => {

  const [fxr, setFxr] = useState({});
  const { authUser } = authSignal;
  const authUserObj = toAuthUserObj(authUser);
  const selectkey = "User"
  const parentkeys = null;
  const isUseCode = true;
  const isShowIcon = false;
  const autohide = false;
  otherProps.disabled = true;
  otherProps.skipcopy = undefined;

  const { locale } = settingsSignal;
  const [fetching, setFetching] = useState(false);
  const [list, setList] = useState([]);
  const [options, setOptions] = useState([]);
  const [parentValues, setParentValues] = useState([]);
  const [hidden, setHidden] = useState(parentkeys && parentkeys.length > 0 && autohide);
  const [label, setLabel] = useState(title);
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const mounted = useRef();

  useEffect(() => {
    mounted.current = true;
    refreshOptions();
    return () => {
      mounted.current = false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const options = list.sort((a, b) => compare(a.label, b.label)).map(l => {
      if (isShowIcon) {
        return <Select.Option key={l.value} value={l.value} ><i className={"select-icon " + l.icon}></i><span className="icon-text">{l.label}</span></Select.Option>
      } else {
        return <Select.Option key={l.value} value={l.value} >{l.label}</Select.Option>
      }
    })
    if (mounted.current) setOptions(options);
  }, [isShowIcon, list]);

  const refreshOptions = async (form, dependencies, name, autohide, args) => {
    if (mounted.current) setFetching(true);
    if (form && dependencies && dependencies.length > 0) {
      const pValues = dependencies.map(d => form.getFieldValue(d));
      const hasNull = pValues.some(v => v == null);
      if (hasNull) {
        if (mounted.current) setHidden(true);
      } else {
        if (!equals(pValues, parentValues)) {
          if (mounted.current) setParentValues(pValues);
          const parentOptionKey = await selectApi.getParentOptionKey(locale, selectkey, isUseCode, pValues);
          let list = [];
          if (parentOptionKey) {
            list = await selectApi.fetchSystemSelectOption(locale, selectkey, isUseCode, parentOptionKey);
            if (list && list.length > 0) {
              if (mounted.current) setHidden(false);
            } else {
              if (mounted.current) setHidden(true);
            }
            const lbl = await selectApi.getCachedSystemSelectOptionLabel(locale, selectkey, parentOptionKey);
            if (lbl) {
              if (mounted.current) setLabel(lbl);
            } else {
              if (mounted.current) setLabel(title);
            }
          } else {
            if (mounted.current) setHidden(true);
            if (mounted.current) setLabel(title);
          }
          if (mounted.current) setList(list);
          if (mounted.current) setFetching(false);
          const cValue = form.getFieldValue(name);
          if (!list.find(l => l.value === cValue)) {
            form.setFields([{ name: name, value: null }]);
          }
        }
      }
    } else if (options.length === 0) {
      const list = await selectApi.fetchSystemSelectOption(locale, selectkey, isUseCode);
      if (mounted.current) setList(list);
    }
  }
  const isHidden = !doRef && autohide && hidden;
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const dependencies = parentkeys ? parentkeys.map(p => ctx ? [...ctx.name, p] : [p]) : [];
        const doRefresh = (...args) => refreshOptions(form, dependencies, name, args);
        const objectId = form?.getFieldValue(["objectId"]);
        const initialValue = ((forCreate && !objectId) || (forUpdate && objectId)) ? authUserObj.username : null;
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr, isHidden, doRefresh});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name} label={doRef ? title : label}
              shouldUpdate={fx} hidden={fxr.hidden || isHidden}
              labelAlign={labelAlign} labelCol={lcs} initialValue={initialValue}>
              <Select inputref={inputref} className={"with-icon"} style={sls} {...otherProps}
                onClick={doRefresh} filterOption={dfo}
                notFoundContent={fetching ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              >
                {options}
              </Select>
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

SfpSystemUser.render = ({ selectkey, isUseCode, parentkeys }) => (value, record, index) => {
  const props = {selectkey, isUseCode, parentkeys, value, record, index}
  return <SelectRenderer {...props}/>
}

SfSystemUser.craft = {
  displayName: "System User",
  related: {
    settings: SfSystemUserSetting
  }
}

SfSystemUser.validate = (props, {parents, container, extraParams}) => {
  const itemKey = props.itemKey;
  const forCreate = props.forCreate;
  const forUpdate = props.forUpdate;
  if (props.skipcopy === undefined) props.skipcopy = true;
  if (!forCreate && !forUpdate) {
    return <IntlMessages id="system.form.validate.system-user-create-or-update" text="item ({itemKey}) - for create / for update is not checked." values={{ itemKey: itemKey }} />
  }

  const selectkey = "User"
  const parentkeys = null;
  const isUseCode = true;

  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "select",
      valueIndex: props.isUseCode ? "code" : "name",
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      sortable: true,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
      selectkey: selectkey,
      parentkeys: parentkeys,
      isUseCode: isUseCode,
      datasec: props.datasec,
      datasectype: props.datasectype,
    });
    if (props.isSelectKey) {
      extraParams.selectConfig.optionTypes.push(props.itemKey);
    }
  }
}

SfSystemUser.isSearchable = true;

registerComponent({
  key: "SfSystemUser",
  component: SfSystemUser,
  runtimeComponent: SfpSystemUser,
  template: <SfSystemUser itemKey={getId('systemuser')} className="sf-select wrap"
    title={"System User"} span={24} labelColStr="span:6" />,
  title: <IntlMessages id="system.form.library.system-user" text="System User" />,
  icon: <GrDocumentUser  className="react-icons icon-gr"/>,
  type: "Component",
  subtype: "sys",
  sequence: 2,
});

export default SfSystemUser;