import {
  APP_SESSION, HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER, RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS, SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_START,
  SIGNUP_USER_STOP,
  SIGNUP_USER_SUCCESS
} from "../../constants/ActionTypes";
import { getLocalStorageItem } from "../../util/algorithm";
import { authSignal } from "../../util/signal";

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: getLocalStorageItem('redirect_url', ''),
  signingUp: false,
  authUser: getLocalStorageItem('user_obj'),
  resetingPassword: false,
  session: null,
};


const AuthReducer = (state = INIT_STATE, action) => {
  let newState = state;
  switch (action.type) {
    case SIGNUP_USER_START: {
      newState = {
        signingUp: true,
      }
      break;
    }
    case SIGNUP_USER_STOP: {
      newState = {
        signingUp: false,
      }
      break;
    }
    case SIGNUP_USER_SUCCESS: {
      newState = {
        ...state,
        loader: false,
        authUser: null,
        initURL: '/',
        signingUp: false,
      }
      break;
    }
    case SIGNIN_USER_SUCCESS:
    case SIGNIN_GOOGLE_USER_SUCCESS:
    case SIGNIN_FACEBOOK_USER_SUCCESS:
    case SIGNIN_TWITTER_USER_SUCCESS:
    case SIGNIN_GITHUB_USER_SUCCESS:
    {
      newState = {
        ...state,
        loader: false,
        authUser: action.payload
      }
      break;
    }
    case RESET_PASSWORD_START: {
      newState = {
        ...state,
        resetingPassword: true,
      }
      break;
    }
    case RESET_PASSWORD_SUCCESS: {
      newState = {
        ...state,
        authUser: null,
        initURL: '/',
        loader: false,
        resetingPassword: false,
      }
      break;
    }
    case INIT_URL: {
      newState = {
        ...state,
        initURL: action.payload
      }
      break;
    }
    case SIGNOUT_USER_SUCCESS: {
      newState = {
        ...state,
        authUser: null,
        initURL: '/',
        loader: false
      }
      break;
    }
    case SHOW_MESSAGE: {
      newState = {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
      break;
    }
    case HIDE_MESSAGE: {
      newState = {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
      break;
    }
    case ON_SHOW_LOADER: {
      newState = {
        ...state,
        loader: true
      }
      break;
    }
    case ON_HIDE_LOADER: {
      newState = {
        ...state,
        loader: false
      }
      break;
    }
    case APP_SESSION:
      newState = {
        ...state,
        session: action.payload,
        loader: true,
      };
      break;
    default:
      newState = state;
  }
  Object.assign(authSignal, newState);
  return newState;
}

export default AuthReducer;
