import { Table } from "antd";
import _ from "lodash";
import React, { useRef } from "react";
import { log } from "../../util/algorithm";

const DRAG_INDICATOR_STYLE = '#ff8080 solid 1px';
const PERFORM_INSTANT_UI_UPDATE = false;
const DEFAULT_WIDTH = 250;
const MINIMUM_WIDTH = 80;

const renderOnHeader = ({ col, ...moreProps }) => {
  const props = {
    width: col.width,
    dataIndex: col.dataIndex,
    ...moreProps,
  };
  return props;
};

const getCurrentMousePosition = (e) => {
  try {
    const contentPane = e.target.closest('.ant-table-content');
    const contentRect = contentPane.getBoundingClientRect();
    const scrollLeft = contentPane.scrollLeft;
    return scrollLeft + e.clientX - contentRect.left;
  } catch (error) {
    log('getCurrentMousePosition()', error, e);
    throw error;
  }
}

const setupResize = (
  e,
  {
    resizingRef,
    tableElement,
    sourceElement,
    minimumWidth,
    onChangeSettings,
    dataIndex,
  }
) => {
  try {
    const startPos = getCurrentMousePosition(e);
    let movePos = null;
    if (resizingRef) resizingRef.current = startPos;
    const pe = tableElement.querySelector(".ant-table-content");
    const ri = document.createElement("div");
    ri.classList.add("resizable-header-indicator");
    ri.style.setProperty("left", startPos + "px");
    pe.appendChild(ri);
    const onMouseMove = (e) => {
      try {
        e.preventDefault();
        e.stopPropagation();
        movePos = getCurrentMousePosition(e);
        const delta = movePos - startPos;
        const currentWidth = sourceElement.offsetWidth;
        if (delta + currentWidth > minimumWidth) {
          ri.style.setProperty("left", movePos + "px");
        } else {
          ri.style.setProperty(
            "left",
            startPos - currentWidth + minimumWidth + "px"
          );
        }
      } catch (error) {
        log('onMouseMove()', error);
        log('onMouseMove() event', e);
      }
    };
    const onMouseUp = (e) => {
      try {
        pe.removeEventListener("mousemove", onMouseMove);
        pe.removeEventListener("mouseup", onMouseUp);
        Array.from(pe.querySelectorAll(".resizable-header-indicator")).forEach(
          (i) => {
            pe.removeChild(i);
          }
        );
        const endPos = getCurrentMousePosition(e);
        let delta = endPos - startPos;
        const currentWidth = sourceElement.offsetWidth;
        if (onChangeSettings) {
          let newWidth = delta + currentWidth;
          if (newWidth < minimumWidth) newWidth = minimumWidth;
          const width = {};
          const cols = Array.from(tableElement.querySelectorAll("th")).filter(
            (th) => th.offsetWidth > 0
          );
          cols.forEach((c) => {
            const key = c.getAttribute("data-index-value");
            if (key === dataIndex) {
              width[key] = newWidth;
            } else {
              width[key] = c.offsetWidth;
            }
          });
          onChangeSettings({ width });
        }
        setTimeout(() => {
          resizingRef.current = false;
        }, 500);
      } catch (error) {
        log("onMouseUp()", error);
        log("onMouseUp() event", e)
      }
    };
    pe.addEventListener("mousemove", onMouseMove);
    pe.addEventListener("mouseup", onMouseUp);
  } catch (error) {
    log("setupResize()", error);
  }
};

const getCurrentIndex = (event) => {
  try {
    const table = event.target.closest("table");
    const th = event.target.closest("th");
    const index = Array.from(table.querySelectorAll("th")).indexOf(th);
    return index;
  } catch (error) {
    log("getCurrentIndex()", error);
    return -1;
  }
};

const getDraggingIndex = (event) => {
  try {
    const table = event.target.closest("table");
    const th = table.querySelector('th.resizable-header-dragging');
    const index = Array.from(table.querySelectorAll("th")).indexOf(th);
    return index;
  } catch (error) {
    log("getCurrentIndex()", error);
    return -1;
  }
};


const setInsertIndicator = ({event, sourceIndex, targetIndex}) => {
  let styleName = null;
  if (sourceIndex < targetIndex) {
    styleName = 'border-right';
  } else if (sourceIndex > targetIndex) {
    styleName = 'border-left';
  }
  const table = event.target.closest("table");
  const cells = Array.from(table.querySelectorAll("tr")).map(
    (row) => Array.from(row.cells)
  );
  cells.forEach((cellsRow) => {
    cellsRow.forEach((c, index) => {
      if (index === targetIndex && index !== sourceIndex) {
        c.style.setProperty(styleName, DRAG_INDICATOR_STYLE);
      } else {
        c.style.setProperty('border-right', null);
        c.style.setProperty('border-left', null);
      }
    })
  });
}

const unsetInsertIndicator = (event) => {
  try {
    const table = event.target.closest("table");
    if (table) {
      const cells = Array.from(table.querySelectorAll("tr")).map(
        (row) => Array.from(row.cells)
      );
      cells.forEach((cellsRow) => {
        cellsRow.forEach((c) => {
          c.style.setProperty('border-right', null);
          c.style.setProperty('border-left', null);
        })
      });
    }
  } catch (error) {
    log("unsetInsertIndicator()", error)
  }
}

const handleDragStart = (event) => {
  try {
    const startIndex = getCurrentIndex(event);
    event.dataTransfer.setData("text/plain", startIndex);
    const th = event.target.closest("th");
    th.classList.add('resizable-header-dragging');
    event.target.style.opacity = "0.5";
  } catch (error) {
    log("handleDragStart()", error);
  }
};

const handleDragOver = (event) => {
  try {
    event.preventDefault();
    const sourceIndex = getDraggingIndex(event);
    const targetIndex = getCurrentIndex(event);
    setInsertIndicator({event, sourceIndex, targetIndex})
    event.dataTransfer.dropEffect = "move";
  } catch (error) {
    log("handleDragOver()", error);
  }
};

const handleDragEnd = (event) => {
  try {
    event.preventDefault();
    const th = event.target.closest("th");
    th.classList.remove('resizable-header-dragging');
    event.target.style.opacity = "1";
  } catch (error) {
    log("handleDragEnd()", error);
  }
};

const performInstantUIEffect = ({table, sourceIndex, targetIndex}) => {
  const cells = Array.from(table.querySelectorAll("tr")).map(
    (row) => row.cells
  );
  if (sourceIndex < targetIndex) {
    cells.forEach((cellsRow) => {
      cellsRow[targetIndex].insertAdjacentElement(
        "afterend",
        cellsRow[sourceIndex]
      );
    });
  } else {
    cells.forEach((cellsRow) => {
      cellsRow[targetIndex].insertAdjacentElement(
        "beforebegin",
        cellsRow[sourceIndex]
      );
    });
  }
}

const prepareIndex = ({cols, sourceIndex, targetIndex}) => {
  let startIndex = sourceIndex;
  let endIndex = targetIndex;
  const end = cols[startIndex];
  if (sourceIndex < targetIndex) {
    for (let i = startIndex; i < endIndex; i++) {
      cols[i] = cols[i + 1];
    }
  } else {
    for (let i = startIndex; i > endIndex; i--) {
      cols[i] = cols[i - 1];
    }
  }
  cols[endIndex] = end;
  const index = {}
  cols.forEach((c, i) => {
    const key = c.getAttribute("data-index-value");
    index[key] = i;
  });
  return index;
}

const createHandleDrop = (onChangeSettings) => {
  const handleDrop = (event) => {
    try {
      event.preventDefault();
      const table = event.target.closest("table");
      const sourceIndex = parseInt(event.dataTransfer.getData("text/plain"));
      const targetIndex = getCurrentIndex(event);
      const cols = Array.from(table.querySelectorAll("th"));
      if (sourceIndex !== -1 && targetIndex !== -1 && sourceIndex !== targetIndex) {
        const index = prepareIndex({cols, sourceIndex, targetIndex})
        if (PERFORM_INSTANT_UI_UPDATE)
          performInstantUIEffect({table, sourceIndex, targetIndex})
        if (onChangeSettings) {
          onChangeSettings({ index });
        }
      }
      table.removeEventListener('drop', handleDrop);
      setTimeout(() => {
        unsetInsertIndicator(event);
      }, 1000)
    } catch (error) {
      log("handleDrop()", error);
    }
  }

  return handleDrop;
}

const DefaultHeader = (children, forwardRef, ...props) => {
  return <th ref={forwardRef} {...props}>{children}</th>
}

const getResizableTitle = (Header = DefaultHeader) => {
  const ResizableTitle = ({
    width,
    dataIndex,
    onChangeSettings,
    tableElement,
    resizable,
    draggable,
    minimumWidth,
    defaultWidth,
    onClick,
    children,
    ...restProps
  }) => {
    const resizingRef = useRef();
    const headerRef = useRef();

    const onMyClick = (e) => {
      if (resizingRef.current) {
        e.preventDefault();
        e.stopPropagation();
      } else if (onClick) {
        onClick(e);
      }
    };

    const startResize = (e) => {
      setupResize(e, {
        resizingRef,
        tableElement,
        sourceElement: headerRef.current,
        minimumWidth,
        onChangeSettings,
        dataIndex,
      });
    };
    if (!resizable) {
      return <Header {...restProps}>{children}</Header>;
    } else {
      const ws = width || defaultWidth || DEFAULT_WIDTH;
      const w = typeof ws === "number" ? `${ws}px` : ws;
      let s = { width: w };
      return (
        <Header
          {...restProps}
          forwardRef={headerRef}
          draggable={draggable}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
          onDrop={createHandleDrop(onChangeSettings)}
          data-width-value={width}
          data-index-value={dataIndex}
          className="resizable-col-header"
          onClick={onMyClick}
          style={s}
        >
          {children}
          <span className="resizable-col-handle" onMouseDown={startResize}></span>
        </Header>
      );
    }
  }
  return ResizableTitle;
};

const calculateDefaultWidth = (tableElement, columns) => {
  if (tableElement?.offsetWidth) {
    const totalWidth = tableElement.offsetWidth;
    let remainingWidth = totalWidth;
    let count = 0;
    for (const col of columns) {
      if (col.width) {
        remainingWidth -= parseInt(col.width);
      } else {
        count++;
      }
    }
    if (count > 0 && remainingWidth > 0) {
      return (remainingWidth / count) - 50;
    } else {
      return DEFAULT_WIDTH;
    }
  } else {
    return DEFAULT_WIDTH;
  }
}

export const ResizableTable = ({
  components,
  columns,
  onChangeSettings,
  resizable = true,
  draggable = true,
  minimumWidth = MINIMUM_WIDTH,
  ...props
}) => {
  const tableRef = useRef();

  if (!components) components = {};
  const outerHeader = _.get(components, "header.cell");
  _.set(components, "header.cell", getResizableTitle(outerHeader));
  const tableElement = tableRef.current;
  let defaultWidth = calculateDefaultWidth(tableElement, columns);
  columns = columns.map((col) => {
    return {
      ...col,
      onHeaderCell: () => {
        return renderOnHeader({
          col,
          tableElement,
          onChangeSettings,
          resizable,
          draggable,
          minimumWidth,
          defaultWidth,
        });
      },
    };
  });

  return (
    <Table
      ref={tableRef}
      components={components}
      columns={columns}
      {...props}
    />
  );
};

export default ResizableTable;
