import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Switch } from "antd";
import { Form, Input, Select } from "components/Form";
import React, {useEffect, useState, useRef} from "react";
import IntlMessages from "util/IntlMessages";
import { deleteButton, EditorCollector, getId, NodeCollector, refreshOptions, registerComponent, SpLayoutSetting, SpStyleSetting, syncPageState, updatePageState } from "./common";
import { getArrayElementByAttribute, log } from "util/algorithm";
import { selectApi } from "parse-api";
import { SystemCheckbox } from "../../FormSetup/components/SfCheckbox";
import { IoCheckboxOutline } from "react-icons/io5";
import { settingsSignal } from "../../../../util/signal";

const SpCheckboxSetting = () => {
  const { locale } = settingsSignal;
  const [systemSelectList, setSystemSelectList] = useState([]);
  const [selects, setSelects] = useState([]);
  const [selected, setSelected] = useState({});
  const [values, setValues] = useState({});
  const [options, setOptions] = useState([]);
  const [filter, setFilter] = useState([]);
  const selectedKey = useRef();
  const mounted = useRef();

  useEffect(() => {
    mounted.current = true;
    const fetchData = async () => {
      const list = await selectApi.loadSystemSelect();
      setSystemSelectList(list);
      // convert to option
      const options = list.map(l => {
        return {
          value: l.selectKey,
          label: l.name,
        }
      })
      if (mounted.current) setSelects(options);
    }
    fetchData();
    return () => {
      mounted.current = false;
    }
  }, [])

  const refreshOptionTypes = () => {
    if (selectedKey.current && systemSelectList?.length > 0) {
      if (selected && selected?.selectKey !== selectedKey.current) {
        log("refreshOptionTypes", selected?.selectKey, selectedKey.current);
        const sel = getArrayElementByAttribute(systemSelectList, "selectKey", selectedKey.current);
        setSelected(sel);
        if (sel && sel.optionTypes) {
          if (mounted.current) setFilter(sel.optionTypes.map(f => ({label:f, value:f})));
        } else {
          if (mounted.current) setFilter([]);
        }
      }
    }
  }

  const onIsUseCodeChanged = (value) => {
    updateFilterOption({ ...values, "isUseCode": value });
  }

  const onFilterChanged = (value) => {
    updateFilterOption({ ...values, "filter": value });
  }

  const updateFilterOption = async (values) => {
    setValues(values);
    if (values.selectkey) {
      let list = await refreshOptions(locale, values.selectkey, values.isUseCode);
      if (filter) list = list.filter(l => values.filter ? l.optionType === values.filter : true);
      setOptions(list.map(l => ({value:l.value, label:l.label})));
    }
  }

  const onSelectKeyChange = (value) => {
    refreshOptionTypes();
    selectedKey.current = value;
  }

  const SelectKey = ({value, onChange, ...props}) => {
    useEffect(()=> {
      onSelectKeyChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[value])
    return <Select {...props} value={value} onChange={onChange} />
  }

  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="defaultValue" label="default value">
        <Input className="item-property"/>
      </Form.Item>
      <Form.Item name="selectkey" label="select">
        <SelectKey className="item-property" options={selects} allowClear showSearch />
      </Form.Item>
      <Form.Item name="filter" label="filter">
        <Select className="item-property" options={filter} allowClear onChange={onFilterChanged}/>
      </Form.Item>
      <Form.Item name="parentkeys" label="parents">
        <Select className="item-property" mode="tags" />
      </Form.Item>
      <Form.Item name="maxOptionCount" label="max count">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="filterOption" label="filter option">
        <Select className="item-property" mode="multiple" options={options} />
      </Form.Item>
      <Form.Item name="isUseCode" label="use code" valuePropName="checked">
        <Switch className="item-property" onChange={onIsUseCodeChanged} />
      </Form.Item>
      <Form.Item name="optionGrid" label="option grid">
        <Select className="item-property">
          <Select.Option value="1">1</Select.Option>
          <Select.Option value="2">2</Select.Option>
          <Select.Option value="3">3</Select.Option>
          <Select.Option value="4">4</Select.Option>
          <Select.Option value="6">6</Select.Option>
          <Select.Option value="8">8</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="fieldWidth" label="field-width">
        <Input className="item-property" />
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  )
}

export const SpCheckbox = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppCheckbox doRef={ref => connect(drag(ref))} dbtn={dbtn} {...props} selectedStyle={style} />
  )
}

export const SppCheckbox = ({
  doRef, dbtn, selectedStyle,
  pageKey, itemKey, pageState, setPageState, value,  defaultValue,
  selectkey, filter, parentkeys, isUseCode, maxOptionCount, filterOption, optionGrid, disabled,
  ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}
  const inputProps = {selectkey, filter, parentkeys, isUseCode, maxOptionCount, filterOption, optionGrid, disabled};
  const [myValue, setMyValue] = useState(defaultValue);
  const [parentValues, setParentValues] = useState([]);

  useEffect(() => {
    syncPageState({itemKey, myValue, setMyValue, pageState, setPageState})
    if (pageState && parentkeys) {
      const parentValues = parentkeys.map(key => pageState[key]);
      setParentValues(parentValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[itemKey, myValue, pageState, setPageState])

  const onMyChange = (myValue) => {
    updatePageState({itemKey, myValue, setMyValue, pageState, setPageState})
  }
  return (
    <Col ref={doRef} {...props}>
      <SystemCheckbox value={myValue} onChange={onMyChange} parentValues={parentValues} {...inputProps} />
      {dbtn}
    </Col>
  );
}
SppCheckbox.enablePageState = true;

SpCheckbox.craft = {
  displayName: "Checkbox",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpCheckboxSetting
  }
}

registerComponent({
  key: "SpCheckbox",
  component: SpCheckbox,
  runtimeComponent: SppCheckbox,
  template: <SpCheckbox itemKey={getId('checkbox')} className="sp-checkbox" title={"Checkbox"} span={24} maxOptionCount={10} />,
  title: <IntlMessages id="system.page.library.checkbox" text="Checkbox" />,
  icon: <IoCheckboxOutline  className="react-icons icon-io"/>,
  type: "Component",
  subtype: "input",
  sequence: 6,
});

export default SpCheckbox;