import loadable from "@loadable/component"
import {iconData, FA_ICONS, MD_ICONS, ANTD_ICONS } from "./iconData"
export function DynIcon({ nameIcon, propsIcon }) {
  const lib = nameIcon.replace(/([a-z0-9])([A-Z])/g, '$1 $2').split(" ")[0].toLocaleLowerCase();
  const ElementIcon = loadable(() => import(`react-icons/${lib}/index.js`), {
    resolveComponent: (el) => el[nameIcon]
  });

  return <ElementIcon {...propsIcon} />
}

export function loadDynIcon(nameIcon) {
  const lib = nameIcon.replace(/([a-z])([A-Z0-9])/g, '$1 $2').split(" ")[0].toLocaleLowerCase();
  const ElementIcon = loadable(() => import(`react-icons/${lib}/index.js`), {
    resolveComponent: (el) => el[nameIcon]
  });
  return ElementIcon;
}

export {iconData, FA_ICONS, MD_ICONS, ANTD_ICONS };

export default DynIcon;