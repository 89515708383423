import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
  auth,
  selectApi
} from "../../parse-api";
import {
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
} from "constants/ActionTypes";
import {
  showAuthMessage, userSignInSuccess, userSignOutSuccess, userSignUpSuccess, resetPasswordSuccess,
} from "../../appRedux/actions/Auth";
import {
  endTranslate
} from "../../appRedux/actions";
import { RESET_PASSWORD, APP_SESSION } from "../../constants/ActionTypes";
import { clearCachedData, log } from "util/algorithm";
import { getLocalStorageItem, notify, refreshClientLogging, removeLocalStorageItem, setLocalStorageItem } from "../../util/algorithm";
import { idb_clear } from "../../parse-api/config";

const createUserWithEmailPasswordRequest = async (username, nickname, email, password, captcha) =>
  auth.createUserWithEmailAndPassword(username, nickname, email, password, captcha)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithEmailPasswordRequest = async (username, password) =>
  auth.signInWithEmailAndPassword(username, password)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithSessionRequest = async (session) =>
  auth.signInWithSession(session)
    .then(authUser => authUser)
    .catch(error => error);

const signOutRequest = async () =>
  auth.signOut()
    .then(authUser => authUser)
    .catch(error => error);

const resetPasswordRequest = async (email) =>
  auth.resetPassword(email)
  .then(result => result)
  .catch(error => error);

function* createUserWithEmailPassword({payload}) {
  const {username, nickname, email, password, captcha} = payload;
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, username, nickname, email, password, captcha);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      const json = JSON.stringify(signUpUser.user);
      removeLocalStorageItem('user_obj');
      selectApi.clearCache();
      refreshClientLogging();
      clearCachedData();
      yield put(userSignUpSuccess(json));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

const ignoreList = ['client_config', 'TRANSLATE_DATA_LOADING', 'language_data', 'IS_LOCALE_LOADING', 'ActiveDataLastModified', 'SharedDataLastModified', 'user_obj', 'username', 'menu_obj_enc', 'SELECTED_MENU'];

const clearBrowserCache = (signInUser) => {
  const username = getLocalStorageItem('username');
  if (username && username !== signInUser?.user?.username) {
    idb_clear('select');
    idb_clear('cache');
    const keysSnapshot = [];
    for (let i = 0; i < localStorage.length; i++) {
        keysSnapshot.push(localStorage.key(i));
    }
    for (let i = 0; i < keysSnapshot.length; i++) {
      const key = keysSnapshot[i];
      if (ignoreList.indexOf(key) === -1) {
        if (!key.startsWith('Parse/')) removeLocalStorageItem(key)
      }
    }
  }
}

function* signInUserWithEmailPassword({payload}) {
  const {username, password} = payload;
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, username, password);
    if (!signInUser) {
      yield put(showAuthMessage("Oops! Service is currently unavailable, please try again later."));
    } else if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message));
    } else if (!signInUser.user) {
      yield put(showAuthMessage("Oops! Service is currently unavailable, please try again later."));
    } else {
      const json = JSON.stringify(signInUser.user);
      setLocalStorageItem('user_obj', json);
      const oldUsername = getLocalStorageItem('username');
      if (!oldUsername || oldUsername !== signInUser?.user?.username) {
        idb_clear('select');
        idb_clear('cache');
      }
      clearBrowserCache(signInUser);
      setLocalStorageItem('username', signInUser.user.username);
      selectApi.clearCache();
      refreshClientLogging();
      clearCachedData();
      yield put(userSignInSuccess(json));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithSession(data) {
  log("signInUserWithSession", data);
  const session = data.payload;
  try {
    const signInUser = yield call(signInUserWithSessionRequest, session);
    if (!signInUser) {
      yield put(showAuthMessage("Oops! Service is currently unavailable, please try again later."));
    } else if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message));
    } else if (!signInUser.user) {
      yield put(showAuthMessage("Oops! Service is currently unavailable, please try again later."));
    } else {
      const json = JSON.stringify(signInUser.user);
      setLocalStorageItem('user_obj', json);
      const oldUsername = getLocalStorageItem('username');
      if (oldUsername && oldUsername !== signInUser?.user?.username) {
        idb_clear('select');
        idb_clear('cache');
      }
      clearBrowserCache(signInUser);
      setLocalStorageItem('username', signInUser.user.username);
      selectApi.clearCache();
      refreshClientLogging();
      clearCachedData();
      notify('sign-in', signInUser.user)
      yield put(userSignInSuccess(json));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined ||
      (signOutUser.message && signOutUser.message.indexOf('Invalid session token') !== -1)){
      selectApi.clearCache();
      refreshClientLogging();
      clearCachedData();
      notify('sign-out', {})
      yield put(endTranslate());
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* resetPassword({payload}) {
  const {email} = payload;
  try {
    const result = yield call(resetPasswordRequest, email);
    if (result === undefined) {
      removeLocalStorageItem('user_obj');
      selectApi.clearCache();
      refreshClientLogging();
      clearCachedData();
      yield put(endTranslate());
      yield put(resetPasswordSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* resetPasswordForUser() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export function* becomeUser() {
  yield takeEvery(APP_SESSION, signInUserWithSession)
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(createUserAccount),
    fork(signOutUser),
    fork(resetPasswordForUser),
    fork(becomeUser),
  ]);
}
