import { TableOutlined } from "@ant-design/icons";
import { Element, useEditor, useNode } from "@craftjs/core";
import { Col, Collapse, InputNumber, Popconfirm, Row, Space, Switch, Table } from "antd";
import { Form, Input, Select } from "components/Form";
import { useEffect, useReducer, useRef, useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { BiTrashAlt } from "react-icons/bi";
import { uid, isEmpty, log } from "util/algorithm";
import IntlMessages from "util/IntlMessages";
import ButtonArea from "./ButtonArea";
import { convertStyleStr, deleteButton, EditorCollector, getElements, getHeaderCell, getId, getRender, NodeCollector, registerComponent, SfPanelContext, SfPanelListContext, shouldUpdate } from "./common";
import Container from "./Container";
import EditPad from "./EditPad";
import SfButton from "./SfButton";
import { ModalForm } from "./SfTableEditPad";
import { SampleColumns, SampleData } from "./SfTableSampleData";
import { SortableTable } from "../../../../components/Form";
import { compare, equals } from "../../../../util/algorithm";
import { AccountStore } from "../../../../constants/Account";
import { useLbl } from "../../../../lngProvider";
import { commonSignal, settingsSignal } from "../../../../util/signal";

const normalizeKey = (key) => {
  if (key) {
      if (Array.isArray(key)) {
        key = JSON.stringify(key);
      }
      return key.replace(/[[\]",]/g, '')
  }
  return key;
}

const findSorter = (sorterArr, col) => {
  let sorter = null;
  for (const s of sorterArr) {
    const k1 = normalizeKey(s.columnKey || s.column?.dataIndex);
    const k2 = normalizeKey(col.key || col.dataIndex);
    if (k1 === k2) {
      sorter = s;
      break;
    }
  }
  return sorter;
}

const getTableFieldsValue = (form, name) => {
  return form.getFieldValue(name) || [];
}

const setTableFieldsValue = (form, name, newList) => {
  const itemKey = name[0]
  if (name.length === 1) {
    form.setFieldsValue({[itemKey]: newList});
  } else if (name.length === 2) {
    const tableKey = name[1]
    const parentValue = form.getFieldValue(itemKey) || {}
    parentValue[tableKey] = newList;
    console.log("setTableFieldsValue() setting...", {[itemKey]: parentValue})
    form.setFieldsValue({[itemKey]: parentValue})
  }
  console.log("setTableFieldsValue()", {form, name, newList})
}

export const SfTable = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  const children =
    <Element is={Container} type="SfTable" id={"children"} noStyle canvas>
      <Element is={EditPad} type="SfTableEdit" id={"edit_area"} styleStr="position:relative,marginBottom:10px" canvas>
      </Element>
      <Element is={ButtonArea} type="SfTableControl" id={"buttons"} canvas styleStr="minHeight:0px">
        <SfButton itemKey="add" className="sf-button add" buttonType="dashed" block buttonAction="add" title="Add" styleStr="marginBottom:0px" />
      </Element>
    </Element>
  return (
    <SfpTable doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props}>{children}</SfpTable>
  )
}

const IGNORE_TAG = /^(input|button)$/i
const getCellIndex = (target, level) => {
  if (level > 10) return 0;
  if (!target) return 0;
  if (target?.nodeName?.match(IGNORE_TAG)) {
    return 0;
  } else if (typeof target?.cellIndex === 'number') {
    return target.cellIndex;
  } else {
    return getCellIndex(target.parentElement, level ? level + 1 : 1);
  }
}

const callChildOnClick = (event, target, level) => {
  if (level > 10) return;
  if (!target) target = event?.target;
  if (!target) return;
  if (target?.onclick) {
    try {
      target.click()
      return true;
    } catch (e) {
      log('onclick error', e)
      return false;
    }
  } else {
    for (const c of target.children) {
      const success = callChildOnClick(event, c, level ? level + 1 : 1);
      if (success) {
        return success;
      }
    }
    return false;
  }
}

const Compare = (index) => {
  return (a, b) => {
    const v1 = a[index];
    const v2 = b[index];
    if (v1 > v2) {
      return 1;
    } else if (v1 < v2) {
      return -1;
    } else {
      return 0;
    }
  }
}

const rowKey = (record) => {
  if (record.index) return record.index;
  if (record.objectId) return record.objectId;
  if (record.rowKey) return record.rowKey;
  log("row has no id", record);
  return null;
}

const SfpTableDesignView = ({doRef, cls, sls, showTitle, titleEle, bordered, size, pagination, scrollConfig, SampleColumnsSorted, demoData, children, dbtn}) => {
  return (
      <div ref={doRef} className={cls} style={sls}>
        <div className="ant-card-head">
          <div className="ant-card-head-wrapper">
            <div className="ant-card-head-title">{showTitle && titleEle}</div>
          </div>
        </div>
        <div className="ant-card-body">
          <Table
            bordered={bordered}
            size={size}
            pagination={pagination}
            scroll={scrollConfig}
            columns={SampleColumnsSorted}
            dataSource={demoData ? SampleData : []} />
          {children}
        </div>
        {dbtn}
      </div>
    );
}

const HeaderCell = ({
  forwardRef,
  children,
  columnHeaderComponent,
  onHeaderCell,
  hideInMobile,
  hideInTable,
  dataIndex,
  render,
  ...restProps
}) => {
  if (columnHeaderComponent) {
    return (
      <th ref={forwardRef} {...restProps}>
        <div className="sf-table-override-header">{columnHeaderComponent}{children}</div>
      </th>
    )
  } else {
    return (
      <th ref={forwardRef} {...restProps}>
        {children}
      </th>
    )
  }
}

const SfpTableRuntimeView = ({
  childNodes, form, itemKey, name, onCellChange, sortable, manualSort,
  setVisible, onRowChange, forceUpdate, nopopup, setEdit, disabled,
  nodel, loading, bordered, size, nopaging, pagination, scrollConfig,
  addRow, noadd, top, table, visible, onFinish, onCancel, edit, formKey,
  processNode, title, layout, labelAlign, labelColStr, colon, width, centered,
  collapsible, fxr, fx, showTitle, titleEle, cls, sls, defaultCollapse,
  expandable, expandedLabelCol, defaultExpandAllRows, tableLayout
}) => {
  const { locale } = settingsSignal;
  const { width: screenWidth } = commonSignal;
  const [sorter, setSorter] = useState(null);
  const [childButtons, setChildButtons] = useState([]);
  const [childInputs, setChildInputs] = useState([]);
  const [childColumns, setChildColumns] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [finalColumns, setFinalColumns] = useState([]);
  const lbl = useLbl(locale);
  const mounted = useRef();
  const ctrlRef = useRef();

  const onKeyDown = (e) => {
    ctrlRef.current = true;
  }
  const onKeyUp = (e) => {
    ctrlRef.current = false;
  }
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    window.addEventListener('keyup', onKeyUp)
    mounted.current = true;
    return () => {
      window.removeEventListener('keydown', onKeyDown)
      window.removeEventListener('keyup', onKeyUp)
      mounted.current = false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const buttons = childNodes
      .filter(n => n.type.resolvedName === 'SfButton');
    setChildButtons(buttons);

    const inputs = childNodes
      .filter(n => n.type.resolvedName !== 'SfButton');
    setChildInputs(inputs);

    let columns = inputs.map(n => {
      return {
        title: isEmpty(n.props.tableColTitle || n.props.title) ? "" : lbl(`form.${formKey}.${itemKey}.col.${n.props.itemKey}`, n.props.tableColTitle || n.props.title),
        dataIndex: n.props.itemKey,
        render: getRender(n, form, itemKey, onCellChange),
        width: n.props.tableColWidth ? `${n.props.tableColWidth}px` : null,
        hideInTable: n.props.hideInTable,
        hideInMobile: n.props.hideInMobile,
        onHeaderCell: getHeaderCell(n, form, itemKey, onCellChange),
      }
    }).filter(c => !c.hideInTable);
    setChildInputs(inputs);

    columns = (!sortable || manualSort) ? columns :
      columns.map((col, i) => {
        const newCol = { ...col };
        const index = newCol.dataIndex;
        newCol.sorter = {
          compare: Compare(index),
          multiple: columns.length - i
        }
        return newCol;
      })

    const actionCol = prepareActionCol({nopopup, disabled, nodel, onEdit, onDelete});
    columns = (nodel) ? columns : [actionCol, ...columns];
    setChildColumns(columns);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childNodes]);

  useEffect(() => {
    if (screenWidth < AccountStore.MOBILE_BREAKPOINT) {
      const hiddenColumns = childColumns.filter(col => !!col.hideInMobile);
      const finalColumns = childColumns.filter(col => !col.hideInMobile);
      setHiddenColumns(hiddenColumns);
      setFinalColumns(finalColumns);
    } else {
      setHiddenColumns([]);
      setFinalColumns(childColumns);
    }
  },[screenWidth, childColumns])

  useEffect(() => {
    if (sorter && finalColumns) {
      const newColumns = updateSorter(finalColumns, sorter);
      if (!equals(newColumns, finalColumns)) {
        setFinalColumns(newColumns);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sorter, finalColumns])

  const getSortOrder = (col, sorterArr) => {
    if (!Array.isArray(sorterArr)) {
      sorterArr = [sorterArr]
    }
    const sorter = findSorter(sorterArr, col);
    if (sorter && sorter.column && sorter.field && (col.dataIndex === sorter.field || equals(col.dataIndex, sorter.field))) {
      if (sorter.order === 'descend') {
        return 'descend';
      } else {
        return 'ascend';
      }
    } else {
      return false;
    }
  }

  const updateSorter = (columns, sorter) => {
    if (columns && sorter) {
      const newColumns = columns.map((col, i) => {
        const newCol = { ...col };
        newCol.sortOrder = getSortOrder(col, sorter);
        return newCol;
      });
      return newColumns;
    } else {
      return columns;
    }
  }

  const list = getTableFieldsValue(form, name);

  const onSort = (sortedDataSource, indexes) => {
    const list = getTableFieldsValue(form, name);
    const updatedList = list.map(l => {
      const l2 = {...l};
      if (l2.key) l2.rowKey = l2.key;
      delete l2.key;
      l2.index = indexes[l2.rowKey];
      return l2;
    })
    const newList = updatedList.sort((a, b) => compare(a.index, b.index));
    setTableFieldsValue(form, name, newList);
    if (onRowChange) onRowChange();
  }

  const onDelete = (index) => {
    const list = getTableFieldsValue(form, name);
    if (index < list.length) {
      const nList = list.filter((r, i) => index !== i);
      setTableFieldsValue(form, name, nList);
      if (onRowChange) onRowChange();
      forceUpdate();
    }
  }

  const onEdit = (index, record) => {
    if (!nopopup) {
      const list = getTableFieldsValue(form, name);
      if (record?.rowKey) {
        const i = list.findIndex(r => r.rowKey === record.rowKey);
        if (i !== -1) {
          index = i;
        }
      }
      if (index < list.length) {
        const record = list[index];
        setEdit({ index: index, record: { ...record }, form });
        setVisible(true);
      }
    }
  }

  const onChange = (pagination, filter, sorter, extra) => {
    if (!ctrlRef.current && sorter && sorter.length > 0) {
      setSorter([sorter.at(-1)])
    } else {
      setSorter(sorter)
    }
  }

  const tableContent = <TableContent
    {...{
      manualSort, loading, bordered, size, nopaging, pagination, scrollConfig,
      finalColumns, hiddenColumns, list, disabled, onEdit, onSort, addRow, noadd, childButtons,
      processNode, title, layout, labelAlign, labelColStr, colon, width, screenWidth, centered,
      top, table, visible, onCancel, onFinish, childInputs, edit, formKey, itemKey,
      expandable, expandedLabelCol, defaultExpandAllRows, tableLayout, onChange
    }}
  />

  return (
    <>
      <Form.Item name={itemKey+'_OnChange'} hidden shouldUpdate={fx || true}>
        <Input/>
      </Form.Item>
      {!fxr.hidden && !collapsible && <div className={cls} style={sls}>
        <div className="ant-card-head">
          <div className="ant-card-head-wrapper">
            <div className="ant-card-head-title">{showTitle && titleEle}</div>
          </div>
        </div>
        {tableContent}
      </div>}
      {!fxr.hidden && collapsible && <Collapse className={cls} style={sls} defaultActiveKey={defaultCollapse ? [] : ["1"]}>
        <Collapse.Panel header={showTitle && titleEle} key="1">
          {tableContent}
        </Collapse.Panel>
      </Collapse>}
    </>
  );
}


const prepareActionCol = ({nopopup, disabled, nodel, onEdit, onDelete}) => {
  return {
      title: <IntlMessages id={`system.form.table-action`} text={'Action'}/>,
      key: 'action',
      width: '45px',
      render: (text, record, index) => (
        <Space key={"Action_"+index}>
          {!nopopup && <span className="gx-link" onClick={() => onEdit(index, record)}><BiEditAlt /></span>}
          {!disabled && !nodel && <Popconfirm
            title={<IntlMessages id="system.form.confirm.delete" text="Are you sure to delete this form?" />}
            onConfirm={() => onDelete(index)}
            okText={<IntlMessages id="system.confirm.yes" text="Yes" />}
            cancelText={<IntlMessages id="system.confirm.no" text="No" />}
          >
            <span className="gx-link"><BiTrashAlt /></span>
          </Popconfirm>}
        </Space>
      ),
    }
}

const TableContent = ({
  manualSort, loading, bordered, size, nopaging, pagination, scrollConfig,
  finalColumns, hiddenColumns, list, disabled, onEdit, onSort, addRow, noadd,
  processNode, title, layout, labelAlign, labelColStr, colon, width, screenWidth, centered,
  top, table, visible, onCancel, onFinish, childInputs, edit, formKey, itemKey,titleEle,
  childButtons, expandable, expandedLabelCol, defaultExpandAllRows, tableLayout, onChange
}) => {
  if (!expandedLabelCol) expandedLabelCol = 8;
  const expandedRowRender = (record, index, indent, expanded) => {
    const list = hiddenColumns.map(c => {
      const title = c.title;
      const render = c.render;
      const itemKey = c.dataIndex;
      const value = record[itemKey];
      return (
        <Row className={'expanded-row'} key={`${c.dataIndex}_${index}`}>
          <Col span={expandedLabelCol}><label>{title}</label></Col>
          <Col span={24 - expandedLabelCol}>{render(value, record, index)}</Col>
        </Row>
      )
    })
    return list;
  }
  const showExpandColumn = defaultExpandAllRows === true ? false : undefined ;
  let _expandable = undefined;
  if (expandable && screenWidth < AccountStore.MOBILE_BREAKPOINT && hiddenColumns.length > 0) {
    _expandable = {expandedRowRender, defaultExpandAllRows, showExpandColumn}
  }
  return (
    <div className="ant-card-body">
      {!manualSort && <Table
        loading={loading}
        bordered={bordered}
        size={size}
        pagination={!nopaging && pagination}
        scroll={scrollConfig}
        columns={finalColumns}
        dataSource={list}
        rowKey={rowKey}
        rowClassName={"clickable-row"}
        expandable={_expandable}
        tableLayout={tableLayout}
        onChange={onChange}
        components={{
          header: {
            cell: HeaderCell,
          }
        }}
        onRow={(record,index) => ({
          onClick: (event) => {
            if (callChildOnClick(event)) {
              // ignore
            } else {
              if (disabled || getCellIndex(event.target) > 0) {
                onEdit(index, record);
              }
            }
          },
        })} />}
      {manualSort && <SortableTable
        loading={loading}
        bordered={bordered}
        size={size}
        scroll={scrollConfig}
        columns={finalColumns}
        dataSource={list}
        rowKey={rowKey}
        onSort={onSort}
        expandable={_expandable}
        tableLayout={tableLayout}
        components={{
          header: {
            cell: HeaderCell,
          }
        }}
        onRow={(record,index) => ({
          onClick: (event) => {
            if (getCellIndex(event.target) > 1) {
              onEdit(index, record);
            }
          },
        })} />}
      <SfPanelListContext.Provider value={{ addRow: addRow }}>
        {!noadd && childButtons.map(b => {
          b.props.key = b.props.itemKey;
          return processNode(b)
        })}
      </SfPanelListContext.Provider>
      <ModalForm
        title={title || title}
        layout={layout}
        labelAlign={labelAlign}
        labelColStr={labelColStr}
        colon={colon}
        width={width}
        centered={centered}
        top={top}
        table={table}
        visible={visible}
        onCancel={onCancel}
        onFinish={onFinish}
        childNodes={childInputs}
        processNode={processNode}
        record={edit.record}
        parentForm={edit.form}
        formKey={formKey}
        itemKey={itemKey}
        disabled={disabled}
      />
    </div>
  )
}

export const SfpTable = ({
  formKey, form, condistyles, doRef, style, dbtn, children, itemKey, className, styleStr,
  title, showTitle, collapsible, defaultCollapse, bordered, sortable, manualSort, noadd, nodel, nopopup,
  demoData, size, nopaging, paginationPosition, pageSize, scroll, layout, width, labelColStr, labelAlign,
  colon, disabled, centered, top, table, childNodes, processNode, isSelectKey, onRowChange, loading,
  ...otherProps }) => {
  const cls = "ant-card ant-card-bordered " + (manualSort ? " manualsort " : "") + (className ? className : "");
  let sls = convertStyleStr(styleStr);
  const [visible, setVisible] = useState(false);
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [edit, setEdit] = useState({ index: -1, record: {}, form });
  const [fxr, setFxr] = useState({});
  if (style) sls = sls ? {...sls, ...style} : style;
  if (fxr.hidden) {
    if (!sls) sls = {};
    sls.display = "none";
  }
  const fx = shouldUpdate({condistyles, form, ctx: {}, style, setFxr});



  const onCancel = () => {
    setVisible(false);
  };

  const onCellChange = () => {
    if (onRowChange) onRowChange();
    forceUpdate();
  }

  const last = SampleColumns.length - 1
  const SampleColumnsSorted = !sortable ? SampleColumns :
    SampleColumns.map((col, i) => {
      const newCol = { ...col };
      const index = newCol.dataIndex;
      if (i !== last) {
        newCol.sorter = {
          compare: Compare(index),
          multiple: SampleColumns.length - i
        }
      }
      return newCol;
    })

  pageSize = pageSize ? pageSize : 5;
  const pagination = { pageSize: pageSize }
  if (paginationPosition) pagination.position = [paginationPosition];
  const scrollConfig = {};
  if (scroll) scrollConfig.x = "max-content";
  const titleEle = <IntlMessages id={`form.${formKey}.${itemKey}.title`} text={title}/>;
  if (doRef) {
    return (
      <SfpTableDesignView
        {...{doRef, cls, sls, showTitle, titleEle,
        bordered, size, pagination, scrollConfig,
        SampleColumnsSorted, demoData, children, dbtn}}
      />
    );
  } else {
    return (
      <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const addRow = () => {
          if (nopopup) {
            onFinish({rowKey: uid()})
          } else {
            setEdit({ index: -1, record: {rowKey: uid()}, form });
            setVisible(true);
          }
        };

        const onFinish = (values) => {
          const list = getTableFieldsValue(form, name);
          let found = false;
          list.forEach((l) => {
            if (l.rowKey === values.rowKey) {
              found = true;
            }
          })
          if (found) {
            const nList = list.map((r, i) => i === edit.index ? values : r);
            setTableFieldsValue(form, name, nList);
          } else {
            const newList = [...list, {...values, index: list.length}];
            setTableFieldsValue(form, name, newList);
          }
          setVisible(false);
          if (onRowChange) onRowChange();
          forceUpdate();
        }
        return (<SfpTableRuntimeView
          {...{childNodes, form, itemKey, name, onCellChange, sortable, manualSort,
          setVisible, onRowChange, forceUpdate, nopopup, setEdit, disabled,
          nodel, loading, bordered, size, nopaging, pagination, scrollConfig,
          addRow, noadd, top, table, visible, onFinish, onCancel, edit, formKey,
          processNode, title, layout, labelAlign, labelColStr, colon, width, centered,
          collapsible, fxr, fx, showTitle, titleEle, cls, sls, defaultCollapse, ...otherProps}}
        />)
    }}
    </SfPanelContext.Consumer>
    );
  }
}

const SfTableSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="showTitle" label="show title" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="collapsible" label="collapsible" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="defaultCollapse" label="default collapse" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="bordered" label="bordered" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="sortable" label="sortable" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="manualSort" label="manual sort" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="noadd" label="no add" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="nodel" label="no del" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="nopopup" label="no popup" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="scroll" label="scroll" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="demoData" label="demo data" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="size" label="size">
        <Select className="item-property">
          <Select.Option value="small">small</Select.Option>
          <Select.Option value="middle">middle</Select.Option>
          <Select.Option value="default">default</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="nopaging" label="no paging" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="pageSize" label="page size">
        <InputNumber className="item-property" min={3} max={100} />
      </Form.Item>
      <Form.Item name="paginationPosition" label="pagination">
        <Select className="item-property">
          <Select.Option value="topLeft">topLeft</Select.Option>
          <Select.Option value="topCenter">topCenter</Select.Option>
          <Select.Option value="topRight">topRight</Select.Option>
          <Select.Option value="bottomLeft">bottomLeft</Select.Option>
          <Select.Option value="bottomCenter">bottomCenter</Select.Option>
          <Select.Option value="bottomRight">bottomRight</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="tableLayout" label="table layout">
        <Select className="item-property">
          <Select.Option value="auto">auto</Select.Option>
          <Select.Option value="fixed">fixed</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="layout" label="form layout">
        <Select className="item-property">
          <Select.Option value="horizontal">horizontal</Select.Option>
          <Select.Option value="vertical">vertical</Select.Option>
          <Select.Option value="inline">inline</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="colon" label="colon" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="labelAlign" label="label align">
        <Select className="item-property">
          <Select.Option value="left">left</Select.Option>
          <Select.Option value="right">right</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="labelColStr" label="label col">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="width" label="form width">
        <InputNumber className="item-property" min={400} max={1000} />
      </Form.Item>
      <Form.Item name="centered" label="centered" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="top" label="top">
        <InputNumber className="item-property" min={0} />
      </Form.Item>
      <Form.Item name="expandable" label="expandable" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="expandedLabelCol" label="exp lbl width">
        <InputNumber className="item-property" min={6} max={12} />
      </Form.Item>
      <Form.Item name="defaultExpandAllRows" label="expand all" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="isSelectKey" label="select key" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="valueKey" label="val. key">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="labelKey" label="lbl. key">
        <Input className="item-property" />
      </Form.Item>
    </>
  );
}

SfpTable.collector = () => getElements("Component");
SfpTable.forwardAttributes = ["loading"];

SfTable.craft = {
  displayName: "Table",
  related: {
    settings: SfTableSetting
  }
}

SfTable.validate = (props, {parents, formData, extraParams}) => {
  props.formKey = formData.formKey;
  if (props.isSelectKey) {
    if (!props.labelKey) {
      return <IntlMessages id="system.form.validate.label-key-missing" text="item ({itemKey}) - label key is missing." values={{itemKey:props.itemKey}}/>
    }
    if (!props.valueKey) {
      return <IntlMessages id="system.form.validate.value-key-missing" text="item ({itemKey}) - label key is missing." values={{itemKey:props.itemKey}}/>
    }
    if (props.labelKey.indexOf("{") === -1) {
      extraParams.selectConfig.labelKey += "{" + props.labelKey + "}";
    } else {
      extraParams.selectConfig.labelKey += props.labelKey;
    }
    if (props.valueKey.indexOf("{") === -1) {
      extraParams.selectConfig.valueKey += "{" + props.valueKey + "}";
    } else {
      extraParams.selectConfig.valueKey += props.valueKey;
    }
    extraParams.selectConfig.optionTypes.push(props.itemKey);
  }
  extraParams.dataClassConfig.columns.push({
    dataIndex: props.itemKey,
    index: extraParams.dataClassConfig.columns.length,
    type: "json[]",
    picker: props.picker,
    format: props.format,
    title: props.tableColTitle || props.title,
    width: props.tableColWidth,
    hiddenForReadOnly: false,
    sortable: true,
    editable: false,
    permission: props.permission,
    volitate: props.volitate,
  });
  console.log("SfTable validate()", extraParams)
}

registerComponent({
  key:"SfTable",
  component: SfTable,
  runtimeComponent: SfpTable,
  template: <SfTable itemKey={getId('table')} className="sf-panel sf-table" title={"Table"} showTitle={true} colon={true}/>,
  title: <IntlMessages id="system.form.library.table" text="Table" />,
  icon: <TableOutlined  className="react-icons icon-antd"/>,
  type: "Container",
});

export default SfTable;