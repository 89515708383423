import React, { useState } from "react";
import { GatewayOutlined } from "@ant-design/icons";
import { Element, useEditor, useNode } from "@craftjs/core";
import { Collapse, InputNumber, Switch } from "antd";
import { Form, Input } from "components/Form";
import IntlMessages from "util/IntlMessages";
import { convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, persistentInfo, registerComponent, SfPanelContext, shouldUpdate } from "./common";
import { Container } from "./Container";

export const SfPanel = ({...props}) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  const children =
    <Element is={Container} type="SfPanel" id={"children"} canvas>
    </Element>
  return (
    <SfpPanel doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props}>{children}</SfpPanel>
  )
}

export const SfpPanel = ({
  doRef, formKey, form, condistyles, style, dbtn, children, itemKey, className, styleStr, title, table, showTitle, collapsible, defaultCollapse, ...otherProps }) => {
  const cls = "ant-card ant-card-bordered " + (className ? className : "");
  let sls = convertStyleStr(styleStr);
  const panelContext = {name: [itemKey]};
  const [fxr, setFxr] = useState({});
  if (style) sls = sls ? {...sls, ...style} : style;
  if (fxr.hidden) {
    if (!sls) sls = {};
    sls.display = "none";
  }
  const fx = shouldUpdate({condistyles, form, ctx: {}, style, setFxr});
  const titleEle = <IntlMessages id={`form.${formKey}.${itemKey}.title`} text={title}/>
  return (
    <SfPanelContext.Provider value={panelContext}>
      <Form.Item name={itemKey+'_OnChange'} hidden shouldUpdate={fx}>
        <Input/>
      </Form.Item>
      {(doRef || (!fxr.hidden && !collapsible)) && <div ref={doRef} className={cls} style={sls}>
        {showTitle && <div className="ant-card-head">
          <div className="ant-card-head-wrapper">
            <div className="ant-card-head-title">{titleEle}</div>
          </div>
        </div>}
        <div className="ant-card-body">
          {persistentInfo(table, panelContext.name)}
          {children}
        </div>
        {dbtn}
      </div>}
      {!doRef && !fxr.hidden && collapsible && <Collapse className={cls} style={sls} defaultActiveKey={defaultCollapse ? [] : ["1"]}>
        <Collapse.Panel header={showTitle && titleEle} key="1">
          <div className="ant-card-body">
            {persistentInfo(table, panelContext.name)}
            {children}
          </div>
        </Collapse.Panel>
      </Collapse>}
    </SfPanelContext.Provider>
  );
}

const SfPanelSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property"/>
      </Form.Item>
      <Form.Item name="showTitle" label="show title" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="collapsible" label="collapsible" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="defaultCollapse" label="default collapse" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
    </>
  );
}

SfPanel.craft = {
  displayName: "Panel",
  related: {
    settings: SfPanelSetting
  }
}

SfPanel.validate = (props, {parents, formData, extraParams}) => {
  props.formKey = formData.formKey;
  extraParams.dataClassConfig.columns.push({
    dataIndex: props.itemKey,
    index: extraParams.dataClassConfig.columns.length,
    type: "json",
    picker: props.picker,
    format: props.format,
    title: props.tableColTitle || props.title,
    width: props.tableColWidth,
    sortable: true,
    editable: false,
    permission: props.permission,
    volitate: props.volitate,
    hiddenForReadOnly: false,
  });
}

registerComponent({
  key:"SfPanel",
  component: SfPanel,
  runtimeComponent: SfpPanel,
  template: <SfPanel itemKey={getId('panel')}
            className="sf-panel" title={"Panel"} showTitle={true}/>,
  title: <IntlMessages id="system.form.library.panel" text="Panel" />,
  icon: <GatewayOutlined  className="react-icons icon-antd"/>,
  type: "Container",
});

export default SfPanel;