import { StarOutlined } from "@ant-design/icons";
import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Rate, Switch } from "antd";
import { Form } from "components/Form";
import React, {useEffect, useState} from "react";
import IntlMessages from "util/IntlMessages";
import { Input } from "../../../../components/Form";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting, syncPageState, updatePageState } from "./common";

const SpRateSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="defaultValue" label="default value">
        <InputNumber min={0} step={0.5} className="item-property"/>
      </Form.Item>
      <Form.Item name="count" label="count">
        <InputNumber min={5} className="item-property"/>
      </Form.Item>
      <Form.Item name="allowHalf" label="allow-half" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  )
}

export const SpRate = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppRate doRef={ref => connect(drag(ref))} dbtn={dbtn} {...props} selectedStyle={style} />
  )
}

export const SppRate = ({
  doRef, dbtn, selectedStyle,
  pageKey, itemKey, pageState, setPageState,
  count, value, defaultValue, allowHalf, disabled, ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}
  const [myValue, setMyValue] = useState(defaultValue);

  useEffect(() => {
    syncPageState({itemKey, myValue, setMyValue, pageState, setPageState})
  },[itemKey, myValue, pageState, setPageState])

  const onMyChange = (myValue) => {
    updatePageState({itemKey, myValue, setMyValue, pageState, setPageState})
  }

  return (
    <Col ref={doRef} {...props}>
      <Rate value={myValue} onChange={onMyChange} count={count} allowHalf={allowHalf} disabled={disabled} />
      {dbtn}
    </Col>
  );
}
SppRate.enablePageState = true;

SpRate.craft = {
  displayName: "Rate",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpRateSetting
  }
}

registerComponent({
  key: "SpRate",
  component: SpRate,
  runtimeComponent: SppRate,
  template: <SpRate itemKey={getId('rate')} className="sp-rate" title={"Rate"} span={24} />,
  title: <IntlMessages id="system.page.library.rate" text="Rate" />,
  icon: <StarOutlined  className="react-icons icon-antd"/>,
  type: "Component",
  subtype: "input",
  sequence: 11,
});

export default SpRate;