import { useNode } from "@craftjs/core";
import { InputNumber, Switch } from "antd";
import { Form, Input, Select } from "components/Form";
import { useEffect, useRef } from "react";
import { applyCss, isEmptyString } from "util/algorithm";
import { defaultCss } from "../defaultForm";
import { convertStyleStr, getDefaultValidateMessage, NodeCollector, registerComponent } from "./common";
import { settingsSignal } from "../../../../util/signal";

export const SfCanvas = ({ ...props }) => {
  const { connectors: { connect, drag } } = useNode(NodeCollector);
  return (
    <SfpCanvas doRef={ref => connect(drag(ref))} {...props} />
  )
}

export const SfpCanvas = ({
  doRef, itemKey, styleStr, labelColStr, children, css, formDefaultValue,
  cssOverride, optionalMark, scrollToFirstError,
  hideVersion, autoClose, showLoading, affixButtonBar, disabled,
  ...otherProps }) => {
  const { locale } = settingsSignal;
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const ref = useRef();
  if (optionalMark) otherProps.requiredMark = "optional";
  useEffect(() => {
    return () => {
      applyCss(defaultCss, itemKey, ref);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  useEffect(() => {
    if (isEmptyString(css)) {
      applyCss(defaultCss, itemKey, ref);
    } else {
      if (cssOverride) {
        applyCss(css, itemKey, ref);
      } else {
        applyCss(defaultCss+" "+css, itemKey, ref);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[css]);

  const validateMessages = getDefaultValidateMessage(locale);

  return (
    <Form name={itemKey} style={sls} labelCol={lcs}
      validateMessages={validateMessages} scrollToFirstError={true}
      {...otherProps}>
      <div ref={doRef || ref} className="craft-form-user-canvas">
        {children}
      </div>
    </Form>
  )
}

const SfCanvasSetting = () => {
  return (
    <>
      <Form.Item name="systemExport" label="sys. export" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="hideVersion" label="hide version" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="autoClose" label="auto close" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="showLoading" label="show loading (sec)">
        <InputNumber className="item-property"  min={1} placeholder="second"/>
      </Form.Item>
      <Form.Item name="affixButtonBar" label="buttons affix " valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="colon" label="colon" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="requiredMark" label="required" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="optionalMark" label="optional" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="scrollToFirstError" label="error scroll" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="layout" label="layout">
        <Select className="item-property">
          <Select.Option value="horizontal">horizontal</Select.Option>
          <Select.Option value="vertical">vertical</Select.Option>
          <Select.Option value="inline">inline</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="size" label="size">
        <Select className="item-property">
          <Select.Option value="small">small</Select.Option>
          <Select.Option value="middle">middle</Select.Option>
          <Select.Option value="large">large</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="labelAlign" label="label align">
        <Select className="item-property">
          <Select.Option value="left">left</Select.Option>
          <Select.Option value="right">right</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="labelColStr" label="label col">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="formDefaultValue" label="default-value" className="default-value"
        labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} >
        <Input.TextArea
          className="item-property"
          autoSize allowClear bordered={false}
          />
      </Form.Item>
    </>
  );
}

const ACCEPTED_LIST = ["SfMainPanel", "SfPanel", "SfPanelList", "SfTabs", "SfTable", "SfComment"];
SfCanvas.craft = {
  displayName: "Canvas",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      let rtnVal = false;
      rtnVal = (ACCEPTED_LIST.indexOf(incomingNode.data.name) !== -1);
      return rtnVal;
    }
  },
  related: {
    settings: SfCanvasSetting
  }
}

SfCanvas.validate = (props, {extraParams, formData}) => {
  props.itemKey = formData.formKey;
  extraParams.defaultValues = props.formDefaultValue;
  extraParams.hideVersion = props.hideVersion;
  extraParams.showLoading = props.showLoading;
  extraParams.autoClose = props.autoClose;
  extraParams.affixButtonBar = props.affixButtonBar;
  extraParams.dataClassConfig.systemExport = props.systemExport;
}

registerComponent({
  key: "SfCanvas",
  component: SfCanvas,
  runtimeComponent: SfpCanvas,
  type: "Container",
});

export default SfCanvas;