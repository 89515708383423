import { Alert, Input } from "antd";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";

export const JsonTextArea = ({
  value, onChange,
  ...props}) => {
  const [myError, setMyError] = useState();
  const [myValue, setMyValue] = useState();

  const parseValue = (value) => {
    try {
      if (!isEmpty(value)) {
        setMyError(null);
        value = JSON.stringify(JSON.parse(value), null, 2);
      }
    } catch (err) {
      setMyError(err);
      console.log("failed to parse config", err)
    }
    return value;
  }

  useEffect(() => {
    const val = parseValue(value);
    if (val !== myValue) {
      setMyValue(val);
    }
  }, [value, myValue])

  const onMyChange = (e, value) => {
    if (!value) value = e.target.value;
    const val = parseValue(value);
    setMyValue(val);
    if (onChange) onChange(val);
  }

  return (
    <>
      {myError && <Alert type="error" message={`${myError}`} banner className={"json-textarea-error show"}/>}
      <Input.TextArea
        {...props}
        value={myValue}
        onChange={onMyChange}
        className="item-property"
        autoSize allowClear
      />
    </>
  )
}
export default JsonTextArea;