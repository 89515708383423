import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Switch } from "antd";
import { Form, Input } from "components/Form";
import React, { useEffect, useRef, useState } from "react";
import { BsCalendarWeek } from "react-icons/bs";
import IntlMessages from "util/IntlMessages";
import { AccountStore } from "../../../../constants/Account";
import { equals } from "../../../../util/algorithm";
import { MyCalendar } from "../../FormSetup/components/SfCalendar";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting, syncPageState, updatePageState } from "./common";

const SpCalendarSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="defaultValue" label="default value" className="defaultValue"
        labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} >
        <Input.TextArea
          className="item-property"
          autoSize allowClear
        />
      </Form.Item>
      <Form.Item name="fullscreen" label="fullscreen" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="dateFormat" label="date format">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="debug" label="debug" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="nodrag" label="no drag" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="noadd" label="no add" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="noedit" label="no edit" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="nocopy" label="no copy" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="nodelete" label="no delete" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="customdialog" label="custom dialog" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="autocolor" label="auto color" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="doublerow" label="double row" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="row" label="row">
        <InputNumber min="6" className="item-property" />
      </Form.Item>
      <Form.Item name="responsive" label="responsive" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  )
}

export const SpCalendar = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppCalendar doRef={ref => connect(drag(ref))} dbtn={dbtn} {...props} selectedStyle={style} />
  )
}

export const SppCalendar = ({
  doRef, dbtn, selectedStyle,
  pageKey, itemKey, pageState, setPageState, value,  defaultValue,
  fullscreen, dateFormat, disabled, debug, nodrag,
  noadd, noedit, nocopy, nodelete, customdialog,
  autocolor, doublerow, row, responsive, hidden, ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  if (!doRef && hidden) style.display = 'none';
  const props = {...otherProps, style}
  const inputProps = {fullscreen, dateFormat, disabled, debug, nodrag, noadd, noedit, nocopy, nodelete, customdialog, autocolor, doublerow, row, responsive};
  const [myValue, setMyValue] = useState(defaultValue);
  const timer = useRef()
  const pageStateValueRef = useRef();

  useEffect(() => {
    if (pageState) {
      const val1 = pageStateValueRef.current;
      const val2 = pageState[itemKey];
      if (val2 && myValue && !val2.selected) val2.selected = myValue.selected;
      if (val2 && myValue && !val2.mode) val2.mode = myValue.mode;
      if (!val1 || !equals(val1, val2)) {
        pageStateValueRef.current = val2;
        syncPageState({itemKey, myValue, setMyValue, pageState})
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[itemKey, pageState])

  const onMyChange = (myValue) => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      updatePageState({itemKey, myValue, setMyValue, pageState, setPageState})
    }, AccountStore.ON_CHANGE_DELAY);
  }

  return (
    <Col ref={doRef} {...props}>
      <MyCalendar id={itemKey} value={myValue} onChange={onMyChange} {...inputProps} />
      {dbtn}
    </Col>
  );
}
SppCalendar.enablePageState = true;

SpCalendar.craft = {
  displayName: "Calendar",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpCalendarSetting
  }
}

registerComponent({
  key: "SpCalendar",
  component: SpCalendar,
  runtimeComponent: SppCalendar,
  template: <SpCalendar itemKey={getId('calendar')} className="sp-calendar calendar" title={"Calendar"} span={24} row={6} fullscreen={true} />,
  title: <IntlMessages id="system.page.library.calendar" text="Calendar" />,
  icon: <BsCalendarWeek className="react-icons icon-io5"/>,
  type: "Component",
  subtype: "input",
  sequence: 19,
});

export default SpCalendar;