import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Switch } from "antd";
import { Form, Input } from "components/Form";
import React, {useEffect, useRef, useState} from "react";
import { BsInputCursorText } from "react-icons/bs";
import IntlMessages from "util/IntlMessages";
import { AccountStore } from "../../../../constants/Account";
import { MyInput } from "../../FormSetup/components/SfInput";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting, syncPageState, updatePageState } from "./common";

const SpInputSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="defaultValue" label="default value">
        <Input className="item-property"/>
      </Form.Item>
      <Form.Item name="placeholder" label="placeholder">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="prefix" label="prefix">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="suffix" label="suffix">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="maxLength" label="max len">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="allowClear" label="allow clear" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="password" label="password" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="readOnly" label="read-only" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="action" label="action">
        <Input className="item-property"/>
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  )
}

export const SpInput = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppInput doRef={ref => connect(drag(ref))} dbtn={dbtn} {...props} selectedStyle={style} />
  )
}

export const SppInput = ({
  doRef, dbtn, selectedStyle,
  pageKey, itemKey, pageState, setPageState, value,  defaultValue,
  placeholder, prefix, suffix, maxLength, allowClear, password, readOnly, disabled, action,
  ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}
  const inputProps = {placeholder, prefix, suffix, maxLength, allowClear, password, readOnly, disabled};
  const [myValue, setMyValue] = useState(defaultValue);
  const timer = useRef()

  useEffect(() => {
    syncPageState({itemKey, myValue, setMyValue, pageState, setPageState})
  },[itemKey, myValue, pageState, setPageState])

  const onMyChange = (myValue) => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      updatePageState({itemKey, myValue, setMyValue, pageState, setPageState})
    }, AccountStore.ON_CHANGE_DELAY);
  }

  const onPressEnter = (myValue) => {
    updatePageState({itemKey, myValue, setMyValue, pageState, setPageState, action})
  }

  return (
    <Col ref={doRef} {...props}>
      <MyInput value={myValue} onChange={onMyChange} {...inputProps} onPressEnter={onPressEnter} triggerOnChange={true} />
      {dbtn}
    </Col>
  );
}
SppInput.enablePageState = true;

SpInput.craft = {
  displayName: "Input",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpInputSetting
  }
}

registerComponent({
  key: "SpInput",
  component: SpInput,
  runtimeComponent: SppInput,
  template: <SpInput itemKey={getId('input')} className="sp-input" title={"Input"} span={24} />,
  title: <IntlMessages id="system.page.library.input" text="Input" />,
  icon: <BsInputCursorText  className="react-icons icon-antd"/>,
  type: "Component",
  subtype: "input",
  sequence: 1,
});

export default SpInput;