import { Element, useNode } from "@craftjs/core";
import { Switch } from "antd";
import { Form, Input } from "components/Form";
import { flowApi } from "parse-api";
import { useEffect, useState } from "react";
import IntlMessages from "util/IntlMessages";
import { getFormType } from "../../../../util/algorithm";
import { ViewText } from "../FormComponent";
import { convertStyleStr, NodeCollector, persistentInfo, registerComponent, SfPanelContext } from "./common";
import { Container } from "./Container";

export const SfMainPanel = ({...props}) => {
  const { connectors: { connect, drag }, style } = useNode(NodeCollector);
  const children =
    <Element is={Container} type="SfPanel" id={"children"} canvas>
    </Element>
  return (
    <SfpMainPanel doRef={ref => connect(drag(ref))} style={style} {...props}>{children}</SfpMainPanel>
  )
}

export const SfpMainPanel = ({
    formKey, formview, form, doRef, style, children, itemKey, className, styleStr, title, table,
    isSelectKey, useFlowTitle, viewTitle, ...otherProps }) => {
  const cls = "ant-card ant-card-bordered " + (className ? className : "");
  let sls = convertStyleStr(styleStr);
  const [flowRecord, setFlowRecord] = useState(null);
  if (style) sls = sls ? {...sls, ...style} : style;
  useEffect(() => {
    const flow = form?.getFieldValue(["flow"]);
    const flowKey =  flow?.flowKey;
    const fetchData = async () => {
      if (useFlowTitle) {
        const formType = getFormType();
        const isPreview = formType === "preview"
        if (flowKey) {
          const lFlowRecord = await flowApi.getSystemFlow(isPreview, flowKey);
          setFlowRecord(lFlowRecord);
        }
      }
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useFlowTitle])
  const panelContext = {name: []};
  const flow = form?.getFieldValue(["flow"]);
  const flowKey =  flow?.flowKey;
  const task = flow?.task || 'start';
  const currentTask = flowRecord?.extraParams.taskMap[task];
  const selectedViewTitle = viewTitle && formview && viewTitle[formview];
  return (
    <SfPanelContext.Provider value={panelContext}>
      <div ref={doRef} className={cls} style={sls}>
        <div className="ant-card-head">
          <div className="ant-card-head-wrapper">
            {useFlowTitle && currentTask?.label && <div className="ant-card-head-title main-panel"><IntlMessages id={`flow.${formKey}.${flowKey}.task.${task}`} text={currentTask.label} /></div>}
            {selectedViewTitle && <div className="ant-card-head-title main-panel"><IntlMessages id={`form.${formKey}.${formview}.title`} text={selectedViewTitle} /></div>}
            {(!(useFlowTitle && flowRecord) && !selectedViewTitle)
              && <div className="ant-card-head-title main-panel"><IntlMessages id={`form.${formKey}.title`} text={title}/></div>}
          </div>
        </div>
        <div className="ant-card-body">
          {persistentInfo(table, panelContext.name)}
          {children}
        </div>
      </div>
    </SfPanelContext.Provider>
  );
}

const SfMainPanelSetting = ({currentView}) => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property"/>
      </Form.Item>
      <Form.Item name="useFlowTitle" label="use flow title" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="viewTitle" label="view title">
        <ViewText className="item-property" currentView={currentView}/>
      </Form.Item>
      <Form.Item name="table" label="table">
        <Input className="item-property"/>
      </Form.Item>
      <Form.Item name="isSelectKey" label="select key" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="valueKey" label="val. key">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="labelKey" label="lbl. key">
        <Input className="item-property" />
      </Form.Item>
    </>
  );
}

SfMainPanel.craft = {
  displayName: "Main Panel",
  related: {
    settings: SfMainPanelSetting
  }
}

const prepareLabelPattern = (valueKey, labelKey) => {
  if (labelKey && labelKey.indexOf("{") === -1) {
    labelKey = "{" + labelKey + "}";
  }
  if (valueKey && valueKey.indexOf("{") === -1) {
    valueKey = "{" + valueKey + "}";
  }
  if (labelKey && valueKey) {
    if (labelKey === valueKey) {
      return labelKey;
    } else {
      return labelKey + " (" + valueKey + ")";
    }
  } else if (labelKey) {
    return labelKey;
  } else if (valueKey) {
    return valueKey;
  } else {
    return null;
  }
}

SfMainPanel.validate = (props, {extraParams, formData}) => {
  if (props.title && props.title === "Form" && formData.formName) {
    props.title = formData.formName;
  }
  if (props.table) {
    if (props.table.startsWith("TempTable") && formData.formKey) {
      props.table = formData.formKey;
    }
    props.formKey = formData.formKey;
    extraParams.dataClassConfig.table = props.table;
    extraParams.dataClassConfig.displayName = props.title;
    extraParams.dataClassConfig.labelPattern = prepareLabelPattern(props.valueKey, props.labelKey);
    extraParams.dataClassConfig.valuePattern = prepareLabelPattern(props.valueKey);
    extraParams.selectConfig.enabled = props.isSelectKey;
    extraParams.selectConfig.name = props.title + " (" + formData.formKey + ")";
    if (props.isSelectKey) {
      if (!props.labelKey) {
        return <IntlMessages id="system.form.validate.label-key-missing" text="item ({itemKey}) - label key is missing." values={{itemKey:props.itemKey}}/>
      }
      if (!props.valueKey) {
        return <IntlMessages id="system.form.validate.value-key-missing" text="item ({itemKey}) - value key is missing." values={{itemKey:props.itemKey}}/>
      }
      if (props.labelKey.indexOf("{") === -1) {
        extraParams.selectConfig.labelKey += "{" + props.labelKey + "}";
      } else {
        extraParams.selectConfig.labelKey += props.labelKey;
      }
      if (props.valueKey.indexOf("{") === -1) {
        extraParams.selectConfig.valueKey += "{" + props.valueKey + "}";
      } else {
        extraParams.selectConfig.valueKey += props.valueKey;
      }
    }
    if (props.valueKey) {
      if (props.valueKey.indexOf("{") === -1) {
        extraParams.translate.recordKey = "{" + props.valueKey + "}";
        extraParams.dataClassConfig.valueKey = "{" + props.valueKey + "}";
      } else {
        extraParams.translate.recordKey = props.valueKey;
        extraParams.dataClassConfig.valueKey = props.valueKey;
      }
    }
  } else {
    return <IntlMessages id="system.form.validate.table-missing" text="item ({itemKey}) - table is missing." values={{itemKey:props.itemKey}}/>
  }
}

registerComponent({
  key:"SfMainPanel",
  component: SfMainPanel,
  runtimeComponent: SfpMainPanel,
  type: "Container",
});

export default SfMainPanel;