import { useEditor, useNode } from "@craftjs/core";
import { Button, Col, Switch } from "antd";
import { Form, Input, Select } from "components/Form";
import React,{useState} from "react";
import { MdOutlineCallToAction } from "react-icons/md";
import IntlMessages from "util/IntlMessages";
import {
  colSls, convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector,
  registerComponent, SfLayoutSetting, SfPanelContext,shouldUpdate
} from "./common";

const BUTTON_TYPES = [
  "default", "primary", "ghost", "dashed", "link", "text",
]

const SfActionButtonSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="labels" label="labels">
        <Select className="item-property" mode="tags">
        </Select>
      </Form.Item>
      <Form.Item name="size" label="size">
        <Select className="item-property">
          <Select.Option value="large">large</Select.Option>
          <Select.Option value="default">default</Select.Option>
          <Select.Option value="small">small</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="type" label="type">
        <Select className="item-property" placeholder="type" allowClear showSearch>
          {BUTTON_TYPES.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfActionButton = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions);
  return (
    <SfpActionButton doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const SfpActionButton = ({
  formKey, doRef, form, condistyles, className, style, dbtn, hideInTable, tableColWidth, tableColTitle,
  itemKey, title, labels, values, onSubmitButtonClicked, volitate,
  inputref, styleStr, format, ...otherProps }) => {
  const sls = convertStyleStr(styleStr);
  const [fxr, setFxr] = useState({});
  let buttons = []
  if (labels) {
    buttons = labels.map((l, index )=> {
      return {
        value: itemKey + "." + index,
        title: <IntlMessages id={`form.${formKey}.button.${itemKey}.${index}`} text={l} />,
      };
    });
  } else {
    buttons = [{
      value: itemKey,
      title: <IntlMessages id={`form.${formKey}.button.${itemKey}`} text={title} />,
    }] ;
  }
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr,itemKey});
        return (
          <Col ref={doRef} className={className} style={style}  hidden={fxr.hidden} {...colSls(otherProps)} >
            <Form.Item name={name} shouldUpdate={fx}>
              {buttons.map(b => {
                return <Button key={b.value} inputref={inputref} style={sls} disabled={fxr.disabled || otherProps.disabled} {...otherProps} onClick={() => {
                  if (onSubmitButtonClicked) onSubmitButtonClicked(b.value);
                }}>{b.title}</Button>
              })}
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

SfActionButton.craft = {
  displayName: "Action Button",
  related: {
    settings: SfActionButtonSetting
  }
}

SfActionButton.validate = (props, {extraParams, formData}) => {
  props.formKey = formData.formKey;
}

registerComponent({
  key:"SfActionButton",
  component: SfActionButton,
  runtimeComponent: SfpActionButton,
  template: <SfActionButton itemKey={getId('actionbutton')} className="sf-actionbutton"
            title={"Action Button"} span={24} size={"small"} volitate={true} />,
  title: <IntlMessages id="system.form.library.action-button" text="Action Button" />,
  icon: <MdOutlineCallToAction className="react-icons icon-antd"/>,
  type: "Component",
  sequence: 21,
});

export default SfActionButton;