import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Switch } from "antd";
import { Form, Input } from "components/Form";
import React, { useEffect, useRef, useState } from "react";
import IntlMessages from "util/IntlMessages";
import { MyTextArea } from "../../FormSetup/components/SfTextArea";
import { BsTextareaT } from "react-icons/bs";
import {
  convertStyleStr,
  deleteButton,
  EditorCollector,
  getId,
  NodeCollector,
  registerComponent,
  SpLayoutSetting,
  SpStyleSetting,
  syncPageState,
  updatePageState,
} from "./common";
import { AccountStore } from "../../../../constants/Account";

const SpTextAreaSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="defaultValue" label="default value">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="autoSize" label="auto size" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="bordered" label="bordered" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="maxLength" label="max len">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="rows" label="rows">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="allowClear" label="allow clear" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="showCount" label="show count" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="readOnly" label="read-only" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="action" label="action">
        <Input className="item-property" />
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  );
};

export const SpTextArea = ({ ...props }) => {
  const {
    connectors: { connect, drag },
    selected,
    style,
  } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions);
  return (
    <SppTextArea
      doRef={(ref) => connect(drag(ref))}
      style={style}
      dbtn={dbtn}
      {...props}
    />
  );
};

export const SppTextArea = ({
  doRef,
  className,
  style,
  dbtn,
  action,
  itemKey,
  pageKey,
  pageState,
  setPageState,
  defaultValue,
  inputref,
  styleStr,
  children,
  hideInTable,
  labelColStr,
  tableColWidth,
  autoSize, bordered, maxLength, rows, allowClear, showCount, readOnly, disabled,
  ...otherProps
}) => {
  const sls = convertStyleStr(styleStr);
  const timer = useRef();
  const [myValue, setMyValue] = useState(defaultValue);
  const inputProps = {autoSize, bordered, maxLength, rows, allowClear, showCount, readOnly, disabled};

  useEffect(() => {
    syncPageState({ itemKey, myValue, setMyValue, pageState, setPageState });
  }, [itemKey, myValue, pageState, setPageState]);

  const onMyChange = (myValue) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      updatePageState({
        itemKey,
        myValue,
        setMyValue,
        pageState,
        setPageState,
      });
    }, AccountStore.ON_CHANGE_DELAY);
  };

  const onPressEnter = (myValue) => {
    updatePageState({
      itemKey,
      myValue,
      setMyValue,
      pageState,
      setPageState,
      action,
    });
  };

  return (
    <Col ref={doRef} className={className} style={style} {...otherProps}>
      <MyTextArea
        value={myValue}
        inputref={inputref}
        onChange={onMyChange}
        style={sls}
        onPressEnter={onPressEnter}
        triggerOnChange={true}
        {...inputProps}
      />
      {dbtn}
    </Col>
  );
};

SpTextArea.craft = {
  displayName: "Text Area",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    },
  },
  related: {
    settings: SpTextAreaSetting,
  },
};

SppTextArea.enablePageState = true;

registerComponent({
  key: "SpTextArea",
  component: SpTextArea,
  runtimeComponent: SppTextArea,
  template: (
    <SpTextArea
      itemKey={getId("textarea")}
      className="sp-textarea"
      title={"Text Area"}
      span={24}
      labelColStr="span:6"
      hideInTable={true}
    />
  ),
  title: <IntlMessages id="system.form.library.textarea" text="Text Area" />,
  icon: <BsTextareaT className="react-icons icon-bs" />,
  type: "Component",
  subtype: "input",
  sequence: 12,
});

export default SpTextArea;
