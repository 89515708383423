import languageData from "lngProvider/languageData";
import {
  APP_HEADLESS, APP_LOGO, BASE_URL, LOAD_LANGUAGE_SUCCESS, LOAD_MENU_SUCCESS, LOAD_VERSION_NO_FAIL,
  LOAD_VERSION_NO_SUCCESS, SAVE_MENU_SUCCESS, SERVER_OFFLINE
} from "../../constants/ActionTypes";
import { getBaseUrl, getLocalStorageItem, getLocalStorageItemObject, isInIframe } from "../../util/algorithm";
import { systemSignal } from "../../util/signal";

const langs = getLocalStorageItemObject('language_data', languageData);

const initialConfig = {
  languageData: langs,
  menu: getLocalStorageItem('menu_obj_enc'),
  index: getLocalStorageItem('index'),
  logo: (process.env.PUBLIC_URL || "")+"/assets/images/logo.png",
  version: null,
  title: null,
  copyright: null,
  termsAndCondition: null,
  homePageUrl: null,
  baseUrl: getBaseUrl(),
  serverOffline: getLocalStorageItem('server_offline'),
  headless: isInIframe() || window.location.search?.indexOf("headless=true") !== -1,
  rdm: window.location.search?.indexOf("rdm=true") !== -1, // reference-data-mode
  accessKey: null,
  fileAccessKey: null,
};

const SystemReducer = (state = initialConfig, action) => {
  let newState = state;
  switch (action.type) {
    case BASE_URL:
      newState = {
        ...state,
        baseUrl: action.payload,
      };
      break;
    case APP_LOGO:
      newState = {
          ...state,
          logo: action.payload,
        };
      break;
    case SERVER_OFFLINE:
      newState = {
        ...state,
        serverOffline: action.payload,
      };
      break;
    case APP_HEADLESS:
      newState = {
        ...state,
        headless: action.payload,
      };
      break;
    case LOAD_VERSION_NO_SUCCESS:
      newState = {
        ...state,
        ...action.payload
      };
      break;
    case LOAD_VERSION_NO_FAIL:
      newState = {
        ...state,
        version: action.payload
      };
      break;
    case SAVE_MENU_SUCCESS:
    case LOAD_MENU_SUCCESS:
      newState = {
        ...state,
        menu: action.payload
      };
      break;
    case LOAD_LANGUAGE_SUCCESS:
      newState = {
        ...state,
        languageData: action.payload
      };
      break;
    default:
      newState = state;
  }
  Object.assign(systemSignal, newState);
  return newState;
};

export default SystemReducer;
