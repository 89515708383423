import { Element, useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Popover, Switch, Tag } from "antd";
import { Form, Input } from "components/Form";
import React, { useState } from "react";
import { FaRegObjectGroup } from "react-icons/fa";
import IntlMessages from "util/IntlMessages";
import { colSls, convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate } from "./common";
import { Container } from "./Container";
import ReactJson from "react-json-view";
import { BiCodeCurly } from "react-icons/bi";

export const SfGroup = ({...props}) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  const children =
    <Element is={Container} type="SfGroup" id={"children"} canvas>
    </Element>
  return (
    <SfpGroup doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props}>{children}</SfpGroup>
  )
}

export const SfpGroup = ({
  doRef, form, condistyles, style, dbtn, children, itemKey, className, styleStr, ...otherProps }) => {
  const cls = "" + (className ? className : "");
  let sls = convertStyleStr(styleStr);
  const [fxr, setFxr] = useState({});
  if (style) sls = sls ? {...sls, ...style} : style;
  if (fxr.hidden) {
    if (!sls) sls = {};
    sls.display = "none";
  }
  const fx = shouldUpdate({condistyles, form, ctx: {}, style, setFxr});
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const panelContext = ctx ? {name: [...ctx.name, itemKey]} : {name: [itemKey]};
        return <SfPanelContext.Provider value={panelContext}>
          <Form.Item name={itemKey+'_OnChange'} hidden shouldUpdate={fx}>
            <Input/>
          </Form.Item>
          {(doRef || (!fxr.hidden)) && <Col ref={doRef} className={cls} style={style} {...colSls(otherProps)}>
              {children}
              {dbtn}
          </Col>}
        </SfPanelContext.Provider>
    }}
    </SfPanelContext.Consumer>
  );
}

const SfGroupSetting = () => {
  return (
    <>
      <Form.Item name="readOnly" label="read-only" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hidden" label="hidden" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="isSelectKey" label="select key" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  );
}

const JsonRenderer = ({
  value,
  record,
  index,
  form,
  tableKey,
  onRowChange,
  editInTable,
  itemKey,
  mask,
  tableColWidth,
  disabled,
}) => {
  return (
    <span className="cell-content clickable">
      {value && (
        <Popover content={<ReactJson src={value} />}>
          <Tag>
            <BiCodeCurly className="react-icons icon-bi" />
          </Tag>
        </Popover>
      )}
    </span>
  );
};

SfpGroup.render = ({ editInTable, itemKey, mask, tableColWidth, disabled }, form, tableKey, onRowChange) => (value, record, index) => {
  const props = {value, record, index, form, tableKey, onRowChange, editInTable, itemKey, mask, tableColWidth, disabled};
  return <JsonRenderer {...props}/>
}

SfGroup.craft = {
  displayName: "Group",
  related: {
    settings: SfGroupSetting
  }
}

SfGroup.validate = (props, {parents, container, extraParams}) => {
  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "json",
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      sortable: true,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
    });
    if (props.isSelectKey) {
      extraParams.selectConfig.optionTypes.push(props.itemKey);
    }
  }
}

SfGroup.isSearchable = true;
SfGroup.isRulable = true;

registerComponent({
  key:"SfGroup",
  component: SfGroup,
  runtimeComponent: SfpGroup,
  template: <SfGroup itemKey={getId('group')} className="sf-group" span={24}/>,
  title: <IntlMessages id="system.form.library.group" text="Group" />,
  icon: <FaRegObjectGroup  className="react-icons icon-fa"/>,
  type: "Component",
  sequence: 23,
});

export default SfGroup;