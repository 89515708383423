import { useEditor, useNode } from "@craftjs/core";
import { Form, Input } from "components/Form";
import { dataExplorer } from "parse-api";
import { useEffect, useRef, useState } from "react";
import { UserAvatar } from "../../../../components/Form";
import { deleteButton, EditorCollector, NodeCollector, registerComponent } from "./common";

const SfUserAvatarSetting = () => {
  return (
    <>
      <Form.Item name="username" label="username">
        <Input className="item-property"/>
      </Form.Item>
    </>
  )
}

export const SfUserAvatar = ({...props}) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpUserAvatar doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props}/>
  )
}

export const SfpUserAvatar = ({
  doRef, style, dbtn, itemKey, styleStr, username, userId,
  ...otherProps }) => {
  const [user, setUser] = useState();
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  },[])
  useEffect(() => {
    const fetchData = async () => {
      if (username) {
        const usr = await dataExplorer.getByKey("User", username);
        if (mounted.current) setUser(usr);
      } else if (userId) {
        const usr = await dataExplorer.get("User", userId);
        if (mounted.current) setUser(usr);
      }
    }
    fetchData();
  },[username, userId])
  
  return (
    <div ref={doRef}><UserAvatar userObj={user} {...otherProps}/></div>
  );
}

SfUserAvatar.craft = {
  displayName: "User Avator",
  related: {
    settings: SfUserAvatarSetting
  }
}

registerComponent({
  key:"SfUserAvatar", 
  component: SfUserAvatar, 
  runtimeComponent: SfpUserAvatar,
  type: "Component",
});

export default SfUserAvatar;