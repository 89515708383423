import { Element, useEditor, useNode } from "@craftjs/core";
import { Col } from "antd";
import { Form, Input } from "components/Form";
import { useEffect, useRef, useState } from "react";
import { MdVerified } from "react-icons/md";
import IntlMessages from "util/IntlMessages";
import Captcha from "../../../../components/Form/Captcha";
import { AccountStore } from "../../../../constants/Account";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting, syncPageState, updatePageState } from "./common";

const SpCaptchaSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  );
}

export const SpCaptcha = ({...props}) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppCaptcha doRef={ref => connect(drag(ref))} selectedStyle={style} dbtn={dbtn} {...props}/>
  )
}

export const SppCaptcha = ({
  doRef, dbtn, selectedStyle, content, pageKey, itemKey,
  value, substitute, fgColor, bgColor, level, size,
  pageState, setPageState,
  ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}
  const [myValue, setMyValue] = useState();
  const timer = useRef()

  useEffect(() => {
    syncPageState({itemKey, myValue, setMyValue, pageState, setPageState})
  },[itemKey, myValue, pageState, setPageState])

  const onMyChange = (myValue) => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      updatePageState({itemKey, myValue, setMyValue, pageState, setPageState})
    }, AccountStore.ON_CHANGE_DELAY);
  }

  return (
    <Col ref={doRef} {...props}>
      <Captcha value={myValue} onChange={onMyChange}/>
      {dbtn}
    </Col>
  );
}
SppCaptcha.enablePageState = true;

SpCaptcha.craft = {
  displayName: "Captcha",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpCaptchaSetting
  }
}

registerComponent({
  key:"SpCaptcha",
  component: SpCaptcha,
  runtimeComponent: SppCaptcha,
  template: <Element canvas is={SpCaptcha} itemKey={getId('captcha')} preview={true}
            className="sp-captcha" span="24" size={128} level={'L'}/>,
  title: <IntlMessages id="system.page.library.captcha" text="Captcha" />,
  icon: <MdVerified className="react-icons icon-md"/>,
  type: "Component",
  subtype: "input",
  sequence: 23,
});

export default SpCaptcha;