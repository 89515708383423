import {combineReducers} from "redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import SystemSetting from "./System";
import Translate from "./Translate";

import {connectRouter} from 'connected-react-router'

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  common: Common,
  system: SystemSetting,
  translate: Translate,
});

export default createRootReducer;
