import { CloudRunWithCache } from './config';
import dataExplorer from './data-explorer';

const loadSystemFlow = async () => {
  try {
    return await dataExplorer.searchAll("SystemFlow", null, "flowName");
  } catch (error) {
    console.error("get system flow failed", error);
    throw error;
  }
};

const getSystemFlow = async (isPreview, flowKey) => {
  try {
    return await dataExplorer.getActiveByKey("SystemFlow", flowKey, isPreview);
  } catch (error) {
    console.error("get system flow failed", error);
    throw error;
  }
};

const getSystemFlowVersions = async (flowKey) => {
  try {
    return await dataExplorer.getAllByKey("SystemFlow", flowKey);
  } catch (error) {
    console.error("get system flow failed", error);
    throw error;
  }
};

const saveSystemFlow = async (flowKey, flow, isNew) => {
  try {
    return await dataExplorer.saveByKey("SystemFlow", flowKey, flow, isNew);
  } catch (error) {
    console.error("get system flow failed", error);
    throw error;
  }
};

const deleteSystemFlow = async (flow) => {
  try {
    return await dataExplorer.destory("SystemFlow", flow);
  } catch (error) {
    console.error("get system flow failed", error);
    throw error;
  }
};

const registerDataClassConfig = async (isPreview, flow, updatecache) => {
  try {
    const param = {isPreview, flow, updatecache};
    return await CloudRunWithCache("registerFlowDataClassConfig", param, isPreview);
  } catch (error) {
    console.error("register data class failed", error);
    throw error;
  }
}

const flowApi = {
  loadSystemFlow,
  getSystemFlow,
  getSystemFlowVersions,
  saveSystemFlow,
  deleteSystemFlow,
  registerDataClassConfig,
}

export default flowApi;