import { GroupOutlined } from "@ant-design/icons";
import { Element, useEditor, useNode } from "@craftjs/core";
import { Form, Input } from "components/Form";
import IntlMessages from "util/IntlMessages";
import ButtonArea from "./ButtonArea";
import { convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfPanelListContext, shouldUpdate } from "./common";
import Container from "./Container";
import RepeatedArea from "./RepeatedArea";
import SfButton from "./SfButton";
import SfDivider from "./SfDivider";
import { uid } from "util/algorithm";
import { Collapse, InputNumber, Switch } from "antd";
import { useEffect, useRef, useState } from "react";

export const SfPanelList = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  const children =
    <Element is={Container} type="SfPanelList" id={"children"} noStyle canvas>
      <Element is={RepeatedArea} type="SfPanelListRepeat" id={"repeat_area"} styleStr="position:relative,marginBottom:10px" canvas>
        <SfDivider itemKey="divider" className="sf-divider" orientation="left" type="horizontal" plain={true} title={"Record"}/>
        <SfButton itemKey="del" className="sf-button delete" buttonType="text" buttonAction="delete" styleStr="position:absolute,right:-18px,top:59px" />
      </Element>
      <Element is={ButtonArea} type="SfPanelListControl" id={"buttons"} canvas styleStr="minHeight:0px">
        <SfButton itemKey="add" className="sf-button add" buttonType="dashed" block buttonAction="add" title="Add" styleStr="marginBottom:0px" />
      </Element>
    </Element>
  return (
    <SfpPanelList doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props}>{children}</SfpPanelList>
  )
}

export const SfpPanelList = ({
  doRef, formKey, form, condistyles, style, dbtn, children, itemKey,
  className, styleStr, title, showTitle, collapsible, defaultCollapse, table,
  min, max,
  ...otherProps }) => {
  const cls = "ant-card ant-card-bordered " + (className ? className : "");
  const [fxr, setFxr] = useState({});
  let sls = convertStyleStr(styleStr);
  if (style) sls = sls ? { ...sls, ...style } : style;
  if (fxr.hidden) {
    if (!sls) sls = {};
    sls.display = "none";
  }
  const fx = shouldUpdate({condistyles, form, ctx: {}, style, setFxr});
  const titleEle = <IntlMessages id={`form.${formKey}.${itemKey}.title`} text={title}/>
  const value = Form.useWatch(itemKey, form);
  const [count, setCount] = useState();
  const valueRef = useRef();
  useEffect(() => {
    if (valueRef.current) {
      clearTimeout(valueRef.current);
    }
    valueRef.current = setTimeout(() => {
      const c = value?.length;
      if (typeof c === 'number') {
        setCount(c);
      }
      if (min > 0) {
        let updated = false;
        let newValue = value;
        if (!newValue) {
          newValue = [];
          updated = true;
        }
        while (newValue.length < min) {
          newValue.push({});
          updated = true;
        }

        if (updated && form) {
          form.setFields([{name: itemKey, value: newValue}]);
        }
      }
    }, 200)
  }, [form, min, itemKey, value]);
  if (doRef) {
    return (
      <SfPanelListContext.Provider value={{}}>
        <div ref={doRef} className={cls} style={sls}>
          <div className="ant-card-head">
            <div className="ant-card-head-wrapper">
              <div className="ant-card-head-title">{titleEle}</div>
            </div>
          </div>
          <div className="ant-card-body">
            {children}
          </div>
          {dbtn}
        </div>
      </SfPanelListContext.Provider>
    );
  } else {
    if (!fxr.hidden) {
      if (collapsible) {
        return (
          <>
            <Form.Item name={itemKey+'_OnChange'} hidden shouldUpdate={fx}>
              <Input/>
            </Form.Item>
            <Form.List name={itemKey}>
              {(fields, { add, remove }) => (
                <SfPanelListContext.Provider value={{ itemKey, fields, max, min, count, addRow: ()=>add({rowKey: uid()}), remove, table }}>
                  <Collapse className={cls} style={sls} defaultActiveKey={defaultCollapse ? [] : ["1"]}>
                    <Collapse.Panel header={showTitle && titleEle} key="1">
                      <div className="ant-card-body">
                        {children}
                      </div>
                    </Collapse.Panel>
                  </Collapse>
                </SfPanelListContext.Provider>
              )}
            </Form.List>
          </>
        );
      } else {
        return (
          <>
            <Form.Item name={itemKey+'_OnChange'} hidden shouldUpdate={fx}>
              <Input/>
            </Form.Item>
            <Form.List name={itemKey}>
              {(fields, { add, remove }) => (
                <SfPanelListContext.Provider value={{ itemKey, fields, max, min, count, addRow: ()=>add({rowKey: uid()}), remove, table }}>
                  <div ref={doRef} className={cls} style={sls}>
                    <div className="ant-card-head">
                      <div className="ant-card-head-wrapper">
                        <div className="ant-card-head-title">{titleEle}</div>
                      </div>
                    </div>
                    <div className="ant-card-body">
                      {children}
                    </div>
                  </div>
                </SfPanelListContext.Provider>
              )}
            </Form.List>
          </>
        );
      }
    } else {
      return null;
    }
  }
}

const SfPanelListSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="showTitle" label="show title" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="collapsible" label="collapsible" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="defaultCollapse" label="default collapse" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="min" label="min">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="max" label="max">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
    </>
  );
}

SfPanelList.craft = {
  displayName: "Panel List",
  related: {
    settings: SfPanelListSetting
  }
}

SfPanelList.validate = (props, {parents, formData, extraParams}) => {
  props.formKey = formData.formKey;
  extraParams.dataClassConfig.columns.push({
    dataIndex: props.itemKey,
    index: extraParams.dataClassConfig.columns.length,
    type: "json[]",
    picker: props.picker,
    format: props.format,
    title: props.tableColTitle || props.title,
    width: props.tableColWidth,
    sortable: true,
    editable: false,
    permission: props.permission,
    volitate: props.volitate,
    hiddenForReadOnly: false,
  });
}

registerComponent({
  key:"SfPanelList",
  component: SfPanelList,
  runtimeComponent: SfpPanelList,
  template: <SfPanelList itemKey={getId('panelList')} className="sf-panel" title={"Panel List"}/>,
  title: <IntlMessages id="system.form.library.panel-list" text="Panel List" />,
  icon: <GroupOutlined  className="react-icons icon-antd"/>,
  type: "Container",
});

export default SfPanelList;