import { Drawer, Layout } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { toggleCollapsedSideNav } from "../../appRedux/actions";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import { getNavStyle } from "../../routes/system/MenuSetup/MenuComponent";
import SidebarContent from "./SidebarContent";
import { getLocalStorageItem, setLocalStorageItem } from "../../util/algorithm";
import { commonSignal, settingsSignal, systemSignal } from "../../util/signal";
import { useSignalValue } from "../../util/reactHook";

const {Sider} = Layout;

const Sidebar = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(getLocalStorageItem("sidebarCollapsed") === "Y");
  const {themeType} = settingsSignal;
  const { menu } = systemSignal;
  const width = useSignalValue(commonSignal, 'width');
  const navCollapsed = useSignalValue(commonSignal, 'navCollapsed');
  const dispatch = useDispatch();

  const onToggleCollapsedNav = () => {
    dispatch(toggleCollapsedSideNav(!navCollapsed));
  };

  const onSidebarCollapsed = (sidebarCollapsed) => {
    setLocalStorageItem("sidebarCollapsed", sidebarCollapsed ? "Y" : "N");
    setSidebarCollapsed(sidebarCollapsed);
  }

  let drawerStyle = "gx-collapsed-sidebar";
  const navStyle = getNavStyle(menu, width);

  if (navStyle === NAV_STYLE_FIXED) {
    drawerStyle = "";
  } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
    drawerStyle = "gx-mini-sidebar gx-mini-custom-sidebar";
  } else if (navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
    drawerStyle = "gx-custom-sidebar"
  } else if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
    drawerStyle = "gx-mini-sidebar";
  }
  if ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR
    || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) && width < TAB_SIZE) {
    drawerStyle = "gx-collapsed-sidebar"
  }

  return (
    <Sider
      className={`gx-app-sidebar ${drawerStyle} ${themeType !== THEME_TYPE_LITE ? 'gx-layout-sider-dark' : null}`}
      trigger={null}
      collapsed={(width < TAB_SIZE ? false : sidebarCollapsed || navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR)}
      theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
      collapsible>
      {
        navStyle === NAV_STYLE_DRAWER || width < TAB_SIZE ?
          <Drawer
            className={`gx-drawer-sidebar ${themeType !== THEME_TYPE_LITE ? 'gx-drawer-sidebar-dark' : null}`}
            placement="left"
            closable={false}
            onClose={onToggleCollapsedNav}
            visible={navCollapsed}>
            <SidebarContent sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={onSidebarCollapsed}/>
          </Drawer> :
          <SidebarContent sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={onSidebarCollapsed}/>
      }
    </Sider>)
};
export default Sidebar;
