import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Radio, Row, Select, Spin, Switch } from "antd";
import { Form, Input } from "components/Form";
import { selectApi } from "parse-api";
import React, { useEffect, useRef, useState } from "react";
import { IoMdRadioButtonOn } from "react-icons/io";
import { compare, getArrayElementByAttribute } from "util/algorithm";
import IntlMessages from "util/IntlMessages";
import {
  colSls, convertRules, convertStyleStr, deleteButton, EditorCollector, getId,
  NodeCollector, refreshOptions, registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate,
  SelectCollector, getParentName
} from "./common";
import { SelectRenderer } from "./SfSelect";
import { settingsSignal } from "../../../../util/signal";

const SfRadioGroupSetting = () => {
  const { localSelects, mainSelects } = useEditor(SelectCollector);
  const { locale } = settingsSignal;
  const [systemSelectList, setSystemSelectList] = useState([]);
  const [selects, setSelects] = useState([]);
  const [selected, setSelected] = useState({});
  const [values, setValues] = useState({});
  const [options, setOptions] = useState([]);
  const [filter, setFilter] = useState([]);
  const selectedKey = useRef();
  const mounted = useRef();

  useEffect(() => {
    mounted.current = true;
    const fetchData = async () => {
      const list = await selectApi.loadSystemSelect();
      setSystemSelectList(list);
      // convert to option
      const options = list.map(l => {
        return {
          value: l.selectKey,
          label: l.name,
        }
      })
      if (mounted.current) setSelects(options);
    }
    fetchData();
    return () => {
      mounted.current = false;
    }
  }, [])

  const refreshOptionTypes = () => {
    if (selectedKey.current && systemSelectList?.length > 0) {
      if (selected && selected?.selectKey !== selectedKey.current) {
        const selected = getArrayElementByAttribute(systemSelectList, "selectKey", selectedKey.current);
        setSelected(selected);
        if (selected && selected.optionTypes) {
          if (mounted.current) setFilter(selected.optionTypes.map(f => ({label:f, value:f})));
        } else {
          if (mounted.current) setFilter([]);
        }
        updateFilterOption({ ...values, "selectkey": selectedKey.current });
      }
    }
  }

  const onIsUseCodeChanged = (value) => {
    updateFilterOption({ ...values, "isUseCode": value });
  }

  const onFilterChanged = (value) => {
    updateFilterOption({ ...values, "filter": value });
  }

  const updateFilterOption = async (values) => {
    setValues(values);
    if (values.selectkey) {
      let list = await refreshOptions(locale, values.selectkey, values.isUseCode);
      if (filter) list = list.filter(l => values.filter ? l.optionType === values.filter : true);
      setOptions(list.map(l => ({value:l.value, label:l.label})));
    }
  }

  const onSelectKeyChange = (value) => {
    refreshOptionTypes();
    selectedKey.current = value;
  }

  const SelectKey = ({value, ...props}) => {
    useEffect(()=> {
      onSelectKeyChange(value);
    },[value])
    return <Select {...props} value={value} />
  }

  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="selectkey" label="select">
        <SelectKey className="item-property" options={selects} allowClear showSearch />
      </Form.Item>
      <Form.Item name="button" label="button" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="filter" label="filter">
        <Select className="item-property" options={filter} allowClear onChange={onFilterChanged}/>
      </Form.Item>
      <Form.Item name="parentkeys" label="parents">
        <Select className="item-property" options={[...mainSelects,...localSelects]} mode="multiple" />
      </Form.Item>
      <Form.Item name="maxOptionCount" label="max count">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="filterOption" label="filter option">
        <Select className="item-property" mode="multiple" options={options} />
      </Form.Item>
      <Form.Item name="isUseCode" label="use code" valuePropName="checked">
        <Switch className="item-property" onChange={onIsUseCodeChanged} />
      </Form.Item>
      <Form.Item name="optionGrid" label="option grid">
        <InputNumber min="1" max="4" className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="isSelectKey" label="select key" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SystemRadioGroup = ({
  selectkey, filter, parentkeys, parentValues, maxOptionCount, filterOption, isUseCode, optionGrid, button, ...otherProps }) => {
  const { locale } = settingsSignal;
  const [fetching, setFetching] = useState(false);
  const [list, setList] = useState([]);
  const [options, setOptions] = useState([]);
  const mounted = useRef();

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, []);

  useEffect(() => {
    const options = list.sort((a, b) => compare(a.displayOrder || a.label, b.displayOrder || b.label))
      .filter((option, index) =>
        (!maxOptionCount || index < maxOptionCount) &&
        (!filterOption || filterOption.length === 0 || filterOption.indexOf(option.value) !== -1)
      )
      .map(l => {
        if (button) {
          return <Radio.Button key={l.value} value={l.value} >{l.label}</Radio.Button>
        } else if (optionGrid) {
          return <Col key={l.value} span={24 / parseInt(optionGrid)}><Radio value={l.value} >{l.label}</Radio></Col>
        } else {
          return <Radio key={l.value} value={l.value} >{l.label}</Radio>
        }
      })

    if (mounted.current) setOptions(options);
  }, [list, filterOption, maxOptionCount, optionGrid, button]);

  useEffect(() => {
    refreshOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectkey, filter, parentkeys, parentValues]);

  const refreshOptions = async () => {
    if (selectkey) {
      if (mounted.current) setFetching(true);
      let list = [];
      if (parentkeys && parentkeys.length > 0) {
        const parentOptionKey = await selectApi.getParentOptionKey(locale, selectkey, isUseCode, parentValues);
        if (parentOptionKey) {
          list = await selectApi.fetchSystemSelectOption(locale, selectkey, isUseCode, parentOptionKey);
        }
      } else {
        list = await selectApi.fetchSystemSelectOption(locale, selectkey, isUseCode);
      }
      if (filter) list = list.filter(l => l.optionType === filter);
      if (mounted.current) setList(list);
      if (mounted.current) setFetching(false);
    }
  }
  if (fetching) {
    return <Spin size="small" />
  } else {
    return <Radio.Group {...otherProps}>
      {optionGrid &&
        <Row>
          {options}
        </Row>
      }
      {!optionGrid && options}
    </Radio.Group>
  }
}

export const SfRadioGroup = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpRadioGroup doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const SfpRadioGroup = ({
  doRef, form, condistyles, className, style, dbtn, hideInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, volitate, skipcopy, button,
  selectkey, filter, parentkeys, isUseCode, maxOptionCount, filterOption, optionGrid,
  inputref, styleStr, children, isSelectKey, ...otherProps }) => {
  const [fxr, setFxr] = useState({});
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const mounted = useRef();
  const newRules = convertRules(rules);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, []);
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const parentname = getParentName(ctx);
        let targetForm = form;
        const dependencies = parentkeys ? parentkeys.map(p => {
          if (p.startsWith("main.")) {
            targetForm = ctx?.parentForm ||  targetForm;
            return [p.substr(5)];
          } else {
            return [...parentname, p];
          }
        }) : [];
        const pValues = dependencies.map(d => targetForm?.getFieldValue(d));
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name} label={title}
               shouldUpdate={fx} hidden={fxr.hidden} rules={fxr.hidden || otherProps.disabled ? null : newRules}
              labelAlign={labelAlign} labelCol={lcs} wrap >
              <SystemRadioGroup
                selectkey={selectkey}
                filter={filter}
                isUseCode={isUseCode}
                button={button}
                maxOptionCount={maxOptionCount}
                filterOption={filterOption}
                optionGrid={optionGrid}
                parentkeys={parentkeys}
                parentValues={pValues}
                style={sls}
                {...otherProps} />
            </Form.Item>

            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

SfpRadioGroup.render = ({ selectkey, isUseCode }) => (value, record, index) => {
  const props = {selectkey, isUseCode, value, record, index};
  return <SelectRenderer {...props}/>
}

SfRadioGroup.craft = {
  displayName: "Radio Group",
  related: {
    settings: SfRadioGroupSetting
  }
}

SfRadioGroup.validate = (props, { parents, mainParents, container, extraParams }) => {
  const itemKey = props.itemKey;
  const selectkey = props.selectkey;
  const parentkeys = props.parentkeys;
  if (!selectkey) {
    return <IntlMessages id="system.form.validate.select-key-missing" text="item ({itemKey}) - select is missing." values={{ itemKey: itemKey }} />
  }

  let isArrayParent = false;
  if (parentkeys) parentkeys.forEach(key => {
    if (key.startsWith('main.')) {
      key = key.substr(5);
      if (mainParents[key]?.type.resolvedName === "SfArray") {
        isArrayParent = true;
      }
    } else {
      if (parents[key]?.type.resolvedName === "SfArray") {
        isArrayParent = true;
      }
    }
  })
  if (isArrayParent && parentkeys.length > 1) {
    return <IntlMessages id="system.form.validate.select-one-and-only-one-array-parent" text="item ({itemKey}) - you could only select one and only one array parent." values={{itemKey:itemKey}}/>
  }
  if (isArrayParent) {
    props.selectkey = "SelfReference"
  } else {
    if (props.selectkey === "SelfReference") {
      return <IntlMessages id="system.form.validate.select-key-invalid" text="item ({itemKey}) - Self Reference is only for array parent." values={{itemKey:itemKey}}/>
    }
  }

  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "select",
      valueIndex: props.isUseCode ? "code" : "name",
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      sortable: true,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
      selectkey: props.selectkey,
      parentkeys: props.parentkeys,
      isUseCode: props.isUseCode,
    });
    if (props.isSelectKey) {
      extraParams.selectConfig.optionTypes.push(props.itemKey);
    }
  }
}

SfRadioGroup.isSearchable = true;
SfRadioGroup.isRulable = true;

registerComponent({
  key: "SfRadioGroup",
  component: SfRadioGroup,
  runtimeComponent: SfpRadioGroup,
  template: <SfRadioGroup itemKey={getId('radiogroup')} className="sf-radiogroup wrap"
    title={"Radio Group"} span={24} labelColStr="span:6" maxOptionCount={10} />,
  title: <IntlMessages id="system.form.library.radio-group" text="Radio Group" />,
  icon: <IoMdRadioButtonOn  className="react-icons icon-io"/>,
  type: "Component",
  sequence: 7,
});

export default SfRadioGroup;