import { CopyOutlined, DeleteOutlined, EyeOutlined, FileAddOutlined, FolderOpenOutlined, ReloadOutlined, SaveOutlined } from "@ant-design/icons";
import { Element, Frame, useEditor } from "@craftjs/core";
import { Button, Popconfirm, Switch, Tooltip, message } from "antd";
import { Form, Input, Select } from "components/Form";
import { system } from "parse-api";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { BiRedo, BiUndo } from "react-icons/bi";
import { MdDisabledVisible, MdOutlinePublic, MdOutlinePublicOff } from "react-icons/md";
import IntlMessages from "util/IntlMessages";
import { applyCss, compressJsonData, decompressJsonData, getArraysIntersection, isEmptyString, log } from "util/algorithm";
import { AccountStore } from "../../../constants/Account";
import { useLbl } from "../../../lngProvider";
import { copyToClipboard, delay, getClipboardContent, getText, getVersionDesc, isEmpty, processForwardAttribute, uid } from "../../../util/algorithm";
import { SystemSelect } from "../FormSetup/components";
import { EditorCollector, SpCanvas, SpDataGridContext, UserComponents, UserRuntimeComponents, getButtons, patchRealId, updateDisplayName, validatePageInfo } from "./components";
import { defaultCss } from "./defaultPage";
import { settingsSignal } from "../../../util/signal";

export const V_MASTER = 'master';
export const V_READONLY = 'read-only';

export const P_HIDDEN = 'hidden';
export const P_READONLY = 'read-only';
export const P_EDITABLE = 'editable';

const setValueByField = (props, field) => {
  const name = field.name;
  let obj = props;
  for (let i = 0; i < name.length; i++) {
    if (i === (name.length - 1)) {
      obj[name[i]] = field.value;
    } else if (obj[name[i]]) {
      obj = obj[name[i]];
    } else {
      obj[name[i]] = {};
      obj = obj[name[i]];
    }
  }
}

const fieldsToActionProps = ({ props, changedFields, allFields, id }) => {
  updateDisplayName(props, id);
  if (changedFields.length > 0) {
    changedFields.forEach(field => {
      setValueByField(props, field);
    })
  } else {
    allFields.forEach(field => {
      setValueByField(props, field);
    })
  }
}

const actionPropsToFields = (form, selectedNode) => {
  const fields = [];
  const allFields = form.getFieldsValue(true);
  if (selectedNode) {
    fields.push({ name: ["selectedId"], value: `${selectedNode.displayName} (${selectedNode.id})` });
    Object.keys(allFields).forEach(field => {
      if (field !== "selectedId") {
        const val = selectedNode.props[field];
        if (Array.isArray(val)) {
          fields.push({ name: [field], value: val });
        } else if (val && typeof val == 'object') {
          Object.keys(val).forEach(field2 => {
            const val2 = val[field2];
            fields.push({ name: [field, field2], value: val2 });
          });
        } else {
          fields.push({ name: [field], value: val });
        }
      }
    });
    Object.keys(selectedNode.props).forEach(field => {
      const val = selectedNode.props[field];
      if (val) {
        if (Array.isArray(val)) {
          fields.push({ name: [field], value: val });
        } else if (typeof val == 'object') {
          Object.keys(val).forEach(field2 => {
            const val2 = val[field2];
            fields.push({ name: [field, field2], value: val2 });
          });
        } else {
          fields.push({ name: [field], value: val });
        }
      } else {
        fields.push({ name: [field], value: val });
      }
    });
  }
  return fields;
}

const ActionButton = ({id, title, confirm, onClick, ...props}) => {
  if (confirm) {
    return <Popconfirm
      title={<IntlMessages id={`system.page.confirm.${id}`} text={confirm} />}
      onConfirm={onClick}
      okText={<IntlMessages id="system.confirm.yes" text="Yes" />}
      cancelText={<IntlMessages id="system.confirm.no" text="No" />}
    >
      <Tooltip mouseEnterDelay={1} title={<IntlMessages id={`system.page.action-button-${id}`} text={title} />}>
        <Button size="medium" className={`pageinfo-btn ${id}`} {...props} />
      </Tooltip>
    </Popconfirm>
  } else {
  return <Tooltip mouseEnterDelay={1} title={<IntlMessages id={`system.page.action-button-${id}`} text={title} />}>
    <Button size="medium" className={`pageinfo-btn ${id}`} onClick={onClick} {...props}/>
  </Tooltip>
  }
}

const SettingsPanel = ({onChange, currentView}) => {
  const [form] = Form.useForm();
  const {query, actions, selectedNode } = useEditor(EditorCollector);
  const cssTextRef = useRef();
  const selectedRef = useRef();

  const copyDefaultCss = () => {
    form.setFields([
      { name: ["css"], value: defaultCss },
      { name: ["cssOverride"], value: true }
    ]);
  }

  const onCssChange = (e) => {
    const css = form.getFieldValue(["css"]);
    const cssOverride = form.getFieldValue(["cssOverride"]);
    if (isEmptyString(css)) {
      form.setFields([
        { name: ["cssOverride"], value: false }
      ]);
      applyCss(defaultCss, 'page');
    } else if (cssOverride) {
      applyCss(css, 'page');
    } else {
      applyCss(defaultCss+" "+css, 'page');
    }
  }

  useEffect(() => {
    form.validateFields();
    if (selectedNode) {
      if (selectedRef.current !== selectedNode.id) {
        log("selectedNode", selectedNode)
        form.resetFields();
        const fields = actionPropsToFields(form, selectedNode);
        form.setFields(fields);
        selectedRef.current = selectedNode.id;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode])

  return selectedNode ? (
    <>
      <Form form={form} size={"small"} name="pagesetup.props" colon={false}  onFieldsChange={(changedFields, allFields) => {
        try {
          const node = query.node(selectedNode.id).get();
          if (node) {
            actions.setProp(selectedNode.id, props => {
              fieldsToActionProps({ props, changedFields, allFields, id: selectedNode.id });
            });
          }
        } catch (e) {
          log(e);
        }
        if (onChange) onChange();
      }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item name="selectedId" label="name">
          <Input className="item-property" readOnly></Input>
        </Form.Item>
        {selectedNode.id !== 'ROOT' &&
          <>
            <Form.Item name="itemKey" label="key">
              <Input className="item-property"></Input>
            </Form.Item>
            <Form.Item name="className" label="class">
              <Input className="item-property"></Input>
            </Form.Item>
          </>
        }
        {
          selectedNode.settings && React.createElement(selectedNode.settings, {actions, selectedNode})
        }
        {selectedNode.id === 'ROOT' &&
          <>
            <Form.Item name="css" label="css" className="css-text"
              labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} >
              <Input.TextArea ref={cssTextRef}
                className="item-property"
                autoSize allowClear bordered={false}
                onChange={onCssChange}
                />
            </Form.Item>
            <Form.Item name="cssOverride" label="css override" valuePropName="checked">
              <Switch className="item-property" onChange={onCssChange}/>
            </Form.Item>
            <Form.Item >
            <Button size="small" className="css-button" onClick={copyDefaultCss} ><Tooltip mouseEnterDelay={1} title={"copy default style template"}><CopyOutlined /></Tooltip></Button>
            </Form.Item>
          </>
        }
      </Form>
    </>
  ) : false;
};

const PageInfo = ({
  pageData, onChange, isModified, allPages, allVersions,
  doSave, saveCount, doReload, doAdd, doDelete, doPreview, doPublish, doUnpublish, doExport,
  editable, setEditable, allActionPolicies }) => {
  const [form] = Form.useForm();
  const [isCopy, setIsCopy] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [allRoles, setAllRoles] = useState([]);
  const { actions, query, enabled, canUndo, canRedo } = useEditor((state, query) => {
    return {
      enabled: state.options.enabled,
      canUndo: query.history.canUndo(),
      canRedo: query.history.canRedo()
    }
  });

  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    const fetchData = async () => {
      const roles = await system.getAllRoles();
      setAllRoles(roles);
    }
    fetchData();
    return () => {
      mounted.current = false;
    }
  }, [])

  useEffect(() => {
    if (pageData) {
      if (form) {
        form.setFieldsValue({
          pageKey: pageData.pageKey,
          savedPageKey: pageData.pageKey,
          pageName: pageData.pageName,
          admins: pageData.admins,
          adminRoles: pageData.adminRoles,
          versionStamp: pageData.versionStamp,
          versionRemarks: pageData.versionRemarks,
          allowedRoles: pageData.extraParams?.allowedRoles,
          isPublished: pageData.isPublished,
          actions: pageData.extraParams?.actions,
        });
      }
      const data = pageData.data;
      const json = decompressJsonData(data);
      if (json) {
        actions.history.ignore().deserialize(json);
      }
      if (mounted) setIsOpen(false);
    } else if (mounted) setIsOpen(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData])

  useEffect(() => {
    if (saveCount > 0) {
      saveAction();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveCount])

  const getCurrentPageData = (initialData) => {
    if (initialData) {
      const {pageKey, pageName, data,
        objectId, extraParams, error} = initialData;
      const newPageData = {
        pageKey, pageName, data,
        objectId,
        extraParams, error
      }
      return newPageData;
    } else {
      const originalJson = query.serialize();
      const patchedJson = patchRealId(originalJson);
      const pageKey = form.getFieldValue(["pageKey"]);
      const pageName = form.getFieldValue(["pageName"]);
      const admins = form.getFieldValue(["admins"]);
      const adminRoles = form.getFieldValue(["adminRoles"]);
      const isPublished = form.getFieldValue(["isPublished"]);
      const allowedRoles = form.getFieldValue(["allowedRoles"]);
      const actions = form.getFieldValue(["actions"]);
      const versionRemarks = form.getFieldValue(["versionRemarks"]);
      const versionStamp = form.getFieldValue(["versionStamp"]);
      let {error, extraParams, json} = validatePageInfo(patchedJson, {pageKey, pageName, versionStamp});

      const selectedActions = (actions || []).map(action => {
        let selected = null;
        allActionPolicies.forEach(p => {
          if (p.actionKey === action) {
            selected = {
              actionKey: p.actionKey,
              // data: p.data,
              actionTypes: p.actionTypes,
            };
          }
        });
        if (!selected) {
          error = <IntlMessages id="system.form.validate.action-not-found" text="Unknown action policy ({action})." values={{action}}/>
        }
        return selected;
      });
      const data = AccountStore.COMPRESSDATA ? compressJsonData(json) : json;
      if (!extraParams) extraParams = {};
      extraParams.allowedRoles = allowedRoles;
      extraParams.actions = actions;
      extraParams.selectedActions = selectedActions;
      const newPageData = {
        pageKey, pageName, admins, adminRoles, data, isPublished,
        objectId:pageData?.objectId,
        extraParams, error, versionRemarks, versionStamp
      }
      return newPageData;
    }
  }

  const saveAction = () => {
    const currentPageData = getCurrentPageData();
    const error = currentPageData.error;
    const isNew = !pageData.objectId || isCopy;
    if (error) {
      message.error(error);
    } else {
      doSave(currentPageData, isNew);
    }
  };

  const copyAction = () => {
    setIsCopy(true);
    setIsOpen(false);
    form.setFieldsValue({ pageKey: "", savedPageKey: "", pageName: "" });
  }

  const openAction = () => {
    if (mounted) setIsOpen(true);
    if (mounted) setIsCopy(false);
    if (mounted) setEditable(false);
    form.setFieldsValue({ pageKey: "", savedPageKey: "", pageName: "" });
  }

  const addAction = () => {
    doAdd();
  }

  const reloadAction = () => {
    doReload();
  }

  const deleteAction = () => {
    const savedPageKey = form.getFieldValue(["savedPageKey"]);
    if (savedPageKey) {
      doDelete(pageData);
    }
  }

  const previewAction = (e) => {
    const pageKey = form.getFieldValue(["pageKey"]);
    const currentView = form.getFieldValue(["currentView"]);
    if (doPreview) {
      doPreview(pageKey, currentView, e);
    }
  }

  const onFieldsChange = (values) => {
    if (Array.isArray(values) && values.length > 0 &&
        Array.isArray(values[0].name) && values[0].name.length > 0 &&
        values[0].name[0] === 'currentView') {
      return;
    } else if (onChange) {
      onChange(values);
    }
  }

  const publishAction = () => {
    const data = pageData.data;
    const json = decompressJsonData(data);
    const nodeMap = JSON.parse(json);
    let error = null;
    for (const key in nodeMap) {
      if (nodeMap.hasOwnProperty(key)) {
        const node = nodeMap[key];
        if (node.props.selectkey) {
          if (node.props.selectkey.match(/^UfxPreview/)) {
            error = "Select key is preview value. Cannot be published! - item key ("+node.props.itemKey+")";
            break;
          }
        }
      }
    }
    if (error) {
      message.error(error);
    } else {
      doPublish();
    }
  }

  const unpublishAllAction = () => {
    doUnpublish({all:true});
  }

  const unpublishAction = () => {
    doUnpublish();
  }

  const ACTION_TYPES = ["PageAction", "PageActionFrontEnd", "PageSetupAction"];
  return (
    <>
      <Form
        name="pagesetup.info"
        form={form}
        labelAlign="left"
        size="small"
        colon={false}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        requiredMark={false}
        onFinish={saveAction}
        onFieldsChange={onFieldsChange}
      >
        {(isOpen || (pageData?.objectId && !isCopy)) &&
          <Form.Item name="savedPageKey" label="page"
            rules={[{ required: true, message: <IntlMessages id="system.page.rules.mandatory.pageKey" text="Please provide a page ID!" /> }]}
          >
            <Select className="savedPageKey" onChange={(value) => doReload(value)} showSearch>
              {allPages?.map(c => <Select.Option key={c.pageKey} value={c.pageKey}>{c.pageKey}</Select.Option>)}
            </Select>
          </Form.Item>
        }
        {(!isOpen && (!pageData?.objectId || isCopy)) &&
          <Form.Item name="pageKey" label="page"
            rules={[{ required: true, message: <IntlMessages id="system.page.rules.mandatory.pageKey" text="Please provide a page ID!" /> }]}
          >
            <Input className="pageKey" readOnly={!editable} />
          </Form.Item>
        }
        <Form.Item name="pageName" label="name" hidden={!editable}
          rules={[{ required: true, message: <IntlMessages id="system.page.rules.mandatory.pageName" text="Please provide a page name!" /> }]}
        >
          <Input className="pageName" readOnly={!editable}></Input>
        </Form.Item>
        <Form.Item name="admins" label="admins" hidden={!editable || isCopy}>
          <SystemSelect className="admins" selectkey="User" isUseCode={true} mode="multiple" showSearch allowClear/>
        </Form.Item>
        <Form.Item name="adminRoles" label="admin roles" hidden={!editable || isCopy}>
          <SystemSelect className="adminRoles" selectkey="Role" isUseCode={true} mode="multiple" showSearch allowClear/>
        </Form.Item>
        <Form.Item name="versionRemarks" label="remarks" hidden={!editable}>
          <Input className="versionRemarks" readOnly={!editable}></Input>
        </Form.Item>
        <Form.Item name="versionStamp" label="version" hidden={!editable || isCopy}>
          <Select className="versionStamp" onChange={(value) => doReload(pageData.pageKey, value)}>
            {allVersions.map(v => <Select.Option key={v.versionStamp} value={v.versionStamp}>{getVersionDesc(v)}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item name="allowedRoles" label="roles" hidden={!editable || isCopy}>
          <Select className="allowedRoles" allowClear mode="multiple">
          {allRoles?.map((r) => (
            <Select.Option key={r.name} value={r.name}><IntlMessages id={"system.role." + r.name} text={r.name} /></Select.Option>
          ))}
          </Select>
        </Form.Item>
        <Form.Item name="actions" label="actions" hidden={!editable || isCopy}>
          <Select className="actions" allowClear mode="multiple" dropdownMatchSelectWidth={false}>
          {allActionPolicies?.filter(p => getArraysIntersection(p.actionTypes, ACTION_TYPES).length > 0).map((r) => (
            <Select.Option key={r.actionKey} value={r.actionKey}><IntlMessages id={"page.action." + r.actionKey} text={r.actionName} /></Select.Option>
          ))}
          </Select>
        </Form.Item>
        <Form.Item name="isPublished" label="published" valuePropName="checked" hidden={!editable || isCopy}>
          <Switch className="item-property" disabled/>
        </Form.Item>

        <div className="pageinfo-btn-bar">
          <ActionButton disabled={!editable || !pageData?.objectId || isCopy} onClick={copyAction} id="copy" title="Copy" ><CopyOutlined /></ActionButton>
          {!isOpen && <ActionButton disabled={!editable} onClick={openAction} id="open" title="Open" ><FolderOpenOutlined /></ActionButton>}
          {isOpen && <ActionButton onClick={addAction} id="add" title="New" ><FileAddOutlined /></ActionButton>}
          <ActionButton disabled={!editable || isOpen} id="save" title="Save" htmlType="submit"><SaveOutlined /></ActionButton>
          <ActionButton danger disabled={isCopy || !pageData?.objectId} id="delete" title="Delete" onClick={deleteAction} confirm="Are you sure to delete this page?" ><DeleteOutlined /></ActionButton>
          <ActionButton disabled={!editable} onClick={reloadAction} id="reload" title="Reload" ><ReloadOutlined /></ActionButton>
          <ActionButton onClick={() => actions.history.undo()} disabled={!enabled || !canUndo} id="undo" title="Undo" ><BiUndo /></ActionButton>
          <ActionButton onClick={() => actions.history.redo()} disabled={!enabled || !canRedo} id="redo" title="Redo" ><BiRedo /></ActionButton>
          <ActionButton disabled={!pageData?.objectId} id="preview" title="Preview" onClick={previewAction}><EyeOutlined /></ActionButton>
          {!pageData?.isPublished && <ActionButton disabled={pageData?.isPublished} id="publish" title="Publish" onClick={publishAction} confirm="Are you sure to publish this page?"><MdOutlinePublic /></ActionButton>}
          {pageData?.isPublished && <ActionButton id="republish" title="Republish" onClick={publishAction} confirm="Are you sure to re-publish this page?"><MdOutlinePublic /></ActionButton>}
          <ActionButton disabled={!pageData?.isPublished} id="unpublish" title="Unpublish" onClick={unpublishAction} confirm="Are you sure to unpublish this page?"><MdOutlinePublicOff /></ActionButton>
          <ActionButton id="unpublishall" title="Unpublish All" onClick={unpublishAllAction} confirm="Are you sure to unpublish all versions?"><MdDisabledVisible /></ActionButton>
        </div>
      </Form>
    </>
  );
}


const serializeNodeTree = (node, nodeTree) => {
  const patchedId = {};
  const nodes = {};
  const rootId = nodeTree.rootNodeId;
  for (const id in nodeTree.nodes) {
    const newId = id + '_' + uid();
    patchedId[id] = newId;
    const current = node(id);
    nodes[newId] = current.toSerializedNode();
  }
  for (const id in nodeTree.nodes) {
    const newId = patchedId[id];
    const current = nodes[newId];
    current.nodes = current.nodes?.map(i => patchedId[id] || id)
    current.parent = patchedId[current.parent];
  }
  return {rootNodeId: patchedId[rootId], nodes};
}

const deserializeNodeTree = async ({dataNodeTree, parent, index, add, selectNode, parseReactElement, node}) => {
  try {
    const idMap = {};
    let rootItemKey = null;
    let rootNodeId = null;
    for (const nodeId in dataNodeTree.nodes) {
      const n = dataNodeTree.nodes[nodeId];
      const name = n.type.resolvedName;
      if (name !== 'Container') {
        const props = n.props;
        if (!rootItemKey && props.itemKey) {
          rootItemKey = props.itemKey;
        }
        const pid = idMap[n.parent] || parent;
        console.log('deserializeNodeTree()', {pid, n, parent})
        const data = {name, props};
        const newNodeId = createNode({data, node, add, parseReactElement, parent: pid, index})
        if (!rootNodeId) {
          rootNodeId = newNodeId;
        }
        await delay(100);
        const newNode = node(newNodeId);
        const linkedNodes = newNode.linkedNodes();
        if (linkedNodes?.length > 0) {
          idMap[nodeId] = linkedNodes[0];
        } else {
          idMap[nodeId] = newNode.get().id;
        }
      } else {
        idMap[nodeId] = idMap[n.parent];
      }
    }
    if (selectNode && rootNodeId) selectNode(rootNodeId);
    return rootItemKey;
  } catch (error) {
    console.log('deserializeNodeTree() failed', error)
  }
}

const sendNodeToClipboard = ({selected, node}) => {
  const self = node(selected.id);
  const { data: {name, props}} = self.get();
  const nodeTree = serializeNodeTree(node, self.toNodeTree());
  const craftSelectedData = {name, props}
  console.log('selected data', {craftSelectedData, nodeTree})
  copyToClipboard({text: JSON.stringify({craftSelectedData, nodeTree})})
  return props?.itemKey;
}

const CONTAINERS = ['Container', 'SpCanvas', 'SpPanel', 'SpDataGrid', 'SpTabsPane']
const findContainer = ({self, node}) => {
  try {
    const ancestors = self.ancestors();
    const childs = self.linkedNodes();
    const selfId = self.get().id;
    const candidates = [...ancestors, ...childs]
    let container = null;
    let index = -1;
    for (const candidateId of candidates) {
      const candidate = node(candidateId)?.get();
      if (CONTAINERS.includes(candidate.data.name)) {
        container = candidateId;
        if (candidate.data?.nodes?.indexOf) {
          const originalIndex = candidate.data.nodes.indexOf(selfId);
          if (originalIndex !== -1) {
            index = originalIndex + 1;
          }
        }
        break;
      }
    }
    if (!container) {
      console.log('findContainer() not found', {self, node})
    }
    return {container, index};
  } catch (error) {
    console.log('findContainer() not found', {self, node, error})
    return null;
  }
}

const createNode = ({data, add, parseReactElement, parent, index}) => {
  const comp = UserComponents[data.name];
  if (comp) {
    const reactNode = React.createElement(comp, data.props);
    const nodeTree = parseReactElement(reactNode).toNodeTree();
    const rootNodeId = nodeTree.rootNodeId;
    const rootNode = nodeTree.nodes[rootNodeId];
    if (index > 0) {
      add(rootNode, parent, index)
    } else {
      add(rootNode, parent)
    }
    return rootNodeId;
  }
}

const createNodeFromClipboard = async ({add, selectNode, parseReactElement, node, selected}) => {
  try {
    const self = node(selected.id);
    let {container: parent, index} = findContainer({self, node})

    const content = await getClipboardContent();
    console.log('paste content', {content, parent, index});
    const dataNodeTree = JSON.parse(content)?.nodeTree;
    if (dataNodeTree && parent) {
      return await deserializeNodeTree({dataNodeTree, parent, index, add, selectNode, parseReactElement, node})
    }
  } catch (error) {
    console.log('ignore paste action', error)
  }
}

function isActiveElementBody() {
  if (document.activeElement === document.body) return true;
  if (document.activeElement?.closest('.craft-main-panel-left')) return true;

  return false;
}

function doCopy({selected, node, lbl}) {
  if (selected) {
    const itemKey = sendNodeToClipboard({selected, node});
    if (itemKey) {
      message.info(lbl("system.form.copied_to_clipboard", "{itemKey} copied to clipboard.", {itemKey}));
    }
  }
}

async function doPaste({add, selectNode, parseReactElement, node, selected, lbl}) {
  const itemKey = await createNodeFromClipboard({add, selectNode, parseReactElement, node, selected});
  if (itemKey) {
    message.info(lbl("system.form.pasted_from_clipboard", "{itemKey} pasted from clipboard.", {itemKey}));
  }
}

const TheFrame = ({ showProperty }) => {
  const { selectedNode, actions: {add, selectNode}, query: { node, parseReactElement } } = useEditor(EditorCollector);
  const prevSelectedNode = useRef();
  const { locale } = settingsSignal;
  const lbl = useLbl(locale);

  const onKeyDown = useCallback((e) => {
    if (isActiveElementBody()) {
      const selected = prevSelectedNode.current;
      if (e.ctrlKey === true && e.key === 'c') {
        doCopy({selected, node, lbl})
      } else if (e.ctrlKey === true && e.key === 'v') {
        doPaste({add, selectNode, parseReactElement, node, selected, lbl})
      }
    }
  }, [node, lbl, add, selectNode, parseReactElement])

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    }
  }, [onKeyDown])

  useEffect(() => {
    if (prevSelectedNode.current !== selectedNode) {
      if (selectedNode) showProperty();
    }
    prevSelectedNode.current = selectedNode;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedNode])

  const defaultStyle = {
    minHeight: "500px",
  }
  return (
    <Frame>
      <Element is={SpCanvas} className="sp-canvas" style={defaultStyle} canvas>
      </Element>
    </Frame>
  );
}

export const setupPage = ({ data, preview, buttonBar, pageState, setPageState, firePageEvent }) => {
  let json = data;
  let nodeMap;
  if (data && typeof data === 'string') {
    try {
      json = decompressJsonData(data);
      nodeMap = JSON.parse(json);
    } catch (e) {
      nodeMap = JSON.parse(data);
    }
  } else {
    nodeMap = data;
  }
  if (json && nodeMap) {
    const node = nodeMap["ROOT"];
    return processNode({ nodeMap, node, preview, buttonBar, pageState, setPageState, firePageEvent });
  } else {
    return null;
  }
}

const processNode = ({ nodeMap, node, preview, buttonBar, pageState, setPageState, firePageEvent }) => {
  const type = node.type.resolvedName;
  const UC = UserRuntimeComponents[type];
  const props = node.props ? node.props : {};
  props.selectedId = undefined;
  delete props.selectedId;
  if (UC) {
    if (UC.enablePageEvent) {
      props.firePageEvent = firePageEvent;
    }
    const collectTags = UC.collector ? UC.collector() : null
    const nodeIds = getChildNodes({ node, nodeMap, collectTags: collectTags });
    const nodes = nodeIds.map(id => nodeMap[id]);
    const callProcessChildNode = (childNode) => {
      return processChildNode({ nodeMap, node: childNode, preview, pageState, setPageState, firePageEvent });
    }
    const callProcessNode = (node) => {
      return processNode({ nodeMap, node: node, preview, pageState, setPageState, firePageEvent })
    }

    return (
      <SpDataGridContext.Consumer>
        {ctx => {
          if (ctx?.pageState && ctx.setPageState) {
            const pageStateProps = UC.enablePageState ? {pageState: ctx.pageState, setPageState: ctx.setPageState} : {};
            processForwardAttribute(props, pageStateProps.pageState, UC.forwardAttributes);
            if (collectTags) {
              nodes.forEach(n => processForwardAttribute(n.props, pageStateProps.pageState, UC.forwardAttributes))
              return <UC key={node.id} pageKey={pageState?.pageKey} {...pageStateProps} childNodes={nodes} processNode={callProcessNode} processChildNode={callProcessChildNode} {...props}>{processChildNode({ nodeMap, node, preview, pageState: pageStateProps.pageState, setPageState, firePageEvent, collectTags: collectTags })}{buttonBar}</UC>
            } else {
              return <UC key={node.id} pageKey={pageState?.pageKey} {...pageStateProps} {...props}>{processChildNode({ nodeMap, node, preview, pageState: pageStateProps.pageState, setPageState, firePageEvent, collectTags: collectTags })}{buttonBar}</UC>
            }
          } else {
            const pageStateProps = UC.enablePageState ? {pageState, setPageState} : {};
            processForwardAttribute(props, pageStateProps.pageState, UC.forwardAttributes);
            if (collectTags) {
              nodes.forEach(n => processForwardAttribute(n.props, pageStateProps.pageState, UC.forwardAttributes))
              return <UC key={node.id} pageKey={pageState?.pageKey} {...pageStateProps} childNodes={nodes} processNode={callProcessNode} processChildNode={callProcessChildNode} {...props}>{processChildNode({ nodeMap, node, preview, pageState, setPageState, firePageEvent, collectTags: collectTags })}{buttonBar}</UC>
            } else {
              return <UC key={node.id} pageKey={pageState?.pageKey} {...pageStateProps} {...props}>{processChildNode({ nodeMap, node, preview, pageState, setPageState, firePageEvent, collectTags: collectTags })}{buttonBar}</UC>
            }
          }
        }}
      </SpDataGridContext.Consumer>
    )
  }
}

const getChildNodes = ({ node, nodeMap, collectTags }) => {
  const nodes = node.nodes;
  const linkedNodes = Object.values(node.linkedNodes);
  if (collectTags) {
    const includesNodes = collectTags;
    let children = getChildNodes({ node });
    const matchedChildren = [];

    let level = 0;
    while (children && children.length > 0) {
      if (level > 10) {
        break;
      }
      const newChildren = [];
      children.forEach(c => {
        const n = nodeMap[c];
        if (includesNodes.indexOf(n.type.resolvedName) !== -1) {
          matchedChildren.push(c);
        }
        newChildren.push(...getChildNodes({ node: n }));
      });
      level++;
      children = newChildren;
    }
    return matchedChildren;
  } else if (nodes && nodes.length > 0) {
    return nodes;
  } else if (linkedNodes && linkedNodes.length > 0) {
    return linkedNodes;
  } else {
    return [];
  }
}

const processChildNode = ({ nodeMap, node, preview, pageState, setPageState, firePageEvent, collectTags }) => {
  const nodes = getChildNodes({ node, nodeMap, collectTags })
  if (nodes && nodes.length > 0) {
    return (<>
      {nodes.map(id => {
        const n = nodeMap[id];
        return <React.Fragment key={id}>{processNode({ nodeMap, node: n, preview, pageState, setPageState, firePageEvent })}</React.Fragment>;
      })}
    </>);
  } else {
    return false;
  }
}

export const Library = ({currentView}) => {
  const [filter, setFilter] = useState("");
  const locale = settingsSignal.locale;
  const lbl = useLbl(locale);
  const { connectors } = useEditor();
  const doFilter = (button) => {
    if (isEmpty(filter)) {
      return true;
    } else {
      return getText(button?.title, "text")?.toUpperCase().indexOf(filter.toUpperCase()) !== -1;
    }
  }
  return (
    <div className="craft-library">
      <Input size="small" placeholder={lbl("system.form.button-group.filter","filter")} onChange={(e) => {
        setFilter(e.target.value);
      }}/>
      <span className="system-form-lib-title">
        <IntlMessages id="system.page.button-group.container" text="Container"/>
      </span>
      {getButtons("Container").filter(doFilter).map(b => {
        return (
          <Tooltip mouseEnterDelay={1} key={b.key} title={b.title}><Button size="small" className="library-btn" ref={(ref) => connectors.create(ref, b.template)}>{b.icon}</Button></Tooltip>
        );
      })}
      <span className="system-form-lib-title">
        <IntlMessages id="system.page.button-group.component" text="Component"/>
      </span>
      {getButtons("Component").filter(doFilter).map(b => {
        return (
          <Tooltip mouseEnterDelay={1} key={b.key} title={b.title}><Button size="small" className="library-btn" ref={(ref) => connectors.create(ref, b.template)}>{b.icon}</Button></Tooltip>
        );
      })}
      <span className="system-form-lib-title">
        <IntlMessages id="system.page.button-group.input-component" text="Input"/>
      </span>
      {getButtons("Component-input").filter(doFilter).map(b => {
        return (
          <Tooltip mouseEnterDelay={1} key={b.key} title={b.title}><Button size="small" className="library-btn" ref={(ref) => connectors.create(ref, b.template)}>{b.icon}</Button></Tooltip>
        );
      })}
      <span className="system-form-lib-title">
        <IntlMessages id="system.page.button-group.chart-component" text="Chart"/>
      </span>
      {getButtons("Component-chart").filter(doFilter).map(b => {
        return (
          <Tooltip mouseEnterDelay={1} key={b.key} title={b.title}><Button size="small" className="library-btn" ref={(ref) => connectors.create(ref, b.template)}>{b.icon}</Button></Tooltip>
        );
      })}
    </div>
  );
};

export { PageInfo, SettingsPanel, TheFrame, UserComponents };

