import { getMessage } from "lngProvider";
import React, { useEffect, useRef, useState } from "react";
import { GlobalSettings, GlobalSettingsEevent } from "../appRedux/reducers/Settings";

const InjectMessage = ({ id, text, values }) => {
  const localeRef = useRef();
  const [locale, setLocale] = useState(GlobalSettings.locale)
  const [finalText, setFinalText] = useState(text);
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    localeRef.current = GlobalSettingsEevent.register(() => {
      setLocale(GlobalSettings.locale);
    });
    return () => {
      mounted.current = false;
      GlobalSettingsEevent.unregister(localeRef.current);
    }
  }, [])
  useEffect(() => {
    const message = getMessage(locale.languageId, id, text);
    const finalText = processMessage(id, message ? message : text, values);
    if (mounted.current) setFinalText(finalText);
  },[id, text, values, locale])
  return (
    <span>{finalText}</span>
  );
}

const processMessage = (id, text, values) => {
  if (text) {
    if (values) {
      Object.keys(values).forEach(k => {
        const v = values[k];
        if (v) {
          text = text.replace(new RegExp('\\{'+k+'\\}','g'), `${v}`);
        }
      })
    }
    return text;
  } else {
    return id;
  }
}

export default InjectMessage;
