import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Switch } from "antd";
import { Form, Input, Select } from "components/Form";
import { formatterApi } from "parse-api";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineNumber } from "react-icons/ai";
import { prepareText, log } from "util/algorithm";
import IntlMessages from "util/IntlMessages";
import {
  generateTooltip,colSls, convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate, updateTableCell, convertRules
} from "./common";

const SfInputNumberSetting = () => {
  const [formatters, setFormatters] = useState([]);
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    const fetchData = async () => {
      const list = await formatterApi.loadSystemFormatter();
      if (mounted.current) setFormatters(list);
    }
    fetchData();
    return () => {
      mounted.current = false;
    }
  }, [])
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="placeholder" label="placeholder">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="min" label="min">
        <InputNumber className="item-property" />
      </Form.Item>
      <Form.Item name="max" label="max">
        <InputNumber className="item-property" />
      </Form.Item>
      <Form.Item name="step" label="step">
        <InputNumber className="item-property" />
      </Form.Item>
      <Form.Item name="systemFormatter" label="formatter">
        <Select className="item-property" allowClear>
          {formatters.map(f => {
            return (
              <Select.Option key={f.formatterKey} value={f.formatterKey}>{f.name}</Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item name="tiptext" label="tooltip text" >
        <Input.TextArea
          className="item-property"
          autoSize allowClear
        />
      </Form.Item>
      <Form.Item name="tiplink" label="tooltip link" >
        <Input className="item-property" type="url"/>
      </Form.Item>
      <Form.Item name="stringMode" label="text mode" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="readOnly" label="read-only" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
        <Form.Item name="hidden" label="hidden" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="editInTable" label="edit col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="isSelectKey" label="select key" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const createFormatter = (formatter) => {
  if (formatter && formatter.formatters) {
    return (value, info) => {
      try {
        if (value) {
          let currval = `${value}`;
          for (const element of formatter.formatters) {
            const f = element;
            if (f.decimal) {
              try {
                currval = parseFloat(currval);
              } catch (e) {
                log("failed to parse float value", e);
              }
              if (currval?.toFixed) {
                currval = currval.toFixed(f.decimal);
              }
            }
            if (f.input === '' && f.output.indexOf('{value}') !== -1) {
              currval = prepareText(f.output, {value: currval});
            } else {
              currval = currval.replace(RegExp(f.input, 'g'), f.output);
            }
          }
          return currval;
        } else {
          return value;
        }
      } catch (e) {
        log(e);
        return value;
      }
    }
  } else {
    return undefined;
  }
}

const createParser = (formatter) => {
  if (formatter && formatter.parsers) {
    return (value) => {
      try {
        if (value) {
          let currval = `${value}`;
          for (const element of formatter.parsers) {
            const f = element;
            currval = currval.replace(RegExp(f.input, 'g'), f.output);
          }
          return parseFloat(currval);
        } else {
          return parseFloat(value);
        }
      } catch (e) {
        log(e);
        return value;
      }
    }
  } else {
    return undefined;
  }
}

export const SfInputNumber = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions);
  return (
    <SfpInputNumber doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const MyInputNumber = ({inputRef, systemFormatter, value, onChange, noupdate, disabled, ...otherProps}) => {
  const ref = useRef();
  const [systemFormatterData, setSystemFormatterData] = useState();
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, [])
  useEffect(() => {
    if (systemFormatter) {
      formatterApi.getSystemFormatter(systemFormatter).then(sf => {
        if (mounted.current) setSystemFormatterData(sf);
      })
    }
  }, [systemFormatter]);
  const f = createFormatter(systemFormatterData);
  const p = createParser(systemFormatterData);
  if (ref.current === undefined) ref.current = noupdate && value !== undefined;
  if (systemFormatter && !systemFormatterData) return null;
  else return <InputNumber ref={inputRef} value={value} onChange={onChange}
    formatter={f} parser={p}
    disabled={disabled || ref.current} {...otherProps}/>
}

export const SfpInputNumber = ({
  doRef, form, condistyles, className, style, dbtn, hidden, hideInTable, editInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, volitate, skipcopy,tiptext,tiplink,
  inputref, styleStr, systemFormatter, children, ...otherProps }) => {
  const [fxr, setFxr] = useState({});
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, [])
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const newRules = convertRules(rules, 'number');
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name} label={title} tooltip={generateTooltip(tiptext,tiplink)}
               shouldUpdate={fx} hidden={fxr.hidden || (!doRef && hidden)} rules={fxr.hidden || hidden || otherProps.disabled ? null : newRules}
              labelAlign={labelAlign} labelCol={lcs} wrap>
              <MyInputNumber inputRef={inputref}
                systemFormatter={systemFormatter}
                style={sls} disabled={fxr.disabled || otherProps.disabled} {...otherProps} />
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

const InputNumberRenderer = ({systemFormatter, value, record, index, form, tableKey, onRowChange, editInTable, itemKey, tableColWidth, min, max, step, disabled}) => {
  const [systemFormatterData, setSystemFormatterData] = useState();
  const [editing, setEditing] = useState(false);
  const [myValue, setMyValue] = useState(value);
  const leditInTable = editInTable && !disabled;
  const ref = useRef();
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, [])
  useEffect(() => {
    if (systemFormatter) {
      formatterApi.getSystemFormatter(systemFormatter).then(sf => {
        if (mounted.current) setSystemFormatterData(sf);
      })
    }
  }, [systemFormatter])
  useEffect(() => {
    setMyValue(value);
  }, [value]);
  const onClick = (event) => {
    setEditing(true);
    event.preventDefault();
    event.stopPropagation();
  }
  if (editing) {
    const onChange = (value) => {
      try {
        setMyValue(value);
      } catch (e) {
        console.error(e);
      }
    }
    const onBlur = (event) => {
      updateTableCell({form, tableKey, record, itemKey, myValue, onRowChange, setEditing, event, index});
    }
    const onKeyDown = (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        ref.current.blur();
      }
    }
    const controls = true;
    const className = "row-editing table-cell-inputnumber"
    const props = {value: myValue, className, onBlur, onChange, ref, onKeyDown, min, max, step, controls};
    if (tableColWidth) props.style = {"minWidth": `${tableColWidth}px`,"maxWidth": `${tableColWidth}px`}
    return <InputNumber autoFocus {...props} />
  } else {
    const editingProps = leditInTable ? {className: "row-editable table-cell-inputnumber", onClick} : {};
    if (tableColWidth) editingProps.style = {"minWidth": `${tableColWidth}px`, "maxWidth": `${tableColWidth}px`}
    if (systemFormatterData) {
      const f = createFormatter(systemFormatterData);
      return <span {...editingProps}>{f(myValue || 0)}</span>;
    } else {
      let displayValue = myValue;
      if (displayValue) displayValue = Number(displayValue);
      if (isNaN(displayValue)) displayValue = myValue;
      return <span {...editingProps}>{displayValue}</span>;
    }
  }
}

SfpInputNumber.render = ({ systemFormatter, editInTable, itemKey, tableColWidth, disabled, min, max, step }, form, tableKey, onRowChange) => (value, record, index) => {
  const props = {systemFormatter, value, record, index, form, tableKey, onRowChange, editInTable, itemKey, tableColWidth, disabled, min, max, step};
  return <InputNumberRenderer {...props}/>
}

SfInputNumber.craft = {
  displayName: "Input Number",
  related: {
    settings: SfInputNumberSetting
  }
}

SfInputNumber.validate = (props, {parents, container, extraParams, formData}) => {
  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "number",
      systemFormatter: props.systemFormatter,
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      sortable: true,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
    });
  }
}

SfInputNumber.isSearchable = true;
SfInputNumber.isRulable = true;

registerComponent({
  key: "SfInputNumber",
  component: SfInputNumber,
  runtimeComponent: SfpInputNumber,
  template: <SfInputNumber itemKey={getId('inputNumber')} className="sf-inputnumber wrap"
    title={"Input Number"} span={24} labelColStr="span:6" />,
  title: <IntlMessages id="system.form.library.input-number" text="Input Number" />,
  icon: <AiOutlineNumber className="react-icons icon-ai" />,
  type: "Component",
  sequence: 2,
});

export default SfInputNumber;