import { useEditor, useNode } from "@craftjs/core";
import { Col, Switch } from "antd";
import { Form, Input } from "components/Form";
import React, { useEffect, useState } from "react";
import { MdOutlineColorLens } from "react-icons/md";
import IntlMessages from "util/IntlMessages";
import { ColorPicker } from "../../../../components/Form";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting, syncPageState, updatePageState } from "./common";

const SpColorPickerSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="defaultValue" label="default value">
        <Input className="item-property"/>
      </Form.Item>
      <Form.Item name="allowClear" label="allow clear" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  )
}

export const SpColorPicker = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppColorPicker doRef={ref => connect(drag(ref))} dbtn={dbtn} {...props} selectedStyle={style} />
  )
}

export const SppColorPicker = ({
  doRef, dbtn, selectedStyle,
  pageKey, itemKey, pageState, setPageState, value,  defaultValue,
  allowClear, disabled,
  ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}
  const inputProps = {allowClear, disabled};
  const [myValue, setMyValue] = useState(defaultValue);

  useEffect(() => {
    syncPageState({itemKey, myValue, setMyValue, pageState, setPageState})
  },[itemKey, myValue, pageState, setPageState])

  const onMyChange = (myValue) => {
    updatePageState({itemKey, myValue, setMyValue, pageState, setPageState})
  }

  return (
    <Col ref={doRef} {...props}>
      <ColorPicker value={myValue} onChange={onMyChange} {...inputProps} />
      {dbtn}
    </Col>
  );
}
SppColorPicker.enablePageState = true;

SpColorPicker.craft = {
  displayName: "Color",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpColorPickerSetting
  }
}

registerComponent({
  key: "SpColorPicker",
  component: SpColorPicker,
  runtimeComponent: SppColorPicker,
  template: <SpColorPicker itemKey={getId('color')} className="sp-color" title={"Color"} span={24} />,
  title: <IntlMessages id="system.page.library.color" text="Color" />,
  icon: <MdOutlineColorLens  className="react-icons icon-antd"/>,
  type: "Component",
  subtype: "input",
  sequence: 11,
});

export default SpColorPicker;