import { InputNumber } from "antd";
import { useEffect, useState } from "react";

export const PixelInput = ({value, onChange, unit, ...props}) => {
    const [pixel, setPixel] = useState();
    if (!unit) unit = "px";
    const PIXEL_PATTERN = new RegExp(`^(\\d+)${unit}$`)
    useEffect(()=> {
      if (value) {
        if (value.match(PIXEL_PATTERN)) {
          setPixel(parseInt(value.replace(PIXEL_PATTERN, "$1"), 10));
        } else {
          onChange(null);
          setPixel(null);
        }
      } else {
        setPixel(null);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[value])
    const onPixelChange = (value) =>  {
      setPixel(value);
      if (onChange) {
        if (value) {
          onChange(`${value}${unit}`);
        } else {
          onChange(null);
        }
      }
    }
    return (
      <InputNumber className="item-property" value={pixel} onChange={onPixelChange} {...props}/>
    )
  }