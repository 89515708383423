import React from "react";
import { FaUserLock } from "react-icons/fa";
import IntlMessages from "util/IntlMessages";
import { registerComponent } from "./common";
import { SfpTransfer, SfTransfer } from "./SfTransfer";

registerComponent({
  key: "SfUser",
  component: SfTransfer,
  runtimeComponent: SfpTransfer,
  template: <SfTransfer itemKey={'users'} className="sf-tranfer wrap" sourceTitle="Available" targetTitle="Assigned" styleStr="width:50%,height:200px"
    title={"Users"} selectkey={"User"} isUseCode={true} showSearch={true} datasec={true} datasectype={"users"} span={24} labelColStr="span:6" />,
  title: <IntlMessages id="system.form.library.users" text="Users" />,
  icon: <FaUserLock  className="react-icons icon-fa"/>,
  type: "Component",
  subtype: "sys",
  sequence: 5,
});
