import { MenuOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import { useEffect, useState } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import IntlMessages from "util/IntlMessages";
import { equals } from '../../util/algorithm';

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#999',
    }}
  />
));

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);
const SortHandleColumn = {
    title: <IntlMessages id="system.form.sort" text="Sort"/>,
    dataIndex: 'sort',
    width: 50,
    className: 'drag-visible',
    render: () => <DragHandle />,
};

export const SortableTable = ({columns, dataSource, onSort, rowKey, components, ...props}) => {
  const [_dataSource, _setDataSource] = useState([]);
  useEffect(() => {
    const newDataSource = dataSource.map((d, index) => {
      const d2 = {...d};
      if (d2.rowKey) {
        d2.key = d2.rowKey;
        delete d2.rowKey;
      }
      d2.index = index;
      return d2;
    });
    _setDataSource(newDataSource);
  },[dataSource])
  useEffect(() => {
    if (onSort) {
      const oldIndexes = {};
      dataSource.forEach((d, index) => {
        if (d.rowKey) {
          oldIndexes[d.rowKey] = index;
        } else {
          oldIndexes[d.key] = index;
        }
      });
      const indexes = {};
      _dataSource.forEach((d, index) => {
        indexes[d.key] = index;
      });
      if (!equals(oldIndexes, indexes)) {
        onSort(_dataSource, indexes);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[_dataSource])
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(_dataSource.slice(), oldIndex, newIndex).filter(
        (el) => !!el,
      );
      _setDataSource(newData);
    }
  };

  const DraggableContainer = (props) => {
    return (<SortableBody
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  )};

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = _dataSource.findIndex((x) => x.index === restProps['data-row-key']);
    const key = _dataSource[index]?.key;
    return <SortableItem key={key} index={index} {...restProps} />;
  };

  return (
    <Table
      pagination={false}
      dataSource={_dataSource}
      columns={[SortHandleColumn, ...columns]}
      rowKey="index"
      components={{
        ...components,
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
      {...props}
      size="small"
    />
  );
};