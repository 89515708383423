import { Element, useEditor, useNode } from "@craftjs/core";
import { Col, Switch } from "antd";
import { Form, Select } from "components/Form";
import { useLbl } from "lngProvider";
import { dataExplorer, dataPolicyApi } from "parse-api";
import { useEffect, useRef, useState } from "react";
import { AiOutlineRadarChart } from "react-icons/ai";
import { Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer, Tooltip } from 'recharts';
import IntlMessages from "util/IntlMessages";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting } from "./common";
import { log, equals } from "util/algorithm";
import { settingsSignal } from "../../../../util/signal";

export const SpChartRadar = ({...props}) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppChartRadar doRef={ref => connect(drag(ref))} selectedStyle={style} dbtn={dbtn} {...props}/>
  )
}

export const SppChartRadar = ({
  doRef, dbtn, selectedStyle, pageKey, itemKey, pageState, setPageState,
  policy, preview, gradient, grid, legend, tootip,
  ...otherProps }) => {
  const { locale } = settingsSignal;
  const [data, setData] = useState();
  const lbl = useLbl(locale);
  const [dataPolicyParams, setDataPolicyParams] = useState(null);
  if (!preview) preview = false;
  const mounted = useRef();
  useEffect(()=>{
    mounted.current = true;
    return ()=>{
      mounted.current = false;
    }
  },[])
  useEffect(()=>{
    const fetchData = async () => {
      if (policy) {
        try {
          let params = null;
          if (dataPolicyParams) {
            params = dataPolicyParams;
          }
          const data = await dataPolicyApi.getDataPolicyData(preview, policy, "RadarChart", locale, params);
          log("data", data);
          if (mounted.current) setData(data);
        } catch (e) {
          log("get data failed", e);
          if (mounted.current) setData(null);
        }
      } else {
        if (mounted.current) setData(null);
      }
    }
    fetchData();
  },[dataPolicyParams, locale, policy, preview])
  useEffect(() => {
    if (pageState) {
      const params = pageState[itemKey + "_params" ];
      if (params && !equals(dataPolicyParams, params)) {
        setDataPolicyParams(params);
      }
    }
  },[dataPolicyParams, itemKey, pageState])
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}

  return (
    <Col ref={doRef} {...props}>
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart
          data={data?.data}
        >
          <PolarGrid />
          <PolarAngleAxis dataKey={data?.nameKey} />
          <PolarRadiusAxis />
          {tootip && <Tooltip />}
          {legend && <Legend verticalAlign="bottom" height={36}/>}
          {data?.dataKeys.map((key, index)=>{
            return <Radar key={"area"+key} label={lbl(`user.data-policy.${policy}.${key}`, key)} type="monotone" dataKey={key} stroke={data?.stokes[index]} fill={data?.fills[index]} fillOpacity={0.6} />
          })}
        </RadarChart>
      </ResponsiveContainer>
      {dbtn}
    </Col>
  );
}

const SpChartRadarSetting = () => {
  const [policyOptions, setPolicyOptions] = useState([]);
  const mounted = useRef();
  useEffect(()=>{
    mounted.current = true;
    return ()=>{
      mounted.current = false;
    }
  },[])
  useEffect(()=>{
    const fetchData = async () => {
      const params = [{key:"dataTypes", operator:"=", value:"RadarChart"}];
      const list = await dataExplorer.searchAll("SystemDataPolicy", params, "dataName");
      if (mounted.current) setPolicyOptions(list.map(p=>({value:p.dataKey,label:p.dataName})));
    }
    fetchData();
  },[])
  return (
    <>
      <Form.Item name="policy" label="policy" >
        <Select className="item-property" options={policyOptions} allowClear/>
      </Form.Item>
      <Form.Item name="preview" label="preview" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="legend" label="legend" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="tootip" label="tootip" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  );
}

SpChartRadar.craft = {
  displayName: "Radar Chart",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpChartRadarSetting
  }
}
SppChartRadar.enablePageState = true;

registerComponent({
  key:"SpChartRadar",
  component: SpChartRadar,
  runtimeComponent: SppChartRadar,
  template: <Element canvas is={SpChartRadar} itemKey={getId('chartradar')} preview={true}
            className="sp-chart" span="24" style={{height:"300px"}}/>,
  title: <IntlMessages id="system.page.library.chartradar" text="Radar Chart" />,
  icon: <AiOutlineRadarChart  className="react-icons icon-ai"/>,
  type: "Component",
  subtype: "chart",
  sequence: 5,
});

export default SpChartRadar;