import { LineOutlined } from "@ant-design/icons";
import { useEditor, useNode } from "@craftjs/core";
import { Divider, Switch } from "antd";
import classNames from 'classnames';
import { Form, Input, Select } from "components/Form";
import IntlMessages from 'util/IntlMessages';
import { convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfPanelContext } from "./common";

const DIVIDER_ORIENTATION = [
  "center", "left", "right",
]

const DIVIDER_TYPE = [
  "vertical", "horizontal",
]

const SfDividerSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="orientation" label="orientation">
        <Select className="item-property" placeholder="orientation" allowClear showSearch>
          {DIVIDER_ORIENTATION.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name="type" label="type">
        <Select className="item-property" placeholder="orientation" allowClear showSearch>
          {DIVIDER_TYPE.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name="plain" label="plain" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="dashed" label="dashed" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
    </>
  )
}

export const SfDivider = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpDivider doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const SfpDivider = ({ ...props }) => {
  if (props.doRef) {
    return (
      <SfpDividerInner {...props} />
    )
  } else {
    return (
      <SfPanelContext.Consumer>
        {(ctx) => {
          return (
            <SfpDividerInner index={ctx ? ctx.index : null} {...props} />
          )
        }}
      </SfPanelContext.Consumer>
    )
  }
}

export const SfpDividerInner = ({
  formKey, doRef, style, dbtn, dashed, plain, orientation, type, index,
  itemKey, className, styleStr, title,
  disabled, ...otherProps }) => {
  const prefixCls = "ant-divider";
  type = type ? type : "horizontal";
  orientation = orientation ? orientation : "center"
  const orientationPrefix = orientation && orientation.length > 0 ? `-${orientation}` : orientation;
  const hasChildren = !!title;
  const classString = classNames(
    prefixCls,
    `${prefixCls}-${type}`,
    {
      [`${prefixCls}-with-text`]: hasChildren,
      [`${prefixCls}-with-text${orientationPrefix}`]: hasChildren,
      [`${prefixCls}-dashed`]: !!dashed,
      [`${prefixCls}-plain`]: !!plain,
    },
    className,
  );
  let sls = convertStyleStr(styleStr);
  if (style) sls = sls ? { ...sls, ...style } : style;
  const text = title ? <IntlMessages id={`user.form.${formKey}.divider.${itemKey}`} text={title} /> : null;
  if (doRef) {
    const height = "18px";
    const width = type === "vertical" ? "18px" : null;
    sls = { ...sls, height: height, width: width }
    return (
      <div ref={doRef} style={sls} className={classString}
      >{text} {dbtn}</div>
    );
  } else {
    if (text && typeof index === 'number') {
      return (
        <Divider style={sls} className={className}
          type={type} dashed={dashed} orientation={orientation} plain={plain}
        >{text} {(index + 1)}</Divider>
      );
    } else if (text) {
      return (
        <Divider style={sls} className={className}
          type={type} dashed={dashed} orientation={orientation} plain={plain}
        >{text}</Divider>
      );
    } else {
      return (
        <Divider style={sls} className={className}
          type={type} dashed={dashed} orientation={orientation} plain={plain}
        ></Divider>
      );  
    }
  }
}

SfDivider.craft = {
  displayName: "Divider",
  related: {
    settings: SfDividerSetting
  }
}

SfDivider.validate = (props, {extraParams, formData}) => {
  props.formKey = formData.formKey;
}

registerComponent({
  key:"SfDivider", 
  component: SfDivider, 
  runtimeComponent: SfpDivider,
  template: <SfDivider itemKey={getId('divider')} className="sf-divider" />,
  title: <IntlMessages id="system.form.library.divider" text="Divider" />,
  icon: <LineOutlined className="react-icons icon-antd"/>,
  type: "Component",
  sequence: 12,
});

export default SfDivider;