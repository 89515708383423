import { FolderAddOutlined } from "@ant-design/icons";
import { Element, useEditor, useNode } from "@craftjs/core";
import { Row, Switch, Tabs } from "antd";
import { Form, Input } from "components/Form";
import IntlMessages from "util/IntlMessages";
import { convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, persistentInfo, registerComponent, SfPanelContext, SfTabsContext } from "./common";
import { Container } from "./Container";

export const SfTabsPane = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions);
  const children =
    <Element is={Container} type="SfTabsPane" id={"children"} canvas>
    </Element>
  return (
    <SfpTabsPane doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props}>{children}</SfpTabsPane>
  )
}

export const SfpTabsPane = ({
  doRef, style, dbtn, children, itemKey, className, styleStr, title, table, showTitle, tab, ...otherProps }) => {
  const sls = convertStyleStr(styleStr);
  const panelContext = { name: [itemKey] };

  if (doRef) {
    return (
      <SfPanelContext.Provider value={panelContext}>
        <div ref={doRef} className="craft-form-tab" style={style}>
          <div className="craft-form-tab-title"><span>Tab : {tab}</span></div>
          <div className={className} style={sls}>
            {showTitle && <div className="tab-title">{title}</div>}
            <div className="tab-body">
              <Row>
                {persistentInfo(table, panelContext.name)}
                {children}
              </Row>
            </div>
          </div>
          {dbtn}
        </div>
      </SfPanelContext.Provider>
    );
  } else {
    return <SfTabsContext.Consumer>
      {ctx => {
        ctx.push(itemKey,
          <Tabs.TabPane tab={tab} key={itemKey} className={className} style={sls}>
            <SfPanelContext.Provider value={panelContext}>
              {showTitle && <div className="tab-title">{title}</div>}
              <div className="tab-body">
                <Row>
                  {persistentInfo(table, panelContext.name)}
                  {children}
                </Row>
              </div>
            </SfPanelContext.Provider>
          </Tabs.TabPane>
        );
        return false;
      }}
    </SfTabsContext.Consumer>
  }
}

const SfTabsPaneSetting = () => {
  return (
    <>
      <Form.Item name="tab" label="tab">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="showTitle" label="show title" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
    </>
  );
}

SfTabsPane.craft = {
  displayName: "Tab Pane",
  related: {
    settings: SfTabsPaneSetting
  }
}

registerComponent({
  key:"SfTabsPane",
  component: SfTabsPane,
  runtimeComponent: SfpTabsPane,
  template: <SfTabsPane itemKey={getId('tabspane')} className="sf-tabspane"
            title={"Tabs Pane"} tab={"Tab"} />,
  title: <IntlMessages id="system.form.library.tabspane" text="Tabs Pane" />,
  icon: <FolderAddOutlined  className="react-icons icon-antd"/>,
  type: "Container",
});

export default SfTabsPane;