import { Element, useEditor, useNode } from "@craftjs/core";
import { Col, Switch, Row, Pagination } from "antd";
import { Form, Select } from "components/Form";
import { dataExplorer, dataPolicyApi } from "parse-api";
import { useEffect, useRef, useState } from "react";
import IntlMessages from "util/IntlMessages";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting, syncPageState, updatePageState, getElements } from "./common";
import { log } from "util/algorithm";
import { MdGridView } from "react-icons/md";
import { SpDataGridContext } from ".";
import { settingsSignal } from "../../../../util/signal";

const ROW_JUSTIFY = ["start", "end", "center", "space-around", "space-between"];
const ROW_ALIGN = ["top", "middle", "bottom"];

const SpDataGridSetting = () => {
  const [policyOptions, setPolicyOptions] = useState([]);
  const mounted = useRef();
  useEffect(()=>{
    mounted.current = true;
    const fetchData = async () => {
      const params = [{key:"dataTypes", operator:"=", value:"DataGrid"}];
      const list = await dataExplorer.searchAll("SystemDataPolicy", params, "dataName");
      if (mounted.current) setPolicyOptions(list.map(p=>({value:p.dataKey,label:p.dataName})));
    }
    fetchData();
    return ()=>{
      mounted.current = false;
    }
  },[])
  return (
    <>
      <Form.Item name="policy" label="policy" >
        <Select className="item-property" options={policyOptions} allowClear/>
      </Form.Item>
      <Form.Item name="preview" label="preview" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name={["justify"]} label={"row justify"}>
        <Select className="item-property" allowClear>
          {ROW_JUSTIFY.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["align"]} label={"vertical align"}>
        <Select className="item-property" allowClear>
          {ROW_ALIGN.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  );
}

export const SpDataGrid = ({...props}) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppDataGrid doRef={ref => connect(drag(ref))} selectedStyle={style} dbtn={dbtn} {...props}/>
  )
}

export const SppDataGrid = ({
  doRef, dbtn, selectedStyle, pageKey, itemKey, pageState, setPageState,
  policy, preview, justify, align, children,
  ...otherProps }) => {
  const { locale } = settingsSignal;
  const [dataPolicyParams, setDataPolicyParams] = useState(null);
  const [myValue, setMyValue] = useState([]);
  const [page, setPage] = useState({current:1, pageSize: 10});
  useEffect(() => {
    syncPageState({itemKey, myValue, setMyValue, pageState, setPageState})
  },[itemKey, myValue, pageState, setPageState])

  if (!preview) preview = false;
  const mounted = useRef();
  useEffect(()=>{
    mounted.current = true;
    return ()=>{
      mounted.current = false;
    }
  },[])
  const fetchData = async () => {
    if (policy && dataPolicyParams) {
      try {
        const data = await dataPolicyApi.getDataPolicyData(preview, policy, "DataGrid", locale, dataPolicyParams);
        log("data", data);
        if (mounted.current) {
          updatePageState({itemKey, myValue: data, setMyValue, pageState, setPageState})
        }
      } catch (e) {
        log("get data failed", e);
        if (mounted.current) {
          updatePageState({itemKey, myValue: [], setMyValue, pageState, setPageState})
        }
      }
    } else {
      if (mounted.current) {
        updatePageState({itemKey, myValue: [], setMyValue, pageState, setPageState})
      }
    }
  }
  useEffect(()=>{
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dataPolicyParams, locale, policy, preview])
  useEffect(() => {
    if (pageState) {
      const params = pageState[itemKey + "_params" ];
      if (params) {
        setDataPolicyParams(params);
      }
    }
  },[itemKey, pageState])

  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}
  if (doRef) {
    return (
      <Col ref={doRef} {...props}>
        <Row justify={justify} align={align} >
          {children}
        </Row>
        {dbtn}
      </Col>
    );
  } else {
    const startIdx = page.current > 1 ? (page.current - 1) * page.pageSize : 0;
    const endIdx = startIdx + page.pageSize > myValue.length ? myValue.length : startIdx + page.pageSize;
    return (
      <>
        {myValue.slice(startIdx, endIdx).map((dataItem, index) => {
          const myPageState = dataItem;
          const setMyPageState = (state) => {
            const myNewValue = [...myValue];
            myNewValue[index] = state;
            updatePageState({itemKey, myValue: myNewValue, setMyValue, pageState, setPageState})
          }
          return (<Col key={dataItem.key} ref={doRef} {...props}>
            <Row justify={justify} align={align} >
              <SpDataGridContext.Provider value={{ pageState: myPageState, setPageState: setMyPageState }}>
                {children}
              </SpDataGridContext.Provider>
            </Row>
          </Col>)
        })}
        {myValue.length > page.pageSize && <Col span={24} className={"sp-datagrid-page-ctrl"}>
          <Pagination current={page.current} pageSize={page.pageSize} total={myValue.length} onChange={(current, pageSize) => setPage({current, pageSize})}/>
        </Col>}
      </>
    )
  }
}
SppDataGrid.enablePageState = true;

SpDataGrid.craft = {
  displayName: "Data Grid",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      const REJECTED_LIST = getElements("Container");
      if (incomingNode.data.name === "SpPanel") {
        return true;
      } else if (REJECTED_LIST.indexOf(incomingNode.data.name) !== -1) {
        return false;
      } else {
        return true;
      }
    }
  },
  related: {
    settings: SpDataGridSetting
  }
}

registerComponent({
  key:"SpDataGrid",
  component: SpDataGrid,
  runtimeComponent: SppDataGrid,
  template: <Element canvas is={SpDataGrid} itemKey={getId('datagrid')} preview={true}
            className="sp-panel" span="24"/>,
  title: <IntlMessages id="system.page.library.table" text="Data Grid" />,
  icon: <MdGridView  className="react-icons icon-antd"/>,
  type: "Container",
  sequence: 6,
});

export default SpDataGrid;