import { useNode } from "@craftjs/core";
import { Form, InputNumber, Row, Switch } from "antd";
import { useEffect, useRef } from "react";
import { applyCss, isEmptyString } from "../../../../util/algorithm";
import { defaultCss } from "../defaultPage";
import { NodeCollector, registerComponent, SpStyleSetting } from "./common";

export const SpCanvas = ({ ...props }) => {
  const { connectors: { connect, drag } } = useNode(NodeCollector);
  return (
    <SppCanvas doRef={ref => connect(drag(ref))} {...props} />
  )
}

export const SppCanvas = ({
  doRef, pageKey, itemKey, css, cssOverride, style,
  keepPageState, hideVersion, showLoading,
  ...otherProps }) => {
  const ref = useRef();
  useEffect(() => {
    return () => {
      applyCss(defaultCss, itemKey, ref);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  useEffect(() => {
    if (isEmptyString(css)) {
      applyCss(defaultCss, itemKey, ref);
    } else {
      if (cssOverride) {
        applyCss(css, itemKey, ref);
      } else {
        applyCss(defaultCss+" "+css, itemKey, ref);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[css, cssOverride]);
  return (
    <Row ref={doRef || ref} {...otherProps} style={doRef ? style : null} >
    </Row>
  )
}

const SpCanvasSetting = () => {
  return (
    <>
      <Form.Item name="keepPageState" label="keep state" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="hideVersion" label="hide version" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="showLoading" label="show loading (sec)">
        <InputNumber className="item-property"  min={1} />
      </Form.Item>
      <SpStyleSetting />
    </>
  );
}

SpCanvas.craft = {
  displayName: "Canvas",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return true;
    }
  },
  related: {
    settings: SpCanvasSetting
  }
}

SpCanvas.validate = (props, {extraParams, pageData}) => {
  props.itemKey = pageData.pageKey;
  extraParams.keepPageState = props.keepPageState;
  extraParams.hideVersion = props.hideVersion;
  extraParams.showLoading = props.showLoading;
}

registerComponent({
  key: "SpCanvas",
  component: SpCanvas,
  runtimeComponent: SppCanvas,
  type: "Container",
});

export default SpCanvas;