import { getRequestContext } from "../parse-api/config";
import { authSignal } from "./signal";

const { getUserLocale } = require("../lngProvider");
const { system, auth, dataPolicyApi } = require("../parse-api");
const { equals, flattenJSON, getPropsMapping, updateFlattenJSON, isEmpty, uid, shortHash, encodeBase64, decodeBase64, delay, compare, cloneJson, isNumeric, isDate, setupSplitter, applyCss, getLocalStorageItemObject, setLocalStorageItemObject, waitForCondition, toAuthUserObj, displayError } = require("./algorithm");
const { exportToExcelTabs, importFromXLSX } = require("./export");

class StateProxy {
  constructor(state, doSetValues) {
      this.state = state;
      this.stateDelta = {};
      this.stateDeltaUpdated = false;
      this.markUpdated = this.markUpdated.bind(this);
      this.sendUpdates = this.sendUpdates.bind(this);
      this.stateProxyHandler = {
      set: this.set.bind(this)
      };
      this.stateProxy = new Proxy(this.state, this.stateProxyHandler);
      this.doSetValues = doSetValues;
  }

  markUpdated(prop) {
      this.stateDelta[prop] = this.stateProxy[prop];
      this.stateDeltaUpdated = true;
  }

  set(obj, prop, value) {
      const oldValue = obj[prop];
      obj[prop] = value;
      if (!equals(oldValue, value)) {
      this.stateDelta[prop] = value;
      this.stateDeltaUpdated = true;
      }
      return true;
  }

  sendUpdates() {
      if (this.stateDeltaUpdated) {
      this.doSetValues({ data: this.stateDelta });
      this.stateDelta = {};
      this.stateDeltaUpdated = false;
      }
  }
}


export class FrontendApi {

    constructor(doSetValues, setIsModified) {
      this.doSetValues = doSetValues;
      this.setIsModified = setIsModified;
      this.equals = equals.bind(this);
      this.flattenJSON = flattenJSON.bind(this);
      this.getPropsMapping = getPropsMapping.bind(this);
      this.updateFlattenJSON = updateFlattenJSON.bind(this);
      this.isEmpty = isEmpty.bind(this);
      this.uid = uid.bind(this);
      this.shortHash = shortHash.bind(this);
      this.encodeBase64 = encodeBase64.bind(this);
      this.decodeBase64 = decodeBase64.bind(this);
      this.delay = delay.bind(this);
      this.compare = compare.bind(this);
      this.cloneJson = cloneJson.bind(this);
      this.isNumeric = isNumeric.bind(this);
      this.isDate = isDate.bind(this);
      this.setupSplitter = setupSplitter.bind(this);
      this.applyCss = applyCss.bind(this);
      this.exportToExcelTabs = exportToExcelTabs.bind(this);
      this.importFromXLSX = importFromXLSX.bind(this);
      this.displayError = displayError.bind(this);

      const { authUser } = authSignal;
      this.authUserObj = toAuthUserObj(authUser);
      this.username = this.authUserObj.username;
      this.requestContext = getRequestContext();
    }

    getStateProxy(state) {
      return new StateProxy(state, this.doSetValues);
    }

    async subscribe(subject, handle) {
      return await system.subscribe(subject, handle);
    }

    async unsubscribe(subject, key) {
      return await system.unsubscribe(subject, key);
    }

    async apiNotify(subject, isJob, params) {
      const rtnVal = await system.apiNotify(subject, isJob, params);
      return rtnVal;
    }

    async generalNotify(key, {title, message, link, scope}) {
      const subject = `GeneralNotification_${this.username}`
      const isJob = true;
      const windowIdentity = this.requestContext?.windowIdentity;
      const installationId = this.requestContext?.installationId;
      const params = {
        key, title, message, link, scope,
        windowIdentity, installationId
      }
      console.log("generalNotify()", {subject, isJob, params})
      const rtnVal = await system.apiNotify(subject, isJob, params);
      return rtnVal;
    }

    async generalAlert(key, {type, message, link, scope, duration, destroy}) {
      const subject = `GeneralAlert_${this.username}`
      const isJob = true;
      const windowIdentity = this.requestContext?.windowIdentity;
      const installationId = this.requestContext?.installationId;
      const params = {
        key, type, message, link, scope, duration, destroy,
        windowIdentity, installationId
      }
      console.log("generalAlert()", {subject, isJob, params})
      if (scope === 'window') {
        params.windowIdentity = 'local';
        const id = uid();
        await system.trigger({subject, id, params})
      } else {
        const rtnVal = await system.apiNotify(subject, isJob, params);
        return rtnVal;
      }
    }

    async createUser(username, nickname, email, password, captcha) {
      return await auth.createUserWithEmailAndPassword(username, nickname, email, password, captcha);
    }

    async signIn(username, password) {
      return await auth.signInWithEmailAndPassword(username, password);
    }

    async signOut(notReportError, noRedirect) {
      return await auth.signOut(notReportError, noRedirect);
    }

    async executeDataPolicy(dataKey, params, isPreview = false, nocache = false) {
      const dataType = 'API';
      const locale = getUserLocale();
      return await dataPolicyApi.getDataPolicyData(
        isPreview,
        dataKey,
        dataType,
        locale,
        params,
        nocache
      );
    }

    setRequestContext(context) {
      let rc = getLocalStorageItemObject('REQUEST_CONTEXT', {});
      if (context) {
        rc = {...rc, ...context}
      }
      setLocalStorageItemObject('REQUEST_CONTEXT', rc);
    }

    async waitForCondition(condition, params) {
      await waitForCondition(condition, params);
    }

  }