import { LineOutlined } from "@ant-design/icons";
import { useEditor, useNode } from "@craftjs/core";
import { Divider, Switch } from "antd";
import classNames from 'classnames';
import { Form, Input, Select } from "components/Form";
import IntlMessages from 'util/IntlMessages';
import { convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent } from "./common";

const DIVIDER_ORIENTATION = [
  "center", "left", "right",
]

const DIVIDER_TYPE = [
  "vertical", "horizontal",
]

const SpDividerSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="orientation" label="orientation">
        <Select className="item-property" placeholder="orientation" allowClear showSearch>
          {DIVIDER_ORIENTATION.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name="type" label="type">
        <Select className="item-property" placeholder="orientation" allowClear showSearch>
          {DIVIDER_TYPE.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name="plain" label="plain" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="dashed" label="dashed" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
    </>
  )
}

export const SpDivider = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppDivider doRef={ref => connect(drag(ref))} dbtn={dbtn} {...props} style={style} />
  )
}

export const SppDivider = ({
  doRef, style, dbtn, dashed, plain, orientation, type, index,
  pageKey, itemKey, className, styleStr, title,
  disabled, ...otherProps }) => {
  const prefixCls = "ant-divider";
  type = type ? type : "horizontal";
  orientation = orientation ? orientation : "center"
  const orientationPrefix = orientation && orientation.length > 0 ? `-${orientation}` : orientation;
  const hasChildren = !!title;
  const classString = classNames(
    prefixCls,
    `${prefixCls}-${type}`,
    {
      [`${prefixCls}-with-text`]: hasChildren,
      [`${prefixCls}-with-text${orientationPrefix}`]: hasChildren,
      [`${prefixCls}-dashed`]: !!dashed,
      [`${prefixCls}-plain`]: !!plain,
    },
    className,
  );
  let sls = convertStyleStr(styleStr);
  if (style) sls = sls ? { ...sls, ...style } : style;
  const text = title ? <IntlMessages id={`page.${pageKey}.divider.${itemKey}`} text={title} /> : null;
  if (doRef) {
    const height = "18px";
    const width = type === "vertical" ? "18px" : null;
    sls = { ...sls, height: height, width: width }
    return (
      <div ref={doRef} style={sls} className={classString}
      >{text} {dbtn}</div>
    );
  } else {
    if (text) {
      return (
        <Divider style={sls} className={className}
          type={type} dashed={dashed} orientation={orientation} plain={plain}
        >{text}</Divider>
      );
    } else {
      return (
        <Divider style={sls} className={className}
          type={type} dashed={dashed} orientation={orientation} plain={plain}
        ></Divider>
      );
    }
  }
}

SpDivider.craft = {
  displayName: "Divider",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpDividerSetting
  }
}

registerComponent({
  key:"SpDivider",
  component: SpDivider,
  runtimeComponent: SppDivider,
  template: <SpDivider itemKey={getId('divider')} className="sp-divider" />,
  title: <IntlMessages id="system.page.library.divider" text="Divider" />,
  icon: <LineOutlined className="react-icons icon-antd"/>,
  type: "Component",
  sequence: 6,
});

export default SpDivider;