import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Select, Switch, Transfer } from "antd";
import { Form, FormContext, Input } from "components/Form";
import { selectApi } from "parse-api";
import React, { useEffect, useRef, useState } from "react";
import { BsArrowLeftRight } from "react-icons/bs";
import { getArrayElementByAttribute } from "util/algorithm";
import IntlMessages from "util/IntlMessages";
import { AccountStore } from "../../../../constants/Account";
import { equals } from "../../../../util/algorithm";
import {
  colSls, convertRules, convertSelectOptionLabel, convertStyleStr, deleteButton, EditorCollector, getId,
  getParentName,
  NodeCollector, registerComponent, SelectCollector, SfLayoutSetting, SfPanelContext, shouldUpdate
} from "./common";
import { SelectRenderer } from "./SfSelect";
import { commonSignal, settingsSignal } from "../../../../util/signal";

const SfTransferSetting = () => {
  const { localSelects, mainSelects } = useEditor(SelectCollector);
  const [systemSelectList, setSystemSelectList] = useState([]);
  const [selects, setSelects] = useState([]);
  const [selected, setSelected] = useState({});
  const [filter, setFilter] = useState([]);
  const selectedKey = useRef();
  const mounted = useRef();

  useEffect(() => {
    mounted.current = true;
    const fetchData = async () => {
      const list = await selectApi.loadSystemSelect();
      setSystemSelectList(list);
      // convert to option
      const options = list.map(l => {
        return {
          value: l.selectKey,
          label: l.name,
        }
      })
      if (mounted.current) setSelects(options);
    }
    fetchData();
    return () => {
      mounted.current = false;
    }
  }, [])

  const onSelectKeyChange = (value) => {
    refreshOptionTypes();
    selectedKey.current = value;
  }

  const refreshOptionTypes = () => {
    if (selectedKey.current && systemSelectList?.length > 0) {
      if (selected && selected?.selectKey !== selectedKey.current) {
        const selected = getArrayElementByAttribute(systemSelectList, "selectKey", selectedKey.current);
        setSelected(selected);
        if (selected && selected.optionTypes) {
          if (mounted.current) setFilter(selected.optionTypes.map(f => ({label:f, value:f})));
        } else {
          if (mounted.current) setFilter([]);
        }
      }
    }
  }

  const SelectKey = ({value, onChange, ...props}) => {
    useEffect(()=> {
      onSelectKeyChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[value])
    return <Select {...props} value={value} onChange={onChange} />
  }

  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="selectkey" label="select">
        <SelectKey className="item-property" options={selects} allowClear showSearch />
      </Form.Item>
      <Form.Item name="filter" label="filter">
        <Select className="item-property" options={filter} allowClear/>
      </Form.Item>
      <Form.Item name="parentkeys" label="parents">
        <Select className="item-property" options={[...mainSelects,...localSelects]} mode="multiple" />
      </Form.Item>
      <Form.Item name="isUseCode" label="use code" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="sourceTitle" label="source">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="targetTitle" label="target">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="showSearch" label="show search" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="pageSize" label="page size">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="responsive" label="responsive" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="isSelectKey" label="select key" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="datasec" label="data sec." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="datasectype" label="datasec type">
        <Select className="item-property" allowClear>
          <Select.Option value="owners">owners</Select.Option>
          <Select.Option value="users">users</Select.Option>
          <Select.Option value="userRoles">userRoles</Select.Option>
        </Select>
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfTransfer = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpTransfer doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const SystemTransfer = ({ selectkey, filter,
  parentkeys, parentValues,
  isUseCode, pageSize,
  listStyleStr, ...otherProps }) => {
  const { locale } = settingsSignal;
  const [list, setList] = useState([]);
  const [options, setOptions] = useState([]);
  const mounted = useRef();
  const refreshOptionsRef = useRef();

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, []);

  useEffect(() => {
    const options = list.map(l => {
      return { key: l.value, title: l.label }
    })
    if (mounted.current) setOptions(options);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    refreshOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectkey, filter, parentkeys, parentValues]);

  const refreshOptions = () => {
    if (refreshOptionsRef.current) {
      clearTimeout(refreshOptionsRef.current);
      refreshOptionsRef.current = null;
    }
    refreshOptionsRef.current = setTimeout(() => {
      if (mounted.current) refreshOptionsImpl();
    }, AccountStore.ON_CHANGE_DELAY)
  }

  const refreshOptionsImpl = async () => {
    if (selectkey) {
      let list = [];
      if (parentkeys && parentkeys.length > 0) {
        const parentOptionKey = await selectApi.getParentOptionKey(locale, selectkey, isUseCode, parentValues);
        if (parentOptionKey) {
          list = await selectApi.fetchSystemSelectOption(locale, selectkey, isUseCode, parentOptionKey);
        }
      } else {
        list = await selectApi.fetchSystemSelectOption(locale, selectkey, isUseCode);
      }
      if (filter) list = list.filter(l => l.optionType === filter);
      if (mounted.current) setList(list);
    }
  }

  const filterOption = (inputValue, option) => option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  const pagination = pageSize ? {pageSize} : false;
  const sls = convertStyleStr(listStyleStr);

  return <MyTransfer
    dataSource={options}
    render={item => item.title}
    filterOption={filterOption}
    pagination={pagination}
    listStyle={sls}
    {...otherProps}
  />
}

export const MyTransfer = ({ targetKeys, onChange, dataSource, isUseCode, showSearch, responsive, ...otherProps }) => {
  const { width } = commonSignal;
  const [selectOption, setSelectOption] = useState([]);
  useEffect(() => {
    const options = (dataSource || []).map(l => {
        return <Select.Option key={l.key} value={l.key} >{convertSelectOptionLabel(l.title,isUseCode)}</Select.Option>
      })
    setSelectOption(options)
  }, [dataSource, isUseCode])
  let notFoundList = [];
  if (targetKeys && dataSource) {
    for (const element of targetKeys) {
      let found = false;
      for (const element2 of dataSource) {
        if (element2.key === element) {
          found = true;
          break;
        }
      }
      if (!found) {
        notFoundList.push(element);
      }
    }
  }
  if (notFoundList.length > 0) {
    notFoundList = notFoundList.map(v=>({key:v, title:v}));
    dataSource = [...dataSource, ...notFoundList];
  }
  if (responsive && width < AccountStore.MOBILE_BREAKPOINT) {
    return <Select className={otherProps.className}
      mode="multiple" showSearch={showSearch} showArrow allowClear
      value={targetKeys || []} onChange={onChange}>{selectOption}</Select>
  } else {
    return <Transfer
      targetKeys={targetKeys || []}
      onChange={onChange}
      dataSource={dataSource}
      showSearch={showSearch}
      {...otherProps}
    />
  }
}

export const SfpTransfer = ({
  doRef, form, condistyles, className, style, dbtn, hideInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, volitate, skipcopy,
  selectkey, filter, parentkeys, isUseCode, datasec, datasectype, sourceTitle, targetTitle, pageSize,
  inputref, styleStr, children, isSelectKey, ...otherProps }) => {
  const { locale } = settingsSignal;
  const [list, setList] = useState([]);
  const [options, setOptions] = useState([]);
  const [parentValues, setParentValues] = useState([]);
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const [fxr, setFxr] = useState({});
  const mounted = useRef();
  const newRules = convertRules(rules);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectkey, filter]);

  useEffect(() => {
    const options = list.map(l => {
      return { key: l.value, title: l.label }
    })
    if (mounted.current) setOptions(options);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const refreshOptions = async (form, dependencies, name) => {
    if (selectkey) {
      if (form && dependencies && dependencies.length > 0) {
        const pValues = dependencies.map(d => form.getFieldValue(d));
        const hasNull = pValues.some(v => v == null);
        let list = [];
        if (hasNull) {
          if (mounted.current) setParentValues(pValues);
          form.setFields([{ name: name, value: null }]);
        } else {
          if (!equals(pValues, parentValues)) {
            if (mounted.current) setParentValues(pValues);
            const parentOptionKey = await selectApi.getParentOptionKey(locale, selectkey, isUseCode, pValues);
            if (parentOptionKey) {
              list = await selectApi.fetchSystemSelectOption(locale, selectkey, isUseCode, parentOptionKey);
            }
            if (filter) list = list.filter(l => l.optionType === filter);
            if (mounted.current) setList(list);
          }
        }
      } else {
        let list = await selectApi.fetchSystemSelectOption(locale, selectkey, isUseCode, null);
        if (filter) list = list.filter(l => l.optionType === filter);
        if (mounted.current) setList(list);
      }
    }
  }

  const getValueFromEvent = (nextTargetKeys, direction, moveKeys) => {
    return nextTargetKeys;
  }

  const filterOption = (inputValue, option) => option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1

  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const parentname = getParentName(ctx);
        const dependencies = parentkeys ? parentkeys.map(p => {
          if (p.startsWith("main.")) {
            return [p.substr(5)];
          } else {
            return [...parentname, p];
          }
        }) : [];
        const doRefresh = (...args) => refreshOptions(form, dependencies, name, args);
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr, doRefresh});
        return (
          <FormContext.Consumer>
            {(formCtx) => {
              let sourceTitleLabel = sourceTitle;
              let targetTitleLabel = targetTitle;
              if (formCtx?.form) {
                sourceTitleLabel = <IntlMessages id={`form.${formCtx.form}.${itemKey}.source.title`} text={sourceTitle}/>;
                targetTitleLabel = <IntlMessages id={`form.${formCtx.form}.${itemKey}.target.title`} text={targetTitle}/>;
              }
              const pagination = pageSize ? {pageSize} : false;
              return (
                <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
                  <Form.Item name={name} label={title}
                    shouldUpdate={fx} hidden={fxr.hidden} rules={fxr.hidden || otherProps.disabled ? null : newRules}
                    labelAlign={labelAlign} labelCol={lcs} valuePropName="targetKeys"
                    getValueFromEvent={getValueFromEvent} wrap>
                    <MyTransfer
                      dataSource={options}
                      titles={[sourceTitleLabel, targetTitleLabel]}
                      render={item => item.title}
                      filterOption={filterOption}
                      pagination={pagination}
                      listStyle={sls}
                      {...otherProps}
                    />
                  </Form.Item>
                  {dbtn}
                </Col>
              )
            }}
          </FormContext.Consumer>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

SfpTransfer.render = ({ selectkey, isUseCode }) => (value, record, index) => {
  const props = {selectkey, isUseCode, value, record, index};
  return <SelectRenderer {...props}/>
}

SfTransfer.craft = {
  displayName: "Tramsfer",
  related: {
    settings: SfTransferSetting
  }
}

SfTransfer.validate = (props, { parents, container, extraParams }) => {
  const itemKey = props.itemKey;
  const selectkey = props.selectkey;
  if (!selectkey) {
    return <IntlMessages id="system.form.validate.select-key-missing" text="item ({itemKey}) - select is missing." values={{ itemKey: itemKey }} />
  }

  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "select",
      mode: "multiple",
      valueIndex: props.isUseCode ? "code" : "name",
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      sortable: true,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
      selectkey: props.selectkey,
      parentkeys: props.parentkeys,
      isUseCode: props.isUseCode,
      datasec: props.datasec,
      datasectype: props.datasectype,
    });
    if (props.isSelectKey) {
      extraParams.selectConfig.optionTypes.push(props.itemKey);
    }
  }
}

SfTransfer.isSearchable = true;
SfTransfer.isRulable = true;

registerComponent({
  key: "SfTransfer",
  component: SfTransfer,
  runtimeComponent: SfpTransfer,
  template: <SfTransfer itemKey={getId('transfer')} className="sf-tranfer wrap" styleStr="width:50%,height:200px"
    title={"Transfer"} span={24} labelColStr="span:6"
    isUseCode={true} showSearch={true}
    sourceTitle="Available" targetTitle="Assigned" />,
  title: <IntlMessages id="system.form.library.transfer" text="Transfer" />,
  icon: <BsArrowLeftRight  className="react-icons icon-bs"/>,
  type: "Component",
  sequence: 8,
});

export default SfTransfer;