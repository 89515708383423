import { Popover } from "antd";
import {
  userSignOut
} from "appRedux/actions/Auth";
import { push } from "connected-react-router";
import { system } from "parse-api";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import IntlMessages from "util/IntlMessages";
import UserAvatar from "../../components/Form/UserAvatar";
import { keycloakApi } from "../../parse-api/keycloak-sso";
import { getClientRoot, isValidUrl, prepareText, toAuthUserObj } from "../../util/algorithm";
import { useSignalValue } from "../../util/reactHook";
import { authSignal } from "../../util/signal";

const UserProfile = ({sidebarCollapsed}) => {
  const dispatch = useDispatch();
  const authUser = useSignalValue(authSignal, 'authUser');
  const authUserObj = toAuthUserObj(authUser);
  const [userProfileUrl, setUserProfileUrl] = useState();
  const mounted = useRef();

  useEffect(() => {
    mounted.current = true;
    const fetchData = async () => {
      const url = await system.getSystemParameter('USER_PROFILE_URL');
      if (url) {
        if (mounted.current) setUserProfileUrl(url);
      }
    }
    fetchData();
    return () => {
      mounted.current = false;
    }
  },[])

  const doOpenUserProfile = () => {
    if (userProfileUrl) {
      const client_root = getClientRoot();
      const keycloakParmas = keycloakApi.getKeycloakUrlParams();
      const urlParams = {username: authUserObj.username, client_root, ...keycloakParmas};
      const url = prepareText(userProfileUrl, urlParams);
      if (isValidUrl(url)) {
        window.open(url);
      } else {
        dispatch(push(url));
      }
    }
  }

  const userMenuOptions = authUserObj.username === 'guest' ?
    (
    <ul className="gx-user-popover">
      <li onClick={() => dispatch(userSignOut())}><IntlMessages id="app.userAuth.signIn"/></li>
    </ul>
    )
  : (
    <ul className="gx-user-popover">
      <li onClick={doOpenUserProfile}><IntlMessages id="app.userAuth.myAccount"/> ({authUserObj.nickname})</li>
      <li onClick={() => dispatch(userSignOut())}><IntlMessages id="app.userAuth.signOut"/></li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <UserAvatar userObj={authUserObj} className="gx-mr-3" noTooltip={true}/>
        <span className={"gx-avatar-name" + (sidebarCollapsed ? ' collapsed' : ' normal')} title={authUserObj.nickname}>{authUserObj.nickname}<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
      </Popover>
    </div>
  )
};

export default UserProfile;
