import { FolderOutlined } from "@ant-design/icons";
import { Element, useEditor, useNode } from "@craftjs/core";
import { Col, Switch, Tabs } from "antd";
import { Form, Input } from "components/Form";
import IntlMessages from "util/IntlMessages";
import { convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting, updatePageState } from "./common";
import { log } from "util/algorithm";
import { Select } from "../../../../components/Form";
import { appendAttributes, processForwardAttribute } from "../../../../util/algorithm";

export const SpTabs = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppTabs doRef={ref => connect(drag(ref))} dbtn={dbtn} {...props} selectedStyle={style} />
  )
}

export const SppTabs = ({
  doRef, dbtn, children,
  pageKey, itemKey ,pageState, setPageState, type, size, renderActiveOnly,
  defaultActiveKey, activeKey, childNodes, processChildNode, processNode,
  selectedStyle, ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}
  const onChange = (activeKey) => {
    const myValue = activeKey;
    updatePageState({itemKey: itemKey + '_activeKey', myValue, pageState, setPageState})
  }
  const tabProps = {itemKey, defaultActiveKey, activeKey, type, size, onChange}
  processForwardAttribute(tabProps, pageState, ['activeKey'])
  delete tabProps.itemKey;
  if (doRef) {
    return (
      <Col ref={doRef} {...props}>
        <div className="ant-card-head">
          <div className="ant-card-head-wrapper">
            <div className="ant-card-head-title">Tab Panel</div>
          </div>
        </div>
        <div className="ant-card-body craft-tabs-body">
          {children}
        </div>
        {dbtn}
      </Col>
    );
  } else {
    const _activeKey = pageState?.[itemKey + '_activeKey'] || defaultActiveKey;
    return (
      <Col ref={doRef} {...props}>
        <Tabs {...tabProps} >
          {childNodes && childNodes.map((n, index) => {
            const key = n.props.itemKey;
            let tsls = convertStyleStr(n.props.styleStr);
            appendAttributes(n.props, 'style', tsls);
            if (n.props.style?.display === 'none') return null;
            return (
              <Tabs.TabPane
                tab={<IntlMessages id={`page.${pageKey}.${key}.tab`}
                text={n.props.tab}/>} key={key} className={n.props.className}
                style={n.props.style} disabled={n.props.disabled}>
                {n.props.showTitle && <div className="tab-title">{n.props.title}</div>}
                <div className="tab-body">
                  {
                    (!renderActiveOnly || _activeKey === key)
                    && processChildNode(n)
                  }
                </div>
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </Col>
    );
  }
}
SppTabs.collector = () => ["SpTabsPane"];

const SpTabsSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="defaultActiveKey" label="active pane">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="type" label="type">
        <Select className="item-property" allowClear>
          <Select.Option value="line">line</Select.Option>
          <Select.Option value="card">card</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="size" label="size">
        <Select className="item-property" allowClear>
          <Select.Option value="small">small</Select.Option>
          <Select.Option value="middle">middle</Select.Option>
          <Select.Option value="large">large</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="renderActiveOnly" label="render active only" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  );
}

SppTabs.enablePageState = true;

const ACCEPTED_LIST = ["SpTabsPane"];
SpTabs.craft = {
  displayName: "Tabs",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      let rtnVal = false;
      rtnVal = (ACCEPTED_LIST.indexOf(incomingNode.data.name) !== -1);
      if (!rtnVal) log("tabs in", incomingNode)
      return rtnVal;
    }
  },
  related: {
    settings: SpTabsSetting
  }
}

SpTabs.validate = (props, {pageData}) => {
  props.pageKey = pageData.pageKey;
}

registerComponent({
  key:"SpTabs",
  component: SpTabs,
  runtimeComponent: SppTabs,
  template: <Element canvas is={SpTabs} itemKey={getId('tabs')} className="sp-tabs" title={"Tabs"} span={24}/>,
  title: <IntlMessages id="system.page.library.tabs" text="Tabs" />,
  icon: <FolderOutlined  className="react-icons icon-antd"/>,
  type: "Container",
});

export default SpTabs;