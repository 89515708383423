import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Switch, Input as AntInput } from "antd";
import { Form, Input } from "components/Form";
import MaskedInput from 'antd-mask-input'
import React, { useEffect, useRef, useState } from "react";
import { BsInputCursorText } from "react-icons/bs";
import IntlMessages from "util/IntlMessages";
import {
  generateTooltip,colSls, convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate, convertRules, updateTableCell
} from "./common";
import { AccountStore } from "../../../../constants/Account";

const SfInputSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="placeholder" label="placeholder">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="prefix" label="prefix">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="suffix" label="suffix">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="maxLength" label="max len">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="allowClear" label="allow clear" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="password" label="password" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="mask" label="mask">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="translate" label="translate" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tiptext" label="tooltip text" >
        <Input.TextArea
          className="item-property"
          autoSize allowClear
        />
      </Form.Item>
      <Form.Item name="tiplink" label="tooltip link" >
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="readOnly" label="read-only" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hidden" label="hidden" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="editInTable" label="edit col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="isSelectKey" label="select key" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfInput = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpInput doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const MyInput = ({value, noupdate, disabled, onChange, password, mask, triggerOnChange, ...props}) => {
  const ref = useRef();
  const [myValue, setMyValue] = useState(value);
  const [defaultMarkedValue, setDefaultMaskedValue] = useState(mask?.replace(/0a*/g, '_'));
  useEffect(() => {
    if (myValue !== value) {
      setMyValue(value);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[value])
  useEffect(() => {
    setDefaultMaskedValue(mask?.replace(/0a*/g, '_'));
  },[mask])
  const onBeforeChange = (event) => {
    if (event?.target?.value) {
      if (event?.target?.value === defaultMarkedValue) {
        if (onChange) onChange("");
      } else {
        if (onChange) onChange(event);
      }
    }
  }
  const onMyChangeRef = useRef();
  const onMyChange = (event) => {
    setMyValue(event.target.value);
    if (triggerOnChange && onChange) {
      onChange(event);
    } else if (onChange) {
      if (onMyChangeRef.current) {
        clearTimeout(onMyChangeRef.current);
      }
      onMyChangeRef.current = setTimeout(() => {
        onChange(event);
      }, AccountStore.ON_CHANGE_DELAY);
    }
  }
  if (ref.current === undefined) ref.current = noupdate && value !== undefined;
  const validmask = mask?.match(/^[^0a*]*$/) ? null : mask;
  if (password) {
    return <Input.Password value={myValue} disabled={disabled || ref.current}
      onChange={onMyChange} {...props}/>
  } else if (validmask) {
    return (
      <MaskedInput mask={mask} disabled={disabled || ref.current}
        onBlur={onBeforeChange} onChange={onMyChange} value={myValue}
        autoComplete="off" {...props} />
    );
  } else {
    return <Input value={myValue} disabled={disabled || ref.current}
      onChange={onMyChange} {...props}/>
  }
}

export const SfpInput = ({
  doRef, form, condistyles, className, style, dbtn, hidden, hideInTable, editInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, translate, volitate, skipcopy,
  inputref, styleStr, children, isSelectKey,tipicon,tiptext,tiplink, ...otherProps }) => {
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const [fxr, setFxr] = useState({});
  const newRules = convertRules(rules);
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name} label={title} tooltip={generateTooltip(tiptext,tiplink)} placeholder={otherProps.placeholder}
               shouldUpdate={fx} hidden={fxr.hidden || (!doRef && hidden)} rules={fxr.hidden || hidden || otherProps.disabled ? null : newRules}
              labelAlign={labelAlign} labelCol={lcs} wrap>
              <MyInput style={sls} disabled={fxr.disabled || otherProps.disabled} {...otherProps} />
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}
const InputRenderer = ({value, record, index, form, tableKey, onRowChange, editInTable, itemKey, mask, tableColWidth, disabled, placeholder}) => {
  const [editing, setEditing] = useState(false);
  const [myValue, setMyValue] = useState(value);
  const leditInTable = editInTable && !disabled;
  const ref = useRef();
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, [])
  useEffect(() => {
    setMyValue(value);
  }, [value]);
  const onClick = (event) => {
    setEditing(true);
    event.preventDefault();
    event.stopPropagation();
  }
  if (editing) {
    const onChange = (event) => {
      try {
        setMyValue(event.target.value);
      } catch (e) {
        console.error(e);
      }
    }
    const onBlur = (event) => {
      updateTableCell({form, tableKey, record, itemKey, myValue, onRowChange, setEditing, event, index});
    }
    const onKeyDown = (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        ref.current.blur();
      }
    }
    const className = "row-editing table-cell-input"
    const autoFocus = true;
    const props = {value: myValue, mask, className, onBlur, onChange, ref, onKeyDown, autoFocus, placeholder};
    if (tableColWidth) props.style = {"minWidth": `${tableColWidth}px`,"maxWidth": `${tableColWidth}px`}
    const validmask = mask?.match(/^[^0a*]*$/) ? null : mask;
    if (validmask) {
      return <MaskedInput {...props} autoComplete="off"/>
    } else {
      return <AntInput {...props} />
    }
  } else {
    const editingProps = leditInTable ? {className: "row-editable table-cell-input", onClick} : {};
    if (tableColWidth) editingProps.style = {"minWidth": `${tableColWidth}px`, "maxWidth": `${tableColWidth}px`}
    return <span {...editingProps}>{myValue}</span>;
  }
}

SfpInput.render = ({ editInTable, itemKey, mask, tableColWidth, disabled, placeholder }, form, tableKey, onRowChange) => (value, record, index) => {
  const props = {value, record, index, form, tableKey, onRowChange, editInTable, itemKey, mask, tableColWidth, disabled, placeholder};
  return <InputRenderer {...props}/>
}

SfInput.craft = {
  displayName: "Input",
  related: {
    settings: SfInputSetting
  }
}

SfInput.validate = (props, {parents, container, extraParams}) => {
  const itemKey = props.itemKey;
  const title = props.title;
  const placeholder = props.placeholder;
  if (props.mask) {
    if (props.mask.match(/^[^0a*]*$/)) {
      return <IntlMessages id="system.form.validate.invalid-input-mask" text="item ({itemKey}) - input mask is invalid." values={{itemKey:itemKey}}/>
    }
  }
  if (container.type.resolvedName !== "SfMainPanel") {
    if (props.translate) {
      return <IntlMessages id="system.form.validate.non-main-panel-translate" text="item ({itemKey}) - input translate is only allowed in main panel." values={{itemKey:itemKey}}/>
    }
  }
  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "string",
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      sortable: true,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
      translate: props.translate,
      tipicon:props.tipicon,
      tiptext:props.tiptext,
      tiplink:props.tiplink
    });
    if (props.isSelectKey) {
      extraParams.selectConfig.optionTypes.push(props.itemKey);
    }
    if (props.translate) {
      extraParams.translate.enabled = true;
      extraParams.translate.items.push({
        key:itemKey,
        type: 'input',
        label: placeholder || title,
        index: Object.keys(parents).indexOf(props.itemKey)
      })
    }
  }
}

SfInput.isSearchable = true;
SfInput.isRulable = true;

registerComponent({
  key:"SfInput",
  component: SfInput,
  runtimeComponent: SfpInput,
  template: <SfInput itemKey={getId('input')} className="sf-input wrap"
            title={"Input"} span={24} labelColStr="span:6" />,
  title: <IntlMessages id="system.form.library.input" text="Input" />,
  icon: <BsInputCursorText  className="react-icons icon-bs"/>,
  type: "Component",
  sequence: 1,
});

export default SfInput;