import { Element, useEditor, useNode } from "@craftjs/core";
import { Col, Empty, Switch } from "antd";
import { useEffect, useRef, useState } from "react";
import { RiPagesLine } from "react-icons/ri";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "../../../../components/CircularProgress";
import { Form, Select } from "../../../../components/Form";
import JsonTextArea from "../../../../components/Form/JsonTextArea";
import { AccountStore } from "../../../../constants/Account";
import { pageApi } from "../../../../parse-api";
import { compare, getPageIdentity, isEmpty, tryAssignJson, tryParseJson } from "../../../../util/algorithm";
import PageRuntimeImpl from "../runtimeimpl";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting, syncPageState, updatePageState } from "./common";

export const SpPage = ({...props}) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppPage doRef={ref => connect(drag(ref))} selectedStyle={style} dbtn={dbtn} {...props}/>
  )
}

export const SppPage = ({
  doRef, dbtn, selectedStyle, disabled,
  itemKey, pageKey, pageState, setPageState, parentkeys, config,
  hideVersion, manualRefresh, onClosePage, onCancelPage, initPageState, isPreview,
  ...otherProps }) => {
  const [pathInfo, setPathInfo] = useState({
    path: null,
    state: {},
    history: []
  });
  const [loading, setLoading] = useState(false);
  const [parentValues, setParentValues] = useState([]);
  const mounted = useRef();
  useEffect(()=>{
    mounted.current = true;
    return ()=>{
      mounted.current = false;
    }
  }, [])
  const [myValue, setMyValue] = useState(initPageState);

  useEffect(() => {
    syncPageState({itemKey, myValue, setMyValue, pageState, setPageState})
    if (pageState && parentkeys) {
      const parentValues = parentkeys.map(key => pageState[key]);
      setParentValues(parentValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[itemKey, myValue, pageState, setPageState])

  const onMyChange = (myValue) => {
    setTimeout(() => {
      updatePageState({itemKey, myValue, setMyValue, pageState, setPageState})
    }, AccountStore.ON_CHANGE_DELAY)
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      if (pageKey) {
        const pg = isPreview ? 'preview' : 'pg';
        const p = `/system/prt/${pg}/${pageKey}`;
        setPathInfo({...pathInfo, path: p});
      } else {
        setPathInfo({...pathInfo, path: null});
      }
      setLoading(false);
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageKey, isPreview])

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, AccountStore.ON_CHANGE_DELAY)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathInfo])

  const dispatchReplace = (path, state) => {
    setPathInfo((prev) => ({...prev, path, state: state || {}}));
  }

  const dispatchGoBack = () => {
    if (pathInfo.history.length > 0) {
      setPathInfo((prev) => {
        const path = prev.history[prev.history.length - 1]
        const history = prev.history.slice(0, prev.history.length - 1)
        return {...prev, history, path, state: {}}
      });
    }
  }

  const dispatchPush = (path, state) => {
    setPathInfo((prev) => {
      const history = [...prev.history, prev.path];
      return {path, history, state: state || {}}
    });
  }

  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}

  if (loading) {
    return (
      <Col ref={doRef} {...props}>
        <CircularProgress/>
        {dbtn}
      </Col>
    );
  } else if (!isEmpty(pathInfo.path) && pageKey) {
    const identity = getPageIdentity(pathInfo.path)
    let myConfig = tryParseJson(config);
    if (!myConfig) myConfig = {}
    myConfig.itemKey = itemKey;
    return (
      <Col ref={doRef} {...props}>
        <PageRuntimeImpl
          pageKey={identity?.pageKey}
          type={identity?.type}
          hideVersion={hideVersion}
          manualRefresh={manualRefresh}
          dispatchReplace={dispatchReplace}
          dispatchGoBack={dispatchGoBack}
          dispatchPush={dispatchPush}
          onClosePage={onClosePage}
          onCancelPage={onCancelPage}
          onPageDataChange={onMyChange}
          initPageState={myValue || initPageState}
          pathInfo={pathInfo}
          parentkeys={parentkeys}
          parentValues={parentValues}
          disabled={disabled}
          config={myConfig}
          designer={!!doRef}
        />
        {dbtn}
      </Col>
    );
  } else {
    return (
      <Col ref={doRef} {...props}>
        <Empty />
        {dbtn}
      </Col>
    );
  }
}

const SpPageSetting = () => {
  const [allPages, setAllPages] = useState([]);
  const [pageOptions, setPageOptions] = useState([]);
  const form = Form.useFormInstance();
  const pageKey = Form.useWatch('pageKey', form);
  const config = Form.useWatch('config', form);
  const itemKey = Form.useWatch('itemKey', form);
  const [myPageKey, setMyPageKey] = useState()
  const [myItemKey, setMyItemKey] = useState()

  const mounted = useRef();
  const optionsSorter = (a, b) => compare(a.label, b.label)
  useEffect(()=>{
    mounted.current = true;
    const fetchData = async () => {
      if (mounted.current) setAllPages(await pageApi.loadSystemPage());
    }
    fetchData();
    return ()=>{
      mounted.current = false;
    }
  },[])

  useEffect(() => {
      if (mounted.current) setPageOptions(allPages.map(p => ({value: p.pageKey, label: p.pageKey})).sort(optionsSorter));
  }, [allPages])


  useEffect(() => {
    if (pageKey && (myPageKey !== pageKey || myItemKey !== itemKey)) {
      for (const p of allPages) {
        if (p.pageKey === pageKey && p.extraParams.configData) {
          const myConfig = tryAssignJson(p.extraParams.configData, config);
          myConfig.itemKey = itemKey;
          const value = JSON.stringify(myConfig, null, 2);
          form.setFields([
            { name: ["config"], value }
          ]);
          setMyPageKey(pageKey);
          setMyItemKey(itemKey);
          break;
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageKey, itemKey, allPages])

  return (
    <>
      <Form.Item name="pageKey" label="page" >
        <Select className="item-property" placeholder="page" allowClear showSearch
          options={pageOptions} />
      </Form.Item>
      <Form.Item name="isPreview" label="preview" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="config" label="config" className="config"
        dependencies={['pageKey']}
        labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} >
        <JsonTextArea/>
      </Form.Item>
      <Form.Item name="hideVersion" label="hide ver." valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="manualRefresh" label="manual ref." valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="parentkeys" label="parents">
        <Select className="item-property" mode="tags" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  );
}
SppPage.enablePageState = true;

SpPage.craft = {
  displayName: "Page",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpPageSetting
  }
}

registerComponent({
  key:"SpPage",
  component: SpPage,
  runtimeComponent: SppPage,
  template: <Element canvas is={SpPage} itemKey={getId('page')}
            className="sp-page" span="24"/>,
  title: <IntlMessages id="system.page.library.page" text="Page" />,
  icon: <RiPagesLine  className="react-icons icon-ri"/>,
  type: "Component",
  sequence: 10,
});

export default SpPage;