import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Menu } from "antd";
import { Form, Input } from "components/Form";
import React, { useEffect, useRef, useState } from "react";
import { MdOutlineMenu } from "react-icons/md";
import IntlMessages from "util/IntlMessages";
import { Select } from "../../../../components/Form";
import { IconOnly, IconSelect, prepareTitleElement } from "../../../../components/Form/IconSelect";
import { AccountStore } from "../../../../constants/Account";
import { equals, tryParseJson } from "../../../../util/algorithm";
import { JsonObjectEditor } from "../../FormSetup/components";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting, syncPageState, updatePageState } from "./common";

const MODES = ['vertical', 'horizontal', 'inline']
const THEMES = ['light', 'dark']

const SpMenuSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="defaultValue" label="default value" className="defaultValue"
        labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} >
        <Input.TextArea
          className="item-property"
          autoSize allowClear
        />
      </Form.Item>
      <Form.Item name="inlineIndent" label="indent">
        <InputNumber className="item-property" />
      </Form.Item>
      <Form.Item name="subMenuCloseDelay" label="close delay">
        <InputNumber className="item-property" />
      </Form.Item>
      <Form.Item name="subMenuOpenDelay" label="open delay">
        <InputNumber className="item-property" />
      </Form.Item>
      <Form.Item name="expandIconKey" label="expand icon">
        <IconSelect iconTypes={['ANTD']}/>
      </Form.Item>
      <Form.Item name="mode" label="mode">
        <Select className="item-property" placeholder="orientation" allowClear showSearch>
          {MODES.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name="theme" label="theme">
        <Select className="item-property" placeholder="orientation" allowClear showSearch>
          {THEMES.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  )
}

const defaultItems = [
  {
    label: "Navigation One",
    key: "mail",
    icon: "MailOutlined",
  },
  {
    label: "Navigation Two",
    key: "app",
    icon: "AppstoreOutlined",
    disabled: true,
  },
  {
    label: "Navigation Three - Submenu",
    key: "SubMenu",
    icon: "SettingOutlined",
    children: [
      {
        type: "group",
        label: "Item 1",
        children: [
          {
            label: "Option 1",
            key: "setting:1",
          },
          {
            label: "Option 2",
            key: "setting:2",
          },
        ],
      },
      {
        type: "group",
        label: "Item 2",
        children: [
          {
            label: "Option 3",
            key: "setting:3",
          },
          {
            label: "Option 4",
            key: "setting:4",
          },
        ],
      },
    ],
  },
  {
    label: "Navigation Four - Link",
    key: "alipay",
  },
];

const defaultMyValue = {items: [...defaultItems], selectedKeys: [], expandedKeys: []};
const defaultMyValueStr = JSON.stringify(defaultMyValue);

const prepareItems = (items) => {
  return JSON.parse(JSON.stringify(items), (key, value) => {
    if (value?.icon && value?.label) {
      value.label = prepareTitleElement(value.label, value.icon);
      value.icon = undefined;
      return value;
    } else {
      return value;
    }
  })
}

export const MyMenu = ({
    id, value, disabled,
    debug, expandIconKey,
    onChange, ...props}) => {
  const [oldValue, setOldValue] = useState({...defaultMyValue});
  const [myValue, setMyValue] = useState({...defaultMyValue});
  const [items, setItems] = useState(defaultItems);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const mounted = useRef();
  const rootRef = useRef();

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, [])

  useEffect(() => {
    if (value && !equals(value, oldValue)) {
      if (mounted.current) {
        setOldValue({...value})
        setItems(value?.items || []);
        setExpandedKeys(value?.expandedKeys || []);
        setSelectedKeys(value?.selectedKeys || []);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[value])

  useEffect(() => {
    setMyValue((v) => {
      return {...v, selectedKeys, expandedKeys}
    })
  },[selectedKeys, expandedKeys])

  const timerRef = useRef();
  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      if (onChange) {
        const json = JSON.parse(JSON.stringify(myValue || {}));
        if (!equals(json, value)) {
          setOldValue(json);
          onChange(json);
        }
      }
    }, AccountStore.ON_CHANGE_DELAY);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myValue])

  const onSelect = ({ item, key, keyPath, selectedKeys, domEvent }) => {
    setSelectedKeys(selectedKeys);
  }

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
  }

  const onClick = ({ item, key, keyPath, domEvent }) => {
    console.log('onClick', { item, key, keyPath, domEvent })
  }

  const onValueChange = (text) => {
    if (onChange) {
      onChange(text);
    }
  }

  return (
    <div ref={rootRef} className={`menu-root menu-root-${id}`}>
      <Menu
        items={prepareItems(items)}
        expandIcon={expandIconKey ? <IconOnly icon={expandIconKey}/> : undefined}
        onSelect={onSelect} selectedKeys={selectedKeys}
        onOpenChange={onExpand} openKeys={expandedKeys}
        onClick={onClick}
        {...props}>
      </Menu>
      {debug && <JsonObjectEditor value={value} onChange={onValueChange} />}
    </div>
  )
}

export const SpMenu = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppMenu doRef={ref => connect(drag(ref))} dbtn={dbtn} {...props} selectedStyle={style} />
  )
}

export const SppMenu = ({
  doRef, dbtn, selectedStyle,
  pageKey, itemKey, pageState, setPageState, value, defaultValue,
  inlineIndent, subMenuCloseDelay, subMenuOpenDelay, expandIconKey, mode, theme,
  debug, hidden, ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  if (!doRef && hidden) style.display = 'none';
  const props = {...otherProps, style}
  const inputProps = {inlineIndent, subMenuCloseDelay, subMenuOpenDelay, expandIconKey, mode, theme, debug};
  const [myValue, setMyValue] = useState();
  const timer = useRef()

  useEffect(() => {
    if (typeof value === 'undefined') {
      updatePageState({itemKey, myValue: tryParseJson(defaultValue), setMyValue, pageState, setPageState})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    syncPageState({itemKey, myValue, setMyValue, pageState, setPageState})
  },[itemKey, myValue, pageState, setPageState])

  const onMyChange = (myValue) => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      updatePageState({itemKey, myValue, setMyValue, pageState, setPageState})
    }, AccountStore.ON_CHANGE_DELAY);
  }

  return (
    <Col ref={doRef} {...props}>
      <MyMenu id={itemKey} value={myValue} onChange={onMyChange} {...inputProps} />
      {dbtn}
    </Col>
  );
}
SppMenu.enablePageState = true;

SpMenu.craft = {
  displayName: "Menu",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpMenuSetting
  }
}

registerComponent({
  key: "SpMenu",
  component: SpMenu,
  runtimeComponent: SppMenu,
  template: <SpMenu itemKey={getId('menu')} className="sp-menu menu" title={"Menu"} span={24} row={6} defaultValue={defaultMyValueStr}/>,
  title: <IntlMessages id="system.page.library.menu" text="Menu" />,
  icon: <MdOutlineMenu className="react-icons icon-md"/>,
  type: "Component",
  sequence: 12,
});

export default SpMenu;