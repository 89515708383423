import { getUserLocale } from '../lngProvider';
import { notify, registerPostMessageAction } from '../util/algorithm';
import { CloudRunWithCache } from './config';

const getDataPolicyData = async (isPreview, dataKey, dataType, locale, params, nocache) => {
  try {
    const param = {
      locale: locale,
      dataKey: dataKey,
      dataType: dataType,
      isPreview: isPreview,
      params: params,
    };
    const id = await CloudRunWithCache("getDataPolicyData", param, nocache);
    return id;
  } catch (error) {
    console.error("get system form failed", error);
    throw error;
  }
}

const dataPolicyApi = {
  getDataPolicyData,
}

registerPostMessageAction('getDataPolicyData', 'getDataPolicyData', async (data) => {
  try {
    const dataKey = data?.dataKey;
    const params = data?.params || {};
    const locale = getUserLocale();
    const dataType = "API";
    const isPreview = !!data?.isPreview;
    if (dataKey) {
      const result = await getDataPolicyData(isPreview, dataKey, dataType, locale, params);
      notify('getDataPolicyDataCallback', result, 'success');
    } else {
      notify('getDataPolicyDataCallback', null, 'error', `dataKey is missing`);
    }
  } catch (error) {
    notify('getDataPolicyDataCallback', null, 'error', `${error}`);
  }
})

export default dataPolicyApi;