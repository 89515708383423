import dataExplorer from './data-explorer';

const loadSystemFormatter = async () => {
  try {
    return await dataExplorer.searchAll("SystemFormatter", null, "name");
  } catch (error) {
    console.error("load system formatter failed", error);
    return null;
  }
};

const getSystemFormatter = async (formatterKey) => {
  try {
    return await dataExplorer.getByKey("SystemFormatter", formatterKey);
  } catch (error) {
    console.error("get system formatter failed", error);
    return null;
  }
};

const saveSystemFormatter = async (formatterKey, formatter) => {
  try {
    return await dataExplorer.saveByKey("SystemFormatter", formatterKey, formatter, false, true);
  } catch (error) {
    console.error("save system formatter failed", error);
    return null;
  }
};

const deleteSystemFormatter = async (formatter) => {
  try {
    return await dataExplorer.destory("SystemFormatter", formatter);
  } catch (error) {
    console.error("delete system formatter failed", error);
  }
};

const formatterApi = {
  loadSystemFormatter,
  getSystemFormatter,
  saveSystemFormatter,
  deleteSystemFormatter,
}

export default formatterApi;