import zhHK from 'antd/lib/locale-provider/zh_HK';
import zhHKMessages from "../locales/zh-Hans-HK.json";

const ZhLan = {
  messages: {
    ...zhHKMessages
  },
  antd: zhHK,
  locale: 'zh-Hans-HK',
};
export default ZhLan;
