import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Switch } from "antd";
import { Form, Input } from "components/Form";
import "easymde/dist/easymde.min.css";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BsMarkdown } from "react-icons/bs";
import ReactMarkdown from 'react-markdown';
import SimpleMDE from "react-simplemde-editor";
import remarkGfm from 'remark-gfm';
import IntlMessages from "util/IntlMessages";
import {
  colSls, convertRules, convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate
} from "./common";
import { equals, isEmpty } from "util/algorithm";

const SfMarkdownSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="minHeight" label="min-height">
        <InputNumber min="100" max="1000" className="item-property"/>
      </Form.Item>
      <Form.Item name="readOnly" label="read-only" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="staticContent" label="content">
        <Input.TextArea className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfMarkdown = ({ ...props }) => {
  const { actions: {setProp}, connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  const onChange = (value) => {
    setProp(props => {
      props.staticContent = value;
    });
  }
  return (
    <SfpMarkdown doRef={ref => connect(drag(ref))} onChange={onChange} style={style} dbtn={dbtn} {...props} />
  )
}

export const MyMarkdown = ({ id, noupdate, editorRef, value, onChange, readOnly, minHeight, staticContent }) => {
  const [, setMdeInstance] = useState(null);
  const [, setCodemirrorInstance] = useState(null);
  const [myValue, setMyValue] = useState("");

  const ref = useRef();
  const tgr = useRef();
  if (ref.current === undefined) ref.current = noupdate && value !== undefined;

  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  },[])

  useEffect(() => {
    if (!isEmpty(value)) {
      if (!equals(myValue, value)) {
        if (mounted.current) setMyValue(value);
      }
    } else if (!isEmpty(staticContent)){
      if (!equals(myValue, staticContent)) {
        if (mounted.current) setMyValue(staticContent);
        if (onChange) onChange(staticContent);
      }
    } else {
      if (mounted.current) setMyValue("");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, staticContent]);

  const onMyChange = (e) => {
    setMyValue(e);
    if (tgr.current) {
      clearTimeout(tgr.current);
    }
    tgr.current = setTimeout(() => {
      if (onChange) onChange(e);
    }, 500);
  }

  const onBlur = () => {
    if (onChange) onChange(myValue);
  }

  const getMdeInstanceCallback = useCallback((simpleMde) => {
    if (mounted.current) setMdeInstance(simpleMde);
    if (editorRef) editorRef.current = {
      focus: async ({cursor}) => {
        if(simpleMde?.codemirror) {
          const cm = simpleMde.codemirror;
          cm.focus();
          if (cursor === "end") {
            await new Promise(r => setTimeout(r, 100));
            cm.setCursor({line: 10000, ch: 1});
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCmInstanceCallback = useCallback((editor) => {
    if (mounted.current) setCodemirrorInstance(editor);
  }, []);

  const options = useMemo(() => {
    return {
      minHeight: minHeight+"px",
      autofocus: false,
      spellChecker: false,
    };
  }, [minHeight]);

  if (readOnly || ref.current) {
    return <div id={id}><ReactMarkdown className="editor-preview" children={isEmpty(value) ? staticContent : value} remarkPlugins={[remarkGfm]} /></div>
  } else {
    return <div id={id}><SimpleMDE value={value} onBlur={onBlur} onChange={onMyChange} options={options}
      getCodemirrorInstance={getCmInstanceCallback}
      getMdeInstance={getMdeInstanceCallback}/></div>
  }
}

export const SfpMarkdown = ({
  doRef, form, condistyles, className, style, dbtn, hideInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, volitate, skipcopy,
  inputref, styleStr, children, ...otherProps }) => {
  const lcs = convertStyleStr(labelColStr);
  const [fxr, setFxr] = useState({});
  const newRules = convertRules(rules);
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name} label={title}
              shouldUpdate={fx} hidden={fxr.hidden} rules={fxr.hidden || otherProps.disabled ? null : newRules}
              labelAlign={labelAlign} labelCol={lcs} wrap>
              <MyMarkdown {...otherProps}/>
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

SfMarkdown.craft = {
  displayName: "Markdown",
  related: {
    settings: SfMarkdownSetting
  }
}

SfMarkdown.validate = (props, {parents, container, extraParams}) => {
  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "string",
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      sortable: true,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
    });
  }
}

SfMarkdown.isSearchable = true;
SfMarkdown.isRulable = true;

registerComponent({
  key: "SfMarkdown",
  component: SfMarkdown,
  runtimeComponent: SfpMarkdown,
  template: <SfMarkdown itemKey={getId('markdown')} className="sf-markdown wrap"
    title={"Markdown Editor"} span={24} labelColStr="span:6" hideInTable={true} styleStr="" minHeight="200" />,
  title: <IntlMessages id="system.form.library.markdown" text="Markdown Editor" />,
  icon: <BsMarkdown className="react-icons icon-bs"/>,
  type: "Component",
  sequence: 15,
});

export default SfMarkdown;