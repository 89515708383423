import { useNode } from "@craftjs/core";
import { Row } from "antd";
import React from "react";
import { convertStyleStr, getElements, NodeCollector, persistentInfo, registerComponent, SfPanelContext, SfPanelListContext } from "./common";

export const RepeatedArea = ({...props}) => {
  const { connectors: { connect, drag }, style } = useNode(NodeCollector);
  return (
    <SfpRepeatedArea doRef={ref => connect(drag(ref))} style={style} {...props}/>
  )
}

export const SfpRepeatedArea = ({ ...props }) => {
  if (props.doRef) {
    return (
      <SfPanelContext.Provider value={{ name: [props.itemKey] }}>
        <SfpRepeatedAreaInner name={[]} {...props} />
      </SfPanelContext.Provider>
    )
  } else {
    return (
      <SfPanelListContext.Consumer>
        {ctx => {
          const { itemKey, fields, min, max, count, remove, table } = ctx;
          return (
            <>
              {fields.map((field, index) => (
                <SfPanelContext.Provider key={field.key} value={{ itemKey, name: [field.name], removeRow: () => remove(field.name), index: index, min, max, count }}>
                  <SfpRepeatedAreaInner table={table} name={[field.name]} {...props} />
                </SfPanelContext.Provider>
              ))}
            </>
          )
        }}
      </SfPanelListContext.Consumer>
    )
  }
}

export const SfpRepeatedAreaInner = ({ 
  doRef, style, dbtn, itemKey, noStyle, children, styleStr, className, table, name, ...otherProps }) => {
  if (noStyle) {
    return (
      <div ref={doRef}>
        {children}
      </div>
    )
  } else {
    let sls = convertStyleStr(styleStr);
    let cls = "craft-form-container";
    if (className) cls = cls + " " + className;
    if (style) sls = sls ? {...sls, ...style} : style;
    return (
      <Row ref={doRef} {...otherProps} className={cls} style={sls}>
        {persistentInfo(table, name)}
        {children}
      </Row>
    )
  }
}

const LOCKED_LIST = ["SfButton"];
RepeatedArea.craft = {
  displayName: "Repeated Area",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      const REJECTED_LIST = getElements("Container");
      let rtnVal = false;
      rtnVal = (REJECTED_LIST.indexOf(incomingNode.data.name) === -1) &&
               (LOCKED_LIST.indexOf(incomingNode.data.name) === -1);
      return rtnVal;
    },
    canMoveOut: function(outgoingNode, currentNode) {
      let rtnVal = false;
      rtnVal = (LOCKED_LIST.indexOf(outgoingNode.data.name) === -1);
      return rtnVal;
    },
  }
}

registerComponent({
  key:"RepeatedArea", 
  component: RepeatedArea, 
  runtimeComponent: SfpRepeatedArea,
  type: "Container",
});

export default RepeatedArea;