import { cloneJson, getArrayElementByAttribute, log } from "../util/algorithm";
import { CloudRunWithCache, idb_partialUpdate, prepareIdbCacheKey } from "./config";

const getAllDataClasses = async () => {
  try {
    return await CloudRunWithCache("dataExplorer_getAllDataClasses", null);
  } catch (error) {
    console.error("get all data class failed", error);
    throw error;
  }
};

const getDefaultDataClasses = async () => {
  try {
    return await CloudRunWithCache("dataExplorer_getDefaultDataClasses", null);
  } catch (error) {
    console.error("get all data class failed", error);
    throw error;
  }
};

const getDataClasses = async () => {
  try {
    return await CloudRunWithCache("dataExplorer_getDataClasses", null);
  } catch (error) {
    console.error("get all data class failed", error);
    throw error;
  }
};

const getDataClass = async (dataClass) => {
  try {
    if (!dataClass) return null
    const param = {dataClass};
    return await CloudRunWithCache("dataExplorer_getDataClass", param);
  } catch (error) {
    console.error("get all data class failed", error);
    throw error;
  }
};

const list = async (dataClass, parentDataClass, parentId, parentRelation) => {
  try {
    const param = {dataClass, parentDataClass, parentId, parentRelation};
    return await CloudRunWithCache("dataExplorer_list", param);
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
};

const searchAll = async (dataClass, params, sort, parentDataClass, parentId, parentRelation, flowParent) => {
  try {
    params = params ? JSON.parse(JSON.stringify(params)) : params;
    const param = {dataClass, params, sort, parentDataClass, parentId, parentRelation, flowParent};
    return await CloudRunWithCache("dataExplorer_searchAllData", param, true);
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
};

const getSearchActionPolicies = async (dataClass, actionType) => {
  try {
    const param = {dataClass, actionType};
    return await CloudRunWithCache("dataExplorer_getSearchActionPolicies", param, true);
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
};


const search = async ({dataClass, params, sort, pagination, parentDataClass, parentId, parentRelation, isAdmin, flowParent, locale, selectedColumns}) => {
  try {
    params = params ? JSON.parse(JSON.stringify(params)) : params;
    const param = {dataClass, params, sort, pagination, parentDataClass, parentId, parentRelation, isAdmin, flowParent, locale, selectedColumns};
    return await CloudRunWithCache("dataExplorer_searchData", param, true);
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
};

const get = async (dataClass, objectId) => {
  try {
    const param = {dataClass, objectId};
    return await CloudRunWithCache("dataExplorer_getData", param, true);
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
};

const getAccessKey = async (dataClass, objectId, dataKey, key) => {
  try {
    const param = {dataClass, objectId, dataKey, key};
    return await CloudRunWithCache("dataExplorer_getAccessKey", param, true);
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
};

const getByKey = async (dataClass, key, nocache = false) => {
  try {
    const param = {dataClass, key};
    return await CloudRunWithCache("dataExplorer_getDataByKey", param, nocache);
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
};

const getAllByKey = async (dataClass, key) => {
  try {
    const param = {dataClass, key};
    return await CloudRunWithCache("dataExplorer_getAllDataByKey", param, true);
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
};

const getActiveByKey = async (dataClass, key, isPreview, currentVersionStamp) => {
  try {
    const param = {dataClass, key, isPreview, currentVersionStamp};
    const nocache = !!isPreview;
    return await CloudRunWithCache("dataExplorer_getActiveByKey", param, nocache);
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
};

const addRelation = async (dataClass, objectId, relation, ids) => {
  try {
    const param = {dataClass, objectId, relation, ids};
    return await CloudRunWithCache("dataExplorer_addRelation", param, true);
  } catch (error) {
    console.error("add relation failed", error);
    throw error;
  }
};

const removeRelation = async (dataClass, objectId, relation, ids) => {
  try {
    const param = {dataClass, objectId, relation, ids};
    return await CloudRunWithCache("dataExplorer_removeRelation", param, true);
  } catch (error) {
    console.error("add relation failed", error);
    throw error;
  }
};

const save = async (dataClass, data) => {
  try {
    const param = {dataClass, data};
    return await CloudRunWithCache("dataExplorer_saveData", param, true);
  } catch (error) {
    console.error("add data failed", error);
    throw error;
  }
};

const saveAll = async (dataClass, data, ignoreError, testOnly) => {
  try {
    const param = {dataClass, data, ignoreError, testOnly};
    return await CloudRunWithCache("dataExplorer_saveAllData", param, true);
  } catch (error) {
    console.error("add data failed", error);
    throw error;
  }
};

const saveByKey = async (dataClass, key, data, isNew, noVersionoCheck) => {
  try {
    if (noVersionoCheck) {
      const oldData = await getByKey(dataClass, key, true);
      if (oldData?.versionStamp) {
        data.versionStamp = oldData.versionStamp;
      }
    }
    const param = {dataClass, key, data, isNew};
    return await CloudRunWithCache("dataExplorer_saveDataByKey", param, true);
  } catch (error) {
    console.error("add data failed", error);
    throw error;
  }
};

const publish = async (dataClass, objectId) => {
  try {
    const param = {dataClass, objectId};
    return await CloudRunWithCache("dataExplorer_publishData", param, true);
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
};

const republish = async (dataClass, objectId) => {
  try {
    const param = {dataClass, objectId};
    return await CloudRunWithCache("dataExplorer_republishData", param, true);
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
};

const unpublish = async (dataClass, objectId) => {
  try {
    const param = {dataClass, objectId};
    return await CloudRunWithCache("dataExplorer_unpublishData", param, true);
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
};

const destory = async (dataClass, data) => {
  try {
    const param = {dataClass, data};
    return await CloudRunWithCache("dataExplorer_deleteData", param, true);
  } catch (error) {
    console.error("add data failed", error);
    throw error;
  }
};

const saveSearch = async (dataClass, name, autoSearch, openSearchPanel, params) => {
  try {
    const param = {dataClass, name, autoSearch, openSearchPanel, params};
    return await CloudRunWithCache("dataExplorer_saveSearch", param, true);
  } catch (error) {
    console.error("save search failed", error);
    throw error;
  }
}

const deleteSearch = async (dataClass, name) => {
  try {
    const param = {dataClass, name};
    return await CloudRunWithCache("dataExplorer_deleteSearch", param, true);
  } catch (error) {
    console.error("save search failed", error);
    throw error;
  }
}

const getAllSearch = async (dataClass, nocache) => {
  try {
    const param = {dataClass};
    return await CloudRunWithCache("dataExplorer_getAllSearch", param, nocache);
  } catch (error) {
    console.error("get search failed", error);
    throw error;
  }
}

const publishSharedSearch = async (dataClass) => {
  try {
    const param = {dataClass};
    return await CloudRunWithCache("dataExplorer_publishSharedSearch", param, true);
  } catch (error) {
    console.error("publish search failed", error);
    throw error;
  }
}

const saveSearchResult = async (dataClass, name, value) => {
  try {
    const param = {dataClass, name, value};
    return await CloudRunWithCache("dataExplorer_saveSearchResult", param, true);
  } catch (error) {
    console.error("save search failed", error);
    throw error;
  }
}

const deleteSearchResult = async (dataClass, name) => {
  try {
    const param = {dataClass, name};
    return await CloudRunWithCache("dataExplorer_deleteSearchResult", param, true);
  } catch (error) {
    console.error("save search failed", error);
    throw error;
  }
}

const getAllSearchResult = async (dataClass, nocache) => {
  try {
    const param = {dataClass};
    return await CloudRunWithCache("dataExplorer_getAllSearchResult", param, nocache);
  } catch (error) {
    console.error("get search failed", error);
    throw error;
  }
}

const publishSharedSearchResult = async (dataClass) => {
  try {
    const param = {dataClass};
    return await CloudRunWithCache("dataExplorer_publishSharedSearchResult", param, true);
  } catch (error) {
    console.error("publish search failed", error);
    throw error;
  }
}

const getFileAccessKey = async (fileKey) => {
  try {
    const param = {fileKey};
    return await CloudRunWithCache("dataExplorer_getFileAccessKey", param, true);
  } catch (error) {
    console.error("get file access key failed", error);
    throw error;
  }
}

const updateSystemFileSharing = async (fileKey, permission) => {
  try {
    const param = {fileKey, permission};
    return await CloudRunWithCache("dataExplorer_updateSystemFileSharing", param, true);
  } catch (error) {
    console.error("update file sharing failed", error);
    throw error;
  }
}

export const EMPTY_SETTINGS = {}

export const DEFAULT_SETTINGS = {
    "dataUpload": true,
    "dataDownload": true,
    "keepLastSearch": true,
    "userSettings": false,
    "index": {},
    "hidden": {},
    "hiddenInMobile": {},
    "width": {},
    "nosort": {},
    "nosearch": {},
    "defaultAscending": {},
    "defaultDescending": {"updatedAt":true},
    "sort": "updatedAt",
    "descending": true,
  }

const deduceSearchTableSettings = async (dataClass, isPreview, columns) => {
  const list = await dataExplorer.getAllSearchResult(dataClass, false);
  const systemSettings = getArrayElementByAttribute(list, "name", "systemSettings");
  const userSettings = getArrayElementByAttribute(list, "name", "userSettings");
  let newSettings = {...DEFAULT_SETTINGS};
  if (columns) {
    for (const element of columns) {
      const row = {...element};
      newSettings.width[row.dataIndex] = row.width;
      if (row.hiddenForReadOnly) {
        newSettings.hidden[row.dataIndex] = true;
      }
    }
  }
  let defaultSettings = DEFAULT_SETTINGS;
  if (isPreview) {
    defaultSettings = cloneJson(newSettings);
    defaultSettings.isReset = true;
  }
  if (systemSettings) {
    try {
      newSettings = JSON.parse(systemSettings.value);
    } catch (error) {
      log("parse json error", systemSettings);
    }
  }
  if (!isPreview && systemSettings) {
    defaultSettings = cloneJson(newSettings);
    defaultSettings.isReset = true;
  }
  if (!isPreview && userSettings) {
    try {
      newSettings = JSON.parse(userSettings.value);
    } catch (error) {
      log("parse json error", userSettings);
    }
  }
  return {defaultSettings, newSettings}
}

const updateLocalSearchResult = async (dataClass, record) => {
  try {
    const f = "dataExplorer_getAllSearchResult";
    const param = {dataClass};
    const key = prepareIdbCacheKey(f, param);
    return await idb_partialUpdate(key, record, 'name')
  } catch (error) {
    console.error("get search failed", error);
    throw error;
  }
}

const updateSearchTableSettings = async (dataClass, isPreview, newSettings) => {
  const json = JSON.stringify(newSettings)
  const name = isPreview ? "systemSettings" : "userSettings";
  await dataExplorer.saveSearchResult(dataClass, name, json);
  await dataExplorer.getAllSearchResult(dataClass, true);
}

const dataExplorer = {
  getAllDataClasses, getDefaultDataClasses, getDataClasses, getDataClass, list, searchAll, search,
  get, getAccessKey, getByKey, getAllByKey, getActiveByKey, addRelation, removeRelation,
  save, saveAll, saveByKey, publish, republish, unpublish, destory, saveSearch, getAllSearch,
  deleteSearch, publishSharedSearch, saveSearchResult, getAllSearchResult, deleteSearchResult,
  publishSharedSearchResult, getFileAccessKey, updateSystemFileSharing, deduceSearchTableSettings,
  updateSearchTableSettings, updateLocalSearchResult, getSearchActionPolicies,
}

export default dataExplorer;
