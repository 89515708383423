import React, { useState, useEffect } from "react";
import { Input as AntInput } from "antd";
import { injectIntl } from "react-intl";
import { getMessage } from "lngProvider";
import { settingsSignal } from "../../util/signal";

const Component = ({ intl: { formatMessage }, placeholder, inputref, value, onChange, ...otherProps }) => {
  const { locale } = settingsSignal;
  const [myValue, setMyValue] = useState("");
  useEffect(() => {
    setMyValue(value);
  },[value])
  const myOnChange = (e) => {
    setMyValue(e.target.value);
    if (onChange) onChange(e);
  }
  if (placeholder) {
    const message = getMessage(locale.languageId, placeholder);
    if (message) {
      return (<AntInput ref={inputref} placeholder={formatMessage({ id: placeholder })} value={myValue} onChange={myOnChange} {...otherProps} />)
    } else {
      return (<AntInput ref={inputref} placeholder={placeholder} value={myValue} onChange={myOnChange} {...otherProps} />)
    }
  } else {
    return (<AntInput ref={inputref} value={myValue} onChange={myOnChange} {...otherProps} />)
  }
}
const Input = injectIntl(Component);
Input.TextArea = AntInput.TextArea;
Input.Password = AntInput.Password;
export default Input;