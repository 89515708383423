import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Switch } from "antd";
import { Form, Input, Select } from "components/Form";
import React, {useEffect, useState, useRef} from "react";
import { CgSelectR } from "react-icons/cg";
import IntlMessages from "util/IntlMessages";
import { SystemSelect } from "../../FormSetup/components/SfSelect";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting, syncPageState, updatePageState } from "./common";
import { getArrayElementByAttribute, log } from "util/algorithm";
import { selectApi } from "parse-api";
import { getText } from "../../../../util/algorithm";
import { AccountStore } from "../../../../constants/Account";

const SpSelectSetting = () => {
  const [systemSelectList, setSystemSelectList] = useState([]);
  const [selects, setSelects] = useState([]);
  const [selected, setSelected] = useState({});
  const [filter, setFilter] = useState([]);
  const mounted = useRef();
  const selectedKey = useRef();
  useEffect(() => {
    mounted.current = true;
    const fetchData = async () => {
      const list = await selectApi.loadSystemSelect();
      setSystemSelectList(list);
      // convert to option
      const options = list.map(l => {
        return {
          value: l.selectKey,
          label: l.name,
        }
      })
      if (mounted.current) setSelects(options);
    }
    fetchData();
    return () => {
      mounted.current = false;
    }
  }, [])

  const onSelectKeyChange = (value) => {
    refreshOptionTypes();
    selectedKey.current = value;
  }

  const refreshOptionTypes = () => {
    if (selectedKey.current && systemSelectList?.length > 0) {
      if (selected && selected?.selectKey !== selectedKey.current) {
        log("refreshOptionTypes", selected?.selectKey, selectedKey.current);
        const sel = getArrayElementByAttribute(systemSelectList, "selectKey", selectedKey.current);
        setSelected(sel);
        if (sel && sel.optionTypes) {
          if (mounted.current) setFilter(sel.optionTypes.map(f => ({label:f, value:f})));
        } else {
          if (mounted.current) setFilter([]);
        }
      }
    }
  }

  const SelectKey = ({value, onChange, ...props}) => {
    useEffect(()=> {
      onSelectKeyChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[value])
    return <Select {...props} value={value} onChange={onChange} />
  }

  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="defaultValue" label="default value">
        <Input className="item-property"/>
      </Form.Item>
      <Form.Item name="placeholder" label="placeholder">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="selectkey" label="select">
        <SelectKey className="item-property" options={selects} onChange={onSelectKeyChange} allowClear showSearch filterOption={dfo}/>
      </Form.Item>
      <Form.Item name="filter" label="filter">
        <Select className="item-property" options={filter} allowClear/>
      </Form.Item>
      <Form.Item name="parentkeys" label="parents">
        <Select className="item-property" mode="tags" />
      </Form.Item>
      <Form.Item name="mode" label="mode">
        <Select className="item-property" allowClear>
          <Select.Option value="multiple">multiple</Select.Option>
          <Select.Option value="tags">tags</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="size" label="size">
        <Select className="item-property">
          <Select.Option value="large">large</Select.Option>
          <Select.Option value="middle">middle</Select.Option>
          <Select.Option value="small">small</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="maxTagCount" label="max tag">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="isUseCode" label="use code" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="isShowIcon" label="show icon" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="showSearch" label="show search" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="allowClear" label="allow clear" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="showArrow" label="show arrow" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="bordered" label="bordered" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="dropdownMatchSelectWidth" label="match width" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="fieldWidth" label="field-width">
        <Input className="item-property" />
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  )
}

const dfo = (input, option) => {
  return getText(option).toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

export const SpSelect = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppSelect doRef={ref => connect(drag(ref))} dbtn={dbtn} {...props} selectedStyle={style} />
  )
}

export const SppSelect = ({
  doRef, dbtn, selectedStyle,
  pageKey, itemKey, pageState, setPageState, value,  defaultValue,
  fieldWidth, selectkey, filter, parentkeys, placeholder, mode, size,
  maxTagCount, isUseCode, isShowIcon, showSearch, allowClear, showArrow,
  bordered, dropdownMatchSelectWidth, disabled, onAddItem, firePageEvent,
  ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}
  const inputProps = {selectkey, filter, parentkeys, placeholder, mode, size, maxTagCount, isUseCode, isShowIcon, showSearch, allowClear, showArrow, bordered, dropdownMatchSelectWidth, disabled, onAddItem};
  const [myValue, setMyValue] = useState(defaultValue);
  const [parentValues, setParentValues] = useState([]);
  const timer = useRef();

  useEffect(() => {
    syncPageState({itemKey, myValue, setMyValue, pageState, setPageState})
    if (pageState && parentkeys) {
      const parentValues = parentkeys.map(key => pageState[key]);
      setParentValues(parentValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[itemKey, myValue, pageState, setPageState])

  const onMyChange = (myValue) => {
    if (!myValue) myValue = null;
    if (firePageEvent) {
      firePageEvent(pageState, itemKey, myValue);
    } else {
      if (timer.current) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        updatePageState({itemKey, myValue, setMyValue, pageState, setPageState})
      }, AccountStore.ON_CHANGE_DELAY);
    }
  }
  const fieldStyle = {};
  if (fieldWidth) fieldStyle.width = fieldWidth;
  return (
    <Col ref={doRef} {...props}>
      <SystemSelect style={fieldStyle} value={myValue} onChange={onMyChange} parentValues={parentValues} {...inputProps} />
      {dbtn}
    </Col>
  );
}
SppSelect.enablePageState = true;
SppSelect.enablePageEvent = true;

SpSelect.craft = {
  displayName: "Select",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpSelectSetting
  }
}

registerComponent({
  key: "SpSelect",
  component: SpSelect,
  runtimeComponent: SppSelect,
  template: <SpSelect itemKey={getId('select')} className="sp-select" title={"Select"} span={24} />,
  title: <IntlMessages id="system.page.library.select" text="Select" />,
  icon: <CgSelectR  className="react-icons icon-cg"/>,
  type: "Component",
  subtype: "input",
  sequence: 5,
});

export default SpSelect;