import moment from 'moment';
import { decompressJsonData, isDate, parseMoment } from '../util/algorithm';
import { CloudRunWithCache } from './config';
import dataExplorer from './data-explorer';

const loadSystemForm = async () => {
  try {
    return await dataExplorer.searchAll("SystemForm", null, "formName");
  } catch (error) {
    console.error("get system form failed", error);
    throw error;
  }
};

const setFormAction = (p, policies) => {
  const selectedActions = p?.extraParams?.selectedActions ?  p?.extraParams?.selectedActions : null;
  const actions = []
  if (selectedActions) {
    for (const element of selectedActions) {
      for (const po of policies) {
        if (element.actionKey === po.actionKey) {
          element.data = po.data;
          element.versionStamp = po.versionStamp;
        }
      }
    }
  }
  return actions;
}

const setFormValidationRules = (p, policies) => {
  const reviver = (k, v) => {
    if (k === 'rules' && Array.isArray(v)) {
      for (const rule of v) {
        if (rule.actions) {
          rule.validationRules = [];
          for (const a of rule.actions) {
            for (const p of policies) {
              if (a === p.actionKey) {
                rule.validationRules.push(p);
              }
            }
          }
        }
      }
    }
    return v;
  }
  if (p.data) {
    let json = p.data;
    let nodeMap;
    if (json && typeof json === 'string') {
      try {
        json = decompressJsonData(json);
        nodeMap = JSON.parse(json, reviver);
      } catch (e) {
        nodeMap = JSON.parse(json, reviver);
      }
    } else {
      nodeMap = json;
    }
    p.data = JSON.stringify(nodeMap);
  }
}

const getSystemForm = async (isPreview, formKey) => {
  try {
    const formData = await dataExplorer.getActiveByKey("SystemForm", formKey, isPreview);
    if (formData) {
      const policies = await getSystemFormActionPolicies(isPreview, formKey);
      setFormAction(formData, policies);
      setFormValidationRules(formData, policies);
    }
    return formData;
  } catch (error) {
    console.error("get system form failed", error);
    throw error;
  }
};

const getSystemFormVersions = async (formKey) => {
  try {
    return await dataExplorer.getAllByKey("SystemForm", formKey);
  } catch (error) {
    console.error("get system form failed", error);
    throw error;
  }
};

const saveSystemForm = async (formKey, form, isNew) => {
  try {
    return await dataExplorer.saveByKey("SystemForm", formKey, form, isNew);
  } catch (error) {
    console.error("get system form failed", error);
    throw error;
  }
};

const deleteSystemForm = async (form) => {
  try {
    return await dataExplorer.destory("SystemForm", form);
  } catch (error) {
    console.error("get system form failed", error);
    throw error;
  }
};

const getSystemFormIdWithUniqueKey = async (isPreview, formKey, key, params) => {
  try {
    const param = {
      formkey: formKey,
      isPreview: isPreview,
      key: key,
      params: params,
    };
    const id = await CloudRunWithCache("getSystemFormIdWithUniqueKey", param);
    return id;
  } catch (error) {
    console.error("get system form failed", error);
    throw error;
  }
}

const getSystemFormData = async (isPreview, formKey, formView, id, isCopy, useCache) => {
  try {
    const param = {
      formkey: formKey,
      formview: formView,
      isPreview: isPreview,
      id: id,
      isCopy: isCopy,
    };
    const nocache = useCache ? false : true;
    const json = await CloudRunWithCache("getSystemFormData", param, nocache);
    return parseMoment(json);
  } catch (error) {
    console.error("get system form failed", error);
    throw error;
  }
}

const getSystemFormDataHistory = async (isPreview, formKey, formView, id) => {
  try {
    const param = {
      formkey: formKey,
      formview: formView,
      isPreview: isPreview,
      id: id,
    };
    const json = await CloudRunWithCache("getSystemFormDataHistory", param, true);
    return parseMoment(json);
  } catch (error) {
    console.error("get system form history failed", error);
    throw error;
  }
}

const getSystemFlowDataHistory = async (isPreview, flowKey, formKey, formView, id) => {
  try {
    const param = {
      flowkey: flowKey,
      formkey: formKey,
      formview: formView,
      isPreview: isPreview,
      id: id,
    };
    const json = await CloudRunWithCache("getSystemFlowDataHistory", param, true);
    return parseMoment(json);
  } catch (error) {
    console.error("get system form history failed", error);
    throw error;
  }
}

const duplicateSystemFormData = async (isPreview, formKey, formView, id, isCopy) => {
  try {
    const param = {
      formkey: formKey,
      formview: formView,
      isPreview: isPreview,
      id: id,
      isCopy: isCopy,
    };
    const json = await CloudRunWithCache("duplicateSystemFormData", param, true);
    return parseMoment(json);
  } catch (error) {
    console.error("get system form failed", error);
    throw error;
  }
}

const createChildSystemFormData = async (isPreview, formKey, formView, id, relationKey) => {
  try {
    const param = {
      formkey: formKey,
      formview: formView,
      isPreview: isPreview,
      id: id,
      relationKey: relationKey,
    };
    const json = await CloudRunWithCache("createChildSystemFormData", param, true);
    return parseMoment(json);
  } catch (error) {
    console.error("get system form failed", error);
    throw error;
  }
}

const refreshChildSystemFormData = async (isPreview, formKey, formView, id, relationKey) => {
  try {
    const param = {
      formkey: formKey,
      formview: formView,
      isPreview: isPreview,
      id: id,
      relationKey: relationKey,
    };
    const json = await CloudRunWithCache("refreshChildSystemFormData", param, true);
    return parseMoment(json);
  } catch (error) {
    console.error("get system form failed", error);
    throw error;
  }
}

const getSystemFormActionPolicies = async (isPreview, formKey, formView) => {
  try {
    const param = {
      formkey: formKey,
      formview: formView,
      isPreview: isPreview,
    };
    const nocache = isPreview ? true : false;
    return await CloudRunWithCache("getSystemFormActionPolicies", param, nocache);
  } catch (error) {
    console.error("get system form action policies failed", error);
    return [];
  }
}

const processSystemFormDataChange = async (isPreview, formKey, formView, form, oldForm) => {
  try {
    const parsedForm = JSON.parse(JSON.stringify(form));
    const parsedOldForm = oldForm ? JSON.parse(JSON.stringify(oldForm)) : undefined;
    const param = {
      formkey: formKey,
      formview: formView,
      form: parsedForm,
      oldForm: parsedOldForm,
      isPreview: isPreview,
    };
    const json = await CloudRunWithCache("processSystemFormDataChange", param, true);
    return parseMoment(json);
  } catch (error) {
    console.error("process system form change failed", error);
    throw error;
  }
}

const saveSystemFormData = async (isPreview, formKey, formView, form, submitKey) => {
  try {
    const parsedForm = JSON.parse(JSON.stringify(form));
    const param = {
      formkey: formKey,
      formview: formView,
      form: parsedForm,
      isPreview: isPreview,
      submitKey: submitKey,
    };
    const json = await CloudRunWithCache("saveSystemFormData", param, true);
    return parseMoment(json);
  } catch (error) {
    console.error("save system form failed", error);
    throw error;
  }
}

const saveSystemFormAndFlowData = async (isPreview, formKey, formView, form, flow, action, flowParent) => {
  try {
    const parsedForm = JSON.parse(JSON.stringify(form));
    const param = {
      formkey: formKey,
      formview: formView,
      form: parsedForm,
      isPreview: isPreview,
      flow, action, flowParent
    };
    const json = await CloudRunWithCache("saveSystemFormAndFlowData", param, true);
    return parseMoment(json);
  } catch (error) {
    console.error("save system form and flow failed", error);
    throw error;
  }
}

const updateSystemFormFlowData = async (isPreview, formKey, id, flow, flowData, notification) => {
  try {
    const parsedFlowData = JSON.parse(JSON.stringify(flowData));
    const param = {
      formkey: formKey,
      id, isPreview, flow, flowData: parsedFlowData, notification
    };
    await CloudRunWithCache("updateSystemFormFlowData", param, true);
    return;
  } catch (error) {
    console.error("update flow data failed", error);
    throw error;
  }
}

const deleteSystemFormData = async (isPreview, formKey, formView, id, versionStamp, cascade) => {
  try {
    const param = {
      formkey: formKey,
      formview: formView,
      isPreview: isPreview,
      id: id,
      versionStamp: versionStamp,
      cascade: cascade,
    };
    const json = await CloudRunWithCache("deleteSystemFormData", param, true);
    return json;
  } catch (error) {
    console.error("delete system form failed", error);
    throw error;
  }
};

const registerDataClassConfig = async (isPreview, formKey, formView, updatecache) => {
  try {
    const param = {isPreview, formkey: formKey, formview: formView, updatecache};
    return await CloudRunWithCache("registerDataClassConfig", param, isPreview);
  } catch (error) {
    console.error("register data class failed", error);
    throw error;
  }
}

const registerAllViewDataClassConfig = async (isPreview, formKey, updatecache) => {
  try {
    const param = {isPreview, formkey: formKey, updatecache};
    return await CloudRunWithCache("registerAllViewDataClassConfig", param, isPreview);
  } catch (error) {
    console.error("register all view data class failed", error);
    throw error;
  }
}

const publishAllSharedSearch = async (formKey) => {
  try {
    const param = {formkey: formKey};
    return await CloudRunWithCache("publishAllSharedSearch", param, true);
  } catch (error) {
    console.error("publish all shared search failed", error);
    throw error;
  }
}

const publishAllSharedSearchResult = async (formKey) => {
  try {
    const param = {formkey: formKey};
    return await CloudRunWithCache("publishAllSharedSearchResult", param, true);
  } catch (error) {
    console.error("publish all shared search result failed", error);
    throw error;
  }
}

const publishSharedSearch = async (formKey, formView) => {
  try {
    const param = {formkey: formKey, formview: formView};
    return await CloudRunWithCache("publishSharedSearch", param, true);
  } catch (error) {
    console.error("publish shared search failed", error);
    throw error;
  }
}

const publishSharedSearchResult = async (formKey, formView) => {
  try {
    const param = {formkey: formKey, formview: formView};
    return await CloudRunWithCache("publishSharedSearchResult", param, true);
  } catch (error) {
    console.error("publish shared search result failed", error);
    throw error;
  }
}

const generateReport = async (isPreview, formKey, formView, id) => {
  try {
    const param = {
      formkey: formKey,
      formview: formView,
      isPreview: isPreview,
      id: id,
    };
    return await CloudRunWithCache("generateReport", param, true);
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
}

const generateExport = async (isPreview, formKey, formView, id, dataClass, params, sort, flowParent) => {
  try {
    const param = {
      formkey: formKey,
      formview: formView,
      isPreview: isPreview,
      id: id, dataClass, params, sort, flowParent
    };
    return await CloudRunWithCache("generateExport", param, true);
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
}

const saveSystemFormDataDraft = async (isPreview, formKey, formView, formId, form) => {
  try {
    const value = JSON.stringify(form);
    const param = {
      formkey: formKey,
      formview: formView,
      formId: formId,
      value: value,
      isPreview: isPreview,
    };
    const json = await CloudRunWithCache("saveSystemFormDataDraft", param, true);
    if (json) {
      const parsed = JSON.parse(json.value, (key, value) => {
        if (isDate(value)) {
          return moment(value);
        } else {
          return value;
        }
      });
      return parsed;
    } else {
      throw new Error("Save draft failed!");
    }
  } catch (error) {
    console.error("save system form draft failed", error);
    throw error;
  }
}

const getSystemFormDataDraft = async (isPreview, formKey, formView, formId) => {
  try {
    const param = {
      formkey: formKey,
      formview: formView,
      formId: formId,
      isPreview: isPreview,
    };
    const json = await CloudRunWithCache("getSystemFormDataDraft", param, true);
    if (json) {
      const parsed = JSON.parse(json.value, (key, value) => {
        if (isDate(value)) {
          return moment(value);
        } else {
          return value;
        }
      });
      return {values:parsed, updatedAt: json.updatedAt};
    } else {
      return null;
    }
  } catch (error) {
    console.error("get system form draft failed", error);
    throw error;
  }
}

const deleteSystemFormDataDraft = async (isPreview, formKey, formView, formId) => {
  try {
    const param = {
      formkey: formKey,
      formview: formView,
      formId: formId,
      isPreview: isPreview,
    };
    await CloudRunWithCache("deleteSystemFormDataDraft", param, true);
  } catch (error) {
    console.error("delete system form draft failed", error);
    throw error;
  }
}

const getFormTranslate = async (filterKeyPrefix, formKey, formView, isPreview, id) => {
  try {
    const param = {
      formkey: formKey,
      formview: formView,
      id: id,
      isPreview: isPreview,
      filterKeyPrefix: filterKeyPrefix
    };
    return await CloudRunWithCache("getFormTranslate", param, true);
  } catch (error) {
    console.error("get system form translate error", error);
    throw error;
  }
}

const saveFormTranslate = async (filterKeyPrefix, formKey, formView, isPreview, id, data) => {
  try {
    const param = {
      formkey: formKey,
      formview: formView,
      id: id,
      isPreview: isPreview,
      filterKeyPrefix: filterKeyPrefix,
      data: data
    };
    return await CloudRunWithCache("saveFormTranslate", param, true);
  } catch (error) {
    console.error("get system form translate error", error);
    throw error;
  }
}

const analyzeScript = async (data) => {
  try {
    const param = {
      data: data
    };
    return await CloudRunWithCache("analyzeScript", param, true);
  } catch (error) {
    console.error("get system form translate error", error);
    throw error;
  }
}

const formApi = {
  loadSystemForm,
  getSystemForm,
  getSystemFormVersions,
  saveSystemForm,
  deleteSystemForm,
  getSystemFormIdWithUniqueKey,
  getSystemFormData,
  getSystemFormDataHistory,
  getSystemFlowDataHistory,
  duplicateSystemFormData,
  createChildSystemFormData,
  refreshChildSystemFormData,
  getSystemFormActionPolicies,
  processSystemFormDataChange,
  saveSystemFormData,
  saveSystemFormAndFlowData,
  updateSystemFormFlowData,
  deleteSystemFormData,
  generateReport,
  generateExport,
  saveSystemFormDataDraft,
  getSystemFormDataDraft,
  deleteSystemFormDataDraft,
  registerDataClassConfig,
  registerAllViewDataClassConfig,
  publishAllSharedSearch,
  publishAllSharedSearchResult,
  publishSharedSearch,
  publishSharedSearchResult,
  getFormTranslate,
  saveFormTranslate,
  analyzeScript,
}

export default formApi;