const languageData = [
  {
    languageId: 'en',
    locale: 'en-US',
    name: 'English',
    icon: 'us'
  },
  {
    languageId: 'zh_HK',
    locale: 'zh-Hans-HK',
    name: '繁體中文',
    icon: 'hk'
  },
  {
    languageId: 'zh_CN',
    locale: 'zh-Hans-CN',
    name: '简体中文',
    icon: 'cn'
  },
];
export default languageData;
