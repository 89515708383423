import { ApartmentOutlined, CopyOutlined, DeleteOutlined, EyeOutlined, FileAddOutlined, FolderOpenOutlined, ReloadOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, InputNumber, message, Popconfirm, Switch, Tooltip } from "antd";
import { Form, ColorPicker, Input, Select } from "components/Form";
import React, { memo, useEffect, useRef, useState } from 'react';
import ReactFlow, {
  addEdge, Background, Controls, Handle,
  MiniMap, removeElements, updateEdge, useZoomPanHelper
} from 'react-flow-renderer';
import { MdOutlinePublic } from "react-icons/md";
import { MdOutlinePublicOff } from "react-icons/md";
import { arrayPushUnique, compressJsonData, decompressJsonData, getArrayElementByAttribute, getArraysUnion, isEmpty, uid, log } from "util/algorithm";
import IntlMessages from "util/IntlMessages";
import { SystemSelect } from "../FormSetup/components";
import { AccountStore } from "../../../constants/Account";
import { BiTrash } from "react-icons/bi";
import { useLbl } from "../../../lngProvider";
import { settingsSignal } from "../../../util/signal";

let CURRID = 1;
const getId = (type) => `${type}_${uid()}`;
const INPUT = '#808000';
const OUTPUT = '#008080';

const onElementsRemoveImpl = (elementsToRemove, setElements, lbl) => {
  for(const element of elementsToRemove) {
    const ele = element;
    if (ele.type === 'start' || ele.type === 'end') {
      log('cannot remove start / end node');
      message.error(lbl("system.flow.error.cannot_delete_start_end", "Cannot remove start or end node."))
      return false;
    }
  }
  setElements((els) => removeElements(elementsToRemove, els));
}

const StartNode = memo(({ data, isConnectable }) => {
  const currentTaskCls = data.isCurrentTask ? 'craft-flow-task-node-current' : ''
  return (
    <>
      <div className={`craft-flow-node craft-flow-circle-node start ${currentTaskCls}`}>
        <IntlMessages id="system.flow.start" text="Start" />
        <Handle
          type="source"
          position="bottom"
          id="a"
          style={{ background: OUTPUT }}
          onConnect={(params) => log('handle onConnect', params)}
          isConnectable={isConnectable}
        />
      </div>
    </>
  );
});

const EndNode = memo(({ data, isConnectable }) => {
  const currentTaskCls = data.isCurrentTask ? 'craft-flow-task-node-current' : ''
  return (
    <>
      <div className={`craft-flow-node craft-flow-circle-node end ${currentTaskCls}`}>
        <IntlMessages id="system.flow.end" text="End" />
        <Handle
          type="target"
          position="top"
          id="a"
          style={{ background: INPUT }}
          onConnect={(params) => log('handle onConnect', params)}
          isConnectable={isConnectable}
        />
      </div>
    </>
  );
});

const TaskNode = memo(({ data, isConnectable }) => {
  const style = {};
  let height = data.height;
  let width = data.width;
  let background = data.background;
  let color = data.color;
  const currentTaskCls = data.isCurrentTask ? 'craft-flow-task-node-current' : ''
  if (!height) height = 45;
  if (!width) width = 150;
  if (!background) background = "rgba(252,247,229,1)";
  if (!color) color = "rgb(84,84,84,1)";
  style.height = height + 'px';
  style.width = width + 'px';
  style.color = color;
  style.backgroundColor = background;

  const inverted = data.inverted;

  if (inverted) {
    return (
      <div className={`craft-flow-node craft-flow-task-node ${currentTaskCls}`} style={style}>
        {data.label ? data.label : "Task"}
        <Handle
          type="source"
          position="top"
          id="a"
          style={{ background: OUTPUT, left: ((width) / 2) + "px" }}
          isConnectable={isConnectable}
        />
        <Handle
          type="target"
          position="top"
          id="a1"
          style={{ background: INPUT, left: ((width - 50) / 2) + "px" }}
          isConnectable={isConnectable}
        />
        <Handle
          type="source"
          position="top"
          id="a2"
          style={{ background: OUTPUT, left: ((width + 50) / 2) + "px" }}
          isConnectable={isConnectable}
        />
        <Handle
          type="target"
          position="bottom"
          id="b"
          style={{ background: INPUT, left: ((width) / 2) + "px" }}
          isConnectable={isConnectable}
        />
        <Handle
          type="source"
          position="bottom"
          id="b1"
          style={{ background: OUTPUT, left: ((width - 50) / 2) + "px" }}
          isConnectable={isConnectable}
        />
        <Handle
          type="target"
          position="bottom"
          id="b2"
          style={{ background: INPUT, left: ((width + 50) / 2) + "px" }}
          isConnectable={isConnectable}
        />
        <Handle
          type="source"
          position="left"
          id="c"
          isConnectable={isConnectable}
          style={{ background: OUTPUT, top: 10 }}
        />
        <Handle
          type="source"
          position="right"
          id="d"
          isConnectable={isConnectable}
          style={{ background: OUTPUT, top: 10 }}
        />
        <Handle
          type="target"
          position="left"
          id="e"
          isConnectable={isConnectable}
          style={{ background: INPUT, top: "unset", bottom: "5px" }}
        />
        <Handle
          type="target"
          position="right"
          id="f"
          isConnectable={isConnectable}
          style={{ background: INPUT, top: "unset", bottom: "5px" }}
        />
      </div>
    );
  } else {
    return (
      <div className={`craft-flow-node craft-flow-task-node ${currentTaskCls}`} style={style}>
        {data.label ? data.label : "Task"}
        <Handle
          type="target"
          position="top"
          id="a"
          style={{ background: INPUT, left: ((width) / 2) + "px"  }}
          isConnectable={isConnectable}
        />
        <Handle
          type="target"
          position="top"
          id="a1"
          style={{ background: INPUT, left: ((width - 50) / 2) + "px" }}
          isConnectable={isConnectable}
        />
        <Handle
          type="source"
          position="top"
          id="a2"
          style={{ background: OUTPUT, left: ((width + 50) / 2) + "px" }}
          isConnectable={isConnectable}
        />
        <Handle
          type="source"
          position="bottom"
          id="b"
          style={{ background: OUTPUT, left: ((width) / 2) + "px"  }}
          isConnectable={isConnectable}
        />
        <Handle
          type="source"
          position="bottom"
          id="b1"
          style={{ background: OUTPUT, left: ((width - 50) / 2) + "px" }}
          isConnectable={isConnectable}
        />
        <Handle
          type="target"
          position="bottom"
          id="b2"
          style={{ background: INPUT, left: ((width + 50) / 2) + "px" }}
          isConnectable={isConnectable}
        />
        <Handle
          type="target"
          position="left"
          id="c"
          isConnectable={isConnectable}
          style={{ background: INPUT, top: 10 }}
        />
        <Handle
          type="target"
          position="right"
          id="d"
          isConnectable={isConnectable}
          style={{ background: INPUT, top: 10 }}
        />
        <Handle
          type="source"
          position="left"
          id="e"
          isConnectable={isConnectable}
          style={{ background: OUTPUT, top: "unset", bottom: "5px" }}
        />
        <Handle
          type="source"
          position="right"
          id="f"
          isConnectable={isConnectable}
          style={{ background: OUTPUT, top: "unset", bottom: "5px" }}
        />
      </div>
    );
  }
});


export const getFlowConfig = (flow) => {
  try {
    const flowConfig = flow.data ? JSON.parse(decompressJsonData(flow.data)) : flow;
    return flowConfig;
  } catch (e) {
    log("fail to get task view", e);
  }
  return null;
}

export const getTask = (flow, task) => {
  try {
    const flowConfig = flow.data ? JSON.parse(decompressJsonData(flow.data)) : flow;
    for (const element of flowConfig.elements) {
      if (element.data.itemKey === task && element.data.type === 'node') {
        return element;
      }
    }
    log("task view not found");
    return null;
  } catch (e) {
    log("fail to get task view", e);
  }
  return null;
}

export const getTaskView = (flow, task) => {
  try {
    const flowConfig = flow.data ? JSON.parse(decompressJsonData(flow.data)) : flow;
    for (const element of flowConfig.elements) {
      if (element.data.itemKey === task && element.data.type === 'node') {
        return element.data.view;
      }
    }
    log("task view not found");
    return null;
  } catch (e) {
    log("fail to get task view", e);
  }
  return null;
}

export const getTaskStatus = (flow, task) => {
  try {
    const flowConfig = flow.data ? JSON.parse(decompressJsonData(flow.data)) : flow;
    for (const element of flowConfig.elements) {
      if (element.data.itemKey === task && element.data.type === 'node') {
        return element.data.flowStatus;
      }
    }
    log("task view not found");
    return null;
  } catch (e) {
    log("fail to get task view", e);
  }
  return null;
}

export const getAllActions = (flow, task) => {
  try {
    log("getAllActions() ", flow, task);
    const flowConfig = flow.data ? JSON.parse(decompressJsonData(flow.data)) : flow;
    const actions = [];
    for (const element of flowConfig.elements) {
      if (element.source === task.id && element.data.type === 'edge') {
        actions.push(element.data);
      }
    }
    return actions;
  } catch (e) {
    log("fail to get task view", e);
  }
  return null;
}

const getEleId = (ele) => {
  const itemKey = ele.data.itemKey;
  const type = ele.data.type;
  const id = type === 'edge' ? `${ele.source}-${itemKey}`  : itemKey;
  return id;
}

const INVALID_KEY = /[. /]/;
const RESERVED_WORD = /^(flowParent|flow|parent|relation|createdAt|updatedAt|ACL|id|objectId|push|replace|reload|open|openPage|cancelPage|closePage|rowKey|key|.*_OnChange|save)$/;

const convertElementToTask = (ele) => {
  return {
    view: ele.data.view,
    status: ele.data.flowStatus,
    allowSave: ele.data.allowSave,
    allowSaveDraft: ele.data.allowSaveDraft,
    label: ele.data.label,
    description: ele.data.description,
    displayOrder: ele.data.displayOrder,
    priority: ele.data.priority,
    dueDate: ele.data.dueDate,
    color: ele.data.color,
    background: ele.data.background,
    emailAlert: ele.data.emailAlert,
    smsAlert: ele.data.smsAlert,
    emailPolicies: [],
    smsPolicies: [],
    actions: [],
  }
}

const convertElementToAction = (ele, itemKey, targetItemKey) => {
  return {
    action: itemKey,
    policies: ele.data.actionPolicies,
    label: ele.data.label,
    displayOrder: ele.data.displayOrder,
    roles: ele.data.actionRoles ? [...ele.data.actionRoles] : [],
    nextTask: targetItemKey,
    discard: ele.data.discard,
    hidden: ele.data.hidden,
    onDue: ele.data.onDue,
    autoStart: ele.data.autoStart,
    confirm: ele.data.confirm,
  };
}

const validateFlowInfoElement = (itemKey) => {
  if (isEmpty(itemKey)) return `Id is missing - "${itemKey}"`;
  if (!itemKey.match(/^[a-zA-Z][a-zA-Z_$0-9]*$/)) {
    return "Id is invalid - "+itemKey+")";
  }
  if (itemKey.match(RESERVED_WORD)) {
    return "Id is reserved word - "+itemKey+")";
  }
  return null;
}

const validateFlowInfoTask = (ele, id, itemKey, idMap, itemKeyMap, allViews) => {
  if (itemKeyMap[id]) {
    return `Task id is duplicated - "${itemKey}"`;
  } else {
    itemKeyMap[id] = ele;
    idMap[ele.id] = ele;
  }
  if (isEmpty(ele.data.view)) return `View is missing - "${itemKey}"`;
  if (isEmpty(ele.data.flowStatus)) return `Status is missing - "${itemKey}"`;
  if (isEmpty(ele.data.label)) return `Label is missing - "${itemKey}"`;
  if (allViews.indexOf(ele.data.view) === -1) {
    return `View is defined in form - "${itemKey}"`;
  }
}

const validateFlowInfoAction = (ele, id, itemKey, idMap, itemKeyMap, taskMap, allPolicies) => {
  let error = null;
  error = validateFlowInfoActionSetting(ele, id, itemKey, idMap, itemKeyMap);
  if (error) return error;

  error = validateFlowInfoActionDueDate(ele, itemKey, idMap, taskMap);
  if (error) return error;

  const sourceTask = idMap[ele.source];
  const targetTask = idMap[ele.target];
  const sourceItemKey = sourceTask?.data.itemKey;
  const targetItemKey = targetTask?.data.itemKey
  const task = taskMap[sourceItemKey];

  const currentAction = convertElementToAction(ele, itemKey, targetItemKey);
  task.actions.push(currentAction);
  error = validateFlowInfoActionPolicy(ele, allPolicies);
  if (error) return error;

  return error;
}

const validateFlowInfoActionSetting = (ele, id, itemKey, idMap, itemKeyMap) => {
  const sourceTask = idMap[ele.source];
  const targetTask = idMap[ele.target];
  const sourceItemKey = sourceTask?.data.itemKey;
  if (itemKeyMap[id]) {
    return `Action id is duplicated in task "${sourceItemKey}"`;
  } else {
    itemKeyMap[id] = ele;
  }
  if (isEmpty(sourceItemKey)) return `Source task not found - "${itemKey}"`;
  const targetItemKey = targetTask?.data.itemKey
  if (isEmpty(targetItemKey)) return `Target task not found - "${itemKey}"`;
  if (isEmpty(ele.data.label)) return `Label is missing - "${sourceItemKey}"`;
  if (!ele.data.actionRoles || ele.data.actionRoles.length === 0) {
    if (!ele.data.hidden) {
      return `Roles is missing - "${sourceItemKey}" "${itemKey}"`;
    }
  }
  return null;
}

const validateFlowInfoActionDueDate = (ele, itemKey, idMap, taskMap) => {
  const sourceTask = idMap[ele.source];
  const sourceItemKey = sourceTask?.data.itemKey;
  const task = taskMap[sourceItemKey];
  let onDueDefined = false;
  let autoStartDefined = false;
  task.actions.forEach(a => {
    if (a.onDue) onDueDefined = true;
    if (a.autoStart) autoStartDefined = true;
  });
  if (onDueDefined && ele.data.onDue) {
    return `on due is only allowed for one action - "${sourceItemKey}" "${itemKey}"`;
  }
  if (autoStartDefined && ele.data.autoStart) {
    return `auto start is only allowed for one action - "${sourceItemKey}" "${itemKey}"`;
  }
  return null;
}

const validateFlowInfoActionPolicy = (ele, allPolicies) => {
  if (ele.data.actionPolicies) {
    for (const p of ele.data.actionPolicies) {
      const policy = getArrayElementByAttribute(allPolicies,"actionKey",p);
      if (!policy || !policy.data) {
        return "action is invalid - ("+p+")";
      }
    }
  }
  return null;
}

const iterateElementsForTask = (elements, idMap, itemKeyMap, allViews, taskMap) => {
  let error = null;
  for (const element of elements) {
    const ele = element;
    const type = ele.data.type;
    const itemKey = ele.data.itemKey;
    error = validateFlowInfoElement(itemKey);
    if (error) return error;

    const id = getEleId(ele);
    if (type === 'node') {
      error = validateFlowInfoTask(ele, id, itemKey, idMap, itemKeyMap, allViews);
      if (error) return error;
      taskMap[itemKey] = convertElementToTask(ele);
    }
  }
  return null;
}

const iteratorElementsForAction = (elements, idMap, itemKeyMap, taskMap, allPolicies) => {
  let error = null;
  for (const element of elements) {
    const ele = element;
    const type = ele.data.type;
    const itemKey = ele.data.itemKey;
    if (isEmpty(itemKey)) return `Id is missing - "${itemKey}"`;
    const id = getEleId(ele);

    if (type === 'edge') {
      error = validateFlowInfoAction(ele, id, itemKey, idMap, itemKeyMap, taskMap, allPolicies)
      if (error) return error;
    }
  }
  return null;
}

export const validateFlowInfo = (data, flowData, formData, allPolicies) => {
  const nodeMap = JSON.parse(decompressJsonData(data));
  const itemKeyMap = {};
  const idMap = {};
  const elements = nodeMap.elements;
  const allViews = formData?.extraParams?.allViews || [];
  const taskMap = {};
  const extraParams = {taskMap};
  let error = null;

  if (flowData.flowKey && flowData.flowKey.match(INVALID_KEY)) {
    return returnError("flow key is invalid - ("+flowData.flowKey+")");
  }

  error = iterateElementsForTask(elements, idMap, itemKeyMap, allViews, taskMap);
  if (error) return returnError(error);

  error = iteratorElementsForAction(elements, idMap, itemKeyMap, taskMap, allPolicies);
  if (error) return returnError(error);

  return {
    error: null,
    extraParams: extraParams,
  };
}

const returnError = (error) => {
  return {
    error: error
  };
}

const FlowCanvas = ({
  flowData, setFlowData,
  rfInstance, setRfInstance,
  elements, setElements,
  selected, setSelected,
  editable, setEditable,
  showProperty }) => {

  const { locale } = settingsSignal;
  const lbl = useLbl(locale);

  const connectionColor = '#666'
  const connectionLineStyle = { stroke: connectionColor };
  const snapGrid = [15, 15];
  const nodeTypes = {
    start: StartNode,
    end: EndNode,
    task: TaskNode,
    invertedTask: TaskNode
  };

  const reactFlowWrapper = useRef(null);
  const prevSelectedNode = useRef();

  useEffect(() => {
    CURRID = flowData?.currId ? flowData.currId : 1;
  }, [flowData])

  const onLoad = (_reactFlowInstance) =>
    setRfInstance(_reactFlowInstance);

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const onDrop = (event) => {
    event.preventDefault();

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData('application/reactflow');
    const position = rfInstance.project({
      x: event.clientX - reactFlowBounds.left - 75,
      y: event.clientY - reactFlowBounds.top - 22.5,
    });
    const id = getId('task');
    const newNode = {
      id: id,
      type,
      position,
      data: { type: 'node', itemKey: id, label: `Task ${CURRID++}`, inverted: type !== 'task' },
    };

    setElements((es) => es.concat(newNode));
  };

  const onElementsRemove = (elementsToRemove) => {
    onElementsRemoveImpl(elementsToRemove, setElements, lbl)
  }

  const onConnect = (params) => {
    const id = getId('edge');
    params.id = id;
    params.style = { ...connectionLineStyle };
    params.arrowHeadType = 'arrowclosed';
    params.label = `Submit`;
    params.data = {type: 'edge', itemKey: 'submit', label: `Submit`};
    setElements((els) => addEdge(params, els));
  }

  const onEdgeUpdate = (oldEdge, newConnection) => {
    setElements((els) => updateEdge(oldEdge, newConnection, els));
  }

  const onElementClick = (event, element) => {
    setSelected(element);
  }

  const onSelectionChange = (elements) => {
    if (elements && elements.length > 0) {
      const selectedNode = elements[0];
      setSelected(selectedNode);
      if (prevSelectedNode.current !== selectedNode) {
        if (selectedNode) showProperty();
      }
      prevSelectedNode.current = selectedNode;
    } else {
      setSelected(null);
    }
  }

  return (
    <div className="reactflow-canvas">
      <div className="reactflow-wrapper" ref={reactFlowWrapper}>
        <ReactFlow
          elements={elements}
          snapToGrid={true}
          snapGrid={snapGrid}
          arrowHeadColor={connectionColor}
          nodeTypes={nodeTypes}
          onConnect={onConnect}
          onEdgeUpdate={onEdgeUpdate}
          onElementsRemove={onElementsRemove}
          onLoad={onLoad}
          onDrop={onDrop}
          onDragOver={onDragOver}
          onElementClick={onElementClick}
          onSelectionChange={onSelectionChange}
        >
          <Controls />
          <Background
            gap={15}
          />
        </ReactFlow>
      </div>
    </div>
  );
};

export const FlowDiagramInternal = ({
  flowData,
  width,
  height,
  noCtrl,
  noBackground,
  readOnly,
  noPanZoom,
  currentTask
}) => {
  const [elements, setElements] = useState([])
  const [flow, setFlow] = useState()
  const [rfInstance, setRfInstance] = useState()
  const connectionColor = '#666'
  const snapGrid = [15, 15]
  const [loading, setLoading] = useState(true)
  const nodeTypes = {
    start: StartNode,
    end: EndNode,
    task: TaskNode,
    invertedTask: TaskNode
  }

  const reactFlowWrapper = useRef(null)

  const onLoad = (_reactFlowInstance) => {
    setRfInstance(_reactFlowInstance)
  }

  useEffect(() => {
    if (flowData && flowData.data) {
      try {
        const json = decompressJsonData(flowData.data)
        const flow = JSON.parse(json, (key, value) => {
          if (key === 'data') {
            if (value && value.itemKey === currentTask) {
              value.isCurrentTask = true
            }
          }
          return value
        })
        setFlow(flow)
        setElements(flow.elements || [])
      } catch (err) {
        log('failed to parse flow data')
      }
    }
  }, [flowData, currentTask])
  useEffect(() => {
    setLoading(true)
    if (rfInstance && flow) {
      setTimeout(() => {
        rfInstance.fitView()
        setLoading(false)
      }, 500)
    }
  }, [rfInstance, flow, currentTask])
  const style = {}
  if (height) style.height = height
  if (width) style.width = width
  if (loading) style.visibility = 'hidden'
  return (
    <div className='reactflow-canvas'>
      <div className='reactflow-wrapper' ref={reactFlowWrapper} style={style}>
        <ReactFlow
          elements={elements}
          snapToGrid
          snapGrid={snapGrid}
          arrowHeadColor={connectionColor}
          nodeTypes={nodeTypes}
          onLoad={onLoad}
          nodesDraggable={!readOnly}
          nodesConnectable={!readOnly}
          zoomOnScroll={!noPanZoom}
          paneMoveable={!noPanZoom}
        >
          {!noCtrl && <Controls />}
          {!noBackground && <Background gap={15} />}
        </ReactFlow>
      </div>
    </div>
  )
}

const flowDataToFields = (flowData) => {
  return {
    flowKey: flowData.flowKey,
    savedFlowKey: flowData.flowKey,
    flowName: flowData.flowName,
    versionStamp: flowData.versionStamp,
    versionRemarks: flowData.versionRemarks,
    formKey: flowData.formKey,
    admins: flowData.admins,
    adminRoles: flowData.adminRoles,
  }
}

const prepareFlowData = (rfInstance, form) => {
  const flow = rfInstance.toObject();
  const flowKey = form.getFieldValue(["flowKey"]);
  const flowName = form.getFieldValue(["flowName"]);
  const formKey = form.getFieldValue(["formKey"]);
  const admins = form.getFieldValue(["admins"]);
  const adminRoles = form.getFieldValue(["adminRoles"]);
  const versionRemarks = form.getFieldValue(["versionRemarks"]);
  const versionStamp = form.getFieldValue(["versionStamp"]);
  const json = JSON.stringify(flow);
  const data = AccountStore.COMPRESSDATA ? compressJsonData(json) : json;
  const currId = CURRID;
  return {
    flowKey, flowName, formKey, data,
    currId, admins, adminRoles,
    versionRemarks, versionStamp
  }
}

const ActionButton = ({id, title, confirm, onClick, dragType, ...props}) => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };
  if (confirm) {
    return <Popconfirm
      title={<IntlMessages id={`system.flow.confirm.${id}`} text={confirm} />}
      onConfirm={onClick}
      okText={<IntlMessages id="system.confirm.yes" text="Yes" />}
      cancelText={<IntlMessages id="system.confirm.no" text="No" />}
    >
      <Tooltip mouseEnterDelay={1} title={<IntlMessages id={`system.flow.action-button-${id}`} text={title} />}>
        <Button size="middle" className={`flowinfo-btn ${id}`} {...props} />
      </Tooltip>
    </Popconfirm>
  } else if (dragType) {
    return (
      <Tooltip
        title={<IntlMessages id={`system.flow.action-button-${id}`} text={title} />}>
        <Button size="middle" className={`flowinfo-btn ${id}`}
        onDragStart={(event) => onDragStart(event, dragType)} draggable {...props} />
      </Tooltip>
    );
  } else {
  return <Tooltip mouseEnterDelay={1} title={<IntlMessages id={`system.flow.action-button-${id}`} text={title} />}>
    <Button size="middle" className={`flowinfo-btn ${id}`} onClick={onClick} {...props}/>
  </Tooltip>
  }
}

const setupFlowData = (flowData, setAllStatus, setElements, transform) => {
  const data = flowData?.data;
  const json = decompressJsonData(data);
  const status = [];
  const flow = JSON.parse(json, (key,value)=>{
    if (key === 'flowStatus') {
      arrayPushUnique(status ,value);
    }
    return value;
  });
  setAllStatus(status);
  if (flow) {
    const [x = 0, y = 0] = flow.position;
    setElements(flow.elements || []);
    transform({ x, y, zoom: flow.zoom || 0 });
  }
}

const FlowBasicInfo = ({isOpen, flowData, allFlows, isCopy, doReload, editable}) => {
  return (
    <>
      {(isOpen || (flowData?.objectId && !isCopy)) &&
        <Form.Item name="savedFlowKey" label="Flow ID"
          rules={[{ required: true, message: <IntlMessages id="system.flow.rules.mandatory.flowKey" text="Please provide a flow ID!" /> }]}
        >
          <Select className="savedFlowKey" onChange={(value) => doReload(value)} showSearch>
            {allFlows?.map(c => <Select.Option key={c.flowKey} value={c.flowKey}>{c.flowKey}</Select.Option>)}
          </Select>
        </Form.Item>
      }
      {(!isOpen && (!flowData?.objectId || isCopy)) &&
        <Form.Item name="flowKey" label="Flow ID"
          rules={[{ required: true, message: <IntlMessages id="system.flow.rules.mandatory.flowKey" text="Please provide a flow ID!" /> }]}
        >
          <Input className="flowKey" readOnly={!editable}/>
        </Form.Item>
      }
      <Form.Item name="flowName" label="Name" hidden={!editable}
        rules={[{ required: true, message: <IntlMessages id="system.flow.rules.mandatory.flowName" text="Please provide a flow name!" /> }]}
      >
        <Input className="flowName" readOnly={!editable}></Input>
      </Form.Item>
    </>
  )
}

const FlowInfo = ({
  setElements, rfInstance, flowData, setAllStatus,
  doSave, doReload, doAdd, doDelete, editable, setEditable,
  allFlows, allVersions, allForms, setFormKey,
  doPreview, doPublish, doUnpublish }) => {
  const { transform } = useZoomPanHelper();
  const [form] = Form.useForm();
  const [isCopy, setIsCopy] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, [])

  useEffect(() => {
    if (flowData) {
      if (form) {
        form.setFieldsValue(flowDataToFields(flowData));
      }
      setupFlowData(flowData, setAllStatus, setElements, transform)
      if (mounted.current) setIsOpen(false);
    } else {
      if (mounted.current) setIsOpen(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowData])

  const saveAction = () => {
    if (rfInstance) {
      const newFlowData = prepareFlowData(rfInstance, form);
      const isNew = !flowData?.objectId || isCopy;
      doSave(newFlowData, isNew);
    }
  };

  const copyAction = () => {
    if (mounted.current) setIsCopy(true);
    if (mounted.current) setIsOpen(false);
    form.setFieldsValue({flowKey: "", savedFlowKey: "", flowName: ""});
  }

  const openAction = () => {
    if (mounted.current) setIsOpen(true);
    if (mounted.current) setIsCopy(false);
    if (mounted.current) setEditable(false);
    form.setFieldsValue({flowKey: "", savedFlowKey: "", flowName: ""});
  }

  const delAction = () => {
    const savedFlowKey = form.getFieldValue(["savedFlowKey"]);
    if (savedFlowKey) {
      doDelete(flowData);
    }
  }

  const onFormChanged = (formKey) => {
    if (mounted.current) setFormKey(formKey);
  }

  const previewAction = (e) => {
    const flowKey = form.getFieldValue(["flowKey"]);
    if (doPreview) {
      doPreview(flowKey, e);
    }
  }

  return (
    <>
      <Form
        name="flowsetup.info"
        form={form}
        colon={false}
        labelAlign="left"
        size="small"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        requiredMark={false}
        onFinish={saveAction}
      >
        <FlowBasicInfo {...{isOpen, flowData, allFlows, isCopy, doReload, editable}}/>
        <Form.Item name="admins" label="Admins" hidden={!editable || isCopy}>
          <SystemSelect className="admins" selectkey="User" isUseCode={true} mode="multiple" showSearch allowClear/>
        </Form.Item>
        <Form.Item name="adminRoles" label="Admin Roles" hidden={!editable || isCopy}>
          <SystemSelect className="adminRoles" selectkey="Role" isUseCode={true} mode="multiple" showSearch allowClear/>
        </Form.Item>
        <Form.Item name="versionRemarks" label="Remarks" hidden={!editable}>
          <Input className="versionRemarks" readOnly={!editable}></Input>
        </Form.Item>
        <Form.Item name="versionStamp" label="Version" hidden={!editable || isCopy}>
          <Select className="versionStamp" onChange={(value) => doReload(flowData.flowKey, value)}>
          {allVersions?.map(v => <Select.Option key={v.versionStamp} value={v.versionStamp}>{v.versionStamp}{v.versionRemarks && " - " + v.versionRemarks}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item name="formKey" label="Form" hidden={!editable || isCopy}>
          <Select className="formKey" onChange={onFormChanged} showSearch>
            {allForms?.map(c => <Select.Option key={c.formKey} value={c.formKey}>{c.formKey}</Select.Option>)}
          </Select>
        </Form.Item>
        <div className="flowinfo-btn-bar">
          <ActionButton disabled={!editable || isOpen} dragType={'task'} id="task" title="Task"><ApartmentOutlined /></ActionButton>
          <ActionButton disabled={!editable || isOpen} dragType={'invertedTask'} id="inverted-task" title="Task (Upside Down)"><ApartmentOutlined rotate={180} /></ActionButton>
          <ActionButton disabled={!editable || isOpen} id="save" title="Save" htmlType="submit"><SaveOutlined /></ActionButton>
          <ActionButton disabled={!editable || !flowData?.objectId || isCopy} onClick={copyAction} id="copy" title="Copy"><CopyOutlined /></ActionButton>
          {!isOpen &&
            <ActionButton disabled={!editable} onClick={openAction} id="open" title="Open" ><FolderOpenOutlined /></ActionButton>
          }
          {isOpen &&
            <ActionButton onClick={doAdd} id="add" title="New" ><FileAddOutlined /></ActionButton>
          }
        </div>
        <div className="flowinfo-btn-bar">
          <ActionButton danger disabled={isCopy || !flowData?.objectId} id="delete" title="Delete" onClick={delAction} confirm="Are you sure to delete this flow?"><DeleteOutlined /></ActionButton>
          <ActionButton disabled={!editable} onClick={() => doReload()} id="reload" title="Reload"><ReloadOutlined /></ActionButton>
          <ActionButton disabled={!flowData?.objectId} id="preview"  title="Preview" onClick={previewAction}><EyeOutlined /></ActionButton>
          {!flowData?.isPublished && <ActionButton disabled={flowData?.isPublished} id="publish"  title="Publish" onClick={doPublish} confirm="Are you sure to publish this flow?"><MdOutlinePublic /></ActionButton>}
          {flowData?.isPublished && <ActionButton id="republish" title="Republish" onClick={doPublish} confirm="Are you sure to re-publish this flow?"><MdOutlinePublic /></ActionButton>}
          <ActionButton disabled={!flowData?.isPublished} id="unpublish"  title="Unpublish" onClick={doUnpublish} confirm="Are you sure to unpublish this flow?"><MdOutlinePublicOff /></ActionButton>
        </div>
      </Form>
    </>
  )
};

const setValueByFieldName = (props, allFields, name) => {
  for (const element of allFields) {
    if (element.name[0] === name) {
      props[name] = element.value;
      return;
    }
  }
}

const fieldsToActionProps = (sel, allFields) => {
  setValueByFieldName(sel.data, allFields, "itemKey");
  setValueByFieldName(sel.data, allFields, "label");
  setValueByFieldName(sel.data, allFields, "description");
  setValueByFieldName(sel.data, allFields, "displayOrder");
  setValueByFieldName(sel.data, allFields, "flowStatus");
  setValueByFieldName(sel.data, allFields, "view");
  setValueByFieldName(sel.data, allFields, "allowSave");
  setValueByFieldName(sel.data, allFields, "allowSaveDraft");
  setValueByFieldName(sel.data, allFields, "actionRoles");
  setValueByFieldName(sel.data, allFields, "actionPolicies");
  setValueByFieldName(sel.data, allFields, "discard");
  setValueByFieldName(sel.data, allFields, "hidden");
  setValueByFieldName(sel.data, allFields, "onDue");
  setValueByFieldName(sel.data, allFields, "autoStart");
  setValueByFieldName(sel.data, allFields, "priority");
  setValueByFieldName(sel.data, allFields, "color");
  setValueByFieldName(sel.data, allFields, "background");
  setValueByFieldName(sel.data, allFields, "dueDate");
  setValueByFieldName(sel.data, allFields, "emailAlert");
  setValueByFieldName(sel.data, allFields, "smsAlert");
  setValueByFieldName(sel.data, allFields, "height");
  setValueByFieldName(sel.data, allFields, "width");
  setValueByFieldName(sel.data, allFields, "confirm");
  sel.label = sel.data.label;
}

const getSelectedElement = (selected, elements) => {
  let sel = null;
  if (selected) {
    for(const element of elements) {
      if (selected.id === element.id) {
        sel = element;
        break;
      }
    }
  }
  if (sel) {
    const newdata = {...sel.data};
    const newSelected = {...sel};
    newSelected.data = newdata;
    return newSelected;
  } else {
    return null;
  }
}

const actionPropsToFields = (selected, elements) => {
  const sel = getSelectedElement(selected, elements);
  if (sel) {
    return [
      { name: ["selectedId"], value: sel.id },
      { name: ["itemKey"], value: sel.data?.itemKey },
      { name: ["label"], value: sel.data?.label },
      { name: ["description"], value: sel.data?.description },
      { name: ["displayOrder"], value: sel.data?.displayOrder },
      { name: ["flowStatus"], value: sel.data?.flowStatus },
      { name: ["view"], value: sel.data?.view },
      { name: ["allowSave"], value: sel.data?.allowSave },
      { name: ["allowSaveDraft"], value: sel.data?.allowSaveDraft },
      { name: ["actionRoles"], value: sel.data?.actionRoles },
      { name: ["actionPolicies"], value: sel.data?.actionPolicies },
      { name: ["discard"], value: sel.data?.discard },
      { name: ["hidden"], value: sel.data?.hidden },
      { name: ["onDue"], value: sel.data?.onDue },
      { name: ["autoStart"], value: sel.data?.autoStart },
      { name: ["connectType"], value: sel.data?.connectType },
      { name: ["priority"], value: sel.data?.priority },
      { name: ["color"], value: sel.data?.color ? sel.data?.color : "rgba(84,84,84,1)" },
      { name: ["background"], value: sel.data?.background ? sel.data?.background : "rgba(252,247,229,1)" },
      { name: ["dueDate"], value: sel.data?.dueDate },
      { name: ["emailAlert"], value: sel.data?.emailAlert },
      { name: ["smsAlert"], value: sel.data?.smsAlert },
      { name: ["height"], value: sel.data?.height },
      { name: ["width"], value: sel.data?.width },
      { name: ["confirm"], value: sel.data?.confirm },
    ];
  } else {
    return [];
  }
}

const SettingsPanel = ({
  selected, elements, setElements, editable, allEmailOptions, allSmsOptions,
  allRoles, allStatus, setAllStatus, allViews, allPolicies,
  dueDateOptions, dueDateValues, setDueDateValues,
}) => {
  const fields = actionPropsToFields(selected, elements);
  const options = allRoles.map(r => ({label:r.name, value:r.name}));
  const policyOptions = allPolicies.map(p => ({label:p.actionName, value:p.actionKey}));
  const statusOptions = allStatus.map(s => ({label:s, value:s}));
  const { locale } = settingsSignal;
  const lbl = useLbl(locale);

  const onAddStatus = (value) => {
    const newAllStatus = getArraysUnion(allStatus, [value]);
    setAllStatus(newAllStatus);
  }

  const onElementsRemove = (elementsToRemove) => {
    onElementsRemoveImpl(elementsToRemove, setElements, lbl);
  }
  return (
    <>
      <Form name="flowsetup.props"
        colon={false}
        size="small"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        fields={fields}
        onFieldsChange={(_, allFields) => {
          const sel = getSelectedElement(selected, elements);
          fieldsToActionProps(sel, allFields);
          setElements((els) =>
            els.map((el) => {
              if (el.id === selected.id) {
                return sel;
              } else {
                return el;
              }
            }));
        }
      }>
        {selected &&
          <>
            <Form.Item name="itemKey" label="id">
              <Input disabled={!editable || selected?.id === 'start' || selected?.id === 'end' }></Input>
            </Form.Item>
            <Form.Item name="label" label="label">
              <Input disabled={!editable}></Input>
            </Form.Item>
            <Form.Item name="description" label="description" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Input.TextArea disabled={!editable}></Input.TextArea>
            </Form.Item>
            <Form.Item name="displayOrder" label="ordering" className="wrap">
              <InputNumber disabled={!editable}></InputNumber>
            </Form.Item>
          </>
        }
        {selected?.data?.type === 'node' &&
          <>
            <Form.Item name="flowStatus" label="status">
              <Select disabled={!editable} options={statusOptions} onAddItem={onAddStatus} allowClear></Select>
            </Form.Item>
            <Form.Item name="view" label="view">
              <Select disabled={!editable} options={allViews} allowClear></Select>
            </Form.Item>
            <Form.Item name="allowSave" label="save" valuePropName="checked">
              <Switch className="item-property"/>
            </Form.Item>
            <Form.Item name="allowSaveDraft" label="draft" valuePropName="checked" className="wrap">
              <Switch className="item-property"/>
            </Form.Item>
            <Form.Item name="priority" label="priority">
              <InputNumber min={1} max={10}  disabled={!editable}></InputNumber>
            </Form.Item>
            <Form.Item name="color" label="color">
              <ColorPicker  disabled={!editable} allowClear />
            </Form.Item>
            <Form.Item name="background" label="bg. color">
              <ColorPicker  disabled={!editable} allowClear />
            </Form.Item>
            {selected?.data?.itemKey !== 'start' && selected?.data?.itemKey !== 'end' &&
              <>
                <Form.Item name="dueDate" label="due date">
                  <Select options={dueDateOptions} allowClear disabled={!editable} onAddItem={(text)=>{
                    const values = [...dueDateValues, text];
                    setDueDateValues(values);
                  }}></Select>
                </Form.Item>
                <Form.Item name="emailAlert" label="email">
                  <Select options={allEmailOptions} allowClear disabled={!editable} mode="multiple"></Select>
                </Form.Item>
                <Form.Item name="smsAlert" label="sms">
                  <Select options={allSmsOptions} allowClear disabled={!editable} mode="multiple"></Select>
                </Form.Item>
                <Form.Item name="height" label="height">
                  <InputNumber min={45} max={90} disabled={!editable}></InputNumber>
                </Form.Item>
                <Form.Item name="width" label="width">
                  <InputNumber min={150} max={300} disabled={!editable}></InputNumber>
                </Form.Item>
              </>
            }
          </>
        }
        {selected?.data?.type === 'edge' &&
          <>
            <Form.Item name="actionRoles" label="roles">
              <Select disabled={!editable} mode="multiple" options={options} allowClear></Select>
            </Form.Item>
            <Form.Item name="confirm" label="confirm" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Input.TextArea disabled={!editable}></Input.TextArea>
            </Form.Item>
            <Form.Item name="actionPolicies" label="policies">
              <Select disabled={!editable} mode="multiple" options={policyOptions} allowClear></Select>
            </Form.Item>
            <Form.Item name="discard" label="discard" valuePropName="checked" >
              <Switch disabled={!editable} ></Switch>
            </Form.Item>
            <Form.Item name="hidden" label="hidden" valuePropName="checked" >
              <Switch disabled={!editable} ></Switch>
            </Form.Item>
            {selected?.source !== 'start' && <Form.Item name="onDue" label="run on due" valuePropName="checked" >
              <Switch disabled={!editable} ></Switch>
            </Form.Item>}
            {selected?.source === 'start' && <Form.Item name="autoStart" label="auto start" valuePropName="checked" >
              <Switch disabled={!editable} ></Switch>
            </Form.Item>}
          </>
        }
      </Form>
      {selected && <div className="craft_flow_setting_actions">
        <Button size="small" danger icon={<BiTrash/>} onClick={() => onElementsRemove([selected])}/>
      </div>}
    </>
  );
};

const Layers = () => {
  return (
    <MiniMap
    nodeColor={(node) => {
        switch (node.type) {
          case 'start':
            return '#faecbb';
          case 'end':
            return '#faecbb';
          case 'task':
            return '#fcf7e5';
          default:
            return 'white';
        }
      }} />
  );
}

export {
  FlowCanvas, FlowInfo, SettingsPanel, Layers
};
