import HCaptcha from "@hcaptcha/react-hcaptcha";
import React, { useEffect, useRef, useState } from "react";
import { AccountStore } from "../../constants/Account";
import { settingsSignal } from "../../util/signal";

const Captcha = ({ value, onChange }) => {
  const [token, setToken] = useState(value);
  const captchaRef = useRef(null);
  const { locale } = settingsSignal;
  useEffect(() => {
    if (token && value !== token) {
      if (onChange) onChange(token);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  useEffect(() => {
    if (value && value !== token) {
      setToken(value);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])
  return (
    <HCaptcha
      sitekey={AccountStore.HCAPTCHA_SITEKEY}
      onVerify={setToken}
      languageOverride={locale?.translate}
      ref={captchaRef}
    />
  );
};

export default Captcha;
