import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Switch } from "antd";
import { Form, Input, Select } from "components/Form";
import { formatterApi } from "parse-api";
import React, {useEffect, useState, useRef} from "react";
import { AiOutlineNumber } from "react-icons/ai";
import IntlMessages from "util/IntlMessages";
import { MyInputNumber } from "../../FormSetup/components/SfInputNumber";
import { deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SpLayoutSetting, SpStyleSetting, syncPageState, updatePageState } from "./common";

const SIZE = ['large','middle','small'];

const SpInputNumberSetting = () => {
  const [formatters, setFormatters] = useState([]);
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    const fetchData = async () => {
      const list = await formatterApi.loadSystemFormatter();
      if (mounted.current) setFormatters(list);
    }
    fetchData();
    return () => {
      mounted.current = false;
    }
  }, [])
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="defaultValue" label="default value">
        <InputNumber className="item-property"/>
      </Form.Item>
      <Form.Item name="min" label="min">
        <InputNumber className="item-property" />
      </Form.Item>
      <Form.Item name="max" label="max">
        <InputNumber className="item-property" />
      </Form.Item>
      <Form.Item name="step" label="step">
        <InputNumber className="item-property" />
      </Form.Item>
      <Form.Item name="systemFormatter" label="formatter">
        <Select className="item-property" allowClear>
          {formatters.map(f => {
            return (
              <Select.Option key={f.formatterKey} value={f.formatterKey}>{f.name}</Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item name="stringMode" label="text mode" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="readOnly" label="read-only" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="fieldWidth" label="field-width">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="size" label="size">
        <Select className="item-property" allowClear>
          {SIZE.map(s => <Select.Option key={s} value={s}>{s}</Select.Option>)}
        </Select>
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  )
}

export const SpInputNumber = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SppInputNumber doRef={ref => connect(drag(ref))} dbtn={dbtn} {...props} selectedStyle={style} />
  )
}

export const SppInputNumber = ({
  doRef, dbtn, selectedStyle,
  pageKey, itemKey, pageState, setPageState, value,  defaultValue, systemFormatter,
  fieldWidth, size, min, max, step, maxLength, stringMode, readOnly, disabled,
  ...otherProps }) => {
  let style = otherProps.style || {};
  style = {...style, ...selectedStyle};
  const props = {...otherProps, style}
  const inputProps = {size, min, max, step, maxLength, stringMode, readOnly, disabled};
  const [myValue, setMyValue] = useState(defaultValue);

  useEffect(() => {
    syncPageState({itemKey, myValue, setMyValue, pageState, setPageState})
  },[itemKey, myValue, pageState, setPageState])

  const onMyChange = (myValue) => {
    updatePageState({itemKey, myValue, setMyValue, pageState, setPageState})
  }
  const fieldStyle = {};
  if (fieldWidth) fieldStyle.width = fieldWidth;
  return (
    <Col ref={doRef} {...props}>
      <MyInputNumber style={fieldStyle} value={myValue} onChange={onMyChange}
        systemFormatter={systemFormatter}
        {...inputProps} />
      {dbtn}
    </Col>
  );
}
SppInputNumber.enablePageState = true;

SpInputNumber.craft = {
  displayName: "Input Number",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    }
  },
  related: {
    settings: SpInputNumberSetting
  }
}

registerComponent({
  key: "SpInputNumber",
  component: SpInputNumber,
  runtimeComponent: SppInputNumber,
  template: <SpInputNumber itemKey={getId('inputnumber')} className="sp-inputnumber" title={"Input Number"} span={24} />,
  title: <IntlMessages id="system.page.library.inputnumber" text="Input Number" />,
  icon: <AiOutlineNumber  className="react-icons icon-ai"/>,
  type: "Component",
  subtype: "input",
  sequence: 2,
});

export default SpInputNumber;