import { blue, cyan, geekblue, green, lime, magenta, orange, purple, volcano, yellow } from '@ant-design/colors';
import { PlusOutlined } from '@ant-design/icons';
import { useEditor, useNode } from "@craftjs/core";
import { Affix, Badge, Button, Calendar, Col, Input, InputNumber, message, Modal, Select, Switch } from "antd";
import { Form } from "components/Form";
import $ from 'jquery';
import moment, { isMoment } from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Draggable, Droppable } from 'react-drag-and-drop';
import { BsCalendarWeek } from "react-icons/bs";
import { BsPlus } from "react-icons/bs";
import IntlMessages from "util/IntlMessages";
import { ColorPicker } from '../../../../components/Form';
import { AccountStore } from '../../../../constants/Account';
import { useLbl } from "../../../../lngProvider";
import { createShadow, equals, hashCode, isDate, log, uid } from "../../../../util/algorithm";
import {
  colSls, convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate
} from "./common";
import { JsonObjectEditor } from "./SfObject";
import { commonSignal, settingsSignal } from '../../../../util/signal';

const EVENT_TYPES = ['default', 'success', 'warning', 'processing', 'error'].map(t => ({value: t, label: t}))

const SfCalendarSetting = () => {
  return (
    <>
      <Form.Item name="fullscreen" label="fullscreen" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="dateFormat" label="date format">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="debug" label="debug" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="nodrag" label="no drag" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="noadd" label="no add" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="noedit" label="no edit" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="nocopy" label="no copy" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="nodelete" label="no delete" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="customdialog" label="custom dialog" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="autocolor" label="auto color" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="doublerow" label="double row" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="row" label="row">
        <InputNumber min="6" className="item-property" />
      </Form.Item>
      <Form.Item name="responsive" label="responsive" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfCalendar = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions);
  return (
    <SfpCalendar doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

const momentReplacer = (key, value) => {
  if (isDate(value)) {
    return new moment(value).format();
  } else {
    return value;
  }
}

const parseValue = (input, myValue, setMyValue, setMyEvents) => {
  const val = tryParseStringValue(input);
  if (val) {
    parseValueInit(val, myValue);
    parseValueToEvents(val, setMyEvents);
    const json1 = JSON.parse(JSON.stringify(myValue || {}, momentReplacer));
    const json2 = JSON.parse(JSON.stringify(val, momentReplacer));
    if (!equals(json1, json2)) {
      setMyValue(val);
    }
  }
}

const tryParseStringValue = (input) => {
  if (typeof input === 'string') {
    try {
      return JSON.parse(input)
    } catch (err) {
      log('failed to parse input value', err);
    }
  }
  return input;
}

const parseValueInit = (val, myValue) => {
  if (!val.selected) {
    val.selected = new moment().format();
  } else if (isMoment(val.selected)) {
    val.selected = val.selected.format();
  }
  if (!val?.events && myValue?.events) {
    val.events = myValue.events;
  }
  if (!val.mode) val.mode = 'month';
}

const parseValueToEvents = (val, setMyEvents) => {
  if (Array.isArray(val.events)) {
    const myEvents = {};
    for (const evt of val.events) {
      if (evt?.title && evt?.slot) {
        const slot = evt.slot;
        if (!myEvents[slot]) myEvents[slot] = [];
        myEvents[slot].push({...evt, index: myEvents[slot].length});
      }
      setMyEvents(myEvents);
    }
  }
}

const STR = 1;
const END = 3;
const popularColors = [
  ...volcano.slice(STR,END), ...orange.slice(STR,END),
  ...yellow.slice(STR,END), ...lime.slice(STR,END),
  ...green.slice(STR,END), ...cyan.slice(STR,END),
  ...blue.slice(STR,END), ...geekblue.slice(STR,END),
  ...purple.slice(STR,END), ...magenta.slice(STR,END)];

export const MyCalendar = ({
    id, value, fullscreen, disabled, debug, row,
    nodrag, noadd, noedit, nocopy, nodelete, customdialog,
    autocolor, doublerow, responsive, onChange, dateFormat, ...props}) => {
  const [oldValue, setOldValue] = useState();
  const [myValue, setMyValue] = useState({});
  const [myEvents, setMyEvents] = useState({});
  const [selectedEvent, setSelectedEvent] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const locale = settingsSignal.locale;
  const { width } = commonSignal;
  const lbl = useLbl(locale);
  const mounted = useRef();
  const copyRef = useRef();
  const rootRef = useRef();
  if (!row) row = 6;
  if (responsive && width < AccountStore.MOBILE_BREAKPOINT) {
    fullscreen = false;
  }
  const onKeyDown = (e) => {
    if (e.ctrlKey && fullscreen) {
      if (!copyRef.current) copyRef.current = true;
      $(`.calendar-root-${id}`).addClass('calendar-event-copy')
    }
  }
  const onKeyUp = (e) => {
    if (copyRef.current) copyRef.current = false;
    $(`.calendar-root-${id}`).removeClass('calendar-event-copy')
  }
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    window.addEventListener('keyup', onKeyUp)
    mounted.current = true;
    return () => {
      window.removeEventListener('keydown', onKeyDown)
      window.removeEventListener('keyup', onKeyUp)
      mounted.current = false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (value !== oldValue) {
      if (mounted.current) parseValue(value, myValue, setMyValue, setMyEvents)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[value])

  useEffect(() => {
    const allevents = [];
    for (const key in myEvents) {
      if (myEvents.hasOwnProperty(key)) {
        const events = myEvents[key]
        if (events) {
          events.forEach(e => {
            allevents.push(e);
          })
        }
      }
    }
    setMyValue((v) => {
      return {...v, events: allevents}
    })
  },[myEvents])

  const timerRef = useRef();
  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      if (onChange) {
        let json = JSON.parse(JSON.stringify(myValue || {}, momentReplacer));
        if (!equals(json, value)) {
          json.fullscreen = fullscreen;
          setOldValue(json);
          onChange(json);
        }
      }
    }, 500);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myValue])

  const SortableEventList = ({slot, autocolor, events, row, className}) => {
    if (!className) className = '';
    let style = {};
    if (row && fullscreen) {
      style.height = row + 'rem';
    }
    return (
      <div className={`calendar-events ${className}`} style={style}>
        {events.filter(item => !item.isDelete).map(item => (
          <MyEvent
            key={item.rowKey} data={item} index={item.index} slot={item.slot}
            type={item.type} title={item.title} tooltip={item.tooltip} autocolor={autocolor}
            backgroundColor={item.backgroundColor} color={item.color}
            onEdit={onEditEvent}/>
        ))}
        {!disabled && !noadd && !fullscreen && slot && <Button className={'add-event'}
          icon={<PlusOutlined/>}
          onClick={()=>onAddEvent(slot)}
          />}
      </div>
    );
  }

  const MyEvent = ({index, data, type = 'default', slot, title, tooltip = title, autocolor, color, backgroundColor, onEdit}) => {
    const style = {}
    if (backgroundColor || color) {
      style.backgroundColor = backgroundColor;
      style.color = color;
    } else if (autocolor) {
      let h = hashCode(title);
      if (h < 0) h *= -1;
      h = h % popularColors.length;
      style.backgroundColor = popularColors[h]
    }
    const onClick = (e) => {
      if (e.detail >= 2 || !fullscreen) {
        if (onEdit) onEdit(data);
      }
    }
    const onDragStart = (e) => {
      createShadow(e, e.target)
    }

    if ((nodrag && nocopy) || (data.nodrag && data.nocopy)) {
      return <Droppable types={['event']} onClick={onClick} onDrop={e => onDrop(e, slot, index)} className={doublerow === true ? `double-row` : ``}><div className={`calendar-event ${type}`} style={style} title={tooltip}>{title}</div></Droppable>
    } else {
      return <Droppable types={['event']} onClick={onClick} onDrop={e => onDrop(e, slot, index)} className={doublerow === true ? `double-row` : ``}><Draggable type="event" data={JSON.stringify(data)} onDragStart={(e)=>onDragStart(e)}><div className={`calendar-event ${type}`} style={style} title={tooltip}>{title}</div></Draggable></Droppable>
    }
  }

  const MyNote = ({type, title, autocolor, color, backgroundColor}) => {
    const style = {}
    if (backgroundColor || color) {
      style.backgroundColor = backgroundColor;
      style.color = color;
    } else if (autocolor) {
      let h = hashCode(title);
      if (h < 0) h *= -1;
      h = h % popularColors.length;
      style.backgroundColor = popularColors[h]
    }
    return <div className={`calendar-note ${type}`} style={style} title={title}>{title}</div>
  }
  const onDrop = (data, slot, index) => {
    try {
      const json = JSON.parse(data.event);
      const oldSlot = json.slot;
      if (oldSlot !== slot && typeof index === 'undefined') {
        setMyEvents((prev) => onDropToDate(prev, slot, oldSlot, json))
      } else if (oldSlot === slot && typeof index === 'number') {
        setMyEvents((prev) => onDropToSeq(prev, slot, index, json))
      }
    } catch (err) {
      log(err)
    }
  }

  const onDropToDate = (prev, slot, oldSlot, json) => {
    const evts = prev?.[slot] || [];
    const copy = !!copyRef.current && !nocopy && !json.nocopy;
    const drag = !copyRef.current && !nodrag && !json.nodrag;
    let oldEvts = prev?.[oldSlot] || [];
    if (!copy && !drag) return prev;
    if (!copy) {
      oldEvts = oldEvts.filter(o => o.rowKey !== json.rowKey);
    } else {
      json.copyFrom = json.rowKey;
      json.rowKey = uid();
    }
    evts.push({...json, slot, index: evts.length})
    return {...prev, [slot]: [...evts], [oldSlot]: [...oldEvts]};
  }

  const onDropToSeq = (prev, slot, index, json) => {
    let evts = prev?.[slot] || [];
    if (index < 0) index = 0;
    if (index >= evts.length) index = evts.length - 1;
    evts = evts.filter(o => o.rowKey !== json.rowKey)
    evts = [...evts.slice(0, index), {...json, slot}, ...evts.slice(index)]
    return {...prev, [slot]: [...evts]}
  }

  const monthCellRender = (val) => {
    const slot = val.format('YYYY-MM')
    const evts = myEvents?.[slot] || [];
    if (fullscreen) {
      return (
        <div className="calendar-events">
          {evts.map(item => (
              <MyNote key={item.rowKey} data={item}
                type={item.type} title={item.title}
                color={item.color} backgroundColor={item.backgroundColor}
                autocolor={autocolor}/>
            )
          )}
        </div>
      );
    } else {
      return evts.length > 0 ? <Badge status={evts[0].type} color={evts[0].backgroundColor} dot/> : null
    }
  }

  const getDateColor = (val) => {
    const slot = val.format('YYYY-MM-DD')
    const selected = new moment(myValue?.selected).format('YYYY-MM-DD');
    const events = myEvents[slot] || [];
    let dateColor = null;
    if (selected === slot) {
      for (const evt of events) {
        if (evt.selectedColor) {
          dateColor = evt.selectedColor;
          break;
        } else if (evt.dateColor) {
          dateColor = evt.dateColor;
          break;
        }
      }
    } else {
      for (const evt of events) {
        if (evt.dateColor) {
          dateColor = evt.dateColor;
          break;
        }
      }
    }
    return dateColor;
  }

  const getCalendarTitle = (val) => {
    if (!fullscreen) return null;
    const slot = val.format('YYYY-MM-DD')
    const events = myEvents[slot] || [];
    let title = null;
    for (const evt of events) {
      if (evt.type === 'summary') {
        title = evt.tooltip;
        break;
      }
    }
    return title;
  }

  const dateFullCellRender = (val) => {
    const dd = val.format('DD');
    const slot = val.format('YYYY-MM-DD')
    const dateColor = getDateColor(val)
    const style = {background: dateColor}
    const contentStyle = {};
    const calendarTitle = getCalendarTitle(val)
    if (row && fullscreen) {
      style.height = ((row + 2) * 1.1) + 'rem';
      contentStyle.height = (row * 1.1) + 'rem';
    }
    return (
      <div className="ant-picker-cell-inner ant-picker-calendar-date" style={style} ref={element => {if (element) element.style.setProperty('background', style.background, 'important');}}>
        {!disabled && !noadd && fullscreen && <Button className={'add-event-button'}
          shape={"square"} size={"small"} icon={<BsPlus/>}
          onClick={() => onAddEvent(slot)}></Button>}
        <div>
          <div className="ant-picker-calendar-date-title" dangerouslySetInnerHTML={{__html:calendarTitle}}></div>
          <div className="ant-picker-calendar-date-value">{dd}</div>
          <div className="ant-picker-calendar-date-content" style={contentStyle}>
            {dateCellRender(val)}
          </div>
        </div>
      </div>
    )
  }

  const dateCellRender = (val) => {
    const slot = val.format('YYYY-MM-DD');
    const events = myEvents?.[slot] || [];
    if (fullscreen) {
      return <Droppable types={['event']} onDrop={e => onDrop(e, slot)}>
        <SortableEventList disabled={disabled} events={events} row={row} autocolor={autocolor} />
      </Droppable>;
    } else {
      return events.length > 0 ? <Badge className="minimize" status={events[0].type}/> : null
    }
  };

  const dateEventList = (val) => {
    if (val) {
      if (!val.format) val = moment(val);
      const slot = val.format('YYYY-MM-DD');
      const events = myEvents?.[slot] || [];
      return <SortableEventList slot={slot} disabled={disabled} events={events} row={row} autocolor={autocolor} className={'mobile'}/>;
    } else {
      return null;
    }
  }

  const onSelect = (date) => {
    setMyValue((prev) => {
      const newValue = {...prev, selected: date}
      return newValue;
    })
  }

  const onPanelChange = (date, mode) => {
    setMyValue((prev) => {
      return {...prev, selected: date, mode}
    })
  }

  const onValueChange = (text) => {
    if (onChange) {
      onChange(text);
    }
  }

  const displayDate = (slot) => {
    if (dateFormat) {
      const date = new moment(slot, 'YYYY-MM-DD');
      return date.format(dateFormat);
    } else {
      return slot;
    }
  }

  const onAddEvent = (slot) => {
    const data = {slot, displayDate: displayDate(slot)};
    if (!noadd) {
      setMyValue((prev) => ({...prev, editing: {...data}}));
      setSelectedEvent(data);
      setModalVisible(true);
    }
  }

  const onEditEvent = (event) => {
    const slot = event.slot;
    const data = {...event, displayDate: displayDate(slot)};
    if (!noedit && !data.noedit) {
      setMyValue((prev) => ({...prev, editing: {...data}}));
      setSelectedEvent(data);
      setModalVisible(true);
    }
  }

  const onOk = (updatedEvent) => {
    if (updatedEvent) {
      const slot = updatedEvent.slot;
      if (slot) {
        setMyEvents((prev) => doUpdateEvent(prev, updatedEvent, slot))
        setModalVisible(false);
        setSelectedEvent(null);
      } else {
        message.error(lbl("system.form.event.no-slot-defined", "No slot defined!"));
      }
    } else {
      message.error(lbl("system.form.event.no-event-defined", "No event defined!"));
    }
    setMyValue((prev) => ({...prev, editing: null}));
  }

  const doUpdateEvent = (prev, updatedEvent, slot) => {
    const events = prev[slot] || [];
    if (updatedEvent.rowKey) {
      // update event
      let index = -1;
      events.forEach((e, idx) => {
        if (e.rowKey === updatedEvent.rowKey) {
          index = idx;
        }
      })
      if (index === -1) {
        message.error(lbl("system.form.event.not-found", "Event not found!"));
        return prev;
      } else {
        events[index] = {...updatedEvent};
        return {...prev, [slot]: [...events]};
      }
    } else {
      // add new event
      updatedEvent.rowKey = uid();
      events.push({...updatedEvent});
      return {...prev, [slot]: [...events]}
    }
  }

  const onCancel = () => {
    setModalVisible(false);
    setSelectedEvent(null);
    setMyValue((prev) => ({...prev, editing: null}));
  }

  return (
    <div ref={rootRef} className={`calendar-root calendar-root-${id} ${nocopy ? 'nocopy' : ''}`}>
      <Affix offsetTop={78}>
        <div ref={rootRef} className={`calendar-copy-flag`}>{lbl('system.form.calendar.event.copy', 'Copy')}</div>
      </Affix>
      <Calendar fullscreen={!!fullscreen}
        value={new moment(myValue?.selected)}
        mode={myValue?.mode}
        onSelect={onSelect} onPanelChange={onPanelChange}
        dateFullCellRender={dateFullCellRender} monthCellRender={monthCellRender}
        {...props}>
      </Calendar>
      {debug && <JsonObjectEditor value={value} onChange={onValueChange} />}
      <EventModal
        visible={modalVisible && !customdialog} onOk={onOk} onCancel={onCancel}
        event={selectedEvent} nodelete={nodelete} nodrag={nodrag} nocopy={nocopy}/>
      {!fullscreen && myValue?.selected && dateEventList(myValue?.selected)}
    </div>
  )
}

const EventModal = ({
  visible,
  onOk,
  onCancel,
  event,
  nodelete,
  nodrag,
  nocopy,
}) => {
  const [form] = Form.useForm();
  const mounted = useRef();
  const titleRef = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(event);
    setTimeout(() => {
      titleRef.current?.focus();
    }, 500)
  }, [form, event]);

  const onFinish = async () => {
    const values = form.getFieldsValue(true);
    if (onOk) onOk(values);
  };
  return (
    <Modal
      width={600}
      title={
        event?.rowKey ? (
          <IntlMessages id="system.form.edit" text="Edit" />
        ) : (
          <IntlMessages id="system.form.add" text="Add" />
        )
      }
      className="calendar-event-modal"
      visible={visible}
      onOk={onFinish}
      onCancel={onCancel}
    >
      <Form form={form} name={"AddEditRecord"} colon={false}>
        <Form.Item name="rowKey" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name={'displayDate'}
          label={<IntlMessages id="system.form.calendar.event.date" text="Date" />}
          labelCol={{ span: 6 }}
        >
          <Input readOnly/>
        </Form.Item>
        <Form.Item
          name={'type'}
          label={<IntlMessages id="system.form.calendar.event.type" text="Type" />}
          labelCol={{ span: 6 }}
          initialValue={'default'}
        >
          <Select options={EVENT_TYPES}/>
        </Form.Item>
        <Form.Item
          name={'title'}
          label={<IntlMessages id="system.form.title" text="Title" />}
          labelCol={{ span: 6 }}
        >
          <Input ref={titleRef} onPressEnter={onFinish}/>
        </Form.Item>
        <Form.Item
          name={'backgroundColor'}
          label={<IntlMessages id="system.form.calendar.event.background-color" text="background color" />}
          labelCol={{ span: 6 }}
        >
          <ColorPicker allowClear />
        </Form.Item>
        <Form.Item
          name={'color'}
          label={<IntlMessages id="system.form.calendar.event.color" text="font color" />}
          labelCol={{ span: 6 }}
        >
          <ColorPicker allowClear />
        </Form.Item>
        <Form.Item
          name={'dateColor'}
          label={<IntlMessages id="system.form.calendar.event.date-color" text="date color" />}
          labelCol={{ span: 6 }}
        >
          <ColorPicker allowClear />
        </Form.Item>
        <Form.Item
          name={'selectedColor'}
          label={<IntlMessages id="system.form.calendar.event.date-selected-color" text="selected color" />}
          labelCol={{ span: 6 }}
        >
          <ColorPicker allowClear />
        </Form.Item>
        {!nodrag && <Form.Item
          name="nodrag"
          label={<IntlMessages id="system.form.calendar.event.nodrag" text="No Drag" />}
          labelCol={{ span: 6 }}
          valuePropName="checked"
        >
          <Switch className="item-property" />
        </Form.Item>}
        {!nocopy && <Form.Item
          name="nocopy"
          label={<IntlMessages id="system.form.calendar.event.nocopy" text="No Copy" />}
          labelCol={{ span: 6 }}
          valuePropName="checked"
        >
          <Switch className="item-property" />
        </Form.Item>}
        {event?.rowKey && !nodelete && !event?.nodelete && <Form.Item
          name="isDelete"
          label={<IntlMessages id="system.form.delete" text="Delete" />}
          labelCol={{ span: 6 }}
          valuePropName="checked"
        >
          <Switch className="item-property delete" />
        </Form.Item>}
      </Form>
    </Modal>
  );
};

export const SfpCalendar = ({
  doRef, form, condistyles, className, style, dbtn, hideInTable,
  tableColWidth, tableColTitle, itemKey, volitate,
  styleStr, ...otherProps }) => {
  const sls = convertStyleStr(styleStr);
  const [fxr, setFxr] = useState({});
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name}
               shouldUpdate={fx} hidden={fxr.hidden}
              wrap>
              <MyCalendar tyle={sls} id={itemKey} {...otherProps} />
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

SfCalendar.craft = {
  displayName: "Calendar",
  related: {
    settings: SfCalendarSetting
  }
}

registerComponent({
  key:"SfCalendar",
  component: SfCalendar,
  runtimeComponent: SfpCalendar,
  template: <SfCalendar itemKey={getId('calendar')} className="sf-calendar calendar wrap"
            title={"Calendar"} span={24} row={6} fullscreen={true}/>,
  title: <IntlMessages id="system.form.library.calendar" text="Calendar" />,
  icon: <BsCalendarWeek className="react-icons icon-io5"/>,
  type: "Component",
  sequence: 23,
});

export default SfCalendar;